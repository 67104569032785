import { OnInit, Component, ViewChild } from "@angular/core";
import { User } from "../../../models/user.model";
import { UserService } from "../../../services/user.service";
import { EmployeeService } from "../../../services/employee.service";
import { forkJoin, Observable } from "rxjs";
import { Employee } from "../../../models/employee.model";
import { FormControl, NgForm } from "@angular/forms";
import { startWith, map, debounceTime, switchMap } from "rxjs/operators";
import { BattalionChiefEmployee } from "../../../models/battalionchiefemployee.model";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { SaveDialogComponent } from "../../../common/saved-dialog/save-dialog.component";
import { ErrorDialogComponent } from "../../../common/error-dialog/error-dialog.component";
import { AuthService } from "../../../services/auth.service";
import { RolePermissionCheck } from "../../../models/role-permission-check.model";
import { UserRole } from "../../../models/userrole.model";

export interface FilteredEmployees {
  id: number;
  name: string;
}

@Component({
  selector: 'ffd-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  employeeNameFormControl = new FormControl();
  userModel: User;
  currentSelectedRoles: Array<number> = [];
  selectedRoles: Array<number> = [];
  employees: Array<Employee> = [];
  battalionChiefEmployeesSelected: Array<{ employeeId: number, employeeName: string; rankId: number, rankName: string, crewId: number, crewName: string, isSelected: boolean }> = [];
  battalionChiefEmployees: Array<{ employeeId: number, employeeName: string; rankId: number, rankName: string, crewId: number, crewName: string, isSelected: boolean }> = [];
  battalionChiefEmployeesDisplay: Array<{ employeeId: number, employeeName: string, rankId: number, rankName: string, crewId: number, crewName: string, isSelected: boolean }> = [];
  errMsg: string = '';
  savedialog: boolean;
  errordialog: boolean;
  isOnLoad: boolean = true;
  isMultipleBattalionsSelected: boolean = false;
  isEmployeeExists: boolean = true;
  isUpdate: boolean = false;
  displayBattalionChiefCrewSelection: boolean = false;
  isEmailDisabled: boolean = true;
  userNameForUpdate: string = '';
  crewHeading: string = '';
  confirmPasswordInput: string = '';
  battalionChiefOneRoleId: number;
  battalionChiefTwoRoleId: number;
  battalionChiefThreeRoleId: number;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  passwordPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&#])[A-Za-z\d$@@$!%*?&#]{8,}/;
  roles: Array<{ roleId: number, roleName: string, isDisabled: boolean }> = [];
  battalionChiefUserRoles: Array<UserRole> = [];
  filteredEmployeeNames: Observable<Array<FilteredEmployees>>;
  displayedColumns: string[] = ['employeeName', 'rankName', 'crewName', 'select'];
  rolePermissionCheckModel: RolePermissionCheck;

  @ViewChild('userForm', { static: false }) userTypeForm: NgForm;

  constructor(
    private userService: UserService,
    private employeeService: EmployeeService,
    private routerService: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.authService.setFormPermissionId('UserForm');
      this.isOnLoad = true;
      this.loadDropDowns();
      this.initializeUserModel();

      this.filteredEmployeeNames = this.employeeNameFormControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );

      //this.employeeNameFormControl.valueChanges.pipe(
      //  startWith(''),
      //  map(value => this._filter(value))
      //).subscribe((employees) => {
      //  this.filteredEmployeeNames = employees;
      //});
    }
  }

  isInvalid(): boolean {
    return this.employeeNameFormControl.touched && this.employeeNameFormControl.invalid;
  }

  loadDropDowns(): void {
    const employees = this.employeeService.getAllEmployees('GetEmployeesForUser', 0);
    const roles = this.userService.getRoles();
    const battalionChiefUserRoles = this.userService.getBattalionChiefUserRoles();
    forkJoin([employees, roles, battalionChiefUserRoles]).subscribe((res) => {
      this.employees = res[0];
      this.roles = res[1].map(x => { return { roleId: x.roleId, roleName: x.roleName, isDisabled: false }; }) || [];
      this.battalionChiefUserRoles = res[2];
      this.battalionChiefOneRoleId = this.roles.find(r => r.roleName === 'Crew 1 BC/CAP').roleId;
      this.battalionChiefTwoRoleId = this.roles.find(r => r.roleName === 'Crew 2 BC/CAP').roleId;
      this.battalionChiefThreeRoleId = this.roles.find(r => r.roleName === 'Crew 3 BC/CAP').roleId;
      this.setBattalioChiefRoleAsDisabledIfAlreadyExists();
    }, (error: any) => {
      this.employees = [];
    });
  }

  setBattalioChiefRoleAsDisabledIfAlreadyExists(): void {
    for (var i = 0; i < this.battalionChiefUserRoles.length; i++) {
      var battalionChiefRoleEntity = this.roles.find(r => r.roleId === this.battalionChiefUserRoles[i].roleId);
      if (battalionChiefRoleEntity) {
        battalionChiefRoleEntity.isDisabled = true;
      }
    }
  }

  initializeUserModel(): void {
    this.userModel = this.userService.initializeUserModel();
  }

  checkBattalionChiefSelected(event: Array<number>): void {
    let bc1 = event.find(x => x == this.battalionChiefOneRoleId);
    let bc2 = event.find(x => x == this.battalionChiefTwoRoleId);
    let bc3 = event.find(x => x == this.battalionChiefThreeRoleId);
    if ((bc1 && bc2) || (bc1 && bc3) || (bc2 && bc3) || (bc1 && bc2 && bc3)) {
      this.resetBattalionChiefLists();
      this.displayBattalionChiefCrewSelection = false;
      this.isMultipleBattalionsSelected = true;
      this.selectedRoles = [];
      if (event && event.length > 0) {
        event.forEach((x: number) => {
          if (!(x == bc1 || x == bc2 || x == bc3)) {
            this.selectedRoles.push(x);
          }
        });
      }
      return;
    } else if (bc1 || bc2 || bc3) {
      this.isMultipleBattalionsSelected = false;
      this.displayBattalionChiefCrewSelection = true;
      this.battalionChiefEmployees = this.employees.map(x => { return { employeeId: x.employeeId, employeeName: x.lastName + ' ' + x.firstName, rankId: x.rank, rankName: x.rankName, crewId: x.shift, crewName: x.shiftName, isSelected: false }; });
      this.battalionChiefEmployeesSelected = [];
      if (bc1) {
        this.crewHeading = 'Crew 1';
        let isBC1Exists = this.selectedRoles.find(x => x == bc1);
        if (!isBC1Exists) {
          this.battalionChiefEmployeesDisplay = [];
        }
      } else if (bc2) {
        this.crewHeading = 'Crew 2';
        let isBC2Exists = this.selectedRoles.find(x => x == bc2);
        if (!isBC2Exists) {
          this.battalionChiefEmployeesDisplay = [];
        }
      } else if (bc3) {
        this.crewHeading = 'Crew 3';
        let isBC3Exists = this.selectedRoles.find(x => x == bc3);
        if (!isBC3Exists) {
          this.battalionChiefEmployeesDisplay = [];
        }
      }
      if (this.isUpdate) {
        let isBc1 = this.userModel.userRoles.find(x => x == bc1);
        let isBc2 = this.userModel.userRoles.find(x => x == bc2);
        let isBc3 = this.userModel.userRoles.find(x => x == bc3);

        if (isBc1 || isBc2 || isBc3) {
          this.battalionChiefEmployeesDisplay = [];
          if (this.userModel.battalionChiefEmployees && this.userModel.battalionChiefEmployees.length > 0) {
            this.userModel.battalionChiefEmployees.forEach((x: BattalionChiefEmployee) => {
              let setBattalionChiefEmployee = this.battalionChiefEmployees.find(bc => bc.employeeId == x.employeeId);
              if (setBattalionChiefEmployee) {
                setBattalionChiefEmployee.isSelected = true;
                this.battalionChiefEmployeesDisplay.push({
                  employeeId: x.employeeId,
                  employeeName: setBattalionChiefEmployee.employeeName,
                  rankId: setBattalionChiefEmployee.rankId,
                  rankName: setBattalionChiefEmployee.rankName,
                  crewId: setBattalionChiefEmployee.crewId,
                  crewName: setBattalionChiefEmployee.crewName,
                  isSelected: x.isSelected
                });
              }
            });
          }
        }
      }
      this.battalionChiefEmployeesDisplay = [...this.battalionChiefEmployeesDisplay];
    } else {
      this.resetBattalionChiefLists();
      this.isMultipleBattalionsSelected = false;
      this.displayBattalionChiefCrewSelection = false;
    }
    this.selectedRoles = [];
    if (event && event.length > 0) {
      event.forEach((x: number) => { this.selectedRoles.push(x); });
    }
  }

  resetBattalionChiefLists(): void {
    this.battalionChiefEmployees = [];
    this.battalionChiefEmployeesSelected = [];
    this.battalionChiefEmployeesDisplay = [];
    this.battalionChiefEmployeesDisplay = [...this.battalionChiefEmployeesDisplay];
  }

  addSelectedEmployeesToList(): void {
    for (var i = 0; i < this.battalionChiefEmployeesSelected.length; i++) {
      this.battalionChiefEmployeesDisplay.push({
        employeeId: this.battalionChiefEmployeesSelected[i].employeeId,
        employeeName: this.battalionChiefEmployeesSelected[i].employeeName,
        rankId: this.battalionChiefEmployeesSelected[i].rankId,
        rankName: this.battalionChiefEmployeesSelected[i].rankName,
        crewId: this.battalionChiefEmployeesSelected[i].crewId,
        crewName: this.battalionChiefEmployeesSelected[i].crewName,
        isSelected: true
      });
      let bcEmployee = this.battalionChiefEmployees.find(x => x.employeeId == this.battalionChiefEmployeesSelected[i].employeeId);
      if (bcEmployee) {
        bcEmployee.isSelected = true;
      }
    }

    if (this.battalionChiefEmployeesSelected.length > 0) {
      this.battalionChiefEmployeesDisplay = [...this.battalionChiefEmployeesDisplay];
      this.battalionChiefEmployeesSelected = [];
    }
  }

  setIsSelected(isChecked: boolean, employeeId: number): void {
    let employeeDisplay = this.battalionChiefEmployeesDisplay.find(x => x.employeeId == employeeId);
    if (employeeDisplay) {
      employeeDisplay.isSelected = isChecked;
    }
  }

  setEmployeeIdForUserModel(): void {
    this.isEmployeeExists = true;
    let isObject = false;
    const formControlValue = this.employeeNameFormControl.value;

    if (formControlValue.id) {
      isObject = true;
    }

    if (isObject) {
      this.resetForm(false);
      var employee = this.employees.find(x => x.employeeId == formControlValue.id);
      if (employee) {
        this.userService.getUser(employee.employeeId).subscribe((res: User) => {
          if (res.userId > 0) {
            this.isOnLoad = false;
            this.isUpdate = true;
            this.userModel = res;
            this.confirmPasswordInput = this.userModel.password;
            this.userNameForUpdate = this.userModel.userName;
            this.currentSelectedRoles = this.userModel.userRoles;
            this.battalionChiefEmployees = [];
            this.battalionChiefEmployeesSelected = [];
            this.battalionChiefEmployeesDisplay = [];
            this.battalionChiefEmployeesDisplay = [...this.battalionChiefEmployeesDisplay];

            let bc1 = this.userModel.userRoles.find(x => x == this.battalionChiefOneRoleId);
            let bc2 = this.userModel.userRoles.find(x => x == this.battalionChiefTwoRoleId);
            let bc3 = this.userModel.userRoles.find(x => x == this.battalionChiefThreeRoleId);
            if (bc1 || bc2 || bc3) {
              if (bc1) {
                this.crewHeading = 'Crew 1';
                let batChief1Entity = this.roles.find(r => r.roleId === this.battalionChiefOneRoleId);
                if (batChief1Entity) {
                  batChief1Entity.isDisabled = false;
                }
              } else if (bc2) {
                this.crewHeading = 'Crew 2';
                let batChief2Entity = this.roles.find(r => r.roleId === this.battalionChiefTwoRoleId);
                if (batChief2Entity) {
                  batChief2Entity.isDisabled = false;
                }
              } else {
                this.crewHeading = 'Crew 3';
                let batChief3Entity = this.roles.find(r => r.roleId === this.battalionChiefThreeRoleId);
                if (batChief3Entity) {
                  batChief3Entity.isDisabled = false;
                }
              }
              this.displayBattalionChiefCrewSelection = true;
              this.battalionChiefEmployees = this.employees.map(x => { return { employeeId: x.employeeId, employeeName: x.lastName + ' ' + x.firstName, rankId: x.rank, rankName: x.rankName, crewId: x.shift, crewName: x.shiftName, isSelected: false }; });
              if (this.userModel.battalionChiefEmployees && this.userModel.battalionChiefEmployees.length > 0) {
                this.userModel.battalionChiefEmployees.forEach((x: BattalionChiefEmployee) => {
                  let setBattalionChiefEmployee = this.battalionChiefEmployees.find(bc => bc.employeeId == x.employeeId);
                  if (setBattalionChiefEmployee) {
                    setBattalionChiefEmployee.isSelected = true;
                    this.battalionChiefEmployeesDisplay.push({
                      employeeId: x.employeeId,
                      employeeName: setBattalionChiefEmployee.employeeName,
                      rankId: setBattalionChiefEmployee.rankId,
                      rankName: setBattalionChiefEmployee.rankName,
                      crewId: setBattalionChiefEmployee.crewId,
                      crewName: setBattalionChiefEmployee.crewName,
                      isSelected: x.isSelected
                    });
                    this.battalionChiefEmployeesDisplay = [...this.battalionChiefEmployeesDisplay];
                  }
                });
              }
            }
          } else {
            this.isOnLoad = false;
            this.isUpdate = false;
            this.userModel.employeeId = employee.employeeId;
            this.userModel.email = (employee.email) ? employee.email : null;
            this.userModel.isActive = true;
            this.userModel.notifyUser = true;
            if (!employee.email) {
              this.isEmailDisabled = false;
            } else {
              this.isEmailDisabled = true;
            }
          }
        }, (error: any) => {
          //Display error message in html
        });
      } else {
        this.isEmployeeExists = false;
      }
    }
  }

  isSaveDisabled(): boolean {
    let isBCInUserRoles = true;
    if (this.currentSelectedRoles.length > 0) {
      this.currentSelectedRoles.forEach((x: number) => {
        if (x == this.battalionChiefOneRoleId || x == this.battalionChiefTwoRoleId || x == this.battalionChiefThreeRoleId) {
          if (this.battalionChiefEmployeesDisplay.length > 0) {
            isBCInUserRoles = true;
          } else {
            isBCInUserRoles = false;
          }
        }
      });
    }
    return !isBCInUserRoles;
  }

  resetForm(isValid: boolean): void {
    if (isValid) {
      this.battalionChiefOneRoleId = null;
      this.battalionChiefTwoRoleId = null;
      this.battalionChiefThreeRoleId = null;
      this.employeeNameFormControl.reset();
      this.roles = [];
      this.employees = [];
      this.loadDropDowns();
    }
    this.initializeUserModel();
    //this.filteredEmployeeNames = [];
    this.currentSelectedRoles = [];
    this.selectedRoles = [];
    this.battalionChiefEmployeesSelected = [];
    this.battalionChiefEmployees = [];
    this.battalionChiefEmployeesDisplay = [];
    this.isOnLoad = true;
    this.isEmployeeExists = true;
    this.isMultipleBattalionsSelected = false;
    this.isUpdate = false;
    this.displayBattalionChiefCrewSelection = false;
    this.isEmailDisabled = true;
    this.userNameForUpdate = '';
    this.crewHeading = '';
    this.confirmPasswordInput = '';
    this.userTypeForm.resetForm();
  }

  saveUser(): void {
    let bc1 = this.currentSelectedRoles.find(x => x == this.battalionChiefOneRoleId);
    let bc2 = this.currentSelectedRoles.find(x => x == this.battalionChiefTwoRoleId);
    let bc3 = this.currentSelectedRoles.find(x => x == this.battalionChiefThreeRoleId);

    if (this.isUpdate) {
      let checkBCAlreadyExists: boolean = false;
      for (var i = 0; i < this.userModel.userRoles.length; i++) {
        if (this.userModel.userRoles[i] == bc1 || this.userModel.userRoles[i] == bc2 || this.userModel.userRoles[i] == bc3) {
          checkBCAlreadyExists = true;
          break;
        }
      }
      if (this.userModel.battalionChiefEmployees && this.userModel.battalionChiefEmployees.length > 0 && checkBCAlreadyExists) {
        if (this.battalionChiefEmployeesDisplay.length > 0) {
          this.battalionChiefEmployeesDisplay.forEach((x) => {
            let employeeExistsInUserModel = this.userModel.battalionChiefEmployees.find(e => e.employeeId == x.employeeId);
            if (employeeExistsInUserModel) {
              employeeExistsInUserModel.isNew = false;
              employeeExistsInUserModel.isSelected = x.isSelected;
            } else {
              this.userModel.battalionChiefEmployees.push({
                employeeId: x.employeeId,
                isSelected: x.isSelected,
                isNew: true
              });
            }
          });
        }
      } else if (this.userModel.battalionChiefEmployees && this.userModel.battalionChiefEmployees.length > 0 && (bc1 || bc2 || bc3)) {
        for (var i = 0; i < this.userModel.battalionChiefEmployees.length; i++) {
          this.userModel.battalionChiefEmployees[i].isSelected = false;
          this.userModel.battalionChiefEmployees[i].isNew = false;
        }
        if (this.battalionChiefEmployeesDisplay.length > 0) {
          this.battalionChiefEmployeesDisplay.forEach((x) => {
            let employeeExistsInUserModel = this.userModel.battalionChiefEmployees.find(e => e.employeeId == x.employeeId);
            if (employeeExistsInUserModel) {
              employeeExistsInUserModel.isNew = false;
              employeeExistsInUserModel.isSelected = x.isSelected;
            } else {
              this.userModel.battalionChiefEmployees.push({
                employeeId: x.employeeId,
                isSelected: x.isSelected,
                isNew: true
              });
            }
          });
        }
      } else if (bc1 || bc2 || bc3) {
        if (this.battalionChiefEmployeesDisplay.length > 0) {
          this.battalionChiefEmployeesDisplay.forEach((x) => {
            if (x.isSelected) {
              this.userModel.battalionChiefEmployees.push({
                employeeId: x.employeeId,
                isSelected: x.isSelected,
                isNew: true
              });
            }
          });
        }
      }

      this.userModel.userRoles = [];
      if (this.currentSelectedRoles.length > 0) {
        this.currentSelectedRoles.forEach((x: number) => { this.userModel.userRoles.push(x); });
      }

      this.userService.updateUser(this.userModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated the User successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetForm(true);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error occurred while updating the User. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetForm(true);
          });
        }
      }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error occurred while updating the User. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.goHome();
        });
      });
    } else {
      if (this.currentSelectedRoles.length > 0) {
        this.currentSelectedRoles.forEach((x: number) => { this.userModel.userRoles.push(x); });
      }
      if (bc1 || bc2 || bc3) {
        if (this.battalionChiefEmployeesDisplay.length > 0) {
          this.battalionChiefEmployeesDisplay.forEach((x) => {
            if (x.isSelected) {
              this.userModel.battalionChiefEmployees.push({
                employeeId: x.employeeId,
                isSelected: x.isSelected,
                isNew: true
              });
            }
          });
        }
      }

      this.userService.saveUser(this.userModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved the User successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetForm(true);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error occurred while saving the User. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetForm(true);
          });
        }
      }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error occurred while saving the User. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.goHome();
        });
      });
    }
  }

  goHome(): void {
    this.routerService.navigate(['home']);
  }

  get isSavePermission(): boolean {
    return ((this.userModel.userId == 0) && (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isCreate));
  }

  get isUpdatePermission(): boolean {
    return ((this.userModel.userId > 0) && (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isUpdate));
  }

  getEmployee(employee: any) {
    if (employee) { return employee.name; }
  }

  private _filter(value: any): Array<{ id: number; name: string }> {
    var employeeNames: Array<{ id: number; name: string }> = [];
    if (typeof (value) === 'object') {
      return employeeNames;
    } else {
      const filterValue = this._normalizeValue(value);
      for (var i = 0; i < this.employees.length; i++) {
        if (this.employees[i].lastName.toLowerCase().startsWith(filterValue)) {
          employeeNames.push({
            id: this.employees[i].employeeId,
            name: this.employees[i].lastName + ', ' + this.employees[i].firstName
          });
        }
      }
      return employeeNames;
    }
  }

  private _normalizeValue(value: string): string {
    if (!value) {
      return "";
    }
    return value.toLowerCase().replace(/\s/g, '');
  }
}
