import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { MaintenenceRequestCommentHistory } from '../models/maintenencerequestcommenthistory.model';
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { SystemCodeService } from '../services/system-code.service';
import { MaintanenceRequestService } from '../services/maintanancerequest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCode } from '../models/system-codes.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'maintanencerequesthistoryview',
  templateUrl: './maintanencerequesthistoryview.component.html'
})
export class MaintanenceRequestHistoryView {

  maintenenceRequestModel: MaintenenceRequest;
  maintenenceRequestHistoryModel: MaintenenceRequestCommentHistory[];
  maintanenceRequestCommentId: number = 0;
  maintenenceRequestId: number = 0;
  requestid: string;
  rolePermissionCheckModel: RolePermissionCheck;
  
  constructor(
    private maintanenceRequestService: MaintanenceRequestService, private route: ActivatedRoute, private systemCodeStateService: SystemCodeService,
    private routerService: Router
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.maintanenceRequestCommentId = parseInt(Id);
        }
        let reqid = params['reqid'];
        this.requestid = reqid;
      });
    }
  }
  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //this.maintenenceRequestHistoryModel = this.initializeMaintanenceRequestModel();

      if (this.maintanenceRequestCommentId > 0) {
        this.maintanenceRequestService.getMaintanenceCommentsHistory('GetMaintanenceRequestCommentsHistory', this.maintanenceRequestCommentId).subscribe(res => {
          // this.maintenenceRequestModel = res;
          this.maintenenceRequestHistoryModel = res || [];

        });
      }
    }
  }

  initializeMaintanenceRequestModel(): MaintenenceRequestCommentHistory {
    return {
      maintanenceRequestCommentHistoryId: 0,
      maintanenceRequestCommentId: 0,
      maintenenceRequestId: 0,
      requestComment: null,
      isActive: false,
      modifiedOn: null,
      modifiedBy: null
    } as MaintenenceRequestCommentHistory;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
