import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { User } from "../models/user.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { UserRole } from "../models/userrole.model";

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/User/', httpClient, errorService, authService);
  }

  initializeUserModel(): User {
    return {
      userId: 0,
      userName: null,
      email: null,
      password: null,
      employeeId: 0,
      notifyUser: false,
      isActive: false,
      userRoles: [],
      battalionChiefEmployees: [],
      newPassword: null,
      confPassword: null
    } as User;
  }

  userNameExists(username: string): Observable<User> {
    let httpParams = new HttpParams().set('username', username);
    return this.customGetRequestWithQueryString<User>('UsernameExists', httpParams);
  }

  getUser(employeeId: number): Observable<User> {
    return this.getRequest(employeeId);
  }

  getRoles(): Observable<Array<{ roleId: number; roleName: string }>> {
    return this.customGetRequest<Array<{ roleId: number; roleName: string }>>('GetRoles', null);
  }

  saveUser(user: User): Observable<any> {
    return this.postRequest(null, JSON.stringify(user));
  }

  updateUser(user: User): Observable<any> {
    return this.putRequest(user.userId, JSON.stringify(user));
  }

  getCrew(action:string,userId: string): Observable<any> {
    return this.customGetRequest(action,userId);
  }

  getBattalionChiefUserRoles(): Observable<Array<UserRole>> {
    return this.customGetRequest<Array<UserRole>>('GetBattalionChiefUserRoles', null);
  }
  updateAccount(action: string, user: User): Observable<any> {
    return this.customPostRequest(action, JSON.stringify(user));
  }
}
