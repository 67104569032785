import { Component } from '@angular/core';
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { SystemCodeService } from '../services/system-code.service';
import { MaintanenceRequestService } from '../services/maintanancerequest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCode } from '../models/system-codes.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'maintanencerequestview',
  templateUrl: './maintanencerequestview.component.html'
})
export class MaintanencerequestView {
  stationCodes: Array<{ key: number, value: string }> = [];
  crews: Array<{ key: number, value: string }> = [];
  managementTypes: Array<{ key: number, value: string }> = [];
  actionReuired: Array<{ key: number, value: string }> = [];
  pendingTypes: Array<{ key: number, value: string }> = [];
  rolePermissionCheckModel: RolePermissionCheck;
  maintenenceRequestModel: MaintenenceRequest;
  maintenenceRequestId: number = 0;

  constructor(
    private maintanenceRequestService: MaintanenceRequestService,
    private routerService: Router,
    private route: ActivatedRoute,
    private systemCodeStateService: SystemCodeService) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.maintenenceRequestId = parseInt(Id);
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.loadDropDowns();
      this.maintenenceRequestModel = this.initializeMaintanenceRequestModel();
      if (this.maintenenceRequestId > 0) {
        this.maintanenceRequestService.getMaintanence('GetMaintenanceRequestView', this.maintenenceRequestId).subscribe(res => {
          // this.maintenenceRequestModel = res;
          this.maintenenceRequestModel = res || [];
        });
      }
    }
  }

  initializeMaintanenceRequestModel(): MaintenenceRequest {
    return {
      maintenenceRequestId: 0,
      stationId: null,
      crewId: null,
      typeId: null,
      actionRequiredId: null,
      description: null,
      pendingOrCompleteId: 0,
      workOrderNumber: null,
      isActive: true,
      pendingCompleteName: null,
      actionRequiredName: null,
      crewName: null,
      stationName: null,
      typeName: null,
      createdOn: null,
      requestComment: null,
      updateComments: null,
      subTypeId: null,
      subTypeName: null
    } as MaintenenceRequest;
  }

  public loadDropDowns() {
    this.systemCodeStateService.getSystemCodesForStation().subscribe(
      (res: Array<SystemCode>) => {
        this.stationCodes = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForCrews().subscribe(
      (res: Array<SystemCode>) => {
        this.crews = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForMaintanenceTypes().subscribe(
      (res: Array<SystemCode>) => {
        this.managementTypes = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForActionRequired().subscribe(
      (res: Array<SystemCode>) => {
        this.actionReuired = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForPendingType().subscribe(
      (res: Array<SystemCode>) => {
        this.pendingTypes = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  GoBack(): void {
    this.routerService.navigate(['/maintenanceReport']);
  }
}
