import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { SystemCodeService } from '../services/system-code.service';
import { WaterUsageService } from '../services/waterusage.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { WaterUsage } from '../models/waterusageform.model';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'waterusagereportview',
  templateUrl: './waterusagereportview.component.html'
})
export class WaterUsageReportView {
  waterUsageId: number;
  waterModel: WaterUsage[] = [];
  waterUsageModel: WaterUsage;
  districtCode: SystemCode[] = [];
  districts: Array<{ key: number, value: string }> = [];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private systemCodeStateService: SystemCodeService,
    private waterusageservice: WaterUsageService, private dialog: MatDialog,
    private route: ActivatedRoute, private routerService: Router
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.waterUsageId = parseInt(Id);
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.loadDropDowns();
      this.waterUsageModel = this.initializeWaterUsageModel();
      if (this.waterUsageId > 0) {
        this.waterusageservice.getWaterUsage('GetWaterUsage', this.waterUsageId).subscribe(res => {
          // this.maintenenceRequestModel = res;
          this.waterUsageModel = res || [];
        });
      }
    }
  }

  initializeWaterUsageModel(): WaterUsage {
    return {
      waterUsageId: 0,
      waterUsageDate: null,
      district: null,
      estimatedWaterUsage: null,
      startDate: null,
      endDate: null,
      districtName: null,
      waterUsageDateStr: null
    } as WaterUsage;
  }

  public loadDropDowns() {
    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.districtCode = res || [];
        this.districts = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];

      }, (error: any) => { }
    );
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  GoBack(): void {
    this.routerService.navigate(['/waterusagereport']);
  }
}
