import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValueTransform'
})

export class KeyValueTransformPipe implements PipeTransform {
  transform(key: string | number, targetArray: Array<{ key: string | number, value: string | number }>) {
    if (key === null || !targetArray)
      return 'Not available';
    var validResults = targetArray.filter(x => +x.key == +key);
    if (validResults)
      return validResults[0].value;
    return 'Not available';
  }
}
