import { Component, OnInit } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { AwardNominationService } from '../services/awardnomination.service';
import { AwardNomination } from '../models/awardnomination.model';
import { SystemCode } from '../models/system-codes.model';
import { Witness } from '../models/witness.model';
import { Nominee } from '../models/nominee.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';


@Component({
  selector: 'awardnominationprint',
  templateUrl: './awardnominationprint.component.html'
})
export class AwardNominationPrintComponent {

  //nomineeName: string = "Briscoe, W";
  //actionIncidentType: string = "EMS";
  //awardRecommendation: string = "Chief Recommendation";
  //runNumber: string = "899";
  //nominationSubmittedBy: string = "Thompson, C";
  //actionIncidentDate: string = "Thursday, February 6,2020";
  //witness: string = "Spaulding, C";
  //narrative: string = "Upon hearing initial dispatch of PD needing help with a patient in custody, Chief Brisco selflessly with no regard to himself dropped his little Debbie cake and proceeded to make his way to the scene. Upon arrival chief brisco sprung into action sizing up and admiring all of the police officers on scene wishing he too was a donut pirate and occasionally scanning for any safety issues for the ambulance crew! Once asked by the paramedic chief brisco bravely helped subdue/secure the patient putting his own safety jeopardy to protect his crew. After loading the patient and being shown where E brake was on the med unit Cheif Briscoe remembered all the snacks in the ems room and volunteered to drive the crew to the ER. All of the above shows great leadership skills and a will to put himself in the face of danger to help his coworkers. ";

  awardNominationModel: AwardNomination;
  awardNominationResultObj: AwardNomination[];
  awardNominationId: number = 0;
  awardNominees: Nominee[];
  awardWitness: Witness[];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private awardNominationService: AwardNominationService
  ) {    
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel) {
      //this.awardNominationModel = { awardNominationId: 0, awardRecommendation: 0, incidentType: 0, actionDate: null, runNumber: "", narrative: "", submittedBy: 0, status: 0, nominees: null, witness: null };

      this.awardNominationModel = this.initializeAwardNominationModel();

      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.awardNominationId = parseInt(Id);
          this.loadData();
        }
      });
    }
  }

  initializeAwardNominationModel(): AwardNomination {
    return {
      awardNominationId: 0, awardRecommendation: null, incidentType: null, actionDate: null, runNumber: null,
      narrative: null, submittedBy: null, status: null, nominees: null, witness: null, nomineeName: "", witnessName: "",
      awardRecommendationName: "", incidentTypeName: "", submittedByName: "", statusName: "", startDate: null, endDate: null
    } as AwardNomination;

  }


  loadData() {
    if (this.awardNominationId > 0) {
      this.awardNominationService.getAwardNominee('GetAwardNominee', this.awardNominationId).subscribe(res => {
        this.awardNominationModel = res;
        this.awardNominees = this.awardNominationModel.nominees;
        this.awardWitness = this.awardNominationModel.witness;
      });
    }
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
