import { Component, Input } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { FormGroup, NgForm, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DepartmentUpdate } from '../models/departmentupdate.model';
import { DepartmentUpdateDescription } from '../models/departmentupdatedescription.model';
import { DepartmentUpdateService } from '../services/departmentupdate.service';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component(
  {
    selector: 'deptupdateview',
    templateUrl: './deptupdateview.component.html'
  })
export class DeptUpdateViewComponent implements OnInit {
  //deptupdateObj: departmentupdate;
  deptupdateObjres: DepartmentUpdate;
  departmentupdatetype: SystemCode[] = [];
  departmentupdates: DepartmentUpdateDescription[] = [];
  createdOn: FormControl;
  createdBy: any;
  subject: string;
  departmentUpdateId: number;
  x = new Date();
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  department1: string;
  department2: string;
  department3: string;
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(private systemCodeStateService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private departmentUpdateService: DepartmentUpdateService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    this.deptupdateObjres = {
      createdOn: null, createdBy: null, departmentUpdateId: 0, subject: null, departmentType: null, descriptions: null, modifiedOn: null, modifiedBy: null }
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.departmentUpdateId = parseInt(Id);
        }
      });
      if (this.departmentUpdateId > 0) {
        this.departmentUpdateService.getDeptUpdate('GetUpdateListForView', this.departmentUpdateId).subscribe(res => {
          this.deptupdateObjres = res;
          this.departmentupdates = this.deptupdateObjres.descriptions || [];
          //this.department1 = this.deptupdateObjres.descriptions[0].description;
          //this.department2 = this.deptupdateObjres.descriptions[1].description;
          //this.department3 = this.deptupdateObjres.descriptions[2].description;
        });
      }
    }
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  GoBack() {
    this.routerService.navigate(['/deptupdatereport']);
  }
}
