import { Component, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatFormFieldModule, MatInputModule } from '@angular/material';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, empty, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { error } from 'util';
//import { FileInput, FileValidator } from 'ngx-material-file-input';
import { PrePlan } from '../models/preplans.model';
import { PrePlanService } from '../services/preplan.service';
import { saveFile, saveAs } from 'file-saver';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { OccupancyService } from '../services/occupancy.service';

@Component({
  selector: 'preplan',
  templateUrl: './preplan.component.html',
  //styleUrls: ['./preplan.component.css']
})

export class PrePlanComponent {

  @ViewChild('prePlanForm', { static: false }) prePlanForm: NgForm;
  @ViewChild('PrePlanfileUploader', { static: false }) prePlanfileUploader: ElementRef;
   
  streets: string[] = [];  
  disabled: boolean = false;
  multiple: boolean = false;
  accept: string;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean
  fileExists: boolean = true;
  prePlanFileExists: boolean = false;  
  validationMsg: string = null;
  isFileSelected: boolean = false;
  floatLabel: string = 'never';
  prePlanDocumentId: number=0;
  fileControl: FormControl;
  prePlanModel: PrePlan;
  prePlanFile: File;
  districtCode: SystemCode[] = [];
  streetnameCode: SystemCode[] = [];
  filteredStreets: Observable<string[]>;
  control = new FormControl();
  rolePermissionCheckModel: RolePermissionCheck;
  isNew: string;
  disableSave: boolean = false;
  streetResultObj: Array<{ key: number, value: string }> = [];
  public filteredStreetList: Array<{ key: number, value: string }> = [];
  
  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private prePlanService: PrePlanService,
    private occupancyService: OccupancyService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.disableSave = false;
      this.route.params.subscribe(params => {
         debugger;
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.prePlanDocumentId = parseInt(Id);
          this.loadDocument();
        }
        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
      });
      this.loadDropDowns();
      this.loadStreets();
      this.prePlanModel = this.initializePrePlanModel();

      this.filteredStreets = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }
  }

  initializePrePlanModel(): PrePlan {
    return {
      prePlanId: 0,
      streetNumber: null,
      streetName: null,
      facilityName: null,
      district: 0,
      prePlanFileName: null,
      prePlanFileType: null
    } as PrePlan;
  }

  PrePlanExcel(fileInput: any) {
    debugger;    
    this.prePlanFile = fileInput[0];
    this.isFileSelected = true;

    var fileName = this.prePlanFile.name;

    const lastDot = fileName.lastIndexOf('.');

    const fName = fileName.substring(0, lastDot);
    const ext = fileName.substring(lastDot + 1);
    this.validationMsg = "";
    if (this.prePlanFile.size > 2097152) {
      console.log("File size is more than 2 MB");
      this.validationMsg = "File size more than 2 MB";
      this.isFileSelected = false;
    }

    if (!(ext == "xlsx" || ext=="XLSX")) {
      console.log("not a valid file format!");
      this.validationMsg = "File extension is Invalid!";
      this.isFileSelected = false;
    }  
  }
  openPrePlanFile(prePlanId: string, fileName: string) {
    debugger;
    this.prePlanService.getPrePlanFile('GetDownPrePlanFile', prePlanId, "PrePlan").subscribe(res => {
      debugger;
      saveAs(res, fileName);     
    });
    return false;
  }
  deletePrePlanFile(prePlanId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '200px',
      data: { id: 1, name: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      debugger;
      if (result != undefined) {

        this.prePlanModel.prePlanFileDelete = true;
        this.prePlanModel.prePlanFileName = null;
        this.prePlanFileExists = false;
      }
    });
    return false;
  }

  closeValidation() {
    this.validationMsg = null;
    this.prePlanfileUploader.nativeElement.value = null;
    this.prePlanFile = null;
  }

  ResetPrePlanFile() {
    this.prePlanModel = this.initializePrePlanModel();
    this.prePlanfileUploader.nativeElement.value = null;
    this.prePlanFile = null;
    this.prePlanFileExists = false;
    this.isFileSelected = false;
    this.prePlanForm.resetForm();
  }

  updatePrePlan() {
    debugger;
    this.disableSave = true;
    let formData: FormData = new FormData();
    formData.append("prePlanId", this.prePlanModel.prePlanId.toString());

  /******************/
    if (this.prePlanModel.streetNumber != undefined && this.prePlanModel.streetNumber != null) {
      formData.append("streetNumber", this.prePlanModel.streetNumber);
    }
    else {
      formData.append("streetNumber", "");
    }

    
    if (this.prePlanModel.streetName != undefined && this.prePlanModel.streetName != null) {
      formData.append("streetName", this.prePlanModel.streetName.toString());
    }
    else {
      formData.append("streetName", "");
    }

    if (this.prePlanModel.facilityName != undefined && this.prePlanModel.facilityName != null) {
      formData.append("facilityName", this.prePlanModel.facilityName);
    }
    else {
      formData.append("facilityName", "");
    }
    if (this.prePlanFileExists == false) {
      formData.append("preplanFileDelete", "true");
    }
       
    if (this.prePlanModel.district != undefined && this.prePlanModel.district != null) {
      formData.append("district", this.prePlanModel.district.toString());
    }
    else {
      formData.append("district", "");
    }

    if (this.prePlanModel.prePlanFileName != undefined && this.prePlanModel.prePlanFileName != null) {
      formData.append("prePlanFileName", this.prePlanModel.prePlanFileName);
    }
    else {
      formData.append("prePlanFileName", "");
    }

    if (this.prePlanFile != null && this.prePlanFile != undefined) {
      formData.append("prePlanFileType", this.prePlanFile.type);
    }
    else {
      formData.append("prePlanFileType", "");
    }


    if (this.prePlanFile != null && this.prePlanFile != undefined) {
      formData.append("prePlanFile", this.prePlanFile, this.prePlanFile.name);
    }
    else {
      formData.append("prePlanFile", null);
    }

   

  
    /*****************/   

    //formData.append("businessText", "");
    //formData.append("streetNameText", "");

    if (this.prePlanModel.prePlanId == 0) {
      debugger;
      this.prePlanService.savePrePlanwithFile("savePrePlanFile", formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {           
            this.ResetPrePlanFile();
            this.GoBack();
           // this.routerService.navigate(['/preplanreport']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
        }
      });
    }
    else {
      this.prePlanService.updatePrePlanwithFile(this.prePlanModel.prePlanId.toString(), formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;         
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {           
            this.ResetPrePlanFile();
            //this.resetTheForm();
            //this.routerService.navigate(['/preplanreport']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
        }
      });

    }
  }

  resetTheForm() {
    this.initializePrePlanModel();
    debugger;
    this.fileExists = false;    
    this.prePlanfileUploader.nativeElement.value = null;
    this.prePlanForm.resetForm();   
    this.prePlanFile = null;
  }

  
  GoBack() {
   // this.routerService.navigate(['/preplanreport']);
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getSystemCodesForDistricts(),
      this.systemCodeService.getSystemCodesForStreetNames()
    ).subscribe(([res1,res2]) => {
      this.districtCode = res1 || [];
      this.streetnameCode = res2 || [];
          });
  }
  public loadStreets() {
    this.occupancyService.getStreets("getSystemCodesForStreetNames").subscribe(
      res => {
        // this.businessNames = res || [];
        //  console.log(res);        
        this.streetResultObj = res.map(x => { return { key: x.systemCodeId, value: x.type }; });
        this.filteredStreetList = this.streetResultObj.slice();
      }, (error: any) => { }
    );

  }
  loadDocument() {
    debugger;
    if (this.prePlanDocumentId > 0) {

      this.prePlanService.getPrePlanDocument('getPrePlanDocument', this.prePlanDocumentId).subscribe(res => {
        this.prePlanModel = res;
        //this.uploadFile = res.uploadFile;
        this.prePlanFileExists = false;
        this.isFileSelected = true;
        if (this.prePlanModel.prePlanFileName != null && this.prePlanModel.prePlanFileName.length > 0) {
          this.prePlanFileExists = true;
        }
        debugger;
        console.log(res);
      },
        err => {
          console.log(err);
        });

    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }
    private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  deletePFile(prePlanId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '200px',
      data: { id: 1, name: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      debugger;
      if (result != undefined) {

        this.prePlanModel.prePlanFileDelete = true;
        this.prePlanModel.prePlanFileName = null;
        this.prePlanFileExists = false;
      }
    });
    return false;
  }
     
}

