import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import * as jspdf from 'jspdf';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
//import autoTable from 'jspdf-autotable'
import autoTable, { UserOptions } from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { Router, ActivatedRoute } from '@angular/router';
import { EventCalendar } from '../models/eventCalendar.model';
import { EventDptService } from '../services/eventdpt.service';
import { SystemCodeService } from '../services/system-code.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { GetAllEvent } from '../models/get-all-event.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { SystemCode } from '../models/system-codes.model';
import { GetAllEventDisplay } from '../models/get-all-eventdisplay.model';
//import { KeyValueTransformPipe } from '../common/pipes/key-value-transform.pipe';



@Component({
  selector: 'eventpdfgenerator',
  templateUrl: './eventPdfgenerator.component.html',
  styleUrls: ['./eventPdfgenerator.component.css']

})
export class EventPdfGenerator {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() eventCalendarModels: EventCalendar;
  @Output() toggleView: EventEmitter<void> = new EventEmitter<void>();

  eventdptModel: EventCalendar[] = [];
  isLogo: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;
  // eventCalendarModels: EventCalendar;
  eventTypeKey: SystemCodeKeyValue[] = [];
  eventTypes: SystemCode[] = [];
  periodicals: SystemCode[] = [];
  eventCalendarId: number;
  user: any;
  resultsLength: number = 0;
  hidepaginator: boolean = false;
  eventtypeString: string;
  dataSource: MatTableDataSource<EventCalendar>;
  //displayedColumns: any[] = ['eventType', 'fromDate', 'eventType'];
  columnsToDisplay: any[] = ['eventType', 'fromDate', 'toDate'];
  displayedColumns: any[] = ['eventTitle', 'fromDate', 'eventType'];
  dateTime: Date;
  constructor(
    private routerService: Router,
    private route: ActivatedRoute,
    private systemCodeService: SystemCodeService,
    private dialog: MatDialog,
    private authService: AuthService, private eventService: EventService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }
  ngOnInit() {

    this.loadDropDowns();
    this.dataSource = new MatTableDataSource(this.eventdptModel);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    let date: Date = new Date();
    this.dateTime = date;
  }

  toggleViewOnClick(): void {
    this.toggleView.emit();
  }

  initializeeventCalendarModel(): EventCalendar {
    return {
      eventCalendarId: 0, eventTitle: null, eventType: 0, fromDate: null, toDate: null, description: null,
      eventTimeRange: null, eventEndTimeRange: null, duration: 0, periodical: 0, dailyOption: 0,
      dailyOccurenceCount: 0, weeklyOccurrenceCount: 0,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      byMonthDayM: 0, byDayMData: null, byDayOnM1: 0, byDayM1Data: null, yearlyMonthM1: 0, byMonthDayY: 0, byDayYData: null, byDayOnY1: 0,
      byDayY1Data: null, yearlyMonthY1: 0, recurrenceFromDate: null, recurrenceToDate: null, rangeOccurrenceCount: 0,
      eventDate: null, eventInclude: null, excludeCheck: false, includeCheck: false, endDateCheck: false, repeatCheck: false
    } as EventCalendar;
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.blockUI.stop();
          return this.getAllEventDpt(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.blockUI.stop();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.eventdptModel = data || [];
        this.dataSource = new MatTableDataSource(this.eventdptModel);
        this.dataSource.sort = this.sort;
        //this.dataSource.paginator = this.paginator;
        this.blockUI.stop();
      });

  }

  getAllEventDpt(pageIndex: number, pageSize: number): Observable<GetAllEventDisplay> {
    return this.eventService.getAllEventDptNew(
      'GetEventDptNew',
      (this.eventCalendarModels.fromDate != null) ? this.eventCalendarModels.fromDate : null,
      (this.eventCalendarModels.toDate != null) ? this.eventCalendarModels.toDate : null,
      (this.eventCalendarModels.eventType) ? this.eventCalendarModels.eventType : 0,
      pageIndex,
      pageSize
    );
  }



  getAllEventDptForDataSource(): void {
    this.getAllEventDpt(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.eventdptModel = data || [];
        //this.dailylogModelsDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.eventdptModel);
        this.dataSource.sort = this.sort;
        this.blockUI.stop();
      });
  }

  downloadPDF() {
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 35;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF();
    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)

    var textWidth = doc.getStringUnitWidth('Department Event Report') * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text('Department Event Report', textOffset, 20)
    doc.setFontSize(10)
   
    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    if (this.eventCalendarModels.eventType) {
      if (count == 1) {
        yAxis = yAxis + 5;
        count = 0;
      }
      for (let i = 0; i < this.eventTypeKey.length; i++) {
        if (this.eventTypeKey[i].key == this.eventCalendarModels.eventType) {
          this.eventtypeString = this.eventTypeKey[i].value;
        }
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Event Type : ' + this.eventtypeString, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)      
      count++;
    }

    if (this.eventCalendarModels.fromDate) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      let startDateString = new Date(this.eventCalendarModels.fromDate);
      let currentStartDateString = (startDateString.getMonth() + 1).toString() + '/' + startDateString.getDate().toString() + '/' + startDateString.getFullYear().toString();
      
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Start Date   : ' + currentStartDateString, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }

    if (this.eventCalendarModels.toDate) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      let startDateString1 = new Date(this.eventCalendarModels.fromDate);
      let currentStartDateString1 = (startDateString1.getMonth() + 1).toString() + '/' + startDateString1.getDate().toString() + '/' + startDateString1.getFullYear().toString();

      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('End Date    : ' + currentStartDateString1, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }

    if (!this.checkPrintReportFilters()) {
      startYVariable = 35;
    } else {
      startYVariable = yAxis + 10;
    }

    autoTable(doc, {
      html: '#mytable',
      headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      bodyStyles: { minCellHeight: 8, fillColor: null },
      head: this.getHeadRows(),
     // body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 185, pageHeight - 10);

        var currentDate = new Date();
        var currentDateString = (currentDate.getMonth() + 1).toString() + '/' + currentDate.getDate().toString() + '/' + currentDate.getFullYear().toString();
        doc.text(currentDateString, 5, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    doc.save('Department_Event_Report.pdf');
  }

  checkPrintReportFilters(): boolean {
    if (this.eventCalendarModels.eventType || this.eventCalendarModels.fromDate || this.eventCalendarModels.toDate) {
      return true;
    }
    return false;
  }

  getHeadRows() {
    return [
      {
        eventType: 'Event Type', startDate: 'Start Date', endDate: 'End Date'
      }
    ]
  }

  //getBody() {
  //  let rowCount = this.eventdptModel.length;
  //  var body = []
  //  for (var j = 0; j < rowCount; j++) {
  //    body.push({
  //      eventType: this.eventdptModel[j].eventType,
  //      startDate: (this.eventdptModel[j].fromDate) ? this.eventdptModel[j].fromDate : 'N/A',
  //      endDate: (this.eventdptModel[j].toDate) ?  '12/20/2020' : 'N/A',        
  //    })
  //  }
  //  return body
  //}

  pdf3() {
    var doc = new jsPDF() as jsPDFWithPlugin;

    autoTable(doc, {
      html: '#mytable',
      headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      bodyStyles: { minCellHeight: 8, fillColor: null },
      margin: { top: 35 },
      //head:"Depar"
      didDrawPage: function (data) {
        // Header
        var base64Img;

        function imgToBase64(url, callback) {
          //if (!window.FileReader) {
          //  callback(null);
          //  return;
          //}
          
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              callback(reader.result.toString().replace('text/xml', 'image/jpeg'));
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', url);
          xhr.send();
        }

        base64Img = '/assets/Logo&Title.png'


        //imgToBase64("https://placehold.it/50x50"
        //  , function (base64) {
        //    base64Img = base64;
        //  });

        if (base64Img) {
          //doc.addImage(base64Img,)
          //doc.addImage(base64Img, 'png', data.settings.margin.top, 6, 60, 25);
          doc.addImage(base64Img, 'png', 76, 6, 50, 25);
        }
        //doc.text("Report", data.settings.margin.left + 15, 22);

        // Footer
        //var str = "Page " + doc.internal.getNumberOfPages()
        var str = "Page " + doc.getNumberOfPages();
        var totalPagesExp = doc.getPageInfo.length + doc.getNumberOfPages();
        //alert('totalpagesexp: ' + totalPagesExp);
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 185, pageHeight - 10);
        

        var currentDate = new Date();
        var currentDateString = currentDate.getMonth().toString() + '/' + currentDate.getDate().toString() + '/' + currentDate.getFullYear().toString();

        doc.text(currentDateString, 5, pageHeight - 10);
        //var pageSize = doc.internal.pageSize;
        //var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        //doc.text(str, data.settings.margin.left, pageHeight - 10);
      },

    });
    doc.save('table.pdf');
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllEventType(),
      this.systemCodeService.getAllRepeatsPeriod(),
    ).subscribe(([res1, res2]) => {
      this.eventTypes = res1 || [];
      this.periodicals = res2 || [];
      this.eventTypeKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  getFormattedDate(date: Date): string {
    return date.getMonth().toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString();
  }

}



interface jsPDFWithPlugin extends jsPDF {
  autoTableHtmlToJson(elem: HTMLElement);
  autoTable: (options: UserOptions) => jsPDF;
}
