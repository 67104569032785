import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable, forkJoin, of } from 'rxjs';
import { CompanyTraining } from '../models/companytraining.model';
import { SystemCodeService } from '../services/system-code.service';
import { SystemCode } from '../models/system-codes.model';
import { AuthService } from '../services/auth.service';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { CompanyTrainingService } from '../services/companytraining.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ModelDialogContent } from '../common/model-dialog/model-dialog-content';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee.model';
import { switchMap, map, startWith, catchError } from 'rxjs/operators';
import { GetAllCompanyTraining } from '../models/get-all-companyTraining.model';
import { GlobalConstants } from '../common/global-constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { CompanyTrainingPrintReportFilter } from '../models/company-training-print-report-filters.model';
import { AngularCsv } from 'angular7-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import autoTable, { UserOptions } from 'jspdf-autotable'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';

interface EmployeeFilter {
  employeeId: number;
  name: string;
}

@Component({
  selector: 'companytrainingreport',
  templateUrl: './companytrainingreport.component.html',
  styleUrls: ['./companytrainingreport.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class CompanyTrainigReportComponent {
  @ViewChild('companyTrainingForm', { static: false }) companyTrainingForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  employeesList: Employee[] = [];
  emstrainingObj: CompanyTraining;
  companyTrainingPrintReportFilters: CompanyTrainingPrintReportFilter;
  crew: SystemCode[] = [];
  emsCategory: SystemCode[] = [];
  station: SystemCode[] = [];
  emstrainingReportList: CompanyTraining[] = [];
  emsCategoryList: SystemCode[] = [];
  stopDate: null;
  employeeNames: string[] = [];
  trainingTypes: SystemCode[] = [];
  employee: EmployeeFilter;
  sortid: MatSort;
  employeeResutObj: Employee[] = [];
  public filteredList5: Employee[] = [];
  resultsLength: number = 0;
  fontName: string = GlobalConstants.fontName;
  fontSize: number = GlobalConstants.fontSize;
  headSize: number = GlobalConstants.headingSize;
  footerFontSize: number = GlobalConstants.footerFontSize;
  @BlockUI() blockUI: NgBlockUI;
  //assignmentObjDB = new MatTableDataSource<CompanyTraining>([]);
  dataSource: MatTableDataSource<CompanyTraining>;
  paginatorid: MatPaginator;  
  //displayedColumns: any[] = ['className', 'startDate', 'emsCategoryName', 'instructorName', 'trainingHours', 'crewName', 'stationName', 'noofstud'];
  displayedColumns: any[] = ['instructorName', 'kemsisNumber', 'finNumber', 'className', 'students', 'emsCategoryName', 'trainingDate', 'startTime', 'endTime', 'trainingHours'];
  startMaxDate: Date;
  endMinDate: Date;
  rolePermissionCheckModel: RolePermissionCheck;
  //isViewReport: boolean = false;
  endDateDisable: boolean = false;
  isInitialLoad: boolean = true;
  floatLabel: string = 'never';
  companyTrainings: Array<CompanyTraining> = [];
  totalHours: number = 0;
  instructors: Employee[] = [];

  constructor(
    private dialog: MatDialog, private routerService: Router,
    private route: ActivatedRoute, private systemCodeStateService: SystemCodeService,
    private authService: AuthService, private companyTrainingService: CompanyTrainingService,
    private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.emstrainingObj = this.initializeCompanyTrainingModel();
      this.companyTrainingPrintReportFilters = this.initializeCompanyTrainingPrintReportFilters();
      this.dataSource = new MatTableDataSource(this.emstrainingReportList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loadDropDowns();
      this.startMaxDate = new Date("12/30/2050");
      this.endMinDate = new Date("12/30/1900");
      this.loadGrid();
      //this.dataSource.sort = this.sort;
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  /*********************************** */
  ngAfterViewInit() {
    this.InitialLoad();
  }

  InitialLoad() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.blockUI.start();
            return this.getAllCompanyTraining(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.emstrainingReportList = data || [];
          //this.assignmentObjDB.data = data || [];
          this.dataSource = new MatTableDataSource(this.emstrainingReportList);
          this.dataSource.sort = this.sort;
          this.blockUI.stop();
        });
    }
  }

  getAllCompanyTraining(pageIndex: number, pageSize: number): Observable<GetAllCompanyTraining> {
    return this.companyTrainingService.getAllCompanyTrainingNew(
      'GetCompanyTrainingNew',
      (this.emstrainingObj.trainingType) ? this.emstrainingObj.trainingType : 0,
      (this.emstrainingObj.instructor) ? this.emstrainingObj.instructor : 0,
      (this.emstrainingObj.startDate != null) ? this.emstrainingObj.startDate : null,
      (this.emstrainingObj.category) ? this.emstrainingObj.category : 0,
      (this.emstrainingObj.crew) ? this.emstrainingObj.crew : 0,
      (this.emstrainingObj.station) ? this.emstrainingObj.station : 0,
      (this.emstrainingObj.endDate != null) ? this.emstrainingObj.endDate : null,
      (this.employee != undefined) ? this.employee.employeeId : 0,
      pageIndex,
      pageSize
    );
  }

  getAllCompanyTrainingForDataSource(): void {
    this.blockUI.start();
    this.getAllCompanyTraining(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.emstrainingReportList = data || [];
        //this.assignmentObjDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.emstrainingReportList);
        this.blockUI.stop();
        this.dataSource.sort = this.sort;
      });
  }

  /*********************************** */

  initializeCompanyTrainingModel(): CompanyTraining {
    return {
      trainingType: 0,
      companyTrainingId: 0,
      className: null,
      startDate: null,
      endDate: null,
      instructor: 0,
      trainingHours: null,
      crew: 0,
      station: 0,
      category: 0,
      numberOfStudents: 0,
      crewName: null,
      emsCategoryName: null,
      instructorName: null,
      stationName: null,
      employeeId: null
    } as CompanyTraining;
  }

  initializeCompanyTrainingPrintReportFilters(): CompanyTrainingPrintReportFilter {
    return {
      trainingType: null,
      employeeName: null,
      category: null,
      crewName: null,
      stationName: null,
      startDate: null,
      endDate: null
    } as CompanyTrainingPrintReportFilter;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForCompanyTrainingCrews(),
      this.systemCodeStateService.getSystemCodesForEmscategory('1'),
      this.systemCodeStateService.getSystemCodesForCompanyTrainingStations(),
      this.systemCodeStateService.getSystemCodesForTrainingTypes()
    ).subscribe(([res1, res2, res3, res4]) => {
      this.crew = res1 || [];
      this.emsCategory = res2 || [];
      this.station = res3 || [];
      this.trainingTypes = res4 || [];
    });
  }

  loadEmployeeInstructor(type: any) {
    this.blockUI.start();
    this.employeeService.getAllEmployeesInstructor('GetEmployeesInstructor', type).subscribe(res => {
      this.instructors = res || [];
      this.blockUI.stop();
    }, err => {
      this.blockUI.stop();
    });
  }

  searchReport() {
    this.isInitialLoad = false;
    this.getAllCompanyTrainingForDataSource();
  }

  resetCompanyTraining() {
    this.isInitialLoad = true;
    this.instructors = [];
    this.companyTrainingForm.resetForm();
    this.emstrainingObj = this.initializeCompanyTrainingModel();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.startMaxDate = null;
    this.endMinDate = null;
    this.companyTrainings = [];
    this.getAllCompanyTrainingForDataSource();
  }

  onTrainingTypeChange(event: any) {
    let str: string = event.source.selected.viewValue.toString();
    let type = str.substring(19, 22).toLocaleLowerCase();
    this.loadEmployeeInstructor(event.value);
    if (type == "ems") {//(event.value == this.emsCompanyTraining) {//"EMS Company Training"
      this.loadEMSCategory();
      this.systemCodeStateService.getSystemCodesForEMSCompanyTrainingCrews().subscribe(
        (res) => { this.crew = res || []; }
      );
      this.systemCodeStateService.getSystemCodesForEMSCompanyTrainingStations().subscribe(
        (res) => {
          this.station = res || [];
        });
    } else {
      this.systemCodeStateService.getAllCourseCode('1').subscribe((res: Array<SystemCode>) => {
        this.emsCategory = res || [];
      }, (error: any) => { }
      );
      this.systemCodeStateService.getSystemCodesForCompanyTrainingCrews().subscribe((res) => { this.crew = res || []; });
      this.systemCodeStateService.getSystemCodesForCompanyTrainingStations().subscribe((res) => { this.station = res || []; });
    }
  }

  public loadEMSCategory() {
    this.systemCodeStateService.getSystemCodesForEmscategory('1').subscribe((res: Array<SystemCode>) => {
      this.emsCategory = res || [];
    }, (error: any) => { }
    );
  }

  deleteCompanyTraining(eId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        // this.employeeObj.employeeId = parseInt( eId);
        this.companyTrainingService.delCompanyTraining('delCompanyTraining', parseInt(eId)).subscribe(res => {
          this.getAllCompanyTrainingForDataSource();
          // this.trainingReport();
          // this.InitialLoad();
        });
      }
    });
    return false;
  }

  openPopup(companyTrainingId: number) {
    this.companyTrainingService.getCompanyTraining('getCompanyTraining', companyTrainingId).subscribe(res => {
      this.employeeNames = res.employeeNames;
      this.openDialog(this.employeeNames);
    });
  }

  openDialog(nameList: any) {
    const dialogRef = this.dialog.open(ModelDialogContent, {
      data: {
        names: nameList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployeesFromCompanyTraining', 0).subscribe(res => {
      let empObj: any = res;
      this.employeeResutObj = empObj.map(x => { return { employeeId: x.employeeId, value: x.lastName + ', ' + x.firstName }; });
      this.employeesList = empObj;
      this.filteredList5 = this.employeeResutObj.slice();
    }, (err: any) => { }
    );
  }

  startDateChange(dt: Date) {
    this.endMinDate = new Date(dt);
    this.endDateDisable = true;
    if (dt == null) {
      this.emstrainingObj.endDate = null;
      this.endDateDisable = false;
    }
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  editTraining(companyId: number) {
    const index = this.emstrainingReportList.findIndex(x => x.companyTrainingId == companyId);
    if (index >= 0) {
      let item = this.emstrainingReportList[index];
      if (item.typeName != null) {
        let str = item.typeName.substring(19, 22).toLocaleLowerCase();
        this.routerService.navigate(['/companytraining', { id: item.companyTrainingId, type: item.trainingType, name: str }]);
      }
    }
  }

  showCompanyTrainingReport(): void {
    this.totalHours = 0;
    this.blockUI.start();
    this.companyTrainingService.getAllCompanyTrainingsForReportView(
      'GetAllCompanyTrainingsForReportView',
      (this.emstrainingObj.trainingType) ? this.emstrainingObj.trainingType : 0,
      (this.emstrainingObj.instructor) ? this.emstrainingObj.instructor : 0,
      (this.emstrainingObj.startDate != null) ? this.emstrainingObj.startDate : null,
      (this.emstrainingObj.category) ? this.emstrainingObj.category : 0,
      (this.emstrainingObj.crew) ? this.emstrainingObj.crew : 0,
      (this.emstrainingObj.station) ? this.emstrainingObj.station : 0,
      (this.emstrainingObj.endDate != null) ? this.emstrainingObj.endDate : null,
      (this.employee != undefined) ? this.employee.employeeId : 0
    ).subscribe((res: Array<CompanyTraining>) => {
      this.companyTrainings = res;
      this.getTotal();
      this.blockUI.stop();
      this.setPDF();
    }, (error: any) => {
      this.companyTrainings = [];
      this.blockUI.stop();
    });
  }

  setPDF(): void {
    let yAxis: number = 30;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setFont(this.fontName);
    let pdfTitle = 'Company/EMS Company Training Report';
    let footerSize = this.footerFontSize;
    let trainingTypeEntity = this.trainingTypes.find(x => x.systemCodeId === this.emstrainingObj.trainingType);
    if (trainingTypeEntity) {
      pdfTitle = trainingTypeEntity.type + ' Report';
    }
    var pageno = 0;
    var nooftime = 0;
    var pagetotal = 0;
    var overalltotal = this.totalHours;
    doc.setProperties({
      title: pdfTitle
    });

    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(this.headSize)
    var textWidth = doc.getStringUnitWidth(pdfTitle) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(pdfTitle, textOffset, 20)
    doc.setFontSize(this.fontSize)

    //Setting the Filter to display on the PDF
    //Training Type Filter Code Begin
    let trainingType = 'Training Type: ';
    if (trainingTypeEntity) {
      trainingType = trainingType + trainingTypeEntity.type;
    }    
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(trainingType, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Training Type Filter Code End

    //Instructor Name Filter Code Begin
    let instructor = 'Instructor: ';
    if (this.instructors.length > 0) {
      let instructorEntity = this.instructors.find(x => x.employeeId == this.emstrainingObj.instructor);
      if (instructorEntity) {
        instructor = instructor + instructorEntity.lastName + ', ' + instructorEntity.firstName;
      }
    }    
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(instructor, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Instructor Name Filter Code End

    //Employee Name Filter Code Begin
    let employeeName = 'Employee Name: ';
    if (this.employee) {
      let employeeEntity = this.employeesList.find(x => x.employeeId == this.employee.employeeId);
      if (employeeEntity) {
        employeeName = employeeName + employeeEntity.lastName + ', ' + employeeEntity.firstName;
      }
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(employeeName, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Employee Name Filter Code End

    //Category Filter Code Begin
    let category = 'Category: ';
    let categoryEntity = this.emsCategory.find(x => x.systemCodeId == this.emstrainingObj.category);
    if (categoryEntity) {
      category = category + categoryEntity.type;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(category, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Category Filter Code End

    //Crew and Station Filter Code Begin
    let crew = 'Crew: ';
    let crewEntity = this.crew.find(x => x.systemCodeId == this.emstrainingObj.crew);
    if (crewEntity) {
      crew = crew + crewEntity.type;
    }
    let station = 'Station: ';
    let stationEntity = this.station.find(x => x.systemCodeId == this.emstrainingObj.station);
    if (stationEntity) {
      station = station + stationEntity.type;
    }
    let textCrewStation = crew + '   ' + station;
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Crew and Station Filter Code End

    //Start Date and End Date Filter Code Begin
    let startDate = 'Start Date: ';
    if (this.emstrainingObj.startDate) {
      let startDateString = new Date(this.emstrainingObj.startDate);
      let startDateFormat = this.getDateAsString(startDateString);
      startDate = startDate + startDateFormat;
    }
    let endDate = 'End Date: ';
    if (this.emstrainingObj.endDate) {
      let endDateString = new Date(this.emstrainingObj.endDate);
      let endDateFormat = this.getDateAsString(endDateString);
      endDate = endDate + endDateFormat;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Start Date and End Date Filter Code End

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        startTime: {
          cellWidth: 20,
          halign: 'right'
        },
        endTime: {
          cellWidth: 20,
          halign: 'right'
        },
        hours: {
          halign: 'right'
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: this.fontName, fontStyle: 'bold', fontSize: this.fontSize },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: this.fontName, fontSize: this.fontSize },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(footerSize);

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

        doc.text(str, 130, pageHeight - 10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width

        let totpageval = pagetotal.toString();
        var pagetotalArr = pagetotal.toString().split(".");
        // If there is no decimal point or only one decimal place found.
        if (pagetotalArr.length == 1 || pagetotalArr[1].length < 3) {
          // Set the number to two decimal places
          totpageval = pagetotal.toFixed(2);
        }

        let alltotpageval = overalltotal.toString();
        var alltotalArr = overalltotal.toString().split(".");
        // If there is no decimal point or only one decimal place found.
        if (alltotalArr.length == 1 || alltotalArr[1].length < 3) {
          // Set the number to two decimal places
          alltotpageval = overalltotal.toFixed(2);
        }

        doc.text('Total - ' + totpageval + ' / ' + alltotpageval, 235, pageHeight - 10);
        //doc.text(str, 250, pageHeight - 10);

        let currentDate = new Date();
        let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
        let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
        let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
        doc.text(currentDateString, 15, pageHeight - 10);
      },
      didDrawCell: function (data) {
        var str = 'Page ' + doc.getNumberOfPages();
        if (pageno != doc.getNumberOfPages()) {
          pageno = doc.getNumberOfPages();
          pagetotal = 0;
        }

        if (nooftime == 9) {
          if (data.row.cells[9].raw != "Hours") {
            if (data.row.cells[9].raw != null && data.row.cells[9].raw.toString() != "") {
              pagetotal = pagetotal + parseFloat(data.row.cells[9].raw.toString());
            }
          }
          nooftime = 0;
        } else {
          nooftime = nooftime + 1;
        }

        //if (this.pageNo != "") {
        //  this.pageNo = doc.getNumberOfPages();
        //}
        //else if (this.pageNo != doc.getNumberOfPages()) {
        //  this.pageNo = doc.getNumberOfPages();
        //}
        //else {
        //var pageSize = doc.internal.pageSize;
        //var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        //doc.text('52/480', 250, pageHeight - 10);
        //}
        //if (data.cell.raw == '') {
        //}
      },
      startY: yAxis
    });

    //Start Date and End Date Filter Code Begin
    //Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }
    //Opens the PDF preview in a new window
    window.open(URL.createObjectURL(doc.output("blob")));
  }

  drawCell(data: any) { }

  getDateAsString(dt: Date): string {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  addZeroes(num): any {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length == 1 || res[1].length < 3) {
      // Set the number to two decimal places
      let val = value.toFixed(2);
      return val;
    }
    // Return updated or original number.
    return value;
  }

  getHeadRows() {
    return [
      {
        instructor: 'Instructor Name', kemsisNumber: 'KEMSIS #', finNumber: 'FIN #', className: 'Class Name', students: 'Students', trainingCodes: 'Training Codes',
        trainingDate: 'Training Date', startTime: 'Start Time', endTime: 'End Time', hours: 'Hours'
      }
    ]
  }

  getBody() {
    let rowCount = this.companyTrainings.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.companyTrainings[j].startDate;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }
      body.push({
        instructor: this.companyTrainings[j].instructorName,
        kemsisNumber: (this.companyTrainings[j].leadInstructorKemisNumber) ? this.companyTrainings[j].leadInstructorKemisNumber : '',
        finNumber: (this.companyTrainings[j].leadInstructorFFNNumber) ? this.companyTrainings[j].leadInstructorFFNNumber : '',
        className: (this.companyTrainings[j].className) ? this.companyTrainings[j].className : '',
        trainingDate: (this.companyTrainings[j].startDate) ? actionDateStringFormat : '',
        students: this.companyTrainings[j].companyTrainingEmployeeNames,
        trainingCodes: this.companyTrainings[j].emsCategoryName,
        hours: this.companyTrainings[j].trainingHours,
        startTime: this.companyTrainings[j].startTime,
        endTime: this.companyTrainings[j].endTime
      });
    }
    return body
  }

  exportCompanyTrainingReportasCSV(): void {
    this.blockUI.start();
    this.companyTrainingService.getAllCompanyTrainingsForReportView(
      'GetAllCompanyTrainingsForReportView',
      (this.emstrainingObj.trainingType) ? this.emstrainingObj.trainingType : 0,
      (this.emstrainingObj.instructor) ? this.emstrainingObj.instructor : 0,
      (this.emstrainingObj.startDate != null) ? this.emstrainingObj.startDate : null,
      (this.emstrainingObj.category) ? this.emstrainingObj.category : 0,
      (this.emstrainingObj.crew) ? this.emstrainingObj.crew : 0,
      (this.emstrainingObj.station) ? this.emstrainingObj.station : 0,
      (this.emstrainingObj.endDate != null) ? this.emstrainingObj.endDate : null,
      (this.employee != undefined) ? this.employee.employeeId : 0
    ).subscribe((res: Array<CompanyTraining>) => {
      this.companyTrainings = res;
      this.blockUI.stop();
      this.exportToCSV();
    }, (error: any) => {
      this.companyTrainings = [];
      this.blockUI.stop();
    });
  }

  exportToCSV() {
    let rowCount = this.companyTrainings.length;
    var data = []
    data.push({
      instructor: 'Instructor Name', kemsisNumber: 'KEMSIS #', finNumber: 'FIN #', className: 'Class Name',
      students: 'Students', trainingCodes: 'Training Codes', trainingDate: 'Training Date', startTime: 'Start Time', endTime: 'End Time',
      hours: 'Hours'
    });

    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.companyTrainings[j].startDate;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }
      data.push({
        instructor: this.companyTrainings[j].instructorName,
        kemsisNumber: (this.companyTrainings[j].leadInstructorKemisNumber) ? this.companyTrainings[j].leadInstructorKemisNumber : '',
        finNumber: (this.companyTrainings[j].leadInstructorFFNNumber) ? this.companyTrainings[j].leadInstructorFFNNumber : '',
        className: (this.companyTrainings[j].className) ? this.companyTrainings[j].className : '',
        students: this.companyTrainings[j].companyTrainingEmployeeNames,
        trainingCodes: this.companyTrainings[j].emsCategoryName,
        trainingDate: (this.companyTrainings[j].startDate) ? actionDateStringFormat : '',
        startTime: this.companyTrainings[j].startTime,
        endTime: this.companyTrainings[j].endTime,
        hours: this.companyTrainings[j].trainingHours
      });
    }
    new AngularCsv(data, 'Company Training Report');
    //return body
  }

  getTotal() {
    let rowCount = this.companyTrainings.length;
    for (var j = 0; j < rowCount; j++) {
      if (this.companyTrainings[j].trainingHours) {
        this.totalHours = this.totalHours + parseFloat(this.companyTrainings[j].trainingHours);
      }
    }
  }
}
