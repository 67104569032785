import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { User } from "../../models/user.model";
import { LoginModel } from "../../models/login.model";
import { HeaderService } from "../../services/header.service";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { of } from "rxjs";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'ffd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginForm', { static: false }) loginTypeForm: NgForm;
  username: string = "";
  password: string = "";
  loginModel: LoginModel;
  usersModel: User;
  userId: number;
  isLoadingResults: boolean = false;
  floatLabel: string = 'always';

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginModel = { id: 0, username: '', auth_token: '', message: '', isinvalid: false };
  }

  clickLogin(): void {
    this.login();
  }

  login(): void {
    if (this.loginTypeForm.valid) {
      this.isLoadingResults = true;
      this.loginService.getLogin(this.username, this.password).subscribe(res => {
        this.loginModel = res;
        this.isLoadingResults = false;
        if (!this.loginModel.isinvalid) {
          this.authService.setUserToken(this.loginModel.auth_token);
          let user = this.authService.getUser();
          if (user) {
            if (user.isforceresetpassword == 'true') {
              this.router.navigate(['myaccount']);
            } else {
              this.router.navigate(['home']);
            }            
          }
        }
      }, err => {
        this.isLoadingResults = false;
      });
    } else {
      const controls = this.loginTypeForm.controls;
      Object.keys(controls).forEach(control => controls[control].markAsTouched());
      return;
    }
  }
}
