import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CKEditorModule } from 'ng2-ckeditor';
//import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CreateEmployeeComponent } from './employee/createemployee.component';
import { EmployeeViewComponent } from './employee/employeeview.component';
import { EmployeeCertificationComponent } from './certifications/employeecertification.component';
import { EmployeeEmgContactComponent } from './employee/employeeemgcontact.component';
import { CreateOccupancyComponent } from './occupancy/createeoccupancy.component';
import { OccupancyReportComponent } from './occupancy/occupancyreport.component';
import { DepartmentUpdateFormComponent } from './forms/departmentupdateform.component';
import { DeptUpdateReportComponent } from './reports/deptupdatereport.component';
import { AwardNominationComponent } from './award-nomination/awardnomination.component';
import { CompanyTrainingComponent } from './company-training/companytraining.component';
import { PPEInspectionFormComponent } from './forms/ppeinspectionform.component';
import { PPEInspectionReportComponent } from './reports/ppeinspectionreport.component';
import { PPEInspectionViewComponent } from './reports/ppeinspectionview.component';
import { GearRequestComponent } from './forms/gearrequestform.component';
import { TradeTimeReportComponent } from './reports/tradetimereport.component';
import { AwardNominationReportComponent } from './award-nomination/awardnominationreport.component';
import { CompanyTrainigReportComponent } from './company-training/companytrainingreport.component';
import { InspectionComponent } from './others/inspection.component';
import { InspectionReportComponent } from './others/inspectionReport.component';
import { companytrainingViewComponent } from './company-training/companytrainingview.component';
import { companytrainingPrintComponent } from './company-training/companytrainingprint.component';
import { AwardNominationViewComponent } from './award-nomination/awardnominationview.component';
import { AwardNominationPrintComponent } from './award-nomination/awardnominationprint.component';
import { DashBoardComponent } from './calendar/dashboard.component';
import { InspectionCalendarComponent } from './calendar/inspectionCalendar.component';
import { ReinspectionCalendarComponent } from './calendar/reinspectionCalendar.component';
import { EmergencyContactViewComponent } from './employee/emergencycontactview.component';
import { DeptUpdateViewComponent } from './reports/deptupdateview.component';
import { DeptUpdatePrintComponent } from './reports/deptupdateprint.component';
import { BlockUIModule } from 'ng-block-ui';
import { NgxPrintModule } from 'ngx-print';
import 'hammerjs';
import { ErrorService } from './services/error.service';
import { SystemCodeService } from './services/system-code.service';
import { AuthService } from './services/auth.service';
import { HeaderService } from './services/header.service';
import { ErrorDialogComponent } from './common/error-dialog/error-dialog.component';
import { SaveDialogComponent } from './common/saved-dialog/save-dialog.component';
import { DeleteDialogComponent } from './common/delete-dialog/delete-dialog.component';
import { PhoneNumberPipe } from './common/pipes/phone-number.pipe';
import { ZipCodePipe } from './common/pipes/zipcode.pipe';
import { Maintenancerequestform } from './maintanence-request/maintenancerequestform.component';
import { MaintenanceReport } from './maintanence-request/maintenanceReport.component';
import { MaintanencerequestView } from './maintanence-request/maintanencerequestview.component';
import { MaintanencerequestPrint } from './maintanence-request/maintanencerequestprint.component';
import { MaintanenceRequestHistoryView } from './maintanence-request/maintanencerequesthistoryview.component';
import { StRoasterComponent } from './others/stroaster.component';
import { StRoasterReportComponent } from './dailyroaster/stroasterreport.component';
import { StRoasterUpdateComponent } from './others/stroasterupdate.component';
import { KeyValueTransformPipe } from './common/pipes/key-value-transform.pipe';
import { DocumentLibraryComponent } from './document-library/documentlibrary.component';
import { EventDepartmentComponent } from './employee/eventdepartment.component';
import { EventReportComponent } from './reports/eventreport.component';
import { EventUpdateComponent } from './reports/eventupdate.component';
import { CompanyTrainingUpdateComponent } from './company-training/companytrainingupdate.component';
import { InspectionPrintComponent } from './others/inspectionprint.component';
import { InspectionViewComponent } from './others/inspectionview.component';
import { MatSortModule } from '@angular/material/sort';
import { DailyLogComponent } from './employee/dailylog.component';
import { DailyLogReportComponent } from './reports/dailylogreport.component';
import { ModelDialogContent } from './common/model-dialog/model-dialog-content';
import { FireRunDialogContent } from './common/model-dialog/fireRun-dialog-content';
import { EmsRunDialogContent } from './common/model-dialog/emsRun-dialog-content';
import { ApparatusDialogContent } from './common/model-dialog/apparatus-dialog-content';
import { RosterAssignmentDialogContent } from './dailyroaster/dailyroasterassignment.component';
import { DeleteAssignmentComponent } from './dailyroaster/deleteassignment.component';
import { DeleteCertificationComponent } from './certifications/deletecertification.component';
import { DeleteUpdationComponent } from './document-library/deleteupdation.component';
import { WaterUsageForm } from './waterusage/createwaterusageform.component';
import { WaterUsageReport } from './waterusage/waterusagereport.component';
import { WaterUsageReportView } from './waterusage/waterusagereportview.component';
import { WaterUsageReportPrint } from './waterusage/waterusagereportprint.component';
import { VacationPicsComponent } from './others/vacationpics.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { UploadDocumentComponent } from './document-library/uploaddocument.component';
import { CertificationUpdateComponent } from './certifications/certifictionupdate.component';
import { EmsTrainingHoursForm } from './others/emstraininghours.component';
import { PrePlanComponent } from './others/preplan.component';
import { PrePlanReportComponent } from './reports/preplanreport.component';
import { CertificatePdfGenerator } from './certifications/certificatePdfgenerator.component';
import { EMSTrainingHoursReport } from './others/emstraininghoursreport.component';
import { DailyRoasterComponent } from './dailyroaster/dailyroaster.component';
import { DatePipe } from '@angular/common'
import { UserComponent } from '../app/administration/users/user_component/user.component';
import { ExistingUserNameValidatorDirective } from '../app/common/directives/usernameexists.directive';
import { CompareFieldsValidatorDirective } from '../app/common/directives/compare-fields-validator.directive';
import { LoginComponent } from '../app/administration/login/login.component';
import { EventCalendarComponent } from './calendar/eventcalendar.component';
import { EventComponent } from './employee/event.component';
import { EventService } from './services/event.service';
import { RoleComponent } from '../app/administration/roles/role.component';
import { UserRoleAuthGuardService } from './services/user-role-auth-guard.service';
import { RolePermissionsResolverService } from './services/role-permissions-resolver.service';
import { DailyRoasterView } from './dailyroaster/dailyroasterview.component';
import { EventPdfGenerator } from './reports/eventPdfgenerator.component';
import { ResponseReport } from './reports/responsereport.component';
import { RoasterReportView } from './dailyroaster/roasterreportview.component';
import { CompanyTrainingReportViewComponent } from '../app/report-views/company-training-report-view/company-training-report-view.component';
import { EmployeeEmergencyViewReport } from './reports/employeeEmergencycontactdetails.component';
import { TimeSheetReportComponent } from './reports/timesheetreport.component';
import { ShaunTimeSheetComponent } from './reports/shauntimesheet.component';
import { GenaPrintReportComponent } from './reports/genaprintreport.component';
import { TradeTimeViewComponent } from './reports/tradetimeviewsheet.component';
import { MyProfile } from './home/myProfile.component';
import { AccountComponent } from './home/account.component';
import { CertificationPopUpComponent } from './certifications/certificationPopUp.component';
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CreateEmployeeComponent,
    EmployeeViewComponent,
    Maintenancerequestform,
    DashBoardComponent,
    EmployeeCertificationComponent,
    EmployeeEmgContactComponent,
    CreateOccupancyComponent,
    OccupancyReportComponent,
    DepartmentUpdateFormComponent,
    DeptUpdateReportComponent,
    AwardNominationComponent,
    AwardNominationReportComponent,
    CompanyTrainigReportComponent,
    InspectionComponent, InspectionReportComponent,
    AwardNominationViewComponent,
    AwardNominationPrintComponent,
    ErrorDialogComponent, SaveDialogComponent,
    DeleteDialogComponent,
    PhoneNumberPipe,
    ZipCodePipe,
    DocumentLibraryComponent,
    EventDepartmentComponent,
    EventReportComponent,
    EventUpdateComponent,
    InspectionCalendarComponent,
    ReinspectionCalendarComponent,
    DeptUpdateViewComponent,
    DeptUpdatePrintComponent,
    EmergencyContactViewComponent,
    DocumentLibraryComponent,
    EventUpdateComponent,
    EventDepartmentComponent,
    EventReportComponent,
    StRoasterComponent,
    StRoasterReportComponent,
    StRoasterUpdateComponent,
    CompanyTrainingComponent,
    CompanyTrainingUpdateComponent,
    MaintenanceReport,
    MaintanencerequestView,
    MaintanencerequestPrint,
    MaintanenceRequestHistoryView,
    InspectionPrintComponent,
    InspectionViewComponent,
    companytrainingViewComponent,
    companytrainingPrintComponent,
    PPEInspectionFormComponent,
    PPEInspectionReportComponent,
    PPEInspectionViewComponent,
    GearRequestComponent,
    KeyValueTransformPipe,
    DailyLogComponent,
    DailyLogReportComponent,
    ModelDialogContent,
    EmsRunDialogContent,
    FireRunDialogContent,
    ApparatusDialogContent,
    RosterAssignmentDialogContent,
    DeleteAssignmentComponent,
    TradeTimeReportComponent,
    WaterUsageForm,
    WaterUsageReport,
    WaterUsageReportView,
    WaterUsageReportPrint,
    UploadDocumentComponent,
    CertificationUpdateComponent,
    EmsTrainingHoursForm,
    VacationPicsComponent,
    PrePlanComponent,
    PrePlanReportComponent,
    CertificatePdfGenerator,
    EMSTrainingHoursReport,
    DailyRoasterComponent,
    UserComponent,
    ExistingUserNameValidatorDirective,
    CompareFieldsValidatorDirective,
    LoginComponent,
    EventCalendarComponent,
    EventComponent,
    LoginComponent,
    RoleComponent,
    DailyRoasterView,
    EventPdfGenerator,
    RoasterReportView,
    CompanyTrainingReportViewComponent,
    ResponseReport,
    EmployeeEmergencyViewReport,
    TimeSheetReportComponent,
    ShaunTimeSheetComponent,
    GenaPrintReportComponent,
    TradeTimeViewComponent,
    MyProfile,
    AccountComponent,
    CertificationPopUpComponent,
    DeleteCertificationComponent,
    DeleteUpdationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    AngularMaterialModule,
    // NgxMaterialTimepickerModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    HttpClientModule,
    ReactiveFormsModule,
    CKEditorModule,
    MatSortModule,
    NgxPrintModule,
    FormsModule,
    MatSelectFilterModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'home', component: HomeComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'Dashboard' } },
      { path: 'createemployee', component: CreateEmployeeComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'EmployeeInfoForm' } },
      { path: 'createemployee/:id', component: CreateEmployeeComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'EmployeeInfoForm' } },
      { path: 'employeeview', component: EmployeeViewComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'EmployeeInfoForm' } },
      { path: 'dashboard', component: DashBoardComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'employeecertification', component: EmployeeCertificationComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'EmployeeCertificationsForm' } },
      { path: 'employeeemgcontact', component: EmployeeEmgContactComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'createoccupancy', component: CreateOccupancyComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'OccupancyForm' } },
      { path: 'occupancyreport', component: OccupancyReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'OccupancyForm' } },
      { path: 'departmentupdateform', component: DepartmentUpdateFormComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentUpdateForm' } },
      { path: 'deptupdatereport', component: DeptUpdateReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentUpdateForm' } },
      { path: 'deptupdateview', component: DeptUpdateViewComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentUpdateForm' } },
      { path: 'deptupdateprint', component: DeptUpdatePrintComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentUpdateForm' } },
      { path: 'awardnomination', component: AwardNominationComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'AwardNominationForm' } },
      { path: 'companytraining', component: CompanyTrainingComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CompanyTrainingForm' } },
      { path: 'awardnominationreport', component: AwardNominationReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'AwardNominationForm' } },
      { path: 'maintenancerequestform', component: Maintenancerequestform, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MaintenanceRequestForm' } },
      { path: 'maintenancerequestform/:id', component: Maintenancerequestform, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MaintenanceRequestForm' } },
      { path: 'awardnominationview', component: AwardNominationViewComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'AwardNominationForm' } },
      //{ path: 'inspection', component: InspectionComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'inspection/:id', component: InspectionComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'inspectionreport', component: InspectionReportComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'awardnominationprint', component: AwardNominationPrintComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'AwardNominationForm' } },
      { path: 'maintenanceReport', component: MaintenanceReport, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MaintenanceRequestForm' } },
      { path: 'maintanencerequestview', component: MaintanencerequestView, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MaintenanceRequestForm' } },
      { path: 'maintanencerequestprint', component: MaintanencerequestPrint, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MaintenanceRequestForm' } },
      { path: 'maintanencerequesthistoryview', component: MaintanenceRequestHistoryView, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MaintenanceRequestForm' } },
      { path: 'documentlibrary', component: DocumentLibraryComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DocumentLibraryForm' } },
      { path: 'uploaddocument', component: UploadDocumentComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DocumentLibraryForm' } },
      //{ path: 'inspectioncalendar', component: InspectionCalendarComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'reinspectioncalendar', component: ReinspectionCalendarComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'deptupdateview', component: DeptUpdateViewComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'deptupdateprint', component: DeptUpdatePrintComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'event', component: EventDepartmentComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'event/:id', component: EventDepartmentComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'eventupdate', component: EventUpdateComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'companytrainingreport', component: CompanyTrainigReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CompanyTrainingForm' } },
      { path: 'companytrainingupdate', component: CompanyTrainingUpdateComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CompanyTrainingForm' } },
      //{ path: 'stroaster', component: StRoasterComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'emergencyview', component: EmergencyContactViewComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'stroaster/:id', component: StRoasterComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'stroasterreport', component: StRoasterReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DailyRosterForm' } },
      { path: 'stroasterupdate', component: StRoasterUpdateComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DailyRosterForm' } },
      { path: 'eventreport', component: EventReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentEventForm' } },
      //{ path: 'inspectionprint', component: InspectionPrintComponent, canActivate: [UserRoleAuthGuardService] },
      //{ path: 'inspectionview', component: InspectionViewComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'emscompanytrainingprint', component: companytrainingPrintComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CompanyTrainingForm' } },
      { path: 'emscompanytrainingview', component: companytrainingViewComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CompanyTrainingForm' } },
      { path: 'ppeinspectionform', component: PPEInspectionFormComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'PPEMonthlyInspectionForm' } },
      { path: 'ppeinspectionreport', component: PPEInspectionReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'PPEMonthlyInspectionForm' } },
      { path: 'ppeinspectionview', component: PPEInspectionViewComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'PPEMonthlyInspectionForm' } },
      { path: 'gearrequestform', component: GearRequestComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'GearRequestForm' } },
      { path: 'dailylog', component: DailyLogComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DailyLogForm' } },
      { path: 'dailylogreport', component: DailyLogReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DailyLogForm' } },
      //{ path: 'tradetimereport', component: TradeTimeReportComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'waterusageform', component: WaterUsageForm, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'WaterUsageForm' } },
      { path: 'waterusageform/:id', component: WaterUsageForm, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'WaterUsageForm' } },
      { path: 'waterusagereport', component: WaterUsageReport, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'WaterUsageForm' } },
      { path: 'waterusagereportview', component: WaterUsageReportView, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'WaterUsageForm' } },
      { path: 'waterusagereportprint', component: WaterUsageReportPrint, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'WaterUsageForm' } },
      { path: 'certificationupdate', component: CertificationUpdateComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CertificationForm' } },
      { path: 'emstraininghoursform', component: EmsTrainingHoursForm, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'EMSTrainingHoursForm' } },
      //{ path: 'vacationpics', component: VacationPicsComponent, canActivate: [UserRoleAuthGuardService] },
      { path: 'preplan', component: PrePlanComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'PrePlanForm' } },
      { path: 'preplanreport', component: PrePlanReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'PrePlanForm' } },
      { path: 'certificationpdfgenerator', component: CertificatePdfGenerator, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'CertificationForm' } },
      { path: 'emstraininghoursreport', component: EMSTrainingHoursReport, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'EMSTrainingHoursForm' } },
      { path: 'dailyroaster', component: DailyRoasterComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DailyRosterForm' } },
      { path: 'user', component: UserComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'UserForm' } },
      { path: 'roles', component: RoleComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'RolesForm' } },
      { path: 'eventscreen', component: EventComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentEventForm' } },
      { path: 'dailyroasterview', component: DailyRoasterView },
      { path: 'eventpdfgenerator', component: EventPdfGenerator, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DepartmentEventForm' } },
      { path: 'roasterreportview', component: RoasterReportView, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'DailyRosterForm' } },
      { path: 'responsereport', component: ResponseReport, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'responseReport' } },
      { path: 'employeeEmergencyviewreport', component: EmployeeEmergencyViewReport, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'employeeEmergrncyReport' } },
      { path: 'timesheetreport', component: TimeSheetReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'TimesheetForm' } },
      { path: 'shauntimesheet', component: ShaunTimeSheetComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'ShaunsTimesheetForm' } },
      { path: 'genaprintreport', component: GenaPrintReportComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'GenasPrintViewForm' } },
      { path: 'tradetimeviewsheet', component: TradeTimeViewComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'TradeTimeViewForm' } },
      { path: 'myaccount', component: AccountComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'MyAccount' } },
      { path: 'myprofile', component: MyProfile, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'ProfileForm' } },
      { path: 'certificationpopup', component: CertificationPopUpComponent, canActivate: [UserRoleAuthGuardService], resolve: { rolePermissions: RolePermissionsResolverService }, data: { formName: 'certificationForm' } },
      { path: '**', redirectTo: 'Login' }
    ]),
    BlockUIModule.forRoot({ message: 'Loading...' })
  ],
  entryComponents: [
    ErrorDialogComponent, SaveDialogComponent, DeleteDialogComponent, ModelDialogContent, FireRunDialogContent, EmsRunDialogContent, ApparatusDialogContent, RosterAssignmentDialogContent, DeleteAssignmentComponent, DeleteCertificationComponent, DeleteUpdationComponent
  ],
  providers: [ErrorService, SystemCodeService, AuthService, HeaderService, DatePipe, EventService, UserRoleAuthGuardService, RolePermissionsResolverService],
  bootstrap: [AppComponent]
})
export class AppModule { }
