import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { EmployeeAward } from '../models/employeeaward.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { inspection } from '../models/inspection.model';
import { InspectionService } from '../services/inspection.service';
import { inspectionexcludedate } from '../models/inspectionexcludedate.model';
import { inspectionincludedate } from '../models/inspectionincludedate.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { forkJoin,  of } from 'rxjs';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { error } from '@angular/compiler/src/util';
import { switchMap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllInspection } from '../models/get-all-inspection.model';


@Component({
  selector: 'inspectionReport',
  templateUrl: './inspectionReport.component.html'
})
export class InspectionReportComponent {
  @ViewChild('inspectionForm', { static: false }) inspectionForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  container: NgForm;
  resultsLength: number = 0;



  control = new FormControl();
  streets: string[] = ['Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue', 'Champs-lysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets: Observable<string[]>;

  control1 = new FormControl();
  businessName: string[] = ['360 Auto Sales & Service', '4th Quarter Sports Apparell', '731 Auto Repair', 'A-1 transmission', 'A-1 Window Tinting', 'Aamco Transmission Center', 'Aarons Rental', 'Abilities Unlimited LLC', 'Access Shelter', 'Active Day Health Service'];
  filteredBusinessName: Observable<string[]>;


  //@ViewChild('inspectionForm', { static: false }) inspectionTypeForm: NgForm;
  inspectionModel: inspection;
  inspectionReportModel: inspection[];


  states: SystemCode[] = [];
  ranks: SystemCode[] = [];
  districts: SystemCode[] = [];
  knoxboxs: SystemCode[] = [];


  inspectionexcludedate: inspectionexcludedate[] = [];
  inspectionincludedate: inspectionincludedate[] = [];


  inspectiontype: Array<{ key: number, value: string }> = [];
  inspectionconductedbyList: Array<{ key: number, value: string }> = [];
  knoxboxlist: Array<{ key: number, value: string }> = [];
  district: Array<{ key: number, value: string }> = [];
  inspectionResult: Array<{ key: number, value: string }> = [];
  occupancyType: Array<{ key: number, value: string }> = [];




  showNewButton: boolean = false;
  @BlockUI() blockUI: NgBlockUI;

  inspectionId: number;
  dataSource: MatTableDataSource<inspection>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  inspectionGridModel: inspection[] = [];

  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;

  displayedColumns: any[] = ['inspectionDate', 'businessName', 'streetNumber', 'streetName', 'occupancyType', 'inspectionType', 'inspectionResult', 'reInspectDate', 'district', 'knoxbox', 'inspectionconductedby'];


  constructor(private dialog: MatDialog, private routerService: Router, private route: ActivatedRoute,
    private inspectionService: InspectionService, private systemCodeStateService: SystemCodeService
  ) {

  }
  ngOnInit() {

    this.loadDropDowns();

    this.inspectionModel = this.initializeInspectionModel();

    


    //this.loadGrid();
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.filteredBusinessName = this.control1.valueChanges.pipe(
      startWith(''),
      map(value => this._filter1(value))
    );

   // this.loadGrid();
    this.dataSource = new MatTableDataSource<inspection>(this.inspectionGridModel);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  /*********************************** */
  ngAfterViewInit() {
    //pagination subscription
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          //this.blockUI.start();
          return this.getAllInspection(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          //this.blockUI.stop();
          debugger;
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          //this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        debugger;
        this.inspectionGridModel = data || [];
        this.dataSource = new MatTableDataSource<inspection>(this.inspectionGridModel);
        this.dataSource.sort = this.sort;
        console.log("Load Grid this.inspectionGridModelResultModel", this.inspectionGridModel);       
      });
  }

  getAllInspection(pageIndex: number, pageSize: number): Observable<GetAllInspection> {
    debugger;
    return this.inspectionService.getAllInspectionNew(
      'GetInspectionNew',
      (this.inspectionModel.occupancyType) ? this.inspectionModel.occupancyType : 0,
      (this.inspectionModel.inspectionType) ? this.inspectionModel.inspectionType : 0,
      (this.inspectionModel.inspectionResult) ? this.inspectionModel.inspectionResult : 0,
      (this.inspectionModel.district) ? this.inspectionModel.district : 0,
      (this.inspectionModel.knoxBox) ? this.inspectionModel.knoxBox : 0,
      (this.inspectionModel.inspectionConductedBy) ? this.inspectionModel.inspectionConductedBy : 0,
      pageIndex,
      pageSize
    );
  }

  getAllInspectionForDataSource(): void {
    this.getAllInspection(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          //this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.inspectionGridModel = data || [];
        this.dataSource = new MatTableDataSource<inspection>(this.inspectionGridModel);
        this.dataSource.sort = this.sort;
      });
  }

/*********************************** */

  initializeInspectionModel(): inspection {
    return {
      inspectionId: 0, inspectionDate: null, businessName: null, district: 0,
      streetNumber: "", streetName: null, occupancyType: 0, inspectionType: 0,
      inspectionConductedBy: 0, inspectionResult: 0, knoxBox: 0, notes: "",
      reInspectDate: null, nextInspectDate: null, repeatcheck: null, repeats: 0, repeatcount: 0,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      occurences: 0, stopRepeating: null, excludecheck: false, includecheck: false,
      inspectionexcludedate: null,
      inspectionincludedate: null,
      occupancyTypeName: "",
      inspectionTypeName: "",
      inspectionResultName: "",
      districtName: "",
      knoxboxName: "",
      inspectionconductedbyName: ""
    } as inspection;
  }


  loadGrid() {
    debugger;
    this.inspectionService.getAllInspection().subscribe(
      (res: Array<inspection>) => {
        debugger;
        this.inspectionGridModel = res || [];

        this.inspectionId = this.inspectionId;
        this.dataSource = new MatTableDataSource(this.inspectionGridModel);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log("All inspection", res);
        console.log("All datasource", this.dataSource.data);

      }, (error: any) => {
      });
    this.dataSource = new MatTableDataSource(this.inspectionGridModel);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  inspectionReport() {
    this.blockUI.start();
    this.showNewButton = true;
    // string firstName, string lastName, string ffnNumber
    debugger;    
       if (!((this.inspectionModel.occupancyType == null || this.inspectionModel.occupancyType == 0) && (this.inspectionModel.inspectionType == null || this.inspectionModel.inspectionType == 0)
         && (this.inspectionModel.inspectionResult == null || this.inspectionModel.inspectionResult == 0) && (this.inspectionModel.district == null || this.inspectionModel.district == 0)
         && (this.inspectionModel.knoxBox == null || this.inspectionModel.knoxBox == 0) && (this.inspectionModel.inspectionConductedBy == null || this.inspectionModel.inspectionConductedBy == 0))) {
      this.showNewButton = true;
    } else {
      return;
    }
    // var empObj = { firstName: "fi", lastName: "li", ffnNumber:"ff"};

    //this.inspectionService.inspectionReport('inspectionReport', this.inspectionModel).subscribe(res => {
    //  this.inspectionReportModel = res;
    //  debugger;     

     // console.log(res);
   // });
    this.getAllInspectionForDataSource();
  }


  public loadDropDowns() {
    
    this.systemCodeStateService.getSystemCodesForInspectionType().subscribe(
      (res: Array<SystemCode>) => {
        this.inspectiontype = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForInspectionConductedBy().subscribe(
      (res: Array<SystemCode>) => {
        this.inspectionconductedbyList = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForKonxBoxs().subscribe(
      (res: Array<SystemCode>) => {
        this.knoxboxlist = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.district = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForInspectionResult().subscribe(
      (res: Array<SystemCode>) => {
        this.inspectionResult = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForOccupancyType().subscribe(
      (res: Array<SystemCode>) => {
        this.occupancyType = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );

   
  }

  
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  private _filter1(value: string): string[] {
    const filterValue1 = this._normalizeValue1(value);
    return this.businessName.filter(businessName => this._normalizeValue1(businessName).includes(filterValue1));
  }

  private _normalizeValue1(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }







}
