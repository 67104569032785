import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { PpeInspection } from '../models/ppeinspection.model';
import { PPEInspectionService } from '../services/ppeinspection.service';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { Observable, from, forkJoin, of } from 'rxjs';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'ppeinspectionview',
  templateUrl: './ppeinspectionview.component.html'
})
export class PPEInspectionViewComponent { 
  ppeInspectionModel: PpeInspection;
  ppeinspectionResultobj: PpeInspection[] = [];
  crews: SystemCode[] = [];
  names: SystemCode[] = [];
  moistureBarriers: SystemCode[] = [];
  ppeInspectionId: number;
  yesornoKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  rolePermissionCheckModel: RolePermissionCheck;
  
  constructor(private dialog: MatDialog, private routerService: Router, private route: ActivatedRoute,
    private systemCodeStateService: SystemCodeService, private ppeinspectionservice: PPEInspectionService
  ) {   
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.ppeInspectionModel = this.initializePpeInspectionModel();
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.ppeInspectionId = parseInt(Id);
        }
      });
      this.loadDisplay();
      this.loadValues();
      //this.ppeInspectionModel = {
      //  ppeInspectionId: 0, crew: 0, name: 0, date: null, helmet: 0, hood: 0, fireBoots: 0, fireGloves: 0,
      //  coatPants: 0, moistureBarrier: 0, usedInFireFighting: 0, cleaned: 0, contaminated: 0,
      //  deficiencies: 0, description: "", crewName: "", instructorName: "", moistureBarrierName: "", startDate: null, endDate: null
      //};
    }
  }

  initializePpeInspectionModel(): PpeInspection {
    return {
      ppeInspectionId: 0, crew: null, name: null, date: null, helmet: null, hood: null, fireBoots: null, fireGloves: null,
      coat: null, pants: null, moistureBarrier: null, gearId: null, usedInFireFighting: null, cleaned: null, contaminated: null,
      deficiencies: null, description: "", crewName: "", instructorName: "", moistureBarrierName: "", startDate: null, endDate: null
    } as PpeInspection;
  }

  loadDisplay() {
    this.ppeinspectionservice.getPPEInspections('GetPPEInspections', this.ppeInspectionId).subscribe(res => {
      let ppe = res;
      this.ppeinspectionResultobj = res;
    });
  }

  public loadValues() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForShifts(),
      this.systemCodeStateService.getSystemCodesForYesNo()
    ).subscribe(([res1, res2]) => {
      this.crews = res1 || [];
      this.crewKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.yesornoKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
