import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { SystemCode } from '../models/system-codes.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, from, forkJoin, of } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { StRoster } from '../models/stroster.model';
import { StRosterService } from '../services/stroster.service';
import { RosterAssignment } from '../models/rosterassignment.model';
import { RosterAssignmentService } from '../services/rosterassignment.service';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee.model';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllTradeTime } from '../models/get-all-tradeTime.model';
import { DatePipe } from '@angular/common';

//export interface tradeview {
//  shiftDate: string;
//  personnel: string;
//  activitycode: string;
//  tradedwith: string;
//  hours: number;
//}

//export interface GroupBy {
//  crew: string;
//  isGroupBy: boolean;
//}

//const tradeviewdata: (tradeview | GroupBy)[] = [
//  { crew: 'crew1', isGroupBy: true },
//  { shiftDate: '10/20/2020', personnel: 'Thompson, C', activitycode: 'Trade Shift Off', tradedwith: 'Hillard, D', hours: 24 },
//  { shiftDate: '10/24/2020', personnel: 'Lowe, P', activitycode: 'Trade Shift On', tradedwith: 'Mink, D', hours: 12 },
//  { crew: 'crew2', isGroupBy: true },
//  { shiftDate: '10/15/2020', personnel: 'Hilbert, W', activitycode: 'Trade Shift Off', tradedwith: 'Kerby, D', hours: 10 }
//];

export class Group {
  level: number = 0;
  parent: Group;
  expanded: boolean = true;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}


@Component({
  selector: 'tradetimereport',
  templateUrl: './tradetimereport.component.html'
})
export class TradeTimeReportComponent{

  @ViewChild('tradetimeForm', { static: false }) tradetimeForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  //
  //dataSource = tradeviewdata;
  //paginatorid: MatPaginator;
  //sortid: MatSort;

  //displayedColumns: any[] = ['shiftDate', 'personnel', 'activitycode', 'tradedwith', 'hours'];

  //isGroup(index, item): boolean {
  //  return item.activitycode;
  //}
  //
  floatLabel: string = 'never';
  personnelCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  tradedWithCode: SystemCode[] = [];
  //tradedWithCode: SystemCode[] = [];

  //---------------
  container: NgForm;
  id: number;
  showdiv: boolean = true;
  rosterId: number;
  stRosterId: number;
  showNewButton: boolean = false;
  isFound: boolean = false;
  employeeModel: Employee[] = [];
  rosterModel: StRoster[] = [];
  assignmentModel: RosterAssignment[] = [];
  stRosterModel: StRoster;
  stRosterModels: StRoster;
  fromTime: number;
  toTime: number;
  zone: string;
  zone1: string;
  thFromTime: number;
  thToTime: number;
  totalhours: number;
  checktotalhour: number = 0;
  rosterassignmentModel: RosterAssignment;
  rosterAssignmentsModel: RosterAssignment[] = [];
  stationCode: SystemCode[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  stationKey: SystemCodeKeyValue[] = [];
  assignmentKey: SystemCodeKeyValue[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];
  resultsLength: number = 0;


 // assignmentModelDB = new MatTableDataSource<StRoster>([]);-----------
 // dataSource: MatTableDataSource<StRoster>;---------
  paginatorid: MatPaginator;
  sortid: MatSort;
  @BlockUI() blockUI: NgBlockUI;
  isLargeScreen: boolean = false;
  errMsg: string;
  //displayedColumns: any[] = ['personnel', 'shiftDate', 'crew', 'station', 'fromDate'];-----

  //---------------
  //----grouping----

  displayedColumns: string[] = ['shiftDate', 'personnel', 'activitycode', 'tradedwith', 'hours'];
  public dataSource = new MatTableDataSource<StRoster | Group>([]);

  groupByColumns: string[] = ['crew'];

  //----grouping----

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private stRosterService: StRosterService,
    private RosterAssignmentService: RosterAssignmentService, private employeeService: EmployeeService, public datepipe: DatePipe ) {




  }

  ngOnInit() {
    this.loadDropDowns();       
    //this.dataSource = tradeviewdata;
    this.loadGrid();
    this.GridSortAndPage();
    this.loadEmployee();

    //---
    this.dataSource.data = this.addGroups(this.rosterModel, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    //---


    this.stRosterModel = this.initializestRosterModel();
    this.rosterassignmentModel = this.initializerosterassignmentModel();
    this.stRosterModels = this.initializestRosterModel();  
    

  }

  //**********************************

  ngAfterViewInit() {
    this.blockUI.start();
    //pagination subscription
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          //this.blockUI.start();
          return this.getAllTradeTime(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.blockUI.stop();
          debugger;
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        debugger;
        //this.STROSTERS = data || [];
        this.rosterModel = data || [];
        this.dataSource = new MatTableDataSource(this.rosterModel);
        this.dataSource.sort = this.sort;
        console.log("Load Grid this.stRosterResultModel", this.rosterModel);
        //this.GridSortAndPage();
        this.blockUI.stop();
      });
  }

  getAllTradeTime(pageIndex: number, pageSize: number): Observable<GetAllTradeTime> {
    debugger;
    return this.stRosterService.getAllTradeTimeNew(
      'GetTradeTime',
       (this.stRosterModel.startDate) ? this.stRosterModel.startDate : null,
      (this.stRosterModel.endDate) ? this.stRosterModel.endDate : null,
      (this.rosterassignmentModel.activityCode) ? this.rosterassignmentModel.activityCode : 0,
      (this.stRosterModel.personnel) ? this.stRosterModel.personnel : 0,
      (this.rosterassignmentModel.assignment) ? this.rosterassignmentModel.assignment : 0,
      pageIndex,
      pageSize
    );
  }

  getAllTradeTimeForDataSource(): void {
    this.getAllTradeTime(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        //this.STROSTERS = data || [];
        this.rosterModel = data || [];
        this.dataSource = new MatTableDataSource(this.rosterModel);
        this.dataSource.sort = this.sort;
        this.blockUI.stop();
      });
  }

  public searchtradetime() {
    debugger;
    this.blockUI.start();
    //this.searchMsg = "";
    this.getAllTradeTimeForDataSource();
    //return false;
  }

  //**********************************

  initializestRosterModel(): StRoster {
    return {
      stRosterId: 0, shiftDate: null, personnel: 0, crew: 0, station: 0, startDate: null,
      endDate: null, endDateCheck: false, rosterAssignments: null, shiftDateStr: null,
      crewName: null,
      employeeName: null
    } as StRoster;
  }

  initializerosterassignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, fromTime: null, toTime: null, notes: "", assignment: 0, position: 0,
      unit: 0, activityCode: 0, isActive: false, stRosterId: 0
    } as RosterAssignment;
  }

  //
  calculateTotalHours(rosterAssignments: RosterAssignment[]) {

    //debugger;
    for (let i = 0; i < this.rosterModel.length; i++) {
      //var startdate = rosterAssignments[i].startDate;
      //var starttime = rosterAssignments[i].fromTime;
      //var stdatetime = new Date(startdate + '' + starttime).toDateString();

      //var enddate = rosterAssignments[i].endDate;
      //var endtime = rosterAssignments[i].toTime;
      //var eddatetime = new Date(enddate + '' + endtime);    
      //var starttime = Date.parse(rosterAssignments[i].fromTime);
      var fromTime = this.rosterAssignmentsModel[i].fromTime;

    }
    console.log(fromTime);
    //console.log(eddatetime);
    return fromTime;

  }

  getTradeModel(id: number) {
    //debugger;
    this.stRosterId = this.rosterModel[id].stRosterId;
    console.log("Strosterid", this.stRosterId);
    //console.log("index", index);
    console.log("rosterModel", this.rosterModel);
    if (this.stRosterId > 0) {
      this.stRosterService.getRoster('GetRoster', this.stRosterId).subscribe(res => {
        this.stRosterModels = res;
        this.rosterAssignmentsModel = res.rosterAssignments;

        console.log("Get Roster", res);
      }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error Occured. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });

      });

    }   
  }

  getTotalhour(index: number) {
    //debugger;

    this.stRosterId = this.rosterModel[index].stRosterId;
    console.log("Strosterid", this.stRosterId);
    //console.log("index", index);
    console.log("rosterModel", this.rosterModel);
    if (this.stRosterId > 0) {
      this.stRosterService.getRoster('GetRoster', this.stRosterId).subscribe(res => {
        this.stRosterModels = res;
        this.rosterAssignmentsModel = res.rosterAssignments;

        console.log("Get Roster", res);
      }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error Occured. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });

      });

    }

    //var index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId = id)
    var fromTime = this.rosterAssignmentsModel[index].fromTime;
    var toTime = this.rosterAssignmentsModel[index].toTime;
    var hoursMinutes = fromTime.split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.zone = hoursMinutes[2].toString();
    this.fromTime = hours + minutes / 60;

    this.thFromTime = this.fromTime;
    //this.fromDate = Date.parse(this.rosterAssignmentsModel[index].startDate.toString());

    var hoursMinutes = toTime.split(/[.:   ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.zone1 = hoursMinutes[2].toString();
    this.toTime = hours + minutes / 60;

    //this.rosterAssignmentsModel[index].startDate = new Date();
    //let startDate = this.datepipe.transform(this.rosterModel[index].startDate, 'yyyy-MM-dd');
    let startDate = this.datepipe.transform(this.rosterAssignmentsModel[index].startDate, 'yyyy-MM-dd');
    //this.rosterAssignmentsModel[index].endDate = new Date();
    let endDate = this.datepipe.transform(this.rosterAssignmentsModel[index].endDate, 'yyyy-MM-dd');

    if (this.zone == 'AM' && this.zone1 == 'AM' && (Date.parse(startDate) == Date.parse(endDate))) {
      this.thToTime = this.toTime;
    } else if (this.zone == 'AM' && this.zone1 == 'PM' && (Date.parse(startDate) == Date.parse(endDate))) {
      this.thToTime = this.toTime + 12;
    } else if (this.zone == 'AM' && this.zone1 == 'AM' && (Date.parse(startDate) < Date.parse(endDate))) {
      this.thToTime = this.toTime + 24;
    }

    
    this.totalhours = this.thToTime - this.thFromTime;
    this.totalhours = Math.round(this.totalhours);

    this.checktotalhour = this.checktotalhour + this.totalhours;   
    console.log(this.totalhours);
    return this.totalhours;    
   
  }


  //--------------------------------------------------------------------------------//
  customFilterPredicate(data: StRoster | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: StRoster): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) return false;

        let match = true;
        this.groupByColumns.forEach(
          column => {
            if (!row[column] || !data[column] || row[column] !== data[column]) match = false;
          }
        );
        return match;
      }
    );

    if (groupRows.length === 0) return true;
    if (groupRows.length > 1) throw "Data row is in more than one group!";
    const parent = <Group>groupRows[0];  // </Group> (Fix syntax coloring)

    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded
    this.dataSource.filter = performance.now().toString();  // hack to trigger filter refresh
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    var rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels. 
    if (level >= groupByColumns.length)
      return data;

    var groups = this.uniqueBy(
      data.map(
        row => {
          var result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (var i = 0; i <= level; i++)
            result[groupByColumns[i]] = row[groupByColumns[i]];
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];

    var subGroups = [];
    groups.forEach(group => {
      let rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn])
      let subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    })
    return subGroups;
  }

  uniqueBy(a, key) {
    var seen = {};
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

  isGroup(index, item): boolean {
    return item.level;
  }
  //-----------------------------------------------------------------------------//


  public loadDropDowns() {

    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getAllActivityCode(),      
      this.systemCodeService.getAllStations(),
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllAssignment()
    ).subscribe(([res1, res2, res3, res4, res5]) => {
      this.personnelCode = res1 || [];
      this.activityCode = res2 || [];
      this.stationCode = res3 || [];
      this.tradedWithCode = res5 || [];
      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.stationKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.crewKey = res4.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.assignmentKey = res5.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      console.log(res1, res2, res3, res4, res5, this.crewKey, this.stationKey);
    });

  }

  loadGrid() {
    //debugger;
    //this.blockUI.start();
    this.stRosterService.getAllRoster().subscribe(
      (res: Array<StRoster>) => {
        //debugger;
        this.rosterModel = res || [];
        // this.assignmentModelDB.data = this.rosterModel;----
        this.rosterId = this.stRosterId;
        this.GridSortAndPage();
        console.log("All Roster", res);        
      }, (error: any) => {
        console.log("Error :", error);
      });
    //this.blockUI.stop();
    this.GridSortAndPage();
  }

  loadEmployee() {
    //debugger;
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      this.employeeModel = res;
      //debugger;
      console.log(res);
    });
  }

  resetform() {
    debugger;
    this.stRosterModel = this.initializestRosterModel();
    this.rosterassignmentModel = this.initializerosterassignmentModel();
    //this.tradetimeForm.resetForm();
    this.getAllTradeTimeForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    
  }

  //resetRoster() {
  //  this.showNewButton = false;
  //  this.stRosterModels.personnel = 0;
  //  this.resetTheForm();
  //  this.loadGrid();
  //}
  //-------------------------------------------------------------------------------
  //AddNew() {
  //  this.routerService.navigate(['stroaster']);
  //}
  //editRoster(id: number) {
  //  //debugger;
  //  this.routerService.navigate(['stroaster', { id }]);
  //}

  //viewRoster(rosterModel: StRoster) {
  //  //debugger;
  //  this.id = rosterModel.stRosterId;
  //  this.gotoupdate(this.id);
  //}
  //-----------------------------------------------------------------------------
  GridSortAndPage() {
    this.dataSource = new MatTableDataSource<StRoster | Group>(this.rosterModel);
    console.log("Datasource", this.dataSource);
    //this.dataSource = new MatTableDataSource<StRoster>(this.rosterModel);---
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //gotoupdate(id: number) {
  //  this.routerService.navigate(['stroasterupdate', { id }]);
  //}
  

}
