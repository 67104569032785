import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatFormFieldModule, MatInputModule } from '@angular/material';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { EventDpt } from '../models/eventdpt.model';
import { EventDptService } from '../services/eventdpt.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { EventDate } from '../models/eventdate.model';
import { EventTime } from '../models/eventtime.model';
import { EventInclude } from '../models/eventinclude.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { forkJoin } from 'rxjs';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';


@Component({
  selector: 'event',
  templateUrl: './eventdepartment.component.html',
})

export class EventDepartmentComponent {
  @ViewChild('eventDptForm', { static: false }) eventDptForm: NgForm;

  eventModel: EventDpt;
  eventTypesCode: SystemCode[] = [];
  periodicalsCode: SystemCode[] = [];
  periodicalsKey: SystemCodeKeyValue[] = [];
  yearlymonthlyCode: SystemCode[] = [];
  yearlymonthlydaysCode: SystemCode[] = [];
  monthlydaysCode: SystemCode[] = [];
  eventDateExcludes: EventDate[] = [];
  eventDateIncludes: EventInclude[] = [];
  eventTime: EventTime[] = [];
  fromDate: Date;
  toDate: Date;
  todayDate: Date = new Date();

  timeerror: string;
  dateerror: string;
  date1error: string;
  errMsg: string;
  datehint: string;
  hint1: string;
  hint2: string="";
  totalActiveItems: number = 0;
  eventId: number = 0;
  savedialog: boolean;
  errordialog: boolean;
  showRepeat: boolean = false;
  showdiv: boolean = false;
  showMonthlyDiv: boolean = false;
  showWeeklyDiv: boolean = false;
  showInclude: boolean = false;
  showExclude: boolean = false;
  EndDateDisable: boolean = false;
  ByDateDisable: boolean = false;
  ByDayDisable: boolean = false;

  ByDateCheckDisable: boolean = false;
  ByDayCheckDisable: boolean = false;
  DayOnEventVar: number;
  DateOnEventVar: number;

  OccurenceDisable: boolean = false;
  AfterDateDisable: boolean = false;
  Repeat: boolean = true;
  periodicalcheck: string = "Repeats";
  floatLabel: string = 'never';
  monthdays: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  byMonthDay: number;
  january1: boolean = false;
  february1: boolean = false;
  march1: boolean = false;
  april1: boolean = false;
  may1: boolean = false;
  june1: boolean = false;
  july1: boolean = false;
  august1: boolean = false;
  september1: boolean = false;
  october1: boolean = false;
  november1: boolean = false;
  december1: boolean = false;

  radiocheck2: boolean;
  radiocheck1: boolean;
  radiocheck3: boolean;
  radiocheck4: boolean;

  fromTime: number;
  toTime: number;
  PrefromTime: number;
  PretoTime: number;

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private eventDptService: EventDptService) {

  }

  ngOnInit() {
    this.loadDropDowns();
    this.eventModel = this.initializeeventModell();    
    var date1 = this.initializedate1();
    var date2 = this.initializedate2()
    var time1 = this.initializetime1()
    this.datehint = "";

    this.eventDateExcludes.push(date1);
    this.eventDateIncludes.push(date2);
    this.eventTime.push(time1);

    this.route.params.subscribe(params => {
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.eventId = parseInt(Id);
      }
    });

    if (this.eventId > 0) {
      this.eventDptService.getEvent('getEvent', this.eventId).subscribe(res => {
        this.datehint = "";
        this.hint2 = "";        
        this.eventModel = res || [];
        this.EndDateDisable = true;
        var nullDate = new Date("0001-01-01T00:00:00");        
        if (this.eventModel.byDay != 0 || this.eventModel.byDayOn != 0) {
          this.ByDateDisable = true;
          this.ByDayDisable = false;

          this.ByDateCheckDisable = true;
          this.ByDayCheckDisable = false;
          this.january1 = this.eventModel.january
          this.february1 = this.eventModel.february;
          this.march1 = this.eventModel.march;
          this.april1 = this.eventModel.april;
          this.may1 = this.eventModel.may;
          this.june1 = this.eventModel.june;
          this.july1 = this.eventModel.july;
          this.august1 = this.eventModel.august;
          this.september1 = this.eventModel.september;
          this.october1 = this.eventModel.october;
          this.november1 = this.eventModel.november;
          this.december1 = this.eventModel.december;

          this.clearModelMonth();
          this.radiocheck2 = true;

        } else if (this.eventModel.byMonthDay != 0) {
          this.ByDayDisable = true;
          this.byMonthDay = this.eventModel.byMonthDay;
        //  this.ByDayDisable = true;
          this.ByDateDisable = false;

          this.ByDayCheckDisable = true;
          this.ByDateCheckDisable = false;

          this.radiocheck1 = true;
          this.clearMonth();
        }
        if (this.eventModel.endDateCheck==true) {
          this.EndDateDisable = true;
        }

        //for (let i = 0; i < this.periodicalsKey.length; i++) {
        //  if (this.periodicalsKey[i].value == "Daily") {
        //    this.Repeat = false;
        //    this.showWeeklyDiv = false;
        //    this.showMonthlyDiv = false;
        //    this.periodicalcheck = "Single Day";
        //  }
        //}    

        if (this.eventModel.periodical == this.periodicalsKey[2].key) {
          this.showWeeklyDiv = true;
          this.showMonthlyDiv = false;
        } else if (this.eventModel.periodical == this.periodicalsKey[1].key) {
          this.showMonthlyDiv = true;
          this.showWeeklyDiv = false;
        } else if (this.eventModel.periodical == this.periodicalsKey[3].key) {
          this.showMonthlyDiv = true;
        } else if (this.eventModel.periodical == this.periodicalsKey[0].key) {
          this.Repeat = false;
          this.showWeeklyDiv = false;
          this.showMonthlyDiv = false;
          this.periodicalcheck = "Single Day";
        }

        if (this.eventModel.excludeCheck == true) {
          this.showExclude = true;
        } else { this.showExclude = false; }
        if (this.eventModel.includeCheck == true) {
          this.showInclude = true;
        } else { this.showInclude = false; }
        if (this.eventModel.endDateCheck == true) {
          this.showdiv = true;
        } else { this.showdiv = false; }
        if (this.eventModel.repeatCheck == true) {
          this.showRepeat = true;
        } else { this.showRepeat = false; }

        if (this.eventModel.occurences != 0) {
          this.radiocheck3 = true;
          this.OccurenceDisable = true;
        } else if (this.eventModel.repeatDate != null) {
          this.radiocheck4 = true;
          this.AfterDateDisable = true;
        }

        //this.show();
        //this.show1();
        //this.show3();
        //this.show4();
        this.eventDateExcludes = res.eventDate || [];
        this.eventDateIncludes = res.eventInclude || [];
        this.eventTime = res.eventTime || [];
        for (let i = 0; i < this.eventDateExcludes.length; i++) {
          if (new Date(this.eventDateExcludes[i].excludeDate).getTime() == new Date(nullDate).getTime()) {
            this.eventDateExcludes[i].excludeDate = null;
          }
        }
        for (let i = 0; i < this.eventDateIncludes.length; i++) {
          if (new Date(this.eventDateIncludes[i].includeDate).getTime() == new Date(nullDate).getTime()) {
            this.eventDateIncludes[i].includeDate = null;
          }
        }
      });
    }
    else {  
      this.clearModelMonth();
      this.clearMonth();
    }
  }

  clearModelMonth() {
    this.eventModel.january = false;
    this.eventModel.february = false;
    this.eventModel.march = false;
    this.eventModel.april = false;
    this.eventModel.may = false;
    this.eventModel.june = false;
    this.eventModel.july = false;
    this.eventModel.august = false;
    this.eventModel.september = false;
    this.eventModel.october = false;
    this.eventModel.november = false;
    this.eventModel.december = false;
  }
  clearWeek() {
    this.eventModel.sunday = false;
    this.eventModel.monday = false;
    this.eventModel.tuesday = false;
    this.eventModel.wednesday = false;
    this.eventModel.thursday = false;
    this.eventModel.friday = false;
    this.eventModel.saturday = false;
  }

  clearMonth() {
    this.january1 = false;
    this.february1 = false;
    this.march1 = false;
    this.april1 = false;
    this.may1 = false;
    this.june1 = false;
    this.july1 = false;
    this.august1 = false;
    this.september1 = false;
    this.october1 = false;
    this.november1 = false;
    this.december1 = false;
  }

  initializeeventModell(): EventDpt {
    return {
      eventId: 0, eventTitle: null, eventType: null, fromDate: null, toDate: null, periodical: 0,
      repeatCount: 0, sunday: false, monday: false, tuesday: false,
      wednesday: false, thursday: false, friday: false, saturday: false, occurences: 0,
      repeatDate: null, excludeCheck: false,
      includeCheck: false, endDateCheck: false, repeatCheck: false, description: null, eventTime: null,
      eventDate: null, eventInclude: null,

      byMonthDay: 0, byDayOn: 0, byDay: 0, january: false, february: false, march: false, april: false,
      may: false, june: false, july: false, august: false, september: false, october: false, november: false, december: false
    } as EventDpt;
  }
  initializedate1(): EventDate {
    return {
      eventDateId: 0, excludeDate: null, isActive: true, eventId: 0
    } as EventDate;
  }
  initializedate2(): EventInclude {
    return {
      eventIncludeId: 0, includeDate: null, isActive: true, eventId: 0
    } as EventInclude;
  }
  initializetime1(): EventTime {
    return {
      eventTimeId: 0, eventTimeRange: null, eventEndTimeRange: null, isActive: true, eventId: 0
    } as EventTime;
  }

  resetDate() {
    this.eventModel.toDate = null;
  }


  public addAddition() {
    if (this.eventDateIncludes.length > 0) {
      let theEventDateIncludes: EventInclude[] = [];
      for (var i = 0; i < this.eventDateIncludes.length; i++) {
        if (this.eventDateIncludes[i].isActive == true) {
          theEventDateIncludes.push(this.eventDateIncludes[i]);
        }
      }
      if (theEventDateIncludes[theEventDateIncludes.length - 1].includeDate != null) {
        var activities = this.initializedate2();
        this.eventDateIncludes.push(activities);
      }
      else {
        this.date1error = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.date1error = "";
        //}, 1500);
      }
    }
    else {
      var activities = this.initializedate2();
      this.eventDateIncludes.push(activities);
    }
    return false;
    //var date2 = this.initializedate2();
    //this.eventDateIncludes.push(date2);
    //return false;
  }

  public addException() {
    if (this.eventDateExcludes.length > 0) {
      let theEventDate: EventDate[] = [];
      for (var i = 0; i < this.eventDateExcludes.length; i++) {
        if (this.eventDateExcludes[i].isActive == true) {
          theEventDate.push(this.eventDateExcludes[i]);
        }
      }
      if (theEventDate[theEventDate.length - 1].excludeDate != null) {
        var activities = this.initializedate1();
        this.eventDateExcludes.push(activities);
      }
      else {
        this.dateerror = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.dateerror = "";
        //}, 1500);
      }
    }
    else {
      var activities = this.initializedate1();
      this.eventDateExcludes.push(activities);
    }
    return false;
    //var date1 = this.initializedate1();
    //this.eventDateExcludes.push(date1);
    //return false;
  }

  public removeAddition(Id: number) {
    const index = Id;
    this.totalActiveItems = 0;
    for (let i = 0; i < this.eventDateIncludes.length; i++) {
      if (this.eventDateIncludes[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    // const index = this.eventDateIncludes.findIndex(x => x.eventIncludeId == Id);
    if (this.totalActiveItems == 1) {
      this.eventDateIncludes[index].includeDate = null;

    }
    else {
      this.eventDateIncludes[index].isActive = false;
    }
    this.date1error = "";
    return false;
  }

  public removeException(Id: number) {
    const index = Id;
    //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
    this.totalActiveItems = 0;
    for (let i = 0; i < this.eventDateExcludes.length; i++) {
      if (this.eventDateExcludes[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.eventDateExcludes[index].excludeDate = null;
    }
    else {
      this.eventDateExcludes[index].isActive = false;
    }
    this.dateerror = "";
    return false;
  }

  addAnotherItem() {
    if (this.eventTime.length > 0) {
      let theEventTime: EventTime[] = [];
      for (var i = 0; i < this.eventTime.length; i++) {
        if (this.eventTime[i].isActive == true) {
          theEventTime.push(this.eventTime[i]);
        }
      }
      if (theEventTime[theEventTime.length - 1].eventEndTimeRange != null && theEventTime[theEventTime.length - 1].eventTimeRange != null && this.hint2 == '' ) {
        var time1 = this.initializetime1();
        this.eventTime.push(time1);
        this.timeerror = "";
      } else if (this.hint2 != '') {
        this.timeerror = "Please Correct the End Time to add another item ! !";
      }
      else {
        this.timeerror = "Please Fill the above field to add another item !";
      }
    }
    else {
      var time1 = this.initializetime1();
      this.eventTime.push(time1);
    }
    return false;
    //var time1 = this.initializetime1();
    //this.eventTime.push(time1);
    //return false;
  }


  deleteTime(Id: number) {
    const index = Id;
    //  const index = this.eventTime.findIndex(x => x.eventTimeId == Id);
    this.totalActiveItems = 0;
    for (let i = 0; i < this.eventTime.length; i++) {
      if (this.eventTime[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (index > 0) {
      this.eventTime[index].isActive = false;
    }
    else {
      this.eventTime[index].eventTimeRange = null;
      this.eventTime[index].eventEndTimeRange = null;
    }
    this.timeerror = "";
    return false;
  }


  timevent(event: string) {
    this.timeerror = "";
    var hoursMinutes = event.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.fromTime = hours + minutes / 60;

    if (this.fromTime >= this.toTime) {
      this.hint2 = "End Time Should be greater than Start Time";
    } else {
      this.hint2 = "";
    }
    //let theEventTimeModel: EventTime[] = [];
    //for (var i = 0; i < this.eventTime.length; i++) {
    //  if (this.eventTime[i].isActive == true) {
    //    theEventTimeModel.push(this.eventTime[i]);
    //  }
    //}



  }


  timevent1(event: string) {
    this.timeerror = "";
    var hoursMinutes = event.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.toTime = hours + minutes / 60;
    if (this.fromTime >= this.toTime) {
      this.hint2 = "End Time Should be greater than Start Time";
    } else {
      this.hint2 = "";
    }
   
  }

  Excludedateevent(excludeDate: Date) {
    this.dateerror = "";
  }

  Includedateevent(includeDate: Date) {
    this.date1error = "";
  }

 
  dateevent1(fromDate: Date) {
    this.fromDate = fromDate;  
    if (this.eventId > 0) {
      this.toDate = this.eventModel.toDate;
    }
    this.EndDateDisable = true;
    this.datehint = "";
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";     
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }

  dateevent2(toDateev: Date) {
    this.toDate = toDateev;    
    if (this.eventId > 0) {
      this.fromDate = this.eventModel.fromDate;
    }
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }



  public updateEventDpt() {
    this.eventModel.eventDate = this.eventDateExcludes;
    this.eventModel.eventInclude = this.eventDateIncludes;
    this.eventModel.eventTime = this.eventTime;
    this.eventModel.eventId = this.eventId;
    this.eventModel.eventTitle = this.eventModel.eventTitle.charAt(0).toUpperCase() + this.eventModel.eventTitle.slice(1);
    //this.eventModel.byDay = this.monthdays;
    if (this.eventModel.occurences == null) {
      this.eventModel.occurences = 0;
    }
    if (this.eventModel.repeatCount == null) {
      this.eventModel.repeatCount = 0;
    }

    if (this.eventModel.byMonthDay == null) {
      this.eventModel.byMonthDay = 0;
    }
    if (this.eventModel.byDayOn == null) {
      this.eventModel.byDayOn = 0;
    }
    if (this.eventModel.byDay == null) {
      this.eventModel.byDay = 0;
    }

    if (this.ByDateDisable == true) {
      this.eventModel.january = this.january1;
      this.eventModel.february = this.february1;
      this.eventModel.march = this.march1;
      this.eventModel.april = this.april1;
      this.eventModel.may = this.may1;
      this.eventModel.june = this.june1;
      this.eventModel.july = this.july1;
      this.eventModel.august = this.august1;
      this.eventModel.september = this.september1;
      this.eventModel.october = this.october1;
      this.eventModel.november = this.november1;
      this.eventModel.december = this.december1;
    }
    if (this.eventId > 0) {
      this.eventDptService.updateEventDpt(this.eventId, this.eventModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
             this.GoBack();
            this.resetTheForm();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
          });
        }
      });
    }
    else {
      this.eventDptService.saveEventDpt('saveEventDpt', this.eventModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
            this.resetTheForm();
          });
        }
        else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
          });
        }
      });
    }
  }



  resetTheForm() {
    this.eventDptForm.resetForm();
    this.eventModel = this.initializeeventModell();
    var date1 = this.initializedate1();
    var date2 = this.initializedate2();
    var time1 = this.initializetime1();

    this.eventDateExcludes = [];
    this.eventDateIncludes = [];
    this.eventTime = [];

    this.eventDateExcludes.push(date1);
    this.eventDateIncludes.push(date2);
    this.eventTime.push(time1);

    this.showRepeat = false;
    this.showExclude = false;
    this.showInclude = false;
    this.showdiv = false;
  }

  resetEvent() {
    this.resetTheForm();
  }
  clearcheckAndRadio() {
    this.ByDayDisable = false;
    this.ByDayCheckDisable = false;
    this.ByDateDisable = false;
    this.ByDateCheckDisable = false;
    this.eventModel.byDayOn = null;
    this.eventModel.byDay = null;
    this.eventModel.byMonthDay = null;
    this.radiocheck2 = false;
    this.radiocheck1 = false;
  }

  onPeriodicalChange(event: number) {
    if (event == this.periodicalsKey[2].key) {
      this.periodicalcheck = "Weeks";
      this.Repeat = true;
      this.showWeeklyDiv = true;
      this.showMonthlyDiv = false;
      this.clearModelMonth();
      this.clearMonth();
      this.clearcheckAndRadio();
    }
    else if (event == this.periodicalsKey[1].key) {
      this.periodicalcheck = "Months";
      this.Repeat = true;
      this.showMonthlyDiv = true;
      this.showWeeklyDiv = false;
      this.clearWeek();
      this.clearModelMonth();
      this.clearMonth();
      this.clearcheckAndRadio();
    }
    else if (event == this.periodicalsKey[3].key) {
      this.periodicalcheck = "Years";
      this.Repeat = true;
      this.showMonthlyDiv = true;
      this.showWeeklyDiv = false;
      this.clearWeek();
      this.clearModelMonth();
      this.clearMonth();
      this.clearcheckAndRadio();
    }
    else if (event == this.periodicalsKey[0].key) {
      this.periodicalcheck = "Single Day";
      this.Repeat = false;
      this.showWeeklyDiv = false;
      this.showMonthlyDiv = false;
      this.clearModelMonth();
      this.clearMonth();
      this.clearWeek();
      this.clearcheckAndRadio();
    }
  }

  onMonthDayChange(MonthDayevent: number) {
    if (MonthDayevent != undefined) {
      this.ByDayCheckDisable = true;
    } else {
      this.ByDayCheckDisable = false;
      this.clearModelMonth();
    }
  }

  onDayOnChange(DayOnEvent: number) {
    if (this.eventId > 0) {
      this.DayOnEventVar = this.eventModel.byDayOn;
      this.DateOnEventVar = this.eventModel.byDay;
    }
    this.DayOnEventVar = DayOnEvent;
    if (this.DayOnEventVar == undefined || this.DateOnEventVar == undefined) {
      this.ByDateCheckDisable = false;
      this.clearMonth();
    } else {
      this.ByDateCheckDisable = true;
    }
  }
  onDayChange(DayEvent: number) {
    if (this.eventId > 0) {
      this.DayOnEventVar = this.eventModel.byDayOn;
      this.DateOnEventVar = this.eventModel.byDay;
    }
    this.DateOnEventVar = DayEvent;
    if (this.DateOnEventVar != undefined && this.DayOnEventVar != undefined) {
      this.ByDateCheckDisable = true;
    } else {
      this.ByDateCheckDisable = false;
      this.clearMonth();
    }
  }

  RadioByDate() {
    this.ByDateDisable = true;
    this.ByDayDisable = false;    
    this.ByDayCheckDisable = false;
    this.clearModelMonth();
    this.eventModel.byMonthDay = null;
  }

  RadioByDay() {
    this.ByDayDisable = true;
    this.ByDateDisable = false;
    this.ByDateCheckDisable = false;
    this.clearMonth();
    this.eventModel.byDayOn = null;
    this.eventModel.byDay = null;
  }

  RadioOccurences() {
    this.eventModel.repeatDate = null;
    this.AfterDateDisable = false;
    this.OccurenceDisable = true;
  }

  RadioDate() {
    this.eventModel.occurences = null;
    this.AfterDateDisable = true;
    this.OccurenceDisable = false;
  }



  GoBack() {
    this.routerService.navigate(['/eventreport']);
  }

  show1() {
    if (this.showRepeat == true) {
      this.showRepeat = false;
    }
    else {
      this.showRepeat = true;
    }
  }
  show3() {
    if (this.showExclude == true) {
      this.showExclude = false;
    }
    else {
      this.showExclude = true;
    }
  }
  show4() {
    if (this.showInclude == true) {
      this.showInclude = false;
    }
    else {
      this.showInclude = true;
    }
  }

  show() {
    this.datehint = "";
    if (this.showdiv == true) {
      this.showdiv = false;
      this.eventModel.toDate = null;      
    }
    else {
      this.showdiv = true;     
    }
  }

  resetDate1() {
    this.eventModel.repeatDate = null;
  }
  resetDate2(index: number) {
    this.eventDateExcludes[index].excludeDate = null;
  }
  resetDate3(index1: number) {
    this.eventDateIncludes[index1].includeDate = null
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllEventType(),
      this.systemCodeService.getAllRepeatsPeriod(),
      this.systemCodeService.getSystemCodesForYearlyMonthly(),
      this.systemCodeService.getSystemCodesForIYearlyMonthlyDays()
     
    ).subscribe(([res1, res2, res3, res4]) => {
      this.eventTypesCode = res1 || [];
      this.periodicalsCode = res2 || [];
      this.periodicalsKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.yearlymonthlyCode = res3 || [];
      this.yearlymonthlydaysCode = res4 || [];
    });
  }
}
