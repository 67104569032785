import { Component, ViewChild, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatRadioChange } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { inspection } from '../models/inspection.model';
import { inspectionexcludedate } from '../models/inspectionexcludedate.model';
import { inspectionincludedate } from '../models/inspectionincludedate.model';
import { inspectionfile } from '../models/inspectionfile.model';
import { InspectionService } from '../services/inspection.service';
import { debug } from 'util';
import { saveFile, saveAs } from 'file-saver';

@Component({
  selector: 'inspection',
  templateUrl: './inspection.component.html'

})
export class InspectionComponent implements OnInit {

  @ViewChild('inspectionForm', { static: false }) inspectionForm: NgForm;
  @ViewChild('inspectionfileUploader', { static: false }) inspectionfileUploader: ElementRef;

  control = new FormControl();
  //streets: string[] = [];
  //filteredStreets: Observable<string[]>;
  streetResultObj: SystemCode[] = [];
  filteredstreets: Observable<SystemCode[]>;

  control1 = new FormControl();
  //BusinessName: string[] = [];
  //filteredBusinessName: Observable<string[]>;
  businessResultObj: SystemCode[] = [];
  filterednames: Observable<SystemCode[]>;

  businessObj: Array<{ key: number, value: string }> = [];
  public filteredBusinessList: Array<{ key: number, value: string }> = [];

  streetObj: Array<{ key: number, value: string }> = [];
  public filteredStreetList: Array<{ key: number, value: string }> = [];

  streetName: string = "";
  //businessName: string = "";
  date = new Date();
  floatLabel: string = 'never';

  inspectionModel: inspection;
  streetNameObj: inspection[] = [];
  states: SystemCode[] = [];
  //ranks: SystemCode[] = [];
  districts: SystemCode[] = [];
  knoxboxs: SystemCode[] = [];
  inspectiontype: SystemCode[] = [];
  inspectionconductedby: SystemCode[] = [];
  inspectionresult: SystemCode[] = [];
  occupancytype: SystemCode[] = [];
  repeatsdropdown: SystemCode[] = [];
  Repeat: boolean = false;
  occupancysubtypes: SystemCode[] = [];
  occupancysubtypechilds: SystemCode[] = [];
  yearlymonthlyCode: SystemCode[] = [];
  yearlymonthlydaysCode: SystemCode[] = [];
  monthdays: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  january1: boolean = false;
  february1: boolean = false;
  march1: boolean = false;
  april1: boolean = false;
  may1: boolean = false;
  june1: boolean = false;
  july1: boolean = false;
  august1: boolean = false;
  september1: boolean = false;
  october1: boolean = false;
  november1: boolean = false;
  december1: boolean = false;
  minDate = new Date(2019, 0, 1);
  maxDate = new Date(2022, 11, 31);
  //maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 3, this.minDate.getDate() - 1);

  inspectionexcludedate: inspectionexcludedate[] = [];
  inspectionincludedate: inspectionincludedate[] = [];
  inspectionFileModel: inspectionfile;
  inspectionFileList: inspectionfile[] = [];

  inspectionId: number;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  showRepeat: boolean = false;
  showExcludeDate: boolean = false;
  showIncludeDate: boolean = false;
  periodicalcheck: string;
  showWeekly: boolean = false;
  showMonthly: boolean = false;
  monthDay: boolean = false;
  dayOn: boolean = false;
  OccurenceDisable: boolean = false;
  AfterDateDisable: boolean = false;
  ByDateCheckDisable: boolean = false;
  ByDayCheckDisable: boolean = false;
  DayOnEventVar: number;
  DateOnEventVar: number;
  dateerror: string;
  date1error: string;
  totalActiveItems: number = 0;
  radiocheck2: boolean;
  radiocheck1: boolean;
  radiocheck3: boolean;
  radiocheck4: boolean;
  repeatcheckenable: boolean;
  excludecheckenable: boolean;
  includecheckenable: boolean;
  fileExists: boolean = true;
  inspectionFileExists: boolean = false;
  isFileSelected: boolean = false;
  validationMsg: string = null;
  inspectionFile: File;

  constructor(private dialog: MatDialog, private routerService: Router, private route: ActivatedRoute,
    private inspectionService: InspectionService, private systemCodeStateService: SystemCodeService) {

  }

  ngOnInit() {

    this.loadDropDowns();

    this.inspectionModel = this.initializeInspectionModel();
    this.inspectionFileModel = this.initializeinspectionfile();
    var inspectionfiles = this.initializeinspectionfile();
    this.inspectionFileList.push(inspectionfiles);

    this.route.params.subscribe(params => {
      //debugger;
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.inspectionId = parseInt(Id);
        this.loadForm();
      }
    });

    //var date1 = { inspectionexcludedateId: 0, inspectionId: 0, excludeDate: null, isActive: true };
    var date1 = this.initializedate1();
    this.inspectionexcludedate.push(date1);
    console.log(this.inspectionexcludedate);

    //var date2 = { inspectionincludedateId: 0, inspectionId: 0, includeDate: null, isActive: true };
    var date2 = this.initializedate2();
    this.inspectionincludedate.push(date2);
    console.log(this.inspectionincludedate);

    //this.filteredStreets = this.control.valueChanges.pipe(
    //  startWith(''),
    //  map(value => this._filter(value))
    //);

    //this.filteredBusinessName = this.control1.valueChanges.pipe(
    //  startWith(''),
    //  map(value => this._filter1(value))
    //);

    this.filteredstreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.type),
      map(type => type ? this._filter1(type) : this.streetResultObj.slice())
    );



    this.filterednames = this.control1.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.type),
      map(type => type ? this._filter2(type) : this.businessResultObj.slice())
    );

  }

  private _filter1(type: string): SystemCode[] {
    //debugger;
    const filterValue = type.toLowerCase();
    return this.streetResultObj.filter(option => option.type.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filter2(type: string): SystemCode[] {
    //debugger;
    const filterValue = type.toLowerCase();
    return this.businessResultObj.filter(option => option.type.toLowerCase().indexOf(filterValue) === 0);
  }

  //displayFn(user: SystemCode): number {
  //  return user && user.type ? user.systemCodeId : 0;
  //}
  displayFn1(user: SystemCode): string {
    return user && user.type ? user.type : '';
  }

  displayFn(user: SystemCode): string {
    return user && user.type ? user.type : '';
  }

  //-------------------------------
  initializeInspectionModel(): inspection {
    return {
      inspectionId: 0, inspectionDate: null, businessName: 0, district: null, streetNumber: null, streetName: 0, occupancyType: null,
      occupancySubType: null, occupancySubTypeChild: null, inspectionType: null, inspectionConductedBy: null, inspectionResult: null,
      knoxBox: null, notes: "", reInspectDate: null, nextInspectDate: null, repeatcheck: false, repeats: null, repeatcount: null,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      occurences: null, stopRepeating: null, excludecheck: false, includecheck: false, inspectionexcludedate: null, inspectionincludedate: null,
      inspectionFiles: null, occupancyTypeName: "", inspectionTypeName: "", inspectionResultName: "", districtName: "", knoxboxName: "",
      inspectionconductedbyName: "", endDateCheck: null, byMonthDay: 0, byDayOn: 0, byDay: 0,
      january: false, february: false, march: false, april: false, may: false, june: false, july: false, august: false, september: false, october: false,
      november: false, december: false
    } as inspection;

  }

  initializedate1(): inspectionexcludedate {
    return {
      inspectionexcludedateId: 0, inspectionId: 0, excludeDate: null, isActive: true
    } as inspectionexcludedate;
  }

  initializedate2(): inspectionincludedate {
    return {
      inspectionincludedateId: 0, inspectionId: 0, includeDate: null, isActive: true
    } as inspectionincludedate;
  }

  initializeinspectionfile(): inspectionfile {
    return {
      inspectionFileId: 0, inspectionFileName: null, inspectionFileType: null, inspectionId: 0, isActive: true
    } as inspectionfile;

  }

  resetDate2(index: number) {
    debugger;
    this.inspectionexcludedate[index].excludeDate = null;
  }

  resetDate3(index1: number) {
    debugger;
    this.inspectionincludedate[index1].includeDate = null
  }

  loadForm() {
    if (this.inspectionId > 0) {
      debugger;
      this.inspectionService.getInspection('GetInspection', this.inspectionId).subscribe(res => {
        this.inspectionModel = res || [];
        console.log(res);

        if (this.inspectionModel.repeatcheck == true) {
          //this.repeatcheckenable = true;
          this.showRepeat = true;
        } else {
          this.showRepeat = false;
          //this.repeatcheckenable = false;
        }

        if (this.inspectionModel.excludecheck == true) {
          this.showExcludeDate = true;
          //this.excludecheckenable = true;
        } else { this.showExcludeDate = false; }

        if (this.inspectionModel.includecheck == true) {
          this.showIncludeDate = true;
          //this.includecheckenable = true;
        } else { this.showIncludeDate = false; }
        

        if (this.inspectionModel.occurences != 0) {
          this.radiocheck3 = true;
          this.OccurenceDisable = true;
        } else if (this.inspectionModel.stopRepeating != null) {
          this.radiocheck4 = true;
          this.AfterDateDisable = true;
        }

        if (this.inspectionModel.byDay != 0 || this.inspectionModel.byDayOn != 0) {
          this.dayOn = true;
          this.monthDay = false;

          this.ByDateCheckDisable = true;
          this.ByDayCheckDisable = false;
          this.january1 = this.inspectionModel.january
          this.february1 = this.inspectionModel.february;
          this.march1 = this.inspectionModel.march;
          this.april1 = this.inspectionModel.april;
          this.may1 = this.inspectionModel.may;
          this.june1 = this.inspectionModel.june;
          this.july1 = this.inspectionModel.july;
          this.august1 = this.inspectionModel.august;
          this.september1 = this.inspectionModel.september;
          this.october1 = this.inspectionModel.october;
          this.november1 = this.inspectionModel.november;
          this.december1 = this.inspectionModel.december;

          this.clearModelMonth();
          this.radiocheck2 = true;

        } else if (this.inspectionModel.byMonthDay != 0) {
          this.monthDay = true;
          //this.byMonthDay = this.inspectionModel.byMonthDay;
          //  this.ByDayDisable = true;
          this.dayOn = false;

          this.ByDayCheckDisable = true;
          this.ByDateCheckDisable = false;

          this.radiocheck1 = true;
          this.clearMonth();
        }

        var repeatType = this.repeatsdropdown.find(x => x.systemCodeId === this.inspectionModel.repeats).type;
        if (repeatType == 'Weekly') {
          this.showWeekly = true;
          this.showMonthly = false;
        } else if (repeatType == 'Monthly') {
          this.showMonthly = true;
          this.showWeekly = false;
        } else if (repeatType == 'Yearly') {
          this.showMonthly = true;
        } else if (repeatType == 'Daily') {
          this.Repeat = false;
          this.showWeekly = false;
          this.showMonthly = false;
          this.periodicalcheck = "Single Day";
        }

        this.inspectionexcludedate = res.inspectionexcludedate || [];
        this.inspectionincludedate = res.inspectionincludedate || [];

      });
    }
  }

  //-------------------
  PrePlanInspection(fileInput: any) {
    debugger;
    this.inspectionFile = fileInput[0];
    this.isFileSelected = true;

    var fileName = this.inspectionFile.name;

    const lastDot = fileName.lastIndexOf('.');

    const fName = fileName.substring(0, lastDot);
    const ext = fileName.substring(lastDot + 1);
    this.validationMsg = "";
    if (this.inspectionFile.size > 2097152) {
      console.log("File size is more than 2 MB");
      this.validationMsg = "File size more than 2 MB";
      this.isFileSelected = false;
    }

    if (!(ext == "pdf")) {
      console.log("not a valid file format!");
      this.validationMsg = "File extension is Invalid!";
      this.isFileSelected = false;
    }
  }

  openInspectionFile(inspectionFileId: string, fileName: string) {
    debugger;
    this.inspectionService.getInspectionFile('GetInspectionFile', inspectionFileId, "PrePlan").subscribe(res => {
      debugger;
      saveAs(res, fileName);
    });
    return false;
  }

  deleteInspectionFile(inspectionFileId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '200px',
      data: { id: 1, name: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      debugger;
      if (result != undefined) {

        this.inspectionFileModel.inspectionFileDelete = true;
        this.inspectionFileModel.inspectionFileName = null;
        this.inspectionFileExists = false;
      }
    });
    return false;
  }

  closeValidation() {
    this.validationMsg = null;
    this.inspectionfileUploader.nativeElement.value = null;
    this.inspectionFile = null;
  }

  //-------------------------------------

  public loadDropDowns() {

    forkJoin(
      this.systemCodeStateService.getSystemCodesForRepeats(),
      this.systemCodeStateService.getSystemCodesForDistricts(),
      this.systemCodeStateService.getSystemCodesForKonxBoxs(),
      this.systemCodeStateService.getSystemCodesForOccupancyType(),
      this.systemCodeStateService.getSystemCodesForInspectionType(),
      this.systemCodeStateService.getSystemCodesForInspectionConductedBy(),
      this.systemCodeStateService.getSystemCodesForInspectionResult(),
      //this.inspectionService.getStreets('GetStreetNames'),
      this.systemCodeStateService.getSystemCodesForStreetNames(),
      //this.inspectionService.getBusiness('GetBusinessNames'),
      this.systemCodeStateService.getSystemCodesForBusinessName(),
      this.systemCodeStateService.getSystemCodesForYearlyMonthly(),
      this.systemCodeStateService.getSystemCodesForIYearlyMonthlyDays()

    ).subscribe(([res1, res2, res3, res4, res5, res6, res7, res8, res9, res10, res11]) => {
      this.repeatsdropdown = res1 || [];
      this.districts = res2 || [];
      this.knoxboxs = res3 || [];
      this.occupancytype = res4 || [];
      this.inspectiontype = res5 || [];
      this.inspectionconductedby = res6 || [];
      this.inspectionresult = res7 || [];
      this.streetResultObj = res8.map(x => { return { sysCodeId: x.systemCodeId, type: x.type, code: x.code }; });
      this.businessResultObj = res9.map(x => { return { sysCodeId: x.systemCodeId, type: x.type, code: x.code }; });
      this.yearlymonthlyCode = res10 || [];
      this.yearlymonthlydaysCode = res11 || [];
      this.businessObj = res9.map(x => { return { key: x.systemCodeId, value: x.type }; });
      this.filteredBusinessList = this.businessObj.slice();
      this.streetObj = res8.map(x => { return { key: x.systemCodeId, value: x.type }; });
      this.filteredStreetList = this.streetObj.slice();
      console.log(res1);
      console.log(res2);
      console.log(res3);
      console.log(res4);
      console.log(res5);
      console.log(res6);
      console.log(res7);
      console.log(this.streetResultObj);
      console.log(res9);
      console.log(this.businessResultObj);
      console.log(res10);
      console.log(res11);
      console.log('blist', this.filteredBusinessList);
      console.log('blist', this.filteredStreetList);
    });

  }

  onTypeChange(event: number) {

    //debugger;
    var type = this.occupancytype.find(x => x.systemCodeId === event).type;

    if (type == 'Commercial') { //Commercial
      this.systemCodeStateService.getSystemCodesForOccupancySubTypeCommercial().subscribe(
        (res: Array<SystemCode>) => {
          this.occupancysubtypes = res || [];
          console.log(res);
        }, (error: any) => { }
      );
    }

    else if (type == 'Residential') { //Residential
      this.systemCodeStateService.getSystemCodesForOccupancySubTypeResidential().subscribe(
        (res: Array<SystemCode>) => {
          this.occupancysubtypes = res || [];
          this.occupancysubtypechilds = [];
          console.log(res);
        }, (error: any) => { }
      );
    }
    return false;
  }

  onSubTypeChange(event: number) {
    //debugger;
    var type = this.occupancysubtypes.find(x => x.systemCodeId === event).type;

    if (type == 'Business') { //Business
      this.systemCodeStateService.getSystemCodesForOccupancySubTypeChildBusiness().subscribe(
        (res: Array<SystemCode>) => {
          this.occupancysubtypechilds = res || [];
          console.log(res);
        }, (error: any) => { }
      );
    }

    else if (type == 'Assembly') { //Assembly
      this.systemCodeStateService.getSystemCodesForOccupancySubTypeChildAssembly().subscribe(
        (res: Array<SystemCode>) => {
          this.occupancysubtypechilds = res || [];
          console.log(res);
        }, (error: any) => { }
      );
    }
    else {
      this.occupancysubtypechilds = [];
    }

  }

  //public addException() {

  //  var date1 = {
  //    inspectionexcludedateId: this.inspectionexcludedate.length + 1,
  //    inspectionId: 0, excludeDate: null, isActive: true
  //  };
  //  this.inspectionexcludedate.push(date1);
  //  return false;
  //}

  //public addAddition() {

  //  var date2 = {
  //    inspectionincludedateId: this.inspectionincludedate.length + 1,
  //    inspectionId: 0, includeDate: null, isActive: true
  //  };
  //  this.inspectionincludedate.push(date2);
  //  return false;
  //}

  //-----------------------add----excludedate------------------

  public addException() {
    if (this.inspectionexcludedate.length > 0) {
      let theinspectDate: inspectionexcludedate[] = [];
      for (var i = 0; i < this.inspectionexcludedate.length; i++) {
        if (this.inspectionexcludedate[i].isActive == true) {
          theinspectDate.push(this.inspectionexcludedate[i]);
        }
      }
      if (theinspectDate[theinspectDate.length - 1].excludeDate != null) {
        var activities = this.initializedate1();
        this.inspectionexcludedate.push(activities);
      }
      else {
        this.dateerror = "Please Fill the above field to add another item !";
      }
    }
    else {
      var activities = this.initializedate1();
      this.inspectionexcludedate.push(activities);
    }
    return false;
  }

  Excludedateevent(excludeDate: Date) {
    this.dateerror = "";
  }

  //----------------------add-----excludedate------------------

  //-----------------------add----includedate------------------

  public addAddition() {
    if (this.inspectionincludedate.length > 0) {
      let theinspectDateinclude: inspectionincludedate[] = [];
      for (var i = 0; i < this.inspectionincludedate.length; i++) {
        if (this.inspectionincludedate[i].isActive == true) {
          theinspectDateinclude.push(this.inspectionincludedate[i]);
        }
      }
      if (theinspectDateinclude[theinspectDateinclude.length - 1].includeDate != null) {
        var activities = this.initializedate2();
        this.inspectionincludedate.push(activities);
      }
      else {
        this.date1error = "Please Fill the above field to add another item !";
      }
    }
    else {
      var activities = this.initializedate2();
      this.inspectionincludedate.push(activities);
    }
    return false;
  }

  Includedateevent(includeDate: Date) {
    this.date1error = "";
  }

  //----------------------add-----includedate------------------

  //-----------------------remove---excludedate--------------------

  public removeException(Id: number) {
    debugger;
    const index = Id;
    //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
    this.totalActiveItems = 0;
    for (let i = 0; i < this.inspectionexcludedate.length; i++) {
      if (this.inspectionexcludedate[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.inspectionexcludedate[index].excludeDate = null;
    }
    else {
      this.inspectionexcludedate[index].isActive = false;
    }
    console.log(index);
    this.dateerror = "";
    return false;
  }

  //-----------------------remove---excludedate--------------------
  //-----------------------remove---includedate--------------------

  public removeAddition(Id: number) {
    debugger;
    const index = Id;
    //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
    this.totalActiveItems = 0;
    for (let i = 0; i < this.inspectionincludedate.length; i++) {
      if (this.inspectionincludedate[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.inspectionincludedate[index].includeDate = null;
    }
    else {
      this.inspectionincludedate[index].isActive = false;
    }
    console.log(index);
    this.date1error = "";
    return false;
  }

  //-----------------------remove---includedate--------------------



  onPeriodicalChange(event: number) {
    //debugger;
    var type = this.repeatsdropdown.find(x => x.systemCodeId === event).type;
    if (type == 'Weekly') {
      this.periodicalcheck = "Week";
      this.showWeekly = true;
      this.showMonthly = false;
      this.Repeat = true;
      this.clearModelMonth();
      this.clearMonth();
    }
    else if (type == 'Monthly') {
      this.periodicalcheck = "Month";
      this.showWeekly = false;
      this.showMonthly = true;
      this.Repeat = false;
      this.clearWeek();
    }
    else if (type == 'Yearly') {
      this.periodicalcheck = "Year";
      this.showWeekly = false;
      this.showMonthly = true;
      this.Repeat = true;
      this.clearWeek();
    }
    else if (type == 'Daily') {
      this.periodicalcheck = "Single Day";
      this.showWeekly = false;
      this.showMonthly = false;
      this.Repeat = false;
      this.clearModelMonth();
      this.clearMonth();
      this.clearWeek();
    }
  }

  radioMonthDay(event) {
    //debugger;
    //console.log('name',event.target.name);

    if (event.target.value == 'byMonthDay') {
      this.monthDay = true;
      this.dayOn = false;
      this.inspectionModel.byDayOn = null;
      this.inspectionModel.byDay = null;
      if (this.inspectionModel.byDayOn == null || this.inspectionModel.byDay == null) {
        this.ByDateCheckDisable = false;
      }
      //this.clearModelMonth();
      this.clearMonth();
    }
    else if (event.target.value == 'byDayOn') {
      this.monthDay = false;
      this.dayOn = true;
      this.inspectionModel.byMonthDay = null;
      if (this.inspectionModel.byMonthDay == null) {
        this.ByDayCheckDisable = false;
      }
      this.clearModelMonth();
      //this.clearMonth();
    }

  }

  onMonthDayChange(MonthDayevent: number) {
    //debugger;
    if (MonthDayevent != undefined) {
      this.ByDayCheckDisable = true;
    } else {
      this.ByDayCheckDisable = false;
      //this.clearModelMonth();
    }
  }

  onDayOnChange(DayOnEvent: number) {
    //debugger;
    if (this.inspectionId > 0) {
      this.DayOnEventVar = this.inspectionModel.byDayOn;
      this.DateOnEventVar = this.inspectionModel.byDay;
    }
    this.DayOnEventVar = DayOnEvent;
    if (this.DayOnEventVar == undefined || this.DateOnEventVar == undefined) {
      this.ByDateCheckDisable = false;
      //this.clearMonth();
    } else {
      this.ByDateCheckDisable = true;
    }
  }
  onDayChange(DayEvent: number) {
    //debugger;
    if (this.inspectionId > 0) {
      this.DayOnEventVar = this.inspectionModel.byDayOn;
      this.DateOnEventVar = this.inspectionModel.byDay;
    }
    this.DateOnEventVar = DayEvent;
    if (this.DateOnEventVar != undefined && this.DayOnEventVar != undefined) {
      this.ByDateCheckDisable = true;
    } else {
      this.ByDateCheckDisable = false;
      //this.clearMonth();
    }
  }

  RadioOccurences() {
    this.inspectionModel.stopRepeating = null;
    this.AfterDateDisable = false;
    this.OccurenceDisable = true;
  }

  RadioDate() {
    this.inspectionModel.occurences = null;
    this.AfterDateDisable = true;
    this.OccurenceDisable = false;
  }

  public saveInspection() {
    debugger;
    //
    let formData: FormData = new FormData();
    formData.append("inspectionFileId", this.inspectionFileModel.inspectionFileId.toString());

    if (this.inspectionFileModel.inspectionFileName != undefined && this.inspectionFileModel.inspectionFileName != null) {
      formData.append("inspectionFileName", this.inspectionFileModel.inspectionFileName);
    }
    else {
      formData.append("inspectionFileName", "");
    }

    if (this.inspectionFile != undefined && this.inspectionFile != null) {
      formData.append("inspectionFileType", this.inspectionFileModel.inspectionFileType);
    }
    else {
      formData.append("inspectionFileType", "");
    }
    

    //

    this.inspectionModel.inspectionexcludedate = this.inspectionexcludedate;
    this.inspectionModel.inspectionincludedate = this.inspectionincludedate;
    this.inspectionModel.inspectionFiles = this.inspectionFileList;
    //this.inspectionModel.inspectionFiles = formData[0];

    if (this.inspectionModel.byMonthDay == null) {
      this.inspectionModel.byMonthDay = 0;
    }
    if (this.inspectionModel.byDayOn == null) {
      this.inspectionModel.byDayOn = 0;
    }
    if (this.inspectionModel.byDay == null) {
      this.inspectionModel.byDay = 0;
    }

    if (this.dayOn == true) {
      this.inspectionModel.january = this.january1;
      this.inspectionModel.february = this.february1;
      this.inspectionModel.march = this.march1;
      this.inspectionModel.april = this.april1;
      this.inspectionModel.may = this.may1;
      this.inspectionModel.june = this.june1;
      this.inspectionModel.july = this.july1;
      this.inspectionModel.august = this.august1;
      this.inspectionModel.september = this.september1;
      this.inspectionModel.october = this.october1;
      this.inspectionModel.november = this.november1;
      this.inspectionModel.december = this.december1;
    }

    if (this.inspectionId > 0) {
      this.inspectionService.updateInspection(this.inspectionId, this.inspectionModel).subscribe(res => {
        debugger;
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
            this.resetTheForm();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          //dialogRef.afterClosed().subscribe(result => {
          //  // this.GoBack();
          //});
        }
      });
    }
    else {
      this.inspectionService.saveInspection('SaveInspection', this.inspectionModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
          //dialogRef.afterClosed().subscribe(result => {
          //  //this.navigateToSearchUsers();
          //});
        }
      });
    }
  }

  show1() {
    if (this.showRepeat == true) {
      this.showRepeat = false;
    }
    else {
      this.showRepeat = true;

    }
  }

  show2() {
    if (this.showExcludeDate == true) {
      this.showExcludeDate = false;
    }
    else {
      this.showExcludeDate = true;
    }
  }


  show4() {
    if (this.showIncludeDate == true) {
      this.showIncludeDate = false;
    }
    else {
      this.showIncludeDate = true;
    }
  }


  resetTheForm() {
    this.inspectionForm.resetForm();
    this.inspectionModel = this.initializeInspectionModel();
    this.inspectionFileModel = this.initializeinspectionfile();
    var date1 = this.initializedate1();
    var date2 = this.initializedate2();
    this.inspectionincludedate = [];
    this.inspectionexcludedate = [];
    this.inspectionexcludedate.push(date1);
    this.inspectionincludedate.push(date2);
    this.showRepeat = false;
    this.showExcludeDate = false;
    this.showIncludeDate = false;
    this.showWeekly = false;
    this.showMonthly = false;
    this.ByDateCheckDisable = false;
    this.ByDayCheckDisable = false;
    return false;
  }

  clearMonth() {
    this.january1 = false;
    this.february1 = false;
    this.march1 = false;
    this.april1 = false;
    this.may1 = false;
    this.june1 = false;
    this.july1 = false;
    this.august1 = false;
    this.september1 = false;
    this.october1 = false;
    this.november1 = false;
    this.december1 = false;
  }

  clearModelMonth() {
    this.inspectionModel.january = false;
    this.inspectionModel.february = false;
    this.inspectionModel.march = false;
    this.inspectionModel.april = false;
    this.inspectionModel.may = false;
    this.inspectionModel.june = false;
    this.inspectionModel.july = false;
    this.inspectionModel.august = false;
    this.inspectionModel.september = false;
    this.inspectionModel.october = false;
    this.inspectionModel.november = false;
    this.inspectionModel.december = false;
  }

  clearWeek() {
    this.inspectionModel.sunday = false;
    this.inspectionModel.monday = false;
    this.inspectionModel.tuesday = false;
    this.inspectionModel.wednesday = false;
    this.inspectionModel.thursday = false;
    this.inspectionModel.friday = false;
    this.inspectionModel.saturday = false;
  }



  //private _filter(value: string): string[] {
  //  const filterValue = this._normalizeValue(value);
  //  return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  //}

  //private _filter1(value: string): string[] {
  //  //const filterValue = this._normalizeValue(value);
  //  ////return this.BusinessName.filter(businessName => this._normalizeValue(businessName).includes(filterValue));
  //  //return this.BusinessName.filter(BusinessName => this._normalizeValue(BusinessName).includes(filterValue));

  //  const filterValue = value.toLowerCase();
  //  return this.BusinessName.filter(BusinessName => BusinessName.toLowerCase().indexOf(filterValue) === 0);
  //}

  //private _normalizeValue(value: string): string {
  //  return value.toLowerCase().replace(/\s/g, '');
  //}


  //private _normalizeValue1(value: string): string {
  //  return value.toLowerCase().replace(/\s/g, '');
  //} 

}
