import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator, MatSort, MatDialog } from "@angular/material";
import { RolePermissionCheck } from "../models/role-permission-check.model";
import { ActivatedRoute, Router } from "@angular/router";
import { SystemCodeService } from "../services/system-code.service";
import { AuthService } from "../services/auth.service";
import { RosterAssignment } from "../models/rosterassignment.model";
import { forkJoin } from "rxjs";
import { SystemCodeKeyValue } from "../models/systemcodekeyvalue.model";
import { SystemCode } from "../models/system-codes.model";
import { DailyRoasterService } from "../services/dailyroaster.service";
import { Employee } from "../models/employee.model";
import { EmployeeService } from "../services/employee.service";
import { DailyRoasterAssignment } from "../models/dailyroasterassignment.model";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
@Component({
  selector: 'timesheetreport',  
  styleUrls: ['./timesheetreport.component.css'],
  templateUrl: './timesheetreport.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class TimeSheetReportComponent {

  @ViewChild('timesheetForm', { static: false }) timesheetForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  rolePermissionCheckModel: RolePermissionCheck;
  rosterassignmentModel: RosterAssignment;
  rosterAssignmentObj: RosterAssignment[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];
  personnelCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  employeeModelObj: Employee[] = [];
  startDate: Date;
  dailyRoasterObj: DailyRoasterAssignment[] = [];
  revisedAssignments: DailyRoasterAssignment[] = [];
  activityCodeName: string = "";
  personelName: string = "";
  constructor(
    private dialog: MatDialog,
    private systemCodeService: SystemCodeService, private dailyRoaster: DailyRoasterService,
    private authService: AuthService, private activatedRoute: ActivatedRoute, private routerService: Router, private employeeService: EmployeeService, private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isRead || this.rolePermissionCheckModel.isPrint)) {
      this.rosterassignmentModel = this.initializeRosterAssignmentModel();
      this.rosterassignmentModel.startDate = new Date();
      this.loadPersonnel();
      this.loadData();
      this.loadDropDowns();
    }
  }
  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: null, fromTime: null, toTime: null, startDate: null, endDate: null,
      isActive: true, stRosterId: 0, station: null
    } as RosterAssignment;
  }
  timesheetReport() {

  }
  public loadDropDowns() {

    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getAllActivityCode()
    ).subscribe(([res1, res2]) => {
     // this.personnelCode = res1 || [];
      this.activityCode = res2 || [];

      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || []; 
    
    });
  }
  public loadPersonnel() {

    this.employeeService.getEmployees("getEmployeesForShift", this.getDateAsString( this.rosterassignmentModel.startDate)).subscribe(
      res => {       
        this.employeeModelObj = res;
      });
  }
  search() {
    debugger;
    
    this.loadData();
  }
  loadData() {
    debugger;
    this.revisedAssignments = [];
    this.dailyRoaster.getTimeSheetView("getTimesheetView", this.rosterassignmentModel.startDate,
      this.rosterassignmentModel.activityCode ? this.rosterassignmentModel.activityCode:0,
      this.rosterassignmentModel.position ? this.rosterassignmentModel.position:0).subscribe(res => {
      this.dailyRoasterObj = res;
        this.getFormattedTable(this.dailyRoasterObj);
        const activityIndex = this.activityCodeKey.findIndex(x => x.key == this.rosterassignmentModel.activityCode);
        if (activityIndex >= 0) {
          this.activityCodeName = this.activityCodeKey[activityIndex].value;
        }
        else {
          this.activityCodeName = "";
        }
        const positionIndex = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.position);
        if (positionIndex >= 0) {
          this.personelName = this.employeeModelObj[positionIndex].lastName + ", " + this.employeeModelObj[positionIndex].miName + " "
            + this.employeeModelObj[positionIndex].firstName;
        }
        else {
          this.personelName = "";
        }
      console.log(res);

    });
  }
  shiftDateChange(dt: any) {
    debugger;
    console.log(dt);
    this.rosterassignmentModel.startDate = dt;
    this.loadPersonnel();
  }
  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
    var m = minutes % 60;
    let h1 = h < 10 ? '0' + h : h;
    let m1 = m < 10 ? '0' + m : m;
    return h1 + ':' + m1;
  }

  getFormattedTable(assignments: DailyRoasterAssignment[]) {
    let lastName = "";
    let personelMins = 0;
    debugger;
    for (let x = 0; x < assignments.length; x++) {

      if (lastName != assignments[x].roasterId) {
        if (lastName != "") {
          let objdata = this.InitializeModel();
          objdata.hours = personelMins.toString();
          objdata.type = "data";
          this.revisedAssignments.push(objdata);
          personelMins = 0;
        }
      }
      personelMins = personelMins + parseInt(assignments[x].hours);


      if (lastName != assignments[x].roasterId) {
        let obj1 = this.InitializeModel();
        obj1.personal = assignments[x].personal;
        obj1.type = "header";        
        this.revisedAssignments.push(obj1);  
        lastName = assignments[x].roasterId;
      }

      let obj = this.InitializeModel();
      obj.personal = assignments[x].personal;
      obj.type = "row";
      obj.crew = assignments[x].crew;
      obj.activity = assignments[x].activity;
      obj.hours = assignments[x].hours;
      obj.startDate = assignments[x].startDate;
      obj.endDate = assignments[x].endDate;
      obj.station = assignments[x].station;
      obj.unit = assignments[x].unit;
      this.revisedAssignments.push(obj);

    }
    if (personelMins >= 0) {
      let objdata = this.InitializeModel();
      objdata.hours = personelMins.toString();
      objdata.type = "data";
      this.revisedAssignments.push(objdata);
    }
  }
  InitializeModel(): DailyRoasterAssignment {
    return {
      roasterId: "",
      personal: "",
      station: "",
      position: "",
      unit: "",
      activity: "",
      startDate: "",
      endDate: "",
      hours: "",
      type: "", crew:""
    }
  }
  resetTheForm() {
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.rosterassignmentModel.startDate = new Date();
    this.loadPersonnel();
    this.loadData();
  }
  resetDate1() {
    debugger;
    this.rosterassignmentModel.startDate = null;
  }
  printReport() {
    debugger;
    let title = "Timesheet Report";
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 32;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setProperties({
      title: 'Timesheet_Report'
    });
    var totalPagesExp = '{total_pages_count_string}';
    doc.setFont('Calibri');
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 20)
    doc.setFontSize(10)

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
   // if (this.rosterassignmentModel.startDate != null) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Shift Date: ' + this.getDateAsString( this.rosterassignmentModel.startDate), pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis + 30, yAxis)
      count++;
   // }

   // if (this.rosterassignmentModel.activityCode != null) {
     // if (count == 1) {
        xAxis = xAxis + 50;
        count = 0;
    //  }
      var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize('Activity Code: ' + this.activityCodeName, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis + 30, yAxis)
      count++;
  //  }

   // if (this.rosterassignmentModel.position != null) {
     // if (count == 2) {
        xAxis = xAxis + 80;
        count = 0;
    //  }
      var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize('Personnel: ' + this.personelName, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis + 30, yAxis)
      count++;
    //}


    if (!this.checkPrintReportFilters()) {
      startYVariable = 40;
    } else {
      startYVariable = yAxis + 10;
    }
    var currentDate = new Date();
    var currentDateString = this.getDateAsString(currentDate);

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        crew: {
          cellWidth: 15
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri',  fontSize: 10  },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 230, pageHeight - 10);

       
        doc.text(currentDateString, 35, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    //doc.output('dataurlnewwindow');
    window.open(URL.createObjectURL(doc.output("blob")));
  }
  getHeadRows() {
    return [
      {
        personnel: 'Personnel', crew: 'Crew', activityCode: 'Activity Code',hours:'Hours',
        startdate: 'Start Date/Time To End Date/Time', station: 'Station', unit: 'Unit'
      }
    ]
  }

  getBody() {
    let rowCount = this.revisedAssignments.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {

      if (this.revisedAssignments[j].type == 'data') {
        body.push({
          personnel:'',
          crew: '',
          activityCode: '                   Total Hours - ',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt( this.revisedAssignments[j].hours)) : 'N/A',
          startdate: '',
          station: '',
          unit: ''
        });
      }
      if (this.revisedAssignments[j].type == 'row') {
        body.push({
          personnel: this.revisedAssignments[j].personal,
          crew: (this.revisedAssignments[j].crew) ? this.revisedAssignments[j].crew : '',
          activityCode: (this.revisedAssignments[j].activity) ? this.revisedAssignments[j].activity : '',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt( this.revisedAssignments[j].hours))+ " hours" : '',
          startdate: this.revisedAssignments[j].startDate + " to " + this.revisedAssignments[j].endDate,
          station: (this.revisedAssignments[j].station) ? this.revisedAssignments[j].station : '',
          unit: (this.revisedAssignments[j].unit) ? this.revisedAssignments[j].unit : ''
        });
      }
      if (this.revisedAssignments[j].type == 'header') {
        body.push({
          personnel: '',
          crew: '',
          activityCode: '',
          hours: this.revisedAssignments[j].personal,
          startdate: '',
          station: '',
          unit: ''
        });
      }

    }
    return body;
  }
  checkPrintReportFilters() {
    debugger;
    //if (this.rosterassignmentModel.startDate == null && this.rosterassignmentModel.activityCode == null && this.rosterassignmentModel) {
    //  return true;
    //}
    return true;
  }
}
