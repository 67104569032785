import { Component, Input, ChangeDetectorRef  } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DailyRoasterService } from '../services/dailyroaster.service';
import { AuthService } from '../services/auth.service';
import { DailyRoasterAssignment } from '../models/dailyroasterassignment.model';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import * as jspdf from 'jspdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import autoTable from 'jspdf-autotable';
@Component(
  {
    selector: 'dailyroasterview',
    templateUrl: './dailyroasterview.component.html'   
  })
export class DailyRoasterView implements OnInit {

  dailyRoasterObj: DailyRoasterAssignment[] = [];
  shiftDt: string = "";
  cId: number = 0;
  roasterId: string = null;
  displayId: string = null;
  totalHours: number = 0;
  allMins: number = 0;
  roasterTotal: number = 0;
  cName: string = "";
  report: string = "";
  constructor( private dailyRoasterService: DailyRoasterService,
    private authService: AuthService, private route: ActivatedRoute, private routerService: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let shiftdate = params['shiftdt']
      let cid = params['cid']
      let cname = params['cname']
      let repor=params['report']
      this.shiftDt = shiftdate;
      if (cid == null || cid == '' || cid == undefined) {
        this.cId = cid;
      }
      this.cName = cname;
      this.report = repor;
      this.loadData(this.cId, this.shiftDt );
    });
  }
  //ngAfterViewInit() {
  //  this.cdRef.detectChanges();
  //}
  loadData(cid: number, shiftdate: any) {
    this.dailyRoasterService.getDailyRoasterView("getDailyRoasterView",this.cId, shiftdate).subscribe(res => {
      this.dailyRoasterObj = res;
    });

  }
  backToHome() {
    if (this.report=="y")
    {
      this.routerService.navigate(['/stroasterreport']);
    }
      else {
      this.routerService.navigate(['/dailyroaster']);
    }
  }
  checkFirst(rosterId: string,index:number) {

    let mins = parseInt(this.dailyRoasterObj[index].hours);
    this.allMins = this.allMins + mins;
    this.roasterTotal = this.roasterTotal + mins;
    if (this.roasterId != rosterId) {        
      this.roasterId = rosterId;
     
      return true;
    }
    else {
      //this.allMins = 0;
      return false;
    }

  }
  totalDisplay(index: number) {
    let count = this.dailyRoasterObj.length;

    if (index < count-1) {
      if (this.dailyRoasterObj[index].roasterId != this.dailyRoasterObj[index + 1].roasterId) {
        
       // this.roasterTotal = parseInt(this.dailyRoasterObj[index].hours);
        return true;
      }
      else {
       // this.roasterTotal = 0;
      }
    }
    if (index == count-1) {
      return true;
    }
    return false;
    //this.dailyRoasterObj[index].roasterId
  }
  finalyDisplay(index) {
    let count = this.dailyRoasterObj.length;
    if (index == count - 1) {
      return true;
    }
    return false;
  }
  setRoasterTotalToZero(i) {
    let count = this.dailyRoasterObj.length;
    if (i < count - 1) {
      if (this.dailyRoasterObj[i].roasterId != this.dailyRoasterObj[i + 1].roasterId) {
        this.roasterTotal = 0;
        // this.roasterTotal = parseInt(this.dailyRoasterObj[index].hours);
        
      }
    }
    return "";
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
      var m = minutes % 60;
      let   h1 = h < 10 ? '0' + h : h;
      let   m1 = m < 10 ? '0' + m : m;
      return h1 + ':' + m1;
  }
  printPdf() {
    this.downloadInPdf();
   // this.loadData(this.cId, this.shiftDt);
    return false;
  }
  downloadInPdf() {   
    if (this.dailyRoasterObj.length > 0) {
        var data = document.getElementById('content');
        html2canvas(data).then(canvas => {
          // Few necessary setting options
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm', 'a4', true);
          var position = 0; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight; // top padding for other pages
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          doc.save('file.pdf'); // Generated PDF
       //   this.blockUI.stop();
          //setTimeout(function () {
          //  self.close();
          //}, 1500);
        });
      }    
      else {
        //  this.blockUI.stop();
        }
    return false;
  }
  
}

