import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { InspectionService } from '../services/inspection.service';
import { inspection } from '../models/inspection.model';
import { ActivatedRoute, Router } from '@angular/router';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'inspectioncalendar',
  changeDetection: ChangeDetectionStrategy.OnPush, 
  templateUrl: './inspectionCalendar.component.html',
})
export class InspectionCalendarComponent  {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  //actions: CalendarEventAction[] = [
  //  {
  //    label: '<i class="fas fa-fw fa-pencil-alt"></i>',
  //    a11yLabel: 'Edit',
  //    onClick: ({ event }: { event: CalendarEvent }): void => {
  //      this.handleEvent('Edited', event);
  //    },
  //  },
  //  {
  //    label: '<i class="fas fa-fw fa-trash-alt"></i>',
  //    a11yLabel: 'Delete',
  //    onClick: ({ event }: { event: CalendarEvent }): void => {
  //      this.events = this.events.filter((iEvent) => iEvent !== event);
  //      this.handleEvent('Deleted', event);
  //    },
  //  },
  //];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  //  [
  //  {
  //    id:1,
  //    start: subDays(startOfDay(new Date()), 1),
  //    end: addDays(new Date(), 1),
  //    title: 'A 3 day event',
  //    color: colors.red,
  //    actions: this.actions,
  //    allDay: true,
  //    resizable: {
  //      beforeStart: true,
  //      afterEnd: true,
  //    },
  //    draggable: true,
  //  },
  //  {
  //    id: 2,
  //    start: startOfDay(new Date()),
  //    title: 'An event with no end date',
  //    color: colors.yellow,
  //    actions: this.actions,
  //  },
  //  {
  //    id: 3,
  //    start: subDays(endOfMonth(new Date()), 3),
  //    end: addDays(endOfMonth(new Date()), 3),
  //    title: 'A long event that spans 2 months',
  //    color: colors.blue,
  //    allDay: true,
  //  },
  //  {
  //    id: 4,
  //    start: addHours(startOfDay(new Date()), 2),
  //    end: addHours(new Date(), 2),
  //    title: 'A draggable and resizable event',
  //    color: colors.yellow,
  //    actions: this.actions,
  //    resizable: {
  //      beforeStart: true,
  //      afterEnd: true,
  //    },
  //    draggable: true,
  //  },
  //];

  activeDayIsOpen: boolean = true;

  constructor(private routerService: Router, private route: ActivatedRoute,private inspectionService: InspectionService) {
   
  }

  ngOnInit() {   
    this.loadCalendar();
  }
  loadCalendar() {

    this.inspectionService.getAllInspections("GetInspection", 0).subscribe(
      (res: Array<inspection>) => {
        //  this.states = res || [];
        for (var i = 0; i < res.length; i++) {

          var newEvent = {
            id: res[i].inspectionId,
            start: startOfDay(res[i].inspectionDate),
            title: res[i].businessName + " - " + res[i].streetName,
            color: colors.yellow
          };
       
          this.events.push(newEvent);
          this.refresh.next();
        }

      }, (error: any) => { }
    );
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    //this.modal.open(this.modalContent, { size: 'lg' });

    this.routerService.navigate(['/inspection', { id: event.id }]);


  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
