import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatPaginator } from '@angular/material';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { EventCalendar } from '../models/eventCalendar.model';
import { EventCalendarExclude } from '../models/eventcalendarexclude.model';
import { GetAllEvent } from '../models/get-all-event.model';
import { SystemCode } from '../models/system-codes.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { SystemCodeService } from '../services/system-code.service';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { GetAllEventDisplay } from '../models/get-all-eventdisplay.model';
import { EventCalendarDisplay } from '../models/eventcalendardisplay.model';
//import { } from 'angular7-csv'

@Component({
  selector: 'eventreport',
  templateUrl: './eventreport.component.html',
  styleUrls: ['./eventreport.component.css']
})
export class EventReportComponent {
  @ViewChild('eventForm', { static: false }) eventForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selection = new SelectionModel<EventCalendar>(true, []);
  container: NgForm;
  id: number;
  eventCalendarId: number;
  eventDptId: number;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  isFound: boolean = false;
  showNewButton: boolean = false;
  resultsLength: number = 0;
  isViewReport: boolean = false;
  isViewReport1: boolean = false;
  fromDate: Date;
  toDate: Date;
  datehint: string = "";
  datehint1: string = "";
  eventDptModel: EventCalendar;
  eventDptResultModel: EventCalendarDisplay[] = [];
  eventTypeValues: string[] = [];
  fromDateValues: string[] = [];
  fromDateKey: Date[] = [];
  eventTypeKeys: number[] = [];
  @BlockUI() blockUI: NgBlockUI;
  eventTypeKey: SystemCodeKeyValue[] = [];
  eventCalendarModel: EventCalendar;
  eventCalendarModels: EventCalendar;
  eventdptModel: EventCalendarDisplay[] = [];
  eventResutModel: EventCalendar[];
  eventTypes: SystemCode[] = [];
  periodicals: SystemCode[] = [];
  eventDate: EventCalendarExclude[] = [];
  //eventTime: EventTime[] = [];
  paginatorid: MatPaginator;
  dataSource: MatTableDataSource<EventCalendarDisplay>;
  floatLabel: string = 'never';
  eventStatus: string = "";
  eventtypeString: string;
  maxrec: number = 15;
  rolePermissionCheckModel: RolePermissionCheck;
  displayedColumns: any[] = ['title', 'startDate', 'eventType'];
  //toDate 'select',

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private eventService: EventService) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.loadDropDowns();
      // this.loadGrid();
      this.GridSortAndPage();
      this.initializeActionsForDisplayedColumns();
      this.eventCalendarModel = this.initializeeventCalendarModel();
      this.eventCalendarModels = this.initializeeventCalendarModel();

      this.dataSource = new MatTableDataSource(this.eventdptModel);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;    //  this.getEmpName(1);
      const sortState: Sort = { active: 'eventTitle', direction: 'desc' };
      // this.sort.active = sortState.active;
      // this.sort.direction = sortState.direction;
      //this.sort.sortChange.emit(sortState);

      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.eventCalendarId = parseInt(Id);
          this.eventDptId = this.eventCalendarId;
        }
      });
      this.eventDptId = this.eventCalendarId;
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.blockUI.start();
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.stop();
            //if (this.isViewReport == true) {
            //  this.paginator.pageIndex = 0;
            //  this.paginator.pageSize = 50;
            //}
            //else {
            //  this.paginator.pageIndex = 0;
            //  this.paginator.pageSize = 15;
            //}
            return this.getAllEventDpt(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.eventdptModel = data || [];

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.eventdptModel);
            this.dataSource.sort = this.sort;
          }, 500);

          //for (let i = 0; i < this.eventdptModel.length; i++) {
          //  this.eventdptModel[i].description = this.eventTypes.find(x => x.systemCodeId === this.eventdptModel[i].eventType).type;
          //}

          //this.dataSource.paginator = this.paginator;
          this.blockUI.stop();
        });
      this.blockUI.stop();
    }
  }

  InitialLoad() {
    this.blockUI.start();
    //pagination subscription
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.blockUI.stop();
          //if (this.isViewReport == true) {
          //  this.paginator.pageIndex = 0;
          //  this.paginator.pageSize = 50;
          //}
          //else {
          //  this.paginator.pageIndex = this.paginator.pageIndex + 1;
          //  this.paginator.pageSize = 15;
          //}
          return this.getAllEventDpt(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.blockUI.stop();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.eventdptModel = data || [];
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.eventdptModel);
          this.dataSource.sort = this.sort;
        }, 500);

        //for (let i = 0; i < this.eventdptModel.length; i++) {
        //  this.eventdptModel[i].description = this.eventTypes.find(x => x.systemCodeId === this.eventdptModel[i].eventType).type;
        //}
        //this.dataSource.paginator = this.paginator;
        this.blockUI.stop();
      });

  }

  getAllEventDpt(pageIndex: number, pageSize: number): Observable<GetAllEventDisplay> {
    return this.eventService.getAllEventDptNew(
      'GetEventDptNew',
      (this.eventCalendarModels.fromDate != null) ? this.eventCalendarModels.fromDate : null,
      (this.eventCalendarModels.toDate != null) ? this.eventCalendarModels.toDate : null,
      (this.eventCalendarModels.eventType) ? this.eventCalendarModels.eventType : 0,
      pageIndex,
      pageSize
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  //masterToggle() {
  //  this.isAllSelected() ?
  //    this.selection.clear() :
  //    this.dataSource.data.forEach(row => this.selection.select(row));
  //}

  //logSelection() {
  //}

  getAllEventDptForDataSource(): void {
    this.blockUI.start();
    //if (this.isViewReport == true) {
    //  this.paginator.pageIndex = 0;
    //  this.paginator.pageSize = 50;
    //}
    this.getAllEventDpt(this.paginator.pageIndex, this.paginator.pageSize)
      .pipe(
        map(data => {
          this.blockUI.stop();
          this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.eventdptModel = data || [];
        //this.dailylogModelsDB.data = data || [];        
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.eventdptModel);
          this.dataSource.sort = this.sort;
        }, 500);

        //for (let i = 0; i < this.eventdptModel.length; i++) {
        //  this.eventdptModel[i].description = this.eventTypes.find(x => x.systemCodeId === this.eventdptModel[i].eventType).type;
        //}
        this.blockUI.stop();
      });
    this.blockUI.stop();
  }

  getAllEventDptForDataSource1(): void {
    this.blockUI.start();
    //if (this.isViewReport == true) {
    //  this.paginator.pageIndex = 0;
    //  this.paginator.pageSize = 50;
    //}
    this.getAllEventDpt(this.paginator.pageIndex, 1000)
      .pipe(
        map(data => {
          this.blockUI.stop();
          this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.eventDptResultModel = data || [];
        //setTimeout(() => {
        //  this.dataSource = new MatTableDataSource(this.eventDptResultModel);
        //  this.dataSource.sort = this.sort;
        //}, 500);
        this.blockUI.stop();
      });
    this.blockUI.stop();
  }
  /*********************************** */

  initializeeventCalendarModel(): EventCalendar {
    return {
      eventCalendarId: 0, eventTitle: null, eventType: 0, fromDate: null, toDate: null, description: null,
      eventTimeRange: null, eventEndTimeRange: null, duration: 0, periodical: 0, dailyOption: 0,
      dailyOccurenceCount: 0, weeklyOccurrenceCount: 0,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      byMonthDayM: 0, byDayMData: null, byDayOnM1: 0, byDayM1Data: null, yearlyMonthM1: 0, byMonthDayY: 0, byDayYData: null, byDayOnY1: 0,
      byDayY1Data: null, yearlyMonthY1: 0, recurrenceFromDate: null, recurrenceToDate: null, rangeOccurrenceCount: 0,
      eventDate: null, eventInclude: null, excludeCheck: false, includeCheck: false, endDateCheck: false, repeatCheck: false
    } as EventCalendar;
  }

  loadGrid() {
    //this.eventService.getAllEvent().subscribe(
    //  (res: Array<EventCalendar>) => {
    //    this.eventdptModel = res || [];
    //    this.eventDptId = this.eventCalendarId;
    //    this.GridSortAndPage();
    //  }, (error: any) => {
    //  });
    //this.GridSortAndPage();
  }

  editevent(id: number) {
    this.routerService.navigate(['eventscreen', { id }]);
  }

  getEventType(eventTypeKey: number) {
    //if (this.eventCalendarModel.eventType > 0) {
    let eventTypeEntity = this.eventTypes.find(x => x.systemCodeId === eventTypeKey);
    if (eventTypeEntity) {
      return eventTypeEntity.type;
    }
    // }
  }

  viewReport() {
    // this.isViewReport = true;
    //this.setCompanyTrainingPrintReportFilters();
  }

  //setCompanyTrainingPrintReportFilters(): void {
  //  //this.eventCalendarModels.trainingType = 'Company/EMS Company Training Report';  

  //  if (this.eventdptModel.crew > 0) {
  //    let crewEntity = this.crew.find(x => x.systemCodeId === this.emstrainingObj.crew);
  //    if (crewEntity) {
  //      this.companyTrainingPrintReportFilters.crewName = crewEntity.type;
  //    }
  //  }

  //  if (this.eventdptModel.station > 0) {
  //    let stationEntity = this.station.find(x => x.systemCodeId === this.emstrainingObj.station);
  //    if (stationEntity) {
  //      this.companyTrainingPrintReportFilters.stationName = stationEntity.type;
  //    }
  //  }

  //  if (this.eventdptModel.startDate) {
  //    let startDateString = new Date(this.emstrainingObj.startDate);
  //    let currentStartDateString = (startDateString.getMonth() + 1).toString() + '/' + startDateString.getDate().toString() + '/' + startDateString.getFullYear().toString();
  //    this.companyTrainingPrintReportFilters.startDate = currentStartDateString;
  //  }


  //}

  toggleViewTomakeFormVisible() {
    // this.isViewReport = true;
  }

  //downloadInPdf(eventCalendarModels: EventCalendar) {
  //  this.eventCalendarModel = eventCalendarModels;
  //  //this.eventCalendarModel.eventCalendarId = 1;
  //  this.routerService.navigate(['eventpdfgenerator']);
  //  //this.routerService.navigate(['eventpdfgenerator', eventCalendarModels]);
  //  //this.routerService.navigate([`eventpdfgenerator`, this.eventCalendarModel.eventCalendarId], { state: { ...this.eventCalendarModel } }); 

  //  //this.routerService.navigate([]).then(result => {
  //  //  window.open('eventpdfgenerator', '_blank');
  //  //});;

  //  //localStorage.setItem('eventdptModel', JSON.stringify(this.eventdptModel));
  //  //return false;
  //}

  viewEvent(id: number) {
    this.id = id;
    this.gotoupdate(this.id);
  }

  resetTheForm() {
    this.eventForm.resetForm();
    this.eventCalendarModels = this.initializeeventCalendarModel();
  }

  ResetEventReport() {
    this.blockUI.start();
    this.showNewButton = false;
    this.resetTheForm();
    this.loadGrid();
    this.getAllEventDptForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.eventStatus = "";
    this.blockUI.stop();
  }

  gotoupdate(id: number) {
    this.routerService.navigate(['eventupdate', { id }]);
  }

  AddNewEvent() {
    this.routerService.navigate(['eventscreen']);
  }

  GridSortAndPage() {
    //this.dataSource = new MatTableDataSource(this.eventdptModel);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  public searchEvent() {
    this.blockUI.start();
    this.showNewButton = true;
    if (!((this.eventCalendarModels.fromDate == null) && (this.eventCalendarModels.toDate == null) && (this.eventCalendarModels.eventType == null || this.eventCalendarModels.eventType == 0))) {
      this.showNewButton = true;
      this.blockUI.stop();
      //this.showSearchMsg = false;
    } else {
      //this.showSearchMsg = true;
      this.blockUI.stop();
      return;
    }
    //this.eventService.eventSearch('eventSearch', this.eventCalendarModels).subscribe(res => {
    //  this.eventdptModel = res;
    //  this.GridSortAndPage();
    //  this.blockUI.stop();
    //});
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.getAllEventDptForDataSource();
    this.blockUI.stop();
  }

  deleteEvent(Id: number, repeatCheck: boolean) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1,
        recurring: repeatCheck
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.blockUI.start();

        this.eventService.deleteEntireEvent('deleteEntireEvent', Id).subscribe(res => {
          this.errMsg = res.msg;
          this.getAllEventDptForDataSource();
          if (this.errMsg == "Deleted Successfully.") {
            const dialogRef = this.dialog.open(SaveDialogComponent, {
              width: '500px',
              height: '200px',
              data: { name: "Deleted Successfully!" }
            });
            this.blockUI.stop();
          }
          this.loadGrid();
          // this.blockUI.stop();
          this.GridSortAndPage();
        });
      }
    });
    this.blockUI.stop();
    return false;
  }

  dateevent1(fromDate: Date) {
    this.fromDate = fromDate;
    if (this.toDate == null) {
      this.datehint1 = "Please Enter End Date";
    } else {
      this.datehint1 = "";
    }
    if (this.eventCalendarId > 0) {
      this.toDate = this.eventCalendarModel.toDate;
    }
    this.datehint = "";
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }

  dateevent2(toDateev: Date) {
    this.toDate = toDateev;
    if (this.fromDate == null) {
      this.datehint = "Please Enter Start Date";
    } else {
      this.datehint1 = "";
    }
    if (this.eventCalendarId > 0) {
      this.fromDate = this.eventCalendarModel.fromDate;
    }
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }

  getFormattedDate(dt: Date): string {
    var nullDate = new Date("0001-01-01T00:00:00");
    //var nullDate1 = new Date("0001-01-01T00:00:00");
    //var nullDate1 = new Date("0001-01-01T00:00:00");
    if (new Date(dt).getTime() == new Date(nullDate).getTime() || dt == null) {
      return "N/A";
    } else {
      var today = new Date(dt);
      return today.toLocaleDateString();
    }
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllEventType(),
      this.systemCodeService.getAllRepeatsPeriod(),
    ).subscribe(([res1, res2]) => {
      this.eventTypes = res1 || [];
      this.periodicals = res2 || [];
      this.eventTypeKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  /*******************************************/

  downloadCSV() {
    var data = this.getBody();
    //new AngularCsv(data, 'My Report');
  }

  printReport() {
    this.blockUI.stop();

    setTimeout(() => {
      // alert("second");
      let title = "Department Event Report";
      let count: number = 0;
      let xAxis: number = 15;
      let yAxis: number = 30;
      let alignment: number = 0;
      let startYVariable = 40;
      var doc = new jsPDF(); /*'l', 'pt'*/

      doc.setProperties({
        title: 'Event Report'
      });
      var totalPagesExp = '{total_pages_count_string}';
      doc.setTextColor(40)
      doc.setFontSize(16)
      var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(title, textOffset, 20)
      doc.setFontSize(10)

      if (this.eventCalendarModels.eventType == undefined) {
        this.eventCalendarModels.eventType = null;
      }
      let eventTypeName = 'Event Type : ';
      if (this.eventCalendarModels.eventType) {
        let eventTypeEntity = this.eventTypes.find(x => x.systemCodeId == this.eventCalendarModels.eventType);
        if (eventTypeEntity) {
          eventTypeName = eventTypeEntity.type;
        }
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(eventTypeName, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      let startDate = 'Start Date: ';
      if (this.eventCalendarModels.fromDate) {
        let startDateString = new Date(this.eventCalendarModels.fromDate);
        let startDateFormat = this.getDateAsString(startDateString);
        startDate = startDate + startDateFormat;
      }
      let endDate = 'End Date: ';
      if (this.eventCalendarModels.toDate) {
        let endDateString = new Date(this.eventCalendarModels.toDate);
        let endDateFormat = this.getDateAsString(endDateString);
        endDate = endDate + endDateFormat;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      if (!this.checkPrintReportFilters()) {
        startYVariable = 50;
      } else {
        startYVariable = yAxis + 10;
      }

      autoTable(doc, {
        rowPageBreak: 'avoid',
        headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
        bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
        head: this.getHeadRows(),
        body: this.getBody(),
        didDrawPage: function (data) {
          var str = 'Page ' + doc.getNumberOfPages()
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(9)

          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, 180, pageHeight - 10);

          let currentDate = new Date();
          let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
          let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
          let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
          doc.text(currentDateString, 15, pageHeight - 10);
        },
        startY: yAxis
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      window.open(URL.createObjectURL(doc.output("blob")));

      this.isViewReport = false;
      this.searchEvent();
      //this.getAllEventDptForDataSource1();
      // this.InitialLoad();
      this.blockUI.stop();
    }, 1000);
    this.isViewReport = true;
    //for (let i = 0; i < this.eventdptModel.length; i++) {
    //  this.eventdptModel[i].description = this.eventTypes.find(x => x.systemCodeId === this.eventdptModel[i].eventType).type;
    //}
    this.getAllEventDptForDataSource1();
    if (this.eventCalendarModels.eventType == 0 && this.eventCalendarModels.fromDate == null && this.eventCalendarModels.toDate == null) {
      // this.InitialLoad();
    } else {
      //this.searchEvent();
    }

  }

  getDateAsString(dt: Date): string {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  getHeadRows() {
    return [
      {
        eventTitle: 'Event Title', fromDate: 'Event Date', eventType: 'Event Type'
      }
    ]
  }

  getBody() {
    let rowCount = this.eventDptResultModel.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      this.fromDateKey[j] = new Date(this.eventDptResultModel[j].startDate);
      this.eventTypeValues[j] = this.eventTypes.find(x => x.systemCodeId == this.eventDptResultModel[j].eventType).type.toString();

      let actionDateStringFormat = '';
      let newActionDate = new Date(this.fromDateKey[j]);
      actionDateStringFormat = this.getDateAsString(newActionDate);

      this.fromDateValues[j] = (this.fromDateKey[j].getMonth() + 1).toString() + '/' + this.fromDateKey[j].getDate().toString() + '/' + this.fromDateKey[j].getFullYear().toString();
      //if (this.eventTypeValues[j]!='') {
      //  this.eventdptModel[j].eventType = this.eventTypeValues[j];
      //}

      body.push({
        eventTitle: this.eventDptResultModel[j].title,
        fromDate: (this.fromDateValues[j]) ? actionDateStringFormat : '',
        eventType: this.eventTypeValues[j]
      })
    }
    return body
  }

  //getBody() {
  //  let rowCount = this.maintanenceModel.length;
  //  var body = []
  //  for (var j = 0; j < rowCount; j++) {
  //    body.push({
  //      requestdate: this.maintanenceModel[j].createdOn,
  //      actionrequested: (this.maintanenceModel[j].actionRequiredName) ? this.maintanenceModel[j].actionRequiredName : 'N/A',
  //      type: (this.maintanenceModel[j].typeName) ? this.maintanenceModel[j].typeName : 'N/A',
  //      description: (this.maintanenceModel[j].description) ? this.maintanenceModel[j].description : 'N/A',
  //      status: this.maintanenceModel[j].pendingCompleteName,
  //      workorder: this.maintanenceModel[j].workOrderNumber,
  //      updateorcomment: this.maintanenceModel[j].updateComments
  //    });
  //  }
  //  return body;
  //}

  checkPrintReportFilters(): boolean {
    if (this.eventCalendarModels.eventType || this.eventCalendarModels.fromDate || this.eventCalendarModels.toDate) {
      return true;
    }
    return false;
  }

  /*****************************************/

  //ngAfterViewInit() {
  //  this.dataSource.sort = this.sort;
  //}
}

interface jsPDFWithPlugin extends jsPDF {
  autoTableHtmlToJson(elem: HTMLElement);
  autoTable: (options: UserOptions) => jsPDF;
}
