import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (phoneNumber) {
      var mask = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !mask[2] ? mask[1] : '(' + mask[1] + ')' + '-' + mask[2] + (!mask[3] ? '' : '-' + mask[3]);
    }
    return null;
  }
}
