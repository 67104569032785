import { Injectable } from "@angular/core";
import { of, Observable } from 'rxjs';

@Injectable()
export class HeaderService {
  showHeader: Observable<boolean>;
  visible: Observable<boolean>;
  displayname: Observable<string>;

  constructor() { }

  hide() {
    this.visible = of(false);
  }

  show() {
    this.visible = of(true);
  }

  toggle() {
    this.visible = of(!this.visible);
  }
}
