import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { DepartmentUpdate } from '../models/departmentupdate.model';
import { ErrorService } from './error.service';
import { GetAllDepartmentUpdate } from '../models/get-all-departmentupdate.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class DepartmentUpdateService  extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/DepartmentUpdate/', httpClient, errorService, authService);
  }

  saveUpdate(action: string, deptupdateObj: DepartmentUpdate): Observable<any> {
    return this.postRequest(action, JSON.stringify(deptupdateObj));
  }

  updateDepartment(departmentUpdateId: number, deptupdateObj: DepartmentUpdate): Observable<any> {
    return this.putRequest(departmentUpdateId, JSON.stringify(deptupdateObj));
  }

  deleteDepartmentUpdate(action: string, departmentUpdateId: number): Observable<any> {
    return this.deleteRequest(departmentUpdateId);
  }

  getDeptUpdate(action: string, departmentUpdateId: number): Observable<any> {

    return this.customGetRequest<DepartmentUpdate>(action, departmentUpdateId.toString());
  }
  getUpdateList(action: string, departmentUpdateId: Number) {
    const params = new HttpParams()
      .set('departmentUpdateId', departmentUpdateId.toString());

    return this.customGetRequestWithQueryString<Array<DepartmentUpdate>>(action, params);
  }
  getAllDepartmentUpdateNew(action: string, subject: string, departmentType: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllDepartmentUpdate> {

    //debugger;
    const params = new HttpParams()
      .set('subject', subject)
      .set('departmentType', departmentType.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    console.log('Params: ', params);

    return this.customGetRequestWithQueryString<GetAllDepartmentUpdate>(action, params);
  }
  

}

