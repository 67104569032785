import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { SystemCodeService } from '../services/system-code.service';
import { DailyLogService } from '../services/dailylog.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { ResponseReportModel } from '../models/responseReport.model';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { startWith, map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { GetAllWaterUsage } from '../models/get-all-waterusage.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { WaterUsagePrintReportFilter } from '../models/water-usage-print-report-filters.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DailyLog } from '../models/dailylog.model';
import { GetAllDailyLogs } from '../models/get-all-dailyLogs.model';
@Component({
  selector: 'responsereport',
  templateUrl: './responsereport.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class ResponseReport {
  @ViewChild('responseReportForm', { static: false }) responseReportForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  container: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  waterUsageId: number;
  startDate: Date;
  endDate: Date;
  responseReportModelObj: ResponseReportModel[];
  responseModel: DailyLog[] = [];
  responseModelForAllData: GetAllDailyLogs;
  responseReports: ResponseReportModel[] = [];
  responseReportModel: ResponseReportModel;
  resultsLength: number = 0;
  //assignmentObjDB = new MatTableDataSource<Employee>([]);
  dataSource = new MatTableDataSource<DailyLog>([]);
  paginatorid: MatPaginator;
  sortid: MatSort;
  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date(2100, 11, 31);
  todayDate: Date = new Date();
  fromDate: any;
  displayedColumns: any[] = ['date', 'crew', 'station', 'totalMedAssists','totalFirstResponses'];
  rolePermissionCheckModel: RolePermissionCheck;
  showEndDateField: boolean = false;
  stationCodes: SystemCode[] = [];
  crews: SystemCode[] = [];
  responseReportPrintModel: Array<DailyLog> = [];

  constructor(
    private systemCodeStateService: SystemCodeService,
    private dailyLogservice: DailyLogService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private routerService: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.loadDropDowns();
      this.responseReportModel = this.initializeResponseReportModel();
      this.dataSource = new MatTableDataSource(this.responseModel);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  initializeResponseReportModel(): ResponseReportModel {
    return {
      crew: null,
      station: null,
      startDate: null,
      endDate: null
    } as ResponseReportModel;
  }
  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllDailyLog(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            //this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.responseModel = data || [];
          this.dataSource = new MatTableDataSource(this.responseModel);
          //this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //this.blockUI.stop();
        });
    }
   

  }

  getAllDailyLog(pageIndex: number, pageSize: number): Observable<GetAllWaterUsage> {
    return this.dailyLogservice.getDailyLogNew(
      'GetAllDailyLogNew',
      (this.responseReportModel.crew != undefined) ? this.responseReportModel.crew : 0,
      (this.responseReportModel.station != undefined) ? this.responseReportModel.station : 0,
      (this.responseReportModel.startDate) ? this.responseReportModel.startDate : null,
      (this.responseReportModel.endDate) ? this.responseReportModel.endDate : null,
      pageIndex,
      pageSize
    );
  }

  getAllDailyLogForDataSource(): void {
    this.getAllDailyLog(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
    ).subscribe(data => {
        this.responseModel = data || [];
      this.dataSource.data = data || [];
      this.dataSource = new MatTableDataSource(this.responseModel);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
        this.blockUI.stop();
      });
  }

/*********************************** */
  startdateevent(startDate: Date) {
    this.fromDate = startDate;
    this.showEndDateField = true;
    if (startDate == null) {
      this.showEndDateField = false;
      this.responseReportModel.endDate = null;
    }
  }

  resetTheForm() {
    this.blockUI.start();
    this.responseReportModel = this.initializeResponseReportModel();
    this.responseReportForm.resetForm();
    this.getAllDailyLogForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;   
    this.fromDate = new Date(2010, 0, 1);
    this.showEndDateField = false;
    this.blockUI.stop();
    return false;
  }
  public searchResponseReport() {
    this.blockUI.start();
    this.getAllDailyLogForDataSource();
    return false;
  }

  showResponseReport(): void {
    this.blockUI.start();
    this.dailyLogservice.getDailyLogNewForAllData(
      'GetAllDailyLogNewForAllData',
      (this.responseReportModel.crew != undefined) ? this.responseReportModel.crew : 0,
      (this.responseReportModel.station != undefined) ? this.responseReportModel.station : 0,
      (this.responseReportModel.startDate) ? this.responseReportModel.startDate : null,
      (this.responseReportModel.endDate) ? this.responseReportModel.endDate : null
    ).subscribe((res: Array<DailyLog>) => {
      this.responseReportPrintModel = res;
      this.blockUI.stop();
      this.pdf3();
    }, (error: any) => {
        this.responseReportPrintModel = [];
      this.blockUI.stop();
    });
  }


  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForStation(),
      this.systemCodeStateService.getSystemCodesForCrews()
    ).subscribe(([res1, res2]) => {
      this.stationCodes = res1 || [];
      this.crews = res2 || [];
    });

  }

  //*************************************
  pdf3() {
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 35;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF();
    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth('Response Report') * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text('Response Report', textOffset, 20)
    doc.setFontSize(10)
    if (this.responseReportModel.crew > 0) {
      let crewNameData = this.crews.find(x => x.systemCodeId === this.responseReportModel.crew);
      if (crewNameData) {
        var crewNameView = 'Crew: ' + crewNameData.type;
      }
      
    }
    else {
      crewNameView = 'Crew: ' + "";
    }
    if (this.responseReportModel.station > 0) {
      let stationNameData = this.stationCodes.find(x => x.systemCodeId === this.responseReportModel.station);
      if (stationNameData) {
        var stationNameView = 'Station: ' + stationNameData.type;
      }
      
    }
    else {
      stationNameView = 'Station: ' + "";
    }

    if (this.responseReportModel.startDate) {
      let startDateString = new Date(this.responseReportModel.startDate);
      let startDateFormat = (startDateString.getMonth() + 1).toString() + '/' + startDateString.getDate().toString() + '/' + startDateString.getFullYear().toString();
      var startDateFilterDisplay = 'Start Date: ' + startDateFormat;
    }
    else {
      startDateFilterDisplay = 'Start Date: ' + "";
    }
    if (this.responseReportModel.startDate) {
      let endDateString = new Date(this.responseReportModel.endDate);
      let endDateFormat = (endDateString.getMonth() + 1).toString() + '/' + endDateString.getDate().toString() + '/' + endDateString.getFullYear().toString();
      var endDateFilterDisplay = 'End Date: ' + endDateFormat;
    }
    else {
      endDateFilterDisplay = 'End Date: ' + "";
    }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(startDateFilterDisplay + ' - ' + endDateFilterDisplay, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });

    var text = doc.splitTextToSize(crewNameView + '  ' + stationNameView, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis + 5, { align: 'center' });
      startYVariable = yAxis + 10;
    //if (this.responseReportModel.startDate || this.responseReportModel.endDate ) {
    //  startYVariable = yAxis + 10;
    //} else {
    //  startYVariable = 30;
    //}


    autoTable(doc, {
      headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      bodyStyles: { minCellHeight: 8, fillColor: null },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 180, pageHeight - 10);

        var currentDate = new Date();
        var currentDateString = currentDate.getMonth() + 1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear();
        doc.text(currentDateString, 12, pageHeight - 10);
      },
      startY: startYVariable 
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    doc.output('dataurlnewwindow');
  }

  //checkPrintReportFilters(): boolean {
  //  if (this.responseReportModel.startDate || this.responseReportModel.endDate) {
  //    return true;
  //  }
  //  return false;
  //}

  getHeadRows() {
    return [
      {
        shiftDate: 'Date',
        crewName: 'Crew',
        stationName: 'Station',
        totalMedAssists: 'Total Med Assists',
        totalFirstResponses:'Total First Responses'
      }
    ]
  }

  getBody() {
    let rowCount: any = this.responseReportPrintModel.length; 
    var body = []
    for (var j = 0; j < rowCount; j++) {
      body.push({
        shiftDate: (this.responseReportPrintModel[j].shiftDate) ? (this.getDateAsString(new Date(this.responseReportPrintModel[j].shiftDate))) : 'N/A',
        crewName: this.responseReportPrintModel[j].crewName,
        stationName: this.responseReportPrintModel[j].stationName,
        totalMedAssists: this.responseReportPrintModel[j].totalMedAssists,
        totalFirstResponses: this.responseReportPrintModel[j].totalFirstResponses

      })
    }

    //for (var j = 0; j < rowCount; j++) {
    //  body.push({
    //    shiftDate: (this.responseModel[j].shiftDate) ? (this.getDateAsString(new Date(this.responseModel[j].shiftDate))) : 'N/A',
    //    crewName: this.responseModel[j].crewName,
    //    stationName: this.responseModel[j].stationName,
    //    totalMedAssists: this.responseModel[j].totalMedAssists,
    //    totalFirstResponses: this.responseModel[j].totalFirstResponses

    //  })
    //}
    return body
  }

  //getFilters(): string {
  //  let filtersText = '';

  //  if (this.waterUsagePrintReportFilters.startDate) {
  //    filtersText = filtersText + 'Start Date: ' + this.waterUsagePrintReportFilters.startDate + ' ';
  //  }

  //  if (this.waterUsagePrintReportFilters.endDate) {
  //    filtersText = filtersText + 'End Date: ' + this.waterUsagePrintReportFilters.endDate + ' ';
  //  }

  //  return filtersText;
  //}

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  //*************************************



}
