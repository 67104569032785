import { Component, Input } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { FormGroup, NgForm, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DepartmentUpdate } from '../models/departmentupdate.model';
import { DepartmentUpdateDescription } from '../models/departmentupdatedescription.model';
import { DepartmentUpdateService } from '../services/departmentupdate.service';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component(
  {
    selector: 'departmentupdateform',
    templateUrl: './departmentupdateform.component.html'
  })

export class DepartmentUpdateFormComponent implements OnInit {
  @ViewChild('departmentUpdateForm', { static: false }) departmentUpdateForm: NgForm;
  deptUpdateModel: DepartmentUpdate;
  deptupadteObjres: DepartmentUpdate[] = [];
  departmentupdatetype: SystemCode[] = [];
  departmentupdates: DepartmentUpdateDescription[] = [];
  createdOn: FormControl;
  subject: string;
  departmentUpdateId: number;
  x = new Date();
  floatLabel: string = 'never';
  whitespacepattern = /^[^\s]+([\s\S]+)*$/;
  disableSave: boolean = false;

  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  count: number = 0;
  clickcount: number = 0;
  click: boolean = false;


  ckeConfig: any;
  ckeditorContent: string = 'Description';
  log: string = '';
  name = 'ng2-ckeditor';
  departmentUpdateDescriptionModel: DepartmentUpdateDescription[] = [];
  errorhint11: string = "";
  errorhint1: string = "";
  Editor1Valid: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;
  enableButton: boolean = false;
  totalActiveItems: number = 0;

  constructor(private systemCodeStateService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private departmentUpdateService: DepartmentUpdateService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];

    route.params.subscribe(val => {

      if (val.id == undefined) {
        this.departmentUpdateId = 0;
        this.clearform();
      }
      else {
        this.departmentUpdateId = parseInt(val.id);
      }
    });
  }


  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      this.deptUpdateModel = this.initializedeptUpdateModel();
      this.ckeConfig = this.initializeCkeConfigModel();
      var departmentUpdateDescription = this.initializeDepartmentUpdateDescriptionModel();
      this.departmentUpdateDescriptionModel.push(departmentUpdateDescription);
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.departmentUpdateId = parseInt(Id);
        }
      });

      if (this.departmentUpdateId > 0) {
        this.departmentUpdateService.getDeptUpdate('GetUpdateLists', this.departmentUpdateId).subscribe(res => {          
          this.deptUpdateModel = res;
          //this.departmentUpdateDescriptionModel = res.departmentUpdateDescription;
          this.departmentUpdateDescriptionModel = res.descriptions;
          if (this.deptUpdateModel.descriptions != null) {
            this.Editor1Valid = true;
          } else {
            var departmentUpdateDescription = this.initializeDepartmentUpdateDescriptionModel();
            this.departmentUpdateDescriptionModel.push(departmentUpdateDescription);
          }
          this.enableButton = true;
          this.calculateTotalActiveItems();
          this.click = true;
        });
      }
      //else {      
      //  this.clearform();
      //}

    }
  }

  initializedeptUpdateModel(): DepartmentUpdate {
    return { createdOn: this.x, createdBy: null, departmentUpdateId: 0, subject: null, departmentType: null, descriptions: null, modifiedOn: null, modifiedBy: null } as DepartmentUpdate;
  }

  initializeDepartmentUpdateDescriptionModel(): DepartmentUpdateDescription {
    return {
      //descriptionUpdateId: 0,
      departmentUpdateDescriptionId: 0,
      description: '',
      isActive: true,
      departmentUpdateId: 0
    } as DepartmentUpdateDescription;
  }

  initializeCkeConfigModel(): any {
    return {
      allowedContent: false, extraPlugins: 'divarea', forcePasteAsPlainText: true, resize_enabled: false, autoGrow_onStartup: false, width: 950, height: 150, versionCheck: false
    } as any;
  }

  editorEvent1(editorString: string) {
    if (editorString == "") {
      //this.errorhint11 = "Please Fill the above field to add another item !";
      this.errorhint1 = "Description Editor Field is Required";
      this.Editor1Valid = false;
      this.enableButton = false;
    } else {
      this.errorhint11 = "";
      this.errorhint1 = "";
      this.Editor1Valid = true;
      this.enableButton = true;
    }
  }


  editorEvent11(editorString1: any) {
    let str: string;
    str = editorString1;
    str = str.replace("[\\n\\t ]", "");
    str = str.replace(/\s/g, '');
    str = str.replace(/\r?\n|\r/, '');
    editorString1 = str;
    // alert('hi' + 'editorString :' + editorString + ':');
    if (editorString1 == "" || editorString1 == '' || editorString1 == "'" || editorString1 == '"') {
      // this.errorhint11 = "Please Fill the above field to add another item !";
      this.errorhint1 = "Description Editor Field is Required";
      this.Editor1Valid = false;
      this.enableButton = false;
    }
    else {
      this.errorhint11 = "";
      this.errorhint1 = "";
      this.Editor1Valid = true;
      this.enableButton = true;
    }
  }

  addDepartmentUpdateDescription() {
    if (this.departmentUpdateDescriptionModel.length > 0) {
      let theDepUpdate: DepartmentUpdateDescription[] = [];
      for (var i = 0; i < this.departmentUpdateDescriptionModel.length; i++) {
        if (this.departmentUpdateDescriptionModel[i].isActive == true) {
          theDepUpdate.push(this.departmentUpdateDescriptionModel[i]);
        }
      }
      if (theDepUpdate[theDepUpdate.length - 1].description != '') {
        //this.clickcount = this.count++;
        //if (this.count <= 2) {
          var dupdate = this.initializeDepartmentUpdateDescriptionModel();
          this.departmentUpdateDescriptionModel.push(dupdate);
          this.Editor1Valid = false;
          this.enableButton = false;
        //}
        //else {
        //  this.click = true;
        //}
        this.errorhint11 = ""
      }
      else {
        this.errorhint11 = "Please Fill the above field to add another item !";
        this.enableButton = false;
        //this.Editor1Valid = false;
      }
    }
    else {
      var departmentUpdateDescription = this.initializeDepartmentUpdateDescriptionModel();
      this.departmentUpdateDescriptionModel.push(departmentUpdateDescription);
      //this.Editor1Valid = false;
    }

    this.calculateTotalActiveItems();
    return false;
  }

  calculateTotalActiveItems() {
    this.totalActiveItems = 0;
    for (let x = 0; x < this.departmentUpdateDescriptionModel.length; x++) {
      if (this.departmentUpdateDescriptionModel[x].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
      if (this.totalActiveItems > 0 && this.departmentUpdateDescriptionModel[x].description != '') {
        this.enableButton = true;
        this.Editor1Valid = true;
      }
      if (this.departmentUpdateDescriptionModel[x].description == '' && this.departmentUpdateDescriptionModel[x].isActive == true) {
        this.enableButton = false;
        this.Editor1Valid = false;
      }

    }
  }




  deleteDepartmentUpdateDescription(Id: number) {
    let index = Id;

    // const index = this.dlquaters.findIndex(x => x.quartersId == Id);
    if (index > 0) {
      this.departmentUpdateDescriptionModel[index].isActive = false;
      this.enableButton = false;
    }
    else {
      this.departmentUpdateDescriptionModel[index].description = null;
    }

    //if (this.clickcount == 3) {
    //  this.count = this.count-2;
    //}
    //else {
    //  this.count = this.count--;
    //}
    
    //this.click = false;
    this.errorhint11 = "";
    this.errorhint1 = "";
    this.Editor1Valid = true;
    this.enableButton = true;
    // this.errorhint11 = "";
    this.calculateTotalActiveItems();
    return false;
  }



  public departmentUpdateEdit() {


  }



  public loadDropDowns() {

    this.systemCodeStateService.getSystemCodesForDepartmentUpdate().subscribe(
      (res: Array<SystemCode>) => {
        this.departmentupdatetype = res || [];

      }, (error: any) => { }
    );
  }



  //public addnewitem() {
  //  this.clickcount = this.count++;

  //  if (this.count <= 2) {

  //    var update2 = {
  //      descriptionUpdateId: this.departmentupdates.length + 1,
  //      description: '',
  //      isActive: true
  //    };
  //    this.departmentupdates.push(update2);

  //  }
  //  else {
  //    this.click = true;      
  //  }
  //  return false;

  //}

  public departmentUpdate() {
    //this.deptUpdateModel.description = this.departmentupdates;
    this.disableSave = true;
    this.deptUpdateModel.descriptions = this.departmentUpdateDescriptionModel;

    if (this.departmentUpdateId > 0) {

      this.departmentUpdateService.updateDepartment(this.departmentUpdateId, this.deptUpdateModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.clearform();
            this.GoBack();
            //this.routerService.navigate(['/departmentupdateform']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });

        }

      });

    }

    else {

      this.departmentUpdateService.saveUpdate('SaveUpdate', this.deptUpdateModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.clearform();
            //this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });

        }

      });
    }

  }

  GoBack() {
    this.routerService.navigate(['/deptupdatereport']);
  }

  public clearform() {

    //this.deptUpdateModel = { createdOn: null, createdBy: "", departmentUpdateId: 0, subject: "", departmentType: 0, description: null };
    this.departmentUpdateForm.resetForm();
    this.deptUpdateModel = this.initializedeptUpdateModel();
    var departmentUpdateDescription = this.initializeDepartmentUpdateDescriptionModel();
    this.departmentUpdateDescriptionModel.splice(0, this.departmentUpdateDescriptionModel.length);
    this.departmentUpdateDescriptionModel.push(departmentUpdateDescription);
    //this.departmentupdates = [];
    //var update1 = {
    //  descriptionUpdateId: 0,
    //  description: '',
    //  isActive: true
    //};
    //this.departmentupdates.push(update1);

    this.ckeConfig = this.initializeCkeConfigModel();
    this.errorhint11 = "";
    this.errorhint1 = "";
    this.click = false;
    this.enableButton = false;
    this.Editor1Valid = false;
    this.disableSave = false;
    this.count = 0;
    return false;


  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
