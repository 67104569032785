import { Component, Input } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { FormGroup, NgForm, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DailyLogService } from '../services/dailylog.service';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DailyLog } from '../models/dailylog.model';
import { DlActivities } from '../models/dlactivities.model';
import { DlApparatus } from '../models/dlapparatus.model';
import { DlFlaggedEmsRun } from '../models/dlflaggedemsrun.model';
import { DlQuarters } from '../models/dlquarters.model';
import { DlFireRun } from '../models/dlfirerun.model';
import { DlEmsRun } from '../models/dlemsrun.model';
import { forkJoin } from 'rxjs'
import { MaintanenceRequestService } from '../services/maintanancerequest.service';
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { KeyValueTransformPipe } from '../common/pipes/key-value-transform.pipe';
import { CompanyTraining } from '../models/companytraining.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component(
  {
    selector: 'dailylog',
    templateUrl: './dailylog.component.html',
    styleUrls: ['./dailylog.component.css']
  })
export class DailyLogComponent implements OnInit {
  @ViewChild('dailyLogForm', { static: false }) dailyLogForm: NgForm;
  formGroup: FormGroup;
  ckeditorContent: string = 'Description';
  name = 'ng2-ckeditor';
  floatLabel: string = 'never';
  TextPattern = /[^a-zA-Z0-9]/;
  DatePattern = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  errorhint1: string = "";
  errorhint11: string = "";
  errorhint2: string = "";
  errorhint22: string = "";
  errorhint3: string = "";
  errorhint33: string = "";
  errorhint4: string = "";
  errorhint5: string = "";
  errorhint6: string = "";
  htmlStringVar: string;
  apparatusTypeCode: number = 0;
  apparatusTypeKey: string = "Apparatus/Vehicle/Boats";
  log: string = '';
  tagp1: string = '<p>';
  tagp2: string = '</p>';
  tagbreak: string = '<br />';
  typelabel: string = "Type : ";
  actionlabel: string = "Action Requested : ";
  descriptionlabel: string = "Description of Problem : ";
  pendinglabel: string = "Pending Status : ";
  workorderlabel: string = "Work Order Number : ";
  classNamelabel: string = "Training Class Name : ";
  trainingHourslabel: string = "Training Hours : ";
  categorylabel: string = "Training Category : ";
  instructorlabel: string = "Training Instructor : ";
  numberOfStudentslabel: string = "Number Of Students : ";
  typeName: string;
  htmlstring: string[] = [];
  htmlstring1: DlApparatus;
  htmlStringModel: DlApparatus[] = [];
  maintenenceRequestModel: MaintenenceRequest[] = [];
  maintenenceRequestModelTemp: MaintenenceRequest[] = [];
  companyTrainingModel: CompanyTraining[] = [];
  typeId: number;
  actionRequiredId: number;
  description: string;
  pendingOrCompleteId: number;
  workOrderNumber: string;
  activitiesHtmlstring: string[] = [];
  activitiesHtmlstring1: DlActivities;
  activitiesHtmlStringModel: DlActivities[] = [];
  trainingType: number;
  className: string;
  trainingHours: string;
  category: number;
  instructor: number;
  numberOfStudents: number;
  count: number = 0;
  clickcount: number = 0;
  totalActiveItems: number = 0;
  totalActiveItemsEms: number = 0;
  totalActiveItemsFire: number = 0;
  totalActiveItemsFlag: number = 0;
  totalActiveItemsQuarter: number = 0;
  totalActiveItemsApparatus: number = 0;
  totalActiveItemsActivities: number = 0;
  x = new Date();
  ckeConfig: any;
  PreEvent: string;
  PreEvent1: string;
  PreEvent2: string;
  PreEvent3: string;
  PreEvent4: string;
  // PreEvent2: string;
  subject: string;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  click: boolean = false;
  departmentUpdateId: number;
  Editor1Valid: boolean = false;
  Editor2Valid: boolean = false;
  Editor3Valid: boolean = false;
  resetOption: boolean = false;
  dailyLogId: number;
  emsRunNumber: number;
  flaggedEmsRunNumber: number;
  fireRunNumber: number;
  totalMedAssists: number;
  totalFirstResponses: number;
  dlApparatusModel: DlApparatus;
  dlActivitiesModel: DlActivities;
  shiftDate: Date;
  createdOn: FormControl;
  dlQuaters: DlQuarters[] = [];
  dlActivities: DlActivities[] = [];
  dlApparatus: DlApparatus[] = [];
  dlFlaggedEmsRun: DlFlaggedEmsRun[] = [];
  dlFireRun: DlFireRun[] = [];
  dlEmsRun: DlEmsRun[] = [];
  dailyLogModel: DailyLog;
  crewCode: SystemCode[] = [];
  stationCode: SystemCode[] = [];
  runvalueCode: SystemCode[] = [];
  maintanenceTypeKey: SystemCodeKeyValue[] = [];
  maintanenceActionKey: SystemCodeKeyValue[] = [];
  maintanencePendingKey: SystemCodeKeyValue[] = [];
  companyTrainingTypeKey: SystemCodeKeyValue[] = [];
  companyEmsCategoryKey: SystemCodeKeyValue[] = [];
  companyInstructorKey: SystemCodeKeyValue[] = [];
  rolePermissionCheckModel: RolePermissionCheck;
  fireRunDisable: boolean = true;
  quarterDisable: boolean = true;
  fireRunDeleteDisable: boolean = true;
  emsRunDisable: boolean = true;
  emsRunDeleteDisable: boolean = true;
  flagRunDisable: boolean = true;
  flagRunDeleteDisable: boolean = true;
  activitiesDisable: boolean = true;
  disableSave: boolean = false;
  // fireRunDisable: boolean = true;

  constructor(private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private dailyLogService: DailyLogService, private maintanenceRequestService: MaintanenceRequestService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      //this.disableSave = false;
      this.ckeConfig = this.initializeCkeConfigModel();
      this.dailyLogModel = this.initializedailyLogModelModel();

      this.htmlstring1 = this.initializeApparatusModel();
      this.activitiesHtmlstring1 = this.initializeActivitiesModel();
      this.dlApparatusModel = this.initializeApparatusModel();
      this.dlActivitiesModel = this.initializeActivitiesModel();

      var quarter = this.initializeQuarterModel();
      //var apparatus = this.initializeApparatusModel();
      //var activities = this.initializeActivitiesModel();
      var flaggedEmsRun = this.initializeFlaggedEmsRunModel();
      var emsRun = this.initializeEmsRunModel();
      var fireRun = this.initializeFireRunModel();

      this.dlQuaters.push(quarter);
      //this.dlApparatus.push(apparatus);   
      //this.dlActivities.push(activities);
      //this.htmlStringModel.push(apparatus);   
      //this.activitiesHtmlStringModel.push(activities);
      this.dlFlaggedEmsRun.push(flaggedEmsRun);
      this.dlEmsRun.push(emsRun);
      this.dlFireRun.push(fireRun);

      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          //this.ResetDailyLog();
          this.dailyLogId = parseInt(Id);
        } else {
          this.ResetDailyLog();
          this.dailyLogId = null;
        }
      });

      if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
        var apparatus = this.initializeApparatusModel();
        var activities = this.initializeActivitiesModel();
        if (this.dlApparatus.length == 0) {
          this.dlApparatus.push(apparatus);
        }
        if (this.dlActivities.length == 0) {
          this.dlActivities.push(activities);
        }

        //this.htmlStringModel.push(apparatus);   
        //this.activitiesHtmlStringModel.push(activities);
        //this.activitiesHtmlstring1.activities = "";
        //this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
        //this.htmlstring1.apparatus = "";
        //this.htmlStringModel.push(this.htmlstring1);
      }

      if (this.dailyLogId > 0) {
        this.dailyLogService.getDailyLog('getDailyLog', this.dailyLogId).subscribe(res => {
          this.dailyLogModel = res;
          this.dlQuaters = res.dlQuarters;
          this.dlActivities = res.dlActivities;
          this.dlApparatus = res.dlApparatuses;
          //this.activitiesHtmlStringModel = res.dlActivities;
          //this.htmlStringModel = res.dlApparatuses;       


          if (this.dlActivities.length != 0) {
            for (let i = 0; i < this.dlActivities.length; i++) {
              this.activitiesHtmlStringModel = this.dlActivities;
              //this.activitiesHtmlstring1.activities = this.dlActivities[i].activities;
              //this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
              // this.activitiesHtmlstring1 = this.initializeHtmlContentModel();
            }
          } else {
            var activities = this.initializeActivitiesModel();
            this.activitiesHtmlStringModel.push(activities);
            //this.activitiesHtmlstring1.activities = "";
            //this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
          }
          if (this.dlApparatus.length != 0) {
            for (let i = 0; i < this.dlApparatus.length; i++) {
              this.htmlStringModel = this.dlApparatus;
              //this.htmlstring1.apparatus = this.dlApparatus[i].apparatus;
              //this.htmlStringModel.push(this.htmlstring1);
              //this.htmlstring1 = this.initializeHtmlContentModel();
            }
          } else {
            var apparatus = this.initializeApparatusModel();
            this.htmlStringModel.push(apparatus);
            //this.htmlstring1.apparatus = "";
            //this.htmlStringModel.push(this.htmlstring1);
          }

          this.dlFlaggedEmsRun = res.dlFlaggedEmsRuns;
          this.dlEmsRun = res.dlEmsRuns;
          this.dlFireRun = res.dlFireRuns;

          if (this.dlFlaggedEmsRun.length > 0 && (this.dlFlaggedEmsRun[0].flaggedEmsRunNumber != null || this.dlFlaggedEmsRun[0].flaggedEmsRunNumber != '' || this.dlFlaggedEmsRun[0].flaggedEmsRunNumber != '')) {
            this.flagRunDisable = false;
          } else {
            this.flagRunDisable = true;
          }
          if (this.dlEmsRun.length > 0 && (this.dlEmsRun[0].emsRunNumber != null || this.dlEmsRun[0].emsRunNumber != '' || this.dlEmsRun[0].emsRunNumber != '')) {
            this.fireRunDisable = false;
          } else {
            this.fireRunDisable = true;
          }
          if (this.dlFireRun.length > 0 && (this.dlFireRun[0].fireRunNumber != null || this.dlFireRun[0].fireRunNumber != '' || this.dlFireRun[0].fireRunNumber != '')) {
            this.emsRunDisable = false;
          } else {
            this.emsRunDisable = true;
          }


          if (this.dailyLogModel.dlApparatuses != null) {
            this.Editor1Valid = true;
          } else {
            this.dlApparatus.push(apparatus);
          }
          if (this.dailyLogModel.dlQuarters != null) {
            this.Editor2Valid = true;
          } else {
            this.dlQuaters.push(quarter);
          }
          if (this.dailyLogModel.dlActivities == null) {
            this.dlActivities.push(activities);
          }
          if (this.dlFlaggedEmsRun.length == 0) {
            this.dlFlaggedEmsRun.push(flaggedEmsRun);
          }
          if (this.dlEmsRun.length == 0) {
            this.dlEmsRun.push(emsRun);
          }
          if (this.dlFireRun.length == 0) {
            this.dlFireRun.push(fireRun);
          }
          if (this.dlActivities.length > 1) {
            this.totalActiveItemsActivities = this.dlActivities.length;
          }
          if (this.dlApparatus.length > 1) {
            this.totalActiveItemsApparatus = this.dlApparatus.length;
          }
          if (this.dlQuaters.length > 1) {
            this.totalActiveItemsQuarter = this.dlQuaters.length;
          }

          if (this.dlEmsRun.length > 1) {
            this.totalActiveItemsEms = this.dlEmsRun.length;
          }
          if (this.dlFireRun.length > 1) {
            this.totalActiveItemsFire = this.dlFireRun.length;
          }
          if (this.dlFlaggedEmsRun.length > 1) {
            this.totalActiveItemsFlag = this.dlFlaggedEmsRun.length;
          }
        });
      }
    }
  }

  initializeCkeConfigModel(): any {
    return {
      allowedContent: false, extraPlugins: 'divarea', forcePasteAsPlainText: true, resize_enabled: false, autoGrow_onStartup: false, width: 950, height: 150
      , versionCheck: false
    } as any;
  }

  initializedailyLogModelModel(): DailyLog {
    return {
      dailyLogId: 0, shiftDate: null, station: null, crew: null, totalMedAssists: null, totalFirstResponses: null,
      dlActivities: null, dlApparatuses: null, dlFlaggedEmsRuns: null, dlQuarters: null, dlFireRuns: null, dlEmsRuns: null, stationName: null, crewName: null
    } as DailyLog;
  }

  initializeQuarterModel(): DlQuarters {
    return {
      quartersId: 0, quarters: '', isActive: true, dailyLogId: 0
    } as DlQuarters;
  }

  initializeApparatusModel(): DlApparatus {
    return {
      apparatusId: 0, apparatus: '', isActive: true, dailyLogId: 0
    } as DlApparatus;
  }

  initializeActivitiesModel(): DlActivities {
    return {
      activitiesId: 0, activities: '', isActive: true, dailyLogId: 0
    } as DlActivities;
  }

  initializeFlaggedEmsRunModel(): DlFlaggedEmsRun {
    return {
      flaggedEmsRunId: 0, flaggedEmsRunNumber: null, flaggedEmsRunValue: null, isActive: true, dailyLogId: 0
    } as DlFlaggedEmsRun;
  }

  initializeEmsRunModel(): DlEmsRun {
    return {
      emsRunId: 0, emsRunNumber: null, emsRunAddress: "", isActive: true, dailyLogId: 0
    } as DlEmsRun;
  }

  initializeFireRunModel(): DlFireRun {
    return {
      fireRunId: 0, fireRunNumber: null, fireRunAddress: "", isActive: true, dailyLogId: 0
    } as DlFireRun;
  }

  initializeMaintanenceRequestModel(): MaintenenceRequest {
    return {
      maintenenceRequestId: 0, stationId: null, crewId: null, typeId: null, actionRequiredId: null, description: null, pendingOrCompleteId: null,
      workOrderNumber: null, isActive: true, pendingCompleteName: null, actionRequiredName: null, crewName: null, stationName: null, typeName: null,
      createdOn: null, updateComments: null, subTypeId: null
    } as MaintenenceRequest;
  }

  initializeHtmlContentModel(): HtmlContent {
    return {
      htmlString: null
    } as HtmlContent;
  }

  onDateChange(shiftDate: Date) {
    this.dailyLogModel.shiftDate = shiftDate;
    if (this.dailyLogModel.shiftDate != null && this.dailyLogModel.station > 0 && this.dailyLogModel.crew > 0) {
      this.editorFill(this.dailyLogModel);
    } else {

    }
  }

  onStationChange(station: number) {
    this.dailyLogModel.station = station;
    if (this.dailyLogModel.shiftDate != null && this.dailyLogModel.station > 0 && this.dailyLogModel.crew > 0) {
      this.editorFill(this.dailyLogModel);
    }
  }

  onCrewChange(crew: number) {
    this.dailyLogModel.crew = crew;
    if (this.dailyLogModel.shiftDate != null && this.dailyLogModel.station > 0 && this.dailyLogModel.crew > 0) {
      this.editorFill(this.dailyLogModel);
    }

  }

  editorFill(dailyLogModel: DailyLog) {
    //if (this.dlApparatus.length != 0 && (this.dailyLogId == 0 || this.dailyLogId == undefined)) {}
    if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
      this.dlApparatus = [];
      this.htmlStringModel = [];
    } else if (this.dailyLogId > 0) {
      this.dlApparatus = [];
      this.htmlStringModel = [];
      this.dailyLogModel.dlActivities.forEach(x => x.isActive = false);
      this.dailyLogModel.dlApparatuses.forEach(x => x.isActive = false);
    }
    this.dailyLogModel = dailyLogModel;
    this.dailyLogModel.shiftDate = new Date(this.dailyLogModel.shiftDate);
    if (this.dailyLogModel.station == null) {
      this.dailyLogModel.station = 0;
    } if (this.dailyLogModel.crew == null) {
      this.dailyLogModel.crew = 0;
    }
    this.dailyLogService.getMaintanenceByDailyLog('getMaintanenceByDailyLog',
      (this.dailyLogModel.shiftDate != null) ? this.dailyLogModel.shiftDate : null,
      (this.dailyLogModel.station) ? this.dailyLogModel.station : 0,
      (this.dailyLogModel.crew) ? this.dailyLogModel.crew : 0
    ).subscribe(res => {
      this.dlApparatus = [];
      this.htmlStringModel = [];
      this.maintenenceRequestModel = [];
      this.dlApparatusModel = this.initializeApparatusModel();

      this.maintenenceRequestModelTemp = res || [];

      for (let mn = 0; mn < this.maintenenceRequestModelTemp.length; mn++) {
        if (this.maintenenceRequestModelTemp[mn].typeId == this.apparatusTypeCode) {
          this.maintenenceRequestModel.push(this.maintenenceRequestModelTemp[mn]);
        }
      }
      //      

      let index = this.maintenenceRequestModel.length;
      for (let j = 0; j < index; j++) {
        this.typeId = this.maintenenceRequestModel[j].typeId;
        this.actionRequiredId = this.maintenenceRequestModel[j].actionRequiredId;
        this.description = this.maintenenceRequestModel[j].description;
        this.pendingOrCompleteId = this.maintenenceRequestModel[j].pendingOrCompleteId
        this.workOrderNumber = this.maintenenceRequestModel[j].workOrderNumber;

        for (let i = 0; i < this.maintanenceTypeKey.length; i++) {
          if (this.maintanenceTypeKey[i].key == this.typeId) {
            this.maintenenceRequestModel[j].typeName = this.maintanenceTypeKey[i].value;
          }
        }

        for (let i = 0; i < this.maintanenceActionKey.length; i++) {
          if (this.maintanenceActionKey[i].key == this.actionRequiredId) {
            this.maintenenceRequestModel[j].actionRequiredName = this.maintanenceActionKey[i].value;
          }
        }
        for (let i = 0; i < this.maintanencePendingKey.length; i++) {
          if (this.maintanencePendingKey[i].key == this.pendingOrCompleteId) {
            this.maintenenceRequestModel[j].pendingCompleteName = this.maintanencePendingKey[i].value;
          }
        }
        this.htmlstring[j] = this.tagp1.concat(this.typelabel, this.maintenenceRequestModel[j].typeName, this.tagbreak, this.actionlabel,
          this.maintenenceRequestModel[j].actionRequiredName, this.tagbreak, this.descriptionlabel, this.description, this.tagbreak,
          this.pendinglabel, this.maintenenceRequestModel[j].pendingCompleteName, this.tagbreak, this.workorderlabel, this.workOrderNumber, this.tagp2);

        this.htmlstring1.apparatus = this.htmlstring[j].toString();
        this.htmlStringModel.push(this.htmlstring1);
        this.htmlstring1 = this.initializeApparatusModel();
        this.dlApparatus = this.htmlStringModel;
      }
      //for (let i = 0; i < this.htmlStringModel.length; i++) {
      //  this.dlApparatusModel.apparatus = this.htmlStringModel[i].apparatus;
      //  if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
      //    this.dlApparatus.push(this.dlApparatusModel);
      //    this.dlApparatusModel = this.initializeApparatusModel();
      //  } else if (this.dailyLogId > 0) {
      //    this.dailyLogModel.dlApparatuses.push(this.dlApparatusModel);
      //    this.dlApparatus = this.dailyLogModel.dlApparatuses;
      //    this.dlApparatusModel = this.initializeApparatusModel();
      //  }
      //}

      if (this.dlApparatus.length > 0 || this.htmlStringModel.length > 0) {
        this.Editor2Valid = true;
      } else {
        this.Editor2Valid = false;
      }


    });

    this.dailyLogService.getCompanyTrainingByDailyLog('getCompanyTrainingByDailyLog',
      (this.dailyLogModel.shiftDate != null) ? this.dailyLogModel.shiftDate : null,
      (this.dailyLogModel.station) ? this.dailyLogModel.station : 0,
      (this.dailyLogModel.crew) ? this.dailyLogModel.crew : 0,
    ).subscribe(res => {
      // if (this.dlActivities.length !== 0 && (this.dailyLogId == 0 || this.dailyLogId == undefined)) {
      this.dlActivities = [];
      this.activitiesHtmlStringModel = [];
      // }
      this.dlActivitiesModel = this.initializeActivitiesModel();
      this.companyTrainingModel = res || [];
      let index = this.companyTrainingModel.length;
      for (let j = 0; j < index; j++) {     
        this.className = this.companyTrainingModel[j].className;
        this.trainingHours = this.companyTrainingModel[j].trainingHours;
        this.category = this.companyTrainingModel[j].category;
        this.instructor = this.companyTrainingModel[j].instructor;
        this.numberOfStudents = this.companyTrainingModel[j].numberOfStudents;

        for (let i = 0; i < this.companyEmsCategoryKey.length; i++) {
          if (this.companyEmsCategoryKey[i].key == this.category) {
            this.companyTrainingModel[j].emsCategoryName = this.companyEmsCategoryKey[i].value;
          }
        }
        for (let i = 0; i < this.companyInstructorKey.length; i++) {
          if (this.companyInstructorKey[i].key == this.instructor) {
            this.companyTrainingModel[j].instructorName = this.companyInstructorKey[i].value;
          }
        }

        this.activitiesHtmlstring[j] = this.tagp1.concat(this.classNamelabel, this.className, this.tagbreak, this.trainingHourslabel,
          this.trainingHours, this.tagbreak, this.categorylabel, this.companyTrainingModel[j].emsCategoryName, this.tagbreak,
          this.instructorlabel, this.companyTrainingModel[j].instructorName, this.tagbreak, this.numberOfStudentslabel, this.numberOfStudents.toString(), this.tagp2);

        this.activitiesHtmlstring1.activities = this.activitiesHtmlstring[j].toString();
        this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
        this.activitiesHtmlstring1 = this.initializeActivitiesModel();
        this.dlActivities = this.activitiesHtmlStringModel;
      }


      //for (let i = 0; i < this.activitiesHtmlStringModel.length; i++) {
      //  this.dlActivitiesModel.activities = this.activitiesHtmlStringModel[i].activities;
      //  if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
      //    this.dlActivities.push(this.dlActivitiesModel);
      //    this.dlActivitiesModel = this.initializeActivitiesModel();
      //  } else if (this.dailyLogId > 0) {
      //    this.dailyLogModel.dlActivities.push(this.dlActivitiesModel);
      //    this.dlActivities = this.dailyLogModel.dlActivities;
      //    this.dlActivitiesModel = this.initializeActivitiesModel();
      //  }     
      //}
      //if ((this.dlApparatus.length == 0 || this.htmlStringModel.length == 0) && (this.dlActivities.length == 0 || this.activitiesHtmlStringModel.length == 0)) {

    });

    setTimeout(() => {
      if ((this.htmlStringModel.length == 0) && (this.activitiesHtmlStringModel.length == 0)) {
        //const dialogRef = this.dialog.open(SaveDialogComponent, {
        //  width: '400px',
        //  height: '150px',
        //  data: { name: "Apparatus Field will be valid with no details !!" }
        //});
        //this.Editor2Valid = true;
        //dialogRef.afterClosed()
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '400px',
          height: '150px',
          data: { name: "No record found on Apparatus & Activites" }
        });
        this.dlApparatus = [];
        //var apparatus = this.initializeApparatusModel();        
        //this.dlApparatus.push(apparatus);
        this.Editor2Valid = false;
        // this.errorhint2 = "Apparatus Editor Field is Required";
        //this.htmlstring1.apparatus = "No Details found on Apparatus";
        //this.htmlStringModel.push(this.htmlstring1);
        //this.dlApparatusModel.apparatus = "No Details found on Apparatus";

        //this.activitiesHtmlstring1.activities = "No Details found on Activites";
        //this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
        //this.dlActivitiesModel.activities = "No Details found on Activites";

        if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
          this.dlApparatus.push(this.dlApparatusModel);
          this.dlActivities.push(this.dlActivitiesModel);
        } else if (this.dailyLogId > 0) {
          this.dailyLogModel.dlApparatuses.push(this.dlApparatusModel);
          this.dailyLogModel.dlActivities.push(this.dlActivitiesModel);
        }
        dialogRef.afterClosed()
      } else if (this.htmlStringModel.length == 0) {
        //const dialogRef = this.dialog.open(SaveDialogComponent, {
        //  width: '400px',
        //  height: '150px',
        //  data: { name: "Apparatus Field will be valid with no details !!" }
        //});
        //this.Editor2Valid = true;
        //this.errorhint2 = "";
        //dialogRef.afterClosed()
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '400px',
          height: '150px',
          data: { name: "No record found on Apparatus" }
        });
        this.Editor2Valid = false;
        //this.errorhint2 = "Apparatus Editor Field is Required";
        //this.htmlstring1.apparatus = "No Details found on Apparatus";
        //this.htmlStringModel.push(this.htmlstring1);
        //this.dlApparatusModel.apparatus = "No Details found on Apparatus";       

        if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
          this.dlApparatus.push(this.dlApparatusModel);
        } else if (this.dailyLogId > 0) {
          this.dlApparatusModel.isActive = true;
          this.dailyLogModel.dlApparatuses.push(this.dlApparatusModel);
        }
        dialogRef.afterClosed()

      }
      else if (this.activitiesHtmlStringModel.length == 0) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '400px',
          height: '150px',
          data: { name: "No record found on Activities" }
        });
        //this.activitiesHtmlstring1.activities = "No Details found on Activites";
        //this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
        //this.dlActivitiesModel.activities = "No Details found on Activites";   
        dialogRef.afterClosed()

        if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
          this.dlActivities.push(this.dlActivitiesModel);
        } else if (this.dailyLogId > 0) {
          this.dlActivitiesModel.isActive = true;
          this.dailyLogModel.dlActivities.push(this.dlActivitiesModel);
        }
      }

      //if (this.dlActivities.length==0) {       
      //  var activities = this.initializeActivitiesModel();        
      //  this.dlActivities.push(activities);
      //}
      //if (this.dlApparatus.length == 0) {
      //  var apparatus = this.initializeApparatusModel();       
      //  this.dlApparatus.push(apparatus);        
      //}
      if (this.dlActivities.length > 1) {
        this.totalActiveItemsActivities = this.dlActivities.length;
      }
      if (this.dlApparatus.length > 1) {
        this.totalActiveItemsApparatus = this.dlApparatus.length;
      }
    }, 500);


  }

  editorEvent1(editorString: string, i: number) {
    if (editorString == "") {
      // this.errorhint1 = "Quarter Editor Field is Required";
      this.Editor1Valid = false;
    } else {
      this.dlQuaters[i].quarters != '';
      this.errorhint1 = "";
      this.errorhint11 = "";
      this.Editor1Valid = true;
    }
  }

  editorEvent11(editorString1: any, i: number) {
    this.errorhint11 = "";
    let str: string;
    str = editorString1;
    str = str.replace("[\\n\\t ]", "");
    str = str.replace(/\s/g, '');
    str = str.replace(/\r?\n|\r/, '');
    editorString1 = str;
    // alert('hi' + 'editorString :' + editorString + ':');
    if (editorString1 == "" || editorString1 == '' || editorString1 == "'" || editorString1 == '"') {
      // this.errorhint1 = "Quarter Editor Field is Required";
      this.Editor1Valid = false;
    }
    else {
      this.dlQuaters[i].quarters != '';
      this.errorhint1 = "";
      this.errorhint11 = "";
      this.Editor1Valid = true;
    }
    this.errorhint11 = "";
  }

  editorEvent2(editorString: string, i: number) {
    if (editorString == "") {
      // this.errorhint2 = "Apparatus Editor Field is Required";
      this.Editor2Valid = false;
    } else {
      this.dlApparatus[i].apparatus != '';
      this.errorhint2 = "";
      this.errorhint22 = "";
      this.Editor2Valid = true;
    }
  }

  editorEvent22(editorString2: any, i: number) {
    this.errorhint22 = "";
    let str: string;
    str = editorString2;
    str = str.replace("[\\n\\t ]", "");
    str = str.replace(/\s/g, '');
    str = str.replace(/\r?\n|\r/, '');
    editorString2 = str;
    if (editorString2 == "" || editorString2 == '' || editorString2 == "'" || editorString2 == '"') {
      //  this.errorhint2 = "Apparatus Editor Field is Required";
      this.Editor2Valid = false;
    } else {
      this.dlApparatus[i].apparatus != '';
      this.errorhint2 = "";
      this.errorhint22 = "";
      this.Editor2Valid = true;
    }
    this.errorhint22 = "";
    // this.Editor2Valid = false;
  }

  editorEvent33(editorString: string, i: number) {
    this.errorhint33 = "";
    if (editorString != "") {
      this.errorhint33 = "";
      this.Editor3Valid = true;
      this.dlActivities[i].activities != '';
    }
    this.errorhint33 = "";
  }

  editorEvent3(editorString: string, i: number) {
    if (editorString == "") {
      // this.errorhint2 = "Apparatus Editor Field is Required";
      this.Editor3Valid = false;
    } else {
      this.dlActivities[i].activities != '';
    }
  }

  FireRunEvent(event: string) {
    this.PreEvent = event;
    var FRnumber = parseInt(this.PreEvent);
    if (FRnumber > 0 || this.PreEvent != "") {
      this.errorhint4 = "";
      this.fireRunDisable = false;
    } else {
      this.fireRunDisable = true;
    }
  }

  FireRun1Event(event1: string) {
    this.PreEvent3 = event1;
    if (event1 != "" || this.PreEvent != "") {
      this.errorhint4 = "";
      this.fireRunDisable = false;
    } else {
      this.fireRunDisable = true;
    }
  }

  EmsRunEvent(event: string) {
    this.PreEvent1 = event;
    var EMnumber = parseInt(this.PreEvent1);
    if (EMnumber > 0 || this.PreEvent1 != "") {
      this.errorhint5 = "";
      this.emsRunDisable = false;
    } else {
      this.emsRunDisable = true;
    }
  }

  EmsRun1Event(event2: string) {
    this.PreEvent4 = event2;
    if (event2 != "" || this.PreEvent1 != "") {
      this.errorhint5 = "";
      this.emsRunDisable = false;
    } else {
      this.emsRunDisable = true;
    }
  }

  FlaggedEmsRunEvent(event: string) {
    this.PreEvent2 = event;
    var FLnumber = parseInt(this.PreEvent2);
    if ((this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].flaggedEmsRunValue == 0 || this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].flaggedEmsRunValue == null
      || this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].flaggedEmsRunValue == undefined) && this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].isActive != true && (FLnumber! > 0 || FLnumber == NaN)) {
      // this.errorhint6 = "Please Fill the above field to add another item !";
      this.flagRunDisable = true;
    }
    else {
      this.errorhint6 = "";
      this.flagRunDisable = false;
    }
  }

  onRunChange() {
    if (this.dlFlaggedEmsRun.length > 0) {
      if ((this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].flaggedEmsRunValue == 0 || this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].flaggedEmsRunValue == null
        || this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].flaggedEmsRunValue == undefined) && this.dlFlaggedEmsRun[this.dlFlaggedEmsRun.length - 1].isActive != true && this.PreEvent2 != "") {
        this.errorhint6 = "Please Fill the above field to add another item !";
        this.flagRunDisable = true;
      }
      else {
        this.errorhint6 = "";
        this.flagRunDisable = false;
      }
    }
  }

  /*----------------*/

  addQuarters() {
    this.Editor1Valid = false;
    this.errorhint1 != '';
    this.errorhint11 = "";
    if (this.dlQuaters.length > 0) {
      let theDlQuarters: DlQuarters[] = [];
      for (var i = 0; i < this.dlQuaters.length; i++) {
        if (this.dlQuaters[i].isActive == true) {
          theDlQuarters.push(this.dlQuaters[i]);
        }
      }
      if (theDlQuarters[theDlQuarters.length - 1].quarters != '') {
        var quarter = this.initializeQuarterModel();
        this.dlQuaters.push(quarter);
        this.Editor1Valid = false;
        // alert("Editor1Valid"+ this.Editor1Valid);
        this.errorhint11 = "";
        // this.errorhint1 = "";
      }
      else {
        //if (this.Editor1Valid == true) {
        //  this.errorhint11 = "";
        //}
        this.errorhint11 = "Please Fill the above field to add another item !";
      }
    }
    else {
      var quarter = this.initializeQuarterModel();
      this.dlQuaters.push(quarter);
      this.errorhint11 = "";
    }
    this.errorhint1 != '';
    this.Editor1Valid = false;
    this.calculateTotalActiveItems2_Quarter();
    return false;
  }

  deleteQuarter(Id: number) {
    const index = Id;
    this.totalActiveItems = 0;
    for (let i = 0; i < this.dlQuaters.length; i++) {
      if (this.dlQuaters[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.dlQuaters[index].isActive = false;
      var dlQuaters = this.initializeQuarterModel();
      this.dlQuaters.push(dlQuaters);
    }
    else {
      this.dlQuaters[index].isActive = false;
    }
    this.errorhint11 = "";
    this.calculateTotalActiveItems2_Quarter();
    return false;

    //let index = Id;
    //if (index > 0) {
    //  this.dlQuaters[index].isActive = false;
    //}
    //else {
    //  this.dlQuaters[index].quarters = null;
    //}

    //let theDlQuaters: DlQuarters[] = [];
    //for (var i = 0; i < this.dlQuaters.length; i++) {
    //  if (this.dlQuaters[i].isActive == true && this.dlQuaters[i].quarters == '') {
    //    theDlQuaters.push(this.dlQuaters[i]);
    //  }
    //}
    //if (theDlQuaters.length == 0) {
    //  this.errorhint1 = "";
    //  this.Editor1Valid = true;
    //}
    //this.errorhint11 = "";
    //return false;
  }

  /*----------------*/

  addActivities() {
    this.errorhint33 = "";
    if (this.dlActivities.length > 0) {
      let theDlActivities: DlActivities[] = [];
      for (var i = 0; i < this.dlActivities.length; i++) {
        if (this.dlActivities[i].isActive == true) {
          theDlActivities.push(this.dlActivities[i]);
        }
      }
      if (theDlActivities[theDlActivities.length - 1].activities != '') {
        var activities = this.initializeActivitiesModel();
        this.dlActivities.push(activities);
        this.Editor1Valid = false;
        this.errorhint33 = "";
        this.activitiesDisable = true;
      }
      else {
        this.errorhint33 = "Please Fill the above field to add another item !";
      }
    }
    else {
      var activities = this.initializeActivitiesModel();
      this.dlActivities.push(activities);
      this.errorhint33 = "";
    }
    this.calculateTotalActiveItems2_Activities();
    return false;
    //if (this.activitiesHtmlStringModel.length > 0) {
    //  let theDlActivities: DlActivities[] = [];
    //  for (var i = 0; i < this.activitiesHtmlStringModel.length; i++) {
    //    if (this.activitiesHtmlStringModel[i].isActive == true) {
    //      theDlActivities.push(this.activitiesHtmlStringModel[i]);
    //    }
    //  }
    //  if (theDlActivities[theDlActivities.length - 1].activities != '') {
    //    var activities = this.initializeActivitiesModel();
    //    this.activitiesHtmlStringModel.push(activities);
    //    this.Editor1Valid = false;
    //  }
    //  else {
    //    this.errorhint33 = "Please Fill the above field to add another item !";
    //    this.activitiesHtmlStringModel.push(activities);
    //  }
    //}
    //else {
    //  var activities = this.initializeActivitiesModel();
    //  this.activitiesHtmlStringModel.push(activities);
    //}
    //return false; 
  }

  deleteActivities(Id: number) {
    const index = Id;
    this.totalActiveItems = 0;
    for (let i = 0; i < this.dlActivities.length; i++) {
      if (this.dlActivities[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.dlActivities[index].isActive = false;
      var dlActivities = this.initializeActivitiesModel();
      this.dlActivities.push(dlActivities);
    }
    else {
      this.dlActivities[index].isActive = false;
    }
    this.errorhint33 = "";
    this.calculateTotalActiveItems2_Activities();
    return false;

    //const index = Id;   
    ////const index = this.dlactivities.findIndex(x => x.activitiesId == Id);
    //if (index > 0) {
    //  this.dlActivities[index].isActive = false;
    //}
    //else {
    //  this.dlActivities[index].activities = null;
    //}
    //this.errorhint33 = "";
    //return false;
  }

  /*----------------*/

  addApparatus() {
    this.Editor2Valid = false;
    this.errorhint22 = "";
    if (this.dlApparatus.length > 0) {
      let theDlApparatus: DlApparatus[] = [];
      for (var i = 0; i < this.dlApparatus.length; i++) {
        if (this.dlApparatus[i].isActive == true) {
          theDlApparatus.push(this.dlApparatus[i]);
        }
      }
      if (theDlApparatus[theDlApparatus.length - 1].apparatus != '') {
        var apparatus = this.initializeApparatusModel();
        this.dlApparatus.push(apparatus);
        // alert("222Editor2Valid222" + this.Editor2Valid);
        this.errorhint22 = "";
        this.Editor2Valid = false;
      }
      else {
        //if (this.Editor2Valid == true) {
        //  this.errorhint22 = "";
        //}
        this.errorhint22 = "Please Fill the above field to add another item !";
      }
    }
    else {
      var apparatus = this.initializeApparatusModel();
      this.dlApparatus.push(apparatus);
      this.errorhint22 = "";
    }
    this.Editor2Valid = false;
    this.calculateTotalActiveItems2_Apparatus();
    //alert("**  " + this.Editor2Valid);
    return false;

    //if (this.dlApparatus.length > 0) {  
    //  let theDlApparatus: DlApparatus[] = [];
    //  for (var i = 0; i < this.dlApparatus.length; i++) {
    //    if (this.dlApparatus[i].isActive == true) {
    //      theDlApparatus.push(this.dlApparatus[i]);
    //    }
    //  } 
    //  if (theDlApparatus[theDlApparatus.length - 1].apparatus != '' || this.dlApparatus.length == 0 || this.htmlstring1.apparatus != "") {
    //    var apparatus = this.initializeApparatusModel();
    //    this.dlApparatus.push(apparatus);

    //    //this.htmlstring1.htmlString = "";
    //    this.htmlStringModel.push(this.htmlstring1);
    //  }
    //  else {
    //    this.errorhint22 = "Please Fill the above field to add another item !";
    //  }
    //}
    //else {
    //  var apparatus = this.initializeApparatusModel();
    //  this.dlApparatus.push(apparatus);
    //}
    //return false;
  }

  deleteApparatus(Id: number) {
    const index = Id;
    this.totalActiveItems = 0;
    for (let i = 0; i < this.dlApparatus.length; i++) {
      if (this.dlApparatus[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.dlApparatus[index].isActive = false;
      var dlApparatus = this.initializeApparatusModel();
      this.dlApparatus.push(dlApparatus);
    }
    else {
      this.dlApparatus[index].isActive = false;
    }
    this.errorhint22 = "";
    this.calculateTotalActiveItems2_Apparatus();
    return false;
    //const index = Id;
    //if (index > 0) {
    //  this.dlApparatus[index].isActive = false;
    //}
    //else {
    //  this.dlApparatus[index].apparatus = null;
    //}

    //let theDlApparatus: DlApparatus[] = [];
    //for (var i = 0; i < this.dlApparatus.length; i++) {
    //  if (this.dlApparatus[i].isActive == true && this.dlApparatus[i].apparatus=='') {
    //    theDlApparatus.push(this.dlApparatus[i]);
    //  }
    //}
    //if (theDlApparatus.length==0) {
    //    this.errorhint2 = "";
    //    this.Editor2Valid = true;
    //  }
    //this.errorhint22 = "";

  }

  /*----------------*/

  addFlaggedEmsRun() {
    if (this.dlFlaggedEmsRun.length > 0) {
      let theDlFlaggedEmsRun: DlFlaggedEmsRun[] = [];
      for (var i = 0; i < this.dlFlaggedEmsRun.length; i++) {
        if (this.dlFlaggedEmsRun[i].isActive == true) {
          theDlFlaggedEmsRun.push(this.dlFlaggedEmsRun[i]);
        }
      }
      if (theDlFlaggedEmsRun[theDlFlaggedEmsRun.length - 1].flaggedEmsRunNumber || theDlFlaggedEmsRun[theDlFlaggedEmsRun.length - 1].flaggedEmsRunValue > 0) {
        var flaggedEmsRun = this.initializeFlaggedEmsRunModel();
        this.dlFlaggedEmsRun.push(flaggedEmsRun);
        this.errorhint6 = "";
        this.flagRunDisable = true;
      }
      else {
        this.errorhint6 = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.errorhint6 = "";
        //}, 1500);
      }
    }
    else {
      var flaggedEmsRun = this.initializeFlaggedEmsRunModel();
      this.dlFlaggedEmsRun.push(flaggedEmsRun);
    }
    this.calculateTotalActiveItems2_Flag();
    return false;
  }

  deleteFlaggedEmsRun(Id: number) {
    const index = Id;
    //const index = this.dlflaggedemsrun.findIndex(x => x.flaggedEmsRunId == Id);
    this.totalActiveItems = 0;
    for (let i = 0; i < this.dlFlaggedEmsRun.length; i++) {
      if (this.dlFlaggedEmsRun[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      //this.dlFlaggedEmsRun[index].flaggedEmsRunNumber = null;
      //this.dlFlaggedEmsRun[index].flaggedEmsRunValue = null;
      this.dlFlaggedEmsRun[index].isActive = false;
      var dlFlaggedEmsRun = this.initializeFlaggedEmsRunModel();
      this.dlFlaggedEmsRun.push(dlFlaggedEmsRun);
    }
    else {
      this.dlFlaggedEmsRun[index].isActive = false;
    }
    this.errorhint6 = "";
    this.calculateTotalActiveItems2_Flag();
    return false;
  }

  /*----------------*/
  addEmsRun() {
    if (this.dlEmsRun.length > 0) {
      let theDlDlEmsRun: DlEmsRun[] = [];
      for (var i = 0; i < this.dlEmsRun.length; i++) {
        if (this.dlEmsRun[i].isActive == true) {
          theDlDlEmsRun.push(this.dlEmsRun[i]);
        }
      }
      if (theDlDlEmsRun[theDlDlEmsRun.length - 1].emsRunNumber || theDlDlEmsRun[theDlDlEmsRun.length - 1].emsRunAddress != '' && theDlDlEmsRun[theDlDlEmsRun.length - 1].emsRunAddress != null) {
        var emsRun = this.initializeEmsRunModel();
        this.dlEmsRun.push(emsRun);
        this.errorhint5 = "";
        this.emsRunDisable = true;
      }
      else {
        this.errorhint5 = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.errorhint5 = "";
        //}, 1500);
      }
    }
    else {
      var emsRun = this.initializeEmsRunModel();
      this.dlEmsRun.push(emsRun);
    }
    this.calculateTotalActiveItems1_Ems();
    return false;
  }

  deleteEmsRun(Id: number) {
    const index = Id;
    this.totalActiveItems = 0;
    for (let i = 0; i < this.dlEmsRun.length; i++) {
      if (this.dlEmsRun[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      //this.dlEmsRun[index].emsRunNumber = null;
      //this.dlEmsRun[index].emsRunAddress = null; 
      this.dlEmsRun[index].isActive = false;
      var dlEmsRun = this.initializeEmsRunModel();
      this.dlEmsRun.push(dlEmsRun);

    }
    else {
      this.dlEmsRun[index].isActive = false;
    }
    this.errorhint5 = "";
    this.calculateTotalActiveItems1_Ems();
    return false;
  }

  //------------------
  addFireRun() {
    if (this.dlFireRun.length > 0) {
      let theDlFireRun: DlFireRun[] = [];
      for (var i = 0; i < this.dlFireRun.length; i++) {
        if (this.dlFireRun[i].isActive == true) {
          theDlFireRun.push(this.dlFireRun[i]);
        }
      }
      //if (theDlFireRun.length > 1) {
      //  this.fireRunDeleteDisable = true;
      //}
      if (theDlFireRun[theDlFireRun.length - 1].fireRunNumber || theDlFireRun[theDlFireRun.length - 1].fireRunAddress != '' && theDlFireRun[theDlFireRun.length - 1].fireRunAddress != null) {
        var fireRun = this.initializeFireRunModel();
        this.dlFireRun.push(fireRun);
        this.errorhint4 = "";
        this.fireRunDisable = true;
      }
      else {
        this.errorhint4 = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.errorhint4 = "";
        //}, 1500);
      }
    }
    else {
      var fireRun = this.initializeFireRunModel();
      this.dlFireRun.push(fireRun);
    }
    this.calculateTotalActiveItems_Fire();
    return false;
  }

  calculateTotalActiveItems_Fire() {
    this.totalActiveItemsFire = 0;
    for (let x = 0; x < this.dlFireRun.length; x++) {
      if (this.dlFireRun[x].isActive == true) {
        this.totalActiveItemsFire = this.totalActiveItemsFire + 1;
      }
      if (this.totalActiveItemsFire > 0 && (this.dlFireRun[x].fireRunNumber != null || this.dlFireRun[x].fireRunNumber != '' || this.dlFireRun[x].fireRunAddress != '')) {
        this.fireRunDisable = false;
        //this.nomineeNameValid = true;
      }
      if ((this.dlFireRun[x].fireRunNumber == null || this.dlFireRun[x].fireRunNumber == '' || this.dlFireRun[x].fireRunAddress == '') && this.dlFireRun[x].isActive == true) {
        this.fireRunDisable = true;
        // this.nomineeNameValid = false;
      }

    }
  }

  calculateTotalActiveItems1_Ems() {
    this.totalActiveItemsEms = 0;
    for (let x = 0; x < this.dlEmsRun.length; x++) {
      if (this.dlEmsRun[x].isActive == true) {
        this.totalActiveItemsEms = this.totalActiveItemsEms + 1;
      }
      if (this.totalActiveItemsEms > 0 && (this.dlEmsRun[x].emsRunNumber != null || this.dlEmsRun[x].emsRunNumber != '' || this.dlEmsRun[x].emsRunAddress != '')) {
        this.emsRunDisable = false;
        //this.nomineeNameValid = true;
      }
      if ((this.dlEmsRun[x].emsRunNumber == null || this.dlEmsRun[x].emsRunNumber == '' || this.dlEmsRun[x].emsRunAddress == '') && this.dlEmsRun[x].isActive == true) {
        this.emsRunDisable = true;
        // this.nomineeNameValid = false;
      }

    }
  }

  calculateTotalActiveItems2_Flag() {
    this.totalActiveItemsFlag = 0;
    for (let x = 0; x < this.dlFlaggedEmsRun.length; x++) {
      if (this.dlFlaggedEmsRun[x].isActive == true) {
        this.totalActiveItemsFlag = this.totalActiveItemsFlag + 1;
      }
      if (this.totalActiveItemsFlag > 0 && (this.dlFlaggedEmsRun[x].flaggedEmsRunNumber != null || this.dlFlaggedEmsRun[x].flaggedEmsRunNumber != '' || this.dlFlaggedEmsRun[x].flaggedEmsRunValue > 0)) {
        this.flagRunDisable = false;
        //this.nomineeNameValid = true;
      }
      if ((this.dlFlaggedEmsRun[x].flaggedEmsRunNumber == null || this.dlFlaggedEmsRun[x].flaggedEmsRunNumber == '' || this.dlFlaggedEmsRun[x].flaggedEmsRunValue == 0 || this.dlFlaggedEmsRun[x].flaggedEmsRunValue == null) && this.dlFlaggedEmsRun[x].isActive == true) {
        this.flagRunDisable = true;
        // this.nomineeNameValid = false;
      }

    }
  }

  calculateTotalActiveItems2_Quarter() {
    this.totalActiveItemsQuarter = 0;
    for (let x = 0; x < this.dlQuaters.length; x++) {
      if (this.dlQuaters[x].isActive == true) {
        this.totalActiveItemsQuarter = this.totalActiveItemsQuarter + 1;
      }
      //if (this.totalActiveItemsQuarter > 0 && this.dlQuaters[x].quarters != '') {
      //  this.quarterDisable = false;
      //  //this.nomineeNameValid = true;
      //}
      //if (this.dlQuaters[x].quarters == '' && this.dlQuaters[x].isActive == true) {
      //  this.quarterDisable = true;
      //  // this.nomineeNameValid = false;
      //}

    }
  }

  calculateTotalActiveItems2_Apparatus() {
    this.totalActiveItemsApparatus = 0;
    for (let x = 0; x < this.dlApparatus.length; x++) {
      if (this.dlApparatus[x].isActive == true) {
        this.totalActiveItemsApparatus = this.totalActiveItemsApparatus + 1;
      }
    }
  }

  calculateTotalActiveItems2_Activities() {
    this.totalActiveItemsActivities = 0;
    for (let x = 0; x < this.dlActivities.length; x++) {
      if (this.dlActivities[x].isActive == true) {
        this.totalActiveItemsActivities = this.totalActiveItemsActivities + 1;
      }
    }
  }

  deleteFireRun(Id: number) {
    const index = Id;
    //const index = this.dlemsrun.findIndex(x => x.emsRunId == Id);
    this.dailyLogId = this.dailyLogModel.dailyLogId;

    this.totalActiveItems = 0;
    for (let i = 0; i < this.dlFireRun.length; i++) {
      if (this.dlFireRun[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      //this.dlFireRun[index].fireRunNumber = null;
      //this.dlFireRun[index].fireRunAddress = null;
      this.dlFireRun[index].isActive = false;
      var dlFireRun = this.initializeFireRunModel();
      this.dlFireRun.push(dlFireRun);
      //  this.fireRunDeleteDisable = true;
    }
    else {
      this.dlFireRun[index].isActive = false;
    }
    this.errorhint4 = "";
    this.calculateTotalActiveItems_Fire();
    return false;
  }

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  public updateDailyLog() {
    this.disableSave = true;
    //for (let i = 0; i < this.htmlStringModel.length; i++) {
    //   var thedlApparatus = this.initializeApparatusModel();
    //  thedlApparatus[i].apparatus = this.htmlStringModel[i].htmlString;
    //  this.dlApparatus.push(thedlApparatus);
    //}
    //for (let i = 0; i < this.activitiesHtmlStringModel.length; i++) {
    //  this.dlActivities[i].activities = this.htmlStringModel[i].htmlString;
    //  this.dlActivities.push(this.dlActivities[i]);
    //}
    if (this.dailyLogId == 0 || this.dailyLogId == undefined) {
      this.dailyLogModel.dlActivities = this.dlActivities;
      this.dailyLogModel.dlApparatuses = this.dlApparatus;
    }
    this.dailyLogModel.dlFlaggedEmsRuns = this.dlFlaggedEmsRun;
    this.dailyLogModel.dlQuarters = this.dlQuaters;
    this.dailyLogModel.dlEmsRuns = this.dlEmsRun;
    this.dailyLogModel.dlFireRuns = this.dlFireRun;
    this.dailyLogId = this.dailyLogModel.dailyLogId;
    //this.dailyLogModel.shiftDate = new Date(this.dailyLogModel.shiftDate);
    //var dth = this.dailyLogModel.shiftDate.getHours();
    //dth = dth + 5;
    //var dtm = this.dailyLogModel.shiftDate.getMinutes();
    //dtm = dtm + 30;
    //this.dailyLogModel.shiftDate.setHours(dth);
    //this.dailyLogModel.shiftDate.setMinutes(dtm);
    if (this.dailyLogModel.totalMedAssists == 0) {
      this.dailyLogModel.totalMedAssists = null;
    }
    if (this.dailyLogModel.totalFirstResponses == 0) {
      this.dailyLogModel.totalFirstResponses = null;
    }
    for (let i = 0; i < this.dlEmsRun.length; i++) {
      if (this.dlEmsRun[i].emsRunNumber == null) {
        this.dlEmsRun[i].emsRunNumber = null;
      }
    }
    for (let i = 0; i < this.dlFireRun.length; i++) {
      if (this.dlFireRun[i].fireRunNumber == null) {
        this.dlFireRun[i].fireRunNumber = null;
      }
    }
    for (let i = 0; i < this.dlFlaggedEmsRun.length; i++) {
      if (this.dlFlaggedEmsRun[i].flaggedEmsRunNumber == null) {
        this.dlFlaggedEmsRun[i].flaggedEmsRunNumber = null;
      }
    }

    this.dailyLogModel.shiftDateStr = this.getDateAsString(new Date(this.dailyLogModel.shiftDate));

    if (this.dailyLogId > 0) {
      this.dailyLogService.updateDailyLog(this.dailyLogId, this.dailyLogModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
            //this.ResetDailyLog();
            //this.dailyLogId = 0;
            //this.routerService.navigate(['/dailylog']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
          });
        }
      });
    }
    else {
      this.dailyLogService.saveDailyLog('saveDailyLog', this.dailyLogModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
            this.ResetDailyLog();
          });
        }
        else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
          });
        }
      });
    }


  }

  GoBack() {
     this.routerService.navigate(['/dailylogreport']);
  }

  ResetDailyLog() {

    this.ckeConfig = this.initializeCkeConfigModel();
    this.dailyLogModel = this.initializedailyLogModelModel();

    this.dlActivities = [];
    this.activitiesHtmlStringModel = [];
    this.dlApparatus = [];
    this.htmlStringModel = [];

    var apparatus = this.initializeApparatusModel();
    var activities = this.initializeActivitiesModel();
    this.dlApparatus.push(apparatus);
    this.dlActivities.push(activities);

    //this.activitiesHtmlstring1.activities = "";
    //this.activitiesHtmlStringModel.push(this.activitiesHtmlstring1);
    //this.htmlstring1.apparatus = "";
    //this.htmlStringModel.push(this.htmlstring1);

    var quarter = this.initializeQuarterModel();
    // var apparatus = this.initializeApparatusModel();
    // var activities = this.initializeActivitiesModel();
    var flaggedEmsRun = this.initializeFlaggedEmsRunModel();
    var emsRun = this.initializeEmsRunModel();
    var fireRun = this.initializeFireRunModel();

    this.dlQuaters.splice(0, this.dlQuaters.length);
    // this.dlApparatus.splice(0, this.dlApparatus.length);
    this.dlFlaggedEmsRun.splice(0, this.dlFlaggedEmsRun.length);
    this.dlEmsRun.splice(0, this.dlEmsRun.length);
    //this.dlActivities.splice(0, this.dlActivities.length);
    this.dlFireRun.splice(0, this.dlFireRun.length);

    this.dlQuaters.push(quarter);
    //this.dlApparatus.push(apparatus);
    //this.dlActivities.push(activities);
    this.dlFlaggedEmsRun.push(flaggedEmsRun);
    this.dlEmsRun.push(emsRun);
    this.dlFireRun.push(fireRun);
    this.disableSave = false;
    this.dailyLogForm.resetForm();
    this.resetOption = true;
    this.errorhint2 = "";
    this.errorhint1 = "";
    this.calculateTotalActiveItems2_Quarter();
    this.calculateTotalActiveItems2_Apparatus();
    this.calculateTotalActiveItems2_Activities();
    this.calculateTotalActiveItems1_Ems();
    this.calculateTotalActiveItems_Fire();
    this.calculateTotalActiveItems2_Flag();
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllStations(),
      this.systemCodeService.getAllRunValues(),
      this.systemCodeService.getSystemCodesForMaintanenceTypes(),
      this.systemCodeService.getSystemCodesForActionRequired(),
      this.systemCodeService.getSystemCodesForTrainingTypes(),
      this.systemCodeService.getSystemCodesForEmscategory('1'),
      this.systemCodeService.getSystemCodesForInstructor(),
      this.systemCodeService.getSystemCodesForPendingType(),
    ).subscribe(([res1, res2, res3, res4, res5, res6, res7, res8, res9]) => {
      this.crewCode = res1 || [];
      this.stationCode = res2 || [];
      this.runvalueCode = res3 || [];
      this.maintanenceTypeKey = res4.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.maintanenceActionKey = res5.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.maintanencePendingKey = res9.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];

      this.companyTrainingTypeKey = res6.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.companyEmsCategoryKey = res7.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.companyInstructorKey = res8.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      const apparatusTypeIndex = this.maintanenceTypeKey.findIndex(x => x.value == this.apparatusTypeKey);
      this.apparatusTypeCode = this.maintanenceTypeKey[apparatusTypeIndex].key;
    });
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}

export interface HtmlContent {
  htmlString: string;
}


