import { Injectable, Inject } from "@angular/core";
import { LoginModel } from "../models/login.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoginService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getLogin(username: string, password: string): Observable<any> {
    var body = {
      username: username,
      password: password
    };
    return this.httpClient.post<LoginModel>(this.baseUrl + "api/Login/", JSON.stringify(body), this.generateHeaders());
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }
}
