import { ViewEncapsulation, OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import { CompanyTraining } from "../../models/companytraining.model";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { CompanyTrainingService } from "../../services/companytraining.service";
import { RolePermissionCheck } from "../../models/role-permission-check.model";
import { ActivatedRoute } from "@angular/router";
import * as jspdf from 'jspdf';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import autoTable, { UserOptions } from 'jspdf-autotable'
import { CompanyTrainingPrintReportFilter } from "../../models/company-training-print-report-filters.model";

interface jsPDFWithPlugin extends jsPDF {
  autoTableHtmlToJson(elem: HTMLElement);
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'ffd-company-training-report-view',
  templateUrl: './company-training-report-view.component.html',
  styleUrls: ['./company-training-report-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyTrainingReportViewComponent implements OnInit {
  companyTrainings: Array<CompanyTraining> = [];
  rolePermissionCheckModel: RolePermissionCheck;

  @BlockUI() blockUI: NgBlockUI;
  @Input() companyTrainingModel: CompanyTraining;
  @Input() employee: { employeeId: number, name: string };
  @Input() companyTrainingPrintReportFilters: CompanyTrainingPrintReportFilter;
  @Output() toggleViewForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private companyTrainingService: CompanyTrainingService,
    private route: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isPrint) {
      this.blockUI.start();
      this.companyTrainingService.getAllCompanyTrainingsForReportView(
        'GetAllCompanyTrainingsForReportView',
        (this.companyTrainingModel.trainingType) ? this.companyTrainingModel.trainingType : 0,
        (this.companyTrainingModel.instructor) ? this.companyTrainingModel.instructor : 0,
        (this.companyTrainingModel.startDate != null) ? this.companyTrainingModel.startDate : null,
        (this.companyTrainingModel.category) ? this.companyTrainingModel.category : 0,
        (this.companyTrainingModel.crew) ? this.companyTrainingModel.crew : 0,
        (this.companyTrainingModel.station) ? this.companyTrainingModel.station : 0,
        (this.companyTrainingModel.endDate != null) ? this.companyTrainingModel.endDate : null,
        (this.employee != undefined) ? this.employee.employeeId : 0
      ).subscribe((res: Array<CompanyTraining>) => {
        this.blockUI.stop();
        this.companyTrainings = res;
      }, (error: any) => {
        this.blockUI.stop();
        this.companyTrainings = [];
      });
    }
  }

  toggleViewCompanyTrainingOnClick(): void {
    this.toggleViewForm.emit();
  }

  pdf3() {
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 35;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF();
    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth(this.companyTrainingPrintReportFilters.trainingType) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(this.companyTrainingPrintReportFilters.trainingType, textOffset, 20)
    doc.setFontSize(10)

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    if (this.companyTrainingPrintReportFilters.employeeName) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Employee Name: ' + this.companyTrainingPrintReportFilters.employeeName, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }
    if (this.companyTrainingPrintReportFilters.category) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Category: ' + this.companyTrainingPrintReportFilters.category, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }
    if (this.companyTrainingPrintReportFilters.crewName) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Crew: ' + this.companyTrainingPrintReportFilters.crewName, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }
    if (this.companyTrainingPrintReportFilters.stationName) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Station: ' + this.companyTrainingPrintReportFilters.stationName, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }
    if (this.companyTrainingPrintReportFilters.startDate) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Start Date: ' + this.companyTrainingPrintReportFilters.startDate, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }
    if (this.companyTrainingPrintReportFilters.endDate) {
      if (count == 1) {
        yAxis = yAxis + 10;
        count = 0;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('End Date: ' + this.companyTrainingPrintReportFilters.endDate, pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis, yAxis)
      count++;
    }

    if (!this.checkPrintReportFilters()) {
      startYVariable = 35;
    } else {
      startYVariable = yAxis + 10;
    }

    autoTable(doc, {
      headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      bodyStyles: { minCellHeight: 8, fillColor: null },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 185, pageHeight - 10);

        var currentDate = new Date();
        var currentDateString = (currentDate.getMonth() + 1).toString() + '/' + currentDate.getDate().toString() + '/' + currentDate.getFullYear().toString();
        doc.text(currentDateString, 5, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    doc.output('dataurlnewwindow');
  }

  checkPrintReportFilters(): boolean {
    if (this.companyTrainingPrintReportFilters.employeeName || this.companyTrainingPrintReportFilters.category || this.companyTrainingPrintReportFilters.crewName ||
      this.companyTrainingPrintReportFilters.stationName || this.companyTrainingPrintReportFilters.startDate || this.companyTrainingPrintReportFilters.endDate) {
      return true;
    }
    return false;
  }

  getHeadRows() {
    return [
      {
        instructor: 'Instructor Name', kemsisNumber: 'KEMSIS #', className: 'Class Name',
        trainingDate: 'Training Date', students: 'Students', trainingCodes: 'Training Codes',
        hours: 'Hours'
      }
    ]
  }

  getBody() {
    let rowCount = this.companyTrainings.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      body.push({
        instructor: this.companyTrainings[j].instructorName,
        kemsisNumber: (this.companyTrainings[j].kemiscode) ? this.companyTrainings[j].kemiscode : 'N/A',
        className: (this.companyTrainings[j].className) ? this.companyTrainings[j].className : 'N/A',
        trainingDate: (this.companyTrainings[j].startDate) ? '12/20/2020' : 'N/A',
        students: this.companyTrainings[j].companyTrainingEmployeeNames,
        trainingCodes: this.companyTrainings[j].emsCategoryName,
        hours: this.companyTrainings[j].trainingHours
      })
    }
    return body
  }

  getFilters(): string {
    let filtersText = '';

    if (this.companyTrainingPrintReportFilters.employeeName) {
      filtersText = filtersText + 'Employee Name: ' + this.companyTrainingPrintReportFilters.employeeName + ' ';
    }

    if (this.companyTrainingPrintReportFilters.category) {
      filtersText = filtersText + 'Category: ' + this.companyTrainingPrintReportFilters.category + ' ';
    }

    if (this.companyTrainingPrintReportFilters.crewName) {
      filtersText = filtersText + 'Crew: ' + this.companyTrainingPrintReportFilters.crewName + ' ';
    }

    if (this.companyTrainingPrintReportFilters.stationName) {
      filtersText = filtersText + 'Station: ' + this.companyTrainingPrintReportFilters.stationName + ' ';
    }

    if (this.companyTrainingPrintReportFilters.startDate) {
      filtersText = filtersText + 'Start Date: ' + this.companyTrainingPrintReportFilters.startDate + ' ';
    }

    if (this.companyTrainingPrintReportFilters.endDate) {
      filtersText = filtersText + 'End Date: ' + this.companyTrainingPrintReportFilters.endDate + ' ';
    }

    return filtersText;
  }

  getFormattedDate(date: Date): string {
    return date.getMonth().toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString();
  }
}
