import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { SystemCodeService } from '../services/system-code.service';
import { EMSTraingHoursService } from '../services/emstraininghours.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { EmsTrainingHours } from '../models/emstraininghours.model';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { GetAllMaintanence } from '../models/get-all-maintanence.model';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of, forkJoin, EMPTY } from 'rxjs';
import { GetAllEMSTrainingHours } from '../models/get-all-emstraininghours.model';
import { EmsTrainingInstructor } from '../models/emstraininginstructor.model';
import { EmsTrainingCodes } from '../models/emstrainingcodes.model';
import { ModelDialogContent } from '../common/model-dialog/model-dialog-content';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';

interface EmployeeFilter {
  employeeId: number;
  name: string;
}

@Component({
  selector: 'emstraininghoursreport',
  templateUrl: './emstraininghoursreport.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class EMSTrainingHoursReport {
  @ViewChild('emsTraingHoursForm', { static: false }) emsTraingHoursForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  container: NgForm;
  employeeNames: string[] = [];
  emsTrainingHourId: number;
  emsTrainingModel: EmsTrainingHours[] = [];
  emsTrainingHoursModel: EmsTrainingHours;
  emsTrainingHoursObject: EmsTrainingHours;
  emsTrainingInstructorsCode: SystemCode[] = [];
  emsTrainingCodefordisplay: SystemCode[] = [];
  emsTrainingCodeHourfordisplay: SystemCode[] = [];
  emsTrainingInstructorsDisplay: Array<{ key: number, value: string }> = [];
  startDate: Date;
  endDate: Date;
  public filteredList5: Employee[] = [];
  employeeResutObj: Employee[] = [];
  employee: EmployeeFilter;
  todayDate: Date = new Date();
  fromDate: any;
  showEndDateField: boolean = false;
  endDateErrorMsg: string = "";
  assignmentObjDB = new MatTableDataSource<EmsTrainingHours>([]);

  dataSource: MatTableDataSource<EmsTrainingHours>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  resultsLength: number = 0;
  showNewButton: boolean = false;
  @BlockUI() blockUI: NgBlockUI;

  displayedColumns: any[] = ['instructor', 'kemsisNumber', 'className', 'trainingDate', 'studentName', 'TrainingCode'];
  rolePermissionCheckModel: RolePermissionCheck;


  constructor(
    private systemCodeStateService: SystemCodeService,
    private emsTrainingHoursService: EMSTraingHoursService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private routerService: Router,
    private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }
  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.emsTrainingHoursModel = this.initializeEmsTrainingHoursModel();
      this.loadGrid();
      this.dataSource = new MatTableDataSource(this.emsTrainingModel);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.displayRecords();
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  initializeEmsTrainingHoursModel(): EmsTrainingHours {
    return {
      emsTrainingHourId: 0,
      className: null,
      trainingDate: null,
      trainingTime: null,
      kemsisNumber: null,
      hours: null,
      // employeeId: null,
      eMSTrainingHourStudentsData: null,
      numberOfStudents: null,
      eMSTrainingHourTrainingCodes: null,
      eMSTrainingHourInstructors: null,
      startDate: null,
      endDate: null
    } as EmsTrainingHours;
  }

  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllEMSTrainingHours(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            //this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.emsTrainingModel = data || [];
          //this.blockUI.stop();
        });
    }
  }
  getAllEMSTrainingHours(pageIndex: number, pageSize: number): Observable<GetAllEMSTrainingHours> {
    return this.emsTrainingHoursService.getEMSTrainingHoursNew(
      'GetEMSTrainingHoursNew',
      (this.emsTrainingHoursModel.startDate) ? this.emsTrainingHoursModel.startDate : null,
      (this.emsTrainingHoursModel.endDate) ? this.emsTrainingHoursModel.endDate : null,
      (this.employee != undefined) ? this.employee.employeeId : 0,
      pageIndex,
      pageSize
    );
  }

  getAllEMSTrainingHoursForDataSource(): void {
    this.getAllEMSTrainingHours(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
    ).subscribe(data => {
      this.emsTrainingModel = data || [];
        this.dataSource.data = data || [];
        this.blockUI.stop();
      });
  }

  public searchEmsTrainingHoursReport() {
    this.blockUI.start();
    
    //this.waterusageservice.searchWaterUsage('SearchWaterUsage', this.waterUsageModel).subscribe(res => {
    //  this.waterModel = res;
    //  if (this.waterModel.length == 0) {
    //    this.searchMsg = " for your Search";
    //  }
    //  this.dataSource = new MatTableDataSource<WaterUsage>(this.waterModel);
    //  this.dataSource.paginator = this.paginator;
    //  this.dataSource.sort = this.sort;
    //  this.blockUI.stop();
    //},
    //  err => {
    //    this.blockUI.stop();
    //  });
    this.getAllEMSTrainingHoursForDataSource();
    return false;
  }
  deleteEMSTrainingHours(eId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.emsTrainingHoursService.delEMSTrainingHours('delEMSTrainingHours', parseInt(eId)).subscribe(res => {
          this.getAllEMSTrainingHoursForDataSource();
        });
      }
    });
    return false;
  }
  //printEMSTrainingInstructor(emsTrainingHourInstructors: EmsTrainingInstructor[]) {

  //  let instructorId: number = null;
  //  let instructorName: string = "";
  //  for (let i = 0; i < emsTrainingHourInstructors.length; i++) {
  //    instructorId = emsTrainingHourInstructors[i].instructor;
  //    var instructorItem = this.emsTrainingInstructorsCode.findIndex(x => x.systemCodeId == instructorId);
  //    if (instructorItem > 0) {
  //      instructorName = instructorName + this.emsTrainingInstructorsCode[instructorItem].type+"\n" ;
        
  //    }

  //  }
  //  return instructorName;
    
  //}

  printEMSTrainingCodes(eMSTrainingHourTrainingCodes: EmsTrainingCodes[]) {

    let trainingCodeId: number = 0;
    let trainingHours: number = null;
    let trainingCodeName: string = "";
    for (let i = 0; i < eMSTrainingHourTrainingCodes.length; i++) {
      trainingCodeId = eMSTrainingHourTrainingCodes[i].trainingCode;
      trainingHours = eMSTrainingHourTrainingCodes[i].hours;
      var trainingCodeItem = this.emsTrainingCodefordisplay.findIndex(x => x.systemCodeId == trainingCodeId);
      if (trainingCodeItem > 0) {
        trainingCodeName = trainingCodeName + this.emsTrainingCodefordisplay[trainingCodeItem].type + " " + trainingHours + "Hrs";
        
      }

    }
    return trainingCodeName;

  }

  printEMSTrainingStudents(eMSTrainingHourStudentsData: Array<number>) {

    let studentId: number = 0;
    if (eMSTrainingHourStudentsData.length > 0) {
      studentId = eMSTrainingHourStudentsData.length;
     
    }
    return studentId;

  }

  printEMSTrainingCodeNumber(eMSTrainingHourTrainingCodes: EmsTrainingCodes[]) {

    let trainingId: number = 0;

    if (eMSTrainingHourTrainingCodes.length > 0) {
      trainingId = eMSTrainingHourTrainingCodes.length;

    }
    return trainingId;

  }
 

  
  displayRecords() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForEMSTrainingInstructorForDisplay(),
      this.systemCodeStateService.getSystemCodesForEMSTrainingCodeForDisplay()
    ).subscribe(([res1,res2]) => {
      this.emsTrainingInstructorsCode = res1 || [];
      this.emsTrainingCodefordisplay = res2 || [];
      //this.emsTrainingInstructorsDisplay = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  openPopup(emsTrainingHourId: number) {
    this.emsTrainingHoursService.getEMSTrainingStudents('getEMSTrainingHours', emsTrainingHourId).subscribe(res => {
      this.employeeNames = res;
      this.openDialog(this.employeeNames);

    });



  }
  openDialog(nameList: any) {
    const dialogRef = this.dialog.open(ModelDialogContent, {
      data: {
        names: nameList,
        headingName: "Students List"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }
  resetTheForm() {
    this.blockUI.start();
    this.emsTrainingHoursModel = this.initializeEmsTrainingHoursModel();
    this.emsTraingHoursForm.resetForm();
    this.startDate = null;
    this.endDate = null;
    // this.loadGrid();
    this.getAllEMSTrainingHoursForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.fromDate = new Date(2010, 0, 1);
    this.showEndDateField = false;
    this.endDateErrorMsg = "";
    //this.searchMsg = "";
    // this.blockUI.stop();
    return false;
  }
  openPopupTrainingCode(eMSTrainingHourTrainingCodes: EmsTrainingCodes[]) {
    let strlist : string[] = []; 
    let trainingCodeId: number = 0;
    let trainingHours: number = null;
    let trainingCodeName: string = "";
    for (let i = 0; i < eMSTrainingHourTrainingCodes.length; i++) {
      trainingCodeId = eMSTrainingHourTrainingCodes[i].trainingCode;
      trainingHours = eMSTrainingHourTrainingCodes[i].hours;
      var trainingCodeItem = this.emsTrainingCodefordisplay.findIndex(x => x.systemCodeId == trainingCodeId);
      if (trainingCodeItem > 0) {
        //trainingCodeName = trainingCodeName + this.emsTrainingCodefordisplay[trainingCodeItem].type + " " + trainingHours + "Hrs";
        strlist.push(this.emsTrainingCodefordisplay[trainingCodeItem].type + " - " + trainingHours + " " +"Hrs");

      }

    }
    //return trainingCodeName;
    this.openDialogTrainingCode(strlist);

  }
  openDialogTrainingCode(nameList: any) {
    const dialogRef = this.dialog.open(ModelDialogContent, {
      data: {
        names: nameList,
        headingName:"Training Codes"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }
  printEMSTrainingInstructor(emsTrainingHourInstructors: EmsTrainingInstructor[]) {
    let instructorId: string = "";
    let instructorLength: number = 0;
    if (emsTrainingHourInstructors.length > 0) {

      instructorLength = emsTrainingHourInstructors.length;
      instructorId = instructorLength.toString();
    }
    else {
      instructorId = "N/A";
    }
    
    return instructorId;

  }

  openPopupInstructor(emsTrainingHourInstructors: EmsTrainingInstructor[]) {
    let strlist: string[] = [];
    let instructorId: number = 0;
    let trainingHours: number = null;
    let otherInstructorStr: string = "";
    if (emsTrainingHourInstructors.length > 0) {
      for (let i = 0; i < emsTrainingHourInstructors.length; i++) {
        instructorId = emsTrainingHourInstructors[i].instructor;
        otherInstructorStr = emsTrainingHourInstructors[i].otherInstructor;
        var instructorItem = this.emsTrainingInstructorsCode.findIndex(x => x.systemCodeId == instructorId);
        if (instructorItem > 0) {
          //trainingCodeName = trainingCodeName + this.emsTrainingCodefordisplay[trainingCodeItem].type + " " + trainingHours + "Hrs";
          //strlist.push(this.emsTrainingInstructorsCode[instructorItem].type + " - " + otherInstructorStr);
          if (otherInstructorStr != null) {
            strlist.push(this.emsTrainingInstructorsCode[instructorItem].type + " - " + otherInstructorStr);
          }
          else {
            strlist.push(this.emsTrainingInstructorsCode[instructorItem].type);
          }
        }
      }
    }
    else {
      strlist.push("N/A");
    }

   
    this.openDialogInstructor(strlist);

  }
  openDialogInstructor(nameList: any) {
    const dialogRef = this.dialog.open(ModelDialogContent, {
      data: {
        names: nameList,
        headingName: "Instructor"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployeesFromEMSTrainingStudents', 0).subscribe(res => {
      // this.employeeResutObj = res;

      //  public variables2 = [{ id: 0, name: 'One' }, { id: 1, name: 'Two' }, { id: 2, name: 'Three' }];
      // public filteredList5 = this.variables2.slice();


      this.employeeResutObj = res;//.map(x => { return { employeeId: x.employeeId, value: x.firstName + ' ' + x.miName + ' ' + x.lastName }; });
      this.filteredList5 = this.employeeResutObj.slice();
      //this.blockUI.stop();
    },
      (err: any) => {
        //  this.blockUI.stop();
      });

  }
  startdateevent(startDate: Date) {
    //this.awardNominationModel.startDate = startDate;
    this.fromDate = startDate;
    this.showEndDateField = true;
    //this.endDateErrorMsg = "End date is required.";
    if (startDate == null) {
      this.showEndDateField = false;
      this.emsTrainingHoursModel.endDate = null;
      
    }
    
    
  }
  enddateevent(endDate: Date) {
   // this.endDateErrorMsg = "";
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
