import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee.model';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'emergencycontactview',
  templateUrl: './emergencycontactview.component.html'
})
export class EmergencyContactViewComponent {
  employeeResutModel: Employee;
  employeeId: number = 0;

  constructor(
    private dialog: MatDialog,
    private routerService: Router,
    private route: ActivatedRoute,
    private employeeService: EmployeeService, private locationStrategy: LocationStrategy) {
    this.preventBackButtonEvent();
    this.route.params.subscribe(params => {
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.employeeId = parseInt(Id);
      }
    });
  }

  ngOnInit() {
    this.employeeResutModel = this.initializeEmployeeModel();
    this.loadGrid();
  }

  initializeEmployeeModel(): Employee {
    return {
      employeeId: 0,
      lastName: null,
      miName: null,
      firstName: null,
      rank: null,
      certification: null,
      ffnNumber: null,
      kemsisNumber: null,
      dateOfBirth: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      email: null,
      phone: null,
      drivingLicense: null,
      startDate: null,
      shift: null,
      payGrade: null,
      emergencyContact: null,
      emergencyContactNumber: null,
      emergencySecContact: null,
      emergencySecContactNumber: null,
      contactNotes: null,
      employeeAward: null,
      certifications: null,
      rankName: null,
      shiftName: null,
      employeeNames: null
    } as Employee;
  }

  loadGrid() {
    this.employeeService.getEmployee('GetEmployee', this.employeeId).subscribe(res => {
      this.employeeResutModel = res;
    });
  }

  GoBack(): void {
    this.routerService.navigate(['/employeeview', { back: 'y' }]);
  }
  preventBackButtonEvent() {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
}
