import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { AwardNominationService } from '../services/awardnomination.service';
import { AwardNomination } from '../models/awardnomination.model';
import { forkJoin } from 'rxjs';
import { SystemCode } from '../models/system-codes.model';
import { Witness } from '../models/witness.model';
import { Nominee } from '../models/nominee.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee.model';

@Component({
  selector: 'awardnomination',
  templateUrl: './awardnomination.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class AwardNominationComponent implements OnInit {
  @ViewChild('awardNominationForm', { static: false }) awardNominationForm: NgForm;
  startdate: FormControl;
  startDate: Date;
  awardNominationModel: AwardNomination;
  floatLabel: string = 'never';
  awardNominationId: number;
  nomineeValid: boolean = false;
  awardRecommendations: SystemCode[] = [];
  incidentTypes: SystemCode[] = [];
  statuses: SystemCode[] = [];
  submittedByName: Employee[] = [];
  nomineenames: Employee[] = [];
  witnessnames: Employee[] = [];
  nominees: Nominee[] = [];
  witness: Witness[] = [];
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  requestErrorMsg: string = "";
  witnessRequestErrorMsg: string;
  enableButton: boolean = false;
  enableAddWitness: boolean = false;
  disableSave: boolean = false;
  totalActiveItems: number = 0;
  totalActiveWitness: number = 0;
  nomineeNameValid: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;
  maxDate = new Date();
  minDate = new Date(2010, 0, 1);
  whitespacepattern = /^[^\s]+([\s\S]+)*$/;
  runNumberPattern = /^[0-9]{0,11}$/;

  constructor(
    private systemCodeAwardService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private awardNominationService: AwardNominationService,
    private dateAdapter: DateAdapter<Date>,
    private employeeService: EmployeeService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    route.params.subscribe(val => {
      if (val.id == undefined) {
        this.awardNominationId = 0;
        this.resetTheForm();
      } else {
        this.awardNominationId = parseInt(val.id);
      }
    });
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.awardNominationModel = this.initializeAwardNominationModel();
      this.loadDropDowns();
      var nominee = this.initializeNomineeModel();
      this.nominees.push(nominee);
      var witness = this.initializeWitnessModel();
      this.witness.push(witness);
      //this.awardNominationModel = { awardNominationId: 0, awardRecommendation: 0, incidentType: 0, actionDate: null, runNumber: "", narrative: "", submittedBy: 0, status: 0, nominees: null, witness: null };
      //var nominee1 = { nomineeId: 0, nomineeName: 0, awardNominationId: 0, isActive: true };
      //this.nominees.push(nominee1);
      //var witness = { witnessId: 0, witnessName: 0, awardNominationId: 0, isActive: true };
      //this.witness.push(witness);
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.awardNominationId = parseInt(Id);
          this.loadForm();
        }
      });
    }
  }

  loadForm() {
    if (this.awardNominationId > 0) {
      this.awardNominationService.getAwardNominee('GetAwardNominee', this.awardNominationId).subscribe(res => {
        this.awardNominationModel = res;
        this.nominees = res.nominees;
        this.witness = res.witness;
        this.nomineeNameValid = true;
        this.enableButton = true;
        this.enableAddWitness = true;
        this.calculateTotalActiveItems();
        this.calculatetotalActiveWitness();
      }, (error: any) => { }
      );
    }
  }

  initializeAwardNominationModel(): AwardNomination {
    return {
      awardNominationId: 0, awardRecommendation: null, incidentType: null, actionDate: null, runNumber: null,
      narrative: null, submittedBy: null, status: null, nominees: null, witness: null, nomineeName: null, witnessName: "",
      awardRecommendationName: "", incidentTypeName: "", submittedByName: "", statusName: "", startDate: null, endDate: null
    } as AwardNomination;
  }

  initializeNomineeModel(): Nominee {
    return {
      nomineeId: 0, nomineeName: null, awardNominationId: 0, isActive: true, orderId: this.nominees.length + 1
    } as Nominee;
  }

  initializeWitnessModel(): Witness {
    return {
      witnessId: 0, witnessName: 0, awardNominationId: 0, isActive: true, orderId: this.witness.length + 1
    } as Witness;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeAwardService.getSystemCodesForAwards(),
      this.systemCodeAwardService.getSystemCodesForIncidentType(),
      this.systemCodeAwardService.getSystemCodesForStatus(),
      this.employeeService.getAllEmployees('GetEmployees', 0)
      //this.systemCodeAwardService.getAllPersonnel(),
      //this.systemCodeAwardService.getSystemCodesForNominees(),
      //this.systemCodeAwardService.getSystemCodesForWitnesses()
    ).subscribe(([res1, res2, res3, res4]) => {
      this.awardRecommendations = res1 || [];
      this.incidentTypes = res2 || [];
      this.statuses = res3 || [];
      if (this.awardNominationId == 0) {
        this.submittedByName = res4.filter(x => x.isActive == true) || [];
        this.nomineenames = res4.filter(x => x.isActive == true) || [];
        this.witnessnames = res4.filter(x => x.isActive == true) || [];
      } else {
        this.submittedByName = res4 || [];
        this.nomineenames = res4 || [];
        this.witnessnames = res4 || [];
      }      
    });
  }

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  public updateAwardNomination() {
    this.disableSave = true;
    this.awardNominationModel.nominees = this.nominees;
    this.awardNominationModel.witness = this.witness;
    this.awardNominationModel.actionDateStr = this.getDateAsString(new Date(this.awardNominationModel.actionDate));

    if (this.awardNominationId > 0) {
      this.awardNominationService.updateAwardNomination(this.awardNominationId, this.awardNominationModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.resetTheForm();
            //this.routerService.navigate(['/awardnomination']);
            this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
        }
      });
    } else {
      this.awardNominationService.saveAwardNomination('SaveAwardNomination', this.awardNominationModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
        }
      });
    }
  }

  GoBack() {
    this.routerService.navigate(['/awardnominationreport']);
    //return false;
  }

  resetTheForm() {
    this.awardNominationForm.resetForm();
    this.awardNominationModel = this.initializeAwardNominationModel();
    this.nominees = [];
    this.witness = [];
    var nominee = this.initializeNomineeModel();
    this.nominees.push(nominee);
    var witness = this.initializeWitnessModel();
    this.witness.push(witness);
    this.requestErrorMsg = "";
    this.witnessRequestErrorMsg = "";
    this.totalActiveItems = 0;
    this.totalActiveWitness = 0;
    this.nomineeNameValid = false;
    this.enableButton = false;
    this.enableAddWitness = false;
    this.disableSave = false;
    return false;
    //var nominee1 = { nomineeId: 0, nomineeName: 0, awardNominationId: 0, isActive: true };
    //this.nominees.push(nominee1);
    //var witness1 = { witnessId: 0, witnessName: 0, awardNominationId: 0, isActive: true };
    //this.witness.push(witness1);
  }

  onSelectionChangedNominee() {
    if (this.nominees.length > 0) {
      if (this.nominees[this.nominees.length - 1].nomineeName == 0 || this.nominees[this.nominees.length - 1].nomineeName == null) {
        this.nomineeNameValid = false;
        this.requestErrorMsg = "Nominee Name is required";
        this.enableButton = false;
      } else {
        this.nomineeNameValid = true;
        this.requestErrorMsg = "";
        this.enableButton = true;
      }
    }
  }

  onSelectionChangedWitness(event$) {
    if (event$ != null) {
      this.witnessRequestErrorMsg = "";
      this.enableAddWitness = true;
    } else {
      this.witnessRequestErrorMsg = "Witness Name is required";
      this.enableAddWitness = false;
    }
  }

  //Nominee
  addAnotherItemNominee() {
    if (this.nominees.length > 0) {
      let thenominees: Nominee[] = [];
      for (var i = 0; i < this.nominees.length; i++) {
        if (this.nominees[i].isActive == true) {
          thenominees.push(this.nominees[i]);
        }
      }

      if (thenominees.length == 0 || (thenominees[thenominees.length - 1].nomineeName != 0 && thenominees[thenominees.length - 1].nomineeName != null)) {
        var nominee = this.initializeNomineeModel();
        this.nominees.push(nominee);
        this.enableButton = false;
      } else {
        this.requestErrorMsg = "Name is required";
        this.enableButton = true;
      }
    } else {
      var nominee = this.initializeNomineeModel();
      this.nominees.push(nominee);
    }
    this.calculateTotalActiveItems();
    return false;
  }

  calculateTotalActiveItems() {
    this.totalActiveItems = 0;
    for (let x = 0; x < this.nominees.length; x++) {
      if (this.nominees[x].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
      if (this.totalActiveItems > 0 && this.nominees[x].nomineeName != null) {
        this.enableButton = true;
        this.nomineeNameValid = true;
      }
      if (this.nominees[x].nomineeName == null && this.nominees[x].isActive == true) {
        this.enableButton = false;
        this.nomineeNameValid = false;
      }
    }
  }

  //Witness
  addAnotherItemWitness() {
    if (this.witness.length > 0) {
      let thewitness: Witness[] = [];
      for (var i = 0; i < this.witness.length; i++) {
        if (this.witness[i].isActive == true) {
          thewitness.push(this.witness[i]);
        }
      }
      if (thewitness.length == 0 || (thewitness[thewitness.length - 1].witnessName != 0 && thewitness[thewitness.length - 1].witnessName != null)) {
        var witness = this.initializeWitnessModel();
        this.witness.push(witness);
        this.enableAddWitness = false;
      } else {
        this.witnessRequestErrorMsg = "Witness is required";
        this.enableAddWitness = true;
      }
    } else {
      var witness = this.initializeWitnessModel();
      this.witness.push(witness);
    }
    this.calculatetotalActiveWitness();
    return false;
  }

  calculatetotalActiveWitness() {
    this.totalActiveWitness = 0;
    for (let x = 0; x < this.witness.length; x++) {
      if (this.witness[x].isActive == true) {
        this.totalActiveWitness = this.totalActiveWitness + 1;
      }
      if (this.totalActiveWitness > 0 && this.witness[x].witnessName != 0) {
        this.enableAddWitness = true;
      }
      if (this.witness[x].witnessName == 0 && this.witness[x].isActive == true) {
        this.enableAddWitness = false;
      }
    }
  }

  //Delete Nominee
  deleteItemNominee(id: number, commentId: number) {
    this.totalActiveItems = 0;
    if (id > 0) {
      const index = this.nominees.findIndex(x => x.orderId == id);
      for (let x = 0; x < this.nominees.length; x++) {
        if (this.nominees[x].isActive == true) {
          this.totalActiveItems = this.totalActiveItems + 1;
        }
      }
      if (this.totalActiveItems == 1) {
        this.nominees[index].nomineeName = null;
        this.nominees[index].nomineeId = 0;
        this.nominees[index].awardNominationId = 0;
        this.nomineeNameValid = false;
      } else {
        this.nominees[index].isActive = false;
        this.nominees[index].nomineeName = 1;
        this.requestErrorMsg = "";
      }
    }

    if (commentId > 0) {
      const index = this.nominees.findIndex(x => x.nomineeId == commentId);
      for (let x = 0; x < this.nominees.length; x++) {
        if (this.nominees[x].isActive == true) {
          this.totalActiveItems = this.totalActiveItems + 1;
        }
      }
      if (this.totalActiveItems == 1) {
        this.nominees[index].isActive = false;
      } else {
        this.nominees[index].isActive = false;
        this.requestErrorMsg = "";
      }
    }
    this.calculateTotalActiveItems();
    return false;
  }

  //Delete Witness
  deleteItemWitness(id: number, commentId: number) {
    this.totalActiveWitness = 0;
    if (id > 0) {
      const index = this.witness.findIndex(x => x.orderId == id);
      for (let x = 0; x < this.witness.length; x++) {
        if (this.witness[x].isActive == true) {
          this.totalActiveWitness = this.totalActiveWitness + 1;
        }
      }
      if (this.totalActiveWitness == 1) {
        this.witness[index].witnessName = null;
        this.witness[index].witnessId = 0;
        this.witness[index].awardNominationId = 0;
      } else {
        this.witness[index].isActive = false;
        this.witnessRequestErrorMsg = "";
      }
    }

    if (commentId > 0) {
      const index = this.witness.findIndex(x => x.witnessId == commentId);
      for (let x = 0; x < this.witness.length; x++) {
        if (this.witness[x].isActive == true) {
          this.totalActiveWitness = this.totalActiveWitness + 1;
        }
      }
      if (this.totalActiveWitness == 1) {
        this.witness[index].isActive = false;
      } else {
        this.witness[index].isActive = false;
        this.witnessRequestErrorMsg = "";
      }
    }
    this.calculatetotalActiveWitness();
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
