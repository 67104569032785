import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator } from '@angular/material';
import { MatTable } from '@angular/material/table';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { StRoster } from '../models/stroster.model';
import { StRosterService } from '../services/stroster.service';
import { RosterAssignment } from '../models/rosterassignment.model';
import { RosterAssignmentService } from '../services/rosterassignment.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { forkJoin } from 'rxjs';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { Employee } from '../models/employee.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'stroasterupdate',
  templateUrl: './stroasterupdate.component.html',
})
export class StRoasterUpdateComponent {
  @ViewChild('stRoasterUpdateForm', { static: false }) stRoasterUpdateForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @BlockUI() blockUI: NgBlockUI;
  container: NgForm;

  isVisible: boolean = false;
  stRosterId: number;
  rosterId: number;
  errMsg: string;

  stRosterModel: StRoster;
  rosterModel: StRoster[] = [];
  rosterassignmentModel: RosterAssignment;

  crewKey: SystemCodeKeyValue[] = [];
  stationKey: SystemCodeKeyValue[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  rolePermissionCheckModel: RolePermissionCheck;
  allEmployeeObj: Employee[] = [];

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private stRosterService: StRosterService, private employeeService: EmployeeService,) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
    this.loadDropDowns();
      this.loadGrid();
      this.loadEmployee();
    this.route.params.subscribe(params => {
      //debugger;
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.stRosterId = parseInt(Id);
      }
    });
    this.stRosterModel = this.initializeStRosterModel();
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    if (this.stRosterId > 0) {
      this.stRosterService.getRoster('getRoster', this.stRosterId).subscribe(res => {
        debugger;
        this.stRosterModel = res || [];
        console.log(res);
      }, (error: any) => {
        console.log("Error :", error);
      });
      }
    }
  }

  initializeStRosterModel(): StRoster {
    return {
      stRosterId: 0, shiftDate: null, personnel: null, crew: null, station: null, fromDate: null,
      toDate: null, endDateCheck: false, rosterAssignments: null, startDate: null,
      endDate: null,
      shiftDateStr: null,
      crewName: null,
      employeeName: null
    } as StRoster;
  }

  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: "", fromTime: null, toTime: null,
      isActive: true, stRosterId: 0
    } as RosterAssignment;
  }

  loadGrid() {
    debugger;
    this.stRosterService.getAllRoster().subscribe(
      (res: Array<StRoster>) => {
        debugger;
        this.rosterModel = res || [];            
        this.rosterId = this.stRosterId;
        console.log(res);

      }, (error: any) => {
      });
  }
  loadEmployee() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      //this.employeeResutObj = res;
      this.allEmployeeObj = res;
      //  debugger;
      console.log(res);
      this.blockUI.stop();
    }, err => {
      console.log(err);
      this.blockUI.stop();
    });
  }
  visible() {
    this.isVisible = true;
  }

  public loadDropDowns() { 
    forkJoin(     
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllStations(),
      this.systemCodeService.getAllPersonnel()     
    ).subscribe(([res1, res2, res3]) => {

      this.crewKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.stationKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.personnelKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      console.log("this.crewKey", this.crewKey);
     
    });    
  }

  getEmployeeName(empId: number) {
    // debugger;
    let employeeIndex = this.allEmployeeObj.findIndex(x => x.employeeId == empId);
    if (employeeIndex > -1) {
      let firstName: string = this.allEmployeeObj[employeeIndex].firstName;
      let miName: string = this.allEmployeeObj[employeeIndex].miName;
      let lastName: string = this.allEmployeeObj[employeeIndex].lastName;

      firstName = (firstName == null) ? "" : firstName;
      miName = (miName == null) ? "" : miName;
      lastName = (lastName == null) ? "" : lastName;

      return (firstName + ' ' + miName + ' ' + lastName);
    }
    else {
      return "";
    }

  }
  GoBack() {
    this.routerService.navigate(['/stroasterreport']);
  }

  GoToEdit(id:number) {
    this.routerService.navigate(['stroaster', { id }]);
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
