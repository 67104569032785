import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { Certification } from '../models/certification.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CertificationService } from '../services/certification.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { EmployeeService } from '../services/employee.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as jspdf from 'jspdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { AngularCsv } from 'angular7-csv';
import autoTable from 'jspdf-autotable';
import { GlobalConstants } from '../common/global-constants';
import { CertificationPopUpComponent } from './certificationPopUp.component';
@Component({
  selector: 'certificationupdate',
  templateUrl: './certifictionupdate.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
  //,styleUrls: ['./employeecertification.component.css']
})
export class CertificationUpdateComponent {
  @ViewChild('certificateUpdateForm', { static: false }) certificateUpdateForm: NgForm;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  myControl = new FormControl();
  filteredOptions: Observable<Certification[]>;
  certificationModel: Certification;
  certificationId: number = null;
  certificationName: string = null;
  expiryDate: Date = null;
  certifications: string[] = [];
  control = new FormControl();
  certificationNames: Observable<string[]>;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  empcertifications: Certification[] = [];
  dataSource: MatTableDataSource<Certification>;
  todayDate: Date = null;
  newDate: Date = null;
  emptyDate: Date = null;
  startDate: Date = null;
  endDate: Date = null;
  certificationNumber: string = null;
  employeename: string = null;
  ffnNumber: string = null;
  kemisNumber: string = null;
  stDate: FormControl;
  edDate: FormControl;
  rolePermissionCheckModel: RolePermissionCheck;
  isChecked: boolean = false;
  stMinDate: Date;
  stMaxDate: Date;
  fontName: string = GlobalConstants.fontName;
  fontSize: number = GlobalConstants.fontSize;
  headSize: number = GlobalConstants.headingSize;
  displayedColumns: any[] = ['Select All','employeeName', 'certificationDisplayName', 'certificationNumber', 'dateOfExpiry', 'noExpiry','newDateOfExpiry'];
  footerFontSize: number = GlobalConstants.footerFontSize;
  certificationsIdsandName: Certification[] = [];
  options: Certification[] = [
    //{ id: 2, name: 'Shelley' },
    //{ id: 3, name: 'Igor' },
    //{ id: 4, name: 'Shell' },
    //{ id: 5, name: 'She is' },
    //{ id: 5, name: 'She was' },
  ];
  certificationNameForPDF: string = null;
  certificationdataId: string = null;
  constructor(
    private certificationService: CertificationService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private employeeService: EmployeeService, private dateAdapter: DateAdapter<Date>) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    this.loadCertificatesIds();
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadCertificates();
    }
  }

  seachCertification() {
    //debugger;
    //this.empcertifications = [];
    if (this.certificationId == null) {
      this.certificationId = 0;
    }
    if (this.certificationNumber == null) {
      this.certificationNumber = "";
    }
    if (this.employeename == null) {
      this.employeename = "";
    }
    if (this.ffnNumber == null) {
      this.ffnNumber = "";
    }
    if (this.kemisNumber == null) {
      this.kemisNumber = "";
    }
    let expiry = '';
    if (this.expiryDate != null && this.expiryDate != undefined) {
      expiry = this.expiryDate.toLocaleDateString();
      //this.expiryDate.toDateString
    }
    this.employeeService.searchCertification("searchCertificate", this.certificationId.toString(), this.certificationNumber, this.startDate, this.endDate, this.employeename, this.ffnNumber, this.kemisNumber).subscribe(res => {
      this.empcertifications = res;
    //  console.log(this.empcertifications);
      this.dataSource = new MatTableDataSource(this.empcertifications);
      this.dataSource.sort = this.sort;
    });
    return false;
  }

  loadCertification() {
    //console.log(this.certificationNumber);
    // this.empcertifications = [];
    if (this.certificationName == null) {
      this.certificationName = "";
    }
    if (this.certificationNumber == null) {
      this.certificationNumber = "";
    }
    if (this.employeename == null) {
      this.employeename = "";
    }
    if (this.ffnNumber == null) {
      this.ffnNumber = "";
    }
    if (this.kemisNumber == null) {
      this.kemisNumber = "";
    }

    let expiry = '';
    if (this.expiryDate != null && this.expiryDate != undefined) {
      expiry = this.expiryDate.toLocaleDateString();
      //this.expiryDate.toDateString
    }
    this.employeeService.searchCertification("searchCertificate", this.certificationName, this.certificationNumber, this.startDate, this.endDate, this.employeename, this.ffnNumber, this.kemisNumber).subscribe(res => {
      this.empcertifications = res;
    //  console.log(this.empcertifications);
      this.dataSource = new MatTableDataSource(this.empcertifications);
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.emptyDate = new Date("0001-01-01T00:00:00");
      let dt = new Date();
      //this.startDate = dt;
      //this.endDate = new Date();
      var edate = new Date();
      //this.endDate.setDate(edate.getDate() + 30);
      let tdate = (dt.getMonth() + 1) + "/" + (dt.getDate() + 1) + "/" + dt.getFullYear();
      this.todayDate = new Date(tdate);
      //this.certificationNames = this.control.valueChanges.pipe(
      //  startWith(''),
      //  map(value => this._filter(value))
      //);
      this.loadCertification();
      this.stMinDate = new Date("12/30/1900");
      this.stMaxDate = new Date("12/30/2050");
    }
    this.loadCertificatesIds();
    debugger;
    this.dataSource = new MatTableDataSource(this.empcertifications);
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
    console.log(this.empcertifications);
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.certificationName),
        map(name => name ? this._filter(name) : this.certificationsIdsandName.slice())
      );
  }

  endDateChange(dt: any) {
    //this.startMaxDate = dt;
    this.stMaxDate = new Date(dt);
  }

  startDateChange(dt: any) {
    //this.startMaxDate = dt;
    this.stMinDate = new Date(dt);
  }

  //private _filter(value: string): string[] {
  //  const filterValue = this._normalizeValue(value);
  //  return this.certifications.filter(s => this._normalizeValue(s).includes(filterValue));
  //}
  //private _normalizeValue(value: string): string {
  //  if (value == null) {
  //    return "";
  //  }
  //  return value.toLowerCase().replace(/\s/g, '');
  //}

  loadCertificates() {
    this.employeeService.loadCertificates('getCertificates').subscribe(res => {
      this.certifications = res;
    }, err => { });
    return false;
  }

  resetAll() {
    this.certificationName = null;
    this.certificationId = null;
    this.expiryDate = null;
    this.newDate = null;
    this.employeename = null;
    this.certificationNumber = null;
    this.startDate = null;
    this.endDate = null;
    // this.empcertifications = [];
    this.loadCertification();
    this.certificateUpdateForm.resetForm();
    this.isChecked = false;
    this.stMinDate = new Date("12/30/1900");
    this.stMaxDate = new Date("12/30/2050");
    this.ffnNumber = null;
    this.kemisNumber = null;
    return false;
  }

  displayFn(certification: Certification): string {
    //return ;
    //var id = certification.certificationId;
    //var stringId = id.toString();
    //this.certificationModel.certificationId = certification.certificationId;
    var stringId = certification && certification.certificationId ? certification.certificationId : null;
    //if (stringId) {
    //  this.certificationModel.certificationId = stringId;
    //}
    return certification && certification.certificationName ? certification.certificationName : '';
  }

  getId(event$) {
    var idNumber = event$.option.value.certificationId;
    this.certificationId = idNumber;
    this.certificationNameForPDF = event$.option.value.certificationName;
    // this.certificationModel.certificationId = event$.option.value.certificationId;
  }

  private _filter(name: string): Certification[] {
    const filterValue = name.toLowerCase();
    let returnValue = this.certificationsIdsandName.filter(certificationsIdsandName => certificationsIdsandName.certificationName.toLowerCase().indexOf(filterValue) === 0)
    let returnValueIds = this.certificationsIdsandName.filter(certificationsIdsandName => certificationsIdsandName.certificationId)
    if (returnValue.length != 0) { }
    return returnValue;
  }

  loadCertificatesIds() {
    this.employeeService.loadCertificatesIds('getCertificatesIdandName').subscribe(res => {
      this.certificationsIdsandName = res;
    }, err => {
    });
    return false;
  }

  updateCertifictions() {
   // debugger;
    for (let i = 0; i < this.empcertifications.length; i++) {
      this.empcertifications[i].newDateOfExpiryStr = this.getDateAsString(new Date(this.empcertifications[i].newDateOfExpiry));
      console.log(this.empcertifications[i].newDateOfExpiryStr);
    }
  //  console.log(this.empcertifications);
    this.employeeService.saveCertifications('updateCertificate', this.empcertifications).subscribe(res => {
      this.errMsg = res.msg;
      if (this.errMsg == "Saved Successfully.") {
        this.savedialog = true;
      } else {
        this.errordialog = true;
      }

      if (this.savedialog == true) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Saved Successfully!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          //this.resetTheForm();
          this.resetAll();
        });
      } else if (this.errordialog == true) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error Occured. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.resetAll();
        });
      }
    });
  }

  checkThis(id: number) {
    const index = this.empcertifications.findIndex(x => x.employeeCertificationId == id);
    this.empcertifications[index].isChecked = !this.empcertifications[index].isChecked;
    let tmpCounter = 0;
    for (let i = 0; i < this.empcertifications.length; i++) {
      if (this.empcertifications[i].isChecked == true) {
        tmpCounter = tmpCounter + 1;
      }
    }
    if (tmpCounter == this.empcertifications.length) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }

  setNoExpiry(id: number) {

    const index = this.empcertifications.findIndex(x => x.employeeCertificationId == id);
    this.empcertifications[index].noExpiry = !this.empcertifications[index].noExpiry;
   // console.log()
    let emptyDt: Date = new Date('1900-01-01');
    emptyDt.setMonth(0);
    emptyDt.setDate(1);
    // this.empcertifications[index].dateOfExpiry = emptyDt;
    this.empcertifications[index].newDateOfExpiry = emptyDt;
    
  }

  applyNewDate() {
    for (let x = 0; x < this.empcertifications.length; x++) {
      if (this.empcertifications[x].isChecked == true && this.empcertifications[x].noExpiry == false) {
        this.empcertifications[x].newDateOfExpiry = this.newDate;
      }
    }
  }

  getDisplayDate(dt: any) {
    if (dt == null) {
      return "";
    }
    let dt1: Date = new Date(dt);
    if (dt1.getDate().toString() == "1" && dt1.getMonth().toString() == "0" && (dt1.getFullYear().toString() == "1" || dt1.getFullYear().toString() == "0" || dt1.getFullYear().toString() == "1899" || dt1.getFullYear().toString() == "1900")) {
      return "";
    } else {
      return dt1;
    }
  }

  downloadInPdf1() {
    localStorage.setItem('empcertifications', JSON.stringify(this.empcertifications));
    this.routerService.navigate(['certificationpdfgenerator']).then(result => {
      //  window.open('certificationpdfgenerator');
    });

    //var data = document.getElementById('content');
    //html2canvas(data).then(canvas => {
    //  // Few necessary setting options
    //  var imgData = canvas.toDataURL('image/png');
    //  var imgWidth = 210;
    //  var pageHeight = 295;
    //  var imgHeight = canvas.height * imgWidth / canvas.width;
    //  var heightLeft = imgHeight;
    //  var doc = new jsPDF('p', 'mm');
    //  var position = 10; // give some top padding to first page
    //  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //  heightLeft -= pageHeight;
    //  while (heightLeft >= 0) {
    //    position += heightLeft - imgHeight; // top padding for other pages
    //    doc.addPage();
    //    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //    heightLeft -= pageHeight;
    //  }
    //  doc.save('file.pdf'); // Generated PDF   
    //});

    return false;

    //var data = document.getElementById('content');
    //html2canvas(data).then(canvas => {
    //  // Few necessary setting options  
    //  var imgWidth = 208;
    //  var pageHeight = 295;
    //  var imgHeight = canvas.height * imgWidth / canvas.width;
    //  var heightLeft = imgHeight;
    //  const contentDataURL = canvas.toDataURL('image/png');
    //  let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
    //  var position = 0;
    //  pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //  pdf.save('MYPdf.pdf'); // Generated PDF   
    //});
  }

  downloadInPdf() {
    let title = "Certification Expiration List";
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 30;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF('p', 'mm', 'letter');
    doc.setProperties({
      title: 'Certification_Expiration_List'
    });
    var totalPagesExp = '{total_pages_count_string}';
    doc.setFont(this.fontName);
    doc.setTextColor(40)
    doc.setFontSize(this.headSize)
    var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 20);
    doc.setFontSize(10);

    //Certification Name Filter Code Begin
    let certificationNameFilter = 'Certification Name: ';
    if (this.certificationNameForPDF) {
      certificationNameFilter = certificationNameFilter + this.certificationNameForPDF;
    }

    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(certificationNameFilter, pageWidth - 35);
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Certification Name Filter Code End

    //Certification Number Filter Code Begin
    let certificationNumberFilter = 'Certification Number: ';
    if (this.certificationNumber) {
      certificationNumberFilter = certificationNumberFilter + this.certificationNumber;
    }

    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(certificationNumberFilter, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Certification Number Filter Code End

    //Start Date and End Date Filter Code Begin
    let startDate = 'Start Date: ';
    if (this.startDate) {
      let startDateString = new Date(this.startDate);
      let startDateFormat = this.getDateAsString(startDateString);
      startDate = startDate + startDateFormat;
    }
    let endDate = 'End Date: ';
    if (this.endDate) {
      let endDateString = new Date(this.endDate);
      let endDateFormat = this.getDateAsString(endDateString);
      endDate = endDate + endDateFormat;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Start Date and End Date Filter Code End

    //FFN Number Filter Code Begin
    let ffnNumberFilter = 'FFN Number: ';
    if (this.ffnNumber) {
      ffnNumberFilter = ffnNumberFilter + this.ffnNumber;
    }

    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(ffnNumberFilter, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //FFN Number Filter Code End

    //KEMSIS Number Filter Code Begin
    let kemsisNumberFilter = 'KEMSIS Number: ';
    if (this.kemisNumber) {
      kemsisNumberFilter = kemsisNumberFilter + this.kemisNumber;
    }

    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(kemsisNumberFilter, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //KEMSIS Number Filter Code End

    //Employee Name Filter Code Begin
    let employeeNameFilter = 'Employee Name: ';
    if (this.employeename) {
      employeeNameFilter = employeeNameFilter + this.employeename;
    }

    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(employeeNameFilter, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Employee Name Filter Code End

    //if (!this.checkPrintReportFilters()) {
    //  startYVariable = 40;
    //} else {
    //  startYVariable = yAxis + 23;
    //}
    var currentDate = new Date();
    var currentDateString = this.getDateAsString(currentDate);

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        crew: {
          cellWidth: 15
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      //headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      //bodyStyles: { minCellHeight: 8, fillColor: null },
      //head: this.getHeadRows(),
      //body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 170, pageHeight - 10);

        doc.text(currentDateString, 35, pageHeight - 10);
      },
      startY: yAxis
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    //doc.output('dataurlnewwindow');
    window.open(URL.createObjectURL(doc.output("blob")));
  }

  checkPrintReportFilters() {
    //if (this.dailyRoaster.shiftDate == null) {
    //  return true;
    //}
    return true;
  }

  getHeadRows() {
    return [
      {
        employeeName: 'Employee Name', certificationName: 'Certification Name', certificationNumber: 'Certification No.', expiryDate: 'Expiry Date', noExpiry: 'No Expiry'
      }
    ]
  }

  getBody() {
    let rowCount = this.empcertifications.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      body.push({
        employeeName: this.empcertifications[j].employeeName,
        certificationName: this.empcertifications[j].certificationDisplayName,
        certificationNumber: this.empcertifications[j].certificationNumber,
        expiryDate: (this.empcertifications[j].dateOfExpiry && this.getDateAsString(new Date(this.empcertifications[j].dateOfExpiry)) != "01/01/1900") ? this.getDateAsString(new Date(this.empcertifications[j].dateOfExpiry)) : "",
        noExpiry: (this.empcertifications[j].noExpiry) ? "Yes" : "No"
      });
    }
    return body;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  checkAll() {
    this.isChecked = !this.isChecked;
    for (let i = 0; i < this.empcertifications.length; i++) {
      this.empcertifications[i].isChecked = this.isChecked;
    }
  }

  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null;
    }
  }

  //exportCSV
  exportCertificationReportasCSV() {
    this.seachCertification();
    this.exportToCSV();
  }

  exportToCSV() {
    let rowCount = this.empcertifications.length;
    var data = []
    data.push({
      employeeName: 'Employee Name', certificationName: 'Certification Name', certificationNumber: 'Certification Number',
      dateOfExpiry: 'Expiration Date', noExpiry: 'No Expiration'
    });
    for (var j = 0; j < rowCount; j++) {
      let expireDate = this.empcertifications[j].dateOfExpiry;
      let expireDateStringFormat = '';
      if (expireDate) {
        let newActionDate = new Date(expireDate);
        expireDateStringFormat = this.getDateAsString(newActionDate);
      }
      data.push({
        employeeName: this.empcertifications[j].employeeName,
        certificationName: (this.empcertifications[j].certificationDisplayName) ? this.empcertifications[j].certificationDisplayName : '',
        certificationNumber: (this.empcertifications[j].certificationNumber) ? this.empcertifications[j].certificationNumber : '',
        dateOfExpiry: (this.empcertifications[j].dateOfExpiry) ? expireDateStringFormat : '',
        noExpiry: (this.empcertifications[j].noExpiry) ? 'Yes' : 'No'
      })
    }
    new AngularCsv(data, 'Certification  Report');
  }
  GetCertification(): void {
    this.routerService.navigate(['/certificationpopup']);
  }
  openAssignment(id: number) {
    //debugger;
    const dialogRef = this.dialog.open(CertificationPopUpComponent, {
      width: '1300px',
      data: { id: id, certificationId: this.certificationdataId },
      disableClose: true,
      //hasBackdrop: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      //debugger;
      this.resetAll();
      //this.user = user;
    });
    return false;
  }
}
