import { Component, ViewChild, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, of } from 'rxjs';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllEmployees } from '../models/get-all-employees.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'employeeview',
  templateUrl: './employeeview.component.html',
  styleUrls: ['./employeeview.component.css']
})
export class EmployeeViewComponent {
  @ViewChild('employeeview', { static: false }) employeeForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  //@HostListener('window:popstate', ['$event'])
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  resultsLength: number = 0;
  employeeModel: Employee;
  employeeResultModel: Employee[] = [];
  isFound: boolean = false;
  showNewButton: boolean = false;
  showSearchMsg: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  dataSource: MatTableDataSource<Employee>;
  assignmentObjDB = new MatTableDataSource<Employee>([]);
  //dataSource: MatTableDataSource<Employee>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  isBack: string = '';
  cbxShowAll: boolean = false;
  displayedColumns: any[] = ['firstName', 'lastName', 'miName', 'rankName', 'ffnNumber', 'dateOfBirth', 'startDate','isActive'];
  rolePermissionCheckModel: RolePermissionCheck;
  //HostListener('window:popstate', ['$event']);
  constructor(
    private dialog: MatDialog,
    private routerService: Router,
    private employeeService: EmployeeService,
    private route: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //this.blockUI.start();
      this.employeeModel = this.initializeEmployeeModel();
      this.employeeModel.isActive = true;
      this.route.params.subscribe(params => {
        this.isBack = params['back']
      });
      if (this.rolePermissionCheckModel) {
        this.initializeActionsForDisplayedColumns();
        this.dataSource = new MatTableDataSource(this.employeeResultModel);
        this.dataSource.sort = this.sort;
       // this.dataSource.paginator = this.paginator;
      }
      // this.blockUI.stop();
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    //if (this.rolePermissionCheckModel.isDelete) {
    //  this.displayedColumns.push('delete');
    //}
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.blockUI.start();
      debugger;
      //LastClick
      if (this.isBack == 'y') {
        if (localStorage.getItem('LastClick') != undefined) {
          if (localStorage.getItem('LastClick') == "E") {

            var pageIndex = parseInt(localStorage.getItem('PageIndex').toString());
            if (pageIndex > 0)
              this.paginator.pageIndex = pageIndex;
            else
              this.paginator.pageIndex = 0;

            var pageSize = parseInt(localStorage.getItem('PageSize').toString());
            if (pageSize > 0)
              this.paginator.pageSize = pageSize;
            else
              this.paginator.pageSize = 15;

            this.employeeModel = JSON.parse(localStorage.getItem('SearchParams').toString());
            if (this.employeeModel.address == "YES") {
              this.cbxShowAll = true;
            }
            else {
              this.cbxShowAll = false;
            }
           
            console.log(this.employeeModel);
          }
          else {
            if (localStorage.getItem('SearchParams') != undefined) {
              this.employeeModel = JSON.parse(localStorage.getItem('SearchParams').toString());
              if (this.employeeModel.address == "YES") {
                this.cbxShowAll = true;
              }
              else {
                this.cbxShowAll = false;
              }

              var pageIndex = parseInt(localStorage.getItem('PageIndex').toString());
              if (pageIndex > 0)
                this.paginator.pageIndex = pageIndex;
              else
                this.paginator.pageIndex = 0;

              var pageSize = parseInt(localStorage.getItem('PageSize').toString());
              if (pageSize > 0)
                this.paginator.pageSize = pageSize;
              else
                this.paginator.pageSize = 15;

              console.log('S');
              console.log(this.employeeModel);
            }
          }
        }
      }
      else {
        if (localStorage.getItem('LastClick') != undefined && localStorage.getItem('LastClick') != null)
          localStorage.removeItem('LastClick');

        if (localStorage.getItem('PageIndex') != undefined && localStorage.getItem('PageIndex') != null)
          localStorage.removeItem('PageIndex');

        if (localStorage.getItem('SearchParams') != undefined && localStorage.getItem('SearchParams') != null)
          localStorage.removeItem('SearchParams');  
      }

      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
           // debugger;
            //this.blockUI.start();
            return this.getAllEmployees(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
           // debugger;
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
      ).subscribe(data => {
        //debugger;
          this.employeeResultModel = data || [];
          this.assignmentObjDB.data = data || [];
          //this.employeeResultModel = data || [];
          this.dataSource = new MatTableDataSource(this.employeeResultModel);
          this.dataSource.sort = this.sort;
          //this.dataSource.paginator = this.paginator;
          this.blockUI.stop();
      });

      
    }
  }

  getAllEmployees(pageIndex: number, pageSize: number): Observable<GetAllEmployees> {
    debugger;


    return this.employeeService.getAllEmployeesNew(
      'GetEmployeesNew',
      (this.employeeModel.firstName) ? this.employeeModel.firstName : '',
      (this.employeeModel.lastName) ? this.employeeModel.lastName : '',
      (this.employeeModel.ffnNumber) ? this.employeeModel.ffnNumber : '',
      (this.employeeModel.isActive) ? this.employeeModel.isActive : false,
      pageIndex,
      pageSize
    );
  }

  getAllEmployeesForDataSource(): void {
    this.getAllEmployees(0, 15)
      .pipe(
        map(data => {
          //debugger;
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
    ).subscribe(data => {
     // debugger;
        this.employeeResultModel = data || [];
        this.assignmentObjDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.employeeResultModel);
        this.dataSource.sort = this.sort;
       // this.dataSource.paginator = this.paginator;
        this.blockUI.stop();
      });
  }

  initializeEmployeeModel(): Employee {
    return {
      employeeId: 0,
      title: null,
      lastName: null,
      miName: null,
      firstName: null,
      rank: null,
      certification: null,
      ffnNumber: null,
      kemsisNumber: null,
      dateOfBirth: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      email: null,
      phone: null,
      drivingLicense: null,
      startDate: null,
      shift: null,
      payGrade: null,
      emergencyContact: null,
      emergencyContactNumber: null,
      emergencySecContact: null,
      emergencySecContactNumber: null,
      contactNotes: null,
      employeeAward: null,
      certifications: null,
      rankName: null,
      shiftName: null,
      dateOfBirthStr: null,
      startDateStr: null,
      employeeNames: null,
      isActive:null
    } as Employee;
  }

  resetEmployee() {

    if (localStorage.getItem('LastClick') != undefined) {
      localStorage.removeItem('LastClick');
    }
    if (localStorage.getItem('SearchParams') != undefined) {
      localStorage.removeItem('SearchParams');
    }
    if (localStorage.getItem('PageIndex') != undefined) {
      localStorage.removeItem('PageIndex');
    }    

    this.showNewButton = false;
    this.cbxShowAll = false;
    this.resetTheForm();
    //this.loadGrid();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.getAllEmployeesForDataSource();    
  }

  resetTheForm() {
    this.employeeModel = this.initializeEmployeeModel();
    this.employeeModel.isActive = true;
  }

  //loadGrid() {
  //  this.employeeService.getAllEmployees('GetEmployees', 0).subscribe((res: Array<Employee>) => {
  //    this.employeeResultModel = res || [];

  //    this.assignmentObjDB.data = this.employeeResultModel;

  //    this.dataSource = new MatTableDataSource<Employee>(this.employeeResultModel);
  //    this.dataSource.paginator = this.paginator;
  //    this.dataSource.sort = this.sort;
  //  },
  //    err => {

  //    });

  //  this.dataSource = new MatTableDataSource<Employee>(this.employeeResultModel);
  //  this.dataSource.paginator = this.paginator;
  //  this.dataSource.sort = this.sort;
  //}

  //searchEmployee() {
  //  this.blockUI.start();
  //  this.showNewButton = false;
  //  this.showSearchMsg = false;
  //  if (!((this.employeeModel.lastName == null || this.employeeModel.lastName == '') && (this.employeeModel.firstName == null || this.employeeModel.firstName == '') && (this.employeeModel.ffnNumber == null || this.employeeModel.ffnNumber == ''))) {
  //    this.showNewButton = true;
  //    this.showSearchMsg = false;
  //  } else {
  //    this.showSearchMsg = true;
  //  }

  //  this.employeeService.employeeSearch('searchEmployee', this.employeeModel).subscribe(res => {
  //    this.employeeResultModel = res;

  //    if (this.employeeResultModel.length == 0) {
  //      this.isFound = true;
  //    } else {
  //      this.isFound = false;
  //    }

  //    this.assignmentObjDB.data = this.employeeResultModel;
  //    this.dataSource = new MatTableDataSource<Employee>(this.employeeResultModel);
  //    this.dataSource.paginator = this.paginator;
  //    this.dataSource.sort = this.sort;
  //    this.blockUI.stop();
  //  }, (err: any) => {
  //    this.blockUI.stop();
  //  });
  //  return false;
  //}

  //printResults() { }

  deleteEmployee(eId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.blockUI.start();
        this.employeeService.delEmployee('delEmployee', parseInt(eId)).subscribe(res => {
          this.getAllEmployeesForDataSource();
        });
      }
    });
    return false;
  }

  addEmployee() {
    //this.blockUI.start();
    this.routerService.navigate(['/createemployee']);
    return false;
  }
  searchEmployee1(): void {
    this.employeeModel.isActive = (this.cbxShowAll == true) ? true : false;
    this.getAllEmployeesForDataSource();  
  }

  searchEmployee(): void {
    this.blockUI.start();
    this.showNewButton = false;
    this.showSearchMsg = false;
    if (!((this.employeeModel.lastName == null || this.employeeModel.lastName == '') && (this.employeeModel.firstName == null || this.employeeModel.firstName == '') && (this.employeeModel.ffnNumber == null || this.employeeModel.ffnNumber == ''))) {
      this.showNewButton = true;
      this.showSearchMsg = false;
      this.employeeModel.isActive = (this.cbxShowAll == true) ? false : true;
      localStorage.setItem('SearchParams', JSON.stringify(this.employeeModel));
      localStorage.setItem('LastClick', 'S');
    } else {
      this.showSearchMsg = true;
      return;
    }
    this.getAllEmployeesForDataSource();
  }

  editEmployee(eId: string) {
    debugger;
    localStorage.setItem('PageIndex', this.paginator.pageIndex.toString());
    localStorage.setItem('PageSize', this.paginator.pageSize.toString());
    localStorage.setItem('LastClick', 'E');
    if (this.cbxShowAll)
      this.employeeModel.address = "YES";
    else
      this.employeeModel.address = "NO";

    localStorage.setItem('SearchParams', JSON.stringify(this.employeeModel));
    console.log(this.employeeModel);
    this.blockUI.start();
    this.routerService.navigate(['/createemployee', { id: eId }]);
    // this.blockUI.stop();
    // return false;
    // this.routerService.navigate(['/inspection', { id: event.id }]);
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  viewEmployee(eId: string) {
    localStorage.setItem('PageIndex', this.paginator.pageIndex.toString());
    localStorage.setItem('PageSize', this.paginator.pageSize.toString());
    localStorage.setItem('LastClick', 'E');
    if (this.cbxShowAll)
      this.employeeModel.address = "YES";
    else
      this.employeeModel.address = "NO";
    localStorage.setItem('SearchParams', JSON.stringify(this.employeeModel));
    this.blockUI.start();
    this.routerService.navigate(['/emergencyview', { id: eId }]);
  }
 
}
