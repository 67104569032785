import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator, MatSort, MatDialog } from "@angular/material";
import { RolePermissionCheck } from "../models/role-permission-check.model";
import { ActivatedRoute, Router } from "@angular/router";
import { SystemCodeService } from "../services/system-code.service";
import { AuthService } from "../services/auth.service";
import { RosterAssignment } from "../models/rosterassignment.model";
import { forkJoin } from "rxjs";
import { SystemCodeKeyValue } from "../models/systemcodekeyvalue.model";
import { SystemCode } from "../models/system-codes.model";
import { DailyRoasterService } from "../services/dailyroaster.service";
import { Employee } from "../models/employee.model";
import { EmployeeService } from "../services/employee.service";
import { DailyRoasterAssignment } from "../models/dailyroasterassignment.model";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AngularCsv } from 'angular7-csv';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
@Component({
  selector: 'shauntimesheet',
  styleUrls: ['./timesheetreport.component.css'],
  templateUrl: './shauntimesheet.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class ShaunTimeSheetComponent {

  @ViewChild('timesheetForm', { static: false }) timesheetForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  rolePermissionCheckModel: RolePermissionCheck;
  rosterassignmentModel: RosterAssignment;
  rosterAssignmentObj: RosterAssignment[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];
  personnelCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  employeeModelObj: Employee[] = [];
  startDate: Date;
  dailyRoasterObj: DailyRoasterAssignment[] = [];
  revisedAssignments: DailyRoasterAssignment[] = [];
  activityCodeName: string = "";
  personelName: string = "";
  activityCodes: number[] = [];
  employees: number[] = [];
  constructor(
    private dialog: MatDialog,
    private systemCodeService: SystemCodeService, private dailyRoaster: DailyRoasterService,
    private authService: AuthService, private activatedRoute: ActivatedRoute, private routerService: Router, private employeeService: EmployeeService, private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isRead || this.rolePermissionCheckModel.isPrint)) {
      this.rosterassignmentModel = this.initializeRosterAssignmentModel();
      this.rosterassignmentModel.startDate = new Date();

      let endDate = new Date();
      var edate = new Date();    
      endDate.setDate(edate.getDate() + 7);
      this.rosterassignmentModel.endDate = endDate;


      this.loadPersonnel();
      this.loadData();
      this.loadDropDowns();
    }
  }
  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: null, fromTime: null, toTime: null, startDate: null, endDate: null,
      isActive: true, stRosterId: 0, station: null
    } as RosterAssignment;
  }
  timesheetReport() {

  }
  public loadDropDowns() {

    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getAllActivityCode()
    ).subscribe(([res1, res2]) => {
      // this.personnelCode = res1 || [];
      this.activityCode = res2 || [];

      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];

    });
  }
  public loadPersonnel() {

    this.employeeService.getEmployeesBetweenDates("getEmployeesBetweenDates", this.getDateAsString(this.rosterassignmentModel.startDate), this.getDateAsString(this.rosterassignmentModel.endDate)).subscribe(
      res => {
        this.employeeModelObj = res;
      });
  }
  search() {
    this.loadData();
  }
  loadData() {
    this.revisedAssignments = [];
    let actCodes = this.activityCodes.join();
    let personals = this.employees.join();
    this.dailyRoaster.getShaunsTimeSheetView("getShaunsTimesheetView", this.rosterassignmentModel.startDate,
      this.rosterassignmentModel.endDate,
      this.activityCodes.length ? actCodes : "",
      this.employees.length ? personals : "").subscribe(res => {
        this.dailyRoasterObj = res;
        this.getFormattedTable(this.dailyRoasterObj,"");
        const activityIndex = this.activityCodeKey.findIndex(x => x.key == this.rosterassignmentModel.activityCode);
        if (activityIndex >= 0) {
          this.activityCodeName = this.activityCodeKey[activityIndex].value;
        }
        else {
          this.activityCodeName = "";
        }
        const positionIndex = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.position);
        if (positionIndex >= 0) {
          this.personelName = this.employeeModelObj[positionIndex].lastName + ", " + this.employeeModelObj[positionIndex].miName + " "
            + this.employeeModelObj[positionIndex].firstName;
        }
        else {
          this.personelName = "";
        }

      });
  }
  shiftDateChange(dt: any) {
    this.rosterassignmentModel.startDate = dt;
    this.loadPersonnel();
  }
  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
    var m = minutes % 60;
    let h1 = h < 10 ? '0' + h : h;
    let m1 = m < 10 ? '0' + m : m;
    return h1 + ':' + m1;
  }

  getFormattedTable(assignments: DailyRoasterAssignment[], type: string) {
    let lastName = "";
    let personelMins = 0;
    for (let x = 0; x < assignments.length; x++) {

      if (lastName != assignments[x].roasterId) {
        if (lastName != "") {
          let objdata = this.InitializeModel();
          objdata.hours = personelMins.toString();
          objdata.type = "data";
          this.revisedAssignments.push(objdata);
          personelMins = 0;
        }
      }
      personelMins = personelMins + parseInt(assignments[x].hours);


      if (lastName != assignments[x].roasterId) {
        let obj1 = this.InitializeModel();
        obj1.personal = assignments[x].personal;
        obj1.type = "header";
        this.revisedAssignments.push(obj1);
        lastName = assignments[x].roasterId;
      }

      let obj = this.InitializeModel();
      obj.personal = assignments[x].personal;
      obj.type = "row";
      obj.crew = assignments[x].crew;
      obj.activity = assignments[x].activity;
      obj.hours = assignments[x].hours;
      obj.startDate = assignments[x].startDate;
      obj.endDate = assignments[x].endDate;
      obj.station = assignments[x].station;
      obj.unit = assignments[x].unit;
      this.revisedAssignments.push(obj);

    }
    if (personelMins >= 0) {
      let objdata = this.InitializeModel();
      objdata.hours = personelMins.toString();
      objdata.type = "data";
      this.revisedAssignments.push(objdata);
    }
    if (type == "pdf") {
      this.printPDFReport();
    }
    else if (type == "csv") {
      this.csvReport();
    }
  }
  InitializeModel(): DailyRoasterAssignment {
    return {
      roasterId: "",
      personal: "",
      station: "",
      position: "",
      unit: "",
      activity: "",
      startDate: "",
      endDate: "",
      hours: "",
      type: "", crew: ""
    }
  }
  resetTheForm() {
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.rosterassignmentModel.startDate = new Date();    
    let endDate = new Date();
    var edate = new Date();
    endDate.setDate(edate.getDate() + 7);
    this.rosterassignmentModel.endDate = endDate;
    this.rosterassignmentModel.activityCode = 0;
    this.rosterassignmentModel.position = 0;
    this.activityCodeName = "";
    this.personelName = "";
    this.loadPersonnel();
    this.loadData();
    this.activityCodes = [];
    this.employees = [];
  }
  printReport() {
    this.revisedAssignments = [];
    let actCodes = this.activityCodes.join();
    let personals = this.employees.join();
    this.dailyRoaster.getShaunsTimeSheetView("getShaunsTimesheetView", this.rosterassignmentModel.startDate,
      this.rosterassignmentModel.endDate,
      this.activityCodes.length ? actCodes : "",
      this.employees.length ? personals : "").subscribe(res => {
        this.dailyRoasterObj = res;

        const activityIndex = this.activityCodeKey.findIndex(x => x.key == this.rosterassignmentModel.activityCode);
        if (activityIndex >= 0) {
          this.activityCodeName = this.activityCodeKey[activityIndex].value;
        }
        else {
          this.activityCodeName = "";
        }
        const positionIndex = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.position);
        if (positionIndex >= 0) {
          this.personelName = this.employeeModelObj[positionIndex].lastName + ", " + this.employeeModelObj[positionIndex].miName + " "
            + this.employeeModelObj[positionIndex].firstName;
        }
        else {
          this.personelName = "";
        }


        this.getFormattedTable(this.dailyRoasterObj,"pdf");        

      });
  }
  printCSVReport() {
    this.revisedAssignments = [];
    let actCodes = this.activityCodes.join();
    let personals = this.employees.join();
    this.dailyRoaster.getShaunsTimeSheetView("getShaunsTimesheetView", this.rosterassignmentModel.startDate,
      this.rosterassignmentModel.endDate,
      this.activityCodes.length ? actCodes : "",
      this.employees.length ? personals : "").subscribe(res => {
        this.dailyRoasterObj = res;

        const activityIndex = this.activityCodeKey.findIndex(x => x.key == this.rosterassignmentModel.activityCode);
        if (activityIndex >= 0) {
          this.activityCodeName = this.activityCodeKey[activityIndex].value;
        }
        else {
          this.activityCodeName = "";
        }
        const positionIndex = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.position);
        if (positionIndex >= 0) {
          this.personelName = this.employeeModelObj[positionIndex].lastName + ", " + this.employeeModelObj[positionIndex].miName + " "
            + this.employeeModelObj[positionIndex].firstName;
        }
        else {
          this.personelName = "";
        }


        this.getFormattedTable(this.dailyRoasterObj, "csv");

      });
  }
  printPDFReport() {
   // this.loadData();
    let activityCodeNames = "";
    let personalNames = "";
    for (let x = 0; x < this.activityCodes.length; x++) {
      let itemIndex = this.activityCode.findIndex(z => z.systemCodeId == this.activityCodes[x]);
      if (itemIndex >= 0) {
        activityCodeNames = activityCodeNames + this.activityCode[itemIndex].type + ", ";
      }
    }
    for (let x = 0; x < this.employees.length ; x++) {
      let itemIndex = this.employeeModelObj.findIndex(z => z.employeeId == this.employees[x]);
      if (itemIndex >= 0) {
        personalNames = personalNames + this.employeeModelObj[itemIndex].lastName + " " + this.employeeModelObj[itemIndex].firstName + ", ";
      }
    }
    let title = "Shauns Timesheet Report";
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 32;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setProperties({
      title: 'Shaun_Timesheet_Report'
    });
    var totalPagesExp = '{total_pages_count_string}';
    doc.setFont('Calibri');
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 20)
    doc.setFontSize(10)

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    // if (this.rosterassignmentModel.startDate != null) {
   // if (count == 1) {
     // yAxis = yAxis + 10;
      count = 0;
  //  }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize('Start Date: ' + this.getDateAsString(this.rosterassignmentModel.startDate), pageWidth - 35)
    //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    doc.text(text, xAxis + 40, yAxis)
  //  count++;
    // }

   // if (count == 1) {
      xAxis = xAxis + 160;
      count = 0;
  //  }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize('End Date: ' + this.getDateAsString(this.rosterassignmentModel.endDate), pageWidth - 35)
    //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    doc.text(text, xAxis+20, yAxis)
    count++;



    // if (this.rosterassignmentModel.activityCode != null) {
   // if (count == 1) {
      xAxis = 10;
      count = 0;
   // }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize('Activity Code: ' + activityCodeNames.substring(0, activityCodeNames.length - 1), pageWidth - 35)
    //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    doc.text(text, xAxis+5 , yAxis+10)
    count++;
    //  }

    // if (this.rosterassignmentModel.position != null) {
   // if (count == 1) {
      xAxis = 10;
      count = 0;
    //}
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize('Personnel: ' + personalNames.substring(0, personalNames.length - 1), pageWidth - 35)
    //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    doc.text(text, xAxis+5, yAxis+20)
   // count++;
    //}


    if (!this.checkPrintReportFilters()) {
      startYVariable = 40;
    } else {
      startYVariable = yAxis + 30;
    }
    var currentDate = new Date();
    var currentDateString = this.getDateAsString(currentDate);

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        crew: {
          cellWidth: 15
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10  },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 230, pageHeight - 10);


        doc.text(currentDateString, 35, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    //doc.output('dataurlnewwindow');
    window.open(URL.createObjectURL(doc.output("blob")));
  }
  csvReport() {
    //this.loadData();
    let rowCount = this.revisedAssignments.length;
    var data = []
    data.push({
      personnel: 'Personnel', crew: 'Crew', activityCode: 'Activity Code', hours: 'Hours',
      startdate: 'Start Date/Time', station: 'Station', unit: 'Unit'
    });

    for (var j = 0; j < rowCount; j++) {

      if (this.revisedAssignments[j].type == 'data') {
        data.push({
          personnel: '',
          crew: '',
          activityCode: '                 Total Hours - ',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) : 'N/A',
          startdate: '',
          station: '',
          unit: ''
        });
      }
      if (this.revisedAssignments[j].type == 'row') {
        data.push({
          personnel: this.revisedAssignments[j].personal,
          crew: (this.revisedAssignments[j].crew) ? this.revisedAssignments[j].crew : '',
          activityCode: (this.revisedAssignments[j].activity) ? this.revisedAssignments[j].activity : '',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) + " hours" : '',
          startdate: this.revisedAssignments[j].startDate + " to " + this.revisedAssignments[j].endDate,
          station: (this.revisedAssignments[j].station) ? this.revisedAssignments[j].station : '',
          unit: (this.revisedAssignments[j].unit) ? this.revisedAssignments[j].unit : ''
        });
      }
      if (this.revisedAssignments[j].type == 'header') {
        data.push({
          personnel: '',
          crew: '',
          activityCode: '',
          hours: this.revisedAssignments[j].personal,
          startdate: '',
          station: '',
          unit: ''
        });
      }
      
    }
    new AngularCsv(data, 'shauntimesheet');
  }
  getHeadRows() {
    return [
      {
        personnel: 'Personnel', crew: 'Crew', activityCode: 'Activity Code', hours: 'Hours',
        startdate: 'Start Date/Time', station: 'Station', unit: 'Unit'
      }
    ]
  }

  getBody() {
    let rowCount = this.revisedAssignments.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {

      if (this.revisedAssignments[j].type == 'data') {
        body.push({
          personnel: '',
          crew: '',
          activityCode: '                 Total Hours - ',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) : 'N/A',
          startdate: '',
          station: '',
          unit: ''
        });
      }
      if (this.revisedAssignments[j].type == 'row') {
        body.push({
          personnel: this.revisedAssignments[j].personal,
          crew: (this.revisedAssignments[j].crew) ? this.revisedAssignments[j].crew : '',
          activityCode: (this.revisedAssignments[j].activity) ? this.revisedAssignments[j].activity : '',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) + " hours" : '',
          startdate: this.revisedAssignments[j].startDate + " to " + this.revisedAssignments[j].endDate,
          station: (this.revisedAssignments[j].station) ? this.revisedAssignments[j].station : '',
          unit: (this.revisedAssignments[j].unit) ? this.revisedAssignments[j].unit : ''
        });
      }
      if (this.revisedAssignments[j].type == 'header') {
        body.push({
          personnel: '',
          crew: '',
          activityCode: '',
          hours: this.revisedAssignments[j].personal,
          startdate: '',
          station: '',
          unit: ''
        });
      }

    }
    return body;
  }
  checkPrintReportFilters() {
    //if (this.rosterassignmentModel.startDate == null) {
    //  return true;
    //}
    return true;
  }
 
}
