import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';


@Component({
  selector: 'deletecertification',
  templateUrl: './deletecertification.component.html',

})
export class DeleteCertificationComponent {
  

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DeleteCertificationComponent>) { } // Closing dialog window
 
  public cancel(): void { // To cancel the dialog window
    this.dialogRef.close();
  }

  public cancelN(): void {
    this.dialog.closeAll();
  }

}
