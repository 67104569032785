import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { SystemCodeService } from '../services/system-code.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { Observable, forkJoin } from 'rxjs';
import { WaterUsage } from '../models/waterusageform.model';
import { WaterUsageService } from '../services/waterusage.service';
import { debug } from 'util';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'waterusageform',
  templateUrl: './createwaterusageform.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class WaterUsageForm {
  @ViewChild('waterUsageForm', { static: false }) waterUsageForm: NgForm;
  waterUsageModel: WaterUsage;
  districts: SystemCode[] = [];
  managementTypes: SystemCode[] = [];
  waterUsageId: number = 0;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  isNew: string;
  todayDate: Date = new Date();
  disableSave: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;
  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date(2100, 11, 31);
  @BlockUI() blockUI: NgBlockUI;


  constructor(private systemCodeStateService: SystemCodeService, private dialog: MatDialog,
    private route: ActivatedRoute,
    private WaterUsageService: WaterUsageService,
    private routerService: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      //this.route.params.subscribe(params => {
      //  let Id = params['id']
      //  if (Id != null && Id != undefined) {
      //    this.waterUsageId = parseInt(Id);
      //  }
      //});
      route.params.subscribe(val => {
        if (val.id == undefined) {
          this.waterUsageId = 0;
          this.resetTheForm();
        }
        else {
          this.waterUsageId = parseInt(val.id);
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.blockUI.start();
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.waterUsageId = parseInt(Id);
        }
        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
        this.blockUI.stop();
      });
      this.loadDropDowns();
      if (this.waterUsageId > 0) {
        this.WaterUsageService.getWaterUsage('GetWaterUsage', this.waterUsageId).subscribe(res => {
          this.waterUsageModel = res;
          var lyear = this.todayDate.getFullYear();
          var lmonth = this.todayDate.getMonth();
          var lday = this.todayDate.getDate();
          this.todayDate = new Date(lyear - 50, lmonth, lday);

        });
      }
      else {
        var lyear = this.todayDate.getFullYear();
        var lmonth = this.todayDate.getMonth();
        var lday = this.todayDate.getDate();
        this.todayDate = new Date(lyear, lmonth, lday);
      }
      this.waterUsageModel = this.initializeWaterUsageModel();
    }
  }

  initializeWaterUsageModel(): WaterUsage {
    return {
      waterUsageId: 0,
      waterUsageDate: null,
      district: null,
      estimatedWaterUsage: null,
      startDate: null,
      endDate: null,
      districtName: null,
      waterUsageDateStr: null
    } as WaterUsage;
  }

  public loadDropDowns() {
    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.districts = res || [];
      }, (error: any) => { }
    );
  }

  resetTheForm() {
    this.waterUsageForm.resetForm();
    this.waterUsageModel = this.initializeWaterUsageModel();
    this.disableSave = false;
    return false;
  }

  public saveWaterUsage() {
    this.blockUI.start();
    this.disableSave = true;
    if (this.waterUsageModel.waterUsageDate != null) {
      this.waterUsageModel.waterUsageDateStr = this.getDateAsString(new Date(this.waterUsageModel.waterUsageDate));
    }
    else {
      this.waterUsageModel.waterUsageDateStr = "N/A";
    }
      if (this.waterUsageId > 0) {
      this.WaterUsageService.updateWaterUsage(this.waterUsageId, this.waterUsageModel).subscribe(res => {
        this.blockUI.stop();
        this.resetTheForm();
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.resetTheForm();
            //this.waterUsageId = 0;
            //this.routerService.navigate(['/waterusageform']);
            this.GoBack();
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
          });
        }
      });
    } else {
      this.WaterUsageService.saveWaterUsage('saveWaterUsage', this.waterUsageModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
           // this.routerService.navigate(['/waterusagereport']);
            //  this.GoBack();
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
          });
        }
      });
    }
    this.blockUI.stop();
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  GoBack() {
    this.routerService.navigate(['/waterusagereport']);
  }
}


