import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { EventDpt } from '../models/eventdpt.model';
import { GetAllEventDpt } from '../models/get-all-eventDpt.model';
import { EventCalendarDisplay } from '../models/eventcalendardisplay.model';
import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class EventDptService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/EventDpt/', httpClient, errorService, authService);
  }

  getEvent(action: string, eventId: number): Observable<any> {

    return this.customGetRequest<EventDpt>(action, eventId.toString());
  }
  

  updateEventDpt(eventId: number, eventModel: EventDpt): Observable<any> {

    return this.putRequest(eventId, JSON.stringify(eventModel));
  }

  saveEventDpt(action: string, eventObj: EventDpt): Observable<any> {
    return this.postRequest(action, JSON.stringify(eventObj));
  }

  getAllEvent(): Observable<Array<EventDpt>> {
    return this.customGetRequest<Array<EventDpt>>('getAllEvent', null);
  } 

  delEvent(action: string, eventId: number): Observable<any> {
    return this.deleteRequest(eventId);
   // return this.postRequest(action, JSON.stringify(eventObj));
  }

  eventSearchByDD(action: string, key: any) {
    return this.customGetRequest<EventDpt>(action, key.toString());
  }
  eventSearchByDate(action: string, date: any) {
    return this.customGetRequest<EventDpt>(action, date.toString());
  }


  eventSearch(action: string, eventObject: any): Observable<Array<EventDpt>> {
    var fromDate = new Date(eventObject.fromDate);
    var toDate = new Date(eventObject.toDate);
    const params = new HttpParams()
      .set('fromDate', fromDate.toDateString())
      .set('toDate', toDate.toDateString())
      .set('eventType', eventObject.eventType);
    return this.customGetRequestWithQueryString<Array<EventDpt>>(action, params);
  }

  

  getAllEventDptNew(action: string, fromDate: Date = null, toDate: Date = null, eventType: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllEventDpt> {
    debugger; 
    let params = new HttpParams()        
      .set('eventType', eventType.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);
    if (fromDate != null) {
      params = params.set('fromDate', fromDate.toDateString());
    }
    if (toDate != null) {
      params = params.set('toDate', toDate.toDateString());
    }
    return this.customGetRequestWithQueryString<GetAllEventDpt>(action, params);
  }
 

}
