import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { AwardNominationService } from '../services/awardnomination.service';
import { AwardNomination } from '../models/awardnomination.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { switchMap, map, startWith, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GetAllAwardNomination } from '../models/get-all-awardNomination.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import autoTable, { UserOptions } from 'jspdf-autotable'

@Component({
  selector: 'awardnominationreport',
  templateUrl: './awardnominationreport.component.html',
  styleUrls: ['./awardnominationreport.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class AwardNominationReportComponent implements OnInit {
  @ViewChild('awardnominationreportForm', { static: false }) awardnominationreportForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @BlockUI() blockUI: NgBlockUI;
  awardNominationModel: AwardNomination;
  awardNominationResultModel: AwardNomination[] = [];
  dataSource: MatTableDataSource<AwardNomination>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  resultsLength: number = 0;
  fromDate: any;
  toDate: any;
  aDate = new Date();
  minDate = new Date(2010, 0, 1);
  maxDate = new Date(2025, 11, 31);
  floatLabel: string = 'never';
  errMsg: string;
  showNewButton: boolean = false;
  endDateDisable: boolean = false;
  displayedColumns: any[] = ['startDate', 'submittedByName', 'awardRecommendationName', 'incidentTypeName', 'actionDate', 'statusName'];
  rolePermissionCheckModel: RolePermissionCheck;
  awardNominations: Array<AwardNomination> = [];

  constructor(
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private awardNominationService: AwardNominationService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.awardNominationModel = this.initializeAwardNominationModel();
      //this.awardNominationModel.startDate= new Date();
      //let endDate = new Date();
      //var edate = new Date();
      //endDate.setDate(edate.getDate() + 30);
      //this.awardNominationModel.endDate = endDate;
      this.dataSource = new MatTableDataSource<AwardNomination>(this.awardNominationResultModel);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.getAllAwardNomination(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            return of([]);
          })
      ).subscribe(data => {
          this.awardNominationResultModel = data || [];          
          //for (var i = 0; i < this.awardNominationResultModel.length; i++) {
          //  this.awardNominationResultModel[i].startDate = this.awardNominationResultModel[i].actionDate;
          //}
          this.dataSource = new MatTableDataSource(this.awardNominationResultModel);
        this.dataSource.sort = this.sort;
        });
    }
  }

  startdateevent(startDate: Date) {
    this.fromDate = startDate;
    this.endDateDisable = true;
    if (startDate == null) {
      this.awardNominationModel.endDate = null;
      this.endDateDisable = false;
    }
  }

  getAllAwardNomination(pageIndex: number, pageSize: number): Observable<GetAllAwardNomination> {
    return this.awardNominationService.getAllAwardNominationNew(
      'GetAwardNominationNew',
      (this.awardNominationModel.awardRecommendation) ? this.awardNominationModel.awardRecommendation : 0,
      (this.awardNominationModel.incidentType) ? this.awardNominationModel.incidentType : 0,
      (this.awardNominationModel.actionDate) ? this.awardNominationModel.actionDate : null,
      (this.awardNominationModel.status) ? this.awardNominationModel.status : 0,
      (this.awardNominationModel.startDate) ? this.awardNominationModel.startDate : null,
      (this.awardNominationModel.endDate) ? this.awardNominationModel.endDate : null,
      pageIndex,
      pageSize
    );
  }

  getAllAwardNominationForDataSource(): void {
    this.getAllAwardNomination(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          return of([]);
        })
      ).subscribe(data => {
        this.awardNominationResultModel = data || [];
        this.dataSource = new MatTableDataSource(this.awardNominationResultModel);
        this.dataSource.sort = this.sort;
        //this.dataSource.paginator = this.paginator;
      });
  }

  /*********************************** */
  initializeAwardNominationModel(): AwardNomination {
    return {
      awardNominationId: 0, awardRecommendation: null, incidentType: null, actionDate: null, runNumber: null,
      narrative: null, submittedBy: null, status: null, nominees: null, witness: null, nomineeName: "", witnessName: "",
      awardRecommendationName: "", incidentTypeName: "", submittedByName: "", statusName: "", startDate: null, endDate: null
    } as AwardNomination;
  }

  searchawardnomination() {
    this.blockUI.start();
    this.showNewButton = true;
    this.getAllAwardNominationForDataSource();
  }

  deleteAwardNomination(aId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.awardNominationService.deleteAwardNomination('DeleteAwardNomination', parseInt(aId)).subscribe(res => {
          this.errMsg = res.msg;
          this.getAllAwardNominationForDataSource();
          if (this.errMsg == "Deleted Successfully.") {
            const dialogRef = this.dialog.open(SaveDialogComponent, {
              width: '500px',
              height: '200px',
              data: { name: "Deleted Successfully!" }
            });
          }
          //this.getAllppeInspectionForDataSource();
        });
      }
    });
    return false;
  }

  addAwardNomination() {
    //this.blockUI.start();
    this.routerService.navigate(['/awardnomination']);
    return false;
  }

  resetform() {
    this.awardnominationreportForm.resetForm();
    this.awardNominationModel = this.initializeAwardNominationModel();
    this.awardNominations = [];
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.getAllAwardNominationForDataSource();
    this.showNewButton = false;
    this.endDateDisable = false;
    this.fromDate = new Date(2010, 0, 1);
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  showAwardNominationReport(): void {
    this.blockUI.start();
    this.awardNominationService.getAllAwardNominationsForReportView(
      'GetAllAwardNominationsForReportView',
      (this.awardNominationModel.startDate) ? this.awardNominationModel.startDate : null,
      (this.awardNominationModel.endDate) ? this.awardNominationModel.endDate : null
    ).subscribe((res: Array<AwardNomination>) => {
      this.awardNominations = res;
      this.blockUI.stop();
      this.setPDF();
    }, (error: any) => {
        this.awardNominations = [];
      this.blockUI.stop();
    });
  }

  setPDF(): void {
    let yAxis: number = 30;
    var doc = new jsPDF('p', 'mm', 'letter');
    doc.setFont('Calibri');
    doc.setProperties({
      title: 'Award_Nomination_Report'
    });

    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth('Award Nomination Report') * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text('Award Nomination Report', textOffset, 20)
    doc.setFontSize(10)

    //Start Date and End Date Filter Code Begin
    let startDate = 'Start Date: ';
    if (this.awardNominationModel.startDate) {
      let startDateString = new Date(this.awardNominationModel.startDate);
      let startDateFormat = this.getDateAsString(startDateString);
      startDate = startDate + startDateFormat;
    }
    let endDate = 'End Date: ';
    if (this.awardNominationModel.endDate) {
      let endDateString = new Date(this.awardNominationModel.endDate);
      let endDateFormat = this.getDateAsString(endDateString);
      endDate = endDate + endDateFormat;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Start Date and End Date Filter Code End

    autoTable(doc, {
      rowPageBreak: 'avoid',
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 185, pageHeight - 10);

        let currentDate = new Date();
        let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
        let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
        let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
        doc.text(currentDateString, 15, pageHeight - 10);
      },
      startY: yAxis
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    window.open(URL.createObjectURL(doc.output("blob")));
  }

  getDateAsString(dt: Date): string {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  getHeadRows() {
    return [
      {
        submissionDate: 'Submission Date', nominationSubmittedBy: 'Nomination Submitted By', awardRecommendation: 'Award Recommendation',
        actionOrIncidentType: 'Action/Incident Type', status: 'Status', actionOrIncidentDate: 'Action/Incident Date'
      }
    ]
  }

  getBody() {
    let rowCount = this.awardNominations.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.awardNominations[j].actionDate;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }

      let submissiondate = this.awardNominations[j].startDate;
      let submissionDateStringFormat = '';
      if (submissiondate) {
        let newSubmissionDate = new Date(submissiondate);
        submissionDateStringFormat = this.getDateAsString(newSubmissionDate);
      }

      body.push({
        submissionDate: submissionDateStringFormat,
        nominationSubmittedBy: this.awardNominations[j].submittedByName,
        awardRecommendation: (this.awardNominations[j].awardRecommendationName) ? this.awardNominations[j].awardRecommendationName : 'N/A',
        actionOrIncidentType: (this.awardNominations[j].incidentTypeName) ? this.awardNominations[j].incidentTypeName : 'N/A',
        status: (this.awardNominations[j].statusName) ? this.awardNominations[j].statusName : 'N/A',
        actionOrIncidentDate: actionDateStringFormat        
      });
    }
    return body;
  }
}
