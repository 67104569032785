import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { vacationpics } from '../models/vacationpics.model';
import { vacationpicsround } from '../models/vacationpicsround.model';
import { vacationpicsroundexcludedate } from '../models/vacationpicsroundexcludedate.model';
import { vacationpicsroundincludedate } from '../models/vacationpicsroundincludedate.model';
import { VacationPicsService } from '../services/vacationpics.service';
import { debug } from 'util';
import { RolePermissionCheck } from '../models/role-permission-check.model';

export interface monthmodel {
  january1: boolean;
  february1: boolean;
  march1: boolean;
  april1: boolean;
  may1: boolean;
  june1: boolean;
  july1: boolean;
  august1: boolean;
  september1: boolean;
  october1: boolean;
  november1: boolean;
  december1: boolean;
}

@Component({
  selector: 'vacationpics',
  templateUrl: './vacationpics.component.html'

})
export class VacationPicsComponent {

  @ViewChild('vacationpicsForm', { static: false }) vacationpicsForm: NgForm;

  monthModel: monthmodel;
  monthModelList: monthmodel[] = [];
  names: SystemCode[] = [];
  crews: SystemCode[] = [];
  periodicaldropdown: SystemCode[] = [];
  yearlymonthlyCode: SystemCode[] = [];
  yearlymonthlydaysCode: SystemCode[] = [];
  vacationpicsModel: vacationpics;
  vacationPicsId: number;

  vacationpicsroundModel: vacationpicsround;
  vacationpicsroundList: vacationpicsround[] = [];
  vacationpicsroundexcludedate: vacationpicsroundexcludedate[] = [];
  vacationpicsroundincludedate: vacationpicsroundincludedate[] = [];

  Repeat: boolean = false;
  periodicalcheck: string;
  showRepeat: boolean = false;
  showWeekly: boolean = false;
  showMonthly: boolean = false;
  minDate = new Date(2019, 0, 1);
  maxDate = new Date(2022, 11, 31);
  monthdays: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  //january1: boolean = false;
  //february1: boolean = false;
  //march1: boolean = false;
  //april1: boolean = false;
  //may1: boolean = false;
  //june1: boolean = false;
  //july1: boolean = false;
  //august1: boolean = false;
  //september1: boolean = false;
  //october1: boolean = false;
  //november1: boolean = false;
  //december1: boolean = false;
  monthDay: boolean = false;
  dayOn: boolean = false;
  showExcludeDate: boolean = false;
  showIncludeDate: boolean = false;
  totalActiveItems: number = 0;
  //dateerror: string;
  //date1error: string;
  OccurenceDisable: boolean = false;
  AfterDateDisable: boolean = false;
  ByDateCheckDisable: boolean = false;
  ByDayCheckDisable: boolean = false;
  DayOnEventVar: number;
  DateOnEventVar: number;
  errorhint11: string = "";
  
  roundname: string[] = ['1st Round', '2nd Round', '3rd Round'];
  roundnum: number[] = [1, 2, 3];
  showWeeklyTrue: boolean[] = [true, true, true];
  showWeeklyFalse: boolean[] = [false, false, false];
  weekEnable: boolean[] = [];
  showMonthlyTrue: boolean[] = [true, true, true];
  showMonthlyFalse: boolean[] = [false, false, false];
  monthEnable: boolean[] = [];
  repeatTrue: boolean[] = [true, true, true];
  repeatFalse: boolean[] = [false, false, false];
  repeatEnable: boolean[] = [];
  periodicalcheckIndex: string[] = [];
  OccurenceTrue: boolean[] = [true, true, true];
  OccurenceFalse: boolean[] = [false, false, false];
  OccurenceEnable: boolean[] = [];
  AfterDateTrue: boolean[] = [true, true, true];
  AfterDateFalse: boolean[] = [false, false, false];
  AfterDateEnable: boolean[] = [];

  ByDateCheckTrue: boolean[] = [true, true, true];
  ByDateCheckFalse: boolean[] = [false, false, false];
  ByDateCheckEnable: boolean[] = [];
  ByDayCheckTrue: boolean[] = [true, true, true];
  ByDayCheckFalse: boolean[] = [false, false, false];
  ByDayCheckEnable: boolean[] = [];

  showRepeatTrue: boolean[] = [true, true, true];
  showRepeatFalse: boolean[] = [false, false, false];
  showRepeatEnable: boolean[] = [false, false, false];
  showExcludeDateTrue: boolean[] = [true, true, true];
  showExcludeDateFalse: boolean[] = [false, false, false];
  showExcludeDateEnable: boolean[] = [false, false, false];  
  showIncludeDateTrue: boolean[] = [true, true, true];
  showIncludeDateFalse: boolean[] = [false, false, false];
  showIncludeDateEnable: boolean[] = [false, false, false];

  monthDayTrue: boolean[] = [true, true, true];
  monthDayFalse: boolean[] = [false, false, false];
  monthDayEnable: boolean[] = [false, false, false];
  
  dayOnTrue: boolean[] = [true, true, true];
  dayOnFalse: boolean[] = [false, false, false];
  dayOnEnable: boolean[] = [false, false, false];
  dateerror: string[] = [];
  date1error: string[] = [];

  floatLabel: string = 'never';
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  count: number = 0;
  clickcount: number = 0;
  click: boolean = false
  showMonthlyIndex: boolean[];
  rolePermissionCheckModel: RolePermissionCheck;


  constructor(private dialog: MatDialog,
    private routerService: Router,
    private route: ActivatedRoute,
    private systemCodeStateService: SystemCodeService,
    private vacationPicsService: VacationPicsService)
  {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    debugger;
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      this.vacationpicsModel = this.initializeVacationPicsModel();

      this.vacationpicsroundModel = this.initializeVacationPicsRound();
      var vacationpicsrounds = this.initializeVacationPicsRound();
      vacationpicsrounds.excludeDates.push(this.initializedate1());
      vacationpicsrounds.includeDates.push(this.initializedate2());
      this.vacationpicsroundList.push(vacationpicsrounds);
      console.log('round1', this.vacationpicsroundList);


      var date1 = this.initializedate1();
      this.vacationpicsroundexcludedate.push(date1);

      var date2 = this.initializedate2();
      this.vacationpicsroundincludedate.push(date2);

      this.monthModel = this.initializeMonthModel();
      var monthmodel = this.initializeMonthModel();
      this.monthModelList.push(monthmodel);
    }    

  }

  initializeVacationPicsModel(): vacationpics {
    return {
      vacationPicsId: 0, name: null, crew: null, vacationPics1stRounds: [], vacationPicsRoundexcludedate: [], vacationPicsRoundincludedate: [],
      instructorName: "", crewName: ""
    } as vacationpics;

  }

  initializeVacationPicsRound(): vacationpicsround {
    return {
      vacationPicsRoundId: 0, roundNumber: 1, date: null, repeatCheck: false, periodical: null, repeatCount: null,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      occurences: null, repeatDate: null, excludeCheck: false, includeCheck: false, byMonthDay: 0, byDayOn: 0, byDay: 0,
      january: false, february: false, march: false, april: false, may: false, june: false, july: false, august: false, september: false, october: false,
      november: false, december: false, vacationPicsId: 0, isActive: true, excludeDates: [], includeDates: [], vacPicRoundId: 0
    } as vacationpicsround;
  }

  initializedate1(): vacationpicsroundexcludedate {
    return {
      vacationPicsRoundExcludeDateId: 0, vacationPicsRoundId: 0, excludeDate: null, isActive: true, vacPicRoundId: 0
    } as vacationpicsroundexcludedate;
  }

  initializedate2(): vacationpicsroundincludedate {
    return {
      vacationPicsRoundIncludeDateId: 0, vacationPicsRoundId: 0, includeDate: null, isActive: true, vacPicRoundId: 0
    } as vacationpicsroundincludedate;
  }

  initializeMonthModel(): monthmodel {
    return {
      january1: false, february1: false, march1: false, april1: false, may1: false, june1: false, july1: false, august1: false, september1: false, october1: false,
      november1: false, december1: false
    } as monthmodel;

  }

  public loadDropDowns() {

    forkJoin(
      this.systemCodeStateService.getAllInstructors(),
      this.systemCodeStateService.getSystemCodesForCrews(),
      this.systemCodeStateService.getSystemCodesForRepeats(),
      this.systemCodeStateService.getSystemCodesForYearlyMonthly(),
      this.systemCodeStateService.getSystemCodesForIYearlyMonthlyDays()
    ).subscribe(([res1, res2, res3, res4, res5]) => {
      this.names = res1 || [];
      this.crews = res2 || [];
      this.periodicaldropdown = res3 || [];
      this.yearlymonthlyCode = res4 || [];
      this.yearlymonthlydaysCode = res5 || [];
      console.log(this.names);
      console.log(this.crews);
      console.log(this.periodicaldropdown);
    });

  }

  //show1(index: number) {
  //  debugger;
  //  if (this.vacationpicsroundList[index].repeatcheck == true) {
  //    this.vacationpicsroundList[index].repeatcheck = false;
  //  } else {
  //    this.vacationpicsroundList[index].repeatcheck = true;
  //  }  

  //}

  showcheckbox(i: number) {
    debugger;
     
    if (this.showRepeatEnable[i] == this.showRepeatTrue[i]) {
      this.showRepeatEnable[i] = this.showRepeatFalse[i];
    }
    else {
      this.showRepeatEnable[i] = this.showRepeatTrue[i];
    }   
    console.log('vacationpicsround1', this.vacationpicsroundList[0]);
    console.log('vacationpicsround2', this.vacationpicsroundList[1]);
  }

  onPeriodicalChange(event: number, i: number) {
    debugger;
    console.log('index i:', i);

    var type = this.periodicaldropdown.find(x => x.systemCodeId === event).type;
    //console.log(type);
    //if (event == 119)
    if (type == 'Weekly'){
      this.periodicalcheck = "Week";
      this.periodicalcheckIndex[i] = "Week";
      //this.showWeekly = true;
      //this.showMonthly = false;
      //this.Repeat = true;
      this.weekEnable[i] = this.showWeeklyTrue[i];
      this.monthEnable[i] = this.showMonthlyFalse[i];
      this.repeatEnable[i] = this.repeatTrue[i];      
      //this.clearModelMonth();
      //this.clearMonth();
      this.clearmodelmonth(i);
      this.clearmonth(i);      
      this.vacationpicsroundList[i].byDayOn = 0;
      this.vacationpicsroundList[i].byDay = 0;
      this.vacationpicsroundList[i].byMonthDay = 0;
      //this.ByDateCheckDisable = false;
      //this.ByDayCheckDisable = false;
      this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
      this.ByDayCheckEnable[i] = this.ByDayCheckFalse[i];      
      
    }
    else if (type == 'Monthly') {
      this.periodicalcheck = "Month";
      this.periodicalcheckIndex[i] = "Month";
      //this.showWeekly = false;
      //this.showMonthly = true;
      //this.Repeat = false;
      this.weekEnable[i] = this.showWeeklyFalse[i];
      this.monthEnable[i] = this.showMonthlyTrue[i];
      this.repeatEnable[i] = this.repeatFalse[i];
      //this.clearWeek();
      this.clearweek(i);
      ////this.clearmodelmonth(i);
      ////this.clearmonth(i);
      //this.monthDay = false;
      //this.dayOn = false;
      ////this.monthDayEnable[i] = this.monthDayFalse[i];
      ////this.dayOnEnable[i] = this.dayOnFalse[i];
      //alert("this.vacationpicsroundList[i].byDayOn" + this.vacationpicsroundList[i].byDayOn);
      ////this.vacationpicsroundList[i].byDayOn = 0;
      ////this.vacationpicsroundList[i].byDay = 0;
      ////this.vacationpicsroundList[i].byMonthDay = 0;
      //this.ByDateCheckDisable = false;
      //this.ByDayCheckDisable = false;
      //this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
      //this.ByDayCheckEnable[i] = this.ByDayCheckFalse[i];     
    }
    else if (type == 'Yearly') {
      this.periodicalcheck = "Year";
      this.periodicalcheckIndex[i] = "Year";
      //this.showWeekly = false;
      //this.showMonthly = true;
      //this.Repeat = true;
      this.weekEnable[i] = this.showWeeklyFalse[i];
      this.monthEnable[i] = this.showMonthlyTrue[i];
      this.repeatEnable[i] = this.repeatTrue[i];       
      //this.clearWeek();
      this.clearweek(i);
      ////this.clearmodelmonth(i);
      ////this.clearmonth(i);
      //this.monthDay = false;
      //this.dayOn = false;
      ////this.monthDayEnable[i] = this.monthDayFalse[i];
      ////this.dayOnEnable[i] = this.dayOnFalse[i];
      ////this.vacationpicsroundList[i].byDayOn = 0;
      ////this.vacationpicsroundList[i].byDay = 0;
      ////this.vacationpicsroundList[i].byMonthDay = 0;
      //this.ByDateCheckDisable = false;
      //this.ByDayCheckDisable = false;
      //this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
      //this.ByDayCheckEnable[i] = this.ByDayCheckFalse[i];  
    }
    else if (type == 'Daily') {
      this.periodicalcheck = "Single Day";
      this.periodicalcheckIndex[i] = "Single Day";
      //this.showWeekly = false;
      //this.showMonthly = false;
      //this.Repeat = false;
      this.weekEnable[i] = this.showWeeklyFalse[i];
      this.monthEnable[i] = this.showMonthlyFalse[i];
      this.repeatEnable[i] = this.repeatFalse[i];
      //this.clearModelMonth();
      //this.clearMonth();
      //this.clearWeek();
      this.clearweek(i);
      this.clearmodelmonth(i);
      this.clearmonth(i);
      //this.monthDay = false;
      //this.dayOn = false;
      this.vacationpicsroundList[i].byDayOn = 0;
      this.vacationpicsroundList[i].byDay = 0;
      this.vacationpicsroundList[i].byMonthDay = 0;
      //this.ByDateCheckDisable = false;
      //this.ByDayCheckDisable = false;
      this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
      this.ByDayCheckEnable[i] = this.ByDayCheckFalse[i];  
    }
  }

  resetDate2(index: number) {
    debugger;
    this.vacationpicsroundexcludedate[index].excludeDate = null;
  }

  resetDate3(index1: number) {
    debugger;
    this.vacationpicsroundincludedate[index1].includeDate = null
  }

  //-----------------------add----excludedate------------------

  public addException1(i: number) {
    debugger;
    if (this.vacationpicsroundList[i].excludeDates.length > 0) {
      let theexcludeDate: vacationpicsroundexcludedate[] = [];
      for (var j = 0; j < this.vacationpicsroundList[i].excludeDates.length; j++) {
        if (this.vacationpicsroundList[i].excludeDates[j].isActive == true) {
          theexcludeDate.push(this.vacationpicsroundList[i].excludeDates[j]);
        }
      }
      if (theexcludeDate[theexcludeDate.length - 1].excludeDate != null) {
        var excludedate = this.initializedate1();
        this.vacationpicsroundList[i].excludeDates.push(excludedate);
      }
      else {
        this.dateerror[i] = "Please fill the above field to add another item !";
      }
    }
    else {
      var excludedate = this.initializedate1();
      this.vacationpicsroundList[i].excludeDates.push(excludedate);
    }
    return false;
  }

  //public addException() {
  //  if (this.vacationpicsroundexcludedate.length > 0) {
  //    let theexcludeDate: vacationpicsroundexcludedate[] = [];
  //    for (var i = 0; i < this.vacationpicsroundexcludedate.length; i++) {
  //      if (this.vacationpicsroundexcludedate[i].isActive == true) {
  //        theexcludeDate.push(this.vacationpicsroundexcludedate[i]);
  //      }
  //    }
  //    if (theexcludeDate[theexcludeDate.length - 1].excludeDate != null) {
  //      var activities = this.initializedate1();
  //      this.vacationpicsroundexcludedate.push(activities);
  //    }
  //    else {
  //      this.dateerror = "Please Fill the above field to add another item !";
  //    }
  //  }
  //  else {
  //    var activities = this.initializedate1();
  //    this.vacationpicsroundexcludedate.push(activities);
  //  }
  //  return false;
  //}

  Excludedateevent(excludeDate: Date, i: number) {
    this.dateerror[i] = "";
  }
  
  //----------------------add-----excludedate------------------

  
  //-----------------------add----includedate------------------

  public addAddition1(i: number) {
    debugger;
    if (this.vacationpicsroundList[i].includeDates.length > 0) {
      let theincludeDate: vacationpicsroundincludedate[] = [];
      for (var j = 0; j < this.vacationpicsroundList[i].includeDates.length; j++) {
        if (this.vacationpicsroundList[i].includeDates[j].isActive == true) {
          theincludeDate.push(this.vacationpicsroundList[i].includeDates[j]);
        }
      }
      if (theincludeDate[theincludeDate.length - 1].includeDate != null) {
        var includeDate = this.initializedate2();
        this.vacationpicsroundList[i].includeDates.push(includeDate);
      }
      else {
        this.date1error[i] = "Please fill the above field to add another item !";
      }
    }
    else {
      var includeDate = this.initializedate2();
      this.vacationpicsroundList[i].includeDates.push(includeDate);
    }
    return false;
  }

  //public addAddition() {
  //  if (this.vacationpicsroundincludedate.length > 0) {
  //    let theincludeDate: vacationpicsroundincludedate[] = [];
  //    for (var i = 0; i < this.vacationpicsroundincludedate.length; i++) {
  //      if (this.vacationpicsroundincludedate[i].isActive == true) {
  //        theincludeDate.push(this.vacationpicsroundincludedate[i]);
  //      }
  //    }
  //    if (theincludeDate[theincludeDate.length - 1].includeDate != null) {
  //      var activities = this.initializedate2();
  //      this.vacationpicsroundincludedate.push(activities);
  //    }
  //    else {
  //      this.date1error = "Please Fill the above field to add another item !";
  //    }
  //  }
  //  else {
  //    var activities = this.initializedate2();
  //    this.vacationpicsroundincludedate.push(activities);
  //  }
  //  return false;
  //}

  Includedateevent(includeDate: Date, i: number) {
    this.date1error[i] = "";
  }

  //----------------------add-----includedate------------------
    
  //-----------------------remove---excludedate--------------------

  public removeException1(i: number, Id: number) {
    debugger;
    const index = Id;
    //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
    this.totalActiveItems = 0;
    for (let j = 0; j < this.vacationpicsroundList[i].excludeDates.length; j++) {
      if (this.vacationpicsroundList[i].excludeDates[j].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.vacationpicsroundList[i].excludeDates[index].excludeDate = null;
    }
    else {
      this.vacationpicsroundList[i].excludeDates[index].isActive = false;
    }
    console.log(index);
    this.dateerror[i] = "";
    return false;
  }

  //public removeException(Id: number) {
  //  debugger;
  //  const index = Id;
  //  //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
  //  this.totalActiveItems = 0;
  //  for (let i = 0; i < this.vacationpicsroundexcludedate.length; i++) {
  //    if (this.vacationpicsroundexcludedate[i].isActive == true) {
  //      this.totalActiveItems = this.totalActiveItems + 1;
  //    }
  //  }
  //  if (this.totalActiveItems == 1) {
  //    this.vacationpicsroundexcludedate[index].excludeDate = null;
  //  }
  //  else {
  //    this.vacationpicsroundexcludedate[index].isActive = false;
  //  }
  //  console.log(index);
  //  this.dateerror = "";
  //  return false;
  //}

  //-----------------------remove---excludedate--------------------
  //-----------------------remove---includedate--------------------

  public removeAddition1(i: number, Id: number) {
    debugger;
    const index = Id;

    this.totalActiveItems = 0;
    for (let j = 0; j < this.vacationpicsroundList[i].includeDates.length; j++) {
      if (this.vacationpicsroundList[i].includeDates[j].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.vacationpicsroundList[i].includeDates[index].includeDate = null;
    }
    else {
      this.vacationpicsroundList[i].includeDates[index].isActive = false;
    }
    console.log(index);
    this.date1error[i] = "";
    return false;
  }
  //public removeAddition(Id: number) {
  //  debugger;
  //  const index = Id;
  //  //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
  //  this.totalActiveItems = 0;
  //  for (let i = 0; i < this.vacationpicsroundincludedate.length; i++) {
  //    if (this.vacationpicsroundincludedate[i].isActive == true) {
  //      this.totalActiveItems = this.totalActiveItems + 1;
  //    }
  //  }
  //  if (this.totalActiveItems == 1) {
  //    this.vacationpicsroundincludedate[index].includeDate = null;
  //  }
  //  else {
  //    this.vacationpicsroundincludedate[index].isActive = false;
  //  }
  //  console.log(index);
  //  this.date1error = "";
  //  return false;
  //}


  //-----------------------remove---includedate--------------------

  //onMonthDayChange(MonthDayevent: number) {
  //  //debugger;
  //  if (MonthDayevent != undefined) {
  //    this.ByDayCheckDisable = true;
  //  } else {
  //    this.ByDayCheckDisable = false;
  //    this.clearModelMonth();
  //  }
  //}

  onMonthDayChange1(MonthDayevent: number, i: number) {
    debugger;
    if (MonthDayevent != undefined) {
      this.ByDayCheckEnable[i] = this.ByDayCheckTrue[i]; 
    } else {
      this.ByDayCheckEnable[i] = this.ByDayCheckFalse[i];
      this.clearmodelmonth(i);
    }
  }

  //onDayOnChange(DayOnEvent: number) {
  //  //debugger;
  //  if (this.vacationPicsId > 0) {
  //    this.DayOnEventVar = this.vacationpicsroundModel.byDayOn;
  //    this.DateOnEventVar = this.vacationpicsroundModel.byDay;
  //  }
  //  this.DayOnEventVar = DayOnEvent;
  //  if (this.DayOnEventVar == undefined || this.DateOnEventVar == undefined) {
  //    this.ByDateCheckDisable = false;
  //    this.clearMonth();
  //  } else {
  //    this.ByDateCheckDisable = true;
  //  }
  //}

  onDayOnChange1(DayOnEvent: number, i: number) {
    debugger;
    if (this.vacationPicsId > 0) {
      this.DayOnEventVar = this.vacationpicsroundList[i].byDayOn;
      this.DateOnEventVar = this.vacationpicsroundList[i].byDay;
    }
    this.DayOnEventVar = DayOnEvent;
    if (this.DayOnEventVar == undefined || this.DateOnEventVar == undefined) {
      this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
      this.clearmonth(i);
    } else {
      this.ByDateCheckEnable[i] = this.ByDateCheckTrue[i];
    }
  }

  //onDayChange(DayEvent: number) {
  //  //debugger;
  //  if (this.vacationPicsId > 0) {
  //    this.DayOnEventVar = this.vacationpicsroundModel.byDayOn;
  //    this.DateOnEventVar = this.vacationpicsroundModel.byDay;
  //  }
  //  this.DateOnEventVar = DayEvent;
  //  if (this.DateOnEventVar != undefined && this.DayOnEventVar != undefined) {
  //    this.ByDateCheckDisable = true;
  //  } else {
  //    this.ByDateCheckDisable = false;
  //    this.clearMonth();
  //  }
  //}

  onDayChange1(DayEvent: number, i: number) {
    debugger;
    if (this.vacationPicsId > 0) {
      this.DayOnEventVar = this.vacationpicsroundList[i].byDayOn;
      this.DateOnEventVar = this.vacationpicsroundList[i].byDay;
    }
    this.DateOnEventVar = DayEvent;
    if (this.DateOnEventVar != undefined && this.DayOnEventVar != undefined) {
      this.ByDateCheckEnable[i] = this.ByDateCheckTrue[i];
    } else {
      this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
      this.clearmonth(i);
    }
  }


  radio1Index(i: number) {    
    this.clearmodelmonth(i);
    //this.monthDay = true;
    //this.dayOn = false;
    this.monthDayEnable[i] = this.monthDayTrue[i];
    this.dayOnEnable[i] = this.dayOnFalse[i];
    this.vacationpicsroundList[i].byDayOn = null;
    this.vacationpicsroundList[i].byDay = null;
    if (this.vacationpicsroundList[i].byDayOn == null || this.vacationpicsroundList[i].byDay == null) {
      //this.ByDateCheckDisable = false;
      this.ByDateCheckEnable[i] = this.ByDateCheckFalse[i];
    }
  }

  radio2Index(i: number) {
    this.clearmonth(i);    
    //this.monthDay = false;
    //this.dayOn = true;
    this.monthDayEnable[i] = this.monthDayFalse[i];
    this.dayOnEnable[i] = this.dayOnTrue[i];
    this.vacationpicsroundList[i].byMonthDay = null;
    if (this.vacationpicsroundList[i].byMonthDay == null) {
      //this.ByDayCheckDisable = false;
      this.ByDayCheckEnable[i] = this.ByDayCheckFalse[i];
    }
  }

  clearweek(i) {
    this.vacationpicsroundList[i].sunday = false;
    this.vacationpicsroundList[i].monday = false;
    this.vacationpicsroundList[i].tuesday = false;
    this.vacationpicsroundList[i].wednesday = false;
    this.vacationpicsroundList[i].thursday = false;
    this.vacationpicsroundList[i].friday = false;
    this.vacationpicsroundList[i].saturday = false;
  }

  clearmonth(i) {//on Day
    //debugger;
    this.vacationpicsroundList[i].january = false;
    this.vacationpicsroundList[i].february = false;
    this.vacationpicsroundList[i].march = false;
    this.vacationpicsroundList[i].april = false;
    this.vacationpicsroundList[i].may = false;
    this.vacationpicsroundList[i].june = false;
    this.vacationpicsroundList[i].july = false;
    this.vacationpicsroundList[i].august = false;
    this.vacationpicsroundList[i].september = false;
    this.vacationpicsroundList[i].october = false;
    this.vacationpicsroundList[i].november = false;
    this.vacationpicsroundList[i].december = false;
  }
  clearmodelmonth(i) { //on The
    //debugger;
    this.monthModelList[i].january1 = false;
    this.monthModelList[i].february1 = false;
    this.monthModelList[i].march1 = false;
    this.monthModelList[i].april1 = false;
    this.monthModelList[i].may1 = false;
    this.monthModelList[i].june1 = false;
    this.monthModelList[i].july1 = false;
    this.monthModelList[i].august1 = false;
    this.monthModelList[i].september1 = false;
    this.monthModelList[i].october1 = false;
    this.monthModelList[i].november1 = false;
    this.monthModelList[i].december1 = false;
  }

  //radioMonthDay(event) {
  //  debugger;

  //  if (event.target.value == 'byMonthDay') {
  //    this.monthDay = true;
  //    this.dayOn = false;
  //    this.vacationpicsroundModel.byDayOn = null;
  //    this.vacationpicsroundModel.byDay = null;
  //    if (this.vacationpicsroundModel.byDayOn == null || this.vacationpicsroundModel.byDay == null) {
  //      this.ByDateCheckDisable = false;
  //    }
  //    this.clearModelMonth();
  //    this.clearMonth();
  //  }
  //  else if (event.target.value == 'byDayOn') {
  //    this.monthDay = false;
  //    this.dayOn = true;
  //    this.vacationpicsroundModel.byMonthDay = null;
  //    if (this.vacationpicsroundModel.byMonthDay == null) {
  //      this.ByDayCheckDisable = false;
  //    }
  //    this.clearModelMonth();
  //    this.clearMonth();
  //  }

  //}

  //RadioByDay() {
  //  //debugger;
  //  this.monthDay = true;
  //  this.dayOn = false;    
  //  this.vacationpicsroundModel.byDayOn = null;
  //  this.vacationpicsroundModel.byDay = null;
  //  if (this.vacationpicsroundModel.byDayOn == null || this.vacationpicsroundModel.byDay == null) {
  //    this.ByDateCheckDisable = false;
  //  }
  //  this.clearModelMonth();
  //  this.clearMonth();
  //}

  //RadioByDate() {
  //  //debugger;
  //  this.dayOn = true;
  //  this.monthDay = false;
  //  this.vacationpicsroundModel.byMonthDay = null;
  //  if (this.vacationpicsroundModel.byMonthDay == null) {
  //    this.ByDayCheckDisable = false;
  //  }
  //  this.clearModelMonth();
  //  this.clearMonth();
  //}

  RadioOccurences(i: number) {
    //this.vacationpicsroundModel.repeatDate = null;
    //this.AfterDateDisable = false;
    //this.OccurenceDisable = true;
    debugger;
    this.vacationpicsroundList[i].repeatDate = null;
    this.AfterDateEnable[i] = this.AfterDateFalse[i];
    this.OccurenceEnable[i] = this.OccurenceTrue[i];
    console.log('1',i, this.AfterDateEnable[i]);
    console.log('2',i, this.OccurenceEnable[i]);

  }

  RadioDate(i: number) {
    //this.vacationpicsroundModel.occurences = null;
    //this.AfterDateDisable = true;
    //this.OccurenceDisable = false;
    debugger;
    this.vacationpicsroundList[i].occurences = null;
    this.AfterDateEnable[i] = this.AfterDateTrue[i];
    this.OccurenceEnable[i] = this.OccurenceFalse[i];
    console.log('3', i, this.AfterDateEnable[i]);
    console.log('4', i, this.OccurenceEnable[i]);
  }

  //show2() {

  //  if (this.showExcludeDate == true) {
  //    this.showExcludeDate = false;
  //  }
  //  else {
  //    this.showExcludeDate = true;
  //  }
  //}

  
  showExcludecheck(i: number) {
    debugger;
    if (this.showExcludeDateEnable[i] == this.showExcludeDateTrue[i]) {
      this.showExcludeDateEnable[i] = this.showExcludeDateFalse[i];
    }
    else {
      this.showExcludeDateEnable[i] = this.showExcludeDateTrue[i];
    }
  }

  showIncludecheck(i: number) {
    debugger;
    if (this.showIncludeDateEnable[i] == this.showIncludeDateTrue[i]) {
      this.showIncludeDateEnable[i] = this.showIncludeDateFalse[i];
    }
    else {
      this.showIncludeDateEnable[i] = this.showIncludeDateTrue[i];
    }

  }


  //show4() {

  //  if (this.showIncludeDate == true) {
  //    this.showIncludeDate = false;
  //  }
  //  else {
  //    this.showIncludeDate = true;
  //  }
  //}

  public saveVacationPics() {

    debugger;

    //this.monthModel = this.monthModelList[];
    for (let k = 0; k < this.vacationpicsroundList.length; k++) {
      if (this.dayOnEnable[k] == true) {
        //if (this.dayOn == true) {
        for (let m = 0; m < this.monthModelList.length; m++) {
          this.vacationpicsroundList[m].january = this.monthModelList[m].january1;
          this.vacationpicsroundList[m].february = this.monthModelList[m].february1;
          this.vacationpicsroundList[m].march = this.monthModelList[m].march1;
          this.vacationpicsroundList[m].april = this.monthModelList[m].april1;
          this.vacationpicsroundList[m].may = this.monthModelList[m].may1;
          this.vacationpicsroundList[m].june = this.monthModelList[m].june1;
          this.vacationpicsroundList[m].july = this.monthModelList[m].july1;
          this.vacationpicsroundList[m].august = this.monthModelList[m].august1;
          this.vacationpicsroundList[m].september = this.monthModelList[m].september1;
          this.vacationpicsroundList[m].october = this.monthModelList[m].october1;
          this.vacationpicsroundList[m].november = this.monthModelList[m].november1;
          this.vacationpicsroundList[m].december = this.monthModelList[m].december1;
        }
      }

      if (this.vacationpicsroundList[k].byMonthDay == null) {
        this.vacationpicsroundList[k].byMonthDay = 0;
      }
      if (this.vacationpicsroundList[k].byDayOn == null) {
        this.vacationpicsroundList[k].byDayOn = 0;
      }
      if (this.vacationpicsroundList[k].byDay == null) {
        this.vacationpicsroundList[k].byDay = 0;
      }
    }

    //var vacationpics1stroundsdata = this.vacationpicsroundModel;
    //this.vacationpicsroundList.push(vacationpics1stroundsdata);
    this.vacationpicsModel.vacationPics1stRounds = this.vacationpicsroundList;

    //this.vacationpicsroundModel.vacationPicsRoundexcludedate = this.vacationpicsroundexcludedate;
    //this.vacationpicsroundModel.vacationPicsRoundincludedate = this.vacationpicsroundincludedate;

    //this.vacationpicsModel.vacationPicsRoundexcludedate = this.vacationpicsroundList.vacationPicsRoundexcludedate;
    //this.vacationpicsModel.vacationPicsRoundincludedate = this.vacationpicsroundList.vacationPicsRoundincludedate;

    if (this.vacationpicsroundModel.byMonthDay == null) {
      this.vacationpicsroundModel.byMonthDay = 0;
    }
    if (this.vacationpicsroundModel.byDayOn == null) {
      this.vacationpicsroundModel.byDayOn = 0;
    }
    if (this.vacationpicsroundModel.byDay == null) {
      this.vacationpicsroundModel.byDay = 0;
    }

    

    this.vacationPicsService.saveVacationPics('SaveVacationPics', this.vacationpicsModel).subscribe(res => {
      this.errMsg = res.msg;
      if (this.errMsg == "Saved Successfully.") {
        this.savedialog = true;
      }
      else {
        this.errordialog = true;
      }

      if (this.savedialog == true) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Saved Successfully!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.resetTheForm();
        });
      } else if (this.errordialog == true) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px'
        });
        //dialogRef.afterClosed().subscribe(result => {
        //  //this.navigateToSearchUsers();
        //});
      }
    });

  }

  addRound() {
    debugger;
  
      if (this.vacationpicsroundList.length > 0) {
        let thevacationpicsround: vacationpicsround[] = [];
        for (var i = 0; i < this.vacationpicsroundList.length; i++) {
          if (this.vacationpicsroundList[i].isActive == true) {
            thevacationpicsround.push(this.vacationpicsroundList[i]);
          }
        }
        if (thevacationpicsround[thevacationpicsround.length - 1].date != null) {
          this.clickcount = this.count++;
          console.log(this.count);
          if (this.count <= 2) {
            var vacationPic = this.initializeVacationPicsRound();
            vacationPic.excludeDates.push(this.initializedate1());
            vacationPic.includeDates.push(this.initializedate2());
            vacationPic.roundNumber = this.roundnum[this.count];
            this.vacationpicsroundList.push(vacationPic);
            console.log('roundList', this.vacationpicsroundList);
            var monthmodel = this.initializeMonthModel();
            this.monthModelList.push(monthmodel);
            //var excludedate1 = this.initializedate1();
            //this.vacationpicsroundexcludedate.push(excludedate1);
            //var includedate1 = this.initializedate2();
            //this.vacationpicsroundincludedate.push(includedate1);	
            //this.vacationpicsroundModel.roundNumber = this.count;
            //console.log('round:',this.vacationpicsroundModel.roundNumber);
          }
          else {
            this.click = true;
          }
          this.errorhint11 = "";
        }
        else {
          this.errorhint11 = "Please fill the date field to add another item !";
        }
      }
      else {
        var vacationPic = this.initializeVacationPicsRound();
        this.vacationpicsroundList.push(vacationPic);
      }

    //}
    //else {
    //  this.click = true;
    //}
    return false;

  }

  clearMonth() {
    //this.january1 = false;
    //this.february1 = false;
    //this.march1 = false;
    //this.april1 = false;
    //this.may1 = false;
    //this.june1 = false;
    //this.july1 = false;
    //this.august1 = false;
    //this.september1 = false;
    //this.october1 = false;
    //this.november1 = false;
    //this.december1 = false;
    this.monthModel = this.initializeMonthModel();
    //this.monthModelList.push(this.monthModel);

  }

  clearModelMonth() {   
    this.vacationpicsroundModel.january = false;
    this.vacationpicsroundModel.february = false;
    this.vacationpicsroundModel.march = false;
    this.vacationpicsroundModel.april = false;
    this.vacationpicsroundModel.may = false;
    this.vacationpicsroundModel.june = false;
    this.vacationpicsroundModel.july = false;
    this.vacationpicsroundModel.august = false;
    this.vacationpicsroundModel.september = false;
    this.vacationpicsroundModel.october = false;
    this.vacationpicsroundModel.november = false;
    this.vacationpicsroundModel.december = false;
  }

  clearWeek() {
    this.vacationpicsroundModel.sunday = false;
    this.vacationpicsroundModel.monday = false;
    this.vacationpicsroundModel.tuesday = false;
    this.vacationpicsroundModel.wednesday = false;
    this.vacationpicsroundModel.thursday = false;
    this.vacationpicsroundModel.friday = false;
    this.vacationpicsroundModel.saturday = false;
  }

  resetTheForm() {
    this.vacationpicsForm.resetForm();
    this.vacationpicsModel = this.initializeVacationPicsModel();
    this.vacationpicsroundModel = this.initializeVacationPicsRound();
    var date1 = this.initializedate1();
    var date2 = this.initializedate2();
    var vacationpicsrounds = this.initializeVacationPicsRound();
    vacationpicsrounds.excludeDates.push(this.initializedate1());
    vacationpicsrounds.includeDates.push(this.initializedate2());
    var monthmodel = this.initializeMonthModel();
    this.monthModel = this.initializeMonthModel();
    this.monthModelList.push(monthmodel);
    this.vacationpicsroundList = [];
    this.vacationpicsroundexcludedate = [];
    this.vacationpicsroundincludedate = [];
    this.vacationpicsroundexcludedate.push(date1);
    this.vacationpicsroundincludedate.push(date2);    
    this.vacationpicsroundList.push(vacationpicsrounds);

    this.weekEnable = [];
    this.monthEnable = [];
    this.repeatEnable = []
    this.periodicalcheckIndex = [];
    this.OccurenceEnable = [];
    this.AfterDateEnable = [];
    this.ByDateCheckEnable = [];
    this.ByDayCheckEnable = [];
    this.showRepeatEnable = [false, false, false];
    this.showExcludeDateEnable = [false, false, false];
    this.showIncludeDateEnable = [false, false, false];

    this.showRepeat = false;
    this.showExcludeDate = false;
    this.showIncludeDate = false;
    this.showWeekly = false;
    this.showMonthly = false;
    this.ByDateCheckDisable = false;
    this.ByDayCheckDisable = false;
    this.periodicalcheck = ''; 
    this.click = false;
    this.count = 0;
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

}
