import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { AwardNomination } from '../models/awardnomination.model';
import { ErrorService } from './error.service';
import { GetAllAwardNomination } from '../models/get-all-awardNomination.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AwardNominationService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/AwardNomination/', httpClient, errorService, authService);
  }

  saveAwardNomination(action: string, awardNominationObj: AwardNomination): Observable<any> {
    return this.postRequest(action, JSON.stringify(awardNominationObj));//
  }

  getAwardNomination(action: string, awardNominationId: Number) {
    const params = new HttpParams().set('awardNominationId', awardNominationId.toString());
    return this.customGetRequestWithQueryString<Array<AwardNomination>>(action, params);
  }

  getAwardNominee(action: string, awardNominationId: number): Observable<any> {
    return this.customGetRequest<AwardNomination>(action, awardNominationId.toString());
  }

  updateAwardNomination(awardNominationId: number, awardNominationModel: AwardNomination): Observable<any> {
    return this.putRequest(awardNominationId, JSON.stringify(awardNominationModel));
  }

  deleteAwardNomination(action: string, awardNominationId: number): Observable<any> {
    return this.deleteRequest(awardNominationId);
  }

  getAllAwardNominationNew(action: string, awardRecommendation: number = null, incidentType: number = 0, actionDate: Date = null, status: number = 0, startDate: Date = null, endDate: Date = null, pageNumber: number = 0, pageSize: number = 0): Observable<GetAllAwardNomination> {
    var actionDate = new Date(actionDate);
    var stDate = new Date();
    if (startDate == null) {
      var lyear = stDate.getFullYear();
      var lmonth = stDate.getMonth();
      var lday = stDate.getDate();
      stDate = new Date(2000, 0, 1);
    } else {
      stDate = new Date(startDate);
    }

    var edDate = new Date();
    if (endDate == null) {
      var cyear = edDate.getFullYear();
      var cmonth = edDate.getMonth();
      var cday = edDate.getDate();
      edDate = new Date(2025, 11, 31);
    } else {
      edDate = new Date(endDate);
    }

    const params = new HttpParams()
      .set('awardRecommendation', awardRecommendation.toString())
      .set('incidentType', incidentType.toString())
      .set('actionDate', actionDate.toDateString())
      .set('status', status.toString())
      .set('startDate', stDate.toDateString())
      .set('endDate', edDate.toDateString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.customGetRequestWithQueryString<GetAllAwardNomination>(action, params);
  }

  getAllAwardNominationsForReportView(action: string, startDate: Date = null, endDate: Date = null): Observable<Array<AwardNomination>> {
    var stDate = new Date();
    if (startDate == null) {
      stDate = new Date(2000, 0, 1);
    } else {
      stDate = new Date(startDate);
    }

    var edDate = new Date();
    if (endDate == null) {
      edDate = new Date(2025, 11, 31);
    } else {
      edDate = new Date(endDate);
    }

    const params = new HttpParams()
      .set('startDate', stDate.toDateString())
      .set('endDate', edDate.toDateString());
    return this.customGetRequestWithQueryString<Array<AwardNomination>>(action, params);
  }

  //getAwardNominations(action: string, awardNominationId: number): Observable<any> {
  //  return this.customGetRequest<AwardNomination>(action, awardNominationId.toString());
  //}
}

