import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { RolePermissionsService } from "./rolepermissions.service";
import { AuthService } from "./auth.service";
import { RolePermissionCheck } from "../models/role-permission-check.model";
import { Injectable } from "@angular/core";

@Injectable()
export class RolePermissionsResolverService implements Resolve<RolePermissionCheck> {
  constructor(
    private rolePermissionService: RolePermissionsService,
    private authService: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RolePermissionCheck> {
    //debugger;
    let formName = route.data['formName'];
    this.authService.setFormPermissionId(formName);
    return this.rolePermissionService.getRolePermissionForForm(this.authService.getUserId(), this.authService.getFormPermissionId());
  }
}
