import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { Occupancy } from '../models/occupancy.model';
import { GetAllPrePlan } from '../models/get-all-prePlan.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PrePlanService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/PrePlan/', httpClient, errorService, authService);
  }
  getDownPrePlanFile(action: string, prePlanId: string, filetype: string) {
    debugger;
    const params = new HttpParams()
      .set('prePlanId', prePlanId)    
    return this.customGetRequestWithQuery(action, params);
  }

  getPrePlanFile(action: string, prePlanId: string, filetype: string) {
    debugger;
    const params = new HttpParams()
      .set('prePlanId', prePlanId)
     // .set('fileType', filetype)
    return this.customGetRequestWithQuery(action, params);
  }
  savePrePlanwithFile(action: string, formdata: FormData): Observable<any> {
    debugger;
    return this.customPostWithFile(action, formdata);
  }
  savePrePlanwithFileNew(action: string, formdata: FormData): Observable<any> {
    return this.uploadFile(action, formdata);
  }
  updatePrePlanwithFile(action: string, formdata: FormData): Observable<any> {
    return this.customPutRequest(action, formdata);
  }

  getPrePlanDocument(action: string, prePlanDocumentId: number) {
    const params = new HttpParams()
      .set('prePlanDocumentId', prePlanDocumentId.toString())

    return this.customGetRequestWithQueryString(action, params);
  }
  getAllUploadsNew(action: string, streetName: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllPrePlan> {
    debugger;
    const params = new HttpParams()
      .set('streetName', streetName.toString())      
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);
    return this.customGetRequestWithQueryString<GetAllPrePlan>(action, params);    
  }
  
  delprePlan(action: string, prePlanId: number): Observable<any> {
    return this.deleteRequest(prePlanId);
    //return this.postRequest(action, JSON.stringify(dailylogobj));
  }
}
