import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventDpt } from '../models/eventdpt.model';
import { SystemCodeService } from '../services/system-code.service';
import { EventDptService } from '../services/eventdpt.service';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { EventCalendarDisplay } from '../models/eventcalendardisplay.model';
import { EventService } from '../services/event.service';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'eventcalendar',
  changeDetection: ChangeDetectionStrategy.OnPush, 
  templateUrl: './eventcalendar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EventCalendarComponent {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  events: CalendarEvent[]=[];
  eventdptModel: EventCalendarDisplay[] = [];
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private eventService: EventService) {

  }

  ngOnInit() {
    this.loadGrid();
    //this.events = [      
    //  {
    //    start: subDays(endOfMonth(new Date()), 3),
    //    end: addDays(endOfMonth(new Date()), 3),
    //    title: 'A long event that spans 2 months',
    //    color: colors.blue,
    //    allDay: true,
    //    actions: this.actions
    //  }
    //];
  }
  loadGrid() {
    this.eventService.getEventsForCalendar().subscribe(
      (res: Array<EventCalendarDisplay>) => {
        this.eventdptModel = res || [];
        for (let i = 0; i < this.eventdptModel.length;i++) {

          let eventObj = {
            id: this.eventdptModel[i].eventId,
            start: new Date( this.eventdptModel[i].startDate),
            end: new Date(this.eventdptModel[i].endDate),
            title: this.eventdptModel[i].title,
            color: colors.blue,
            actions: this.actions
          };
          
          this.events.push(eventObj);
          if (i == this.eventdptModel.length-1) {
            this.refresh.next();
          }
        }


      //  this.eventDptId = this.eventId;
        // this.GridSortAndPage();
      }, (error: any) => {
      });
   
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-pencil" style="font-size:24px"></i>&nbsp;&nbsp;',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fa fa-trash"  style="font-size:24px"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
       // this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  

  activeDayIsOpen: boolean = true;

  //constructor(private modal: NgbModal) { }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action == "Edited") {
      this.routerService.navigate(['/eventscreen', { id: event.id }]);
    }
    else if (action == "Deleted") {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: {
          id: 1
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == 1) {
          this.eventdptModel = [];
          this.events = [];
          let id: any = event.id;
          //this.eventService.deleteEvent(id).subscribe(res => {
          //  this.loadGrid();
          //});
        }
      });   

    }
    else {

    }

    //this.modalData = { event, action };
   // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}

/*
{
  start: subDays(startOfDay(new Date()), 1),
    end: addDays(new Date(), 1),
      title: 'A 3 day event',
        color: colors.red,
          actions: this.actions,
            allDay: true,
              resizable: {
    beforeStart: true,
      afterEnd: true,
        },
  draggable: true,
      }      ,
{
  start: startOfDay(new Date()),
    title: 'An event with no end date',
      color: colors.yellow,
        actions: this.actions,
      },
{
  start: subDays(endOfMonth(new Date()), 3),
    end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
        color: colors.blue,
          allDay: true,
      },
{
  start: addHours(startOfDay(new Date()), 2),
    end: addHours(new Date(), 2),
      title: 'A draggable and resizable event',
        color: colors.yellow,
          actions: this.actions,
            resizable: {
    beforeStart: true,
      afterEnd: true,
        },
  draggable: true,
      }

*/
