import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { PpeInspection } from '../models/ppeinspection.model';
import { ErrorService } from './error.service';
import { GetAllPpeInspection } from '../models/get-all-ppeInspection.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PPEInspectionService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/PPEInspection/', httpClient, errorService, authService);
  }

  savePPEInspection(action: string, ppeInspectionModel: PpeInspection): Observable<any> {
    return this.postRequest(action, JSON.stringify(ppeInspectionModel));
  }


  //updatePPEInspection(action: string, ppeInspectionModel: PpeInspection): Observable<any> {

  //  return this.putRequest(action, JSON.stringify(ppeInspectionModel));    

  //}

  updatePPEInspection(ppeInspectionId: number, ppeInspectionModel: PpeInspection): Observable<any> {
    return this.putRequest(ppeInspectionId, JSON.stringify(ppeInspectionModel));
  }


  getPPEInspection(action: string, ppeInspectionId: Number) {
    const params = new HttpParams()
      .set('ppeInspectionId', ppeInspectionId.toString());

    return this.customGetRequestWithQueryString<Array<PpeInspection>>(action, params);
  }

  getPPEInspections(action: string, ppeInspectionId: number): Observable<any> {

    return this.customGetRequest<PpeInspection>(action, ppeInspectionId.toString());
  }

  deletePpeInspection(action: string, ppeInspectionId: number): Observable<any> {
    return this.deleteRequest(ppeInspectionId);
  }



  searchPPEInspection(action: string, ppeinspectionobj: any): Observable<Array<PpeInspection>> {
    //var dt = new Date(ppeinspectionobj.startDate);
    //let tmpDt: any = dt.getDate();
    //let stDate = JSON.stringify(dt);
    //stDate = stDate.slice(1, 11);

    var startDate = new Date(ppeinspectionobj.startDate);
    var endDate = new Date(ppeinspectionobj.endDate);
    const params = new HttpParams()
      .set('crew', ppeinspectionobj.crew)
      .set('name', ppeinspectionobj.name)
      .set('deficiencies', ppeinspectionobj.deficiencies)
      //.set('startDate', dt.toDateString())
      .set('startDate', startDate.toDateString())
      .set('endDate', endDate.toDateString());

    //.set('startDate', tmpDt )
    //.set('date', date.toDateString())
    //.set('endDate', ppeinspectionobj.endDate.toDateString())
    //return this.customGetRequestWithQueryString<Array<PpeInspection>>(action, null);
    return this.customGetRequestWithQueryString<Array<PpeInspection>>(action, params);
  }

  getAllPPEInspectionForReportView(action: string, crew: number = null, name: number = 0, deficiencies: number = 0, startDate: Date = null, endDate: Date = null): Observable<Array<PpeInspection>> {
    var stDate = new Date();
    if (startDate == null) {
      var lyear = stDate.getFullYear();
      var lmonth = stDate.getMonth();
      var lday = stDate.getDate();
      stDate = new Date(2000, 0, 1);
    }
    else {
      stDate = new Date(startDate);
    }

    var edDate = new Date();
    if (endDate == null) {
      var cyear = edDate.getFullYear();
      var cmonth = edDate.getMonth();
      var cday = edDate.getDate();
      edDate = new Date(2025, 11, 31);
    }
    else {
      edDate = new Date(endDate);
    }

    const params = new HttpParams()
      .set('crew', crew.toString())
      .set('name', name.toString())
      .set('deficiencies', deficiencies.toString())
      .set('startDate', stDate.toDateString())
      .set('endDate', edDate.toDateString());
    return this.customGetRequestWithQueryString<GetAllPpeInspection>(action, params);
  }


  getAllPpeInspectionNew(action: string, crew: number = null, name: number = 0, deficiencies: number = 0, startDate: Date = null, endDate: Date = null, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllPpeInspection> {
    var stDate = new Date();
    if (startDate == null) {
      var lyear = stDate.getFullYear();
      var lmonth = stDate.getMonth();
      var lday = stDate.getDate();
      stDate = new Date(2000, 0, 1);
    }
    else {
      stDate = new Date(startDate);
    }

    var edDate = new Date();
    if (endDate == null) {
      var cyear = edDate.getFullYear();
      var cmonth = edDate.getMonth();
      var cday = edDate.getDate();
      edDate = new Date(2025, 11, 31);
    }
    else {
      edDate = new Date(endDate);
    }

    const params = new HttpParams()
      .set('crew', crew.toString())
      .set('name', name.toString())
      .set('deficiencies', deficiencies.toString())
      .set('startDate', stDate.toDateString())
      .set('endDate', edDate.toDateString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);

    return this.customGetRequestWithQueryString<GetAllPpeInspection>(action, params);
  }

}
