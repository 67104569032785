import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { CompanyTraining } from '../models/companytraining.model';
import { SystemCodeService } from '../services/system-code.service';
import { SystemCode } from '../models/system-codes.model';
import { CompanyTrainingService } from '../services/companytraining.service';
import { MatDialog } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { forkJoin } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { saveAs } from 'file-saver';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'companytraining',
  templateUrl: './companytraining.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class CompanyTrainingComponent {
  @ViewChild('emstrainingForm', { static: false }) emstrainingForm: NgForm;
  @ViewChild('CompanyTrainingfileUploader', { static: false }) ctFileUploader: ElementRef;
  startdate: FormControl;
  startDate: Date;
  companyTrainingModel: CompanyTraining;
  instructors: SystemCode[] = [];
  crew: SystemCode[] = [];
  emsCategoryList: SystemCode[] = [];
  courseCodeList: SystemCode[] = [];
  station: SystemCode[] = [];
  location: SystemCode[] = [];
  trainingTypes: SystemCode[] = [];
  employeeResutObj: Employee[] = [];
  //employeeResutObjAll: Employee[] = [];
  //employeeResutObjActive: Employee[] = [];
  employeeInstructorObj: Employee[] = [];
  employee: number[] = [];
  employeeNames: string[] = [];
  employeeCount: number = 0;
  minDate: Date = new Date();
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  companyTrainingId: number = 0;
  hourPattern = /^([0-1]?[0-9]|[0-9]+):[0-5][0-9]$/;
  isNew: string;
  showcrew: boolean = false;
  showstation: boolean = false;
  //emsCompanyTraining: string = GlobalConstants.emsCompanyTraining;
  //crewOther: string = GlobalConstants.crewOther;
  //stationOther: string = GlobalConstants.stationOther;
  @BlockUI() blockUI: NgBlockUI;
  isEmsTraining: boolean = false;
  classTrainingFile: File;
  classTrainingFileExists: boolean = false;
  classTrainingFileDeteted: boolean = false;
  showInstructorName: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;
  isClicked: boolean = false;
  typeName: string = "";
  trainingHours: string = "0";
  isStartTimeValidation: boolean = false;

  //Lead Instructor Autocomplete - Begin
  employeeResultObj: Array<{employeeId: number, value: string}> = [];
  public filteredList5: Array<{ employeeId: number, value: string }> = [];
  isDropDown: boolean = false;
  //Lead Instructor Autocomplete - End

  constructor(
    private dialog: MatDialog, private routerService: Router, private route: ActivatedRoute,
    private companyTrainingService: CompanyTrainingService, private systemCodeStateService: SystemCodeService,
    private authService: AuthService, private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>, private datepipe: DatePipe
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    route.params.subscribe(val => {
      if (val.id == undefined) {
        this.companyTrainingId = 0;
        this.resetTheForm();
        this.loadGrid();
      } else {
        this.companyTrainingId = parseInt(val.id);
        this.loadGrid();
      }
    });
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.blockUI.start();
      this.loadDropDowns();
      this.companyTrainingModel = this.initializeCompanyTrainingModel();
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.companyTrainingId = parseInt(Id);
          this.loadCompanyTraining();
          this.minDate.setTime(1);
        }
        let name = params['name'];
        if (name != null && name != undefined) {
          this.typeName = name;
        }
        let isnew = params['isnew'];
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
        let type = params['type']
        if (type != null && type != undefined) {
          if (this.typeName == "ems") {
            //load all courses
            this.isEmsTraining = true;
            this.emsCategoryList = this.courseCodeList;
          }
          this.loadEmployeeInstructor(type);
        }
      }, err => {
        this.blockUI.stop();
      });

    }
  }

  initializeCompanyTrainingModel(): CompanyTraining {
    return {
      companyTrainingId: 0,
      trainingType: null,
      className: null,
      startDate: new Date(),
      endDate: new Date(),
      instructor: null,
      trainingHours: null,
      startTime: null,
      endTime: null,
      crew: null,
      station: null,
      category: null,
      numberOfStudents: null,
      crewName: null,
      emsCategoryName: null,
      instructorName: null,
      stationName: null,
      companyTrainingEmployee: null,
      employeeId: null,
      kemiscode: null,
      ffdcode: null,
      classTrainingFileName: null,
      companyTrainingEmployeeStr: null,
      classTrainingFileDeteted: false,
      primaryInstructor: null,
      startDateStr: null,
      leadInstructorFFNNumber: null,
      leadInstructorKemisNumber: null
    } as CompanyTraining;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForTrainingTypes(),
      this.systemCodeStateService.getSystemCodesForCompanyTrainingCrews(),
      this.systemCodeStateService.getSystemCodesForCompanyTrainingStations(),
      this.systemCodeStateService.getSystemCodesForCompanyTrainingLocations(),
      //this.systemCodeStateService.getSystemCodesForInstructor()
    ).subscribe(([res1, res2, res3,res4]) => {
      this.trainingTypes = res1 || [];
      this.crew = res2 || [];
      this.station = res3 || [];
      this.location = res4 || [];
      //this.instructors = res4 || [];
    });
  }

  loadCompanyTraining() {
    if (this.companyTrainingId > 0) {
      this.showcrew = false;
      this.showstation = false;
      this.companyTrainingService.getCompanyTraining('getCompanyTraining', this.companyTrainingId).subscribe(res => {
        this.companyTrainingModel = res;
        this.trainingHours = this.companyTrainingModel.trainingHours;
        this.employee = res.companyTrainingEmployee;
        if (this.companyTrainingModel.crewOther != null && this.companyTrainingModel.crewOther != "") {
          this.showcrew = true;
        }
        if (this.companyTrainingModel.stationOther != null && this.companyTrainingModel.stationOther != "") {
          this.showstation = true;
        }
        if (this.companyTrainingModel.classTrainingFileName != null && this.companyTrainingModel.classTrainingFileName.length > 0) {
          this.classTrainingFileExists = true;
        }
        this.onTrainingTypeChange(this.companyTrainingModel.trainingType, "", this.companyTrainingModel.category, this.typeName);
      }, err => {
        this.blockUI.stop();
      });
    }
  }

  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      //this.employeeResutObjAll = res;
      if (this.companyTrainingId === 0) {
        this.employeeResutObj = res.filter(x => x.isActive == true);
        // this.employeeResutObjActive = res.filter(x => x.isActive == true);
      } else {
        this.employeeResutObj = res;
      }
      this.blockUI.stop();
    }, err => {
      this.blockUI.stop();
    });
  }

  public saveEmstraining() {
    this.isClicked = true;
    let employees: any = "";
    for (let y = 0; y < this.employee.length; y++) {
      employees = employees + this.employee[y] + ",";
    }
    let empArr: number[] = [];
    empArr.push(2);
    this.companyTrainingModel.companyTrainingEmployee = this.employee;

    if (!(this.companyTrainingModel.crewOther != null && this.companyTrainingModel.crewOther != "")) {
      this.companyTrainingModel.crewOther = null;
    }
    if (!(this.companyTrainingModel.stationOther != null && this.companyTrainingModel.stationOther != "")) {
      this.companyTrainingModel.stationOther = null;
    }
    if (this.companyTrainingModel.crew == null) {
      this.companyTrainingModel.crew = 0;
    }
    if (this.companyTrainingModel.station == null) {
      this.companyTrainingModel.station = 0;
    }

    var stDate = new Date(this.companyTrainingModel.startDate);
    var endDate = new Date();
    let formData: FormData = new FormData();
    formData.append("companyTrainingId", this.companyTrainingModel.companyTrainingId.toString());
    formData.append("trainingType", this.companyTrainingModel.trainingType.toString());
    formData.append("className", this.companyTrainingModel.className.toString());
    formData.append("startDate", ((stDate.getMonth() + 1) + "/" + stDate.getDate().toString() + "/" + stDate.getFullYear()));
    formData.append("endDate", ((endDate.getMonth() + 1) + "/" + endDate.getDate().toString() + "/" + endDate.getFullYear()));
    formData.append("startDateStr", this.getDateAsString(stDate));
    formData.append("trainingHours", this.trainingHours.toString());
    formData.append("category", this.companyTrainingModel.category.toString());
    formData.append("crew", this.companyTrainingModel.crew.toString());
    formData.append("station", this.companyTrainingModel.station.toString());
    formData.append("instructor", this.companyTrainingModel.instructor.toString());
    formData.append("numberOfStudents", this.companyTrainingModel.numberOfStudents.toString());
    formData.append("startTime", this.companyTrainingModel.startTime.toString());
    formData.append("endTime", this.companyTrainingModel.endTime.toString());

    if (this.companyTrainingModel.crewName == null || this.companyTrainingModel.crewName == '') {
      formData.append("crewName", "");
    } else {
      formData.append("crewName", this.companyTrainingModel.crewName.toString());
    }

    if (this.companyTrainingModel.stationName == null || this.companyTrainingModel.stationName == '') {
      formData.append("stationName", "");
    } else {
      formData.append("stationName", this.companyTrainingModel.stationName.toString());
    }

    if (this.companyTrainingModel.emsCategoryName == null || this.companyTrainingModel.emsCategoryName == '') {
      formData.append("emsCategoryName", "");
    } else {
      formData.append("emsCategoryName", this.companyTrainingModel.emsCategoryName.toString());
    }

    if (this.companyTrainingModel.instructorName == null || this.companyTrainingModel.instructorName == '') {
      formData.append("instructorName", "");
    } else {
      formData.append("instructorName", this.companyTrainingModel.instructorName.toString());
    }

    formData.append("companyTrainingEmployee", empArr.toString());
    formData.append("employeeNames", "");
    formData.append("classTrainingFileName", "");
    formData.append("employeeId", "0");

    if (this.companyTrainingModel.crewOther == null || this.companyTrainingModel.crewOther == '') {
      formData.append("crewOther", "");
    } else {
      formData.append("crewOther", this.companyTrainingModel.crewOther.toString());
    }

    if (this.companyTrainingModel.stationOther == null || this.companyTrainingModel.stationOther == '') {
      formData.append("stationOther", "");
    } else {
      formData.append("stationOther", this.companyTrainingModel.stationOther.toString());
    }

    if (this.companyTrainingModel.kemiscode == null || this.companyTrainingModel.kemiscode == '') {
      formData.append("kemiscode", "");
    } else {
      formData.append("kemiscode", this.companyTrainingModel.kemiscode.toString());
    }

    if (this.companyTrainingModel.ffdcode == null || this.companyTrainingModel.ffdcode == '') {
    } else {
      formData.append("ffdcode", this.companyTrainingModel.ffdcode.toString());
    }

    if (this.classTrainingFile != null && this.classTrainingFile != undefined) {
      formData.append("classTrainingFile", this.classTrainingFile, this.classTrainingFile.name);
    } else {
      formData.append("classTrainingFile", null);
    }

    formData.append("companyTrainingEmployeeStr", employees);
    //formData.append("classTrainingFileDeteted", this.companyTrainingModel.classTrainingFileDeteted ? "true" : "false");

    if (this.classTrainingFileExists == false) {
      formData.append("classTrainingFileDeteted", "true");
    }
    if (this.companyTrainingModel.primaryInstructor != null && this.companyTrainingModel.primaryInstructor != undefined) {
      formData.append("primaryInstructor", this.companyTrainingModel.primaryInstructor);
    } else {
      formData.append("primaryInstructor", "");
    }
    if (this.companyTrainingModel.location == null || this.companyTrainingModel.location == '') {
      formData.append("location", '');
    }
    else {
      formData.append("location", this.companyTrainingModel.location.toString());
    }

    if (this.companyTrainingId > 0) {
      this.companyTrainingService.updateCompnayTrainingwithFile(this.companyTrainingModel.companyTrainingId.toString(), formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully!") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
            //this.resetTheForm();
            //this.companyTrainingId = 0;
            //this.routerService.navigate(['/companytraining']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
          //dialogRef.afterClosed().subscribe(result => {
          //  //this.navigateToSearchUsers();
          //});
        }
      });
    } else {
      this.companyTrainingService.saveCompnayTrainingwithFile('saveCompnayTraining', formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully!") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }
        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            // this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
          //dialogRef.afterClosed().subscribe(result => {
          //  //this.navigateToSearchUsers();
          //});
        }
      });
    }
  }

  GoBack() {
    this.routerService.navigate(['/companytrainingreport']);
  }

  resetTheForm() {
    this.companyTrainingModel = this.initializeCompanyTrainingModel();
    this.companyTrainingModel.classTrainingFileDeteted = true;
    this.companyTrainingModel.classTrainingFileName = null;
    this.classTrainingFileExists = false;
    if (this.ctFileUploader) {
      this.ctFileUploader.nativeElement.value = null;
    }
    if (this.emstrainingForm)
      this.emstrainingForm.reset();

    this.trainingHours = "";
    this.isEmsTraining = false;
    this.isClicked = false;
    this.isStartTimeValidation = false;
    //Lead Instructor Autocomplete - Begin
    this.employeeResultObj = [];
    this.filteredList5 = [];
    //Lead Instructor Autocomplete - End
  }

  onTrainingTypeChange(value: any, isDefault: any, category: number | null, selectedOption: any) {    
    let fname = "";
    debugger;
    let index = this.trainingTypes.findIndex(x => x.systemCodeId == value);
    if (index >= 0) {
      fname = this.trainingTypes[index].type.substring(19, 22).toLocaleLowerCase();
      console.log(fname);
    }
  //  let fname = selectedOption.substring(0, 3).toLocaleLowerCase();
    //Lead Instructor Autocomplete - Begin
    this.employeeResultObj = [];
    this.filteredList5 = [];
    //Lead Instructor Autocomplete - End
    this.loadEmployeeInstructor(value);
    if (fname == "ems") { //"EMS Company Training"
      this.isEmsTraining = true;
      this.systemCodeStateService.getSystemCodesForEmscategory((this.companyTrainingId > 0 ? '1' : '0')).subscribe(
        (res: Array<SystemCode>) => {
          this.emsCategoryList = res || [];
          this.companyTrainingModel.category = category;
        }, (error: any) => { }
      );
      this.systemCodeStateService.getSystemCodesForEMSCompanyTrainingCrews().subscribe(
        (res) => { this.crew = res || []; }
      );
      this.systemCodeStateService.getSystemCodesForEMSCompanyTrainingStations().subscribe(
        (res) => { this.station = res || []; });
    } else {
      this.isEmsTraining = false;
      this.systemCodeStateService.getAllCourseCode((this.companyTrainingId > 0 ? '1' : '0')).subscribe(
        (res: Array<SystemCode>) => {
          this.emsCategoryList = res || [];
          this.companyTrainingModel.category = category;
        }, (error: any) => { }
      );
      this.systemCodeStateService.getSystemCodesForCompanyTrainingCrews().subscribe((res) => { this.crew = res || []; });
      this.systemCodeStateService.getSystemCodesForCompanyTrainingStations().subscribe((res) => { this.station = res || []; });
    }

    if (isDefault == "Yes") {
      this.emstrainingForm.reset();
      //this.companyTrainingModel = this.initializeCompanyTrainingModel();
      this.companyTrainingModel.trainingType = value;
      this.companyTrainingModel.className = null;
      this.companyTrainingModel.category = null;
      this.companyTrainingModel.startDate = new Date();
      this.companyTrainingModel.instructor = null;
      this.companyTrainingModel.companyTrainingEmployee = null;
      this.companyTrainingModel.numberOfStudents = null;
      this.companyTrainingModel.crew = null;
      this.companyTrainingModel.crewOther = null;
      this.companyTrainingModel.station = null;
      this.companyTrainingModel.stationOther = null;
      this.companyTrainingModel.trainingHours = null;
      this.companyTrainingModel.startTime = null;
      this.companyTrainingModel.endTime = null;
      this.companyTrainingModel.ffdcode = null;
      this.companyTrainingModel.kemiscode = null;
      this.companyTrainingModel.primaryInstructor = null;
      this.showcrew = false;
      this.showstation = false;
      this.isStartTimeValidation = false;
      this.trainingHours = "";
    }
  }

  onLeadInstructorSelected(employeeId: number): void {
    if (employeeId > 0 && (this.companyTrainingModel.instructor != employeeId)) {
      this.companyTrainingModel.instructor = employeeId;
      this.employeeService.getEmployee('getEmployee', employeeId).subscribe((res: Employee) => {
        if (res) {
          this.companyTrainingModel.leadInstructorKemisNumber = res.kemsisNumber;
          this.companyTrainingModel.leadInstructorFFNNumber = res.ffnNumber;
        }
      }, (error: any) => { });
    } else if (!employeeId) {
      this.companyTrainingModel.instructor = null;
      this.companyTrainingModel.leadInstructorFFNNumber = null;
      this.companyTrainingModel.leadInstructorKemisNumber = null;
    }
  }

  getSelected(event: any) {
    if (event.source._selected == true) {
      this.employeeCount = this.employeeCount + 1;
      this.companyTrainingModel.numberOfStudents = this.employeeCount;
    } else {
      this.employeeCount = this.employeeCount - 1;
      this.companyTrainingModel.numberOfStudents = this.employeeCount;
    }
  }

  onCrewChange(value: any, event: any) {
    if (event.source.selected.viewValue == "Other") {
      this.showcrew = true;
    } else {
      this.showcrew = false;
    }
  }

  onStationChange(value: any, event: any) {
    if (event.source.selected.viewValue == "Other") {
      this.showstation = true;
    } else {
      this.showstation = false;
    }
  }

  classTrainingFileStorage(fileInput: any) {
    //this.fileData = <File>fileInput.target.files[0];
    this.classTrainingFile = fileInput[0];
    // this.preview();
  }

  openCFile(companyTrainingId: string, fileName: string) {
    this.companyTrainingService.getCompanyTrainingFile('GetCompanyTrainingFile', companyTrainingId, "CompanyTraining").subscribe(res => {
      saveAs(res, fileName);
    });
    return false;
  }

  deleteCFile(companyTrainingId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '200px',
      data: { id: 1, name: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.companyTrainingModel.classTrainingFileDeteted = true;
        this.companyTrainingModel.classTrainingFileName = null;
        this.classTrainingFileExists = false;
      }
    });
    return false;
  }

  loadEmployeeInstructor(type: any) {
    this.employeeService.getAllEmployeesInstructor('GetEmployeesInstructor', type).subscribe(res => {
      let empobj: Employee[] = res;
      if (this.companyTrainingId == 0) {
        this.employeeInstructorObj = empobj.filter(x => x.isActive == true);
        //Lead Instructor Autocomplete - Begin
        this.employeeResultObj = this.employeeInstructorObj.map(x => { return { employeeId: x.employeeId, value: x.lastName + ', ' + x.firstName }; });
        //Lead Instructor Autocomplete - End
      } else {
        this.employeeInstructorObj = res;
        //Lead Instructor Autocomplete - Begin
        this.employeeResultObj = this.employeeInstructorObj.map(x => { return { employeeId: x.employeeId, value: x.lastName + ', ' + x.firstName }; });
        //Lead Instructor Autocomplete - End
      }
      this.filteredList5 = this.employeeResultObj;
      this.blockUI.stop();
    }, err => {
      this.blockUI.stop();
    });
  }

  onTypeChange(value: any) { }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null
    }
  }

  hourAdded(event) {
    let selectedNumber = event.target.value;
    let prezero = '0';
    let fullhour = '';
    const index = selectedNumber.indexOf('.');
    if (index >= 0) {
      var strs = selectedNumber.split(".");
      if (strs[0] == '') {
        fullhour = prezero + "." + strs[1];
        this.companyTrainingModel.trainingHours = fullhour;
      } else {
        this.companyTrainingModel.trainingHours = selectedNumber;
      }
    }
  }

  startTimeSelected(event: string): void {
    if (event) {
      if (this.companyTrainingModel.endTime) {
        this.timeValidation();
      }
    }
  }

  endTimeSelected(event: string): void {
    if (event) {
      if (this.companyTrainingModel.startTime) {
        this.timeValidation();
      }
    }
  }

  timeValidation(): void {
    this.isStartTimeValidation = false;
    let startTime12HourFormat = this.getAMorPM(this.companyTrainingModel.startTime);
    let endTime12HourFormat = this.getAMorPM(this.companyTrainingModel.endTime);
    let startTimeAM = (startTime12HourFormat === 'AM') ? true : false;
    let endTimeAM = (endTime12HourFormat === 'AM') ? true : false;

    let startTimeHours = this.getTimeHours(this.companyTrainingModel.startTime);
    let endTimeHours = this.getTimeHours(this.companyTrainingModel.endTime);
    let startTimeMinutes = this.getTimeMinutes(this.companyTrainingModel.startTime);
    let endTimeMinutes = this.getTimeMinutes(this.companyTrainingModel.endTime);

    if (startTimeAM) {
      if (endTimeAM) {
        if (startTimeHours == 12 && endTimeHours == 12) {
          if (startTimeMinutes > endTimeMinutes) {
            //Start Time Is Greater Than End Time Error Message
            this.isStartTimeValidation = true;
            this.companyTrainingModel.trainingHours = null;
            return;
          }
        } else if (endTimeHours == 12 && startTimeHours != 12) {
          //Start Time Is Greater Than End Time Error Message
          this.isStartTimeValidation = true;
          this.companyTrainingModel.trainingHours = null;
          return;
        } else if ((startTimeHours > 0 && startTimeHours < 12) && (endTimeHours > 0 && endTimeHours < 12)) {
          if (startTimeHours > endTimeHours) {
            //Start Time Is Greater Than End Time Error Message
            this.isStartTimeValidation = true;
            this.companyTrainingModel.trainingHours = null;
            return;
          } else if (startTimeHours == endTimeHours) {
            if (startTimeMinutes > endTimeMinutes) {
              //Start Time Is Greater Than End Time Error Message
              this.isStartTimeValidation = true;
              this.companyTrainingModel.trainingHours = null;
              return;
            }
          }
        }
      }
    } else {
      if (endTimeAM) {
        //Start Time Is Greater Than End Time Error Message
        this.isStartTimeValidation = true;
        this.companyTrainingModel.trainingHours = null;
        return;
      } else {
        if (startTimeHours == 12 && endTimeHours == 12) {
          if (startTimeMinutes > endTimeMinutes) {
            //Start Time Is Greater Than End Time Error Message
            this.isStartTimeValidation = true;
            this.companyTrainingModel.trainingHours = null;
            return;
          }
        } else if (endTimeHours == 12 && startTimeHours != 12) {
          //Start Time Is Greater Than End Time Error Message
          this.isStartTimeValidation = true;
          this.companyTrainingModel.trainingHours = null;
          return;
        } else if ((startTimeHours > 0 && startTimeHours < 12) && (endTimeHours > 0 && endTimeHours < 12)) {
          if (startTimeHours > endTimeHours) {
            //Start Time Is Greater Than End Time Error Message
            this.isStartTimeValidation = true;
            this.companyTrainingModel.trainingHours = null;
            return;
          } else if (startTimeHours == endTimeHours) {
            if (startTimeMinutes > endTimeMinutes) {
              //Start Time Is Greater Than End Time Error Message
              this.isStartTimeValidation = true;
              this.companyTrainingModel.trainingHours = null;
              return;
            }
          }
        }
      }
    }

    this.initializeTrainingHoursTotal();
  }

  initializeTrainingHoursTotal(): void {
    let startDate = this.datepipe.transform(this.companyTrainingModel.startDate, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(this.companyTrainingModel.startDate, 'yyyy-MM-dd');

    let startTime = this.companyTrainingModel.startTime;
    let endTime = this.companyTrainingModel.endTime;

    let stDate: any = new Date(startDate);
    let edDate: any = new Date(endDate);

    var hoursMinutes = this.timeConvertor(startTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

    stDate.setHours(hours, minutes);
    if (hoursMinutes.includes("PM") && hours !== 12) {
      hours += 12;
      stDate.setTime(1000 /* ms */ * 60 /* s */ * (hours * 60 + minutes));
    }

    var hoursMinutes = this.timeConvertor(endTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);

    let diffMs = (edDate - stDate);
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var startTimeinNumber = (new Date(stDate).getTime() / 1000);
    var endTimeinNumber = (new Date(edDate).getTime() / 1000);
    var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;

    let trainingHoursString = Math.floor(hourDiff);
    let trainingMinutesString = this.getTrainingMinutesFormat(trainingHoursString, diffMins);

    this.trainingHours = trainingMinutesString;
  }

  getTrainingMinutesFormat(hours: number, minutes: number): string {
    if (minutes == 0) {
      return hours + '.' + '0';
    } else if (minutes > 0 && minutes <= 15) {
      return hours + '.' + '25';
    } else if (minutes > 15 && minutes <= 30) {
      return hours + '.' + '5';
    } else if (minutes > 30 && minutes <= 45) {
      return hours + '.' + '75';
    } else {
      return (hours + 1) + '.' + '0';
    }
  }

  timeConvertor(time: any) {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1].toLowerCase();
    if (AMPM == "pm" && hours < 12) hours = hours + 12;
    if (AMPM == "am" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ':' + sMinutes;
  }

  getTimeHours(time: string): number {
    let index = time.indexOf(':');
    let hours = time.substring(0, index);
    if (hours) {
      return +hours;
    }
    return 0;
  }

  getTimeMinutes(time: string): number {
    let hoursIndex = time.indexOf(':');
    let index = time.indexOf(' ');
    let minutes = time.substring(hoursIndex + 1, index);
    if (minutes) {
      return +minutes;
    }
    return 0;
  }

  getAMorPM(time: string): string {
    let index = time.indexOf(' ');
    let time12HourFormat = time.substring(index + 1, time.length);
    if (time12HourFormat) {
      return time12HourFormat;
    }
    return null;
  }

  //Lead Instructor Autocomplete - Begin
  onOpenedChange(isOpen, employeeId): void {
    this.isDropDown = isOpen;
    if (!isOpen) {
      if ((this.filteredList5.length <= 0 || this.filteredList5.find(x => x.employeeId == employeeId) == null)) {
        this.companyTrainingModel.instructor = null;
        this.companyTrainingModel.leadInstructorFFNNumber = null;
        this.companyTrainingModel.leadInstructorKemisNumber = null;
      }
    }
  }
  //Lead Instructor Autocomplete - End
}
