import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { inspection } from '../models/inspection.model';
import { InspectionService } from '../services/inspection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
@Component({
  selector: 'inspectionprint',
  templateUrl: './inspectionprint.component.html'
})
export class InspectionPrintComponent {
  inspectionReportModel: inspection;
  inspectionId: number = 0;
  inspectionModel: inspection;

  inspectiontype: Array<{ key: number, value: string }> = [];
  inspectionconductedbyList: Array<{ key: number, value: string }> = [];
  knoxboxlist: Array<{ key: number, value: string }> = [];
  district: Array<{ key: number, value: string }> = [];
  inspectionResult: Array<{ key: number, value: string }> = [];
  occupancyType: Array<{ key: number, value: string }> = [];



  constructor(
    private inspectionService: InspectionService, private route: ActivatedRoute, private systemCodeStateService: SystemCodeService
  ) {
    this.route.params.subscribe(params => {
      debugger;
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.inspectionId = parseInt(Id);
      }
    });

  }
  ngOnInit() {
    this.loadDropDowns();
    this.inspectionModel = this.initializeInspectionModel();

    if (this.inspectionId > 0) {
      debugger;
      this.inspectionService.getInspection('GetInspection', this.inspectionId).subscribe(res => {
        this.inspectionModel = res;

        console.log(res);
      });
    }


  }

  initializeInspectionModel(): inspection {
    return {
      inspectionId: 0, inspectionDate: null, businessName: null, district: 0,
      streetNumber: "", streetName: null, occupancyType: 0, inspectionType: 0,
      inspectionConductedBy: 0, inspectionResult: 0, knoxBox: 0, notes: "",
      reInspectDate: null, nextInspectDate: null, repeatcheck: null, repeats: 0, repeatcount: 0,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      occurences: 0, stopRepeating: null, excludecheck: false, includecheck: false,
      inspectionexcludedate: null,
      inspectionincludedate: null,
      occupancyTypeName: "",
      inspectionTypeName: "",
      inspectionResultName: "",
      districtName: "",
      knoxboxName: "",
      inspectionconductedbyName: ""
    } as inspection;
  }
  public loadDropDowns() {

    this.systemCodeStateService.getSystemCodesForInspectionType().subscribe(
      (res: Array<SystemCode>) => {
        this.inspectiontype = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForInspectionConductedBy().subscribe(
      (res: Array<SystemCode>) => {
        this.inspectionconductedbyList = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForKonxBoxs().subscribe(
      (res: Array<SystemCode>) => {
        this.knoxboxlist = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.district = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForInspectionResult().subscribe(
      (res: Array<SystemCode>) => {
        this.inspectionResult = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );
    this.systemCodeStateService.getSystemCodesForOccupancyType().subscribe(
      (res: Array<SystemCode>) => {
        this.occupancyType = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
        //  console.log(res);

      }, (error: any) => { }
    );


  }

}
