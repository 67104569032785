import { Component, Input } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { FormGroup, NgForm, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DepartmentUpdate } from '../models/departmentupdate.model';
import { DepartmentUpdateDescription } from '../models/departmentupdatedescription.model';
import { DepartmentUpdateService } from '../services/departmentupdate.service';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { MatTable } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { startWith, map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GetAllDepartmentUpdate } from '../models/get-all-departmentupdate.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';


@Component(
  {
    selector: 'deptupdatereport',
    templateUrl: './deptupdatereport.component.html'
  })

export class DeptUpdateReportComponent implements OnInit {

  @ViewChild('deptupdatereportForm', { static: false }) deptupdatereportForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @BlockUI() blockUI: NgBlockUI;

  deptupdateObjres: DepartmentUpdate;
  deptUpdateResultObj: DepartmentUpdate[];
  deptUpdateModel: DepartmentUpdate;

  dataSource: MatTableDataSource<DepartmentUpdate>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  errMsg: string;
  rolePermissionCheckModel: RolePermissionCheck;
  updateType: SystemCode[] = [];
  dt = new Date();
  floatLabel: string = 'never';
  resultsLength: number = 0;

  displayedColumns: any[] = ['modifiedOn', 'subject'];

  constructor(private systemCodeStateService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private departmentUpdateService: DepartmentUpdateService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    
    this.deptupdateObjres = { createdOn: null, createdBy: null, departmentUpdateId: 0, subject: null, departmentType: null, descriptions: null, modifiedOn: null, modifiedBy: null }
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.loadDropDowns();
      this.initializeActionsForDisplayedColumns();
      this.deptUpdateModel = this.initializedeptUpdateModel();
      this.dataSource = new MatTableDataSource<DepartmentUpdate>(this.deptUpdateResultObj);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //this.loaddeptupdatelist();
    }
  }

  initializedeptUpdateModel(): DepartmentUpdate {
    return { createdOn: this.dt, createdBy: null, departmentUpdateId: 0, subject: null, departmentType: null, descriptions: null, modifiedOn: null, modifiedBy: null } as DepartmentUpdate;
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  //**************

  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllDepartmentUpdate(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            //this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.deptUpdateResultObj = data || [];
          this.dataSource = new MatTableDataSource(this.deptUpdateResultObj);
          //this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //this.blockUI.stop();
        });
    }
  }

  getAllDepartmentUpdate(pageIndex: number, pageSize: number): Observable<GetAllDepartmentUpdate> {
    return this.departmentUpdateService.getAllDepartmentUpdateNew(
      'GetAllDepartmentUpdateNew',
      (this.deptUpdateModel.subject) ? this.deptUpdateModel.subject : null,
      (this.deptUpdateModel.departmentType) ? this.deptUpdateModel.departmentType : 0,
      pageIndex,
      pageSize
    );
  }

  getAllDepartmentUpdateForDataSource(): void {
    this.getAllDepartmentUpdate(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          return of([]);
        })
      ).subscribe(data => {
        this.deptUpdateResultObj = data || [];
        this.dataSource = new MatTableDataSource(this.deptUpdateResultObj);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  searchdepartmentupdate() {
    this.blockUI.start();
    //this.showNewButton = true;
    this.getAllDepartmentUpdateForDataSource();
  }
  //*******************

  public loadDropDowns() {

    this.systemCodeStateService.getSystemCodesForDepartmentUpdate().subscribe(
      (res: Array<SystemCode>) => {
        this.updateType = res || [];
      }, (error: any) => { }
    );
  }

  loaddeptupdatelist() {
    this.blockUI.start();
    this.departmentUpdateService.getUpdateList('GetUpdateList', 0).subscribe(res => {
      //this.deptupdateObjres = res;
      this.deptUpdateResultObj = res;
      this.dataSource = new MatTableDataSource<DepartmentUpdate>(this.deptUpdateResultObj);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.blockUI.stop();
    });
    //this.dataSource = new MatTableDataSource<DepartmentUpdate>(this.deptUpdateResultObj);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  deleteDepartmentUpdate(dId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {

        this.departmentUpdateService.deleteDepartmentUpdate('DeleteDepartmentUpdate', parseInt(dId)).subscribe(res => {
          this.errMsg = res.msg;
          //this.loaddeptupdatelist();
          this.getAllDepartmentUpdateForDataSource();
          if (this.errMsg == "Deleted Successfully.") {
            const dialogRef = this.dialog.open(SaveDialogComponent, {
              width: '500px',
              height: '200px',
              data: { name: "Deleted Successfully!" }
            });
          }
          //this.getAllppeInspectionForDataSource();

        });
      }
    });
    return false;
  }

  resetform() {
    this.deptupdatereportForm.resetForm();
    this.deptUpdateModel = this.initializedeptUpdateModel();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.getAllDepartmentUpdateForDataSource();
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}


//  //deptupdateObj: departmentupdate;
//  deptupdateObjres: departmentupdate;
//  departmentupdatetype: SystemCode[] = [];
//  departmentupdates: departmentdescription[] = [];
//  createdOn: FormControl;
//  createdBy: any;
//  subject: string;
//  departmentUpdateId: number;
//  x = new Date();


//  errMsg: string;
//  savedialog: boolean;
//  errordialog: boolean;
//  department1: string;
//  department2: string;
//  department3: string;

//  constructor(private systemCodeStateService: SystemCodeService,
//    private routerService: Router,
//    private route: ActivatedRoute,
//    private router: Router,
//    private dialog: MatDialog,
//    private authService: AuthService,
//    private departmentUpdateService: DepartmentUpdateService)
//  {
//    this.route.params.subscribe(params => {
//      let Id = params['id']
//      if (Id != null && Id != undefined) {
//        this.departmentUpdateId = parseInt(Id);
//      }
//    });

//  }

//  ngOnInit() {

//    this.deptupdateObjres = { createdOn: this.x, createdBy: "", departmentUpdateId: 0, subject: "", departmentType: 0, description: null }

//    if (this.departmentUpdateId > 0) {
//      this.departmentUpdateService.getDeptUpdate('GetUpdateLists', this.departmentUpdateId).subscribe(res => {        
//        this.deptupdateObjres = res;
//        this.department1 = this.deptupdateObjres.description[0].description;
//        this.department2 = this.deptupdateObjres.description[1].description;
//        this.department3 = this.deptupdateObjres.description[2].description;
//      });

//    }

//    //this.loaddeptupdatelist();
//  }

//  goBack() {
//    this.router.navigate(['/departmentupdateform']);
//  }

//}
