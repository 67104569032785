import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'emsRun-dialog-content',
  templateUrl: 'emsRun-dialog-content.html',
})
export class EmsRunDialogContent {
  nameList: string[];
  name1: string[];
  name2: string[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.nameList = data.names;
    //this.name1 = this.nameList[0].split("-");

  }
}
