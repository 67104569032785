import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { CompanyTraining } from '../models/companytraining.model';
import { ErrorService } from './error.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { GetAllCompanyTraining } from '../models/get-all-companyTraining.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class CompanyTrainingService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/CompanyTraining/', httpClient, errorService, authService);
  }

  saveCompnayTraining(action: string, companyTrainingDto: CompanyTraining): Observable<any> {
    return this.postRequest(action, JSON.stringify(companyTrainingDto));
  }

  getCompanyTraining(action: string, companyTrainingId: number): Observable<any> {
    const params = new HttpParams()
      .set('companyTrainingId', companyTrainingId.toString());
    return this.customGetRequestWithQueryString<Array<CompanyTraining>>(action, params);
  }

  getAllCompanyTraining(action: string): Observable<Array<CompanyTraining>> {
    return this.customGetRequest<Array<CompanyTraining>>('getAllCompanyTraining', null);
  }

  getAllCompanyTrainingsForReportView(action: string, trainingType: number = 0, instructor: number = 0, startDate: Date = null,
    category: number = 0, crew: number = 0, station: number = 0, endDate: Date = null,
    employeeId: number = 0): Observable<Array<CompanyTraining>> {
    let params = new HttpParams()
      .set('trainingType', trainingType.toString())
      .set('instructor', instructor.toString())
      .set('category', category.toString())
      .set('crew', crew.toString())
      .set('station', station.toString())
      .set('employeeId', employeeId.toString());

    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }

    return this.customGetRequestWithQueryString<Array<CompanyTraining>>(action, params);
  }

  getAllCompanyTrainingNew(action: string, trainingType: number = 0, instructor: number = 0, startDate: Date = null,
    category: number = 0, crew: number = 0, station: number = 0, endDate: Date = null,
    employeeId: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllCompanyTraining> {
    let params = new HttpParams()
      .set('trainingType', trainingType.toString())
      .set('instructor', instructor.toString())
      .set('category', category.toString())
      .set('crew', crew.toString())
      .set('station', station.toString())
      .set('employeeId', employeeId.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    return this.customGetRequestWithQueryString<GetAllCompanyTraining>(action, params);

    //var startDate = new Date(startDate);
    //var endDate = new Date(endDate);
    //var stDate = new Date();
    //if (startDate == null) {
    //  var lyear = stDate.getFullYear();
    //  var lmonth = stDate.getMonth();
    //  var lday = stDate.getDate();
    //  stDate = new Date(1900, 0, 1);
    //}
    //else {
    //  stDate = new Date(startDate);
    //}

    //var edDate = new Date();
    //if (endDate == null) {
    //  var cyear = edDate.getFullYear();
    //  var cmonth = edDate.getMonth();
    //  var cday = edDate.getDate();
    //  edDate = new Date(1900, 0, 1);
    //}
    //else {
    //  edDate = new Date(endDate);
    //}    
  }

  searchCompanyTraining(action: string, companyTrainingDto: CompanyTraining): Observable<any> {
    return this.postRequest(action, JSON.stringify(companyTrainingDto));
  }

  delCompanyTraining(action: string, companyId: number): Observable<any> {
    return this.deleteRequest(companyId);
  }

  updateCompnayTraining(action: string, companyTrainingDto: CompanyTraining): Observable<any> {
    return this.putRequest(companyTrainingDto.companyTrainingId, JSON.stringify(companyTrainingDto));
  }

  saveCompnayTrainingwithFile(action: string, formdata: FormData): Observable<any> {
    return this.customPostWithFile(action, formdata);
  }

  updateCompnayTrainingwithFile(action: string, formdata: FormData): Observable<any> {
    return this.customPutRequest(action, formdata);
  }

  saveCompnayTrainingwithFileNew(action: string, formdata: FormData): Observable<any> {
    return this.uploadFile(action, formdata);
  }

  getCompanyTrainingFile(action: string, companyTrainingId: string, filetype: string) {
    const params = new HttpParams()
      .set('companyTrainingId', companyTrainingId)
      .set('fileType', filetype)
    return this.customGetRequestWithQuery(action, params);
  }
}
