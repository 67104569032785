import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
//import { DailyRoaster } from '../models/dailyroaster.model';
import { ErrorService } from './error.service';
import { Certification } from '../models/certification.model';
import { GetAllEmployees } from '../models/get-all-employees.model';
import { GetAllDailyRoaster } from '../models/GetAllDailyRoaster';
import { StRoster } from '../models/stroster.model';
import { AuthService } from './auth.service';
import { DailyRoasterAssignment } from '../models/dailyroasterassignment.model';
import { Employee } from '../models/employee.model';

@Injectable({ providedIn: 'root' })
export class DailyRoasterService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/DailyRoaster/', httpClient, errorService, authService);
  }

  saveDailyRoaster(action: string, dailyRoaster: StRoster[]): Observable<any> {
    return this.postRequest(action, JSON.stringify(dailyRoaster));
  }

  saveStRoster(action: string, stRosterObj: StRoster): Observable<any> {
    return this.postRequest(action, JSON.stringify(stRosterObj));
  }

  getRoster(action: string, StRosterId: number): Observable<any> {
    return this.customGetRequest<StRoster>(action, StRosterId.toString());
  }

  updateRoster(stRosterId: number, stRosterObj: StRoster): Observable<any> {
    return this.putRequest(stRosterId, JSON.stringify(stRosterObj));
  }

  getDailyRoasters(action: string, shiftDate: Date = null, crew: number = 0, station: number = 0, employee: number = 0, pageNumber: number = 0, pageSize: number = 0, shiftStr: string) {
    let params = new HttpParams()
      // .set('shiftDate', shiftDate.toDateString())
      .set('crew', crew.toString())
      .set('station', station.toString())
      .set('personnel', employee.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (shiftDate != null) {
      params = params.set('shiftDateStr', shiftStr);
    } else {
      params = params.set('shiftDateStr', "");
    }
    return this.customGetRequestWithQueryString<GetAllDailyRoaster>(action, params);
  }

  deleteRoaster(action: string, roasterId: number) {
    return this.deleteRequest(roasterId);
  }

  getDailyRoasterView(action: string, employee: number, shiftdate: string): Observable<any> {
    // let sdt = this.getDateAsString(shiftdate);
    let params = new HttpParams()
      .set('shiftdate', shiftdate)
      .set('employee', employee.toString())
    return this.customGetRequestWithQueryString<DailyRoasterAssignment>(action, params);
  }

  getStRoasterPrintView(action: string, employee: number, shiftdate: string, station: number): Observable<any> {
    let params = new HttpParams()
      .set('shiftdate', shiftdate)
      .set('employee', employee.toString())
      .set('station', station.toString())
    return this.customGetRequestWithQueryString<DailyRoasterAssignment>(action, params);
  }

  getTimeSheetView(action: string, shiftdate: Date, activitycode: number, position: number): Observable<any> {
    let sdt = this.getDateAsString(shiftdate);
    let params = new HttpParams()
      .set('shiftdate', sdt)
      .set('activitycode', activitycode.toString())
      .set('personnel', position.toString())
    return this.customGetRequestWithQueryString<DailyRoasterAssignment>(action, params);
  }

  getGenaTimeSheetView(action: string, startDate: Date, endDate: Date, activitycode: number, position: number, station: number): Observable<any> {
    let sdt = this.getDateAsString(startDate);
    let edt = this.getDateAsString(endDate);

    let params = new HttpParams()
      .set('startDate', sdt)
      .set('endDate', edt)
      .set('activitycode', activitycode.toString())
      .set('personnel', position.toString())
      .set('crew', station.toString())
    return this.customGetRequestWithQueryString<DailyRoasterAssignment>(action, params);
  }

  getShaunsTimeSheetView(action: string, shiftdate: Date, endDate: Date, activitycode: string, position: string): Observable<any> {
    let sdt = this.getDateAsString(shiftdate);
    let edt = this.getDateAsString(endDate);

    let params = new HttpParams()
      .set('startdate', sdt)
      .set('enddate', edt)
      .set('activitycode', activitycode.toString())
      .set('personnel', position.toString())
    return this.customGetRequestWithQueryString<DailyRoasterAssignment>(action, params);
  }

  getTradeTimeView(action: string, startDate: Date, endDate: Date, activitycode: string, position: string, station: string): Observable<any> {
    let sdt = this.getDateAsString(startDate);
    let edt = this.getDateAsString(endDate);

    let params = new HttpParams()
      .set('startdate', sdt)
      .set('enddate', edt)
      .set('activitycode', activitycode.toString())
      .set('personnel', position.toString())
      .set('assignment', station.toString())
    return this.customGetRequestWithQueryString<DailyRoasterAssignment>(action, params);
  }

  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null
    }
  }

  getDailyRoastersForPrint(action: string, shiftDate: Date = null, crew: number = 0, station: number = 0, employee: number = 0, pageNumber: number = 0, pageSize: number = 0, shiftStr: string) {
    let params = new HttpParams()
      // .set('shiftDate', shiftDate.toDateString())
      .set('crew', crew.toString())
      .set('station', station.toString())
      .set('personnel', employee.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (shiftDate != null) {
      params = params.set('shiftDateStr', shiftStr);
    } else {
      params = params.set('shiftDateStr', "");
    }
    return this.customGetRequestWithQueryString<any>(action, params);
  }
}
