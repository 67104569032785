import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AwardNominationService } from '../services/awardnomination.service';
import { AwardNomination } from '../models/awardnomination.model';
import { Witness } from '../models/witness.model';
import { Nominee } from '../models/nominee.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'awardnominationview',
  templateUrl: './awardnominationview.component.html'
})
export class AwardNominationViewComponent {
  awardNominationModel: AwardNomination;
  awardNominationResultObj: AwardNomination[];
  awardNominationId: number = 0;
  awardNominees: Nominee[] = [];
  awardWitness: Witness[] = [];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private routerService: Router,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private awardNominationService: AwardNominationService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.awardNominationModel = this.initializeAwardNominationModel();
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.awardNominationId = parseInt(Id);
          this.loadData();
        }
      });
    }
  }

  initializeAwardNominationModel(): AwardNomination {
    return {
      awardNominationId: 0, awardRecommendation: null, incidentType: null, actionDate: null, runNumber: null,
      narrative: null, submittedBy: null, status: null, nominees: null, witness: null, nomineeName: "", witnessName: "",
      awardRecommendationName: "", incidentTypeName: "", submittedByName: "", statusName: "", startDate: null, endDate: null
    } as AwardNomination;
  }

  loadData() {
    if (this.awardNominationId > 0) {
      this.awardNominationService.getAwardNominee('GetAwardNominee', this.awardNominationId).subscribe(res => {
        this.awardNominationModel = res;
        this.awardNominees = this.awardNominationModel.nominees;
        this.awardWitness = this.awardNominationModel.witness;
      });
    }
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  GoBack(): void {
    this.routerService.navigate(['/awardnominationreport']);
  }
}
