import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { StRoster } from '../models/stroster.model';
import { StRosterService } from '../services/stroster.service';
import { EmployeeService } from '../services/employee.service';
import { RosterAssignment } from '../models/rosterassignment.model';
import { RosterAssignmentService } from '../services/rosterassignment.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, empty, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { error } from 'util';
import { Employee } from '../models/employee.model';
import { Time } from '@angular/common';
import { MaintanenceRequestService } from '../services/maintanancerequest.service';

@Component({
  selector: 'stroaster',
  templateUrl: './stroaster.component.html',
  styleUrls: ['./stroaster.component.css']
})

export class StRoasterComponent {

  @ViewChild('stRosterForm', { static: false }) stRosterForm: NgForm;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('personnelControl', { static: false }) personnelControl: HTMLSelectElement
  paginatorid: MatPaginator;
  sortid: MatSort;
  showEndDate: boolean = false;
  stRosterId: number;
  assignmentId: number;
  errMsg: string;
  hint: string;
  hint1: string;
  //hint2: string;
  hint3: string;
  datehint: string;
  datehint2: string;
  editIndex: number=0;
  OnEdit: boolean = false;
  savedialog: boolean;
  updatebtn: boolean = false;
  resetbtn: boolean = true;
  addassbtn: boolean = true;
  savebtn: boolean = true;
  resetOption: boolean = false;
  resetOption1: boolean = false;
  key: number;
  value: string;
  floatLabel: string = 'never';
  rosterAssignments: string[] = [];
  errordialog: boolean;
  personnel: number[] = [];
  fromTime: number;
  toTime: number;
  zone: string;
  zone1: string;
  PrefromTime: number;
  PretoTime: number;
  NextfromTime: number;
  NexttoTime: number;
  EndDateDisable: boolean = false;

  endDate: number;
  PrestartDate: number;
  PreendDate: number;
  NextstartDate: number;
  NextendDate: number;
  startDateVar: Date=null;
  endDateVar: Date = null;
  endDateVar1: Date;
  startDateVar1: Date;

  index: number;
  assignmentEmployee: number;
  assignmentEmployeeName: string;

  //assignmentId: number;
  assignment: number=0;
  position: number=null;
  unit: number=0;
  activityCodeVar: number;
  notes: string;
  fromTimeVar: string;
  toTimeVar: string;
  isActive: boolean;
  dateenable: boolean;
  enddateenable: boolean;
  //stRosterId: number;

  todayDate: Date = new Date();
  fromDate: Date;
  shiftDate: Date;
  toDate: Date;
  activityCodeValue: string;
  employeeModel: Employee[] = [];
  assignmentModelDB = new MatTableDataSource<RosterAssignment>([]);
  control = new FormControl();
  rosterAssignmentsModel: RosterAssignment[] = [];
  stRosterModel: StRoster;
  rosterassignmentModel: RosterAssignment;
  assignmentModel: RosterAssignment[] = [];
  trader: Observable<string[]>;
  systemcodeKeyValue: SystemCodeKeyValue;
  personnelCode: SystemCode[] = [];
  crewCode: SystemCode[] = [];
  unitCode: SystemCode[] = [];
  assignmentCode: SystemCode[] = [];
  stationCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  positionsCode: SystemCode[] = [];

  personnelKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  positionKey: SystemCodeKeyValue[] = [];
  assignmentKey: SystemCodeKeyValue[] = [];
  unitKey: SystemCodeKeyValue[] = [];
  stationKey: SystemCodeKeyValue[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];

  displayedColumns: any[] = ['position', 'unit', 'assignment', 'fromTime', 'toTime', 'assignmentId', 'index'];
  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private stRosterService: StRosterService,
    private rosterAssignmentService: RosterAssignmentService, private changeDetectorRefs: ChangeDetectorRef,
    private employeeService: EmployeeService) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.stRosterId = parseInt(Id);
        //this.rosterassignmentObj.stRosterId = parseInt(Id);
      }
    });
    this.loadDropDowns();
    this.loadEmployee();
    this.loadAssignments();
    this.updatebtn = false;
    this.stRosterModel = this.initializeStRosterModel();
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    if (this.stRosterId > 0) {
      this.stRosterService.getRoster('getRoster', this.stRosterId).subscribe(res => {
        //debugger;
        this.show();
        this.datehint = "";
        this.stRosterModel = res;
        this.dateenable = true;
        this.assignmentModelDB = res.rosterAssignments;
        this.rosterAssignmentsModel = res.rosterAssignments;
        console.log("Get Roster", res);
      }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error Occured. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });

      });


    }
    this.trader = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );


    //  this.gridPageAndSort();
    //this.systemcodeKeyValue = {
    //  key:0,value:''
    //}

  }

  initializeStRosterModel(): StRoster {
    return {
      stRosterId: 0, shiftDate: null, personnel: null, crew: null,  rosterAssignments: null
    } as StRoster;
  }// fromDate: null, toDate: null, endDateCheck: false,

  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: null, fromTime: null, toTime: null, startDate: null, endDate: null,
      isActive: true, stRosterId: 0
    } as RosterAssignment;
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.rosterAssignments.filter(s => this._normalizeValue(s).includes(filterValue));
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  loadEmployee() {
    debugger;
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      this.employeeModel = res;
      debugger;
      console.log("Employee Model", res);
    });
  }

  loadAssignments() {
    this.rosterAssignmentService.loadAssignments('getAssignments').subscribe(res => {
      //debugger;
      this.rosterAssignments = res;
    });
    return false;
  }

  addAssignment() {
    debugger;

    this.rosterassignmentModel.assignment = this.assignment;
    this.rosterassignmentModel.position = this.position;
    this.rosterassignmentModel.unit = this.unit;
    this.rosterassignmentModel.activityCode = this.activityCodeVar;
    this.rosterassignmentModel.notes = this.notes;
    this.rosterassignmentModel.fromTime = this.fromTimeVar;
    this.rosterassignmentModel.toTime = this.toTimeVar;
    this.rosterassignmentModel.startDate = this.startDateVar;
    this.rosterassignmentModel.endDate = this.endDateVar;
    this.rosterassignmentModel.isActive = true;


    this.rosterAssignmentsModel.push(this.rosterassignmentModel);
    console.log('rosterassignmentModel:' + this.rosterAssignmentsModel);
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();

    this.assignment = 0;
    this.position = 0;
    this.unit = 0;
    this.activityCodeVar = 0;
    this.notes = '';
    this.fromTimeVar = '';
    this.toTimeVar = '';
    this.startDateVar = null;
    this.endDateVar = null;  
  }

  shiftdateevent1(shiftDate: Date) {
    debugger;
    this.shiftDate = shiftDate;
    this.dateenable = true;
    
  }

  autofillnextday() {
    debugger;
    
  }

  onActivityCodeChange(event: any) {
    debugger;
    this.hint = "";
    this.hint1 = "";
    if (event.source.selected.viewValue == "Overtime") {
      this.activityCodeValue = event;      
      this.startDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    } else {
      this.activityCodeValue = "";
      if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
        this.hint = "";
        if (this.fromTime < this.toTime && this.zone == this.zone1) {
          this.hint1 = "More than 24 hrs shift  not allowed";
        } else if (this.zone != this.zone1) {
          this.hint1 = "More than 24 hrs shift  not allowed";
        } else {
          this.hint1 = "";
        }
      }
      //this.startDateVar1 = this.startDateVar;
      //this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    }
    this.enddateenable = true;    
  }



  dateevent1(fromDate: Date) {
    debugger;    
    this.fromDate = fromDate;
    //if (this.activityCodeValue != "") {
    //  this.startDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    //  this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    //} else {
    //  this.startDateVar1 = this.startDateVar;
    //    this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    //}
    this.startDateVar1 = this.startDateVar;
    this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    this.endDateVar = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    this.toDate = this.endDateVar;
    //if (this.stRosterId > 0) {
    //  this.toDate = this.rosterassignmentModel.endDate;
    //}
    this.datehint = "";
    //if ((new Date(this.shiftDate).getTime()) > (new Date(this.fromDate).getTime())) {
    //  this.datehint2 = "Start date Should be greater than or equal to Shift Date";      
    //} else if((new Date(this.shiftDate).getTime()) <= (new Date(this.fromDate).getTime())) {
    //  this.datehint2 = "";
    //}

    //if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
    //  this.datehint = "End Date Should Greater than or equal to Start Date";
    //} else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
    //  this.datehint = "";
    //} else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
    //  this.datehint = "";
    //}    
  }

  dateevent2(toDateev: Date) {
    debugger;
    this.toDate = toDateev;
    //if (this.stRosterId > 0) {
    //  this.fromDate = this.rosterassignmentModel.startDate;
    //}
    //if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
    //  this.datehint = "End Date Should Greater than or equal to Start Date";
    //} else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
    //  this.datehint = "";
    //}else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
    //  this.datehint = "";
    //}

    if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.hint = "";
      if (this.fromTime < this.toTime && this.zone == this.zone1) {
        this.hint1 = "More than 24 hrs shift  not allowed";
      } else if (this.zone != this.zone1) {
        this.hint1 = "More than 24 hrs shift  not allowed";
      } else {
        this.hint1 = "";
      }

    } else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      if (this.fromTime > this.toTime && this.zone == this.zone1) {
        this.hint1 = "Increase end date to extend shift hours (OR)";
        this.hint = "Provide End Time greater than Start Time";
      } else if (this.fromTime > this.toTime && this.zone != this.zone1) {
        this.hint1 = "";
        this.hint = "";
      } else if (this.fromTime > this.toTime && this.zone != this.zone1) {
        this.hint1 = "";
        this.hint = "";
      }else {
        this.hint1 = "";
        this.hint = "";
      }
    }
  }

  timevent(event: string) {
    debugger;

    var hoursMinutes = event.split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.zone = hoursMinutes[2].toString();
    this.fromTime = hours + minutes / 60;

    if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.hint1 = "";
      
    } else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      if (this.fromTime < this.toTime && this.zone == this.zone1) {
        this.hint1 = "Increase end date to extend shift hours ";
        this.hint = "Provide End Time greater than Start Time";
      } else if (this.fromTime > this.toTime && this.zone != this.zone1) {
        this.hint1 = "";
        this.hint = "";
      } else {
        this.hint1 = "";
        this.hint = "";
      }
    }

    //let therosterAssignmentsModel: RosterAssignment[] = [];
    //for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
    //  if (this.rosterAssignmentsModel[i].isActive == true) {
    //    therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
    //  }
    //}

    //if ((this.OnEdit == true || this.index != undefined)) { 
    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex - 1].fromTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PrefromTime = hours + minutes / 60;

    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex - 1].toTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PretoTime = hours + minutes / 60;

    //  if ((this.PrefromTime < this.fromTime) && (this.PretoTime < this.fromTime) && this.rosterassignmentModel.assignmentId == 0) {
    //    this.hint2 = "";
    //    if (this.fromTime >= this.toTime) {
    //      this.hint3 = "End Time Should be greater than Start Time";
    //    } else {
    //      this.hint3 = "";
    //    }
    //  } else {
    //    this.hint2 = "Start Time Should be greater than previous assignment";      
    //  }
          
    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex + 1].fromTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.NextfromTime = hours + minutes / 60;

    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex + 1].toTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.NexttoTime = hours + minutes / 60;

    //  if ((this.NextfromTime > this.fromTime) && (this.NexttoTime > this.fromTime) && this.rosterassignmentModel.assignmentId == 0 ) {
    //    this.hint = "";
    //    if (this.fromTime >= this.toTime) {
    //      this.hint3 = "End Time Should be greater than Start Time";
    //    } 
    //  } else {
    //    this.hint = "Start Time Should be lesser than next assignment";       
    //  }
    //}
    //else if (therosterAssignmentsModel.length > 0) {
    //  var hoursMinutes = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].fromTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PrefromTime = hours + minutes / 60;

    //  var hoursMinutes = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].toTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PretoTime = hours + minutes / 60;

    //  if ((this.PrefromTime < this.fromTime) && (this.PretoTime < this.fromTime)) { 
    //    this.hint = "";
    //  } else {
    //    this.hint = "Start Time Should be greater than previous assignment";
    //  }
    //} else if (therosterAssignmentsModel.length == 0) {
    //  this.hint = "";
    //}
    if (this.hint3 == undefined) {
      this.hint3 = "";
    }
    if (this.hint == undefined) {
      this.hint = "";
    }
    if (this.hint1 == undefined) {
      this.hint = "";
    }
    //if (this.hint2 == undefined) {
    //  this.hint = "";
    //}

    console.log("hint", this.hint);
    console.log("hint 3", this.hint3);
    //console.log("hint 2", this.hint2);

  }


  timevent1(event: string) {
    debugger;
    var hoursMinutes = event.split(/[.:   ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.zone1 = hoursMinutes[2].toString();
    this.toTime = hours + minutes / 60;
   

    if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {


    } else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {


    }

    if (this.activityCodeValue == "" || this.activityCodeValue == null) {
      if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
        this.hint = "";
        if (this.fromTime < this.toTime && this.zone == this.zone1) {
          this.hint1 = "More than 24 hrs shift  not allowed";
        } else if (this.zone != this.zone1) {
          this.hint1 = "More than 24 hrs shift  not allowed";
        } else {
          this.hint1 = "";
        }

      } else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
        if (this.fromTime > this.toTime && this.zone == this.zone1) {
          this.hint1 = "Increase end date to extend shift hours ";
          this.hint = "Provide End Time greater than Start Time";
        } else if (this.fromTime < this.toTime && this.zone != this.zone1) {
          this.hint1 = "";
          this.hint = "";
        } else {
          this.hint1 = "";
          this.hint = "";
        }
      }
    } else if (this.activityCodeValue != "") {

      this.endDateVar1 = this.startDateVar;
      //if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      //  this.hint = "";
      //  if (this.fromTime < this.toTime && this.zone == this.zone1) {
      //    this.hint1 = "More than 36 hrs shift  not allowed";
      //  } else if (this.zone != this.zone1) {
      //    this.hint1 = "More than 36 hrs shift  not allowed";
      //  } else {
      //    this.hint1 = "";
      //  }
      //} 
    }

    //let therosterAssignmentsModel: RosterAssignment[] = [];
    //for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
    //  if (this.rosterAssignmentsModel[i].isActive == true) {
    //    therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
    //  }
    //}

    //if ((this.OnEdit == true || this.index != undefined)) { 
    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex - 1].fromTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PrefromTime = hours + minutes / 60;

    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex - 1].toTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PretoTime = hours + minutes / 60;

    //  if ((this.PrefromTime < this.toTime) && (this.PretoTime < this.toTime) && this.rosterassignmentModel.assignmentId == 0 ) {
    //    this.hint3 = "";
    //    if (this.fromTime >= this.toTime) {
    //      this.hint1 = "End Time Should be greater than Start Time";
    //    }
    //    else {
    //      this.hint1 = "";
    //    }
    //  } else {
    //    this.hint3 = "End Time Should be greater than previous assignment";       
    //  }
    
    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex + 1].fromTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.NextfromTime = hours + minutes / 60;

    //  var hoursMinutes = therosterAssignmentsModel[this.editIndex + 1].toTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.NexttoTime = hours + minutes / 60;

    //  if ((this.NextfromTime > this.toTime) && (this.NexttoTime > this.toTime) && this.rosterassignmentModel.assignmentId == 0) {
    //    this.hint1 = "";
    //    if (this.fromTime >= this.toTime) {
    //      this.hint1 = "End Time Should be greater than Start Time";
    //    } else {
    //      this.hint1 = "";
    //    }
    //  } else {
    //    this.hint1 = "End Time Should be lesser than next assignment";       
    //  }
    //}
    //else if (therosterAssignmentsModel.length > 0) {
    //  var hoursMinutes = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].fromTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PrefromTime = hours + minutes / 60;

    //  var hoursMinutes = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].toTime.split(/[.:]/);
    //  var hours = parseInt(hoursMinutes[0], 10);
    //  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //  this.PretoTime = hours + minutes / 60;

    //  if ((this.PrefromTime < this.fromTime) && (this.PretoTime < this.fromTime) && (this.toTime > this.fromTime)) {
    //    this.hint1 = "";
    //    if (this.fromTime >= this.toTime) {
    //      this.hint1 = "End Time Should be greater than Start Time";
    //    } else {
    //      this.hint1 = "";
    //    }
    //  } else {
    //    this.hint1 = "End Time Should be greater than previous assignment";
    //  }
    //} else if (therosterAssignmentsModel.length == 0) {
    //  if (this.toTime <= this.fromTime) {
    //    this.hint1 = "End Time Should be greater than Start Time";
    //  } else {
    //    this.hint1 = "";
    //  }     
    //}
    if (this.hint3 == undefined) {
      this.hint3 = "";
    }
    if (this.hint == undefined) {
      this.hint = "";
    }
    if (this.hint1 == undefined) {
      this.hint = "";
    }
    //if (this.hint2 == undefined) {
    //  this.hint = "";
    //}

    console.log("hint 1", this.hint1);
    console.log("hint 3",this.hint3);
  }

  gridPageAndSort() {
    this.assignmentModelDB.paginator = this.paginator;
    this.assignmentModelDB.sort = this.sort;
  }

  update() {
    //this.addAssignment();
    debugger;
    this.rosterassignmentModel.assignment = this.assignment;
    this.rosterassignmentModel.position = this.position;
    this.rosterassignmentModel.unit = this.unit;
    this.rosterassignmentModel.activityCode = this.activityCodeVar;
    this.rosterassignmentModel.notes = this.notes;
    this.rosterassignmentModel.fromTime = this.fromTimeVar;
    this.rosterassignmentModel.toTime = this.toTimeVar;
    this.rosterassignmentModel.startDate = this.startDateVar;
    this.rosterassignmentModel.endDate = this.endDateVar;
    this.rosterassignmentModel.isActive = true;
    this.rosterAssignmentsModel[this.index] = this.rosterassignmentModel;
    this.ResetAssignment();
    //this.hint2 = "";
    this.OnEdit = false;
    this.updatebtn = false;
    this.resetbtn = true;
    this.addassbtn = false;
  }


  editAssignment(Id: number) {
    debugger;
    this.updatebtn = true;
    let therosterAssignmentsModel: RosterAssignment[] = [];
    for (var i = 0; i < Id; i++) {
      if (this.rosterAssignmentsModel[i].isActive == false) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
      this.editIndex = Id - therosterAssignmentsModel.length;
    }
   // this.editIndex = Id;
    this.OnEdit = true;
    //const index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId == Id);
    const index = Id;
    this.index = index;
    if (index >= 0) {
      this.rosterassignmentModel = this.rosterAssignmentsModel[index];
      this.assignment = this.rosterassignmentModel.assignment;
      this.position = this.rosterassignmentModel.position;
      this.unit = this.rosterassignmentModel.unit;
      this.activityCodeVar = this.rosterassignmentModel.activityCode;
      this.notes = this.rosterassignmentModel.notes;
      this.fromTimeVar = this.rosterassignmentModel.fromTime;
      this.toTimeVar = this.rosterassignmentModel.toTime;
      this.startDateVar = this.rosterassignmentModel.startDate;
      this.endDateVar = this.rosterassignmentModel.endDate;
      //this.isActive = true;
      //this.rosterAssignmentsModel.push(this.rosterassignmentModel);

      //let therosterAssignmentsModel: RosterAssignment;
      //therosterAssignmentsModel = this.rosterAssignmentsModel[index];
      //this.rosterassignmentModel = therosterAssignmentsModel;
    }
    console.log(index);
    this.updatebtn = true;
    this.resetbtn = true;
    this.addassbtn = false;
    return false;
  }

  deleteAssignment(Id: number) {
    debugger;
    //const index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId == Id);
    this.rosterAssignmentsModel;
    const index = Id;
    if (index >= 0) {
      this.rosterAssignmentsModel[index].isActive = false;
    }
    else {
      this.rosterAssignmentsModel[index].position = 0;
      this.rosterAssignmentsModel[index].unit = 0;
      this.rosterAssignmentsModel[index].assignment = null;
      this.rosterAssignmentsModel[index].activityCode = 0;
      this.rosterAssignmentsModel[index].fromTime = null;
      this.rosterAssignmentsModel[index].toTime = null;
      this.rosterAssignmentsModel[index].notes = "";
    }
    console.log(index);
    return false;
  }



  RefreshGrid() {
    this.changeDetectorRefs.detectChanges();
  }

  onPersonnelChange() {
    // this.assignmentEmployee = this.personnelControl.nativeElement.value;
  }

  resetDate1() {
    debugger;
    this.stRosterModel.shiftDate = null;
  }

  PersonnelEvent(event: string) {
    debugger;
    console.log("personnel select", event);
    this.assignmentEmployeeName = event;
    // this.assignment = event.source._mostRecentViewValue;
    //console.log("personnel select 11", this.assignment);
  }

  public updateStRoster() {
    debugger;
    this.stRosterModel.rosterAssignments = this.rosterAssignmentsModel;
    // this.stRosterModel.personnel = this.employeeModel.;
    this.stRosterId = this.stRosterModel.stRosterId;
    if (this.stRosterId > 0) {
      // this.stRosterService.delRoster('delRoster', this.stRosterId).subscribe(res => {
      // this.stRosterService.saveStRoster('saveStRoster', this.stRosterModel).subscribe(res => {
      this.stRosterService.updateRoster(this.stRosterId, this.stRosterModel).subscribe(res => {
        debugger;
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
            this.ResetRoster();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
          });
        }
      });
      //});
    }
    else {
      this.stRosterService.saveStRoster('saveStRoster', this.stRosterModel).subscribe(res => {
        //debugger;
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
            this.ResetRoster();
          });
        }
        else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
          });
        }
      });
    }

  }


  show() {
    //debugger;
    if (this.showEndDate == true) {
      this.showEndDate = false;
      // this.toDate = null;
      //this.stRosterModel.toDate = null;
    }
    else {
      this.showEndDate = true;

    }
  }



  ResetAssignment() {
    this.assignment = 0;
    this.position = 0;
    this.unit = 0;
    this.activityCodeVar = 0;
    this.notes = '';
    this.fromTimeVar = '';
    this.toTimeVar = '';
    this.startDateVar = null;
    this.endDateVar = null;
    this.resetOption1 = true;
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.hint = "";
    this.hint1 = "";
  //  this.hint2 = "";
    this.datehint = "";

  }

  ResetRoster() {
    this.fromDate = null;
    this.resetOption = true;
    this.stRosterForm.resetForm();
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.stRosterModel = this.initializeStRosterModel();
    this.rosterAssignmentsModel = [];
    this.showEndDate == false;
  }

  GoBack() {
    this.routerService.navigate(['/stroasterreport']);
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllUnit(),
      this.systemCodeService.getAllAssignment(),
      this.systemCodeService.getAllStations(),
      this.systemCodeService.getAllActivityCode(),
      //this.systemCodeService.getSystemCodesForRanks()
      this.systemCodeService.getAllPositions()

    ).subscribe(([res1, res2, res3, res4, res5, res6, res7]) => {
      this.personnelCode = res1 || [];
      this.crewCode = res2 || [];
      this.unitCode = res3 || [];
      this.assignmentCode = res4 || [];
      this.stationCode = res5 || [];
      this.activityCode = res6 || [];
      this.positionsCode = res7 || [];

      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.unitKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.assignmentKey = res4.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res6.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.positionKey = res7.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });

  }

}

