import { NgModule } from '@angular/core';
import {
  MatFormFieldModule, MatInputModule, MatSelectModule,
  MatButtonModule, MatSlideToggleModule, MatDatepickerModule,
  MatNativeDateModule, MatTabsModule, MatDialogModule,
  MatIconModule, MatCheckboxModule, MatTableModule, MatCardModule, MatTooltipModule, MatPaginatorModule, MatProgressSpinnerModule,
  MatSidenavModule, MatSliderModule, MatMenuModule, MatListModule, MatAutocompleteModule, MatRadioModule
} from '@angular/material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
const MaterialComponents = [
  MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule,
  MatTabsModule, MatDialogModule, MatIconModule, MatCheckboxModule, MatTableModule, MatCardModule, MatTooltipModule, MatPaginatorModule,
  MatProgressSpinnerModule, MatSidenavModule, MatSliderModule, MatMenuModule, MatListModule, MatAutocompleteModule, NgxMaterialTimepickerModule, MatRadioModule
];

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents]
})
export class AngularMaterialModule { }
