import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator, MatSort, MatDialog } from "@angular/material";
import { RolePermissionCheck } from "../models/role-permission-check.model";
import { ActivatedRoute, Router } from "@angular/router";
import { SystemCodeService } from "../services/system-code.service";
import { AuthService } from "../services/auth.service";
import { RosterAssignment } from "../models/rosterassignment.model";
import { forkJoin } from "rxjs";
import { SystemCodeKeyValue } from "../models/systemcodekeyvalue.model";
import { SystemCode } from "../models/system-codes.model";
import { DailyRoasterService } from "../services/dailyroaster.service";
import { Employee } from "../models/employee.model";
import { EmployeeService } from "../services/employee.service";
import { DailyRoasterAssignment } from "../models/dailyroasterassignment.model";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { DailyRoasterAssignmentNew } from "../models/dailyroasterassignmentNew.model";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AngularCsv } from 'angular7-csv';
@Component({
  selector: 'tradetimeviewsheet',
  styleUrls: ['./genaprintreport.component.css'],
  templateUrl: './tradetimeviewsheet.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class TradeTimeViewComponent {

  @ViewChild('timesheetForm', { static: false }) timesheetForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  rolePermissionCheckModel: RolePermissionCheck;
  rosterassignmentModel: RosterAssignment;
  rosterAssignmentObj: RosterAssignment[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];
  personnelCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  employeeModelObj: Employee[] = [];
  employeeModelObj1: Employee[] = [];
  startDate: Date;
  dailyRoasterObj: DailyRoasterAssignmentNew[] = [];
  revisedAssignments: DailyRoasterAssignmentNew[] = [];
  activityCodeName: string = "";
  personelName: string = "";
  assignmentName: string = "";
  datehint: string = "";
  datehint1: string = "";
  fromDate: Date;
  toDate: Date;
  activityCodeVar: number[] = [];
  positionVar: number[] = [];
  stationVar: number[] = [];
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private dialog: MatDialog,
    private systemCodeService: SystemCodeService, private dailyRoaster: DailyRoasterService,
    private authService: AuthService, private activatedRoute: ActivatedRoute, private routerService: Router, private employeeService: EmployeeService, private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isRead || this.rolePermissionCheckModel.isPrint)) {
      this.rosterassignmentModel = this.initializeRosterAssignmentModel();
      setTimeout(() => { this.loadData(); }, 1000);
      this.loadDropDowns();
      this.defaultValues();
      this.loadPersonnel();

    }
  }
  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: null, fromTime: null, toTime: null, startDate: null, endDate: null,
      isActive: true, stRosterId: 0, station: null
    } as RosterAssignment;
  }
  timesheetReport() {

  }

  defaultValues() {
    for (let i = 0; i < this.activityCodeKey.length; i++) {
      if (this.activityCodeKey[i].value == "Trade Shift Off") {
        this.rosterassignmentModel.activityCode = this.activityCodeKey[i].key;
      }
    }
    this.rosterassignmentModel.startDate = new Date();

    let endDate = new Date();
    var edate = new Date();
    endDate.setDate(edate.getDate() + 7);
    this.rosterassignmentModel.endDate = endDate;
    this.toDate = this.rosterassignmentModel.endDate;
    this.fromDate = this.rosterassignmentModel.startDate;
  }


  //onSelectionChangedEmployee(sta) {


  //}

  dateevent1(fromDate: Date) {
    this.fromDate = fromDate;
   
    if (this.toDate == null) {
      this.datehint1 = "Please Enter End Date";
    } else {
      this.datehint1 = "";
    }
    //if (this.eventCalendarId > 0) {
    //  this.toDate = this.eventCalendarModel.toDate;
    //}
    this.datehint = "";
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    this.rosterassignmentModel.startDate = fromDate;
  }

  dateevent2(toDateev: Date) {
    this.toDate = toDateev;
   
    if (this.fromDate == null) {
      this.datehint = "Please Enter Start Date";
    } else {
      this.datehint1 = "";
    }
    //if (this.eventCalendarId > 0) {
    //  this.fromDate = this.eventCalendarModel.fromDate;
    //}
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    this.rosterassignmentModel.endDate = toDateev;
  }


  public loadDropDowns() {

    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getAllActivityCode()
    ).subscribe(([res1, res2]) => {
      // this.personnelCode = res1 || [];
      this.activityCode = res2 || [];

      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      for (let i = 0; i < this.activityCodeKey.length; i++) {
        if (this.activityCodeKey[i].value == "Trade Shift Off") {
          this.rosterassignmentModel.activityCode = this.activityCodeKey[i].key;
        }
      }
      this.defaultValues();
    });



  }
  public loadPersonnel() {

    this.employeeService.getEmployeesBetweenDates("getEmployeesBetweenDates", this.getDateAsString(this.rosterassignmentModel.startDate), this.getDateAsString(this.rosterassignmentModel.endDate)).subscribe(
      res => {
        this.employeeModelObj = res;
      });
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      this.employeeModelObj1 = res;
    });
    //this.employeeService.getEmployeesBetweenDates("getEmployeesBetweenDates", this.getDateAsString(this.rosterassignmentModel.startDate), this.getDateAsString(this.rosterassignmentModel.endDate)).subscribe(
    //  res => {
    //    this.employeeModelObj = res;
    //  });
  }
  search() {
    this.loadData();
  }
  loadData() {
    this.blockUI.start();
    this.revisedAssignments = [];
    let actCodes = this.activityCodeVar.join();
    let positionCodes = this.positionVar.join();
    let stationCodes = this.stationVar.join();
    this.dailyRoaster.getTradeTimeView("getTradeTimeView", this.rosterassignmentModel.startDate,
      this.rosterassignmentModel.endDate,
      this.activityCodeVar.length ? actCodes : "",
      this.positionVar.length ? positionCodes : "",
      this.stationVar.length ? stationCodes : ""
      //this.rosterassignmentModel.activityCode ? this.rosterassignmentModel.activityCode : 0,
      //this.rosterassignmentModel.position ? this.rosterassignmentModel.position : 0,
      //this.rosterassignmentModel.station ? this.rosterassignmentModel.station : 0
    ).subscribe(res => {
        this.dailyRoasterObj = res;
      this.getFormattedTable(this.dailyRoasterObj);
      let activityCodeNames = "";
      let personalNames = "";
      let assignmentName = "";
      for (let x = 0; x < this.activityCodeVar.length; x++) {
        let itemIndex = this.activityCode.findIndex(z => z.systemCodeId == this.activityCodeVar[x]);
        if (itemIndex >= 0) {
          let comma = ", ";
          if (x == this.activityCodeVar.length - 1) {
            comma = " ";
          }
          activityCodeNames = activityCodeNames + this.activityCode[itemIndex].type + comma;
        }
      }
      this.activityCodeName = activityCodeNames;
      for (let x = 0; x < this.positionVar.length; x++) {
        let itemIndex = this.employeeModelObj.findIndex(z => z.employeeId == this.positionVar[x])
        if (itemIndex >= 0) {
          let comma = ", ";
          if (x == this.positionVar.length - 1) {
            comma = " ";
          }
          personalNames = personalNames + this.employeeModelObj[itemIndex].lastName + " " + this.employeeModelObj[itemIndex].firstName + comma;
        } 
      }
      this.personelName=personalNames;
      for (let x = 0; x < this.stationVar.length; x++) {
        let itemIndex = this.employeeModelObj1.findIndex(z => z.employeeId == this.stationVar[x]);
        if (itemIndex >= 0) {
          let comma = ", ";
          if (x==this.stationVar.length-1) {
          comma = " ";
          }
          //else {
          //  comma = " ";
          //}
          assignmentName = assignmentName + this.employeeModelObj1[itemIndex].lastName + " " + this.employeeModelObj1[itemIndex].firstName + comma;
        }
      }
      this.assignmentName=assignmentName;
        //const activityIndex = this.activityCodeKey.findIndex(x => x.key == this.rosterassignmentModel.activityCode);
        //if (activityIndex >= 0) {
        //  this.activityCodeName = this.activityCodeKey[activityIndex].value;
        //}
        //else {
        //  this.activityCodeName = "";
        //}
        //const positionIndex1 = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.station);
        //if (positionIndex1 >= 0) {
        //  this.assignmentName = this.employeeModelObj[positionIndex1].lastName + ", " + this.employeeModelObj[positionIndex1].miName + " "
        //    + this.employeeModelObj[positionIndex1].firstName;
        //}
        //else {
        //  this.assignmentName = "";
        //}

        //const positionIndex = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.position);
        //if (positionIndex >= 0) {
        //  this.personelName = this.employeeModelObj[positionIndex].lastName + ", " + this.employeeModelObj[positionIndex].miName + " "
        //    + this.employeeModelObj[positionIndex].firstName;
        //}
        //else {
        //  this.personelName = "";
        //}
        // this.printReport();
      }, (error: any) => {
        this.revisedAssignments = [];
        this.blockUI.stop();
      });
    this.blockUI.stop();
  }

  exporttrdaereportasCSV() {
    setTimeout(() => {
      this.printCSV();
      this.exportToCSV();
    }, 1000);
   this.loadData();
   
  }
  printCSV() {
    this.blockUI.stop();
    setTimeout(() => {
      let title = "Trade Time View Report";
      let count: number = 0;
      let xAxis: number = 15;
      let yAxis: number = 30;
      let alignment: number = 0;
      let startYVariable = 40;
      var doc = new jsPDF(); /*'l', 'pt'*/
      doc.setProperties({
        title: 'Trade Time View Report'
      });
      var totalPagesExp = '{total_pages_count_string}';
      doc.setTextColor(40)
      doc.setFontSize(16)
      var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(title, textOffset, 20)
      doc.setFontSize(10)

      let startDate = 'Start Date: ';
      if (this.rosterassignmentModel.startDate) {
        let startDateString = new Date(this.rosterassignmentModel.startDate);
        let startDateFormat = this.getDateAsString(startDateString);
        startDate = startDate + startDateFormat;
      }
      let endDate = 'End Date: ';
      if (this.rosterassignmentModel.endDate) {
        let endDateString = new Date(this.rosterassignmentModel.endDate);
        let endDateFormat = this.getDateAsString(endDateString);
        endDate = endDate + endDateFormat;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;
      
      let Activity = 'Activity Code:  ';      
      let Personnel = 'Personnel: ';      
      Activity = Activity + this.activityCodeName;
      Personnel = Personnel + this.personelName;
      let textCrewStation = Activity + '   ' + Personnel;
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      let category = 'Traded With: ';      
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(this.assignmentName, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      if (!this.checkPrintReportFilters()) {
        startYVariable = 50;
      } else {
        startYVariable = yAxis + 10;
      }
      var currentDate = new Date();
      var currentDateString = this.getDateAsString(currentDate);

      autoTable(doc, {
        rowPageBreak: 'avoid',
        headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
        bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
        head: this.getHeadRows(),
        body: this.getBody(),
        didDrawPage: function (data) {
          var str = 'Page ' + doc.getNumberOfPages()
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(9)

          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, 180, pageHeight - 10);

          let currentDate = new Date();
          let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
          let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
          let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
          doc.text(currentDateString, 15, pageHeight - 10);
        },
        startY: yAxis
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
    //  window.open(URL.createObjectURL(doc.output("blob")));
    }, 1000);
    this.loadData();
  }
  exportToCSV() {
  //  this.loadData();

    let rowCount = this.dailyRoasterObj.length;
    var data = []

    data.push({
      shiftdate: 'Shift Date', personnel: 'Personnel', activityCode: 'Activity Code',assignment: 'Traded with', hours: 'Hours'
    });
   // alert("test");    
    for (var j = 0; j < rowCount; j++) {
    //  if (this.dailyRoasterObj[j].type == 'row') {
        data.push({
          shiftdate: this.dailyRoasterObj[j].shiftDate,
          personnel: this.dailyRoasterObj[j].personal,         
          activityCode: (this.dailyRoasterObj[j].activity) ? this.dailyRoasterObj[j].activity : '',
          assignment: this.dailyRoasterObj[j].assignment,          
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      //}
      //else if (this.dailyRoasterObj[j].type == 'empty') {
      //  data.push({
      //    shiftdate: '',
      //    personnel: this.dailyRoasterObj[j].personal,
      //    activityCode: '',
      //    assignment: '',          
      //    hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
      //  });
      //}
      //else {
      //  data.push({
      //    shiftdate: '',
      //    personnel: '',
      //    activityCode: '',
      //    assignment: '        11Total Hours -',
      //    hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
      //  });
      //}
    }
    setTimeout(() => { new AngularCsv(data, 'Trade Time Report'); }, 500);
    
    //return data;

  }
  shiftDateChange(dt: any) {
    this.rosterassignmentModel.startDate = dt;
    this.loadPersonnel();
  }
  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
    var m = minutes % 60;
    let h1 = h < 10 ? '0' + h : h;
    let m1 = m < 10 ? '0' + m : m;
    return h1 + ':' + m1;
  }

  getFormattedTable(assignments: DailyRoasterAssignmentNew[]) {
    let lastName = "";
    let personelMins = 0;
    for (let x = 0; x < assignments.length; x++) {

      if (lastName != assignments[x].personal) {
        if (lastName != "") {
          let objdata = this.InitializeModel();
          objdata.hours = personelMins.toString();
          objdata.type = "data";
          this.revisedAssignments.push(objdata);
          personelMins = 0;
        }
      }
      personelMins = personelMins + parseInt(assignments[x].hours);


      if (lastName != assignments[x].personal) {
        let obj1 = this.InitializeModel();
        obj1.personal = assignments[x].personal;
        obj1.type = "header";
        this.revisedAssignments.push(obj1);
        lastName = obj1.personal;
      }

      let obj = this.InitializeModel();
      obj.personal = assignments[x].personal;
      obj.type = "row";
      obj.crew = assignments[x].crew;
      obj.activity = assignments[x].activity;
      obj.hours = assignments[x].hours;
      obj.startDate = assignments[x].startDate;
      obj.endDate = assignments[x].endDate;
      obj.station = assignments[x].station;
      obj.unit = assignments[x].unit;
      obj.assignment = assignments[x].assignment;
      obj.shiftDate = assignments[x].shiftDate;
      this.revisedAssignments.push(obj);

    }
    if (personelMins >= 0) {
      let objdata = this.InitializeModel();
      objdata.hours = personelMins.toString();
      objdata.type = "data";
      this.revisedAssignments.push(objdata);
    }
  }
  InitializeModel(): DailyRoasterAssignmentNew {
    return {
      roasterId: "",
      personal: "",
      station: "",
      position: "",
      unit: "",
      activity: "",
      startDate: "",
      endDate: "",
      hours: "",
      type: "", crew: "", assignment: "", shiftDate: ""
    }
  }
  resetTheForm() {
    this.blockUI.start();

    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.rosterassignmentModel.startDate = new Date();

    let endDate = new Date();
    var edate = new Date();
    endDate.setDate(edate.getDate() + 7);
    this.rosterassignmentModel.endDate = endDate;
    this.rosterassignmentModel.activityCode = 0;
    this.rosterassignmentModel.position = 0;
    this.defaultValues();
    this.activityCodeName = "";
    this.personelName = "";
    this.activityCodeVar = [];
    this.positionVar = [];
    this.stationVar = [];
    this.loadPersonnel();
    this.loadData();
    this.blockUI.stop();
  }
  printReport() {
    this.blockUI.stop();
    setTimeout(() => {
      let title = "Trade Time View Report";
      let count: number = 0;
      let xAxis: number = 15;
      let yAxis: number = 30;
      let alignment: number = 0;
      let startYVariable = 40;
      var doc = new jsPDF(); /*'l', 'pt'*/
      doc.setProperties({
        title: 'Trade Time View Report'
      });
      var totalPagesExp = '{total_pages_count_string}';
      doc.setTextColor(40)
      doc.setFontSize(16)
      var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(title, textOffset, 20)
      doc.setFontSize(10)


      let startDate = 'Start Date: ';
      if (this.rosterassignmentModel.startDate) {
        let startDateString = new Date(this.rosterassignmentModel.startDate);
        let startDateFormat = this.getDateAsString(startDateString);
        startDate = startDate + startDateFormat;
      }
      let endDate = 'End Date: ';
      if (this.rosterassignmentModel.endDate) {
        let endDateString = new Date(this.rosterassignmentModel.endDate);
        let endDateFormat = this.getDateAsString(endDateString);
        endDate = endDate + endDateFormat;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;
      
      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Start Date: ' + this.getDateAsString(this.rosterassignmentModel.startDate), pageWidth - 35)      
      //doc.text(text, xAxis + 300, yAxis - 14)
      //count++;     

      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('End Date: ' + this.getDateAsString(this.rosterassignmentModel.endDate), pageWidth - 35)      
      //doc.text(text, xAxis + 450, yAxis - 14)
      //count++;

      let Activity = 'Activity Code:  ';
      //let crewEntity = this.crew.find(x => x.systemCodeId == this.emstrainingObj.crew);
      //if (crewEntity) {
      //  crew = crew + crewEntity.type;
      //}
      let Personnel = 'Personnel: ';
      //let stationEntity = this.station.find(x => x.systemCodeId == this.emstrainingObj.station);
      //if (stationEntity) {
      //  station = station + stationEntity.type;
      //}
     // Activity = Activity + this.activityCodeName;
      Activity = Activity + this.activityCodeName.substring(0, this.activityCodeName.length - 1);
      Personnel = Personnel + this.personelName.substring(0, this.personelName.length - 1);
      let textCrewStation = Activity + '   ' + Personnel;
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;
                 
      //if (count == 1) {
      //  xAxis = xAxis + 280;
      //  count = 0;
      //}
      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Activity Code: ' + this.activityCodeName, pageWidth - 35)      
      //doc.text(text, xAxis + 110, yAxis)
      //count++;
      
      //if (count == 1) {
      //  xAxis = xAxis + 280;
      //  count = 0;
      //}
      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Personnel: ' + this.personelName, pageWidth - 35)      
      //doc.text(text, xAxis + 350, yAxis)
      //count++;
      let category = 'Traded With: ';
      //let categoryEntity = this.emsCategory.find(x => x.systemCodeId == this.emstrainingObj.category);
      //if (categoryEntity) {
      //  category = category + categoryEntity.type;
      //}
      category = category + this.assignmentName.substring(0, this.assignmentName.length - 1);
      var pageSize = doc.internal.pageSize;
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(category, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      //if (count == 1) {
      //  xAxis = xAxis + 280;
      //  count = 0;
      //}
      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Traded With: ' + this.assignmentName, pageWidth - 35)      
      //doc.text(text, xAxis + 550, yAxis)
      //count++;



      if (!this.checkPrintReportFilters()) {
        startYVariable = 50;
      } else {
        startYVariable = yAxis + 10;
      }
      var currentDate = new Date();
      var currentDateString = this.getDateAsString(currentDate);

      autoTable(doc, {
        rowPageBreak: 'avoid',
        headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
        bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
        head: this.getHeadRows(),
        body: this.getBody(),
        didDrawPage: function (data) {
          var str = 'Page ' + doc.getNumberOfPages()
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(9)

          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, 180, pageHeight - 10);

          let currentDate = new Date();
          let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
          let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
          let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
          doc.text(currentDateString, 15, pageHeight - 10);
        },
        startY: yAxis
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      window.open(URL.createObjectURL(doc.output("blob")));
    }, 1200);
    this.loadData();
  }


  getHeadRows() {
    return [
      {
        shiftDate: 'Shift Date', personnel: 'Personnel', activityCode: 'Activity Code', assignment: 'Assignment', hours: 'Hours',

      }
    ]
  }

  getBody() {
    let rowCount = this.revisedAssignments.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {


      if (this.revisedAssignments[j].type == 'row') {
        body.push({
          shiftDate: this.revisedAssignments[j].shiftDate,
          personnel: this.revisedAssignments[j].personal,
          activityCode: (this.revisedAssignments[j].activity) ? this.revisedAssignments[j].activity : '',
          assignment: this.revisedAssignments[j].assignment,
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) + "" : '',
        });
      }


    }
    return body;
  }
  checkPrintReportFilters() {
    if (this.rosterassignmentModel.startDate == null) {
      return true;
    }
    return false;
  }

}
