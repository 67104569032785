import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { vacationpics } from '../models/vacationpics.model';
import { ErrorService } from './error.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class VacationPicsService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
)
  {
    super('api/VacationPics/', httpClient, errorService, authService);
  }

  saveVacationPics(action: string, vacationPicsObj: vacationpics): Observable<any> {
    return this.postRequest(action, JSON.stringify(vacationPicsObj));
  }

  getVacationPics(action: string, vacationPicsId: Number) {
    const params = new HttpParams()
      .set('vacationPicsId', vacationPicsId.toString());

    return this.customGetRequestWithQueryString<Array<vacationpics>>(action, params);
  }

}
