import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { RosterAssignment } from '../models/rosterassignment.model';
import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class RosterAssignmentService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/RosterAssignment/', httpClient, errorService, authService);
  }

  getAllAssignment(): Observable<Array<RosterAssignment>> {
    return this.customGetRequest<Array<RosterAssignment>>('getAllAssignment', null);
  }

  getAssignment(action: string, assignmentId: number): Observable<any> {

    return this.customGetRequest<RosterAssignment>(action, assignmentId.toString());
  } 

  saveRosterAssignment(action: string, rosterassignmentObj: RosterAssignment): Observable<any> {
    return this.postRequest(action, JSON.stringify(rosterassignmentObj));
  } 

  loadAssignments(action: string): Observable<Array<string>> {
    return this.customGetRequest<Array<string>>(action, null);
  }


}
