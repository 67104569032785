import { Component, ViewChild, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { SystemCodeService } from '../services/system-code.service';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { StRoster } from '../models/stroster.model';
import { StRosterService } from '../services/stroster.service';
import { RosterAssignment } from '../models/rosterassignment.model';
import { RosterAssignmentService } from '../services/rosterassignment.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee.model';
import { forkJoin, Observable, of } from 'rxjs';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { error } from '@angular/compiler/src/util';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllStRoster } from '../models/get-all-stRoster.model';
import { RosterAssignmentDialogContent } from '../dailyroaster/dailyroasterassignment.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DailyRoasterService } from '../services/dailyroaster.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DailyRoasterAssignment } from '../models/dailyroasterassignment.model';
import { getDate } from 'date-fns';
import { AngularCsv } from 'angular7-csv';
@Component({
  selector: 'stroasterreport',
  templateUrl: './stroasterreport.component.html',
  styleUrls: ['./stroasterreport.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class StRoasterReportComponent {

  @ViewChild('strosterForm', { static: false }) strosterForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<RosterAssignment>>;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  container: NgForm;
  id: number;
  showdiv: boolean = true;
  rosterId: number;
  stRosterId: number;
  showNewButton: boolean = false;
  isFound: boolean = false;
  assignmentEmployeeName: string;
  floatLabel: string = 'never';
  resultsLength: number = 0;
  allEmployeeObj: Employee[] = [];
  employeeModel: Employee[];
  employeeModels: Employee;
  employee: number = null;
  rosterModel: StRoster[] = [];
  assignmentModel: RosterAssignment[] = [];
  stRosterModel: StRoster;
  stRosterModels: StRoster;
  rosterassignmentModel: RosterAssignment;
  personnelCode: SystemCode[] = [];
  stationCode: SystemCode[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  stationKey: SystemCodeKeyValue[] = [];
  assignmentModelDB = new MatTableDataSource<StRoster>([]);
  dataSource: MatTableDataSource<StRoster>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  @BlockUI() blockUI: NgBlockUI;
  isLargeScreen: boolean = false;
  errMsg: string;
  isViewReport: boolean = false;
  IterateStRosterData: StRoster[] = [];
  stationString: string;
  displayedColumns: any[] = ['shiftDate', 'employeeName', 'station', 'position', 'unit', 'crewName', 'hours' ];
  items: number[] = [];
  expandedElement: StRoster | null;
  StRosterData: StRoster[] = [];
  STROSTERS: StRoster[];
  rolePermissionCheckModel: RolePermissionCheck;
  dailyRoasterObj: DailyRoasterAssignment[] = [];

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private stRosterService: StRosterService,
    private RosterAssignmentService: RosterAssignmentService, private employeeService: EmployeeService, private dailyRoasterService: DailyRoasterService,
    private cd: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.loadDropDowns();
      // this.loadGrid();
      this.GridSortAndPage();
      this.loadEmployee();
      // this.stRosterModel = this.initializestRosterModel();
      this.rosterassignmentModel = this.initializerosterassignmentModel();
      this.stRosterModels = this.initializestRosterModel();
      this.stRosterModels.shiftDate = new Date();
      this.employeeModels = this.initializeemployeeModels();
      this.dataSource = new MatTableDataSource(this.StRosterData);
     // this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      //const sortState: Sort = { active: 'personnel', direction: 'desc' };
      //this.sort.active = sortState.active;
      //this.sort.direction = sortState.direction;
      //this.sort.sortChange.emit(sortState);
    }
  }

  initializeActionsForDisplayedColumns(): void {
    //if (this.rolePermissionCheckModel.isDelete) {
    //  this.displayedColumns.push('delete');
    //  this.columnsToDisplay.push('delete');
    //  this.innerDisplayedColumns.push('delete');
    //}
    //if (this.rolePermissionCheckModel.isRead) {
    //  this.displayedColumns.push('view');
    //  this.columnsToDisplay.push('view');
    //  this.innerDisplayedColumns.push('view');
    //}
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('stRosterId');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
   
  }

  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //this.blockUI.start();
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.getAllStRoster(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            // this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
         // this.STROSTERS = data || [];
          this.StRosterData = data || [];
          this.dataSource = new MatTableDataSource(this.StRosterData);
          this.dataSource.sort = this.sort;
          //this.IterateStRosterData = this.StRosterData.reduce((current, next) => {
          //  next.rosterAssignments.forEach(b => {
          //    current.push({ personnel: next.personnel, crew: next.crew, rosterAssignments: b })
          //  });
          //  return current;
          //}, []);
         // this.dataSource = new MatTableDataSource(this.IterateStRosterData);
        //  this.dataSource.sort = this.sort;
         
         // this.GridSortAndPage();
          //this.blockUI.stop();
        });
    }
  }

  getAllStRoster(pageIndex: number, pageSize: number): Observable<GetAllStRoster> {
    //return this.stRosterService.getAllStRosterNew(
    //  'GetStRosterNew',
    //  (this.stRosterModels.shiftDate != null) ? this.stRosterModels.shiftDate : null,
    //  (this.stRosterModels.personnel) ? this.stRosterModels.personnel : 0,
    ////  (this.stRosterModels.station) ? this.stRosterModels.station : 0,
    //  pageIndex,
    //  pageSize
    //);
    return this.dailyRoasterService.getDailyRoasters(
      'getDailyRoastersForReport',
      (this.stRosterModels.shiftDate) ? this.stRosterModels.shiftDate : null,
      0, (this.stRosterModels.crew) ? this.stRosterModels.crew : 0,
      (this.employee) ? this.employee : 0,
      pageIndex,
      pageSize,
      this.getDateAsString(this.stRosterModels.shiftDate)
    );
    //(this.employee) ? this.employee : 0,
  }

  getAllStRosterForDataSource(): void {
    this.getAllStRoster(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          // this.blockUI.stop();
          return of([]);
        })
    ).subscribe(data => {
        this.STROSTERS = data || [];
      this.StRosterData = data || [];
      //for (var i = 0; i < this.StRosterData.length; i++) {
      //  this.StRosterData[i].hours = this.StRosterData[i].hours ? this.convertMinsToHrsMins(parseInt(this.StRosterData[j].hours)) + " " : '';
      //}
        this.dataSource = new MatTableDataSource(this.StRosterData);
        this.dataSource.sort = this.sort;
        //this.blockUI.stop();
      });
  }

  /*********************************** */

  initializestRosterModel(): StRoster {
    return {
      stRosterId: 0, shiftDate: null, personnel: 0, crew: 0, rosterAssignments: null, crewName: null, employeeName: null
    } as StRoster;
  } //fromDate: null, toDate: null, endDateCheck: false,

  initializerosterassignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, fromTime: null, toTime: null, notes: "", assignment: 0, position: 0,
      unit: 0, activityCode: 0, isActive: false, stRosterId: 0
    } as RosterAssignment;
  }
  initializeemployeeModels(): Employee {
    return {
      employeeId: 0, lastName: null, miName: null, firstName: null, rank: null, certification: null, ffnNumber: null, kemsisNumber: null,
      dateOfBirth: null, address: null, city: null, state: null, zipcode: null, email: null, phone: null, drivingLicense: null, startDate: null,
      shift: null, payGrade: null, emergencyContact: null, emergencyContactNumber: null, emergencySecContact: null, emergencySecContactNumber: null,
      contactNotes: null, employeeAward: null, certifications: null, rankName: null
    } as Employee;
  }

 
  loadGrid() {
    this.stRosterService.getAllRoster().subscribe(
      (res: Array<StRoster>) => {
        this.rosterModel = res || [];
        this.STROSTERS = res || [];
       // this.grouping();
        //let STROSTERS: StRoster[] = res || [];
        this.assignmentModelDB.data = this.rosterModel;
        this.rosterId = this.stRosterId;
        this.GridSortAndPage();
      }, (error: any) => {
      });

    this.GridSortAndPage();

  }


  /****************************** */
  //grouping() {
  //  this.STROSTERS.forEach(roster => {
  //    if (roster.rosterAssignments && Array.isArray(roster.rosterAssignments) && roster.rosterAssignments.length) {
  //      this.StRosterData = [...this.StRosterData, { ...roster, rosterAssignments: new MatTableDataSource(roster.rosterAssignments) }];
  //    } else {
  //      this.StRosterData = [...this.StRosterData, roster];
  //    }
  //  });
  //  this.dataSource = new MatTableDataSource(this.StRosterData);
  //  this.dataSource.sort = this.sort;
  //  this.dataSource.paginator = this.paginator;
  //}

  //toggleRow(element: StRoster) {
  //  element.rosterAssignments && (element.rosterAssignments as MatTableDataSource<RosterAssignment>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
  //  this.cd.detectChanges();
  //  this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<RosterAssignment>).sort = this.innerSort.toArray()[index]);
  //}

  //applyFilter(filterValue: string) {
  //  this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<RosterAssignment>).filter = filterValue.trim().toLowerCase());
  //}

  /****************************** */

  loadEmployee() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      //this.employeeResutObj = res;
      this.allEmployeeObj = res;
      this.blockUI.stop();
    }, err => {
      this.blockUI.stop();
    });
  }
  resetTheForm() {
    this.stRosterModels = this.initializestRosterModel();
    this.stRosterModels.shiftDate = new Date();
    //this.strosterForm.resetForm();
  }

  resetRoster() {
    this.showNewButton = false;
    //this.stRosterModels.personnel = 0;
    this.employee = 0;
    this.stRosterModels.shiftDate = new Date();
    this.resetTheForm();
  //  this.loadGrid();
    this.getAllStRosterForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
  }
  AddNew() {
    this.routerService.navigate(['stroaster']);
  }
  editRoster(id: number,crewname:string) {
    const dialogRef = this.dialog.open(RosterAssignmentDialogContent, {
      width: '1200px',     
      data: { id: id, crewName: crewname }, 
      disableClose: true,
      hasBackdrop: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.user = user;
      this.getAllStRosterForDataSource();
    });
    return false;
    ////editRoster(user) {
    ////  const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
    ////    width: '250px',
    ////    data: user
    ////  });

    ////  dialogRef.afterClosed().subscribe(result => {
    ////    this.user = user;
    ////  });
    ////}
    ////   this.routerService.navigate(['stroaster', { id }]);
  }

  viewRoster(rosterModel: StRoster) {
    this.id = rosterModel.stRosterId;
    this.gotoupdate(this.id);
  }

  GridSortAndPage() {
    //this.dataSource = new MatTableDataSource<StRoster>(this.rosterModel);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  //getEmpName(EmpiD: any) {
  //  let name: string;
  //  let str1: string;
  //  let str2: string;
  //  let str3: string;
  //  this.employeeModels = this.initializeemployeeModels();
  //  this.employeeService.getEmployee('getEmployee', EmpiD).subscribe(res => {
  //    this.employeeModel = res;
  //    this.employeeModels = res;
  //    if (this.employeeModels.miName == null) {
  //      this.employeeModels.miName = " ";
  //    }
  //    str1 = this.employeeModels.firstName.toString();
  //    str2 = this.employeeModels.miName.toString();
  //    str3 = this.employeeModels.lastName.toString();
  //    name = str1.concat(str2, str3);
  //  },
  //    err => {
  //    });
  // // return name;   
  //}

  gotoupdate(id: number) {
    this.routerService.navigate(['stroasterupdate', { id }]);
  }

  PersonnelEvent(event: string) {
    this.assignmentEmployeeName = event;
    // this.assignment = event.source._mostRecentViewValue;
  }

  searchRoster() {
    // this.blockUI.start();
    this.showNewButton = true;
    //if (this.stRosterModels.station == null) {
    //  this.stRosterModels.station = 0;
    //}
    if (this.employee == null) {
      this.stRosterModels.personnel = 0;
    }
    //&& (this.stRosterModels.station == null || this.stRosterModels.station == 0)

    //if (!((this.stRosterModels.shiftDate == null) && (this.employee == null || this.employee == 0) )) {
    //  this.showNewButton = true;
    //} else {
    //  return;
    //}
    this.getAllStRosterForDataSource();



  }



  deleteRoster(rosterObj:any,Id: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        // this.blockUI.start();
        if (rosterObj.roasterId) {
          this.stRosterService.delRoster('delRoster', Id).subscribe(res => {
            this.errMsg = res.msg;
            this.getAllStRosterForDataSource();
            //if (this.errMsg == "Deleted Successfully.") {
            //  const dialogRef = this.dialog.open(SaveDialogComponent, {
            //    width: '500px',
            //    height: '200px',
            //    data: { name: "Deleted Successfully!" }
            //  });
            //}
            //this.loadGrid();
          });
        }
        else {
          this.dailyRoasterService.deleteRoaster('delRoaster', rosterObj.stRosterId).subscribe(res => {
           // this.errMsg = res.msg;
            
            this.getAllStRosterForDataSource();
            //if (this.errMsg == "Deleted Successfully.") {
            //  const dialogRef = this.dialog.open(SaveDialogComponent, {
            //    width: '500px',
            //    height: '200px',
            //    data: { name: "Deleted Successfully!" }
            //  });
            //}

          });

        }
      }
    });
    return false;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllStations()
    ).subscribe(([res1, res2, res3]) => {
      this.personnelCode = res1 || [];
      this.stationCode = res3 || [];

      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.crewKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.stationKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  viewReport() {
    this.isViewReport = true;
  }
  toggleViewTomakeFormVisible() {
    this.isViewReport = false;
  }
  printReport() {
    this.items = [];
    let dt = this.getDateAsString(this.stRosterModels.shiftDate);
    var dailyRoasterArr: StRoster[] = [];
    this.getAllStRosterForPrint(0, 0).subscribe(res => {
      dailyRoasterArr = res;
   

    this.dailyRoasterService.getStRoasterPrintView("GetStRoasterPrintView", (this.employee == null) ? 0 : this.employee, (dt == null) ? "" : dt, (this.stRosterModels.crew == null) ? 0 : this.stRosterModels.crew).subscribe(res => {
      this.dailyRoasterObj = res;

      let lastObj = this.dailyRoasterObj[this.dailyRoasterObj.length - 1];
      this.dailyRoasterObj.pop();
      
      for (let x = 0; x < dailyRoasterArr.length; x++) {
        //for (let y = 0; y < this.dailyRoasterObj.length; y++)  {
        //  if (this.dailyRoasterList[x].stRosterId.toString() != this.dailyRoasterObj[y].roasterId) {
        //    this.items.push(this.dailyRoasterList[x].stRosterId);
        //  }
        //}
        const index = this.dailyRoasterObj.findIndex(x1 => x1.roasterId == dailyRoasterArr[x].stRosterId.toString());
        if (index == -1) {
          this.items.push(dailyRoasterArr[x].stRosterId);
        }
      }
      for (let z = 0; z < this.items.length; z++) {

        const index = dailyRoasterArr.findIndex(x1 => x1.stRosterId == this.items[z]);
        if (index >= 0) {
          //const dindex = this.dailyRoasterList.findIndex(i => i.stRosterId == this.items[z]);
          //if (dindex >= 0) {
          let dailyObj = dailyRoasterArr[index];
          let dailyRoster = {
            roasterId: '',
            personal: this.getEmployeeName(dailyObj.personnel),
            station: '',
            position: '',
            unit: '',
            activity: '',
            startDate: '',
            endDate: '',
            hours: '0.00',
            type: 'empty',
            crew: ''
          };


          this.dailyRoasterObj.push(dailyRoster);

          //}
        }
      }

      this.dailyRoasterObj.push(lastObj);

      this.displayReport();
    });
    });
  }

  resetDate1() {
    this.stRosterModels.shiftDate = null;
  }
  getEmployeeName(empId: number) {
    let employeeIndex = this.allEmployeeObj.findIndex(x => x.employeeId == empId);
    if (employeeIndex > -1) {
      let firstName: string = this.allEmployeeObj[employeeIndex].firstName;
      let miName: string = this.allEmployeeObj[employeeIndex].miName;
      let lastName: string = this.allEmployeeObj[employeeIndex].lastName;

      firstName = (firstName == null) ? "" : firstName;
      miName = (miName == null) ? "" : miName;
      lastName = (lastName == null) ? "" : lastName;

      return (firstName + ' ' + miName + ' ' + lastName);
    }
    else {
      return "";
    }

  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    }
    else {
      return "";
    }
  }
  displayReport() {
    let title = "Daily Roster Report";
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 32;
    let alignment: number = 0;
    let startYVariable = 40;
    let personnelName = this.getPersonnelName();
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setProperties({
      title: 'DailyRoster_Report'
    });
    var totalPagesExp = '{total_pages_count_string}';
    doc.setFont('Calibri');
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 20)
    doc.setFontSize(10)

    let date = 'Shift Date:';
    date = date + this.getDateAsString(this.stRosterModels.shiftDate);
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(date, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Category Filter Code End
    if (this.stRosterModels.crew > 0) {
      for (let i = 0; i < this.stationKey.length; i++) {
        if (this.stationKey[i].key == this.stRosterModels.crew) {
          this.stationString = this.stationKey[i].value;
        }
      }
    } else {
      this.stationString = "";
    }
    //Crew and Station Filter Code Begin

    let station = 'Station: ';
    station = station + this.stationString;

    let Personnel = 'Personnel: ';
    Personnel = Personnel + personnelName;
    let textCrewStation = station + '     ' + Personnel;
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    //if (this.stRosterModels.shiftDate != null) {
    //if (count == 1) {
    //  yAxis = yAxis + 10;
    //  count = 0;
    //}
    //var pageSize = doc.internal.pageSize
    //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    //var text = doc.splitTextToSize('Shift Date: ' + this.getDateAsString(this.stRosterModels.shiftDate), pageWidth - 35)
    ////doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    //doc.text(text, xAxis + 110, yAxis)
    //// count++;
    //// }


    // // if (this.employee != null) {
    ////  if (count == 1) {
    ////  xAxis = xAxis + 90;
    //  yAxis = yAxis + 3;
    //  // count = 0;
    //  // }

    //  let Station = 'Station: ';
    //  Station = Station + this.stationString;
    //  //var pageSize = doc.internal.pageSize
    //  //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    //  //var text = doc.splitTextToSize(Station, pageWidth - 35)
    //  //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    //  //doc.text(text, xAxis + 90, yAxis)
    //  //count++;

    //  let Personnel = 'Personnel: ';
    //  Personnel = Personnel + personnelName;
    //  let textCrewStation = Station + '   ' + Personnel;
    //  var pageSize = doc.internal.pageSize
    //  var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    //  var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
    //  doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    //  doc.text(text, xAxis + 130, yAxis)
    //  count++;
    ////   }   


    if (!this.checkPrintReportFilters()) {
      startYVariable = 40;
    } else {
      startYVariable = yAxis + 10;
    }
    var currentDate = new Date();
    var currentDateString = this.getDateAsString(currentDate);

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        crew: {
          cellWidth: 15
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      //headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      //bodyStyles: { minCellHeight: 8, fillColor: null },
      //head: this.getHeadRows(),
      //body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 230, pageHeight - 10);

        doc.text(currentDateString, 35, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    //doc.output('dataurlnewwindow');
    window.open(URL.createObjectURL(doc.output("blob")));
  }
  getHeadRows() {
    return [
      {
        personnel: 'Personnel', station: 'Station', position: 'Position', unit: 'Unit', activityCode: 'Activity Code',
        startdate: 'Start Date/Time', enddate: 'End Date/Time', hours: 'Hours'
      }
    ]
  }

  getBody() {
    let rowCount = this.dailyRoasterObj.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {

      if (this.dailyRoasterObj[j].type == 'row') {
        body.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: (this.dailyRoasterObj[j].station) ? this.dailyRoasterObj[j].station : '',
          position: this.dailyRoasterObj[j].position,
          unit: (this.dailyRoasterObj[j].unit) ? this.dailyRoasterObj[j].unit : '',
          activityCode: (this.dailyRoasterObj[j].activity) ? this.dailyRoasterObj[j].activity : '',
          startdate: this.dailyRoasterObj[j].startDate,
          enddate: this.dailyRoasterObj[j].endDate,
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else if (this.dailyRoasterObj[j].type == 'empty') {
        body.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else {
        body.push({
          personnel: '',
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '               Total Hours -',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
    }
    return body;
  }
  checkPrintReportFilters() {
    if (this.stRosterModels.shiftDate == null && (this.employee == 0 || this.employee == null) && (this.stRosterModels.crew == null || this.stRosterModels.crew == 0)) {
      return true;
    }
    return false;
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
    var m = minutes % 60;
    let h1 = h < 10 ? '0' + h : h;
    let m1 = m < 10 ? '0' + m : m;
    return h1 + ':' + m1;
  }
  getPersonnelName() {

    const personelIndex = this.allEmployeeObj.findIndex(x => x.employeeId == this.employee);
    if (personelIndex >= 0) {
      let lastName = this.allEmployeeObj[personelIndex].lastName;// + employee.miName + employee.firstName;
      let firstName = this.allEmployeeObj[personelIndex].firstName;
      let miName = this.allEmployeeObj[personelIndex].miName;
      let mname = (miName) ? miName : "";
      return lastName + ", " + mname + " " + firstName;

    }
    return "";
  }

  //exportCSV

  exportStRosterReportasCSV() {
    this.items = [];
    let dt = this.getDateAsString(this.stRosterModels.shiftDate);
    var dailyRoasterArr: StRoster[] = [];
    this.getAllStRosterForPrint(0,0).subscribe(res => {
      dailyRoasterArr = res;
    

    this.dailyRoasterService.getStRoasterPrintView("GetStRoasterPrintView", (this.employee == null) ? 0 : this.employee, (dt == null) ? "" : dt, (this.stRosterModels.crew == null) ? 0 : this.stRosterModels.crew).subscribe(res => {
      this.dailyRoasterObj = res;

      let lastObj = this.dailyRoasterObj[this.dailyRoasterObj.length - 1];
      this.dailyRoasterObj.pop();

      for (let x = 0; x < dailyRoasterArr.length; x++) {
        //for (let y = 0; y < this.dailyRoasterObj.length; y++)  {
        //  if (this.dailyRoasterList[x].stRosterId.toString() != this.dailyRoasterObj[y].roasterId) {
        //    this.items.push(this.dailyRoasterList[x].stRosterId);
        //  }
        //}
        const index = this.dailyRoasterObj.findIndex(x1 => x1.roasterId == dailyRoasterArr[x].stRosterId.toString());
        if (index == -1) {
          this.items.push(dailyRoasterArr[x].stRosterId);
        }
      }
      for (let z = 0; z < this.items.length; z++) {

        const index = dailyRoasterArr.findIndex(x1 => x1.stRosterId == this.items[z]);
        if (index >= 0) {
          //const dindex = this.dailyRoasterList.findIndex(i => i.stRosterId == this.items[z]);
          //if (dindex >= 0) {
          let dailyObj = dailyRoasterArr[index];
          let dailyRoster = {
            roasterId: '',
            personal: this.getEmployeeName(dailyObj.personnel),
            station: '',
            position: '',
            unit: '',
            activity: '',
            startDate: '',
            endDate: '',
            hours: '0.00',
            type: 'empty',
            crew: ''
          };


          this.dailyRoasterObj.push(dailyRoster);

          //}
        }
      }

      this.dailyRoasterObj.push(lastObj);

      this.exportToCSV();

    });
    });
  }

  exportToCSV() {
    let rowCount = this.dailyRoasterObj.length;
    var data = []

    data.push({
      personnel: 'Personnel', station: 'Station', position: 'Position', unit: 'Unit', activityCode: 'Activity Code',
      startdate: 'Start Date/Time', enddate: 'End Date/Time', hours: 'Hours'
    });

    for (var j = 0; j < rowCount; j++) {

      if (this.dailyRoasterObj[j].type == 'row') {
        data.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: (this.dailyRoasterObj[j].station) ? this.dailyRoasterObj[j].station : '',
          position: this.dailyRoasterObj[j].position,
          unit: (this.dailyRoasterObj[j].unit) ? this.dailyRoasterObj[j].unit : '',
          activityCode: (this.dailyRoasterObj[j].activity) ? this.dailyRoasterObj[j].activity : '',
          startdate: this.dailyRoasterObj[j].startDate,
          enddate: this.dailyRoasterObj[j].endDate,
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else if (this.dailyRoasterObj[j].type == 'empty') {
        data.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else {
        data.push({
          personnel: '',
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '               Total Hours -',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
    }
    new AngularCsv(data, 'Daily Roster Report');
    //return data;

  }
  getAllStRosterForPrint(pageIndex: number, pageSize: number): Observable<any> {    
    return this.dailyRoasterService.getDailyRoastersForPrint(
      'getDailyRoastersForReportPrint',
      (this.stRosterModels.shiftDate) ? this.stRosterModels.shiftDate : null,
      0, (this.stRosterModels.crew) ? this.stRosterModels.crew : 0,
      (this.employee) ? this.employee : 0,
      pageIndex,
      pageSize,
      this.getDateAsString(this.stRosterModels.shiftDate)
    );
    //(this.employee) ? this.employee : 0,
  }

}



export interface RosterDataSource {
  stRosterId: number;
  shiftDate: Date;
  personnel: number;
  crew: number;
  station: number;
  fromDate: Date;
  toDate: Date;
  endDateCheck: Boolean;
  rosterAssignments?: MatTableDataSource<RosterAssignment>;
}
