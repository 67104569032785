import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator, MatSort, MatDialog } from "@angular/material";
import { RolePermissionCheck } from "../models/role-permission-check.model";
import { ActivatedRoute, Router } from "@angular/router";
import { SystemCodeService } from "../services/system-code.service";
import { AuthService } from "../services/auth.service";
import { RosterAssignment } from "../models/rosterassignment.model";
import { forkJoin } from "rxjs";
import { SystemCodeKeyValue } from "../models/systemcodekeyvalue.model";
import { SystemCode } from "../models/system-codes.model";
import { DailyRoasterService } from "../services/dailyroaster.service";
import { Employee } from "../models/employee.model";
import { EmployeeService } from "../services/employee.service";
import { DailyRoasterAssignment } from "../models/dailyroasterassignment.model";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: 'genaprintreport',
  styleUrls: ['./genaprintreport.component.css'],
  templateUrl: './genaprintreport.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class GenaPrintReportComponent {
  @ViewChild('timesheetForm', { static: false }) timesheetForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  rolePermissionCheckModel: RolePermissionCheck;
  rosterassignmentModel: RosterAssignment;
  rosterAssignmentObj: RosterAssignment[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];
  personnelCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  personnelKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  employeeModelObj: Employee[] = [];
  startDate: Date;
  dailyRoasterObj: DailyRoasterAssignment[] = [];
  revisedAssignments: DailyRoasterAssignment[] = [];
  activityCodeName: string = "";
  personelName: string = "";
  crewString: string = "";
  crewCode: SystemCode[] = [];
  datehint: string = "";
  datehint1: string = "";
  fromDate: Date;
  toDate: Date;
  isViewReport: boolean = false;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private dialog: MatDialog,
    private systemCodeService: SystemCodeService, private dailyRoaster: DailyRoasterService,
    private authService: AuthService, private activatedRoute: ActivatedRoute, private routerService: Router, private employeeService: EmployeeService, private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isRead || this.rolePermissionCheckModel.isPrint)) {
      this.rosterassignmentModel = this.initializeRosterAssignmentModel();
      setTimeout(() => { this.loadData(); }, 1000);
      this.loadDropDowns();
      this.defaultValues();
      this.loadPersonnel();
    }
  }

  defaultValues() {
    this.rosterassignmentModel.startDate = new Date();
    let endDate = new Date();
    var edate = new Date();
    endDate.setDate(edate.getDate() + 7);
    this.rosterassignmentModel.endDate = endDate;

    for (let i = 0; i < this.activityCodeKey.length; i++) {
      if (this.activityCodeKey[i].value == "Regular Work Schedule") {
        this.rosterassignmentModel.activityCode = this.activityCodeKey[i].key;
      }
    }
    for (let i = 0; i < this.crewKey.length; i++) {
      if (this.crewKey[i].value == "Crew 3") {
        this.rosterassignmentModel.station = this.crewKey[i].key;
      }
    }

    this.toDate = this.rosterassignmentModel.endDate;
    this.fromDate = this.rosterassignmentModel.startDate;
  }

  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: null, fromTime: null, toTime: null, startDate: null, endDate: null,
      isActive: true, stRosterId: 0, station: null
    } as RosterAssignment;
  }

  timesheetReport() { }

  dateevent1(fromDate: Date) {
    this.fromDate = fromDate;
    if (this.toDate == null) {
      this.datehint1 = "Please Enter End Date";
    } else {
      this.datehint1 = "";
    }
    //if (this.eventCalendarId > 0) {
    //  this.toDate = this.eventCalendarModel.toDate;
    //}
    this.datehint = "";
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }

  dateevent2(toDateev: Date) {
    this.toDate = toDateev;
    if (this.fromDate == null) {
      this.datehint = "Please Enter Start Date";
    } else {
      this.datehint1 = "";
    }
    //if (this.eventCalendarId > 0) {
    //  this.fromDate = this.eventCalendarModel.fromDate;
    //}
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getAllActivityCode(),
      this.systemCodeService.getSystemCodesForCrews(),
    ).subscribe(([res1, res2, res3]) => {
      // this.personnelCode = res1 || [];
      this.activityCode = res2 || [];
      this.crewCode = res3 || [];
      this.crewKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.defaultValues();
    });

  }

  public loadPersonnel() {
    this.employeeService.getEmployeesBetweenDates("getEmployeesBetweenDates", this.getDateAsString(this.rosterassignmentModel.startDate), this.getDateAsString(this.rosterassignmentModel.endDate)).subscribe(
      res => {
        this.employeeModelObj = res;
      });
    //this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
    //  this.employeeModelObj = res;
    //});
    //this.employeeService.getEmployees("getEmployeesForShift", this.getDateAsString(this.rosterassignmentModel.startDate)).subscribe(
    //  res => {
    //    this.employeeModelObj = res;
    //  });
  }

  search() {
    this.loadData();
  }

  loadData() {
    this.blockUI.start();
    this.revisedAssignments = [];
    this.dailyRoaster.getGenaTimeSheetView("getGenaTimeSheetView", (this.rosterassignmentModel.startDate) ? this.rosterassignmentModel.startDate : null,
      (this.rosterassignmentModel.endDate) ? this.rosterassignmentModel.endDate : null,
      this.rosterassignmentModel.activityCode ? this.rosterassignmentModel.activityCode : 0,
      this.rosterassignmentModel.position ? this.rosterassignmentModel.position : 0,
      this.rosterassignmentModel.station ? this.rosterassignmentModel.station : 0).subscribe(res => {
        this.dailyRoasterObj = res;
        this.getFormattedTable(this.dailyRoasterObj);
        const activityIndex = this.activityCodeKey.findIndex(x => x.key == this.rosterassignmentModel.activityCode);
        if (activityIndex >= 0) {
          this.activityCodeName = this.activityCodeKey[activityIndex].value;
        }
        const positionIndex = this.employeeModelObj.findIndex(x => x.employeeId == this.rosterassignmentModel.position);
        if (positionIndex >= 0) {
          this.personelName = this.employeeModelObj[positionIndex].lastName + ", " + this.employeeModelObj[positionIndex].miName + " "
            + this.employeeModelObj[positionIndex].firstName;
        }
        this.blockUI.stop();
        //this.printReport();
      }, (error: any) => {
        this.dailyRoasterObj = [];
        this.blockUI.stop();
      });
    this.blockUI.stop();
  }

  shiftDateChange(dt: any) {
    this.rosterassignmentModel.startDate = dt;
    this.loadPersonnel();
  }

  shiftDateChange1(dt1: any) {
    this.rosterassignmentModel.endDate = dt1;
    this.loadPersonnel();
  }

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
    var m = minutes % 60;
    let h1 = h < 10 ? '0' + h : h;
    let m1 = m < 10 ? '0' + m : m;
    return h1 + ':' + m1;
  }

  getFormattedTable(assignments: DailyRoasterAssignment[]) {
    let lastName = "";
    let personelMins = 0;
    for (let x = 0; x < assignments.length; x++) {
      if (lastName != assignments[x].personal) {
        if (lastName != "") {
          let objdata = this.InitializeModel();
          objdata.hours = personelMins.toString();
          objdata.type = "data";
          this.revisedAssignments.push(objdata);
          personelMins = 0;
        }
      }
      personelMins = personelMins + parseInt(assignments[x].hours);

      if (lastName != assignments[x].personal) {
        let obj1 = this.InitializeModel();
        obj1.personal = assignments[x].personal;
        obj1.type = "header";
        this.revisedAssignments.push(obj1);
        lastName = obj1.personal;
      }

      let obj = this.InitializeModel();
      obj.personal = assignments[x].personal;
      obj.type = "row";
      obj.crew = assignments[x].crew;
      obj.activity = assignments[x].activity;
      obj.hours = assignments[x].hours;
      obj.startDate = assignments[x].startDate;
      obj.endDate = assignments[x].endDate;
      obj.station = assignments[x].station;
      obj.unit = assignments[x].unit;
      this.revisedAssignments.push(obj);
    }
    if (personelMins >= 0) {
      let objdata = this.InitializeModel();
      objdata.hours = personelMins.toString();
      objdata.type = "data";
      this.revisedAssignments.push(objdata);
    }
  }

  InitializeModel(): DailyRoasterAssignment {
    return {
      roasterId: "",
      personal: "",
      station: "",
      position: "",
      unit: "",
      activity: "",
      startDate: "",
      endDate: "",
      hours: "",
      type: "", crew: ""
    }
  }

  resetTheForm() {
    this.blockUI.start();
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.defaultValues();
    this.loadPersonnel();
    this.loadData();
    this.blockUI.stop();
  }

  printReport() {
    this.blockUI.stop();
    setTimeout(() => {
      let title = "Gena's Print Report";
      let count: number = 0;
      let xAxis: number = 15;
      let yAxis: number = 30;
      let alignment: number = 0;
      let startYVariable = 40;
      var doc = new jsPDF('l', 'mm', 'letter');
      doc.setProperties({
        title: "Gena's Print Report"
      });
      var totalPagesExp = '{total_pages_count_string}';
      doc.setTextColor(40)
      doc.setFontSize(16)
      var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(title, textOffset, 20)
      doc.setFontSize(10)

      let startDate = 'Start Date: ';
      if (this.rosterassignmentModel.startDate) {
        let startDateString = new Date(this.rosterassignmentModel.startDate);
        let startDateFormat = this.getDateAsString(startDateString);
        startDate = startDate + startDateFormat;
      }
      let endDate = 'End Date: ';
      if (this.rosterassignmentModel.endDate) {
        let endDateString = new Date(this.rosterassignmentModel.endDate);
        let endDateFormat = this.getDateAsString(endDateString);
        endDate = endDate + endDateFormat;
      }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      let Activity = 'Activity Code:  ';
      let Personnel = 'Personnel: ';
      Activity = Activity + this.activityCodeName;
      Personnel = Personnel + this.personelName;
      let textCrewStation = Activity + '   ' + Personnel;
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      for (let i = 0; i < this.crewKey.length; i++) {
        if (this.crewKey[i].key == this.rosterassignmentModel.station) {
          this.crewString = this.crewKey[i].value;
        }
      }

      let Crew = 'Crew: ';
      Crew = Crew + this.crewString;
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(Crew, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
      yAxis = yAxis + 5;

      //if (count == 1) {
      //  yAxis = yAxis + 10;
      //  count = 0;
      //}

      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Start Date: ' + this.getDateAsString(this.rosterassignmentModel.startDate), pageWidth - 35)    
      //doc.text(text, xAxis + 300, yAxis -19)
      //count++;


      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('End Date: ' + this.getDateAsString(this.rosterassignmentModel.endDate), pageWidth - 35)    
      //doc.text(text, xAxis + 450, yAxis - 19)
      //count++;

      //if (count == 1) {
      //  xAxis = xAxis + 280;
      //  count = 0;
      //}
      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Activity Code: ' + this.activityCodeName, pageWidth - 35)   
      //doc.text(text, xAxis + 110, yAxis-7)
      //count++;

      //if (count == 1) { 
      //  xAxis = xAxis + 280;
      //  count = 0;
      //}
      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Personnel: ' + this.personelName, pageWidth - 35)    
      //doc.text(text, xAxis + 350, yAxis-7)
      //count++;   

      //var pageSize = doc.internal.pageSize
      //var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      //var text = doc.splitTextToSize('Crew: ' + this.crewString, pageWidth - 35)
      ////doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      //doc.text(text, xAxis + 550, yAxis-7)
      //  count++;

      if (!this.checkPrintReportFilters()) {
        startYVariable = 50;
      } else {
        startYVariable = yAxis + 10;
      }
      var currentDate = new Date();
      var currentDateString = this.getDateAsString(currentDate);

      autoTable(doc, {
        rowPageBreak: 'avoid',
        headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
        bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
        head: this.getHeadRows(),
        body: this.getBody(),
        //margin: { top: 65 }, styles: { overflow: 'linebreak' },
        //margin: {
        //  top: 150,
        //} ,
        didDrawPage: function (data) {
          var str = 'Page ' + doc.getNumberOfPages()
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(9)

          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, 250, pageHeight - 10);

          let currentDate = new Date();
          let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
          let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
          let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
          doc.text(currentDateString, 15, pageHeight - 10);
        },
        startY: yAxis
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      window.open(URL.createObjectURL(doc.output("blob")));

    }, 300);
    this.search();
    //this.isViewReport = true;
    //if (this.rosterassignmentModel.startDate == null && this.rosterassignmentModel.endDate == null && this.rosterassignmentModel.activityCode == 0
    //  && this.rosterassignmentModel.position == 0 && this.rosterassignmentModel.station == 0) {
    //  this.InitialLoad();
    //} else {
    //  this.searchEvent();
    //}
  }

  getHeadRows() {
    return [
      {
        personnel: 'Personnel', crew: 'Crew', activityCode: 'Activity Code', hours: 'Hours',
        startdate: 'Start Date/Time', station: 'Station', unit: 'Unit'
      }
    ]
  }

  getBody() {
    let rowCount = this.revisedAssignments.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      //if (this.revisedAssignments[j].type == 'data') {
      //  body.push({
      //    personnel: '',
      //    crew: '',
      //    activityCode: '',
      //    hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) : 'N/A',
      //    startdate: '',
      //    station: '',
      //    unit: ''
      //  });
      //}

      if (this.revisedAssignments[j].type == 'row') {
        body.push({
          personnel: this.revisedAssignments[j].personal,
          crew: (this.revisedAssignments[j].crew) ? this.revisedAssignments[j].crew : '',
          activityCode: (this.revisedAssignments[j].activity) ? this.revisedAssignments[j].activity : '',
          hours: (this.revisedAssignments[j].hours) ? this.convertMinsToHrsMins(parseInt(this.revisedAssignments[j].hours)) + "" : '',
          startdate: this.revisedAssignments[j].startDate + " to " + this.revisedAssignments[j].endDate,
          station: (this.revisedAssignments[j].station) ? this.revisedAssignments[j].station : '',
          unit: (this.revisedAssignments[j].unit) ? this.revisedAssignments[j].unit : ''
        });
      }

      //if (this.revisedAssignments[j].type == 'header') {
      //  body.push({
      //    personnel: '',
      //    crew: '',
      //    activityCode: '',
      //    hours: this.revisedAssignments[j].personal,
      //    startdate: '',
      //    station: '',
      //    unit: ''
      //  });
      //}
    }
    return body;
  }

  checkPrintReportFilters() {
    if (this.rosterassignmentModel.startDate == null) {
      return true;
    }
    return false;
  }
}
