import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { StRoster } from '../models/stroster.model';
import { GetAllStRoster } from '../models/get-all-stRoster.model';
import { GetAllTradeTime } from '../models/get-all-tradeTime.model';
import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class StRosterService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/StRoster/', httpClient, errorService, authService);
  }
  
  saveStRoster(action: string, stRosterObj: StRoster): Observable<any> {
    return this.postRequest(action, JSON.stringify(stRosterObj));
  }
  
  getRoster(action: string, StRosterId: number): Observable<any> {

    return this.customGetRequest<StRoster>(action, StRosterId.toString());
  }
  
  updateRoster(stRosterId: number, stRosterObj: StRoster): Observable<any> {

    return this.putRequest(stRosterId, JSON.stringify(stRosterObj));
  }   

  getAllRoster(): Observable<Array<StRoster>> {
    return this.customGetRequest<Array<StRoster>>('getAllRoster', null);
  }

  delRoster(action: string, stRosterId: number): Observable<any> {
    return this.deleteRequest(stRosterId);
    //return this.postRequest(action, JSON.stringify(rosterobj));
  }
  

  searchRoster(action: string, stRosterObj: any): Observable<Array<StRoster>> {    
      var shiftDate = new Date(stRosterObj.shiftDate);
      const params = new HttpParams()
        .set('station', stRosterObj.station)
        .set('personnel', stRosterObj.personnel)
        .set('shiftDate', shiftDate.toDateString());
      return this.customGetRequestWithQueryString<Array<StRoster>>(action, params);   
  }
  
  getAllStRosterNew(action: string, shiftDate: Date = null, personnel: number = 0, station: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllStRoster> {
    debugger;
    //var shiftDate = new Date(shiftDate);
    let params = new HttpParams()      
      .set('personnel', personnel.toString())
      .set('station', station.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);
    if (shiftDate != null) {
      params = params.set('shiftDate', shiftDate.toDateString());
    }
    return this.customGetRequestWithQueryString<GetAllStRoster>(action, params);
  }

  //----------------
  getAllTradeTimeNew(action: string, startDate: Date = null, endDate: Date = null, activityCode: number = 0, personnel: number = 0, assignment: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllTradeTime> {
    debugger;
    var stDate = new Date();
    if (startDate == null) {
      var lyear = stDate.getFullYear();
      var lmonth = stDate.getMonth();
      var lday = stDate.getDate();
      stDate = new Date(2000, 0, 1);
    }
    else {
      stDate = new Date(startDate);
    }


    var edDate = new Date();
    if (endDate == null) {
      var cyear = edDate.getFullYear();
      var cmonth = edDate.getMonth();
      var cday = edDate.getDate();
      edDate = new Date(2025, 11, 31);
    }
    else {
      edDate = new Date(endDate);
    }

    const params = new HttpParams()
      .set('activityCode', activityCode.toString())
      .set('personnel', personnel.toString())
      .set('assignment', assignment.toString())
      .set('startDate', stDate.toDateString())
      .set('endDate', edDate.toDateString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);
    return this.customGetRequestWithQueryString<GetAllTradeTime>(action, params);
  }



}
