import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { SystemCodeService } from '../services/system-code.service';
import { OccupancyService } from '../services/occupancy.service';
import { Observable, of } from 'rxjs';
import { Occupancy } from '../models/occupancy.model';
import { SystemCode } from '../models/system-codes.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllOccupancy } from '../models/get-all-occupancy.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { ActivatedRoute, Router } from '@angular/router';

interface StreetNameFilter {
  key: number;
  value: string;
}
interface BuisnessFilter {
  key: number;
  value: string;
}
@Component({
  selector: 'occupancyreport',
  templateUrl: './occupancyreport.component.html'
})
export class OccupancyReportComponent {
  @ViewChild('occupancyReportForm', { static: false }) occupancyReportForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  districts: SystemCode[] = [];
  occupancyResultList: Occupancy[] = [];
  occupancyModel: Occupancy;
  control = new FormControl();
  control1 = new FormControl();
  businesss: string[] = [];
  streets: string[] = [];
  occupancyId: number =0;
  sDistrict: number = 0;
  sStreetName: string = "";
  sBusinessName: string = "";
  searchMsg: string = "";
  streetResultObj: Array<{ key: number, value: string }> = [];
  public filteredStreetList: Array<{ key: number, value: string }> = [];
  buisnessResultObj: Array<{ key: number, value: string }> = [];
  public filteredBuisnessList: Array<{ key: number, value: string }> = [];
  streetNames: StreetNameFilter;
  buisnessNames: BuisnessFilter;
  resultsLength: number = 0;
  assignmentObjDB = new MatTableDataSource<Occupancy>([]);
  isShowAddButton: boolean = false;

  dataSource: MatTableDataSource<Occupancy>;
  paginatorid: MatPaginator;
  sortid: MatSort;


  filteredStreets: Observable<string[]>;
  filteredStreets1: Observable<string[]>;

  displayedColumns: any[] = ['businessText', 'phone', 'streetNumber', 'streetNameText', 'aptsuite', 'contactFirstName', 'contactLastName', 'contactTypeName', 'districtName', 'knoxBoxName'];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private systemCodeStateService: SystemCodeService,
    private dialog: MatDialog,
    private routerService: Router,
    private occupancyService: OccupancyService,
    private activatedRoute: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.loadDropDowns();
      this.loadStreetNames();
      this.loadBuisness();
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.occupancyModel = this.initializeOccupancyModel();
      this.dataSource = new MatTableDataSource(this.occupancyResultList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    //this.dataSource = new MatTableDataSource<Occupancy>(this.occupancyResultObj);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.filteredStreets1 = this.control1.valueChanges.pipe(
      startWith(''),
      map(value => this._filter1(value))
    );
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
  }

  initializeOccupancyModel(): Occupancy {
    return {
      occupancyId: 0,
      employeeId: 0,
      business: null,
      phone: null,
      streetNumber: null,
      streetName: null,
      aptsuite: null,
      district: null,
      knoxbox: null,
      contactFirstName: null,
      contactLastName: null,
      contactType: null,
      contactPhone: null,
      districtName: null,
      knoxBoxName: null,
      contactTypeName: null,
      //occupancyFile: File;
      //preplanFile: File;
      occupancyFileName: null,
      preplanFileName: null,
      occupancyFileDelete: false,
      preplanFileDelete: false,
      contactPhonePrimary: null,
      contactPhoneSecondary: null,
      businessText: null,
      streetNameText: null,
    } as Occupancy;
  }

  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllOccupancy(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            //this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.occupancyResultList = data || [];
          //this.assignmentObjDB.data = data || [];
          this.dataSource = new MatTableDataSource(this.occupancyResultList);
          this.dataSource.sort = this.sort;
          ////this.dataSource = new MatTableDataSource<Occupancy>(this.occupancyResultList);
          //this.dataSource.paginator = this.paginator;
          //this.dataSource.sort = this.sort;
          //this.blockUI.stop();
        });
    }
  }

  getAllOccupancy(pageIndex: number, pageSize: number): Observable<GetAllOccupancy> {
    return this.occupancyService.getAllOccupancyNew(
      'GetOccupancyNew',
      (this.occupancyModel.district) ? this.occupancyModel.district : 0,
      (this.occupancyModel.streetName) ? this.occupancyModel.streetName : 0,
      (this.occupancyModel.business) ? this.occupancyModel.business : 0,
      pageIndex,
      pageSize
    );
  }

  getAllOccupancyForDataSource(): void {
    this.getAllOccupancy(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          //this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.occupancyResultList = data || [];
        //this.assignmentObjDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.occupancyResultList);
        ////this.dataSource = new MatTableDataSource<Occupancy>(this.occupancyResultList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        //this.blockUI.stop();
      });
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _filter1(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.businesss.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public applySearch() {
    this.isShowAddButton = true;
    //  this.blockUI.start();
    this.searchMsg = "";
    this.getAllOccupancyForDataSource();
    return false;

    //this.occupancyService.searchOccupancies('searchOccupancy', this.sDistrict, this.sStreetName, this.sBusinessName).subscribe(res => {
    //  this.occupancyResultList = res;
    //});
  }

  public loadDropDowns() {
    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.districts = res || [];

      }, (error: any) => { }
    );
  }

  GridSortAndPage() {
    //this.dataSource = new MatTableDataSource(this.occupancyResultList);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  loadGrid() {
    this.occupancyService.getAllOccupanciesForGrid().subscribe(
      (res: Array<Occupancy>) => {
        this.occupancyResultList = res || [];

        this.occupancyId = this.occupancyId;
        this.GridSortAndPage();

      }, (error: any) => {
      });
    this.GridSortAndPage();
  }

  deleteOccupancy(oId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        // this.employeeObj.employeeId = parseInt( eId);

        this.occupancyService.delOccupancy('delOccupancy', parseInt(oId)).subscribe(res => {
          // this.loadGrid();
          this.getAllOccupancyForDataSource();

        });
      }

    });
    return false;
  }


  //loadGrid() {
  //  this.occupancyService.getAllOccupancies('GetOccupancies', 1).subscribe(res => {
  //    this.occupancyResultList = res;
  //  });
  //}
  //loadStreets() {

  //  this.occupancyService.getStreets('GetBuisnessNames').subscribe(res => {

  //  //  this.businesss = res;

  //  });
  //  return false;

  //}
  //loadBuisness() {
  //  this.occupancyService.getBuisness('GetStreetNames').subscribe(res => {

  //  //  this.streets = res;

  //  });
  //  return false;
  //}

  resetSearch() {
    this.occupancyModel = this.initializeOccupancyModel();
    this.occupancyReportForm.resetForm();
    this.searchMsg = "";
    this.getAllOccupancyForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15
    this.isShowAddButton = false;
    // return false;
  }

  loadStreetNames() {
    this.occupancyService.getStreets("getSystemCodesForStreetNames").subscribe(
      res => {
        // this.businessNames = res || [];     
        this.streetResultObj = res.map(x => { return { key: x.systemCodeId, value: x.type }; });
        this.filteredStreetList = this.streetResultObj.slice();
      }, (error: any) => { }
    );
  }

  loadBuisness() {
    this.occupancyService.getBuisness("getSystemCodesForBuisness").subscribe(
      res => {
        // this.businessNames = res || [];      
        this.buisnessResultObj = res.map(x => { return { key: x.systemCodeId, value: x.type }; });
        this.filteredBuisnessList = this.buisnessResultObj.slice();
      }, (error: any) => { }
    );
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
