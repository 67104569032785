import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from "../common/error-dialog/error-dialog.component";
import { RosterAssignmentService } from "../services/rosterassignment.service";
import { StRosterService } from "../services/stroster.service";
import { RosterAssignment } from "../models/rosterassignment.model";
import { StRoster } from "../models/stroster.model";
import { Employee } from "../models/employee.model";
import { FormControl, NgForm } from "@angular/forms";
import { MatTableDataSource } from "@angular/material";
import { Observable, forkJoin, interval } from "rxjs";
import { SystemCodeKeyValue } from "../models/systemcodekeyvalue.model";
import { SystemCode } from "../models/system-codes.model";
import { SystemCodeService } from "../services/system-code.service";
import { DeleteAssignmentComponent } from "./deleteassignment.component";
import { SaveDialogComponent } from "../common/saved-dialog/save-dialog.component";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common'
//import { DailyRoaster } from "../models/dailyroaster.model";
import { DailyRoasterService } from "../services/dailyroaster.service";
import { EmployeeService } from "../services/employee.service";
import { setHours } from "date-fns";
import { DeleteDialogComponent } from "../common/delete-dialog/delete-dialog.component";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'dailyroasterassignment',
  templateUrl: 'dailyroasterassignment.component.html',
  styleUrls: ['./dailyroasterassignment.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class RosterAssignmentDialogContent {
  @ViewChild('assignmentForm', { static: false }) assignmentForm: NgForm;
  //dailyRoasterId: number;
  stRosterId: number;
  stRosterModel: StRoster;
  //stRosterModel: DailyRoaster;
  rosterassignmentModel: RosterAssignment;
  rosterAssignmentsModel: RosterAssignment[] = [];
  totalhours: number;
  overTimeCode: number = 0;
  checktotalhour: number=0;
  titalHoursString: string;
  thFromTime: number;
  thToTime: number;
  showEndDate: boolean = false;  
  assignmentId: number;
  errMsg: string;
  hint: string = "";
  hint1: string="";
  hint2: string;
  hint3: string;
  datehint: string="";
  datehint2: string="";
  editIndex: number = 0;
  OnEdit: boolean = false;
  savedialog: boolean;
  updatebtn: boolean = false;
  resetbtn: boolean = true;
  addassbtn: boolean = true;
  savebtn: boolean = true;
  resetOption: boolean = false;
  resetOption1: boolean = false;
  key: number;
  value: string;
  floatLabel: string = 'never';
  rosterAssignments: string[] = [];
  errordialog: boolean;
  personnel: number[] = [];
  fromTime: number;
  toTime: number;
  totalHourString: string="";
  totalHourString1: string = "";
  totalHourStringCur: string = "";
  totalHourStringPre: string = "";
  totalHourStringNxt: string = "";
  zone: string;
  zone1: string;
  PrefromTime: number;
  PretoTime: number;
  NextfromTime: number;
  NexttoTime: number;
  nextTozone1: string;
  nextFromzone1: string;
  preFromzone1: string;
  preTozone1: string;
  EndDateDisable: boolean = false;
  employeeResutObj: Employee[] = [];
  employeeResutObjActive: Employee[] = [];
  employeeResutObjAll: Employee[] = [];
  differenceMins: number = 0;
  isClicked: boolean = false;
  endDate: number;
  CurstartDate: number;
  CurendDate: number;
  PrestartDate: number;
  PreendDate: number;
  NextstartDate: number;
  NextendDate: number;
  startDateVar: Date = null;
  endDateVar: Date = null;
  endDateVar1: Date;
  startDateVar1: Date;
  defaultActivity: string = "Regular Work Schedule";
  overtimeActivity: string = "Overtime";
  defaultActivityKey: number = null;
  index: number;
  assignmentEmployee: number;
  assignmentEmployeeName: string;
  //employee: number[] = [];
  employee: number;
  //assignmentId: number;
  assignment: number = 0;
 // employee: string;
  employeeId: number = 0;
  station: number = null;
  position: number = null;
  unit: number = 0;
  activityCodeVar: number=null;
  notes: string;
  fromTimeVar: string;
  toTimeVar: string;
  isActive: boolean;
  dateenable: boolean;
  enddateenable: boolean = true;
  showAssSave: boolean = true;
  //stRosterId: number;
  crewString: string = "";
  todayDate: Date = new Date();
  fromDate: Date;
  shiftDate: Date;
  toDate: Date;
  activityCodeValue: string="";
  employeeModel: Employee[] = [];
  assignmentModelDB = new MatTableDataSource<RosterAssignment>([]);
  control = new FormControl();  
  assignmentModel: RosterAssignment[] = [];
  trader: Observable<string[]>;
  systemcodeKeyValue: SystemCodeKeyValue;
  personnelCode: SystemCode[] = [];
  crewCode: SystemCode[] = [];
  unitCode: SystemCode[] = [];
  assignmentCode: SystemCode[] = [];
  stationCode: SystemCode[] = [];
  activityCode: SystemCode[] = [];
  positionsCode: SystemCode[] = [];
  @BlockUI() blockUI: NgBlockUI;
  overTimeSystemcode: number;
  totalHrsArray: number[] = [];
  shiftSum: number;
  shiftSumTemp: number;
  personnelKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  positionKey: SystemCodeKeyValue[] = [];
  assignmentKey: SystemCodeKeyValue[] = [];
  unitKey: SystemCodeKeyValue[] = [];
  stationKey: SystemCodeKeyValue[] = [];
  activityCodeKey: SystemCodeKeyValue[] = [];
  therosterAssignmentsModel: RosterAssignment[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private stRosterService: DailyRoasterService,
    private rosterAssignmentService: RosterAssignmentService, private systemCodeService: SystemCodeService,
    private routerService: Router, public datepipe: DatePipe, private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>) {
    this.stRosterId = data.id;
    this.crewString = data.crewName;
  }
  ngOnInit() {
    this.blockUI.start();
    this.loadDropDowns();
    this.loadAssignments();
    this.loadGrid();
   
    this.stRosterModel = this.initializeStRosterModel();
    //this.stRosterModel = this.initializeModel();
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    
    if (this.stRosterId > 0) {
    //  this.defaultActivity = "";
      this.stRosterService.getRoster('getRoster', this.stRosterId).subscribe(res => {
        //this.show();
        //this.datehint = "";
        this.stRosterModel = res;
        
        this.shiftDate = this.getDateFormat( this.stRosterModel.shiftDateStr);
        this.setDefaultTime();
        this.endDateVar1 = new Date(new Date(this.shiftDate).setDate(new Date(this.shiftDate).getDate() + 1));
        //this.dateenable = true;
        // this.assignmentModelDB = res.rosterAssignments;
        this.rosterAssignmentsModel = res.rosterAssignments;
        this.shiftHrsEnd();
        if (this.rosterAssignmentsModel.length>0) {
          this.resetDefaultTime();
        }
        //this.stRosterModel.station
      }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error Occured. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });

      });

    }
    else {
      //this.rosterassignmentModel.activityCode = this.defaultActivityKey;
    
    }
    this.ActiveModel();
    this.blockUI.stop();
  }
 
  initializeStRosterModel(): StRoster {
    return {
      stRosterId: 0, shiftDate: null, personnel: null, crew: null,  rosterAssignments: null
    } as StRoster;
  }

  initializeRosterAssignmentModel(): RosterAssignment {
    return {
      assignmentId: 0, assignment: null, position: null, unit: null, activityCode: null, notes: null, fromTime: null, toTime: null, startDate: null, endDate: null,
      isActive: true, stRosterId: 0, station: null, startDateStr: null,
      endDateStr: null
    } as RosterAssignment;
  }

  onSelectionChangedEmployee(event: any) {
    this.assignment = event;
  }

  ActiveModel() {
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true) {
        this.therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
    }     
  }
  shiftHrsInitial() {
    let temprosterAssignmentsModel: RosterAssignment[] = [];
    //this.ActiveModel();
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true && this.rosterAssignmentsModel[i].activityCode != this.overTimeCode) {
        temprosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }     
    }
    if (this.rosterassignmentModel.activityCode != this.overTimeSystemcode) {
      temprosterAssignmentsModel.push(this.rosterassignmentModel);
    }
    this.totalHrsArray = [];
    if (temprosterAssignmentsModel.length > 0) {
      for (let i = 0; i < temprosterAssignmentsModel.length; i++) {
        if (this.OnEdit == true) {
          if (i != this.index) {
            this.getTotalHoursParams(temprosterAssignmentsModel[i].startDate, temprosterAssignmentsModel[i].endDate, temprosterAssignmentsModel[i].fromTime, temprosterAssignmentsModel[i].toTime);
          }          
        } else {
          this.getTotalHoursParams(temprosterAssignmentsModel[i].startDate, temprosterAssignmentsModel[i].endDate, temprosterAssignmentsModel[i].fromTime, temprosterAssignmentsModel[i].toTime);
        }
       
      }
    } else {
      this.getTotalHoursParams(this.startDateVar, this.endDateVar, this.fromTimeVar, this.toTimeVar);
    }
    this.shiftSumTemp = this.totalHrsArray.reduce((acc, cur) => acc + cur, 0);
    this.totalHrsArray = [];
  }
  

  shiftHrsEnd() {
    let therosterAssignmentsModel: RosterAssignment[] = [];
    //this.ActiveModel();
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
    } 
    this.totalHrsArray = [];
    if (therosterAssignmentsModel.length > 0) {
      for (let i = 0; i < therosterAssignmentsModel.length; i++) {
        this.getTotalHoursParams(therosterAssignmentsModel[i].startDate, therosterAssignmentsModel[i].endDate, therosterAssignmentsModel[i].fromTime, therosterAssignmentsModel[i].toTime);
      }
    } else {
      this.getTotalHoursParams(this.startDateVar, this.endDateVar, this.fromTimeVar, this.toTimeVar);
    }
    
    this.shiftSum = this.totalHrsArray.reduce((acc, cur) => acc + cur, 0);
    this.totalHrsArray = [];
    if (this.activityCodeValue != "") {
      if (this.shiftSum <= 36) {
        //alert("less than 36 hrs");
        //this.showAssSave = true;
      } else {
        //alert("Greater than 36 hrs");
        //this.showAssSave = false;
      }
    } else {
      if (this.shiftSum <= 24) {
        //alert("less than 24 hrs");
        //this.showAssSave = true;
      } else {
        //alert("Greater than 24 hrs");
        //this.showAssSave = false;
      }
    }
  }

  addAssignment() {
    this.startDateVar = new Date(this.startDateVar);
    this.endDateVar = new Date(this.endDateVar);
    this.rosterassignmentModel.assignment = this.assignment;
    this.rosterassignmentModel.position = this.position;
    this.rosterassignmentModel.unit = this.unit;
    this.rosterassignmentModel.activityCode = this.activityCodeVar;
    this.rosterassignmentModel.notes = this.notes;
    this.rosterassignmentModel.fromTime = this.fromTimeVar;
    this.rosterassignmentModel.toTime = this.toTimeVar;
    this.rosterassignmentModel.startDate = this.startDateVar;
    this.rosterassignmentModel.endDate = this.endDateVar;
    this.rosterassignmentModel.station = this.station;
    this.rosterassignmentModel.isActive = true;
    this.rosterassignmentModel.startDateStr = this.getDateAsString( this.startDateVar);
    this.rosterassignmentModel.endDateStr = this.getDateAsString(this.endDateVar);
    this.shiftHrsInitial();
    //this.getTotalHoursParams(this.startDateVar, this.endDateVar, this.fromTimeVar, this.toTimeVar);
    //this.shiftSum = this.shiftSum + this.totalHrsArray.reduce((acc, cur) => acc + cur, 0);
    this.duplicateTimeRange();
    this.getCurrentTotalhour();
    this.ActiveModel();
   // this.onActivityCodeChange(this.activityCodeVar);
    let result = this.checkTimeOverlapse(this.rosterassignmentModel);
    if (result == true) {
      if (this.shiftSumTemp  > 24 && this.activityCodeValue == "") {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '550px',
          height: '200px',
          data: { name: "Shift Alert", description: "You can't add assignment more than 24 Hrs in normal activity code" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      //else if (this.shiftSum + this.totalhours > 36 && this.activityCodeValue != "") {
      //  const dialogRef = this.dialog.open(ErrorDialogComponent, {
      //    width: '500px',
      //    height: '200px',
      //    data: { name: "Shift Alert", description: "You can't add assignment more than 36 Hrs in over time" }
      //  });
      //  dialogRef.afterClosed().subscribe(result => {
      //  });
      //}
      else {
        if (((new Date(this.rosterassignmentModel.startDate).getTime()) == (new Date(this.rosterassignmentModel.endDate).getTime())) &&
          (this.rosterassignmentModel.fromTime === this.rosterassignmentModel.toTime)) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "No Shift Alert!", description: "Provide time range to add new assignment" }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        } else {
          this.getCurrentTotalhour();
          if (this.totalHourStringCur.length > 5) {
            this.hint = "Time range not valid";
          } else {
             this.hint = '';
            let duplicateentry = this.duplicateTimeRange();
            if (duplicateentry == false) {
              this.rosterAssignmentsModel.push(this.rosterassignmentModel);
              this.shiftHrsEnd();
              this.rosterassignmentModel = this.initializeRosterAssignmentModel();
              this.ActiveModel();
              this.employee = 0;
              this.shiftHrsEnd();
              this.station = null;
              this.position = null;
              this.unit = null;
              this.assignment = 0;
              this.activityCodeVar = this.defaultActivityKey;
              this.notes = '';
              this.fromTimeVar = '07:00 AM';
              this.toTimeVar = '07:00 AM';
              this.startDateVar = null;
              this.endDateVar = null;
              this.activityCodeValue = "";
              this.hint1 = '';
            } else {

            }
            
          }          
        }        
      }  
     // this.setDefaultTime();
    }
    else {

      if (this.therosterAssignmentsModel.length > 0) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Duplicate Shift Alert", description: "The selected Time is already exists!" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      this.duplicateTimeRange();
      
      //this.hint1 = 'The selected Time is already exists!';
    }
    this.shiftHrsEnd();
   this.getCurrentTotalhour();    
    this.resetDefaultTime();
    this.overlapValidation();
    if (this.datehint2 != "" && this.shiftSum<24) {
      this.datehint2 = "For New Addition change time  ";
      //this.datehint2 !='';
    } else {
      this.datehint2 = "";
    }
   // this.getdifferenceOfHrs();
  }

  Shift_24_36(){

}

  duplicateTimeRange() {
    if ((new Date(this.rosterassignmentModel.startDate).getTime() == new Date(this.rosterassignmentModel.endDate).getTime()) &&
      (this.rosterassignmentModel.fromTime == this.rosterassignmentModel.toTime)) {
      //const dialogRef = this.dialog.open(ErrorDialogComponent, {
      //  width: '500px',
      //  height: '200px',
      //  data: { name: "Duplicate Shift Alert", description: "Provide time range to add new assignment" }
      //});
      //dialogRef.afterClosed().subscribe(result => {
      //});
      if (this.therosterAssignmentsModel.length > 0) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Duplicate Shift Alert", description: "The selected Time is already exists!" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      
      return true;
    } else {
      return false;
    }

  }

  resetDefaultTime() {
   // var hoursMinutes = this.totalHourString.split(/[.: ]/);
   // var hours = parseInt(hoursMinutes[0], 10);
   // var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
   //// this.zone1 = hoursMinutes[2].toString();
   // this.toTime = hours + minutes / 60;
    //if (this.totalHourString = "24:00") {
    //  this.hint1 != '';
    //} else {
    //  this.hint1 == '';
    //}

    let therosterAssignmentsModel: RosterAssignment[] = [];
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
    }

    if (therosterAssignmentsModel.length>0) {
      this.startDateVar = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].endDate;
      this.endDateVar = new Date(new Date(this.shiftDate).setDate(new Date(this.shiftDate).getDate() + 1));
      this.fromTimeVar = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].toTime;
    }
    
    //this.toTimeVar = this.rosterAssignmentsModel[this.rosterAssignmentsModel.length - 1].toTime;    
  }

  onActivityCodeChange(event: any) {
    this.hint = "";
    this.hint1 = "";
    
    if (event.source.selected.viewValue == "Overtime") {
      this.activityCodeValue = event;

      //this.shiftHrsEnd();
      //if (this.shiftSum < 24) {
      //  this.startDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      //  this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      //} else {
      //  this.startDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      //  this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      //}

      this.startDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      this.datehint = "";
    } else {
      this.activityCodeValue = "";
      this.getCurrentTotalhour();
      this.shiftHrsInitial();
      if (this.shiftSumTemp <= 24) {
        this.hint = "";
      } else {
        this.hint = "More than 24 hrs shift  not allowed";
      }
      if (this.totalhours <= 24) {
        this.datehint = "";
      } else {
        this.datehint = "More than 24 hrs shift  not allowed";
      }
      //this.datehint = "";
      //this.datehint = "";
      //if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      //  this.hint = "";
      //  this.hint1 = "";
      //  if (this.fromTime < this.toTime && this.zone == this.zone1) {
      //    this.hint1 = "More than 24 hrs shift  not allowed";
      //  } else if (this.zone != this.zone1) {
      //    this.hint1 = "More than 24 hrs shift  not allowed";
      //  } else {
      //    this.hint1 = "";
      //  }
      //}
      //this.startDateVar1 = this.startDateVar;
      //this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    }
   // this.enddateenable = true;
  }
  

  dateevent1(fromDate: Date) {
    this.fromDate = fromDate;
    this.getTotalHoursParams(this.startDateVar, this.endDateVar, this.fromTimeVar, this.toTimeVar);
    this.startDateVar1 = this.startDateVar;
    this.getCurrentTotalhour();
    if (this.totalhours < 24) {
      this.datehint = "";
    } else {
      this.datehint = "More than 24 hrs shift  not allowed";
    }
    if ((new Date(this.fromDate).getTime()) == (new Date(this.shiftDate).getTime())) {
      this.endDateVar1 = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
      this.endDateVar = new Date(new Date(this.startDateVar).setDate(new Date(this.startDateVar).getDate() + 1));
    } else {
      //this.endDateVar1 = this.fromDate;
      this.endDateVar = new Date(new Date(this.shiftDate).setDate(new Date(this.shiftDate).getDate() + 1));
    }
    this.toDate = this.endDateVar;    
    this.datehint = "";   
    this.getCurrentTotalhour();
   // this.getdifferenceOfHrs();
    //if (this.totalHourStrin+g.length > 5) {
    //  this.hint1 = "End Time Should be greater than Start Time"      
    //} else {
    //  this.hint1 = "";      
    //}
    this.overlapValidation();
  }

  dateevent2(toDateev: Date) {
    this.toDate = toDateev;
    this.getCurrentTotalhour();
    if (this.totalhours < 24) {
      this.datehint = "";
    } else {
      this.datehint = "More than 24 hrs shift  not allowed";
    }
  //  this.getdifferenceOfHrs();
    //if ((new Date(this.toDate).getTime()) > (new Date(this.startDateVar).getTime())) {
    //  this.hint = "";
    //  if (this.fromTime < this.toTime && this.zone == this.zone1) {
    //    this.hint1 = "More than 24 hrs shift  not allowed";
    //  } else if (this.zone != this.zone1) {
    //    this.hint1 = "More than 24 hrs shift  not allowed";
    //  } else {
    //    this.hint1 = "";
    //  }

    //} else if ((new Date(this.toDate).getTime()) == (new Date(this.startDateVar).getTime())) {
    //  if (this.fromTime > this.toTime && this.zone == this.zone1) {
    //    this.hint1 = "Increase end date to extend shift hours (OR)";
    //    this.hint = "Provide End Time greater than Start Time";
    //  } else if (this.fromTime > this.toTime && this.zone != this.zone1) {
    //    this.hint1 = "";
    //    this.hint = "";
    //  } else if (this.fromTime > this.toTime && this.zone != this.zone1) {
    //    this.hint1 = "";
    //    this.hint = "";
    //  } else {
    //    this.hint1 = "";
    //    this.hint = "";
    //  }
    //}
    //this.getCurrentTotalhour();
    //if (this.totalHourString.length > 5) {
    //  this.hint1 = "End Time Should be greater than Start Time"      
    //} else {
    //  this.hint1 = "";      
    //}
  //  this.getdifferenceOfHrs();
    this.overlapValidation();
  }

  

  appendZero(mins: number) {
    if (mins < 10) {
      return '0' + mins;
    }
    else
      return mins;
  }

  timeConvertor(time: any) {    
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1].toLowerCase();

    if (AMPM == "pm" && hours < 12) hours = hours + 12;
    if (AMPM == "am" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

   return sHours + ':' + sMinutes;
  }

  getTotalhour(index: number) {
    let startDate = this.datepipe.transform(this.rosterAssignmentsModel[index].startDate, 'yyyy-MM-dd');
    //this.rosterAssignmentsModel[index].endDate = new Date();
    let endDate = this.datepipe.transform(this.rosterAssignmentsModel[index].endDate, 'yyyy-MM-dd');
    //var index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId = id)
    var fromTime = this.rosterAssignmentsModel[index].fromTime;
    var toTime = this.rosterAssignmentsModel[index].toTime;

    var stDate: any = new Date(startDate);
    var edDate: any = new Date(endDate);

    var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;


    stDate.setHours(hours, minutes);
    if (hoursMinutes.includes("PM") && hours !== 12) {
      hours += 12;
      stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
    }
    this.fromTime = hours + minutes / 60;

    this.thFromTime = this.fromTime;
    
    var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);

    let diffMs = (edDate - stDate);
    //let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var startTimeinNumber = (new Date(stDate).getTime() / 1000);
    var endTimeinNumber = (new Date(edDate).getTime() / 1000);
    var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;
    var minDiff = (endTimeinNumber - startTimeinNumber) / 60;
    return this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);
  }
  getTotalHoursParams(startDatePms:Date,endDatepms:Date,startTimepms:string,endTimepms:string) {
    let startDate = this.datepipe.transform(startDatePms, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(endDatepms, 'yyyy-MM-dd');
    var fromTime = startTimepms;
    var toTime = endTimepms;

    var stDate: any = new Date(startDate);
    var edDate: any = new Date(endDate);

    var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

    this.fromDate = stDate.setHours(hours, minutes);
    this.CurstartDate = stDate.setHours(hours, minutes);
    stDate.setHours(hours, minutes);
    if (hoursMinutes.includes("PM") && hours !== 12) {
      hours += 12;
      stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
    }

    this.fromTime = hours + minutes / 60;

    this.thFromTime = this.fromTime;
    var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);
    this.toDate = edDate.setHours(hours, minutes);
    this.CurendDate = edDate.setHours(hours, minutes);
    //if (stDate < edDate) { 
    //  alert('true')
    //} else {
    //  alert('false')
    //}

    let diffMs = (edDate - stDate);
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var startTimeinNumber = (new Date(stDate).getTime() / 1000);
    var endTimeinNumber = (new Date(edDate).getTime() / 1000);
    var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;
    var minDiff = (endTimeinNumber - startTimeinNumber) / 60;
    this.totalHourStringCur = this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);


    var hoursMinutes = this.totalHourStringCur.split(/[ .:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    this.totalhours = hours + minutes / 60;
    this.totalHrsArray.push(this.totalhours);
    if (this.totalHourStringCur.length > 5) {
      this.hint1 = "End Time Should be greater than Start Time"
    } else {
      this.hint1 = "";
    }
  }

  getCurrentTotalhour() {
    let startDate = this.datepipe.transform(this.startDateVar, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(this.endDateVar, 'yyyy-MM-dd');
    var fromTime = this.fromTimeVar;
    var toTime = this.toTimeVar;

    var stDate: any = new Date(startDate);
    var edDate: any = new Date(endDate);

    var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

    this.fromDate = stDate.setHours(hours, minutes);
    this.CurstartDate = stDate.setHours(hours, minutes);
    stDate.setHours(hours, minutes);
    if (hoursMinutes.includes("PM") && hours !== 12) {
      hours += 12;
      stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
    }

    this.fromTime = hours + minutes / 60;

    this.thFromTime = this.fromTime;
    var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);
    this.toDate = edDate.setHours(hours, minutes);
    this.CurendDate = edDate.setHours(hours, minutes);
    //if (stDate < edDate) { 
    //  alert('true')
    //} else {
    //  alert('false')
    //}

    let diffMs = (edDate - stDate);
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var startTimeinNumber = (new Date(stDate).getTime() / 1000);
    var endTimeinNumber = (new Date(edDate).getTime() / 1000);
    var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;
    var minDiff = (endTimeinNumber - startTimeinNumber) / 60;
    this.totalHourStringCur = this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);

    
    var hoursMinutes = this.totalHourStringCur.split(/[ .:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;    
    this.totalhours = hours + minutes / 60;
    if (this.totalHourStringCur.length > 5) {
      this.hint1 = "End Time Should be greater than Start Time"
    } else {
      this.hint1 = "";
    }   
  } 


  convertMilliSec(duration) {
    //var milliseconds = parseInt((duration % 1000) / 100),
    //  seconds = Math.floor((duration / 1000) % 60),
    //  minutes = Math.floor((duration / (1000 * 60)) % 60),
    //  hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    //hours = (hours < 10) ? "0" + hours : hours;
    //minutes = (minutes < 10) ? "0" + minutes : minutes;
    //seconds = (seconds < 10) ? "0" + seconds : seconds;
    //return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }
  


  getdifferenceOfHrs() {
    let therosterAssignmentsModel: RosterAssignment[] = [];
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
    }

    if (therosterAssignmentsModel.length > 0) {
      if (this.OnEdit == true) {

      }
      else if (this.OnEdit == false) {

        let startDate = this.datepipe.transform(therosterAssignmentsModel[therosterAssignmentsModel.length - 1].endDate, 'yyyy-MM-dd');
        let endDate = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
        var fromTime = therosterAssignmentsModel[therosterAssignmentsModel.length - 1].toTime;
        var toTime = this.fromTimeVar;

        var stDate: any = new Date(startDate);
        var edDate: any = new Date(endDate);

        var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
        var hours = parseInt(hoursMinutes[0], 10);
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

        this.fromDate = stDate.setHours(hours, minutes);
        this.CurstartDate = stDate.setHours(hours, minutes);
        stDate.setHours(hours, minutes);
        if (hoursMinutes.includes("PM") && hours !== 12) {
          hours += 12;
          stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
        }

        this.fromTime = hours + minutes / 60;

        this.thFromTime = this.fromTime;
        var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
        var hours = parseInt(hoursMinutes[0], 10);
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        edDate.setHours(hours, minutes);
        this.toDate = edDate.setHours(hours, minutes);
        this.CurendDate = edDate.setHours(hours, minutes);
        //if (stDate < edDate) {
        //  alert('true')
        //} else {
        //  alert('false')
        //}

        let diffMs = (edDate - stDate);
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        var startTimeinNumber = (new Date(stDate).getTime() / 1000);
        var endTimeinNumber = (new Date(edDate).getTime() / 1000);
        var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;
        var minDiff = (endTimeinNumber - startTimeinNumber) / 60;
        this.totalHourString1 = this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);
        if (this.totalHourString1.length > 5) {
          this.hint = "Time Range should be greater than previous assignment"
        } else {
          this.hint = "";
        }
      }
    }
  }

  getPredifferenceOfHrs() {    
    let therosterAssignmentsModel: RosterAssignment[] = [];
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
    }
    if (this.OnEdit == true && this.editIndex > 0) {
      let startDate = this.datepipe.transform(therosterAssignmentsModel[this.editIndex - 1].endDate, 'yyyy-MM-dd');
      let endDate = this.datepipe.transform(therosterAssignmentsModel[this.editIndex].startDate, 'yyyy-MM-dd');
      var fromTime = therosterAssignmentsModel[this.editIndex - 1].toTime;
      var toTime = therosterAssignmentsModel[this.editIndex].fromTime;

      var stDate: any = new Date(startDate);
      var edDate: any = new Date(endDate);

      var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
      var hours = parseInt(hoursMinutes[0], 10);
      var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

      this.fromDate = stDate.setHours(hours, minutes);
      this.CurstartDate = stDate.setHours(hours, minutes);
      stDate.setHours(hours, minutes);
      if (hoursMinutes.includes("PM") && hours !== 12) {
        hours += 12;
        stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
      }

      this.fromTime = hours + minutes / 60;

      this.thFromTime = this.fromTime;
      var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
      var hours = parseInt(hoursMinutes[0], 10);
      var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
      edDate.setHours(hours, minutes);
      this.toDate = edDate.setHours(hours, minutes);
      this.CurendDate = edDate.setHours(hours, minutes);   

      let diffMs = (edDate - stDate);
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      var startTimeinNumber = (new Date(stDate).getTime() / 1000);
      var endTimeinNumber = (new Date(edDate).getTime() / 1000);
      var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;
      var minDiff = (endTimeinNumber - startTimeinNumber) / 60;
      this.totalHourStringPre = this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);
      if (this.totalHourStringPre.length > 5) {
        this.datehint2 = "Time Range should be greater than previous assignment"
      } else {
        this.datehint2 = "";
      }
    }
  }
  getNextdifferenceOfHrs() {

  }

  CurdateTimeconverter(startDatePms: Date, endDatepms: Date, startTimepms: string, endTimepms: string) {
    let startDate = this.datepipe.transform(startDatePms, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(endDatepms, 'yyyy-MM-dd');
    var fromTime = startTimepms;
    var toTime = endTimepms;

    var stDate: any = new Date(startDate);
    var edDate: any = new Date(endDate);

    var hoursMinutes1 = this.timeConvertor(fromTime).split(/[.: ]/);
    var hours1 = parseInt(hoursMinutes1[0], 10);
    var minutes1 = hoursMinutes1[1] ? parseInt(hoursMinutes1[1], 10) : 0;
    stDate.setHours(hours1, minutes1);
    this.fromDate = stDate;

    var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);
    this.toDate = edDate;
  }
  ModeldateTimeconverter(startDatePms: Date, endDatepms: Date, startTimepms: string, endTimepms: string) {
    let startDate = this.datepipe.transform(startDatePms, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(endDatepms, 'yyyy-MM-dd');
    var fromTime = startTimepms;
    var toTime = endTimepms;

    var stDate: any = new Date(startDate);
    var edDate: any = new Date(endDate);

    var hoursMinutes1 = this.timeConvertor(fromTime).split(/[.: ]/);
    var hours1 = parseInt(hoursMinutes1[0], 10);
    var minutes1 = hoursMinutes1[1] ? parseInt(hoursMinutes1[1], 10) : 0;
    stDate.setHours(hours1, minutes1);
    this.PrestartDate = stDate;

    var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);
    this.PreendDate = edDate;
  }
  
  timevent(event: string) {
    this.hint = "";
    this.hint1 = "";
   this.fromTimeVar = event;
    this.getCurrentTotalhour();
    if (this.activityCodeValue != "") {
      this.datehint = "";
    } else {
      if (this.totalhours > 24) {
        this.datehint = "More than 24 hrs shift  not allowed";
      } else {
        this.datehint = "";
      }
      //if ((toEndtime > 7 ) || this.zone=="PM") {
      //  this.datehint = "More than 24 hrs shift  not allowed";
      //} else {
      //  this.datehint = "";
      //}
    }
    //this.getdifferenceOfHrs();
    //this.getPredifferenceOfHrs(); 
    if (this.hint3 == undefined) {
      this.hint3 = "";
    }
    if (this.hint == undefined) {
      this.hint = "";
    }
    if (this.hint1 == undefined) {
      this.hint = "";
    } 
    this.overlapValidation();
    //interval interval1 = new interval();    

  }



  overlapValidation() {
    let therosterAssignmentsModel: RosterAssignment[] = [];
    for (var i = 0; i < this.rosterAssignmentsModel.length; i++) {
      if (this.rosterAssignmentsModel[i].isActive == true) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
    }
    //this.ModeldateTimeconverter(therosterAssignmentsModel[i].startDate, therosterAssignmentsModel[i].endDate,
    //  therosterAssignmentsModel[i].fromTime, therosterAssignmentsModel[i].toTime);    
  //  var sortObject: any = therosterAssignmentsModel.sort(function (a, b) { return a.endDate.getTime() - b.endDate.getTime() });
    //this.therosterAssignmentsModel = therosterAssignmentsModel.sort((obj1, obj2) => {

    //  if (obj1.station > obj2.station) {
    //    return 1;
    //  }

    //  if (obj1.station < obj2.station) {
    //    return -1;
    //  }

    //  return 0;
    //});
   // therosterAssignmentsModel = sortObject;
    //this.therosterAssignmentsModel.push();

    if (this.OnEdit == false) {
      this.CurdateTimeconverter(this.startDateVar, this.endDateVar, this.fromTimeVar, this.toTimeVar);
      for (let i = 0; i < therosterAssignmentsModel.length; i++) {
        this.ModeldateTimeconverter(therosterAssignmentsModel[i].startDate, therosterAssignmentsModel[i].endDate,
          therosterAssignmentsModel[i].fromTime, therosterAssignmentsModel[i].toTime);
        let overlap = this.overlap(this.fromDate, this.toDate, new Date(this.PrestartDate), new Date(this.PreendDate));
        if (overlap == true) {
          this.datehint2 = "Time Range Overlapping!! Correct the Start/End Time Before add new"
          break;
          //        alert("Overlap with"+(i+1)+"assignment");
        } else {
          this.datehint2 = "";
        }
      }
    } else if (this.OnEdit == true) {
      this.CurdateTimeconverter(this.startDateVar, this.endDateVar, this.fromTimeVar, this.toTimeVar);
      for (let i = 0; i < therosterAssignmentsModel.length; i++) {
        if (i!=this.index) {
          this.ModeldateTimeconverter(therosterAssignmentsModel[i].startDate, therosterAssignmentsModel[i].endDate,
            therosterAssignmentsModel[i].fromTime, therosterAssignmentsModel[i].toTime);
          let overlap = this.overlap(this.fromDate, this.toDate, new Date(this.PrestartDate), new Date(this.PreendDate));
          if (overlap == true) {
            this.datehint2 = "Time Range Overlapping!! Correct the Start/End Time Before add new"
            break;
            //        alert("Overlap with"+(i+1)+"assignment");
          } else {
            this.datehint2 = "";
          }
        }
      }
    }
    
  }

  overlap(start1:Date, end1:Date, start2:Date, end2:Date){
  return start1.getTime() < end2.getTime() && start2.getTime() < end1.getTime();
}

   timevent1(event1: string) {
     this.hint = "";
     this.hint1 = "";
    this.toTimeVar = event1;
    this.getCurrentTotalhour();
    if ((new Date(this.endDateVar).getTime()) > (new Date(this.shiftDate).getTime())) {
      var eventtime = event1;
      var hoursMinutes = eventtime.split(/[ .:]/);
      var hours = parseInt(hoursMinutes[0], 10);
      var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
      this.zone = hoursMinutes[2].toString();       
      this.toTime = toEndtime;
      if (hours == 12) {  
        var toEndtime = 0;
      } else {
        toEndtime = hours + minutes / 60;
      }
      if (this.activityCodeValue != "") {
        this.datehint = "";
      } else {
        if (this.totalhours > 24) {
          this.datehint = "More than 24 hrs shift  not allowed";
        } else {
          this.datehint = "";
        }
        //if ((toEndtime > 7 ) || this.zone=="PM") {
        //  this.datehint = "More than 24 hrs shift  not allowed";
        //} else {
        //  this.datehint = "";
        //}
      }
      
    } else {
      this.datehint = "";
     }
     this.overlapValidation();
  }  
    

  update() { 
    this.hint = "";
    this.hint1 = "";
    this.hint2 = "";
    this.hint3 = "";    
    this.rosterassignmentModel.assignment = this.assignment;
    this.rosterassignmentModel.position = this.position;
    this.rosterassignmentModel.unit = this.unit;
    this.rosterassignmentModel.activityCode = this.activityCodeVar;
    this.rosterassignmentModel.notes = this.notes;
    this.rosterassignmentModel.fromTime = this.fromTimeVar;
    this.rosterassignmentModel.toTime = this.toTimeVar;
    this.rosterassignmentModel.startDate = this.startDateVar;
    this.rosterassignmentModel.endDate = this.endDateVar;
    this.rosterassignmentModel.isActive = true;
    this.rosterassignmentModel.station = this.station;

    this.rosterassignmentModel.startDateStr = this.getDateAsString(new Date(this.startDateVar));
    this.rosterassignmentModel.endDateStr = this.getDateAsString(new Date(this.endDateVar));

    this.shiftHrsInitial();
    //this.shiftHrsEnd();
    this.duplicateTimeRange();
    this.getCurrentTotalhour();
    this.ActiveModel();
    // this.onActivityCodeChange(this.activityCodeVar);
    let result = this.checkTimeOverlapse(this.rosterassignmentModel);
    if (result == true) {
      if (this.shiftSumTemp >= 24 && this.activityCodeValue == "") {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '550px',
          height: '200px',
          data: { name: "Shift Alert", description: "You can't add assignment more than 24 Hrs in normal activity code" }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      else if (this.shiftSum + this.totalhours > 36 && this.activityCodeValue != "") {
        //const dialogRef = this.dialog.open(ErrorDialogComponent, {
        //  width: '500px',
        //  height: '200px',
        //  data: { name: "Shift Alert", description: "You can't add assignment more than 36 Hrs in over time" }
        //});
        //dialogRef.afterClosed().subscribe(result => {
        //});

      }
      else {
        if (((new Date(this.rosterassignmentModel.startDate).getTime()) == (new Date(this.rosterassignmentModel.endDate).getTime())) &&
          (this.rosterassignmentModel.fromTime === this.rosterassignmentModel.toTime)) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "No Shift Alert!", description: "Provide time range to add new assignment" }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        } else {
          this.getCurrentTotalhour();
          if (this.totalHourStringCur.length > 5) {
            this.hint = "Time range not valid";
          } else {
            this.hint = '';
            let duplicateentry = this.duplicateTimeRange();
            if (duplicateentry == false) {
              //update code
              this.rosterAssignmentsModel[this.index] = this.rosterassignmentModel;

              //this.rosterAssignmentsModel.push(this.rosterassignmentModel);
              this.shiftHrsEnd();
              this.rosterassignmentModel = this.initializeRosterAssignmentModel();
              this.ActiveModel();
              this.employee = 0;
              this.shiftHrsEnd();
              this.station = null;
              this.position = null;
              this.unit = null;
              this.assignment = 0;
              this.activityCodeVar = this.defaultActivityKey;
              this.notes = '';
              this.fromTimeVar = '07:00 AM';
              this.toTimeVar = '07:00 AM';
              this.startDateVar = null;
              this.endDateVar = null;
              this.activityCodeValue = "";
              this.hint1 = '';
              this.employeeResutObj = this.employeeResutObjActive;

            } else {

            }

          }
        }
      }
      // this.setDefaultTime();
    }
    else {

      if (this.therosterAssignmentsModel.length > 0) {
        //const dialogRef = this.dialog.open(ErrorDialogComponent, {
        //  width: '500px',
        //  height: '200px',
        //  data: { name: "Duplicate Shift Alert", description: "The selected Time is already exists!" }
        //});
        //dialogRef.afterClosed().subscribe(result => {
        //});
      }
      this.duplicateTimeRange();

      //this.hint1 = 'The selected Time is already exists!';
    }  


    this.ResetAssignment(); 
    //this.resetDefaultTime();
    this.shiftHrsEnd();
    //this.hint2 = "";
    this.OnEdit = false;
    this.updatebtn = false;
    this.resetbtn = true;
    this.addassbtn = true;
    this.updatebtn = false;
  }

  editAssignment(Id: number) {
    this.updatebtn = true;
    this.enddateenable = true;
    this.hint = "";
    this.hint1 = "";
    this.hint2 = "";
    this.hint3 = "";
    this.datehint2 = "";
    let therosterAssignmentsModel: RosterAssignment[] = [];
    for (var i = 0; i < Id; i++) {
      if (this.rosterAssignmentsModel[i].isActive == false) {
        therosterAssignmentsModel.push(this.rosterAssignmentsModel[i]);
      }
      this.editIndex = Id - therosterAssignmentsModel.length;
    }
    // this.editIndex = Id;
    this.OnEdit = true;
    //const index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId == Id);
    const index = Id;
    this.index = index;
    if (index >= 0) {
      // this.employeeResutObj = [];
      this.employeeResutObj = this.employeeResutObjAll;
      this.rosterassignmentModel = this.rosterAssignmentsModel[index];
      this.assignment = this.rosterassignmentModel.assignment;
      //let employeeIndex = this.employeeResutObj.findIndex(x => x.employeeId == this.assignment);
      //let firstName: string = this.employeeResutObj[employeeIndex].firstName;
      //let miName: string = this.employeeResutObj[employeeIndex].miName;
      //let lastName: string = this.employeeResutObj[employeeIndex].lastName;

      //firstName = (firstName == null) ? "" : firstName;
      //miName = (miName == null) ? "" : miName;
      //lastName = (lastName == null) ? "" : lastName;

      //this.employee[index]=(firstName + ' ' + miName + ' ' + lastName);

      this.employee = this.rosterassignmentModel.assignment;
      this.position = this.rosterassignmentModel.position;
      this.unit = this.rosterassignmentModel.unit;
      this.activityCodeVar = this.rosterassignmentModel.activityCode;
      this.notes = this.rosterassignmentModel.notes;
      this.fromTimeVar = this.rosterassignmentModel.fromTime;
      this.toTimeVar = this.rosterassignmentModel.toTime;
      this.startDateVar = this.rosterassignmentModel.startDate;
      this.endDateVar = this.rosterassignmentModel.endDate;
      this.station = this.rosterassignmentModel.station;
      //this.isActive = true;
      //this.rosterAssignmentsModel.push(this.rosterassignmentModel);

      //let therosterAssignmentsModel: RosterAssignment;
      //therosterAssignmentsModel = this.rosterAssignmentsModel[index];
      //this.rosterassignmentModel = therosterAssignmentsModel;
    }
    this.updatebtn = true;
    this.resetbtn = true;
    this.addassbtn = false;
    this.overlapValidation();
    return false;
  }

  deleteAssignment(Id: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        //const index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId == Id);
        //this.rosterAssignmentsModel;
        const index = Id;
        if (index >= 0) {
          this.rosterAssignmentsModel[index].isActive = false;          
          this.shiftHrsEnd();
          this.ActiveModel();
          if (this.therosterAssignmentsModel.length == 0) {
            this.shiftSum = 0;
            this.ResetAssignment();
          } else {
            this.resetDefaultTime();
          }          
          this.updatebtn = false;
        }
        else {
          this.rosterAssignmentsModel[index].position = 0;
          this.rosterAssignmentsModel[index].unit = 0;
          this.rosterAssignmentsModel[index].assignment = null;
          this.rosterAssignmentsModel[index].activityCode = 0;
          this.rosterAssignmentsModel[index].fromTime = null;
          this.rosterAssignmentsModel[index].toTime = null;
          this.rosterAssignmentsModel[index].notes = "";
          this.rosterAssignmentsModel[index].station = null;
        }
        // return false;
      }      
    }
      );
    setTimeout(() => {    
      this.shiftHrsEnd();
      this.ActiveModel();
      if (this.therosterAssignmentsModel.length == 0) {
        this.shiftSum = 0;
        this.ResetAssignment();
      } else {
        this.resetDefaultTime();
      }     
      this.updatebtn = false;
    }, 1000);
    return false;
  }

  ResetAssignment() {
    this.employee = 0;
    this.assignment = 0;
    this.position = 0;
    this.unit = 0;
    this.activityCodeVar = null;
    this.notes = '';
    this.fromTimeVar = '';
    this.toTimeVar = '';
    this.startDateVar = null;
    this.endDateVar = null;
    this.resetOption1 = true;
    this.rosterassignmentModel = this.initializeRosterAssignmentModel();
    this.hint = "";
    this.hint1 = "";
    //  this.hint2 = "";
    this.datehint = "";
    this.station = null;
    this.updatebtn = false;
    this.addassbtn = true;
   // this.activityCodeVar = this.rosterassignmentModel.activityCode;
    this.activityCodeVar = this.defaultActivityKey;
    this.datehint2 = '';
    this.setDefaultTime();
    this.employeeResutObj = this.employeeResutObjActive;
    //this.overlapValidation();
  }

  loadAssignments() {
    this.rosterAssignmentService.loadAssignments('getAssignments').subscribe(res => {
      this.rosterAssignments = res;
    });
    return false;
  }

  onStationChange() {
    this.overlapValidation();
  }
  GoBack() {
    this.dialog.closeAll();
    //this.routerService.navigate(['/stroasterreport']);
  }


  public updateAssignment() {
    this.isClicked = true;
    //let crewIndex = this.crewCode.findIndex(x => x.systemCodeId == this.stRosterModel.crew);
    //let crewName: string = this.crewCode[crewIndex].type;

    //let stationIndex = this.stationCode.findIndex(x => x.systemCodeId == this.stRosterModel.station);
    //let stationName: string = this.stationCode[stationIndex].type;

    //for (let y = 0; y < this.employee.length; y++) {
    //  let employeeIndex = this.employeeResutObj.findIndex(x => x.employeeId == this.employee[y]);
    //  let firstName: string = this.employeeResutObj[employeeIndex].firstName;
    //  let miName: string = this.employeeResutObj[employeeIndex].miName;
    //  let lastName: string = this.employeeResutObj[employeeIndex].lastName;

    //  firstName = (firstName == null) ? "" : firstName;
    //  miName = (miName == null) ? "" : miName;
    //  lastName = (lastName == null) ? "" : lastName;
    //}

    this.stRosterModel.rosterAssignments = this.rosterAssignmentsModel;
    for (let i = 0; i < this.rosterAssignmentsModel.length; i++) {
      this.rosterAssignmentsModel[i].startDate = new Date(this.rosterAssignmentsModel[i].startDate);
      var dth = this.rosterAssignmentsModel[i].startDate.getHours();
      dth = dth + 5;
      var dtm = this.rosterAssignmentsModel[i].startDate.getMinutes();
      dtm = dtm + 30;
      this.rosterAssignmentsModel[i].startDate.setHours(dth);
      this.rosterAssignmentsModel[i].startDate.setMinutes(dtm);

      this.rosterAssignmentsModel[i].endDate = new Date(this.rosterAssignmentsModel[i].endDate);
      var dth = this.rosterAssignmentsModel[i].endDate.getHours();
      dth = dth + 5;
      var dtm = this.rosterAssignmentsModel[i].endDate.getMinutes();
      dtm = dtm + 30;
      this.rosterAssignmentsModel[i].endDate.setHours(dth);
      this.rosterAssignmentsModel[i].endDate.setMinutes(dtm);

      this.rosterAssignmentsModel[i].startDateStr = this.getDateAsString( new Date(this.rosterAssignmentsModel[i].startDate));
      this.rosterAssignmentsModel[i].endDateStr = this.getDateAsString( new Date(this.rosterAssignmentsModel[i].endDate));

    }
    //this.stRosterModel.shiftDate = this.getDateFormat(this.stRosterModel.shiftDateStr);
    this.stRosterModel.shiftDateStr = this.getDateAsString(new Date(this.stRosterModel.shiftDate));
    
    this.stRosterId = this.stRosterModel.stRosterId;
    if (this.stRosterId > 0) {     
      this.stRosterService.updateRoster(this.stRosterId, this.stRosterModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
           // this.ResetRoster();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
          });
        }
      });
      //});
    }
    else {
      this.stRosterService.saveStRoster('saveStRoster', this.stRosterModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
           // this.ResetRoster();
          });
        }
        else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.GoBack();
          });
        }
      });
    }

  }

  getEmployeeName(empId: number) {
    let employeeIndex = this.employeeResutObjAll.findIndex(x => x.employeeId == empId);
    if (employeeIndex >= 0) {
      let firstName: string = this.employeeResutObjAll[employeeIndex].firstName;
      let miName: string = this.employeeResutObjAll[employeeIndex].miName;
      let lastName: string = this.employeeResutObjAll[employeeIndex].lastName;

      firstName = (firstName == null) ? "" : firstName;
      miName = (miName == null) ? "" : miName;
      lastName = (lastName == null) ? "" : lastName;

      return (lastName + ', ' + miName +' '+ firstName );

    }
    else {
      return "";
    }

  }

  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      //this.employeeResutObj = res;
      this.employeeResutObjAll = res;
      this.employeeResutObj = res.filter(x => x.isActive == true);
      this.employeeResutObjActive = res.filter(x => x.isActive == true);
      //this.blockUI.stop();
    }, err => {
      //this.blockUI.stop();
    });
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getAllPersonnel(),
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllUnit(),
      this.systemCodeService.getAllAssignment(),
      this.systemCodeService.getAllStations(),
      this.systemCodeService.getAllActivityCode(),
      //this.systemCodeService.getSystemCodesForRanks()
      this.systemCodeService.getAllPositions()
      
    ).subscribe(([res1, res2, res3, res4, res5, res6, res7]) => {
      this.personnelCode = res1 || [];
      this.crewCode = res2 || [];
      this.unitCode = res3 || [];
      this.assignmentCode = res4 || [];
      this.stationCode = res5 || [];
      this.activityCode = res6 || [];
      this.positionsCode = res7 || [];
      this.personnelKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.unitKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.assignmentKey = res4.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.activityCodeKey = res6.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.positionKey = res7.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.crewKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.stationKey = res5.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];

      // set Regular work schedule as default
      const activityIndex = this.activityCodeKey.findIndex(x => x.value == this.defaultActivity);
      this.defaultActivityKey = this.activityCodeKey[activityIndex].key;
      this.activityCodeVar = this.defaultActivityKey;

      
      const overTimeIndex = this.activityCodeKey.findIndex(x => x.value == this.overtimeActivity);
      this.overTimeCode = this.activityCodeKey[overTimeIndex].key;
      //for (let i = 0; i < this.activityCodeKey.length; i++) {
      //  if (this.activityCodeKey[i].value == "Overtime") {
      //    this.overTimeSystemcode = this.activityCodeKey[i].key;
      //    break;
      //  }
      //}
    });

  }

  
    canceldialog(): void {      
      //if (this.assignmentForm.dirty) {
        const dialogRef = this.dialog.open(DeleteAssignmentComponent, {
        width: '400px',
      });
    //} else {
      //this.dialog.closeAll();
    //}  
  }
  setDefaultTime() {
    this.startDateVar = this.shiftDate;
    this.endDateVar = new Date(new Date(this.shiftDate).setDate(new Date(this.shiftDate).getDate() + 1));
    this.fromTimeVar = '07:00 AM';
    this.toTimeVar = '07:00 AM';
  }
  getUnitName(unitId:number) {

    const index = this.unitKey.findIndex(x => x.key == unitId);
    if (index >= 0) {
      return this.unitKey[index].value;
    }
    else {
      return "";
    }
  }
  getStationName(stationId: number) {

    const index = this.stationKey.findIndex(x => x.key == stationId);
    if (index >= 0) {
      return this.stationKey[index].value;
    }
    else {
      return "";
    }
  }
  getDateFormat(date: string) {
    return new Date( Date.parse(date));
  }
  getPositionName(positionId: number) {

    const index = this.positionKey.findIndex(x => x.key == positionId);
    if (index >= 0) {
      return this.positionKey[index].value;
    }
    else {
      return "";
    }
  }
  getActivityCode(activityId: number) {

    const index = this.activityCodeKey.findIndex(x => x.key == activityId);
    if (index >= 0) {
      return this.activityCodeKey[index].value;
    }
    else {
      return "";
    }
  }
  checkTimeOverlapse(rosterAssignment: RosterAssignment) { 

    let newStartTime = this.addTimeToDate(rosterAssignment.startDate, rosterAssignment.fromTime);
    let newEndTime = this.addTimeToDate(rosterAssignment.endDate, rosterAssignment.toTime);
    for (let index = 0; index < this.rosterAssignmentsModel.length; index++) {
      if (this.rosterAssignmentsModel[index].isActive == true) {
        let stTime = this.addTimeToDate(this.rosterAssignmentsModel[index].startDate, this.rosterAssignmentsModel[index].fromTime);
        let edTime = this.addTimeToDate(this.rosterAssignmentsModel[index].endDate, this.rosterAssignmentsModel[index].toTime);

        if (newStartTime > stTime && newStartTime < edTime) {// stTimenewEndTime <= ) {
          return false;
        }
        else if (newEndTime > stTime && newEndTime < edTime) {
          return false;
        }
        else {
          //  return true;
        }
        let diffMs = (edTime - stTime);
        this.differenceMins = this.differenceMins + diffMs;

        if (newStartTime.getTime() == stTime.getTime() && newEndTime.getTime() == edTime.getTime()) {
          return false;
        }

      }      
    }
    //if (this.differenceMins > 0) {
    //  let mis = this.differenceMins / 60000;
    //  if (mis >= 1440 && this.) {
    //    return false;
    //  }     
    //}
    //let diffSecs = (newEndTime - newStartTime);
    //if (diffSecs > 0) {
    //  let existMins = this.differenceMins / 60000;
    //  let NewEntryMins = diffSecs / 60000;
    //  if ((existMins + NewEntryMins) >= 1440) {
    //    return false;
    //  }
    //}   


    return true;
  }
  addTimeToDate(dt: Date, time: string) {

    let startDate = this.datepipe.transform(dt, 'yyyy-MM-dd');  
    let stDate: any = new Date(startDate);   

    let hoursMinutes = this.timeConvertor(time).split(/[.: ]/);
    let hours = parseInt(hoursMinutes[0], 10);
    let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    stDate.setHours(hours, minutes);
    return stDate;

  }
  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null
    }
  }
}
      //let startDate = this.datepipe.transform(this.rosterAssignmentsModel[index].startDate, 'yyyy-MM-dd');
      ////this.rosterAssignmentsModel[index].endDate = new Date();
      //let endDate = this.datepipe.transform(this.rosterAssignmentsModel[index].endDate, 'yyyy-MM-dd');
      ////var index = this.rosterAssignmentsModel.findIndex(x => x.assignmentId = id)
      //var fromTime = this.rosterAssignmentsModel[index].fromTime;
      //var toTime = this.rosterAssignmentsModel[index].toTime;

      //var stDate: any = new Date(startDate);
      //var edDate: any = new Date(endDate);

      //var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
      //var hours = parseInt(hoursMinutes[0], 10);
      //var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;


      //stDate.setHours(hours, minutes);
      ////if (hoursMinutes.includes("PM") && hours !== 12) {
      ////  hours += 12;
      ////  stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
      ////}

      //// this.zone = hoursMinutes[2].toString();
      ////this.fromTime = hours + minutes / 60;

      ////this.thFromTime = this.fromTime;
      ////this.fromDate = Date.parse(this.rosterAssignmentsModel[index].startDate.toString());

      //var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
      //var hours = parseInt(hoursMinutes[0], 10);
      //var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
      //edDate.setHours(hours, minutes);

//export interface RosterAssignment {
//  assignmentId: number;
//  assignment: number;
//  position: number;
//  unit: number;
//  activityCode: number;
//  notes: string;
//  //fromTime: string;
//  //toTime: string;
//  startDate: Date;
//  endDate: Date;
//  isActive: boolean,
//  stRosterId: number;
//  station: number;
//  startDateStr: string;
//  endDateStr: string;
//}
