import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { inspection } from '../models/inspection.model';
import { ErrorService } from './error.service';
import { EmsTrainingHours } from '../models/emstraininghours.model';
import { GetAllEventDpt } from '../models/get-all-eventDpt.model';
import { GetAllEMSTrainingHours } from '../models/get-all-emstraininghours.model';
import { AuthService } from './auth.service';
@Injectable({ providedIn: 'root' })
export class EMSTraingHoursService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/EMSTrainingHour/', httpClient, errorService, authService);
  }
  saveEMSTrainingHour(action: string, emsTraingHourDto: EmsTrainingHours): Observable<any> {
    return this.postRequest(action, JSON.stringify(emsTraingHourDto));
  }
  getEMSTrainingHoursNew(action: string, startDate: Date = null, endDate: Date = null, employeeId: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<any> {

    //debugger;
    var stDate = new Date();
    if (startDate == null) {
      var lyear = stDate.getFullYear();
      var lmonth = stDate.getMonth();
      var lday = stDate.getDate();
      stDate = new Date(lyear - 50, lmonth, lday);
    }
    else {
      stDate = new Date(startDate);
    }


    var edDate = new Date();
    if (endDate == null) {
      var cyear = edDate.getFullYear();
      var cmonth = edDate.getMonth();
      var cday = edDate.getDate();
      edDate = new Date(cyear +50, cmonth, cday);
    }
    else {
      edDate = new Date(endDate);
    }

    // var edDate = new Date(endDate);
    const params = new HttpParams()
      .set('startDate', stDate.toDateString())
      .set('endDate', edDate.toDateString())
      .set('employeeId', employeeId.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);

    return this.customGetRequestWithQueryString<GetAllEMSTrainingHours>(action, params);

    // return this.customGetRequest<WaterUsage>(action, waterUsageId.toString());
  }
  getEMSTrainingStudents(action: string, emsTrainingHourId: number): Observable<string[]> {


    const params = new HttpParams()
      .set('emsTrainingHourId', emsTrainingHourId.toString());

    return this.customGetRequestWithQueryString<string[]>(action, params);
  }
  searchEMSTraining(action: string, emsTrainingHoursModel: any): Observable<Array<EmsTrainingHours>> {

    var startDate = new Date(emsTrainingHoursModel.startDate);
    var endDate = new Date(emsTrainingHoursModel.endDate);

    const params = new HttpParams()

      .set('startDate', startDate.toDateString())
      .set('endDate', endDate.toDateString())
    return this.customGetRequestWithQueryString<Array<EmsTrainingHours>>(action, params);
  }
  getEMSTrainingHours(action: string, emsTrainingHoursId: number): Observable<any> {

    return this.customGetRequest<EmsTrainingHours>(action, emsTrainingHoursId.toString());
  }
  updateEMSTrainingHours(emsTrainingHourId: number, emsTrainingModel: EmsTrainingHours): Observable<any> {

    return this.putRequest(emsTrainingHourId, JSON.stringify(emsTrainingModel));
  }
  deleteEMSTrainingHours(action: string, emsTrainingModel: EmsTrainingHours): Observable<any> {
    return this.postRequest(action, JSON.stringify(emsTrainingModel));
  }
  delEMSTrainingHours(action: string, eId: number): Observable<any> {

    return this.deleteRequest(eId);
    //return this.postRequest(action, JSON.stringify(obj));
  }
}
