import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, of } from 'rxjs';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllEmployees } from '../models/get-all-employees.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'employeeEmergencyviewreport',
  templateUrl: './employeeEmergencycontactdetails.component.html',
  
})
export class EmployeeEmergencyViewReport {
  @ViewChild('employeeReportView', { static: false }) employeeReportView: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  resultsLength: number = 0;
  employeeModel: Employee;
  employeeResultModel: Employee[] = [];
  isFound: boolean = false;
  showNewButton: boolean = false;
  showSearchMsg: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  dataSource: MatTableDataSource<Employee>;
  assignmentObjDB = new MatTableDataSource<Employee>([]);
  //dataSource: MatTableDataSource<Employee>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  displayedColumns: any[] = ['employeeNames', 'emergencyContact', 'emergencyContactNumber', 'emergencySecContact', 'emergencySecContactNumber', 'contactNotes'];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private dialog: MatDialog,
    private routerService: Router,
    private employeeService: EmployeeService,
    private route: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.employeeModel = this.initializeEmployeeModel();
      if (this.rolePermissionCheckModel) {
        this.dataSource = new MatTableDataSource(this.employeeResultModel);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.blockUI.start();
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllEmployees(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.employeeResultModel = data || [];
          //this.assignmentObjDB.data = data || [];
          //this.employeeResultModel = data || [];
          this.dataSource = new MatTableDataSource(this.employeeResultModel);
          this.dataSource.sort = this.sort;
          //this.dataSource.paginator = this.paginator;
          this.blockUI.stop();
        });
    }
  }

  getAllEmployees(pageIndex: number, pageSize: number): Observable<GetAllEmployees> {
    return this.employeeService.getAllEmployeesForEmergencyReportNew(
      'GetAllEmployeesForEmergencyReportNew',
      pageIndex,
      pageSize
    );
  }

  getAllEmployeesForDataSource(): void {
    this.getAllEmployees(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.employeeResultModel = data || [];
        this.assignmentObjDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.employeeResultModel);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.blockUI.stop();
      });
  }

  initializeEmployeeModel(): Employee {
    return {
      employeeId: 0,
      title: null,
      lastName: null,
      miName: null,
      firstName: null,
      rank: null,
      certification: null,
      ffnNumber: null,
      kemsisNumber: null,
      dateOfBirth: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      email: null,
      phone: null,
      drivingLicense: null,
      startDate: null,
      shift: null,
      payGrade: null,
      emergencyContact: null,
      emergencyContactNumber: null,
      emergencySecContact: null,
      emergencySecContactNumber: null,
      contactNotes: null,
      employeeAward: null,
      certifications: null,
      rankName: null,
      shiftName: null,
      dateOfBirthStr: null,
      startDateStr: null,
      employeeNames: null,
      isActive: null
    } as Employee;
  }

  resetEmployee() {
    this.showNewButton = false;
    this.resetTheForm();
    //this.loadGrid();
    this.getAllEmployeesForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
  }

  resetTheForm() {
    this.employeeModel = this.initializeEmployeeModel();
  }

  //loadGrid() {
  //  this.employeeService.getAllEmployees('GetEmployees', 0).subscribe((res: Array<Employee>) => {
  //    this.employeeResultModel = res || [];

  //    this.assignmentObjDB.data = this.employeeResultModel;

  //    this.dataSource = new MatTableDataSource<Employee>(this.employeeResultModel);
  //    this.dataSource.paginator = this.paginator;
  //    this.dataSource.sort = this.sort;
  //  },
  //    err => {

  //    });

  //  this.dataSource = new MatTableDataSource<Employee>(this.employeeResultModel);
  //  this.dataSource.paginator = this.paginator;
  //  this.dataSource.sort = this.sort;
  //}

  //searchEmployee() {
  //  this.blockUI.start();
  //  this.showNewButton = false;
  //  this.showSearchMsg = false;
  //  if (!((this.employeeModel.lastName == null || this.employeeModel.lastName == '') && (this.employeeModel.firstName == null || this.employeeModel.firstName == '') && (this.employeeModel.ffnNumber == null || this.employeeModel.ffnNumber == ''))) {
  //    this.showNewButton = true;
  //    this.showSearchMsg = false;
  //  } else {
  //    this.showSearchMsg = true;
  //  }

  //  this.employeeService.employeeSearch('searchEmployee', this.employeeModel).subscribe(res => {
  //    this.employeeResultModel = res;

  //    if (this.employeeResultModel.length == 0) {
  //      this.isFound = true;
  //    } else {
  //      this.isFound = false;
  //    }

  //    this.assignmentObjDB.data = this.employeeResultModel;
  //    this.dataSource = new MatTableDataSource<Employee>(this.employeeResultModel);
  //    this.dataSource.paginator = this.paginator;
  //    this.dataSource.sort = this.sort;
  //    this.blockUI.stop();
  //  }, (err: any) => {
  //    this.blockUI.stop();
  //  });
  //  return false;
  //}

  //printResults() { }

  deleteEmployee(eId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.blockUI.start();
        this.employeeService.delEmployee('delEmployee', parseInt(eId)).subscribe(res => {
          this.getAllEmployeesForDataSource();
        });
      }
    });
    return false;
  }

  addEmployee() {
    //this.blockUI.start();
    this.routerService.navigate(['/createemployee']);
    return false;
  }

  searchEmployee(): void {
    this.blockUI.start();
    this.showNewButton = false;
    this.showSearchMsg = false;
    if (!((this.employeeModel.lastName == null || this.employeeModel.lastName == '') && (this.employeeModel.firstName == null || this.employeeModel.firstName == '') && (this.employeeModel.ffnNumber == null || this.employeeModel.ffnNumber == ''))) {
      this.showNewButton = true;
      this.showSearchMsg = false;
    } else {
      this.showSearchMsg = true;
      return;
    }
    this.getAllEmployeesForDataSource();
  }

  editEmployee(eId: string) {

    this.blockUI.start();
    this.routerService.navigate(['/createemployee', { id: eId }]);
    // this.blockUI.stop();
    // return false;
    // this.routerService.navigate(['/inspection', { id: event.id }]);
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
