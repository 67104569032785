import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, of } from 'rxjs';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetAllEmployees } from '../models/get-all-employees.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../models/user.model';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';

@Component({
  selector: 'myprofile',
  templateUrl: './myProfile.component.html',

})
export class MyProfile {
  @ViewChild('employeeReportView', { static: false }) employeeReportView: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  resultsLength: number = 0;
  employeeModel: Employee;
  employeeResultModel: Employee[] = [];
  isFound: boolean = false;
  showNewButton: boolean = false;
  showSearchMsg: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  dataSource: MatTableDataSource<Employee>;
  assignmentObjDB = new MatTableDataSource<Employee>([]);
  //dataSource: MatTableDataSource<Employee>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  displayedColumns: any[] = ['employeeNames', 'emergencyContact', 'emergencyContactNumber', 'emergencySecContact', 'emergencySecContactNumber', 'contactNotes'];
  rolePermissionCheckModel: RolePermissionCheck;
  employeeProfileModel: Employee;
  userDateModel: User;
  showFieldsToEdit: boolean = false;
  employeeId: string;
  userName: string;
  phonePattern = /^\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$/;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;

  constructor(
    private dialog: MatDialog,
    private routerService: Router,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private authservice: AuthService,
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //this.blockUI.start();
      this.userName = this.authservice.getUserName();
      this.employeeModel = this.initializeEmployeeModel();     
      this.getEmployeeId(); 
    }
  }



  initializeUserModel(): User {
    return {
      userId: null,
      userName: null,
      email: null,
      password: null,
      employeeId: null,
      notifyUser: null,
      isActive: null,
      userRoles: null,
      battalionChiefEmployees: null,
    } as User;
        }

  initializeEmployeeModel(): Employee {
    return {
      employeeId: 0,
      title: null,
      lastName: null,
      miName: null,
      firstName: null,
      rank: null,
      certification: null,
      ffnNumber: null,
      kemsisNumber: null,
      dateOfBirth: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      email: null,
      phone: null,
      drivingLicense: null,
      startDate: null,
      shift: null,
      payGrade: null,
      emergencyContact: null,
      emergencyContactNumber: null,
      emergencySecContact: null,
      emergencySecContactNumber: null,
      contactNotes: null,
      employeeAward: null,
      certifications: null,
      rankName: null,
      shiftName: null,
      dateOfBirthStr: null,
      startDateStr: null,
      employeeNames: null,
      isActive: null
    } as Employee;
  }
  getEmployeeId() {
    var userId = this.authservice.getUserId();
    this.employeeService.getEmployeeId('GetProfileEmployeeId', userId).subscribe(res => {
      this.employeeProfileModel = res;
      this.employeeId = res.employeeId;
    
    }); 
  }

  updateProfile() {
    //var userId = this.authservice.getUserId();
    //this.employeeService.updateProfile('UpdateProfile', this.employeeProfileModel).subscribe(res => {
    //  this.employeeProfileModel = res;
    //});
    //this.getEmployeeId();
    //this.showFieldsToEdit = false;

    {
      //this.maintenenceRequestModel.updateComments = this.maintenenceRequestModel.updateComments + this.commentsFromServer ;
      this.employeeService.updateProfile('UpdateProfile', this.employeeProfileModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.getEmployeeId();
            this.showFieldsToEdit = false;
            //this.GoBack();
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
          });
        }
      });
    }


  }

  editProfile() {
    this.showFieldsToEdit = true;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
