import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee.model';
@Component({
  selector: 'employeeemgcontact',
  templateUrl: './employeeemgcontact.component.html'
})
export class EmployeeEmgContactComponent {
  employeeResultObj: Employee[];
  constructor(private dialog: MatDialog, private routerService: Router,
    private employeeService: EmployeeService) {

  }
  ngOnInit() {

    this.loadGrid();

  }
  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      this.employeeResultObj = res;
    });

  }
}
