import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { inspection } from '../models/inspection.model';
import { ErrorService } from './error.service';
import { GetAllInspection } from '../models/get-all-inspection.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class InspectionService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  )
  {
    super('api/Inspection/', httpClient, errorService, authService);
  }

  saveInspection(action: string, inspectionObj: inspection): Observable<any>
  {
    return this.postRequest(action, JSON.stringify(inspectionObj));
  }

  //
  saveInspectionwithFile(action: string, formdata: FormData): Observable<any> {
    debugger;
    return this.customPostWithFile(action, formdata);
  }
  //

  getInspectionFile(action: string, inspectionFileId: string, filetype: string) {
    debugger;
    const params = new HttpParams()
      .set('inspectionFileId', inspectionFileId)
    // .set('fileType', filetype)
    return this.customGetRequestWithQuery(action, params);
  }

  getInspection(action: string, inspectionId: number): Observable<any> {

    return this.customGetRequest<inspection>(action, inspectionId.toString());
  }
  getAllInspections(action: string, inspectionId: number): Observable<any> {
    const params = new HttpParams()
      .set('inspectionId', inspectionId.toString());

    return this.customGetRequestWithQueryString<Array<inspection>>(action, params);
  }

  updateInspection(inspectionId: number, inspectionModel: inspection): Observable<any> {

    return this.putRequest(inspectionId, JSON.stringify(inspectionModel));
  }

  inspectionReport(action: string, inspectionObj: any): Observable<Array<inspection>> {
   


    return this.customGetRequestWithQueryString<Array<inspection>>(action, inspectionObj);
  }
  

  getInspectionStreetname(): Observable<Array<inspection>> {
    return this.customGetRequest<Array<inspection>>('GetInspectionStreetname', null);

  }
  getInspectionBusinessName(): Observable<Array<inspection>> {
    return this.customGetRequest<Array<inspection>>('GetInspectionBusinessName', null);

  }

  getBusiness(action: string): Observable<Array<string>> {
    return this.customGetRequest<Array<string>>(action, null);
  }
  getStreets(action: string): Observable<Array<string>> {
    return this.customGetRequest<Array<string>>(action, null);
  }
  getAllInspection(): Observable<Array<inspection>> {
    return this.customGetRequest<Array<inspection>>('getAllInspection', null);
  }


  getAllInspectionNew(action: string, occupancyType: number = 0, inspectionType: number = 0, inspectionResult: number = 0, district: number = 0, knoxBox: number = 0, inspectionConductedBy: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllInspection> {
    debugger;
    // var shiftDate = new Date(shiftDate);
    const params = new HttpParams()
      .set('occupancyType', occupancyType.toString())
      .set('inspectionType', inspectionType.toString())
      .set('inspectionResult', inspectionResult.toString())
      .set('district', district.toString())
      .set('knoxBox', knoxBox.toString())
      .set('inspectionConductedBy', inspectionConductedBy.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);
    return this.customGetRequestWithQueryString<GetAllInspection>(action, params);
  }

}
