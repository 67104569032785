import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { SystemCodeService } from '../services/system-code.service';
import { WaterUsageService } from '../services/waterusage.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { WaterUsage } from '../models/waterusageform.model';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { startWith, map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GetAllWaterUsage } from '../models/get-all-waterusage.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { WaterUsagePrintReportFilter } from '../models/water-usage-print-report-filters.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'waterusagereport',
  templateUrl: './waterusagereport.component.html',
  styleUrls: ['./waterusage.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class WaterUsageReport {
  @ViewChild('waterUsageForm', { static: false }) waterUsageForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  container: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  waterUsageId: number;
  startDate: Date;
  endDate: Date;
  watetUsageModelObj: WaterUsage[];
  waterModel: WaterUsage[] = [];
  waterUsages: WaterUsage[] = [];
  waterUsageModel: WaterUsage;
  waterUsagesModelForAllData: GetAllWaterUsage;
  waterUsagePrintReportFilters: WaterUsagePrintReportFilter;
  districtCode: SystemCode[] = [];
  districts: Array<{ key: number, value: string }> = [];
  searchMsg: string = "";
  resultsLength: number = 0;
  //assignmentObjDB = new MatTableDataSource<Employee>([]);
  dataSource=new MatTableDataSource<WaterUsage>([]);
  paginatorid: MatPaginator;
  sortid: MatSort;
  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date(2100, 11, 31);
  emptyDate: Date = null;
  todayDate: Date = new Date();
  fromDate: any;
  displayedColumns: any[] = ['waterUsageDate', 'districtName', 'estimatedWaterUsage'];
  rolePermissionCheckModel: RolePermissionCheck;
  showEndDateField: boolean = false;
  isViewReport: boolean = false;
  waterUsageForReportPrint: Array<WaterUsage> = [];
  stMinDate: Date;
  stMaxDate: Date;


  constructor(
    private systemCodeStateService: SystemCodeService,
    private waterusageservice: WaterUsageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private routerService: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.loadDropDowns();
      this.waterUsageModel = this.initializeWaterUsageModel();
      this.waterUsagePrintReportFilters = this.initializeWaterUsagePrintReportFilters();
      this.dataSource = new MatTableDataSource(this.waterModel);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  initializeWaterUsageModel(): WaterUsage {
    return {
      waterUsageId: 0,
      waterUsageDate: null,
      district: null,
      estimatedWaterUsage: null,
      startDate: null,
      endDate: null,
      districtName: null,
      waterUsageDateStr: null
    } as WaterUsage;
  }
  initializeWaterUsagePrintReportFilters(): WaterUsagePrintReportFilter {
    return {      
      startDate: null,
      endDate: null,
     // displayTitle: null
    } as WaterUsagePrintReportFilter;
  }

/*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.blockUI.start();
            return this.getAllWaterUsagae(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();

            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {

          this.waterModel = data || [];
          this.dataSource = new MatTableDataSource(this.waterModel);
          //this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.blockUI.stop();
        });
    }

  }

  getAllWaterUsagae(pageIndex: number, pageSize: number): Observable<GetAllWaterUsage> {


    return this.waterusageservice.getWaterUsageNew(
      'GetAllWaterUsageNew',
      (this.waterUsageModel.startDate) ? this.waterUsageModel.startDate : null,
      (this.waterUsageModel.endDate) ? this.waterUsageModel.endDate : null,
      pageIndex,
      pageSize
    );
  }

  getAllWaterUsageForDataSource(): void {
    this.getAllWaterUsagae(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.waterModel = data || [];
        this.dataSource.data = data || [];
        this.dataSource = new MatTableDataSource(this.waterModel);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.blockUI.stop();
      });
  }

/*********************************** */

  GridSortAndPage() {
    this.dataSource = new MatTableDataSource(this.waterModel);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  loadGrid() {

    this.waterusageservice.getAllWaterUsage().subscribe(
      (res: Array<WaterUsage>) => {

        this.waterModel = res || [];

        this.waterUsageId = this.waterUsageId;
        this.GridSortAndPage();
      }, (error: any) => {
      });
    this.GridSortAndPage();
  }
  startdateevent(startDate: Date) {
    this.fromDate = startDate;
    this.showEndDateField = true;
    if (startDate == null) {
      this.showEndDateField = false;
      this.waterUsageModel.endDate = null;
    }
  }

  resetTheForm() {
    this.blockUI.start();
    this.waterUsageModel = this.initializeWaterUsageModel();
    this.waterUsageForm.resetForm();
    this.getAllWaterUsageForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.searchMsg = "";
    this.fromDate = new Date(2010, 0, 1);
    this.showEndDateField = false;
    this.blockUI.stop();
    return false;
  }


  public loadDropDowns() {
    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.districtCode = res || [];
        this.districts = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );

  }

  public searchWaterUsageReport() {   
    this.blockUI.start();
    this.searchMsg = "";
    this.getAllWaterUsageForDataSource();
    this.blockUI.stop();
    return false;
  }

  showWaterUsageReportForPrint(): void {
    this.blockUI.start();
    this.waterusageservice.getAllWaterUsagesForReportView(
      'GetAllWaterUsageForReportView',
      (this.waterUsageModel.startDate != null) ? this.waterUsageModel.startDate : null,
      (this.waterUsageModel.endDate != null) ? this.waterUsageModel.endDate : null,
    ).subscribe((res: Array<WaterUsage>) => {
      this.waterUsageForReportPrint = res;
      this.blockUI.stop();
      this.pdf3();
    }, (error: any) => {
        this.waterUsageForReportPrint = [];
      this.blockUI.stop();
    });
  }

  deleteWaterUsage(wId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.waterusageservice.delWaterUsage('delWaterUsage', parseInt(wId)).subscribe(res => {
        this.getAllWaterUsageForDataSource();
        });
      }
    });
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  showWaterUsageReport() {
    this.setWaterUsagePrintReportFilters();
    this.isViewReport = true;
  }
  setWaterUsagePrintReportFilters(): void {
    if (this.waterUsageModel.startDate) {
      let startDateString = new Date(this.waterUsageModel.startDate);
      let currentStartDateString = (startDateString.getMonth() + 1).toString() + '/' + startDateString.getDate().toString() + '/' + startDateString.getFullYear().toString();
      this.waterUsagePrintReportFilters.startDate = currentStartDateString;
    }

    if (this.waterUsageModel.endDate) {
      let endDateString = new Date(this.waterUsageModel.endDate);
      let currentEndDateString = (endDateString.getMonth() + 1).toString() + '/' + endDateString.getDate().toString() + '/' + endDateString.getFullYear().toString();
      this.waterUsagePrintReportFilters.endDate = currentEndDateString;
    }
  }
  toggleFormViewToVisible(): void {
    this.isViewReport = false;
    //this.paginator.pageIndex = 0;
    this.waterUsagePrintReportFilters = this.initializeWaterUsagePrintReportFilters();
    this.getAllWaterUsageForDataSource();
  }
  displayWaterUsageDate(waterUsageDate: Date) {
    let dateValue: string = "";
    if (waterUsageDate != null) {
      var waterDateModel = new Date(waterUsageDate);
      var dd = String(waterDateModel.getDate()).padStart(2, '0');
      var mm = String(waterDateModel.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = waterDateModel.getFullYear();
      var dateData = mm + '/' + dd + '/' + yyyy;
      dateValue = dateData.toString();
    }
    else {
      dateValue = "N/A";
    }
    return dateValue;
  }

  //*************************************
  pdf3() {
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 35;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF();
    doc.setFont('Calibri');
    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth('Water Usage Report') * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text('Water Usage Report', textOffset, 20)
    doc.setFontSize(10)

    if (this.waterUsageModel.startDate) {
      let startDateString = new Date(this.waterUsageModel.startDate);
      let startDateFormat = (startDateString.getMonth() + 1).toString() + '/' + startDateString.getDate().toString() + '/' + startDateString.getFullYear().toString();
      var startDateFilterDisplay = 'Start Date: ' + startDateFormat;
    }
    else {
      startDateFilterDisplay = 'Start Date: ' + "";
    }
      if (this.waterUsageModel.endDate) {
        let endDateString = new Date(this.waterUsageModel.endDate);
        let endDateFormat = (endDateString.getMonth() + 1).toString() + '/' + endDateString.getDate().toString() + '/' + endDateString.getFullYear().toString();
        var endDateFilterDisplay = 'End Date: ' + endDateFormat;
      } else {
        endDateFilterDisplay = 'End Date: ' + "";
      }

      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize(startDateFilterDisplay + ' - ' + endDateFilterDisplay, pageWidth - 35)
      doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
       
    //if (this.waterUsageModel.startDate && this.waterUsageModel.endDate) {
      
    //} else {
    //  startYVariable = 30;
    //}
    startYVariable = yAxis + 10;

    autoTable(doc, {
      //headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      //bodyStyles: { minCellHeight: 8, fillColor: null },
      rowPageBreak: 'avoid',
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 185, pageHeight - 10);

        var currentDate = new Date();
        var currentDateString = currentDate.getMonth() + 1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear();
        doc.text(currentDateString, 5, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    doc.output('dataurlnewwindow');
  }

  checkPrintReportFilters(): boolean {
    if (this.waterUsageModel.startDate || this.waterUsageModel.endDate) {
      return true;
    }
    return false;
  }

  getHeadRows() {
    return [
      {
        waterUsageDate: 'WaterUsage Date',
        district: 'District Name', estimatedWaterUsage: 'Estimated WaterUsage',

      }
    ]
  }

  getBody() {
    let rowCount = this.waterUsageForReportPrint.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.waterUsageForReportPrint[j].startDate;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }

      body.push({
        waterUsageDate: (this.waterUsageForReportPrint[j].waterUsageDate) ? (this.getDateAsString(new Date(this.waterUsageForReportPrint[j].waterUsageDate))) : 'N/A',
        district: this.waterUsageForReportPrint[j].districtName,
        estimatedWaterUsage: this.waterUsageForReportPrint[j].estimatedWaterUsage

      })
    }
    return body
  }

  getFilters(): string {
    let filtersText = '';

    if (this.waterUsagePrintReportFilters.startDate) {
      filtersText = filtersText + 'Start Date: ' + this.waterUsagePrintReportFilters.startDate + ' ';
    }

    if (this.waterUsagePrintReportFilters.endDate) {
      filtersText = filtersText + 'End Date: ' + this.waterUsagePrintReportFilters.endDate + ' ';
    }

    return filtersText;
  }

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  //*************************************


}
