import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { DailyLog } from '../models/dailylog.model';
import { ErrorService } from './error.service';
import { DlActivities } from "../models/dlactivities.model";
import { DlApparatus } from "../models/dlapparatus.model";
import { DlFlaggedEmsRun } from "../models/dlflaggedemsrun.model";
import { DlQuarters } from "../models/dlquarters.model";
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { CompanyTraining } from '../models/companytraining.model';
import { GetAllDailyLogs } from '../models/get-all-dailyLogs.model';
import { AuthService } from './auth.service';
import { DailyLogReport } from '../models/daily-log-report.model';

@Injectable({ providedIn: 'root' })
export class DailyLogService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/DailyLog/', httpClient, errorService, authService);
  }

  saveDailyLog(action: string, saveDailyLog: DailyLog): Observable<any> {
    return this.postRequest(action, JSON.stringify(saveDailyLog));
  }

  updateDailyLog(dailyLogId: number, dailyLogModel: DailyLog): Observable<any> {
    return this.putRequest(dailyLogId, JSON.stringify(dailyLogModel));
  }

  getDailyLog(action: string, dailyLogId: number): Observable<any> {
    return this.customGetRequest<DailyLog>(action, dailyLogId.toString());
  }

  getAllDailyLog(): Observable<Array<DailyLog>> {
    return this.customGetRequest<Array<DailyLog>>('getAllDailyLog', null);
  }

  delDailyLog(action: string, dailyLogId: number): Observable<any> {
    return this.deleteRequest(dailyLogId);
  }
  getAllDailyLogNew(action: string, crew: number = 0, station: number = 0, startDate: Date = null, endDate: Date = null, pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    debugger;

    // var edDate = new Date(endDate);
    let params = new HttpParams()
      .set('crew', crew.toString())
      .set('station', station.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    return this.customGetRequestWithQueryString<GetAllDailyLogs>(action, params);
  }

  //getAllDailyLogNew(action: string, shiftDate: Date = null, crew: number = 0, station: number = 0, pageNumber: number = 0, pageSize: number = 15): Observable<GetAllDailyLogs> {
  //  let params = new HttpParams()
  //    .set('crew', crew.toString())
  //    .set('station', station.toString())
  //    .set('pageNumber', pageNumber.toString())
  //    .set('pageSize', pageSize.toString());
  //  if (shiftDate != null) {
  //    params = params.set('shiftDate', shiftDate.toDateString());
  //  }
  //  return this.customGetRequestWithQueryString<GetAllDailyLogs>(action, params);
  //}

  dailyLogSearch(action: string, dailylogObject: any): Observable<Array<DailyLog>> {
    debugger;
    var shiftDate = new Date(dailylogObject.shiftDate);
    const params = new HttpParams()
      .set('shiftDate', shiftDate.toDateString())
      .set('station', dailylogObject.station)
      .set('crew', dailylogObject.crew);
    return this.customGetRequestWithQueryString<Array<DailyLog>>(action, params);
  }

  getMaintanenceByDailyLog(action: string, shiftDate: Date = null, station: number = 0, crew: number = 0): Observable<any> {
    //var shiftDate = new Date(shiftDate);
    let params = new HttpParams()
      .set('station', station.toString())
      .set('crew', crew.toString());
    if (shiftDate != null) {
      params = params.set('shiftDate', shiftDate.toDateString());
    }
      //.set('shiftDate', shiftDate.toDateString());
    return this.customGetRequestWithQueryString<MaintenenceRequest>(action, params);
  }

  getCompanyTrainingByDailyLog(action: string, shiftDate: Date = null, station: number = 0, crew: number = 0): Observable<any> {
    let params = new HttpParams()
      .set('station', station.toString())
      .set('crew', crew.toString());
    if (shiftDate != null) {
      params = params.set('shiftDate', shiftDate.toDateString());
    }
    return this.customGetRequestWithQueryString<CompanyTraining>(action, params);
  }

  getDailyLogNew(action: string, crew: number = 0, station: number = 0, startDate: Date = null, endDate: Date = null, pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    

    // var edDate = new Date(endDate);
    let params = new HttpParams()
      .set('crew', crew.toString())
      .set('station', station.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    return this.customGetRequestWithQueryString<GetAllDailyLogs>(action, params);
  }

  getDailyLogNewForAllData(action: string, crew: number = 0, station: number = 0, startDate: Date = null, endDate: Date = null): Observable<any> {

    let params = new HttpParams()
      .set('crew', crew.toString())
      .set('station', station.toString())
    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    return this.customGetRequestWithQueryString<Array<DailyLog>>(action, params);
  }



  //getAllDailyLogsForReportView(action: string, shiftDate: Date = null, crew: number = 0, station: number = 0): Observable<Array<DailyLogReport>> {
  //  let params = new HttpParams()
  //    .set('crew', crew.toString())
  //    .set('station', station.toString());

  //  if (shiftDate != null) {
  //    params = params.set('shiftDate', shiftDate.toDateString());
  //  }
  //  return this.customGetRequestWithQueryString<Array<DailyLogReport>>(action, params);
  //}
  getAllDailyLogsForReportView(action: string, crew: number = 0, station: number = 0, startDate: Date = null, endDate: Date = null): Observable<Array<DailyLogReport>> {
    let params = new HttpParams()
      .set('crew', crew.toString())
      .set('station', station.toString());

    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    return this.customGetRequestWithQueryString<Array<DailyLogReport>>(action, params);
  }

}
