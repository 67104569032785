import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'zipcodePipe' })
export class ZipCodePipe implements PipeTransform {
  transform(zipcode: string): string {
    if (zipcode) {
      zipcode = zipcode.replace(/\D/g, '');
      return zipcode;
    }
    return null;
  }
}
