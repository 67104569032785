import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CompanyTraining } from '../models/companytraining.model';
import { CompanyTrainingService } from '../services/companytraining.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveFile, saveAs } from 'file-saver';
import { GlobalConstants } from '../common/global-constants';
import { RolePermissionCheck } from '../models/role-permission-check.model';
@Component({
  selector: 'emscompanytrainingview',
  templateUrl: './companytrainingview.component.html'
})
export class companytrainingViewComponent {
  emstrainingObj: CompanyTraining;
  emsTrainingId: number = 0;
  //emsCompanyTraining: string = GlobalConstants.emsCompanyTraining;
  classTrainingFileExists: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private emsService: CompanyTrainingService,
    private route: ActivatedRoute,
    private routerService: Router) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.emsTrainingId = parseInt(Id);
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.emstrainingObj = this.initializeCompanyTrainingModel();
      if (this.emsTrainingId > 0) {
        this.emsService.getCompanyTraining('getCompanyTraining', this.emsTrainingId).subscribe(res => {
          this.emstrainingObj = res;
          if (this.emstrainingObj.classTrainingFileName != null && this.emstrainingObj.classTrainingFileName.length > 0) {
            this.classTrainingFileExists = true;
          }
        });
      }
    }
  }

  initializeCompanyTrainingModel(): CompanyTraining {
    return {
      companyTrainingId: 0,
      trainingType: null,
      className: null,
      startDate: null,
      endDate: new Date(),
      instructor: null,
      trainingHours: null,
      crew: null,
      station: null,
      category: null,
      numberOfStudents: null,
      crewName: null,
      emsCategoryName: null,
      instructorName: null,
      stationName: null,
      companyTrainingEmployee: null,
      employeeId: null,
      kemiscode: null,
      ffdcode: null,
      classTrainingFileName: null,
      companyTrainingEmployeeStr: null,
      classTrainingFileDeteted: false
    } as CompanyTraining;

  }

  openCFile(companyTrainingId: string, fileName: string) {
    this.emsService.getCompanyTrainingFile('GetCompanyTrainingFile', companyTrainingId, "CompanyTraining").subscribe(res => {
      saveAs(res, fileName);
    });
    return false;
  }

  GoBack(): void {
    this.routerService.navigate(['/companytrainingreport']);
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
