import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { inspection } from '../models/inspection.model';
import { ErrorService } from './error.service';
import { WaterUsage } from '../models/waterusageform.model';
import { GetAllWaterUsage } from '../models/get-all-waterusage.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class WaterUsageService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/WaterUsage/', httpClient, errorService, authService);
  }
  saveWaterUsage(action: string, waterUsageModel: WaterUsage): Observable<any> {
    return this.postRequest(action, JSON.stringify(waterUsageModel));
  }
  getAllWaterUsage(): Observable<Array<WaterUsage>> {
    return this.customGetRequest<Array<WaterUsage>>('getAllWaterUsage', null);
  }
  getWaterUsage(action: string, waterUsageId: number): Observable<any> {

    return this.customGetRequest<WaterUsage>(action, waterUsageId.toString());
  }
  getWaterUsageNew(action: string, startDate: Date = null, endDate: Date = null, pageNumber: number = 0, pageSize: number = 10): Observable<any> {

    //debugger;
    //var stDate = new Date();
    //if (startDate == null) {
    //  var lyear = stDate.getFullYear();
    //  var lmonth = stDate.getMonth();
    //  var lday = stDate.getDate();
    //  stDate = new Date(lyear -2, lmonth, lday);
    //}
    //else {
    //   stDate = new Date(startDate);
    //}


    //var edDate = new Date();
    //if (endDate == null) {
    //  var cyear = edDate.getFullYear();
    //  var cmonth = edDate.getMonth();
    //  var cday = edDate.getDate();
    //  edDate = new Date(cyear +2, cmonth, cday);
    //}
    //else {
    //  edDate = new Date(endDate);
    //}
   
   // var edDate = new Date(endDate);
    let params = new HttpParams()     
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    //console.log('Params: ', params);

    return this.customGetRequestWithQueryString<GetAllWaterUsage>(action, params);

   // return this.customGetRequest<WaterUsage>(action, waterUsageId.toString());
  }
  deleteWaterUsage(action: string, waterUsageModel: WaterUsage): Observable<any> {
    return this.postRequest(action, JSON.stringify(waterUsageModel));
  }
  delWaterUsage(action: string, wId: number): Observable<any> {

    return this.deleteRequest(wId);
    //return this.postRequest(action, JSON.stringify(obj));
  }
  updateWaterUsage(waterUsageId: number, waterUsageModel: WaterUsage): Observable<any> {

    return this.putRequest(waterUsageId, JSON.stringify(waterUsageModel));
  }
  searchWaterUsage(action: string, waterUsageModel: any): Observable<Array<WaterUsage>> {
  
    var startDate = new Date(waterUsageModel.startDate);
    var endDate = new Date(waterUsageModel.endDate);

    const params = new HttpParams()
     
      .set('startDate', startDate.toDateString())
      .set('endDate', endDate.toDateString())  
    return this.customGetRequestWithQueryString<Array<WaterUsage>>(action, params);
  }
  getAllWaterUsagesForReportView(action: string,  startDate: Date = null, endDate: Date = null): Observable<Array<WaterUsage>> {
    let params = new HttpParams()
    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }

    return this.customGetRequestWithQueryString<Array<WaterUsage>>(action, params);
  }
  getWaterUsageNewForAllData(action: string, startDate: Date = null, endDate: Date = null): Observable<any> {

    let params = new HttpParams()
    if (startDate != null) {
      params = params.set('startDate', startDate.toDateString());
    }
    if (endDate != null) {
      params = params.set('endDate', endDate.toDateString());
    }
    return this.customGetRequestWithQueryString<Array<WaterUsage>>(action, params);
    //return this.customGetRequestWithQueryString<WaterUsage>(action, params);

    // return this.customGetRequest<WaterUsage>(action, waterUsageId.toString());
  }


}
