import { Directive, Input } from "@angular/core";
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from "@angular/forms";
import { Subscription } from "rxjs";

@Directive({
  selector: '[comparefields]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CompareFieldsValidatorDirective, multi: true }]
})

export class CompareFieldsValidatorDirective implements Validator {
  @Input('comparefields') compareControls: string;
  @Input('comparingControls') comparingControlContent: string;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value.length === 0) {
      return null;
    }

    const controlToCompare = control.root.get(this.compareControls);

    if (controlToCompare) {
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }

    if (this.comparingControlContent === 'currentpassword') {
      return controlToCompare && controlToCompare.value === control.value ? { 'comparefields': true } : null;
    } else if (this.comparingControlContent === 'newpassword') {
      return controlToCompare && controlToCompare.value !== control.value ? { 'comparefields': true } : null;
    }
  }
}
