import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { Certification } from '../models/certification.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class CertificationService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/Certification/', httpClient, errorService, authService);
  }

  saveCertification(action: string, certificationObj: Certification): Observable<any> {
    return this.postRequest(action, JSON.stringify(certificationObj));
  }
}
