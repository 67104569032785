import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { SystemCodeService } from '../services/system-code.service';
import { MaintanenceRequestService } from '../services/maintanancerequest.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { GetAllMaintanence } from '../models/get-all-maintanence.model';
import { switchMap, map, startWith, catchError } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'MaintenanceReport',
  templateUrl: './maintenanceReport.component.html',
  styleUrls: ['./maintanencerequest.component.css'],
})
export class MaintenanceReport {
  @ViewChild('maintanenceRequestForm', { static: false }) maintanenceRequestForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  container: NgForm;
  maintenenceRequestId: number;
  maintanenceModel: MaintenenceRequest[] = [];
  maintenenceRequestModel: MaintenenceRequest;
  maintenenceRequestObject: MaintenenceRequest;
  maintenenceRequestComments: MaintenenceRequestComment[] = [];
  resultsLength: number = 0;
  maintenenceRequestModelObj: MaintenenceRequest[];
  showNewButton: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  searchMsg: string = "";
  maintanenceTypesCodes: SystemCode[] = [];
  statusCodes: SystemCode[] = [];
  stationCodes: Array<{ key: number, value: string }> = [];
  crews: Array<{ key: number, value: string }> = [];
  managementTypes: Array<{ key: number, value: string }> = [];
  actionReuired: Array<{ key: number, value: string }> = [];
  pendingTypes: Array<{ key: number, value: string }> = [];
  maintanenceModelForAllData: GetAllMaintanence;
  assignmentObjDB = new MatTableDataSource<MaintenenceRequest>([]);
  dataSource: MatTableDataSource<MaintenenceRequest>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  commentList: string[] = [];
  commentString1: string[] = [];
  hidePopup: boolean = false;
  rolePermissionCheckModel: RolePermissionCheck;
  //displayedColumns: any[] = ['createdOn', 'actionRequiredName', 'typeName', 'description', 'pendingOrCompleteId', 'workOrderNumber', 'updatecomments'];
  displayedColumns: any[] = ['createdOn', 'actionRequiredName', 'typeName', 'subTypeName', 'description', 'pendingOrCompleteId', 'workOrderNumber', 'updatecomments'];
  status: string = "";
  maintanenceType: string = "";
  maintenanceSubType: string = "";
  maintanenceRequestPrintModel: Array<MaintenenceRequest> = [];

  subTypes: SystemCode[] = [];
  subTypeDisplay: boolean = false;

  constructor(
    private systemCodeStateService: SystemCodeService,
    private maintanenceRequestService: MaintanenceRequestService, private dialog: MatDialog,
    private route: ActivatedRoute, private routerService: Router
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.maintenenceRequestModel = this.initializeMaintanenceRequestModel();
      this.loadDropDowns();
      this.dataSource = new MatTableDataSource(this.maintanenceModel);
      this.dataSource.sort = this.sort;
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  ngAfterViewInit() {
    //pagination subscription
    this.InitialLoad();
  }

  InitialLoad() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.blockUI.start();
            return this.getAllMaintanence(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.maintanenceModel = data || [];
          this.assignmentObjDB.data = data || [];
          this.dataSource = new MatTableDataSource(this.maintanenceModel);
          this.dataSource.sort = this.sort;
          this.blockUI.stop();
        });
    }
  }

  getAllMaintanence(pageIndex: number, pageSize: number): Observable<GetAllMaintanence> {
    return this.maintanenceRequestService.getAllMaintanenceNew(
      'GetMaintanenceNew',
      (this.maintenenceRequestModel.pendingOrCompleteId) ? this.maintenenceRequestModel.pendingOrCompleteId : 0,
      (this.maintenenceRequestModel.typeId) ? this.maintenenceRequestModel.typeId : 0,
      (this.maintenenceRequestModel.subTypeId) ? this.maintenenceRequestModel.subTypeId : 0,
      pageIndex,
      pageSize
    );
  }

  getAllMaintanenceForDataSource(): void {
    this.blockUI.start();
    this.getAllMaintanence(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.maintanenceModel = data || [];
        this.assignmentObjDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.maintanenceModel);
        this.dataSource.sort = this.sort;
        this.blockUI.stop();
      });
  }

  resetTheForm() {
    this.maintenenceRequestModel = this.initializeMaintanenceRequestModel();
    this.subTypeDisplay = false;
    this.subTypes = [];
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.searchMsg = "";
    this.status = "";
    this.maintanenceType = "";
    this.maintenanceSubType = "";
    this.getAllMaintanenceForDataSource();
  }

  initializeMaintanenceRequestModel(): MaintenenceRequest {
    return {
      maintenenceRequestId: 0,
      stationId: null,
      crewId: null,
      typeId: null,
      actionRequiredId: null,
      description: null,
      pendingOrCompleteId: null,
      workOrderNumber: null,
      isActive: true,
      pendingCompleteName: null,
      actionRequiredName: null,
      crewName: null,
      stationName: null,
      typeName: null,
      createdOn: null,
      requestComment: null,
      updateComments: null,
      subTypeId: null,
      subTypeName: null
    } as MaintenenceRequest;
  }

  deleteMaintanenceRequest(mId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.maintanenceRequestService.delMaintanenceRequest('delMaintanenceRequest', parseInt(mId)).subscribe(res => {
          this.getAllMaintanenceForDataSource();
        });
      }
    });
    return false;
  }

  maintanenceGetReport() {
    this.showNewButton = true;
    this.status = "";
    this.maintanenceType = "";
    this.maintenanceSubType = "";
    this.searchMsg = "";
    if (this.maintenenceRequestModel.pendingOrCompleteId == null) {
      this.maintenenceRequestModel.pendingOrCompleteId = 0;
    }
    if (this.maintenenceRequestModel.typeId == null) {
      this.maintenenceRequestModel.typeId = 0;
    }
    if (!((this.maintenenceRequestModel.pendingOrCompleteId == null || this.maintenenceRequestModel.pendingOrCompleteId == 0) &&
      (this.maintenenceRequestModel.typeId == null || this.maintenenceRequestModel.typeId == 0))) {
      this.showNewButton = true;
    } else {
      return;
    }
    this.getAllMaintanenceForDataSource();

    const maintanenceTypeIndex = this.maintanenceTypesCodes.findIndex(x => x.systemCodeId == this.maintenenceRequestModel.typeId);
    if (maintanenceTypeIndex >= 0) {
      this.maintanenceType = this.maintanenceTypesCodes[maintanenceTypeIndex].type;
    } else {
      this.maintanenceType = "";
    }

    const statusIndex = this.statusCodes.findIndex(x => x.systemCodeId == this.maintenenceRequestModel.pendingOrCompleteId);
    if (statusIndex >= 0) {
      this.status = this.statusCodes[statusIndex].type;
    } else {
      this.status = "";
    }

    const subTypeIndex = this.subTypes.findIndex(x => x.systemCodeId == this.maintenenceRequestModel.subTypeId);
    if (subTypeIndex >= 0) {
      this.maintenanceSubType = this.subTypes[subTypeIndex].type;
    } else {
      this.maintenanceSubType = "";
    }
  }

  showMaintanenceRequestReport(): void {
    this.blockUI.start();
    this.maintanenceRequestService.getAllMaintanenceForAllDataNew(
      'GetMaintanenceForAllDataNew',
      (this.maintenenceRequestModel.pendingOrCompleteId) ? this.maintenenceRequestModel.pendingOrCompleteId : 0,
      (this.maintenenceRequestModel.typeId) ? this.maintenenceRequestModel.typeId : 0,
      (this.maintenenceRequestModel.subTypeId) ? this.maintenenceRequestModel.subTypeId : 0
    ).subscribe((res: Array<MaintenenceRequest>) => {
      this.maintanenceRequestPrintModel = res;
      this.blockUI.stop();
      this.printReport();
    }, (error: any) => {
      this.maintanenceRequestPrintModel = [];
      this.blockUI.stop();
    });
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForStation(),
      this.systemCodeStateService.getSystemCodesForCrews(),
      this.systemCodeStateService.getSystemCodesForMaintanenceTypes(),
      this.systemCodeStateService.getSystemCodesForActionRequired(),
      this.systemCodeStateService.getSystemCodesForPendingType()
    ).subscribe(([res1, res2, res3, res4, res5]) => {
      this.stationCodes = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.crews = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.maintanenceTypesCodes = res3 || [];
      this.managementTypes = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.actionReuired = res4.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.statusCodes = res5 || [];
      this.pendingTypes = res5.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  printReport() {
    let title = "Maintenance Report";
    let yAxis: number = 20;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setFont('Calibri');
    doc.setProperties({
      title: 'Maintenance_Request_Report'
    });

    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 10)
    doc.setFontSize(10)

    let status = 'Status: ';
    if (this.status) {
      status = status + this.status;
    }
    let type = 'Type: ';
    if (this.maintanenceType) {
      type = type + this.maintanenceType;
    }

    let subType = 'UID: ';
    if (this.maintenanceSubType) {
      subType = subType + this.maintenanceSubType;
    }

    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(status + '     ' + type + '     ' + subType, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 10;

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        requestdate: {
          cellWidth: 20
        },
        actionrequested: {
          cellWidth: 20
        },
        type: {
          cellWidth: 25
        },
        subtype: {
          cellWidth: 20
        },
        description: {
          cellWidth: 65
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 245, pageHeight - 10);

        var currentDate = new Date();
        var currentDateString = (currentDate.getMonth() + 1).toString() + '/' + currentDate.getDate().toString() + '/' + currentDate.getFullYear().toString();
        doc.text(currentDateString, 15, pageHeight - 10);
      },
      startY: yAxis
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    window.open(URL.createObjectURL(doc.output("blob")));
  }

  getHeadRows() {
    return [
      {
        //requestdate: 'Request Date', actionrequested: 'Action Requested', type: 'Type',
        requestdate: 'Request Date', actionrequested: 'Action Requested', type: 'Type', subtype: 'UID',
        description: 'Description Of Problems', status: 'Pending/ Complete', workorder: 'W.O #',
        updateorcomment: 'Update/Comments'
      }
    ]
  }

  getBody() {
    let rowCount = this.maintanenceRequestPrintModel.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.maintanenceRequestPrintModel[j].createdOn;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }

      body.push({
        requestdate: actionDateStringFormat,
        actionrequested: (this.maintanenceRequestPrintModel[j].actionRequiredName) ? this.maintanenceRequestPrintModel[j].actionRequiredName : '',
        type: (this.maintanenceRequestPrintModel[j].typeName) ? this.maintanenceRequestPrintModel[j].typeName : '',
        subtype: (this.maintanenceRequestPrintModel[j].subTypeName) ? this.maintanenceRequestPrintModel[j].subTypeName : '',
        description: (this.maintanenceRequestPrintModel[j].description) ? this.maintanenceRequestPrintModel[j].description : '',
        status: this.maintanenceRequestPrintModel[j].pendingCompleteName,
        workorder: this.maintanenceRequestPrintModel[j].workOrderNumber,
        updateorcomment: this.maintanenceRequestPrintModel[j].updateComments ? this.maintanenceRequestPrintModel[j].updateComments : '',
      });
    }
    return body;
  }

  getDateAsString(dt: Date): string {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  checkPrintReportFilters() {
    if (this.status == "" || this.maintanenceType == "" || this.maintenanceSubType == "") {
      return true;
    }
    return false;
  }

  onTypeChange(value: any) {
    var type = this.maintanenceTypesCodes.find(x => x.systemCodeId === value).type;
    if (type.toLowerCase().trim() == "station items") {
      this.systemCodeStateService.getSystemCodesForStationItems().subscribe(
        (res: Array<SystemCode>) => {
          this.subTypes = res || [];
        }, (error: any) => { }
      );
    } else if (type.toLowerCase().trim() == "equipment") {
      this.systemCodeStateService.getSystemCodesForEquipment().subscribe(
        (res: Array<SystemCode>) => {
          this.subTypes = res || [];
        }, (error: any) => { }
      );
    } else {
      this.systemCodeStateService.getSystemCodesForApparatus().subscribe(
        (res: Array<SystemCode>) => {
          this.subTypes = res || [];
        }, (error: any) => { }
      );
    }

    this.subTypeDisplay = true;
  }
}
