import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { GearRequest } from '../models/gearrequest.model';
import { ErrorService } from './error.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class GearRequestService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  )
  {
    super('api/GearRequest/', httpClient, errorService, authService);
  }
  
  //saveGearRequest
  saveGearRequest(action: string, gearrequestObj: GearRequest): Observable<any>
  {
    return this.postRequest(action, JSON.stringify(gearrequestObj));
  }
}
