import { Component, ViewChild, OnInit, Inject, ElementRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort, Sort } from '@angular/material';
import { HttpClientModule, HttpClient, HttpEventType } from '@angular/common/http';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { saveFile, saveAs } from 'file-saver';
import { debug } from 'util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GetAllPrePlan } from '../models/get-all-prePlan.model';
import { PrePlan } from '../models/preplans.model';
import { PrePlanService } from '../services/preplan.service';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'preplanreport',
  templateUrl: './preplanreport.component.html'

})
export class PrePlanReportComponent {
  @ViewChild('documentForm', { static: false }) documentForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  resultsLength: number = 0;
  prePlanFileExists: boolean = false;
  showNewButton: boolean = false;
  floatLabel: string = 'never';
  errMsg: string;

  streetnameCode: SystemCode[] = [];
  fileTypes: SystemCode[] = [];    
  prePlanModel: PrePlan;
  prePlanModels: PrePlan[] = [];
  dataSource = new MatTableDataSource<PrePlan>([]);
  paginatorid: MatPaginator;
  sortid: MatSort;
  streetNameKey: SystemCodeKeyValue[] = [];
  districtKey: SystemCodeKeyValue[] = [];

  @BlockUI() blockUI: NgBlockUI;
  displayedColumns: any[] = ['streetNumber', 'streetName', 'facilityName', 'district', 'download'];//
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private prePlanService: PrePlanService, private http: HttpClient
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.loadDropDowns();
      this.prePlanModel = this.initializePrePlanModel();
      this.dataSource = new MatTableDataSource(this.prePlanModels);
      

      const sortState: Sort = { active: 'streetName', direction: 'asc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
  }

  initializePrePlanModel(): PrePlan {
    return {
      prePlanId: 0,
      streetNumber: null,
      streetName: null,
      facilityName: null,
      district: 0,
      prePlanFileName: null,
      prePlanFileType: null
    } as PrePlan;
  }
  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.blockUI.start();
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllPrePlanNew(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.prePlanModels = data || [];
          this.dataSource = new MatTableDataSource(this.prePlanModels);
          this.dataSource.sort = this.sort;
          this.blockUI.stop();
        });
    }
  }
  getAllPrePlanNew(pageIndex: number, pageSize: number): Observable<GetAllPrePlan> {
    return this.prePlanService.getAllUploadsNew(
      'GetAllPrePlanNew',
      (this.prePlanModel.streetName) ? this.prePlanModel.streetName : 0,      
      pageIndex,
      pageSize
    );
  }


  getAllPrePlanForDataSource(): void {
    this.getAllPrePlanNew(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.prePlanModels = data || [];
        this.dataSource = new MatTableDataSource(this.prePlanModels);
        this.dataSource.sort = this.sort;
       // this.showNewButton = true;
        this.blockUI.stop();
      });
  }

  /*********************************** */
  loadGrid() {
    //this.prePlanService.getAllUploads('GetAllFileUploads').subscribe((res: Array<PrePlan>) => {
    //  this.prePlanModels = res || [];

    //  this.dataSource = new MatTableDataSource<PrePlan>(this.prePlanModels);
    //  this.dataSource.paginator = this.paginator;
    //  this.dataSource.sort = this.sort;
    //},
    //  err => {

    //  });

    this.dataSource = new MatTableDataSource<PrePlan>(this.prePlanModels);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openPrePlanFile(prePlanId: string, fileName: string) {
    this.prePlanService.getPrePlanFile('GetPrePlanFile', prePlanId, "PrePlan").subscribe(res => {
      saveAs(res, fileName);
    });
    return false;
  }

  deleteOFile(prePlanId: string) {
    this.prePlanModel.prePlanFileDelete = true;
    this.prePlanModel.prePlanFileName = "";

    return false;
  }

  //deletePrePlanFile(prePlanId: string) {
  //  const dialogRef = this.dialog.open(DeleteDialogComponent, {
  //    width: '500px',
  //    height: '200px',
  //    data: { id: 1, name: "" }
  //  });
  //  dialogRef.afterClosed().subscribe(result => {
  //    if (result != undefined) {

  //      this.prePlanModel.prePlanFileDelete = true;
  //      this.prePlanModel.prePlanFileName = null;
  //      this.prePlanFileExists = false;
  //    }
  //  });
  //  return false;
  //}

 

  //openPrePlanFile(prePlanId: string, fileName: string) {
  //  this.prePlanService.getPrePlanFile('GetPrePlanFile', prePlanId, "PrePlan").subscribe(res => {
  //    saveAs(res, fileName);
  //  });
  //  return false;
  //}
  downloadFile(prePlanId: string, fileName: string) {
    this.prePlanService.getDownPrePlanFile('GetDownPrePlanFile', prePlanId, "PrePlan").subscribe(res => {

      //var blob = new Blob([data], { type: type });
      //var url = window.URL.createObjectURL(blob);

      saveAs(res, fileName);
      //window.location.href = "/";
      //let url = "https://localhost:44391/api/Occupancy/GetOccupancyFile?occupancyId=4&fileType=Occupancy";
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      //saveAs(res, "sdsd.txt");
      // var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }
  resetTheForm() {
    this.blockUI.start();
    this.prePlanModel = this.initializePrePlanModel();
    this.documentForm.resetForm();
  
    // this.loadGrid();
    this.getAllPrePlanForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.showNewButton = false;
    //this.searchMsg = "";
    // this.blockUI.stop();
    return false;
  }
  deleteDocument(Id: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.blockUI.start();
        this.prePlanService.delprePlan('delprePlan', Id).subscribe(res => {
          this.errMsg = res.msg;
          this.getAllPrePlanForDataSource();
          if (this.errMsg == "Deleted Successfully.") {
            const dialogRef = this.dialog.open(SaveDialogComponent, {
              width: '500px',
              height: '200px',
              data: { name: "Deleted Successfully!" }
            });
          }
          // this.loadGrid();
        });
      }

    });
    return false;
  }
 

  loadDropDowns() {
    forkJoin(
      this.systemCodeService.getSystemCodesForStreetNames(),
      this.systemCodeService.getSystemCodesForDistricts()
    ).subscribe(([res1, res2]) => {
      this.streetnameCode = res1 || [];
      this.streetNameKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.districtKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  onStreetChange() {

  }

  public searchPrePlanReport() {
    this.blockUI.start();
    this.showNewButton = true;
    if (!((this.prePlanModel.streetName == null || this.prePlanModel.streetName == 0))) {
      this.showNewButton = true;
    }
    this.getAllPrePlanForDataSource();    
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
