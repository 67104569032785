import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { BaseService } from "./base.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { RolePermission } from "../models/role-permission.model";
import { RolePermissionCheck } from "../models/role-permission-check.model";

@Injectable({ providedIn: 'root' })
export class RolePermissionsService extends BaseService {
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/RolePermission/', httpClient, errorService, authService);
  }

  getRolePermissions(roleId: number): Observable<Array<RolePermission>> {
    return this.getRequest(roleId);
  }

  updateRolePermissions(roleId: number, rolePermissions: Array<RolePermission>): Observable<any> {
    return this.putRequest(roleId, JSON.stringify(rolePermissions));
  }

  getRolePermissionForForm(userId: number, permissionFormId: number): Observable<RolePermissionCheck> {
    const params = new HttpParams()
      .set('userId', userId.toString())
      .set('permissionFormId', permissionFormId.toString())
    return this.customGetRequestWithQueryString<RolePermissionCheck>('GetRolePermissionForFormAsync', params);
  }
}
