import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router, Event } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { EventCalendar } from '../models/eventCalendar.model';
import { EventService } from '../services/event.service';
import { EventCalendarExclude } from '../models/eventcalendarexclude.model';


@Component({
  selector: 'eventupdate',
  templateUrl: './eventupdate.component.html',
})
export class EventUpdateComponent {
  @ViewChild('eventDptForm', { static: false }) eventTypeForm: NgForm;

  eventCalendarId: number = 0;
  eventObj: EventCalendar;
  eventDate: EventCalendarExclude[] = [];
  //eventTime: EventTime[] = [];
  eventTypes: Array<{ key: number, value: string }> = [];
  periodicals: Array<{ key: number, value: string }> = [];
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private eventDptService: EventService) {
    this.route.params.subscribe(params => {
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.eventCalendarId = parseInt(Id);
      }
    });
  }

  ngOnInit() {
    this.loadDropDowns();

    this.eventObj = {
      eventCalendarId: 0,
      eventTitle: null,
      eventType: 0,
      fromDate: null,
      toDate: null,
      description: null,
      eventTimeRange: null,
      eventEndTimeRange: null,
      duration: 0,
      periodical: 0,
      dailyOption: 0,
      dailyOccurenceCount: 0,
      weeklyOccurrenceCount: 0,
      fromDatestr: null,
      toDatestr: null,
      recurrenceToDatestr: null,
      recurrenceFromDatestr: null,
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      byMonthDayM: 0,
      byDayMData: null,
      byDayOnM1: 0,
      byDayM1Data: null,
      yearlyMonthM1: 0,
      byMonthDayY: 0,
      byDayYData: null,
      byDayOnY1: 0,
      byDayY1Data: null,
      yearlyMonthY1: 0,
      recurrenceFromDate: null,
      recurrenceToDate: null,
      rangeOccurrenceCount: 0,
      eventDate: null,
      eventInclude: null,
      excludeCheck: false,
      includeCheck: false,
      endDateCheck: false,
      repeatCheck: false
    };

    if (this.eventCalendarId > 0) {
      this.eventDptService.getEvent('getEvent', this.eventCalendarId).subscribe(res => {
        this.eventObj = res || [];
      });
    }
    else {
      //this.clearModelMonth();
      //this.clearMonth();
    }
  }

  public loadDropDowns() {
    this.systemCodeService.getAllEventType().subscribe(
      (res: Array<SystemCode>) => {
        // this.eventTypes = res || [];
        this.eventTypes = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );
    this.systemCodeService.getAllRepeatsPeriod().subscribe(
      (res: Array<SystemCode>) => {
        //this.periodicals = res || [];
        this.periodicals = res.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      }, (error: any) => { }
    );
  }

  GoBack() {
    this.routerService.navigate(['/eventreport']);
  }

  GoToEdit(id: number) {
    this.routerService.navigate(['eventscreen', { id }]);
  }
}
