import { Component, ViewChild, OnInit, Injectable } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, forkJoin } from 'rxjs';
import { PpeInspection } from '../models/ppeinspection.model';
import { PPEInspectionService } from '../services/ppeinspection.service';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';

@Component({
  selector: 'ppeinspectionform',
  templateUrl: './ppeinspectionform.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class PPEInspectionFormComponent implements OnInit {
  @ViewChild('ppeinspectionForm', { static: false }) ppeinspectionForm: NgForm;
  ppeInspectionModel: PpeInspection;
  crews: SystemCode[] = [];
  names: SystemCode[] = [];
  moistureBarriers: SystemCode[] = [];
  ppeInspectionId: number;
  ppedate = new Date();
  //minDate = new Date();
  //maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 1, 0);
  maxDate = new Date(2025, 11, 31);
  minDate = new Date(2010, 0, 1);
  floatLabel: string = 'never';
  gearIdPattern = /^[a-zA-Z0-9]{1,20}$/;
  employeeNameObj: Employee[] = [];
  employee: string;
  //options: any[] = ['Yes', 'No'];
  options: SystemCode[] = [];
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  rolePermissionCheckModel: RolePermissionCheck;
  disableSave: boolean = false;

  constructor(private dialog: MatDialog, private routerService: Router, private route: ActivatedRoute,
    private systemCodeStateService: SystemCodeService, private ppeinspectionservice: PPEInspectionService, private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];

    route.params.subscribe(val => {

      if (val.id == undefined) {
        this.ppeInspectionId = 0;
        this.resetTheForm();
        this.loadEmployeeName();
      }
      else {
        this.ppeInspectionId = parseInt(val.id);
        this.loadEmployeeName();
      }
    });
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      this.ppeInspectionModel = this.initializePpeInspectionModel();
      //this.ppeInspectionModel = {
      //  ppeInspectionId: 0, crew: 0, name: 0, date: null, helmet: 0, hood: 0, fireBoots: 0, fireGloves: 0,
      //  coatPants: 0, moistureBarrier: 0, usedInFireFighting: 0, cleaned: 0, contaminated: 0,
      //  deficiencies: 0, description: "", crewName: "", instructorName: "", moistureBarrierName: "", startDate: null, endDate: null
      //};
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.ppeInspectionId = parseInt(Id);
          this.loadForm();
        }
      });
      this.loadEmployeeName();
    }
  }

  initializePpeInspectionModel(): PpeInspection {
    return {
      ppeInspectionId: 0, crew: null, name: null, date: null, helmet: null, hood: null, fireBoots: null, fireGloves: null,
      coat: null, pants: null, moistureBarrier: null, gearId: null, pantsGearId: null, usedInFireFighting: null, cleaned: null, contaminated: null,
      deficiencies: null, description: "", crewName: "", instructorName: "", moistureBarrierName: "", startDate: null, endDate: null
    } as PpeInspection;
  }

  loadForm() {
    if (this.ppeInspectionId > 0) {
      this.ppeinspectionservice.getPPEInspections('GetPPEInspections', this.ppeInspectionId).subscribe(res => {
        this.ppeInspectionModel = res;
      }, (error: any) => { }
      );
    }
  }

  loadEmployeeName() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      if (this.ppeInspectionId === 0) {
        this.employeeNameObj = res.filter(x => x.isActive == true);
      }
      else
        this.employeeNameObj = res;
    }, (error: any) => { });
    //  this.employeeNameObj = res;
    //});
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForShifts(),
      this.systemCodeStateService.getAllInstructors(),
      this.systemCodeStateService.getSystemCodesForMoistureBarrier(),
      //this.employeeService.getAllEmployees('GetEmployees', 0),
      this.systemCodeStateService.getSystemCodesForYesNo()
    ).subscribe(([res1, res2, res3, res4]) => {
      this.crews = res1 || [];
      this.names = res2 || [];
      this.moistureBarriers = res3 || [];
      //this.employeeNameObj = res4.filter(s => s.isActive == true);;
      this.options = res4 || [];
    });

    //this.systemCodeStateService.getSystemCodesForShifts().subscribe(
    //  (res: Array<SystemCode>) => {
    //    this.crews = res || [];

    //  }, (error: any) => { }
    //);

    //this.systemCodeStateService.getSystemCodesForInstructor().subscribe(
    //  (res: Array<SystemCode>) => {
    //    this.names = res || [];

    //  }, (error: any) => { }
    //);

    //this.systemCodeStateService.getSystemCodesForMoistureBarrier().subscribe(
    //  (res: Array<SystemCode>) => {
    //    this.moistureBarriers = res || [];
    //  }, (error: any) => { }
    //);
  }

  //getDateAsString(dt: Date) {
  //  //return dt.getMonth() + 1 + "/" + dt.getDate() + "/" + dt.getFullYear();
  //  return ('0' + (dt.getMonth() + 1)).slice(-2) + "/" + ('0' + dt.getDate()).slice(-2) + "/" + dt.getFullYear();

  //}

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }


  public savePPEinspections() {
    this.disableSave = true;
    this.ppeInspectionModel.dateStr = this.getDateAsString(new Date(this.ppeInspectionModel.date));
    if (this.ppeInspectionId > 0) {
      //this.updatePPEinspections();
      this.ppeinspectionservice.updatePPEInspection(this.ppeInspectionId, this.ppeInspectionModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.resetTheForm();
            this.routerService.navigate(['/ppeinspectionreport']);
            //this.routerService.navigate(['/ppeinspectionform']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          //dialogRef.afterClosed().subscribe(result => {          
          //  this.routerService.navigate(['/ppeinspectionform']);          
          //});
        }
      });
    }
    else {

      this.ppeinspectionservice.savePPEInspection('SavePPEInspection', this.ppeInspectionModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            //this.routerService.navigate(['/ppeinspectionreport']);
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          //dialogRef.afterClosed().subscribe(result => {
          //  //this.navigateToSearchUsers();
          //});
        }
      });
    }
  }

  //public updatePPEinspections() {
  //  this.ppeinspectionservice.updatePPEInspection(this.ppeInspectionId , this.ppeInspectionModel).subscribe(res => {
  //    this.errMsg = res.msg;
  //    if (this.errMsg == "Updated Successfully.") {
  //      this.savedialog = true;
  //    }
  //    else {
  //      this.errordialog = true;
  //    }

  //    if (this.savedialog == true) {
  //      const dialogRef = this.dialog.open(SaveDialogComponent, {
  //        width: '500px',
  //        height: '200px',
  //        data: { name: "Saved Successfully!" }
  //      });
  //      dialogRef.afterClosed().subscribe(result => {
  //        this.resetTheForm();
  //        this.routerService.navigate(['/ppeinspectionform']); 
  //      });
  //    } else if (this.errordialog == true) {
  //      const dialogRef = this.dialog.open(ErrorDialogComponent, {
  //        width: '500px',
  //        height: '200px',
  //        data: { name: "Error", description: "Error Occured. Please try again!" }
  //      });
  //      //dialogRef.afterClosed().subscribe(result => {          
  //      //  this.routerService.navigate(['/ppeinspectionform']);          
  //      //});
  //    }
  //  });
  //}

  public resetTheForm() {
    //this.ppeInspectionModel = {
    //  ppeInspectionId: 0, crew: 0, name: 0, date: null, helmet: 0, hood: 0, fireBoots: 0, fireGloves: 0,
    //  coatPants: 0, moistureBarrier: 0, usedInFireFighting: 0, cleaned: 0, contaminated: 0,
    //  deficiencies: 0, description: "", crewName: "", instructorName: "", moistureBarrierName: "", startDate: null, endDate: null
    //};
    this.ppeinspectionForm.resetForm();
    this.ppeInspectionModel = this.initializePpeInspectionModel();
    this.disableSave = false;
    return false;
  }

  GoBack() {
    this.routerService.navigate(['/ppeinspectionreport']);
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
