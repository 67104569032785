import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { Occupancy } from '../models/occupancy.model';
import { SystemCode } from '../models/system-codes.model';
import { GetAllOccupancy } from '../models/get-all-occupancy.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class OccupancyService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/Occupancy/', httpClient, errorService, authService);
  }

  saveOccupancy(action: string, occupancyObj: Occupancy): Observable<any> {
    return this.postRequest(action, JSON.stringify(occupancyObj));
  }
  saveOccupancywithFile(action: string, formdata: FormData): Observable<any> {
    return this.customPostWithFile(action, formdata);
  }
  saveOccupancywithFileNew(action: string, formdata: FormData): Observable<any> {
    return this.uploadFile(action, formdata);
  }
  updateOccupancywithFile(action: string, formdata: FormData): Observable<any> {
    return this.customPutRequest(action, formdata);
  }

  getAllOccupancies(action: string, employeeId: Number) {
    const params = new HttpParams()
      .set('employeeId', employeeId.toString());
    return this.customGetRequestWithQueryString<Array<Occupancy>>(action, params);
  }

  getAllOccupanciesForGrid(): Observable<Array<Occupancy>> {
    return this.customGetRequest<Array<Occupancy>>('getAllOccupanciesForGrid', null);
  }

  deleteOccupancy(action: string, occupancyResultObj: Occupancy): Observable<any> {
    return this.postRequest(action, JSON.stringify(occupancyResultObj));
  }
  delOccupancy(action: string, oId: number): Observable<any> {

    return this.deleteRequest(oId);
    //return this.postRequest(action, JSON.stringify(obj));
  }

  searchOccupancies(action: string, districtId: Number, streetName: string, businessName: string) {
    const params = new HttpParams()
      .set('districtId', districtId.toString())
      .set('streetName', streetName.toString())
      .set('businessName', businessName.toString())   ;

    return this.customGetRequestWithQueryString<Array<Occupancy>>(action, params);
  }

  getOccupancy(action: string, occupancyId: string) {
    const params = new HttpParams()
      .set('occupancyId', occupancyId.toString());
    return this.customGetRequest<Occupancy>(action, occupancyId.toString());  
  }
  getOccupancyFile(action: string, occupancyId: string,filetype:string) {
    const params = new HttpParams()
      .set('occupancyId', occupancyId)
      .set('fileType', filetype)
    return this.customGetRequestWithQuery(action, params);
  }
  getBuisness(action: string): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>(action, null);
  }
  getStreets(action: string): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>(action, null);
  }
  getAllOccupancyNew(action: string, district: number, streetName: number, business: number,  pageNumber: number = 0, pageSize: number = 10): Observable<GetAllOccupancy> {
    const params = new HttpParams()

      .set('district', district.toString())
      .set('streetName', streetName.toString())
      .set('business', business.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    console.log('Params: ', params);

    return this.customGetRequestWithQueryString<GetAllOccupancy>(action, params);
  }
  getOccupancyFilesList(action: string, occupancyFileId: string, filetype: string) {
    const params = new HttpParams()
      .set('occupancyFileId', occupancyFileId)
      .set('fileType', filetype)
    return this.customGetRequestWithQuery(action, params);
  }
}
