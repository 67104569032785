import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { SystemCodeService } from '../services/system-code.service';
import { EMSTraingHoursService } from '../services/emstraininghours.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { Observable, forkJoin } from 'rxjs';
import { EmsTrainingHours } from '../models/emstraininghours.model';
import { EmsTrainingCodes } from '../models/emstrainingcodes.model';
import { EmsTrainingInstructor } from '../models/emstraininginstructor.model';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';

import { debug } from 'util';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { parse } from 'date-fns';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core'; import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';

@Component({
  selector: 'emstraininghoursform',
  templateUrl: './emstraininghours.component.html',
   providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class EmsTrainingHoursForm {

  @ViewChild('emsTraingHoursForm', { static: false }) emsTrainingHoursForm: NgForm;


  emsTrainingModel: EmsTrainingHours;
  emsTrainingInstructorModel: EmsTrainingInstructor;
  emsTrainingCodesModel: EmsTrainingCodes;
  emsTrainingCodes: EmsTrainingCodes[] = [];
  emsTrainingInstructor: EmsTrainingInstructor[] = [];
  trainingCodesErrorMsg: string;
  totalActiveItems: number = 0;
  trainingCodefieldempty: boolean = true;
  totalActiveItemsofInstructor: number = 0;
  trainingInstructorErrorMsg: string;
  trainingInstructorfieldempty: boolean = true;
  employeeResutObj: Employee[] = [];
  employee: number[] = [];
  employeeNames: string[] = [];
  employeeCount: number = 0;
  instructors: SystemCode[] = [];
  trainingCodes: SystemCode[] = [];
  students: SystemCode[] = [];
  EMSTrainingId: number = 0;
  emsTrainingHourId: number = 0;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  showinstructor: boolean = false;
  other: number = 2364;
  isNew: string;
  todayDate: Date = new Date();
  displayAddAnotherTrainingCode: boolean = false;
  displayAddAnotherTrainingHours: boolean = false;
  showAddAnotherInstructorButton: boolean = false;
  showAddAnotherTrainingCodeButton: boolean = false;
  showDeleteButtonForInstructor: boolean = false;
  //traininghourPattern = /^(?:[0-9]{1,2}?\.(?:25|75|50))$/;
  
  hourPattern = /^([0-1]?[0-9]|[0-9]+):[0-5][0-9]$/;
  traininghourPattern = /^([1-9]{1,2}|(?:[0-9]{1,2}?\.(?:25|75|50|00|5)))$/;
  kemsishoursPattern = /^([0-1]?[0-9]|[0-9]+):[0-5][0-9]$/;
  KEMSISnumberPattern = /^[a-zA-Z0-9]{1,15}$/;

  rolePermissionCheckModel: RolePermissionCheck;

  constructor(private dialog: MatDialog,
    private routerService: Router,
    private emsTraingHourService: EMSTraingHoursService,
    private route: ActivatedRoute,
    private systemCodeStateService: SystemCodeService,
    private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }
  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.emsTrainingHourId = parseInt(Id);
        }
        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;

        }
      });
      this.emsTrainingInstructorModel = this.initializeEmsTrainingInstructorModel();
      this.emsTrainingCodesModel = this.initializeEmsTrainingCodesModel();
      this.emsTrainingModel = this.initializeEmsTrainingHoursModel();
      this.loadGrid();
      var emsTrainingInstructorModel = this.initializeEmsTrainingInstructorModel();
      this.emsTrainingInstructor.push(emsTrainingInstructorModel);
      var emsTrainingCodesModel = this.initializeEmsTrainingCodesModel();
      this.emsTrainingCodes.push(emsTrainingCodesModel);
      this.loadDropDowns();

      if (this.emsTrainingHourId > 0) {
       
        this.emsTraingHourService.getEMSTrainingHours('GetEMSTraining', this.emsTrainingHourId).subscribe(res => {
          this.emsTrainingModel = res;
          this.emsTrainingInstructor = res.emsTrainingHourInstructors;
          this.emsTrainingCodes = res.emsTrainingHourTrainingCodes;
          this.employee = res.emsTrainingHourStudentsData;
          var lyear = this.todayDate.getFullYear();
          var lmonth = this.todayDate.getMonth();
          var lday = this.todayDate.getDate();
          this.todayDate = new Date(lyear - 50, lmonth, lday);
         
          if (this.emsTrainingInstructor != null) {
            for (var i = 0; i < this.emsTrainingInstructor.length; i++) {
              if (this.emsTrainingInstructor[i].isActive == true) {

                if (this.emsTrainingInstructor[i].instructor == this.other) {
                  this.emsTrainingInstructor[i].otherSelected = true;

                }
                else {

                  this.emsTrainingInstructor[i].otherSelected = false;
                  this.emsTrainingInstructor[i].otherInstructor = null;

                }
              }
              //theemsTrainingInstructor.push(this.emsTrainingInstructor[i]);
            }
          }
          if (this.emsTrainingCodes != null) {
            for (var i = 0; i < this.emsTrainingCodes.length; i++) {
              if (this.emsTrainingCodes[i].hours.toString.length <= 2) {
                var data = this.emsTrainingCodes[i].hours.toString() + ".00";
                var hourData = parseFloat(data);
                this.emsTrainingCodes[i].hours = hourData;
              }

            }
          }
          if (this.emsTrainingInstructor.length == 0) {
            this.addAnotherItemofInstructor();
          }

          this.calculateTotalActiveItemsofInstructor();
          this.calculateTotalActiveItems();
        },
          err => {
            //this.blockUI.stop();
          });
      }
      else {
        var lyear = this.todayDate.getFullYear();
        var lmonth = this.todayDate.getMonth();
        var lday = this.todayDate.getDate();
        this.todayDate = new Date(lyear, lmonth, lday);
      }
    }
  }
  initializeEmsTrainingHoursModel(): EmsTrainingHours {
    return {
      emsTrainingHourId: 0,
      className: null,
      trainingDate: null,
      trainingTime: null,
      kemsisNumber: null,
      hours: null,
      // employeeId: null,
      eMSTrainingHourStudentsData: null,
      numberOfStudents: null,
      eMSTrainingHourTrainingCodes: null,
      eMSTrainingHourInstructors: null


    } as EmsTrainingHours;
  }

  initializeEmsTrainingCodesModel(): EmsTrainingCodes {
    return {
      emsTrainingHourTrainingCodeId: 0,
      emsTrainingHourId: 0,
      trainingCode: null,
      hours: null,
      isActive: true,
      orderId: this.emsTrainingCodes.length + 1
    } as EmsTrainingCodes;
  }
  //commentInput(event: string) {
  //  if (event != '') {
  //    this.trainingCodesErrorMsg = "";
  //    this.trainingCodefieldempty = true;
  //  } else {
  //    this.trainingCodesErrorMsg = "Training Code field is required";
  //    this.trainingCodefieldempty = false;
  //  }
  //}
  commentInput() {
    for (var i = 0; i < this.emsTrainingCodes.length; i++) {

      if (this.emsTrainingCodes[i].isActive == true && (this.emsTrainingCodes[i].trainingCode != null && this.emsTrainingCodes[i].hours != null)) {
        this.showAddAnotherTrainingCodeButton = true;
      } else {
        this.showAddAnotherTrainingCodeButton = false;
      }
    }
    if (this.emsTrainingCodes.length > 0) {
      if (this.emsTrainingCodes[this.emsTrainingCodes.length - 1].trainingCode == 0 && this.emsTrainingCodes[this.emsTrainingCodes.length - 1].isActive != true) {
        this.trainingCodesErrorMsg = "Training Code field is required";
        this.displayAddAnotherTrainingCode = false;
      }
      else {
        this.trainingCodesErrorMsg = "";
        this.displayAddAnotherTrainingCode = true;
      }
   
    }
  }


  initializeEmsTrainingInstructorModel(): EmsTrainingInstructor {
    return {
      emsTrainingHourInstructorId: 0,
      emsTrainingHourId: 0,
      instructor: null,
      isActive: true,
      otherInstructor: null,
      instructorOrderId: this.emsTrainingInstructor.length + 1,
      otherSelected:false

    } as EmsTrainingInstructor;
  }
  //commentInputInstructor(event: string) {
  //  if (event != '') {
  //    this.trainingInstructorErrorMsg = "";
  //    this.trainingInstructorfieldempty = true;
  //  } else {
  //    this.trainingInstructorErrorMsg = "Instructor field is required";
  //    this.trainingInstructorfieldempty = false;
  //  }
  //}
  commentInputInstructor(value: any, event: any) {    
    for (var i = 0; i < this.emsTrainingInstructor.length; i++) {
      
      if (this.emsTrainingInstructor[i].isActive == true) {
        this.showAddAnotherInstructorButton = true;
        this.showDeleteButtonForInstructor = true;
       // this.totalActiveItems = 2;
        if (this.emsTrainingInstructor[i].instructor == this.other) {
          this.emsTrainingInstructor[i].otherSelected = true;
        }
        else {
          this.emsTrainingInstructor[i].otherSelected = false;
          this.emsTrainingInstructor[i].otherInstructor = null;

        }
      }
      else {
        this.showAddAnotherInstructorButton = false;
        this.showDeleteButtonForInstructor = false;
      }
      
      //theemsTrainingInstructor.push(this.emsTrainingInstructor[i]);
    }
    if (this.emsTrainingInstructor.length > 0) {
      if (this.emsTrainingInstructor[this.emsTrainingInstructor.length - 1].instructor == 0 && this.emsTrainingInstructor[this.emsTrainingInstructor.length - 1].isActive != true) {
        this.trainingInstructorErrorMsg = "Instructor field is required";
      }
      else {
        this.trainingInstructorErrorMsg = "";
      }
    }
    if (event.source.selected.viewValue == "Other Instructor") {
      this.showinstructor = true;
    }
    else {
      this.showinstructor = false;
    }

  }



  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
      this.employeeResutObj = res;
    });

  }

  disableSavebutton() {
    for (var i = 0; i < this.emsTrainingCodes.length; i++) {

      if (this.emsTrainingCodes[i].isActive == true && (this.emsTrainingCodes[i].trainingCode != null && this.emsTrainingCodes[i].hours != null)) {
        this.showAddAnotherTrainingCodeButton = true;
      } else {
        this.showAddAnotherTrainingCodeButton = false;
      }
    }
    if (this.emsTrainingCodes.length > 0) {
      if (this.emsTrainingCodes[this.emsTrainingCodes.length - 1].hours == null) {
        this.trainingCodesErrorMsg = "Training Hours field is required";
        this.displayAddAnotherTrainingHours = false;
      }
      else {
        this.trainingCodesErrorMsg = "";
        this.displayAddAnotherTrainingHours = true;
      }
     
    }
  }

  addAnotherItem() {
    if (this.emsTrainingCodes.length > 0) {
      let theemsTrainingCodes: EmsTrainingCodes[] = [];
      for (var i = 0; i < this.emsTrainingCodes.length; i++) {
        if (this.emsTrainingCodes[i].isActive == true) {
          theemsTrainingCodes.push(this.emsTrainingCodes[i]);
        }
      }
      if (theemsTrainingCodes[theemsTrainingCodes.length - 1].trainingCode > 0 && theemsTrainingCodes[theemsTrainingCodes.length - 1].hours > 0) {
        var emsTrainingCodesModel = this.initializeEmsTrainingCodesModel();
        this.emsTrainingCodes.push(emsTrainingCodesModel);
        this.displayAddAnotherTrainingCode = false;
        this.displayAddAnotherTrainingHours = false;
        // this.requestErrorMsg = "";
        this.trainingCodefieldempty = false;
       
      }
      else {
        this.trainingCodesErrorMsg = "The above field is required/invalid";
      
      }
    }
    else {
      var emsTrainingCodesModel = this.initializeEmsTrainingCodesModel();
      this.emsTrainingCodes.push(emsTrainingCodesModel);
    }
    this.calculateTotalActiveItems();
    return false;
  }

  calculateTotalActiveItems() {
    this.totalActiveItems = 0;
    for (let x = 0; x < this.emsTrainingCodes.length; x++) {
      if (this.emsTrainingCodes[x].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
      if (this.totalActiveItems > 0 && (this.emsTrainingCodes[x].trainingCode != null && this.emsTrainingCodes[x].hours)) {
        this.showAddAnotherTrainingCodeButton = true;
            this.displayAddAnotherTrainingCode = true;
        this.displayAddAnotherTrainingHours = true;
      }
      if ((this.emsTrainingCodes[x].trainingCode == null && this.emsTrainingCodes[x].hours == null) && this.emsTrainingCodes[x].isActive == true) {
        this.showAddAnotherTrainingCodeButton = false;
        this.displayAddAnotherTrainingCode = false;
      this.displayAddAnotherTrainingHours = false;
      }
      //if (this.emsTrainingCodes[x].trainingCode != null && this.emsTrainingCodes[x].hours != null) {
      //  this.displayAddAnotherTrainingCode = true;
      //  this.displayAddAnotherTrainingHours = true;
      //}
      //else {
      //  this.displayAddAnotherTrainingCode = false;
      //  this.displayAddAnotherTrainingHours = false;
      //}
    }
  }

  deleteItem(id: number, commentId: number) {
    this.totalActiveItems = 0;
    if (id > 0) {
      const index = this.emsTrainingCodes.findIndex(x => x.orderId == id);

      for (let x = 0; x < this.emsTrainingCodes.length; x++) {
        if (this.emsTrainingCodes[x].isActive == true) {
          this.totalActiveItems = this.totalActiveItems + 1;
        }
        
      }
      if (this.totalActiveItems == 1) {
        this.emsTrainingCodes[index].trainingCode = null;
        this.emsTrainingCodes[index].hours = null;
        this.emsTrainingCodes[index].emsTrainingHourId = 0;
        this.emsTrainingCodes[index].emsTrainingHourTrainingCodeId = 0;
        this.trainingCodesErrorMsg = "";
      }
      else {
        this.emsTrainingCodes[index].isActive = false;
        this.emsTrainingCodes[index].hours = 1;
        this.emsTrainingCodes[index].trainingCode = 1;
        this.trainingCodesErrorMsg = "";
      }
    }
    if (commentId > 0) {
      const index = this.emsTrainingCodes.findIndex(x => x.emsTrainingHourTrainingCodeId == commentId);

      for (let x = 0; x < this.emsTrainingCodes.length; x++) {
        if (this.emsTrainingCodes[x].isActive == true) {
          this.totalActiveItems = this.totalActiveItems + 1;
        }
      }
      if (this.totalActiveItems == 1) {
        // this.maintenenceRequestComments[index].requestComment = null;
        //this.maintenenceRequestComments[index].maintenenceRequestId = 0;
        //   this.maintenenceRequestComments[index].maintanenceRequestCommentId = 0;
        this.emsTrainingCodes[index].isActive = false;

      }
      else {
        this.emsTrainingCodes[index].isActive = false;
        this.trainingCodesErrorMsg = "";
        this.trainingCodefieldempty = true;
      }
    }
    this.calculateTotalActiveItems();
    return false;
  }


  addAnotherItemofInstructor() {
    if (this.emsTrainingInstructor.length > 0) {
      let theemsTrainingInstructor: EmsTrainingInstructor[] = [];
      for (var i = 0; i < this.emsTrainingInstructor.length; i++) {
        if (this.emsTrainingInstructor[i].isActive == true) {
          theemsTrainingInstructor.push(this.emsTrainingInstructor[i]);
        }
      }
      if (theemsTrainingInstructor[theemsTrainingInstructor.length - 1].instructor > 0) {
        var emsTrainingInstructorModel = this.initializeEmsTrainingInstructorModel();
        this.emsTrainingInstructor.push(emsTrainingInstructorModel);
        this.showAddAnotherInstructorButton = false;
        // this.requestErrorMsg = "";
        this.trainingInstructorfieldempty = false;
      }
      else {
        this.trainingInstructorErrorMsg = "Instructor field is required";
      }
    }
    else {
      var emsTrainingInstructorModel = this.initializeEmsTrainingInstructorModel();
      this.emsTrainingInstructor.push(emsTrainingInstructorModel);
    }
    this.calculateTotalActiveItemsofInstructor();
    return false;
  }
  calculateTotalActiveItemsofInstructor() {
    this.totalActiveItemsofInstructor = 0;
    for (let x = 0; x < this.emsTrainingInstructor.length; x++) {
      if (this.emsTrainingInstructor[x].isActive == true) {
        this.totalActiveItemsofInstructor = this.totalActiveItemsofInstructor + 1;
      }
      if (this.totalActiveItemsofInstructor > 0 && this.emsTrainingInstructor[x].instructor != null) {
        this.showAddAnotherInstructorButton = true;
      }
      if (this.emsTrainingInstructor[x].instructor == null && this.emsTrainingInstructor[x].isActive == true) {
        this.showAddAnotherInstructorButton = false;
        
      }
    
    }
  }

  deleteInstructorItem(id: number, instructorId: number) {
    this.totalActiveItemsofInstructor = 0;   
    if (id > 0) {
      const index = this.emsTrainingInstructor.findIndex(x => x.instructorOrderId == id);
     

      for (let x = 0; x < this.emsTrainingInstructor.length; x++) {
        if (this.emsTrainingInstructor[x].isActive == true) {
          this.totalActiveItemsofInstructor = this.totalActiveItemsofInstructor + 1;
         // this.showDeleteButtonForInstructor = true;
        }
        else {
         // this.showDeleteButtonForInstructor = false;
        }
        
      }
      if (this.totalActiveItemsofInstructor == 1) {
        this.emsTrainingInstructor[index].instructor = null;
        this.emsTrainingInstructor[index].emsTrainingHourId = 0;
        this.emsTrainingInstructor[index].emsTrainingHourInstructorId = 0;
      
        this.trainingInstructorErrorMsg = "";
      }
      else {
        this.emsTrainingInstructor[index].isActive = false;
        this.trainingInstructorErrorMsg = "";
      }      
    }
    if (instructorId > 0) {
      const index = this.emsTrainingInstructor.findIndex(x => x.emsTrainingHourInstructorId == instructorId);

      for (let x = 0; x < this.emsTrainingInstructor.length; x++) {
        if (this.emsTrainingInstructor[x].isActive == true) {
          this.totalActiveItemsofInstructor = this.totalActiveItemsofInstructor + 1;
        }

      }
      if (this.totalActiveItemsofInstructor == 1) {
        // this.maintenenceRequestComments[index].requestComment = null;
        //this.maintenenceRequestComments[index].maintenenceRequestId = 0;
        //   this.maintenenceRequestComments[index].maintanenceRequestCommentId = 0;
        this.emsTrainingInstructor[index].isActive = false;
        

      }
      else {
        this.emsTrainingInstructor[index].isActive = false;
        this.trainingInstructorErrorMsg = "";
        this.trainingInstructorfieldempty = true;
      }
      if (this.emsTrainingInstructor[index + 1].instructor != null || this.emsTrainingInstructor[index - 1].instructor != null) {
        this.showAddAnotherInstructorButton = true;
      }
      else {
        this.showAddAnotherInstructorButton = false;
      }
    }
    //this.showinstructor = false;
    this.calculateTotalActiveItemsofInstructor();
    return false;
  }



  getSelected(event: any) {
    if (event.source._selected == true) {
      this.employeeCount = this.employeeCount + 1;
      this.emsTrainingModel.numberOfStudents = this.employeeCount;
    }
    else {
      this.employeeCount = this.employeeCount - 1;
      this.emsTrainingModel.numberOfStudents = this.employeeCount;
    }
  }

  public loadDropDowns() {

    //  this.loadEMSCategory();

    forkJoin(

      //this.systemCodeStateService.getSystemCodesForEmscategory(),
      //this.systemCodeStateService.getSystemCodesForShifts(),
      //this.systemCodeStateService.getSystemCodesForStation(),
      this.systemCodeStateService.getSystemCodesForEMSTrainingInstructor(),
      this.systemCodeStateService.getAllInstructors(),
      this.systemCodeStateService.getAllEMSTrainingCodes()
      //this.systemCodeStateService.getSystemCodesForTrainingTypes()


    ).subscribe(([res1, res2, res3]) => {
      //this.emsCategoryList = res || [];
      //this.crew = res1 || [];
      //this.station = res2 || [];
      this.instructors = res1 || [];
      this.students = res2 || [];
      this.trainingCodes = res3 || [];
      //this.trainingTypes = res4 || [];

    });

  }
  resetTheForm() {

    this.emsTrainingModel = this.initializeEmsTrainingHoursModel();
    this.emsTrainingModel.eMSTrainingHourStudentsData = null;

    this.emsTrainingCodes = [];
    var emsTrainingCode = this.initializeEmsTrainingCodesModel();
    this.emsTrainingCodes.push(emsTrainingCode);
    this.trainingCodesErrorMsg = "";

    this.emsTrainingInstructor = [];
    var emsTrainingInstructor = this.initializeEmsTrainingInstructorModel();
    this.emsTrainingInstructor.push(emsTrainingInstructor);
    this.trainingInstructorErrorMsg = "";

    this.emsTrainingHoursForm.resetForm();
    this.emsTrainingModel = this.initializeEmsTrainingHoursModel();
    var emsTrainingCode = this.initializeEmsTrainingCodesModel();
    var emsTrainingInstructor = this.initializeEmsTrainingInstructorModel();
    this.showinstructor = false;
    this.displayAddAnotherTrainingCode = false;
    this.displayAddAnotherTrainingHours = false;
    this.showAddAnotherInstructorButton  = false;
    this.showAddAnotherTrainingCodeButton = false;
    this.showDeleteButtonForInstructor = false;
    return false;

  }

  public saveEmsTrainingHour() {
    if (this.emsTrainingCodes[this.emsTrainingCodes.length - 1].hours != null) {
      for (let x = 0; x < this.emsTrainingCodes.length; x++) {
        var trainingcodehoursStr = this.emsTrainingCodes[x].hours.toString();
        var trainingcodehoursNum = parseFloat(trainingcodehoursStr);
        this.emsTrainingCodes[x].hours = trainingcodehoursNum;
      }
      this.emsTrainingModel.eMSTrainingHourTrainingCodes = this.emsTrainingCodes;
    }
    this.emsTrainingModel.eMSTrainingHourInstructors = this.emsTrainingInstructor;
    //this.emsTrainingModel.eMSTrainingHourInstructors = this.emsTrainingInstructor;
    //if (this.emsTrainingInstructor[this.emsTrainingInstructor.length - 1].instructor != null) {
    //  this.emsTrainingModel.eMSTrainingHourInstructors = this.emsTrainingInstructor;
    //}
    
    this.emsTrainingModel.eMSTrainingHourStudentsData = this.employee;
    if (this.emsTrainingHourId > 0) {
      this.emsTraingHourService.updateEMSTrainingHours(this.emsTrainingHourId, this.emsTrainingModel).subscribe(res => {

        this.errMsg = res.msg;
        this.resetTheForm();
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            this.emsTrainingHourId = 0;
            this.routerService.navigate(['/emstraininghoursform']);
            //this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
          });
        }
      });
    }
    else {

      this.emsTraingHourService.saveEMSTrainingHour('saveEMSTrainingHour', this.emsTrainingModel).subscribe(res => {

        this.errMsg = res.msg;
        this.resetTheForm();
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            //this.routerService.navigate(['/emstraininghoursreport']);
            //this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
          //dialogRef.afterClosed().subscribe(result => {
          //  //this.navigateToSearchUsers();
          //});
        }
      });
    }
  }
  showOther(index: number) {
    for (var i = 0; i < this.emsTrainingInstructor.length; i++) {
      if (index == i) {
        if (this.emsTrainingInstructor[i].instructor == this.other) {
          this.emsTrainingInstructor[i].otherSelected = true;

        }
      }
    }
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  get checkFormIsInvalid(): boolean {
    return this.emsTrainingHoursForm.valid;
  }
}
