import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { GetAllMaintanence } from '../models/get-all-maintanence.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class MaintanenceRequestService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/MaintanenceRequest/', httpClient, errorService, authService);
  }

  saveMaintanence(action: string, maintenencerequestObj: MaintenenceRequest): Observable<any> {
    return this.postRequest(action, JSON.stringify(maintenencerequestObj));
  }

  getMaintanence(action: string, maintenenceRequestId: number): Observable<any> {
    return this.customGetRequest<MaintenenceRequest>(action, maintenenceRequestId.toString());
  }

  getMaintanenceCommentsHistory(action: string, maintanenceRequestCommentId: number): Observable<any> {
    return this.customGetRequest<MaintenenceRequest>(action, maintanenceRequestCommentId.toString());
  }

  maintanenceReport(action: string, maintenenceRequestModel: any): Observable<Array<MaintenenceRequest>> {       
    const params = new HttpParams()
      .set('pendingOrCompleteId', maintenenceRequestModel.pendingOrCompleteId)
      .set('typeId', maintenenceRequestModel.typeId.toString())      
    return this.customGetRequestWithQueryString<Array<MaintenenceRequest>>(action, params);
  }

  getAllMaintanence(): Observable<Array<MaintenenceRequest>> {
    return this.customGetRequest<Array<MaintenenceRequest>>('getAllMaintanence', null);
  }

  updateMaintanence(maintenenceRequestId: number, maintenencerequestObj: MaintenenceRequest): Observable<any> {

    return this.putRequest(maintenenceRequestId, JSON.stringify(maintenencerequestObj));
  }

  deleteMaintanenceRequest(action: string, maintenenceRequestModel: MaintenenceRequest): Observable<any> {
    return this.postRequest(action, JSON.stringify(maintenenceRequestModel));
  }

  delMaintanenceRequest(action: string, mId: number): Observable<any> {
    return this.deleteRequest(mId);
    //return this.postRequest(action, JSON.stringify(obj));
  }

  getAllMaintanenceNew(action: string, pendingOrCompleteId: number = 0, typeId: number = 0, subTypeId: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllMaintanence> {
    const params = new HttpParams()
      .set('pendingOrCompleteId', pendingOrCompleteId.toString())
      .set('typeId', typeId.toString())
      .set('subTypeId', subTypeId.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.customGetRequestWithQueryString<GetAllMaintanence>(action, params);
  }

  getMaintanenceRequestForDate(action: string): Observable<string> {
    return this.customGetRequest<string>(action, null);
  }

  getMaintanenceRequestUserName(action: string): Observable<string> {
    return this.customGetRequest<string>(action, null);
  }

  getAllMaintanenceForAllDataNew(action: string, pendingOrCompleteId: number = 0, typeId: number = 0, subTypeId: number = 0,): Observable<any> {
    const params = new HttpParams()
      .set('pendingOrCompleteId', pendingOrCompleteId.toString())
      .set('typeId', typeId.toString())
      .set('subTypeId', subTypeId.toString());
    return this.customGetRequestWithQueryString<Array<MaintenenceRequest>>(action, params);
  }

  //maintanenceReport(action: string, maintenenceRequestModel: any): Observable<Array<Maintenencerequest>> {
  //  return this.customGetRequestWithQueryString<Array<Maintenencerequest>>(action, maintenenceRequestModel);
  //}

  //getMaintanenceRequestForDate(): Observable<Array<MaintenenceRequest>> {
  //  debugger;
  //  return this.customGetRequest<Array<MaintenenceRequest>>('getMaintanenceRequestForDate', null);
  //}
}
