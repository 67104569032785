import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Certification } from '../models/certification.model';
import * as jspdf from 'jspdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; 
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'certificationpdfgenerator',
  templateUrl: './certificatePdfgenerator.component.html'

})
export class CertificatePdfGenerator {
  @BlockUI() blockUI: NgBlockUI;
  empcertifications: Certification[] = [];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private routerService: Router,
    private route: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }
  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isPrint) {
    //  this.blockUI.start();
      this.downloadInPdf();
    }
  }

  downloadInPdf() {
    var getData = localStorage.getItem('empcertifications');
    if (getData != undefined && getData != null) {
     // this.blockUI.start();
      this.empcertifications = JSON.parse(getData);
      if (this.empcertifications.length > 0) {
        var data = document.getElementById('content');
        html2canvas(data).then(canvas => {
          // Few necessary setting options
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm');
          var position = 0; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight; // top padding for other pages
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          doc.save('file.pdf'); // Generated PDF
          this.blockUI.stop();
          setTimeout(function () {
            self.close();
          }, 1500);
        });
      }
    }
    else {
      this.blockUI.stop();
    }
    return false;
  }
  getDisplayDate(dt: any) {
    if (dt == null) {
      return "";
    }   
    let dt1: Date = new Date(dt);
    
    if (dt1.getDate().toString() == "1" && dt1.getMonth().toString() == "0" && (dt1.getFullYear().toString() == "1" || dt1.getFullYear().toString() == "0" || dt1.getFullYear().toString() == "1899" || dt1.getFullYear().toString() == "1900")) {
      return "";
    }
    else {
      return dt1;
    }

  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}



