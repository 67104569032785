import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Employee } from '../models/employee.model';
import { ErrorService } from './error.service';
import { Certification } from '../models/certification.model';
import { GetAllEmployees } from '../models/get-all-employees.model';
import { AuthService } from './auth.service';
import { EmployeeCertificationComponent } from '../certifications/employeecertification.component';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/Employee/', httpClient, errorService, authService);
  }

  saveEmployee(action: string, empoyeeObj: Employee): Observable<any> {
    return this.postRequest(action, JSON.stringify(empoyeeObj));
  }

  getEmployee(action: string, employeeId: number): Observable<any> {
    debugger;
    return this.customGetRequest<Employee>(action, employeeId.toString());
  }

  deleteEmployee(action: string, empoyeeObj: Employee): Observable<any> {
    return this.postRequest(action, JSON.stringify(empoyeeObj));
  }

  delEmployee(action: string, eId: number): Observable<any> {
    return this.deleteRequest(eId);
    //return this.postRequest(action, JSON.stringify(obj));
  }

  getAllEmployees(action: string, employeeId: number): Observable<Array<Employee>> {
    const params = new HttpParams()
      .set('employeeId', employeeId.toString());
    return this.customGetRequestWithQueryString<Array<Employee>>(action, params);
  }

  getAllEmployeesForCrew(action: string, crewId: number): Observable<any> {
    const params = new HttpParams()
      .set('crewId', crewId.toString());
    return this.customGetRequestWithQueryString<Array<Employee>>(action, params);
  }

  getAllEmployeesInstructor(action: string, typeId: number): Observable<any> {
    const params = new HttpParams()
      .set('typeId', typeId.toString());
    return this.customGetRequestWithQueryString<Array<Employee>>(action, params);
  }

  getAllEmployeesNew(action: string, firstName: string = '', lastName: string = '', ffnNumber: string = '', isActive: boolean=false, pageNumber: number = 0, pageSize: number = 15): Observable<GetAllEmployees> {
    const params = new HttpParams()
      .set('firstName', firstName)
      .set('lastName', lastName)
      .set('ffnNumber', ffnNumber)
      .set('isActive', (isActive)?"True":"False")
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.customGetRequestWithQueryString<GetAllEmployees>(action, params);
  }

  getAllEmployeesForEmergencyReportNew(action: string, pageNumber: number = 0, pageSize: number = 15): Observable<GetAllEmployees> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    return this.customGetRequestWithQueryString<GetAllEmployees>(action, params);
  }

  getMyPorfile(action: string, employeeId: number): Observable<any> {
    return this.customGetRequest<Employee>(action, employeeId.toString());
  }

  getEmployeeId(action: string, userId: number): Observable<any> {
    return this.customGetRequest<Employee>(action, userId.toString());
  }

  updateProfile(action: string, employeeProfileModel: Employee): Observable<any> {
    return this.postRequest(action, JSON.stringify(employeeProfileModel));
  }

  employeeSearch(action: string, employeeObj: any): Observable<Array<Employee>> {
    const params = new HttpParams()
      .set('firstName', employeeObj.firstName)
      .set('lastName', employeeObj.lastName)
      .set('ffnNumber', employeeObj.ffnNumber);
    return this.customGetRequestWithQueryString<Array<Employee>>(action, params);
  }

  loadCertificates(action: string): Observable<Array<string>> {
    return this.customGetRequest<Array<string>>(action, null);
  }

  loadCertificatesIds(action: string): Observable<Array<Certification>> {
    return this.customGetRequest<Array<Certification>>(action, null);
  }

  ////------to get employee names-------------
  //loadEmployeeNames(action: string): Observable<Array<string>> {
  //  return this.customGetRequest<Array<string>>(action, null);
  //}
  ////------to get employee emailid-------------
  //loadEmail(action: string, lname: string): Observable<any> {
  //  return this.customGetRequest<Array<Employee>>(action, lname);
  //}

  updateEmployee(employeeId: number, employeeObj: Employee): Observable<any> {
    return this.putRequest(employeeId, JSON.stringify(employeeObj));
  }

  searchCertification(action: string, certificationName: string, certificationNumber: string, stDate: Date, edDate: Date, employeename: string, ffnumber: string, kemisnumber: string): Observable<Array<Certification>> {
    var sDate = "";
    if (stDate != undefined && stDate != null) {
      sDate = stDate.toDateString();
    }
    var eDate = "";
    if (edDate != undefined && edDate != null) {
      eDate = edDate.toDateString();
    }
    const params = new HttpParams()
      .set('certificateName', certificationName)
      .set('certificationNumber', certificationNumber)
      .set('stDate', sDate)
      .set('edDate', eDate)
      .set('employeename', employeename)
      .set('ffnNumber', ffnumber)
      .set('kemisNumber', kemisnumber);
    return this.customGetRequestWithQueryString<Array<Certification>>(action, params);
  }

  saveCertifications(action: string, certifications: Certification[]): Observable<any> {
    return this.postRequest(action, JSON.stringify(certifications));
  }

  getEmployees(action: string, shiftdate: string): Observable<any> {
    let params = new HttpParams()
      .set('shiftdate', shiftdate)
    return this.customGetRequestWithQueryString<Array<Employee>>(action, params);
  }

  getEmployeesBetweenDates(action: string, startDate: string, endDate: string): Observable<any> {
    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
    return this.customGetRequestWithQueryString<Array<Employee>>(action, params);
  }
  getCertificationData(action: string, certificationdataId: number): Observable<any> {
    debugger;
    const params = new HttpParams()
      .set('certificationdataId', certificationdataId.toString());
    return this.customGetRequestWithQueryString<Certification>(action, params);
  }

  updateCertificationData(action: string, certificationModel: Certification): Observable<any> {
    return this.postRequest(action, JSON.stringify(certificationModel));
  }

  getCertificationName(action: string, certificationId: number): Observable<any> {
    return this.customGetRequest<Certification>(action, certificationId.toString());
  }
}
