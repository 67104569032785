import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ErrorService } from './error.service';
import { EventDpt } from '../models/eventdpt.model';
import { GetAllEventDpt } from '../models/get-all-eventDpt.model';
import { EventCalendar } from '../models/eventCalendar.model';
import { EventCalendarDisplay } from '../models/eventcalendardisplay.model';
import { GetAllEvent } from '../models/get-all-event.model';
import { AuthService } from './auth.service';
import { GetAllEventDisplay } from '../models/get-all-eventdisplay.model';

@Injectable({ providedIn: 'root' })
export class EventService extends BaseService {
  baseUrl: string;

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/Event/', httpClient, errorService, authService);
  }

  updateEventDpt(eventId: number, eventModel: EventCalendar): Observable<any> {
    return this.putRequest(eventId, JSON.stringify(eventModel));
  }

  saveEventDpt(action: string, eventObj: EventCalendar): Observable<any> {
    return this.postRequest(action, JSON.stringify(eventObj));
  }

  getEventsForCalendar(): Observable<Array<EventCalendarDisplay>> {
    return this.customGetRequest<Array<EventCalendarDisplay>>('getEventsForCalendar', null);
  }

  deleteEntireEvent(action: string, eventCalendarId: number): Observable<any> {
    return this.deleteRequest(eventCalendarId);
    // return this.postRequest(action, JSON.stringify(eventObj));
  }

  deleteEvent(action: string, obj: string) {
    let params = new HttpParams()
      .set('query', obj.toString())
    return this.customPostRequestWithParams(action, null, params);
  }

  getEvent(action: string, eventId: number): Observable<any> {
    return this.customGetRequest<EventCalendar>(action, eventId.toString());
  }

  getAllEvent(): Observable<Array<EventCalendar>> {
    return this.customGetRequest<Array<EventCalendar>>('getAllEvent', null);
  }

  getAllEventDptNew(action: string, fromDate: Date = null, toDate: Date = null, eventType: number = 0, pageNumber: number = 0, pageSize: number = 10): Observable<GetAllEventDisplay> {
    let params = new HttpParams()
      .set('eventType', eventType.toString())
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (fromDate != null) {
      params = params.set('fromDate', fromDate.toDateString());
    }
    if (toDate != null) {
      params = params.set('toDate', toDate.toDateString());
    }
    return this.customGetRequestWithQueryString<GetAllEventDisplay>(action, params);
  }
}
