import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CompanyTraining } from '../models/companytraining.model';
import { CompanyTrainingService } from '../services/companytraining.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RolePermissionCheck } from '../models/role-permission-check.model';
@Component({
  selector: 'emscompanytrainingprint',
  templateUrl: './companytrainingprint.component.html'
})
export class companytrainingPrintComponent {
  emstrainingObj: CompanyTraining;
  emsTrainingId: number = 0;
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private emsService: CompanyTrainingService, private route: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.emsTrainingId = parseInt(Id);
        }
      });
    }
  }

  ngOnInit() {
    //if (this.emsTrainingId > 0) {
    //  this.emsService.getEmstraining('GetEmsTraining', this.emsTrainingId).subscribe(res => {
    //    this.emstrainingObj = res;
    //  });
    //}
  }
}
