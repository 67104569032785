import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { CompanyTraining } from '../models/companytraining.model';
import { CompanyTrainingService } from '../services/companytraining.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';


@Component({
  selector: 'companytrainingupdate',
  templateUrl: './companytrainingupdate.component.html',
})

export class CompanyTrainingUpdateComponent {
  @ViewChild('eventDptForm', { static: false }) eventTypeForm: NgForm;
  companytrainingObj: CompanyTraining[] = [];
  companyTrainingId: number = 1;
  ctobjs: CompanyTraining[] = [];
  shifts: SystemCode[] = [];
  stations: SystemCode[] = [];
  instructors: SystemCode[] = [];
  courseCodes: SystemCode[] = [];
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  isVisible: boolean = false;
  displayedColumns: string[] = ['startDate', 'instructor', 'numberOfStudents'];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private companytrainingservice: CompanyTrainingService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.companyTrainingId = parseInt(Id);
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      this.companytrainingservice.getCompanyTraining('getCompanyTraining', this.companyTrainingId).subscribe(res => {
        this.companytrainingObj = res || [];
        //this.employeeAwards = res.employeeAward;
      });
      //this.companytrainingservice.getAllCompanyTraining().subscribe(
      //  (res: Array<companytraining>) => {
      //    this.ctobjs = res || [];

      //  }, (error: any) => {
      //  });
    }
  }


  visible() {
    this.isVisible = true;
  }

  public loadDropDowns() {
    this.systemCodeService.getAllShifts().subscribe(
      (res: Array<SystemCode>) => {
        this.shifts = res || [];

      }, (error: any) => {
      });

    this.systemCodeService.getAllStations().subscribe(
      (res: Array<SystemCode>) => {
        this.stations = res || [];

      }, (error: any) => { }
    );

    this.systemCodeService.getAllInstructors().subscribe(
      (res: Array<SystemCode>) => {
        this.instructors = res || [];

      }, (error: any) => { }
    );

    this.systemCodeService.getAllCourseCode('1').subscribe(
      (res: Array<SystemCode>) => {
        this.courseCodes = res || [];

      }, (error: any) => { }
    );

  }

  GoBack() {
    this.routerService.navigate(['/companytrainingreport']);
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
