import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HeaderService } from './header.service';
import { RolePermissionCheck } from '../models/role-permission-check.model';

const helper = new JwtHelperService();

@Injectable()
export class AuthService {
  rolePermissionCheckModel: RolePermissionCheck = null;
  formPermissionId: number = 0;
  formPermissions: Array<{ permissionFormId: number, formAction: string, formName: string }> = [];
  userRoles: Array<{ userRoleId: number, roleId: number, roleName: string, userId: number }> = [];
  baseUrl: BehaviorSubject<string>;
  private user: BehaviorSubject<any>;
  private auth_token: BehaviorSubject<string>;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string, private router: Router, private headerService: HeaderService) {
    this.baseUrl = new BehaviorSubject<string>(baseUrl);
    this.user = new BehaviorSubject<any>(null);
    this.auth_token = new BehaviorSubject<string>(null);
  }

  isLoggedIn(): boolean {
    return !helper.isTokenExpired(this.auth_token.value);
  }

  getUser(): any {
    return this.user == null ? null : this.user.value;
  }

  getUserId(): any {
    return this.user == null ? null : this.user.value.id;
  }
  getUserName(): any {
    return this.user == null ? null : this.user.value.sub;
  }

  setRolePermissionCheckModel(rolePermissionForForm: RolePermissionCheck): void {
    this.rolePermissionCheckModel = rolePermissionForForm;
  }

  getRolePermissionCheckModel(): RolePermissionCheck {
    return this.rolePermissionCheckModel;
  }

  setFormPermissionId(formName: string): void {
    let formPermission = this.formPermissions.find(x => x.formName.toLowerCase() == formName.toLowerCase());
    if (formPermission) {
      this.formPermissionId = formPermission.permissionFormId;
    }
  }

  getFormPermissionId(): number {
    return this.formPermissionId;
  }

  getFormPermissions(): Array<{ permissionFormId: number, formName: string, formAction: string }> {
    return this.formPermissions;
  }

  getUserRoles(): Array<{ userRoleId: number, roleId: number, roleName: string, userId: number }> {
    return this.userRoles;
  }

  setFormPermissions(): void {
    let userFormPermissions = this.user.value.formpermissions;
    var permissionsArray: Array<string> = userFormPermissions.split(',');
    if (permissionsArray.length > 0) {
      permissionsArray.forEach(x => {
        let permissionArray = x.split('|');
        this.formPermissions.push({
          permissionFormId: +permissionArray[0],
          formAction: permissionArray[1],
          formName: permissionArray[2]
        });
      });
    }
  }

  setUserRolesForToken(): void {
    let userRolesToken = this.user.value.userroles;
    var userRolesArray: Array<string> = userRolesToken.split(',');
    if (userRolesArray.length > 0) {
      userRolesArray.forEach(x => {
        let userRolesSplit = x.split('|');
        this.userRoles.push({
          userRoleId: +userRolesSplit[0],
          roleId: +userRolesSplit[1],
          roleName: userRolesSplit[2],
          userId: +userRolesSplit[3],
        });
      });
    }
  }

  setUserToken(token: string) {
    this.auth_token = new BehaviorSubject<string>(null);
    this.auth_token.next(token);
    this.user = new BehaviorSubject<any>(null);
    this.user.next(helper.decodeToken(this.auth_token.value));
    this.setFormPermissions();
    this.setUserRolesForToken();
    this.headerService.visible = of(true);
    this.headerService.showHeader = of(false);
  }

  getAuthToken(): string {
    return this.auth_token == null ? null : (this.auth_token.value == null ? null : JSON.parse(this.auth_token.value).auth_token);
  }

  signOut() {
    this.formPermissionId = 0;
    this.formPermissions = [];
    this.rolePermissionCheckModel = null;
    this.auth_token = null;
    this.user = null;
    this.headerService.displayname = of('');
    this.headerService.visible = of(false);
    this.headerService.showHeader = of(true);
    this.router.navigate([`./login`]);
  }

  generateHeaders(): HttpHeaders {
    if (this.user == null || this.user.value == null || this.auth_token == null || this.auth_token.value == null) {
      this.signOut();
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .append('Authorization', 'Bearer ' + this.getAuthToken())
      .append('UserId', this.user.value.id)
      .append('PermissionFormId', this.formPermissionId.toString())
      .append('TimezoneOffset', (new Date().getTimezoneOffset()).toString());
    return headers;
  }

  generateHeaders_v2(): HttpHeaders {
    if (this.user == null || this.user.value == null || this.auth_token == null || this.auth_token.value == null) {
      this.signOut();
    }
    const headers = new HttpHeaders()//.set('Content-Type', 'multipart/form-data')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .append('Authorization', 'Bearer ' + this.getAuthToken())
      .append('UserId', this.user.value.id)
      .append('PermissionFormId', this.formPermissionId.toString())
      .append('TimezoneOffset', (new Date().getTimezoneOffset()).toString());
    return headers;
  }

  getAuthorizationHeaderValue(): string {
    return 'Bearer ' + this.getAuthToken();
  }

  getAuthorizationHeaderUserId(): string {
    return this.user.value.id;
  }

  getAuthorizationHeaderFormPermissionId(): string {
    return this.formPermissionId.toString();
  }

  getAuthorizationHeaderOffset(): string {
    return (new Date().getTimezoneOffset()).toString();
  }

  //generateHeaders_v2(): HttpHeaders {
  //  if (this.user == null || this.user.value == null || this.auth_token == null || this.auth_token.value == null) {
  //    this.signOut();
  //  }     
  //  const headers = new HttpHeaders().set('Content-Type', 'application/json')
  //    .set('Cache-Control', 'no-cache')
  //    .set('Pragma', 'no-cache')
  //    .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
  //    .append('Authorization', 'Bearer ' + this.getAuthToken())
  //    .append('UserId', this.user.value.id);
  //  return headers;    
  //}
}
