import { Component, Input, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DailyLog } from '../models/dailylog.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { DailyLogService } from '../services/dailylog.service';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { forkJoin, Observable, of } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { switchMap, map, startWith, catchError } from 'rxjs/operators';
import { GetAllDailyLogs } from '../models/get-all-dailyLogs.model';
import { DlEmsRun } from '../models/dlemsrun.model';
import { DlActivities } from '../models/dlactivities.model';
import { DlQuarters } from '../models/dlquarters.model';
import { DlApparatus } from '../models/dlapparatus.model';
import { DlFlaggedEmsRun } from '../models/dlflaggedemsrun.model';
import { DlFireRun } from '../models/dlfirerun.model';
import { EmsRunDialogContent } from '../common/model-dialog/emsRun-dialog-content';
import { FireRunDialogContent } from '../common/model-dialog/fireRun-dialog-content';
import { ApparatusDialogContent } from '../common/model-dialog/apparatus-dialog-content';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DailyLogReport } from '../models/daily-log-report.model';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import autoTable, { UserOptions } from 'jspdf-autotable'

@Component({
  selector: 'dailylogreport',
  templateUrl: './dailylogreport.component.html',
  styleUrls: ['./dailylogreport.component.css']
})
export class DailyLogReportComponent implements OnInit {
  @ViewChild('dailyLogReportForm', { static: false }) dailyLogReportForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<DlEmsRun>>;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;

  name = 'ng2-ckeditor';
  ckeditorContent: string = 'Description';
  floatLabel: string = 'never';
  log: string = '';
  ckeConfig: any;
  subject: string;
  departmentUpdateId: number;
  x = new Date();
  errMsg: string;
  showNewButton: boolean = false;
  isFound: boolean = false;
  isLoadingResults: boolean = false;
  resultsLength: number = 0;
  dailyLogsModel: DailyLog;
  dailyLogsResultModel: DailyLog[] = [];
  @BlockUI() blockUI: NgBlockUI;
  crewCode: SystemCode[] = [];
  stationCode: SystemCode[] = [];
  stationKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  dataSource: MatTableDataSource<DailyLog>;
  dailylogModelsDB = new MatTableDataSource<DailyLog>([]);
  paginatorid: MatPaginator;
  sortid: MatSort;
  createdOn: FormControl;
  dailylogModels: DailyLog[] = [];
  dailyLogModel: DailyLog;
  dailylogReportModel: DailyLog;
  shiftDate: Date;
  dlFireRunModel: DlFireRun[] = [];
  dlEmsRunModel: DlEmsRun[] = [];
  dlApparatusModel: DlApparatus[] = [];
  emsString1: string[] = [];
  emsString2: string[] = [];
  emsList: string[] = [];
  fireString1: string[] = [];
  fireString2: string[] = [];
  fireList: string[] = [];
  apparatusString1: string[] = [];
  apparatusString2: string[] = [];
  apparatusList: string[] = [];
  fireListModel: StringArray[];
  popupdisable: boolean = true;
  EmsRunTotal: number;
  fromDate: any;
  showEndDateField: boolean = false;
  //fireList: string[];
  // fireList: StringArray[] = [];
  //displayedColumns: any[] = ['shiftDate', 'crew', 'station', 'totalMedAssists', 'totalFirstResponses', 'dlEmsRuns', 'dlFireRuns', 'dlApparatuses', 'dailyLogId', 'delete'];
  displayedColumns: any[] = ['shiftDate', 'crew', 'station', 'totalMedAssists', 'totalFirstResponses', 'dlEmsRuns', 'dlFireRuns', 'dlApparatuses'];
  columnsToDisplay = ['shiftDate', 'crew', 'station', 'totalMedAssists', 'totalFirstResponses', 'dailyLogId'];
  innerDisplayedColumns = ['emsRunNumber', 'emsRunAddress', 'dailyLogId'];
  expandedElement: DailyLog | null;
  rolePermissionCheckModel: RolePermissionCheck;
  dailyLogs: Array<DailyLogReport> = [];

  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private dailyLogService: DailyLogService, private cd: ChangeDetectorRef
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.loadDropDowns();
      this.dailyLogModel = this.initializedailyLogModelModel();
      this.dailylogReportModel = this.initializedailyLogModelModel();
      this.dataSource = new MatTableDataSource(this.dailyLogsResultModel);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      //this.dlFireRunModel = this.initializeFireRunModel();
      //this.dlEmsRunModel = this.initializeEmsRunModel();

      const sortState: Sort = { active: 'shiftDate', direction: 'desc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('dailyLogId');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    
    //if (this.rolePermissionCheckModel.isUpdate) {
    //  //this.displayedColumns.push('edit');
    //  //this.columnsToDisplay.push('edit');
    //  //this.innerDisplayedColumns.push('edit');
    //}

    //if (this.rolePermissionCheckModel.isDelete) {
    //  this.displayedColumns.push('delete');
    //  //this.columnsToDisplay.push('delete');
    //  //this.innerDisplayedColumns.push('delete');
    //}
  }

  grouping() {
    this.dailyLogsResultModel.forEach(emsRun => {
      if (emsRun.dlEmsRuns && Array.isArray(emsRun.dlEmsRuns) && emsRun.dlEmsRuns.length) {
        this.dailylogModels = [...this.dailylogModels, { ...emsRun, dlEmsRuns: new MatTableDataSource(emsRun.dlEmsRuns) }];
      } else {
        this.dailylogModels = [...this.dailylogModels, emsRun];
      }
    });
    this.dataSource = new MatTableDataSource(this.dailylogModels);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  toggleRow(element: DailyLog) {
    element.dlEmsRuns && (element.dlEmsRuns as MatTableDataSource<DlEmsRun>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<DlEmsRun>).sort = this.innerSort.toArray()[index]);
  }

  /*********************************** */

  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.blockUI.start();
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.getAllDailyLogs(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.dailyLogsResultModel = data || [];
          //this.grouping();
          this.dataSource = new MatTableDataSource(this.dailyLogsResultModel);
          this.dataSource.sort = this.sort;
          this.blockUI.stop();
        });
    }
  }

  //getAllDailyLogs(pageIndex: number, pageSize: number): Observable<GetAllDailyLogs> {
  //  return this.dailyLogService.getAllDailyLogNew(
  //    'GetDailyLogNew',
  //    (this.dailylogReportModel.shiftDate != null) ? this.dailylogReportModel.shiftDate : null,
  //    (this.dailylogReportModel.crew) ? this.dailylogReportModel.crew : 0,
  //    (this.dailylogReportModel.station) ? this.dailylogReportModel.station : 0,
  //    pageIndex,
  //    pageSize
  //  );
  //}
  getAllDailyLogs(pageIndex: number, pageSize: number): Observable<GetAllDailyLogs> {
    return this.dailyLogService.getAllDailyLogNew(
      'GetDailyLogNew',
      (this.dailylogReportModel.crew != undefined) ? this.dailylogReportModel.crew : 0,
      (this.dailylogReportModel.station != undefined) ? this.dailylogReportModel.station : 0,
      (this.dailylogReportModel.startDate) ? this.dailylogReportModel.startDate : null,
      (this.dailylogReportModel.endDate) ? this.dailylogReportModel.endDate : null,
      pageIndex,
      pageSize
    );
  }

  getAllDailyLogsForDataSource(): void {
    this.getAllDailyLogs(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.dailyLogsResultModel = data || [];
        this.dailylogModelsDB.data = data || [];
        this.dataSource = new MatTableDataSource(this.dailyLogsResultModel);
        this.dataSource.sort = this.sort;
        this.blockUI.stop();
      });
  }

  /*************/ 

  startdateevent(startDate: Date) {
    this.fromDate = startDate;
    this.showEndDateField = true;
    if (startDate == null) {
      this.showEndDateField = false;
      this.dailylogReportModel.endDate = null;
    }
  }





  /*********************************** */

  initializedailyLogModelModel(): DailyLog {
    return {
      dailyLogId: 0, shiftDate: null, station: null, crew: null, totalMedAssists: null, totalFirstResponses: null,
      dlActivities: null, dlApparatuses: null, dlFlaggedEmsRuns: null, dlQuarters: null, dlFireRuns: null, dlEmsRuns: null, stationName: null, crewName: null, startDate: null, endDate: null
    } as DailyLog;
  }

  initializeEmsRunModel(): DlEmsRun {
    return {
      emsRunId: 0, emsRunNumber: null, emsRunAddress: "", isActive: true, dailyLogId: 0
    } as DlEmsRun;
  }

  initializeFireRunModel(): DlFireRun {
    return {
      fireRunId: 0, fireRunNumber: null, fireRunAddress: "", isActive: true, dailyLogId: 0
    } as DlFireRun;
  }


  editDailyLog(id: number) {
    this.routerService.navigate(['dailylog', { id }]);
  }

  resetDate1() {
    this.dailylogReportModel.shiftDate = null;
  }

  public searchDailyLog() {
    this.blockUI.start();
    this.showNewButton = true;
    //if (!((this.dailylogReportModel.shiftDate == null) && (this.dailylogReportModel.crew == null || this.dailylogReportModel.crew == 0) && (this.dailylogReportModel.station == null || this.dailylogReportModel.station == 0))) {
    //  this.showNewButton = true;
    //} else {
    //  return;
    //}
    this.getAllDailyLogsForDataSource();
  }

  deleteDailyLog(Id: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.blockUI.start();
        this.dailyLogService.delDailyLog('delDailyLog', Id).subscribe(res => {
          this.errMsg = res.msg;
          this.getAllDailyLogsForDataSource();
          if (this.errMsg == "Deleted Successfully.") {
            const dialogRef = this.dialog.open(SaveDialogComponent, {
              width: '500px',
              height: '200px',
              data: { name: "Deleted Successfully!" }
            });
          }
        });
      }
    });
    return false;
  }

  //getEmsRunString(EmsRunModel: DlEmsRun[]) {
  //  this.dlEmsRunModel = EmsRunModel;
  //  if (this.dlEmsRunModel.length != 0) {
  //    for (let i = 0; i < this.dlEmsRunModel.length; i++) {
  //      if (this.dlEmsRunModel[i].emsRunAddress != '') {
  //        this.emsString1 = this.dlEmsRunModel[i].emsRunAddress.toString();
  //      } else { this.emsString1="N/A"}
  //      if (this.dlEmsRunModel[i].emsRunNumber != null) {
  //        this.emsString2 = this.dlEmsRunModel[i].emsRunNumber.toString();
  //      } else { this.emsString2 = "N/A" }
  //      this.emsString1 = this.emsString1.concat(",",this.emsString2);
  //    }
  //    return this.emsString1;
  //  }
  //}

  /***********************************/

  getEmsRunTotal(EmsRun: DlEmsRun[]) {
    this.dlEmsRunModel = EmsRun;
    if (this.dlEmsRunModel.length >= 1 && (this.dlEmsRunModel[0].emsRunAddress != '' || this.dlEmsRunModel[0].emsRunNumber != null)) {
      this.popupdisable = true;
      this.EmsRunTotal = this.dlEmsRunModel.length;
      return this.dlEmsRunModel.length;
    } else if (this.dlEmsRunModel.length == 1 && this.dlEmsRunModel[0].emsRunAddress == '' && this.dlEmsRunModel[0].emsRunNumber == null) {
      this.popupdisable = false;
      this.EmsRunTotal = null;
      //return null
      return "N/A"
    }
  }

  openEmsRunPopup(dailyLogId: number) {
    this.emsList = [];
    this.dailyLogService.getDailyLog('getDailyLog', dailyLogId).subscribe(res => {
      // this.dlEmsRun = res.dlEmsRuns;
      this.dlEmsRunModel = res.dlEmsRuns;
      if (this.dlEmsRunModel.length != 0) {
        for (let i = 0; i < this.dlEmsRunModel.length; i++) {
          if (this.dlEmsRunModel[i].emsRunAddress != '') {
            this.emsString1[i] = this.dlEmsRunModel[i].emsRunAddress.toString();
          } else {
            this.emsString1[i] = "N/A"
          }
          if (this.dlEmsRunModel[i].emsRunNumber != null) {
            this.emsString2[i] = this.dlEmsRunModel[i].emsRunNumber.toString();
          } else {
            this.emsString2[i] = "N/A"
          }
          this.emsString2[i] = this.emsString2[i].concat('\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0', " - ", '\xa0\xa0\xa0\xa0\xa0\xa0\xa0', this.emsString1[i]);
          this.emsList.push(this.emsString2[i]);
        }
      }
      this.openEmsRunDialog(this.emsList);
    });
  }

  openEmsRunDialog(nameList: any) {
    const dialogRef = this.dialog.open(EmsRunDialogContent, {
      data: {
        names: nameList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /***********************************/

  getFireRunTotal(FireRun: DlFireRun[]) {
    this.dlFireRunModel = FireRun;
    if (this.dlFireRunModel.length >= 1 && (this.dlFireRunModel[0].fireRunAddress != '' || this.dlFireRunModel[0].fireRunNumber != null)) {
      //this.popupdisable = false;
      return this.dlFireRunModel.length;
    } else if (this.dlFireRunModel.length == 1 && this.dlFireRunModel[0].fireRunAddress == '' && this.dlFireRunModel[0].fireRunNumber == null) {
      //this.popupdisable = true;
      //return null
      return "N/A"
    }
  }

  openFireRunPopup(dailyLogId: number) {
    this.fireList = [];
    this.dailyLogService.getDailyLog('getDailyLog', dailyLogId).subscribe(res => {
      // this.dlEmsRun = res.dlEmsRuns;
      this.dlFireRunModel = res.dlFireRuns;
      if (this.dlFireRunModel.length != 0) {
        for (let i = 0; i < this.dlFireRunModel.length; i++) {
          if (this.dlFireRunModel[i].fireRunAddress != '') {
            this.fireString1[i] = this.dlFireRunModel[i].fireRunAddress.toString();
          } else {
            this.fireString1[i] = "N/A"
          }
          if (this.dlFireRunModel[i].fireRunNumber != null) {
            this.fireString2[i] = this.dlFireRunModel[i].fireRunNumber.toString();
          } else {
            this.fireString2[i] = "N/A"
          }
          this.fireString2[i] = this.fireString2[i].concat('\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0', " - ", '\xa0\xa0\xa0\xa0\xa0\xa0\xa0', this.fireString1[i]);
          this.fireList.push(this.fireString2[i]);
        }
      }
      this.openFireRunDialog(this.fireList);
    });
  }

  openFireRunDialog(nameList: any) {
    const dialogRef = this.dialog.open(FireRunDialogContent, {
      data: {
        names: nameList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /***********************************/

  /***********************************/

  getApparatusTotal(Apparatus: DlApparatus[]) {
    this.dlApparatusModel = Apparatus;
    if (this.dlApparatusModel.length >= 1 && (this.dlApparatusModel[0].apparatus != "No Details found on Apparatus")) {
      //this.popupdisable = false;
      return this.dlApparatusModel.length;
    } else if (this.dlApparatusModel.length == 1 && this.dlApparatusModel[0].apparatus == "No Details found on Apparatus") {
      //this.popupdisable = true;
      //return null
      return "N/A"
    }
  }

  openApparatusPopup(dailyLogId: number) {
    this.apparatusList = [];
    this.dailyLogService.getDailyLog('getDailyLog', dailyLogId).subscribe(res => {
      // this.dlEms = res.dlEmss;
      this.dlApparatusModel = res.dlApparatuses;
      if (this.dlApparatusModel.length != 0) {
        for (let i = 0; i < this.dlApparatusModel.length; i++) {
          if (this.dlApparatusModel[i].apparatus != "No Details found on Apparatus") {
            this.apparatusString1[i] = this.dlApparatusModel[i].apparatus.toString();
          } else {
            this.apparatusString1[i] = "N/A"
          }

          
          for (let l = 0; l < l+1; l++) {
            if (this.apparatusString1[i].includes("<br />")) {
              this.apparatusString1[i] = this.apparatusString1[i].replace(/<br ?\/?>/g, "\xa0\xa0\xa0 & \xa0\xa0\xa0 ");
            } else {
              break;
            }
          }
          for (let m = 0; m < m + 1; m++) {
            if (this.apparatusString1[i].includes("<p>")) {
              this.apparatusString1[i] = this.apparatusString1[i].replace("<p>", " ");
            } else {
              break;
            }
          }
          for (let n = 0; n < n + 1; n++) {
            if (this.apparatusString1[i].includes("</p>")) {
              this.apparatusString1[i] = this.apparatusString1[i].replace("</p>", " ");
            } else {
              break;
            }
          }
          for (let p = 0; p < p + 1; p++) {
            if (this.apparatusString1[i].includes("&nbsp;")) {
              this.apparatusString1[i] = this.apparatusString1[i].replace("&nbsp;", " ");
            } else {
              break;
            }
          }
          
          
          //this.apparatusString1[i] = this.apparatusString1[i].replace("<p>", " ");
          //this.apparatusString1[i] = this.apparatusString1[i].replace("</p>", " ");
          //this.apparatusString1[i] = this.apparatusString1[i].replace("&nbsp;", " ");
          
          //this.apparatusString1[i] = this.apparatusString1[i].replace("<br />", " ");
          //for (let l = 0; l < 10; l++) {
          //  this.apparatusString1[i] = this.apparatusString1[i].replace(/<br ?\/?>/g, "\xa0\xa0\xa0 & \xa0\xa0\xa0 ");
          //  this.apparatusString1[i] = this.apparatusString1[i].replace("<p>", " ");
          //  this.apparatusString1[i] = this.apparatusString1[i].replace("</p>", " ");
          //  this.apparatusString1[i] = this.apparatusString1[i].replace("&nbsp;", " ");
          //}
          

          //var str = this.apparatusString1[i];
          //var mapObj = {
          //  "<p>": " ",
          //  "</p>": " ",
          //  "<br />": " "
          //};
          //str = str.replace(/"<p>"|"</p >"|" <br />"/gi, function (matched) {
          //  return mapObj[matched];
          //});
          this.apparatusList.push(this.apparatusString1[i]);
        }
      }
      this.openApparatusDialog(this.apparatusList);
    });
  }

  openApparatusDialog(nameList: any) {
    const dialogRef = this.dialog.open(ApparatusDialogContent, {
      data: {
        names: nameList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /***********************************/

  resetTheForm() {
    this.dailylogReportModel = this.initializedailyLogModelModel();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.getAllDailyLogsForDataSource();
    this.fromDate = new Date(2010, 0, 1);
    this.showEndDateField = false;
  }

  resetDailyLog() {
    this.showNewButton = false;
    this.resetTheForm();
  }

  AddNewLog() {
    this.routerService.navigate(['dailylog']);
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllStations(),
    ).subscribe(([res1, res2]) => {
      this.crewCode = res1 || [];
      this.stationCode = res2 || [];
      this.crewKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.stationKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  showDailyLogReport(): void {
    this.dailyLogService.getAllDailyLogsForReportView(
      'GetAllDailyLogsForReportView',
      (this.dailylogReportModel.crew) ? this.dailylogReportModel.crew : 0,
      (this.dailylogReportModel.station) ? this.dailylogReportModel.station : 0,
      (this.dailylogReportModel.startDate) ? this.dailylogReportModel.startDate : null,
      (this.dailylogReportModel.endDate) ? this.dailylogReportModel.endDate : null
    ).subscribe((res: Array<DailyLogReport>) => {
      this.dailyLogs = res;
      this.blockUI.stop();
      this.setPDF();
    }, (error: any) => {
      this.dailyLogs = [];
      this.blockUI.stop();
    });
  }
  

  setPDF(): void {
    let yAxis: number = 30;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setFont('Calibri');
    doc.setProperties({
      title: 'Daily Log Report'
    });

    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth('Daily Log Report') * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text('Daily Log Report', textOffset, 20)
    doc.setFontSize(10)

    //Shift Date Filter Code Begin
    //let startDate = 'Shift Date: ';
    //if (this.dailylogReportModel.shiftDate) {
    //  let startDateString = new Date(this.dailylogReportModel.shiftDate);
    //  let startDateFormat = this.getDateAsString(startDateString);
    //  startDate = startDate + startDateFormat;
    //}
    let startDate = 'Start Date: ';
    if (this.dailylogReportModel.startDate) {
      let startDateString = new Date(this.dailylogReportModel.startDate);
      let startDateFormat = this.getDateAsString(startDateString);
      startDate = startDate + startDateFormat;
    } 

    let endDate = 'End Date: ';
    if (this.dailylogReportModel.endDate) {
      let endDateString = new Date(this.dailylogReportModel.endDate);
      let endDateFormat = this.getDateAsString(endDateString);
      endDate = endDate + endDateFormat;
    }
    let dateLables = startDate + '   ' + endDate;
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(dateLables, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Shift Date Filter Code End

    //Crew and Station Filter Code Begin
    let crew = 'Crew: ';
    let crewEntity = this.crewCode.find(x => x.systemCodeId == this.dailylogReportModel.crew);
    if (crewEntity) {
      crew = crew + crewEntity.type;
    }
    let station = 'Station: ';
    let stationEntity = this.stationCode.find(x => x.systemCodeId == this.dailylogReportModel.station);
    if (stationEntity) {
      station = station + stationEntity.type;
    }
    let textCrewStation = crew + '   ' + station;
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(textCrewStation, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Crew and Station Filter Code End

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        crew: {
          cellWidth: 15
        },
        station: {
          cellWidth: 20
        },
        emsRuns: {
          cellWidth: 20
        },
        fireRuns: {
          cellWidth: 20
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 250, pageHeight - 10);

        let currentDate = new Date();
        let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
        let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
        let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
        doc.text(currentDateString, 15, pageHeight - 10);
      },
      startY: yAxis
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    window.open(URL.createObjectURL(doc.output("blob")));
  }

  getDateAsString(dt: Date): string {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  getHeadRows() {
    return [
      {
        shiftDate: 'Shift Date', crew: 'Crew', station: 'Station', emsRuns: 'EMS Runs', fireRuns: 'Fire Runs', apparatusVehicles: 'Apparatus/Vehicles'
      }
    ]
  }

  getBody() {
    let rowCount = this.dailyLogs.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.dailyLogs[j].shiftDate;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }

      let emsRunsConcact = '';
      let comma: string = '  ,  ';
      let comma1: string = '  ,  ';
      for (var i = 0; i < this.dailyLogs[j].dlEmsRuns.length; i++) {
        if (this.dailyLogs[j].dlEmsRuns[i].emsRunNumber == null) {
          this.dailyLogs[j].dlEmsRuns[i].emsRunNumber = '';
        }
        if (i == this.dailyLogs[j].dlEmsRuns.length-1) {
          comma = '';
        }
        if (this.dailyLogs[j].dlEmsRuns[i].emsRunAddress == '' && this.dailyLogs[j].dlEmsRuns[i].emsRunNumber == '') {          
          emsRunsConcact += ' '; 
        } 
        if (this.dailyLogs[j].dlEmsRuns[i].emsRunNumber == '' && this.dailyLogs[j].dlEmsRuns[i].emsRunAddress != '') {         
          emsRunsConcact += this.dailyLogs[j].dlEmsRuns[i].emsRunAddress + comma+'\n '; 
        }
        if (this.dailyLogs[j].dlEmsRuns[i].emsRunAddress == '' && this.dailyLogs[j].dlEmsRuns[i].emsRunNumber != '') {        
          emsRunsConcact += this.dailyLogs[j].dlEmsRuns[i].emsRunNumber + comma+'\n ';
        }
        if (this.dailyLogs[j].dlEmsRuns[i].emsRunAddress != '' && this.dailyLogs[j].dlEmsRuns[i].emsRunNumber != '') {
          emsRunsConcact += this.dailyLogs[j].dlEmsRuns[i].emsRunNumber + ': ' + this.dailyLogs[j].dlEmsRuns[i].emsRunAddress + comma+'\n ';
        }        
      }

      if (emsRunsConcact.length > 0) {
        emsRunsConcact = emsRunsConcact.slice(0, emsRunsConcact.length - 2)
      }

      let fireRunsConcact = '';
      for (var i = 0; i < this.dailyLogs[j].dlFireRuns.length; i++) {
        if (this.dailyLogs[j].dlFireRuns[i].fireRunNumber==null) {
          this.dailyLogs[j].dlFireRuns[i].fireRunNumber = '';
        }
        if (i == this.dailyLogs[j].dlFireRuns.length - 1) {
          comma1 = '';
        }
        if (this.dailyLogs[j].dlFireRuns[i].fireRunAddress == '' && this.dailyLogs[j].dlFireRuns[i].fireRunNumber == '') {
          fireRunsConcact += ' '; 
        } /*else*/
        if (this.dailyLogs[j].dlFireRuns[i].fireRunNumber == '' && this.dailyLogs[j].dlFireRuns[i].fireRunAddress != '') {
          fireRunsConcact += this.dailyLogs[j].dlFireRuns[i].fireRunAddress + comma1+ '\n ';
        } 
        if (this.dailyLogs[j].dlFireRuns[i].fireRunAddress == '' && this.dailyLogs[j].dlFireRuns[i].fireRunNumber != '') {
          fireRunsConcact += this.dailyLogs[j].dlFireRuns[i].fireRunNumber + comma1+'\n ';
        }
        if (this.dailyLogs[j].dlFireRuns[i].fireRunAddress != '' && this.dailyLogs[j].dlFireRuns[i].fireRunNumber != '') {
          fireRunsConcact += this.dailyLogs[j].dlFireRuns[i].fireRunNumber + ': ' + this.dailyLogs[j].dlFireRuns[i].fireRunAddress + comma1+'\n ';
        }  

       // fireRunsConcact += this.dailyLogs[j].dlFireRuns[i].fireRunNumber + ': ' + this.dailyLogs[j].dlFireRuns[i].fireRunAddress + ',\n ';
      }
      if (fireRunsConcact.length > 0) {
        fireRunsConcact = fireRunsConcact.slice(0, fireRunsConcact.length - 2)
      } 

      let apparatusVehcilesConcact = ''
      for (var i = 0; i < this.dailyLogs[j].dlApparatuses.length; i++) {
        let count = i + 1;
        let dot = '. ';
        if (this.dailyLogs[j].dlApparatuses[i].apparatus.includes("Type") && this.dailyLogs[j].dlApparatuses[i].apparatus.includes("Action Requested") && this.dailyLogs[j].dlApparatuses.length > 1) {
          apparatusVehcilesConcact += count + dot + this.dailyLogs[j].dlApparatuses[i].apparatus;
        } else {
          apparatusVehcilesConcact += this.dailyLogs[j].dlApparatuses[i].apparatus;
        }
        

        //apparatusVehcilesConcact = apparatusVehcilesConcact.replace(/<br ?\/?>/g, "\xa0\xa0\xa0 & \xa0\xa0\xa0 ");        
        //apparatusVehcilesConcact = apparatusVehcilesConcact.replace("<p>", " ");
        //apparatusVehcilesConcact = apparatusVehcilesConcact.replace("</p>", " ");
        //apparatusVehcilesConcact = apparatusVehcilesConcact.replace("&nbsp;", " ");
        for (let l = 0; l < l + 1; l++) {
          if (apparatusVehcilesConcact.includes("<br />")) {
            apparatusVehcilesConcact = apparatusVehcilesConcact.replace(/<br ?\/?>/g, "\xa0\xa0\xa0 & \xa0\xa0\xa0 ");
          } else {
            break;
          }
        }
        for (let m = 0; m < m + 1; m++) {
          if (apparatusVehcilesConcact.includes("<p>")) {
            apparatusVehcilesConcact = apparatusVehcilesConcact.replace("<p>", " ");
          } else {
            break;
          }
        }
        for (let n = 0; n < n + 1; n++) {
          if (apparatusVehcilesConcact.includes("</p>")) {
            apparatusVehcilesConcact = apparatusVehcilesConcact.replace("</p>", " ");
          } else {
            break;
          }
        }
        for (let p = 0; p < p + 1; p++) {
          if (apparatusVehcilesConcact.includes("&nbsp;")) {
            apparatusVehcilesConcact = apparatusVehcilesConcact.replace("&nbsp;", " ");
          } else {
            break;
          }
        }
       
        apparatusVehcilesConcact = apparatusVehcilesConcact + '\n';
        //apparatusVehcilesConcact = count+'.'+ apparatusVehcilesConcact + '\n' ;
        //if (this.dailyLogs[j].dlApparatuses.length > 1) {
        //  //apparatusVehcilesConcact = apparatusVehcilesConcact.concat(count.toString(), '.', apparatusVehcilesConcact, '\n');
        //  let sno: string = count.toString().concat('.');
        //  apparatusVehcilesConcact = sno + apparatusVehcilesConcact + '\n';
        //} else {
        //  apparatusVehcilesConcact = apparatusVehcilesConcact + '\n';
        //}
        
      }

      body.push({
        shiftDate: actionDateStringFormat,
        crew: this.dailyLogs[j].crewName,
        station: this.dailyLogs[j].stationName,
        emsRuns: emsRunsConcact,
        fireRuns: fireRunsConcact,
        apparatusVehicles: apparatusVehcilesConcact
      });
    }
    return body;
  }
}

export interface DailyLogDataSource {
  dailyLogId: number;
  shiftDate: Date;
  station: number;
  crew: number;
  totalMedAssists: number;
  totalFirstResponses: number;
  dlActivities: DlActivities[];
  dlApparatuses: DlApparatus[];
  dlFlaggedEmsRuns: DlFlaggedEmsRun[];
  dlQuarters: DlQuarters[];
  dlFireRuns: DlFireRun[];
  dlEmsRuns?: DlEmsRun[] | MatTableDataSource<DlEmsRun>;
}

export interface StringArray {
  FireRunString: string;
}
