import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DailyRoasterService } from '../services/dailyroaster.service';
import { AuthService } from '../services/auth.service';
import { DailyRoasterAssignment } from '../models/dailyroasterassignment.model';
import { StRoster } from '../models/stroster.model';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { switchMap, map, startWith, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GetAllStRoster } from '../models/get-all-stRoster.model';
import { StRosterService } from '../services/stroster.service';
import * as jspdf from 'jspdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; 


@Component(
  {
    selector: 'roasterreportview',
    templateUrl: './roasterreportview.component.html'   
  })
export class RoasterReportView implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() stRosterModels: StRoster;
  @Output() toggleView: EventEmitter<void> = new EventEmitter<void>();
  @BlockUI() blockUI: NgBlockUI;

  StRosterData: StRoster[] = [];
  dailyRoasterObj: DailyRoasterAssignment[] = [];
  dataSource: MatTableDataSource<StRoster>;
  shiftDt: string = "";
  cId: number = 0;
  roasterId: string = null;
  displayId: string = null;
  totalHours: number = 0;
  allMins: number = 0;
  roasterTotal: number = 0;
  resultsLength: number = 0;
  cName: string = "";
  constructor( private dailyRoasterService: DailyRoasterService,
    private authService: AuthService, private route: ActivatedRoute, private routerService: Router, private stRosterService: StRosterService,) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let shiftdate = params['shiftdt']
      let cid = params['cid']
      let cname = params['cname']
      this.shiftDt = shiftdate;
      this.cId = cid;
      this.cName = cname;
      if (cid == null) {
        cid = 0;
      }
      this.dailyRoasterService.getDailyRoasterView("getDailyRoasterView", cid, shiftdate).subscribe(res => {
        this.dailyRoasterObj = res;
      });
    });
    this.dataSource = new MatTableDataSource(this.StRosterData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  toggleViewOnClick() {
    //this.routerService.navigate(['/dailyroaster']);
    this.toggleView.emit();
  }

  ngAfterViewInit() {
   // if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //this.blockUI.start();
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.getAllStRoster(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            // this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
         // this.STROSTERS = data || [];
          this.StRosterData = data || [];
          ///this.dailyRoasterObj.push(this.StRosterData[4].rosterAssignments)
          this.dataSource = new MatTableDataSource(this.StRosterData);
          this.dataSource.sort = this.sort;
         // this.GridSortAndPage();
          //this.blockUI.stop();
        });
    //}
  }

  getAllStRoster(pageIndex: number, pageSize: number): Observable<GetAllStRoster> {
    return this.stRosterService.getAllStRosterNew(
      'GetStRosterNew',
      (this.stRosterModels.shiftDate != null) ? this.stRosterModels.shiftDate : null,
      (this.stRosterModels.personnel) ? this.stRosterModels.personnel : 0,
      //  (this.stRosterModels.station) ? this.stRosterModels.station : 0,
      pageIndex,
      pageSize
    );
  }

  getAllStRosterForDataSource(): void {
    this.getAllStRoster(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          // this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
       // this.STROSTERS = data || [];
        this.StRosterData = data || [];
        this.dataSource = new MatTableDataSource(this.StRosterData);
        this.dataSource.sort = this.sort;
        //this.blockUI.stop();
      });
  }

  downloadInPdf() {
    //var getData = localStorage.getItem('eventdptModel');
    //if (getData != undefined && getData != null) {
    //  //this.blockUI.start();
    //  this.eventdptModel = JSON.parse(getData);
    if (this.StRosterData != undefined && this.StRosterData != null) {
      if (this.StRosterData.length > 0) {
        var data = document.getElementById('content');
        html2canvas(data).then(canvas => {
          //document.body.appendChild(canvas);
          // Few necessary setting options 
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm');
          var position = 0; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight; // top padding for other pages
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          doc.save('file.pdf'); // Generated PDF
          this.blockUI.stop();
          setTimeout(function () {
            self.close();
          }, 1500);
        });
      }
    }
    else {
      this.blockUI.stop();
    }
    return false;
  }

  checkFirst(rosterId: string,index:number) {

    let mins = parseInt(this.dailyRoasterObj[index].hours);
    this.allMins = this.allMins + mins;
    this.roasterTotal = this.roasterTotal + mins;
    if (this.roasterId != rosterId) {        
      this.roasterId = rosterId;
     
      return true;
    }
    else {
      //this.allMins = 0;
      return false;
    }

  }
  totalDisplay(index: number) {
    let count = this.dailyRoasterObj.length;

    if (index < count-1) {
      if (this.dailyRoasterObj[index].roasterId != this.dailyRoasterObj[index + 1].roasterId) {
        
       // this.roasterTotal = parseInt(this.dailyRoasterObj[index].hours);
        return true;
      }
      else {
       // this.roasterTotal = 0;
      }
    }
    if (index == count-1) {
      return true;
    }
    return false;
    //this.dailyRoasterObj[index].roasterId
  }
  finalyDisplay(index) {
    let count = this.dailyRoasterObj.length;
    if (index == count - 1) {
      return true;
    }
    return false;
  }
  setRoasterTotalToZero(i) {
    let count = this.dailyRoasterObj.length;
    if (i < count - 1) {
      if (this.dailyRoasterObj[i].roasterId != this.dailyRoasterObj[i + 1].roasterId) {
        this.roasterTotal = 0;
        // this.roasterTotal = parseInt(this.dailyRoasterObj[index].hours);
        
      }
    }
    return "";
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
      var m = minutes % 60;
      let   h1 = h < 10 ? '0' + h : h;
      let   m1 = m < 10 ? '0' + m : m;
      return h1 + ':' + m1;
  }
}

