import { Injectable } from "@angular/core";
import { HttpResponse, HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';

@Injectable()
export class ErrorService {

  constructor(public dialog: MatDialog) { }
  public handleRequestError(response: HttpResponse<any>) {
    
    let msg = "";    
    msg = "Error Occured. Please try again!"
   
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        name:msg || 'Unknown error',
        description: response.body
      }
    });
  }
}
