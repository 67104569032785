import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { DocumentUpload } from '../models/documentupload.model';
import { ErrorService } from './error.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { GetAllDocumentUpload } from '../models/get-all-documentupload';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class DocumentUploadService extends BaseService {

  baseUrl: string;
  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/DocumentUpload/', httpClient, errorService, authService);
  }

  saveDocumentUpload(action: string, fileobj: DocumentUpload): Observable<any> {
    return this.postRequest(action, JSON.stringify(fileobj));
  }
  getUploadedDocument(action: string, documentId: number) {
    const params = new HttpParams()
      .set('documentId', documentId.toString())
   
    return this.customGetRequestWithQueryString(action, params);
  }
  

  getuploadFile(action: string, fileId: string, filetype: string) {
    const params = new HttpParams()
      .set('fileId', fileId)
      //.set('fileType', filetype)
    return this.customGetRequestWithQuery(action, params);
  }

  saveUploadFile(action: string, formdata: FormData): Observable<any> {
    return this.customPostWithFile(action, formdata);
  }
  //saveUploadFileNew(action: string, formdata: FormData): Observable<any> {
  //  return this.uploadFile(action, formdata);
  //}
  updateUploadFile(id: number, formdata: FormData): Observable<any> {

    return this.customPutRequest(id, formdata);
  }
  getAllUploads(action: string): Observable<any> {
    return this.customGetRequest<Array<string>>(action, null);
  }
  getAllUploadsNew(action: string, fileTitle: string = null, fileType: number , pageNumber: number = 0, pageSize: number = 10): Observable<any> {

    debugger;
    
    // var edDate = new Date(endDate);
    const params = new HttpParams()
      .set('fileId', "0")
      .set('fileTitle', fileTitle.toString())
      .set('filetype', fileType.toString())
      .set('uploadFileName', "test")
      .set('uploadFileDelete', "true")
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    console.log('Params: ', params);

    return this.customGetRequestWithQueryString<GetAllDocumentUpload>(action, params);

    // return this.customGetRequest<WaterUsage>(action, waterUsageId.toString());
  }
  deleteDocumentUpload(action: string, fileobj: DocumentUpload): Observable<any> {
    return this.postRequest(action, JSON.stringify(fileobj));
  }
  delDocumentUpload(action: string, dId: number): Observable<any> {

    return this.deleteRequest(dId);
    //return this.postRequest(action, JSON.stringify(obj));
  }
}
