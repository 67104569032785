import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { MatDialog } from '@angular/material';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'myaccount',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit {
  userModel: User;
  userid: number;
  username: string="";
  cpassword: string = null;
  npassword: string = null;
  confpassword: string = null;
  passwordPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&#])[A-Za-z\d$@@$!%*?&#]{8,}/;
  errMsg: string;
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private dialog: MatDialog,
    private headerService: HeaderService, private activatedRoute: ActivatedRoute,
    private authService: AuthService, private userService: UserService
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && ( this.rolePermissionCheckModel.isUpdate)) {
      this.initializeUserModel();
      this.username = this.authService.getUserName();
      this.userid = this.authService.getUserId();
    }
  }

  initializeUserModel(): void {
    this.userModel = this.userService.initializeUserModel();
  }

  saveAccount() {
    this.userModel.userId = parseInt(this.userid.toString());
    this.userModel.password = this.cpassword;
    this.userModel.newPassword = this.npassword;
    this.userModel.confPassword = this.confpassword;
    this.userService.updateAccount("UpdateAccountAsync", this.userModel).subscribe(res => {
      this.errMsg = res.msg;

     // if (this.errMsg=="") {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: this.errMsg  }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (this.errMsg == "Password Updated Successfully!") {
            this.authService.signOut();
            this.userModel.password = null;
            this.userModel.newPassword = null;
            this.userModel.confPassword = null;
            this.resetForm();
          }
        });
      // }
    });    
  }

  resetForm() {
    this.cpassword = null;
    this.npassword = null;
    this.confpassword = null;
  }
}
