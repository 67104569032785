import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventDpt } from '../models/eventdpt.model';
import { SystemCodeService } from '../services/system-code.service';
import { EventDptService } from '../services/eventdpt.service';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { EventCalendarDisplay } from '../models/eventcalendardisplay.model';
import { EventService } from '../services/event.service';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';
/*import { start } from 'repl';*/

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;
  actions: CalendarEventAction[] = [];
  CalendarView = CalendarView;
  rolePermissionCheckModel: RolePermissionCheck;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  eventdptModel: EventCalendarDisplay[] = [];
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  tooltipDesc: string = "";
  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private eventService: EventService) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isUpdate) {
        {
          this.actions.push({
            label: '<i class="fa fa-pencil" title="Edit Event" style="font-size:24px"></i>&nbsp;&nbsp;',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              this.handleEvent('Edited', event);
            }
          });
        }
      }
      if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isDelete) {
        this.actions.push({
          label: '<i class="fa fa-trash" title="Delete Event"  style="font-size:24px"></i>&nbsp;&nbsp;',
          a11yLabel: 'Delete',
          onClick: ({ event }: { event: CalendarEvent }): void => {
            // this.events = this.events.filter((iEvent) => iEvent !== event);
            this.handleEvent('Deleted', event);
          }
        });
      }
      if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isCreate) {
        this.actions.push({
          label: '<i class="fa fa-calendar-plus-o" title="Add New Event"  style="font-size:24px"></i>',
          a11yLabel: 'Add',
          onClick: ({ event }: { event: CalendarEvent }): void => {
            // this.events = this.events.filter((iEvent) => iEvent !== event);
            this.handleEvent('Added', event);
          }
        });
      }
    }
    this.loadGrid();

    //this.events = [      
    //  {
    //    start: subDays(endOfMonth(new Date()), 3),
    //    end: addDays(endOfMonth(new Date()), 3),
    //    title: 'A long event that spans 2 months',
    //    color: colors.blue,
    //    allDay: true,
    //    actions: this.actions
    //  }
    //];
    //}
  }

  loadGrid() {
    debugger;
    this.eventService.getEventsForCalendar().subscribe(
      (res: Array<EventCalendarDisplay>) => {
     
        this.eventdptModel = res || [];
        //console.log(this.eventdptModel);
        //console.log(this.eventdptModel[3].endTimeRange);
        //console.log(this.eventdptModel[4].startTimeRange);
       
        for (let i = 0; i < this.eventdptModel.length; i++) {
          const dily = this.eventdptModel[i];
          const dd = dily.endTimeRange;
          //console.log(dily);
          //console.log(dd);        
          let eventObj = {
            id: this.eventdptModel[i].eventId,
            start: new Date(this.eventdptModel[i].startDate),
            end: new Date(this.eventdptModel[i].endDate),
            title: this.eventdptModel[i].title + '-' + this.eventdptModel[i].startTimeRange + " to "+this.eventdptModel[i].endTimeRange ,
            color: colors.blue,
            actions: (this.eventdptModel[i].eventId > 0) ? this.actions : null        
          };         
       
          this.events.push(eventObj);       
   
          if (i == this.eventdptModel.length - 1) {          
            this.refresh.next();
          }
        }
        //  this.eventDptId = this.eventId;
        // this.GridSortAndPage();
      }, (error: any) => {

      });
  }

  //actions1: CalendarEventAction[] = [
  //  {
  //    label: '<i class="fa fa-pencil" style="font-size:24px"></i>&nbsp;&nbsp;',
  //    a11yLabel: 'Edit',
  //    onClick: ({ event }: { event: CalendarEvent }): void => {
  //      this.handleEvent('Edited', event);
  //    },
  //  },
  //  {
  //    label: '<i class="fa fa-trash"  style="font-size:24px"></i>',
  //    a11yLabel: 'Delete',
  //    onClick: ({ event }: { event: CalendarEvent }): void => {
  //      // this.events = this.events.filter((iEvent) => iEvent !== event);
  //      this.handleEvent('Deleted', event);
  //    },
  //  },
  //];

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  //constructor(private modal: NgbModal) { }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    //debugger;
    //console.log(event);
    //console.log(date);
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
    if (events.length === 0) {
      if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isCreate) {
        let dtTxt = this.getDateAsStringComma(date);
        this.routerService.navigate(['/eventscreen', { dt: dtTxt }]);
      }
    }
    //else {
    // // let dtTxt = this.getDateAsString(date);
    //  let newEvent = this.initializeCalendarEvent();
    //  newEvent.start = date;
    //  newEvent.title = "Add Event";
    //  events.push(newEvent);
    //  this.refresh.next();
    //}
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
  /*  debugger;*/
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, cevent: CalendarEvent): void {
    if (action == "Edited") {
      this.routerService.navigate(['/eventscreen', { id: cevent.id }]);
    } else if (action == "Deleted") {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: {
          id: 1
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == 1) {
          let dt: any = this.getDateAsString(cevent.start);// (cevent.start.getMonth() + 1).toString() + "-" + cevent.start.getDate().toString() + "-" + cevent.start.getFullYear().toString();
          this.eventdptModel = [];
          this.events = [];
          let id: any = cevent.id;
          let params: string = id + "$" + dt;
          this.eventService.deleteEvent("deleteEventByDate", params).subscribe(res => {
            this.loadGrid();
          });
        }
      });
    } else if (action == "Added") {
      if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isCreate) {
        let dtTxt = this.getDateAsStringComma(cevent.start);
        this.routerService.navigate(['/eventscreen', { dt: dtTxt }]);
      }
    } else {
      this.routerService.navigate(['eventupdate', { id: cevent.id} ]);
    }
    //this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null;
    }
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  initializeCalendarEvent(): CalendarEvent {
    return {
      id: 0,
      start: new Date(),
      title: null
    } as CalendarEvent;
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getDateAsStringComma(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "-" + dd + "-" + dt.getFullYear();
    } else {
      return null;
    }
  }

  openToolTip(event: any, date: Date) {

    debugger;
    this.tooltipDesc = "";
   // console.log(this.events);
    let eventlst: any = this.events.filter(x => x.start.getDate() === date.getDate() && x.start.getMonth() === date.getMonth() && x.start.getFullYear() === date.getFullYear());
    for (let i = 0; i < eventlst.length; i++) {
     
      this.tooltipDesc = this.tooltipDesc + eventlst[i].title + "\n" ;
    }
   // console.log(date);

  }
  
}

/*
{
  start: subDays(startOfDay(new Date()), 1),
    end: addDays(new Date(), 1),
      title: 'A 3 day event',
        color: colors.red,
          actions: this.actions,
            allDay: true,
              resizable: {
    beforeStart: true,
      afterEnd: true,
        },
  draggable: true,
      }      ,
{
  start: startOfDay(new Date()),
    title: 'An event with no end date',
      color: colors.yellow,
        actions: this.actions,
      },
{
  start: subDays(endOfMonth(new Date()), 3),
    end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
        color: colors.blue,
          allDay: true,
      },
{
  start: addHours(startOfDay(new Date()), 2),
    end: addHours(new Date(), 2),
      title: 'A draggable and resizable event',
        color: colors.yellow,
          actions: this.actions,
            resizable: {
    beforeStart: true,
      afterEnd: true,
        },
  draggable: true,
}
*/
