import { Component } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Certification } from '../models/certification.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material';
import { CertificationService } from '../services/certification.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';

@Component({
  selector: 'employeecertification',
  templateUrl: './employeecertification.component.html'
  //,styleUrls: ['./employeecertification.component.css']
})
export class EmployeeCertificationComponent {

  certificationModel: Certification;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private certificationService: CertificationService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          //  this.employeeId = parseInt(Id);
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.certificationModel = this.initializeCertificationModel();
    }
  }

  initializeCertificationModel(): Certification {
    return {
      employeeCertificationId: 0,
      employeeId: 0,
      certificationName: null,
      certificationNumber: null,
      dateOfExpiry: null,
      id: null,
      notes: null,
      isActive: false,
      newDateOfExpiry: null,
      isChecked: false
    } as Certification;
  }

  updateCertification() {
    this.certificationService.saveCertification("saveCertification", this.certificationModel).subscribe(res => {
      this.errMsg = res.msg;
      if (this.errMsg == "Saved Successfully.") {
        this.savedialog = true;
      }
      else {
        this.errordialog = true;
      }

      if (this.savedialog == true) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Saved Successfully!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.resetTheForm();
        });
      } else if (this.errordialog == true) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px'
        });
        //dialogRef.afterClosed().subscribe(result => {
        //  //this.navigateToSearchUsers();
        //});
      }
    });
  }

  resetTheForm() {
    this.certificationModel = this.initializeCertificationModel();
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
