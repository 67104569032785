import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SystemCodeService } from '../services/system-code.service';
import { SystemCode } from '../models/system-codes.model';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, from, forkJoin, of } from 'rxjs';
import { PpeInspection } from '../models/ppeinspection.model';
import { PPEInspectionService } from '../services/ppeinspection.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { switchMap, map, startWith, catchError } from 'rxjs/operators';
import { GetAllPpeInspection } from '../models/get-all-ppeInspection.model';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import autoTable, { UserOptions } from 'jspdf-autotable'

@Component({
  selector: 'ppeinspectionreport',
  templateUrl: './ppeinspectionreport.component.html',
  styleUrls: ['./ppeinspectionreport.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class PPEInspectionReportComponent {
  @ViewChild('ppereportForm', { static: false }) ppereportForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @BlockUI() blockUI: NgBlockUI;
  ppeInspectionModel: PpeInspection;
  ppeinspectionResultobj: PpeInspection[] = [];
  crews: SystemCode[] = [];
  names: SystemCode[] = [];
  moistureBarriers: SystemCode[] = [];
  deficiencies: SystemCode[] = [];
  ppeInspectionId: number;
  isFound: boolean = false;
  floatLabel: string = 'never';
  fromDate: any;
  toDate: any;
  minDate = new Date(2010, 0, 1);
  maxDate = new Date(2025, 11, 31);
  resultsLength: number = 0;
  errMsg: string;
  employeeNameObj: Employee[] = [];
  yesornoKey: SystemCodeKeyValue[] = [];
  crewKey: SystemCodeKeyValue[] = [];
  dataSource: MatTableDataSource<PpeInspection>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  displayedColumns: any[] = ['instructorName', 'date', 'crew', 'coat', 'pants', 'fireBoots', 'fireGloves', 'helmet', 'hood', 'usedInFireFighting', 'cleaned', 'contaminated', 'moistureBarrierName', 'deficiencies'];
  rolePermissionCheckModel: RolePermissionCheck;
  showNewButton: boolean = false;
  endDateDisable: boolean = false;
  ppeInspections: Array<PpeInspection> = [];

  constructor(private dialog: MatDialog, private routerService: Router, private route: ActivatedRoute,
    private systemCodeStateService: SystemCodeService, private ppeinspectionservice: PPEInspectionService, private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.loadDropDowns();
      this.dataSource = new MatTableDataSource<PpeInspection>(this.ppeinspectionResultobj);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ppeInspectionModel = this.initializePpeInspectionModel();
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    if (this.rolePermissionCheckModel.isRead) {
      this.displayedColumns.push('view');
    }
  }

  loadGrid() {
    this.ppeinspectionservice.getPPEInspection('GetPPEInspection', 0).subscribe(res => {
      this.ppeinspectionResultobj = res;
      this.dataSource = new MatTableDataSource(this.ppeinspectionResultobj);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  startdateevent(startDate: Date) {
    this.fromDate = startDate;
    this.endDateDisable = true;
    if (startDate == null) {
      this.ppeInspectionModel.endDate = null;
      this.endDateDisable = false;
    }
  }

  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.blockUI.start();
            return this.getAllPpeInspection(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.ppeinspectionResultobj = data || [];
          this.dataSource = new MatTableDataSource(this.ppeinspectionResultobj);
          this.dataSource.sort = this.sort;
          this.blockUI.stop();
        });
    }
  }

  getAllPpeInspection(pageIndex: number, pageSize: number): Observable<GetAllPpeInspection> {
    return this.ppeinspectionservice.getAllPpeInspectionNew(
      'GetPpeInspectionNew',
      (this.ppeInspectionModel.crew) ? this.ppeInspectionModel.crew : 0,
      (this.ppeInspectionModel.name) ? this.ppeInspectionModel.name : 0,
      (this.ppeInspectionModel.deficiencies) ? this.ppeInspectionModel.deficiencies : 0,
      (this.ppeInspectionModel.startDate) ? this.ppeInspectionModel.startDate : null,
      (this.ppeInspectionModel.endDate) ? this.ppeInspectionModel.endDate : null,
      pageIndex,
      pageSize
    );
  }

  getAllppeInspectionForDataSource(): void {
    this.blockUI.start();
    this.getAllPpeInspection(0, 15)
      .pipe(
        map(data => {
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.ppeinspectionResultobj = data || [];
        this.dataSource = new MatTableDataSource(this.ppeinspectionResultobj);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.blockUI.stop();
      });
  }

  /*********************************** */

  initializePpeInspectionModel(): PpeInspection {
    return {
      ppeInspectionId: 0, crew: null, name: null, date: null, helmet: null, hood: null, fireBoots: null, fireGloves: null,
      coat: null, pants: null, moistureBarrier: null, gearId: null, usedInFireFighting: null, cleaned: null, contaminated: null,
      deficiencies: null, description: "", crewName: "", instructorName: "", moistureBarrierName: "", startDate: null, endDate: null
    } as PpeInspection;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForShifts(),
      this.employeeService.getAllEmployees('GetEmployees', 0),
      this.systemCodeStateService.getSystemCodesForYesNo(),
      this.systemCodeStateService.getAllInstructors(),
    ).subscribe(([res1, res2, res3, res4]) => {
      this.crews = res1 || [];
      this.employeeNameObj = res2 || [];
      this.deficiencies = res3 || [];
      this.names = res4 || [];
      this.crewKey = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.yesornoKey = res3.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });
  }

  searchppeinspection() {
    if (this.ppeInspectionModel.deficiencies == null) {
      this.ppeInspectionModel.deficiencies = 0;
    }
    if (this.ppeInspectionModel.crew == null) {
      this.ppeInspectionModel.crew = 0;
    }
    if (this.ppeInspectionModel.name == null) {
      this.ppeInspectionModel.name = 0;
    }
    if (this.ppeInspectionModel.startDate == null) {
      this.ppeInspectionModel.startDate = null;
    }
    if (this.ppeInspectionModel.endDate == null) {
      this.ppeInspectionModel.endDate = null;
    }

    if (!((this.ppeInspectionModel.startDate == null) && (this.ppeInspectionModel.endDate == null) && (this.ppeInspectionModel.crew == null || this.ppeInspectionModel.crew == 0) &&
      (this.ppeInspectionModel.name == null || this.ppeInspectionModel.name == 0) && (this.ppeInspectionModel.deficiencies == null || this.ppeInspectionModel.deficiencies == 0))) {
    } else {
      return;
    }

    this.showNewButton = true;
    this.getAllppeInspectionForDataSource();
  }

  deletePpeInspection(pId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        this.ppeinspectionservice.deletePpeInspection('deletePpeInspection', parseInt(pId)).subscribe(res => {
          this.errMsg = res.msg;
          this.getAllppeInspectionForDataSource();
          if (this.errMsg == "Deleted Successfully.") {
            const dialogRef = this.dialog.open(SaveDialogComponent, {
              width: '500px',
              height: '200px',
              data: { name: "Deleted Successfully!" }
            });
          }
        });
      }
    });
    return false;
  }

  resetform() {
    this.ppeInspectionModel = this.initializePpeInspectionModel();
    this.ppereportForm.resetForm();
    this.loadGrid();
    this.ppeInspections = [];
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.showNewButton = false;
    this.endDateDisable = false;
    this.fromDate = new Date(2010, 0, 1)
    this.getAllppeInspectionForDataSource();
    return false;
  }

  addPpeInspection() {
    this.routerService.navigate(['/ppeinspectionform']);
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  showPPEInspectionReport(): void {
    this.blockUI.start();
    this.ppeinspectionservice.getAllPPEInspectionForReportView(
      'GetPPEInspectionForReportView',
      (this.ppeInspectionModel.crew) ? this.ppeInspectionModel.crew : 0,
      (this.ppeInspectionModel.name) ? this.ppeInspectionModel.name : 0,
      (this.ppeInspectionModel.deficiencies) ? this.ppeInspectionModel.deficiencies : 0,
      (this.ppeInspectionModel.startDate) ? this.ppeInspectionModel.startDate : null,
      (this.ppeInspectionModel.endDate) ? this.ppeInspectionModel.endDate : null
    ).subscribe((res: Array<PpeInspection>) => {
      this.ppeInspections = res;
      this.blockUI.stop();
      this.setPDF();
    }, (error: any) => {
      this.ppeInspections = [];
      this.blockUI.stop();
    });
  }

  setPDF() {
    let yAxis: number = 25;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setFont('Calibri');
    doc.setProperties({
      title: 'PPE Inspection Report'
    });

    var totalPagesExp = '{total_pages_count_string}';
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth('PPE Inspection Report') * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text('PPE Inspection Report', textOffset, 20)
    doc.setFontSize(10)

    //Setting the Filter to display on the PDF
    //Crew Filter Code Begin
    let crew = 'Crew: ';
    let crewEntity = this.crews.find(x => x.systemCodeId == this.ppeInspectionModel.crew);
    if (crewEntity) {
      crew = crew + crewEntity.type;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(crew, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Crew Filter Code End

    //Name Filter Code Begin
    let name = 'Name: ';
    //let nameEntity = this.names.find(x => x.systemCodeId == this.ppeInspectionModel.name);
    let nameEntity = this.getEmployeeName();
    if (nameEntity) {
      //name = name + nameEntity.type;
      name = name + nameEntity;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(name, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Name Filter Code End

    //Start Date and End Date Filter Code Begin
    let startDate = 'Start Date: ';
    if (this.ppeInspectionModel.startDate) {
      let startDateString = new Date(this.ppeInspectionModel.startDate);
      let startDateFormat = this.getDateAsString(startDateString);
      startDate = startDate + startDateFormat;
    }
    let endDate = 'End Date: ';
    if (this.ppeInspectionModel.endDate) {
      let endDateString = new Date(this.ppeInspectionModel.endDate);
      let endDateFormat = this.getDateAsString(endDateString);
      endDate = endDate + endDateFormat;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(startDate + ' - ' + endDate, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Start Date and End Date Filter Code End

    //Deficiencies Filter Code Begin
    let deficiency = 'Deficiencies: ';
    let deficienciesEntity = this.deficiencies.find(x => x.systemCodeId == this.ppeInspectionModel.deficiencies);
    if (deficienciesEntity) {
      deficiency = deficiency + deficienciesEntity.type;
    }
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var text = doc.splitTextToSize(deficiency, pageWidth - 35)
    doc.text(text, (pageWidth / 2), yAxis, { align: 'center' });
    yAxis = yAxis + 5;
    //Deficiencies Filter Code End

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        deficiencyComment: {
          cellWidth: 60
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize: 10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 250, pageHeight - 10);

        let currentDate = new Date();
        let ddFormat = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
        let mmFormat = ((currentDate.getMonth() + 1) < 10 ? '0' : '') + (currentDate.getMonth() + 1);
        let currentDateString = mmFormat + '/' + ddFormat + '/' + currentDate.getFullYear();
        doc.text(currentDateString, 15, pageHeight - 10);
      },
      startY: yAxis
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    window.open(URL.createObjectURL(doc.output("blob")));
  }

  getDateAsString(dt: Date): string {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  getHeadRows() {
    return [
      {
        instructorName: 'Name', date: 'Date',
        //crew: 'Crew',
        coats: 'Coat', pants: 'Pants', fireBoots: 'Fire Boots',
        fireGloves: 'Fire Gloves', helmet: 'Helmet', hood: 'Hood', usedInFireFighting: 'Used In FF',
        cleaned: "Cleaned", contaminated: 'Cont', moistureBarrier: 'Moisture Barrier', defeciencies: 'Def',
        deficiencyComment: 'Def Com'
      }
    ]
  }

  getBody() {
    let rowCount = this.ppeInspections.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {
      let actionDate = this.ppeInspections[j].date;
      let actionDateStringFormat = '';
      if (actionDate) {
        let newActionDate = new Date(actionDate);
        actionDateStringFormat = this.getDateAsString(newActionDate);
      }

      body.push({
        instructorName: this.ppeInspections[j].instructorName,
        date: actionDateStringFormat,
        //crew: this.ppeInspections[j].crewName,
        coats: this.yesornoKey.find(x => x.key == this.ppeInspections[j].coat).value,
        pants: this.yesornoKey.find(x => x.key == this.ppeInspections[j].pants).value,
        fireBoots: this.yesornoKey.find(x => x.key == this.ppeInspections[j].fireBoots).value,
        fireGloves: this.yesornoKey.find(x => x.key == this.ppeInspections[j].fireGloves).value,
        helmet: this.yesornoKey.find(x => x.key == this.ppeInspections[j].helmet).value,
        hood: this.yesornoKey.find(x => x.key == this.ppeInspections[j].hood).value,
        usedInFireFighting: this.yesornoKey.find(x => x.key == this.ppeInspections[j].usedInFireFighting).value,
        cleaned: this.yesornoKey.find(x => x.key == this.ppeInspections[j].cleaned).value,
        contaminated: this.yesornoKey.find(x => x.key == this.ppeInspections[j].contaminated).value,
        moistureBarrier: this.ppeInspections[j].moistureBarrierName,
        defeciencies: this.yesornoKey.find(x => x.key == this.ppeInspections[j].deficiencies).value,
        deficiencyComment: this.ppeInspections[j].description,
      });
    }
    return body;
  }

  getEmployeeName() {

    const personelIndex = this.employeeNameObj.findIndex(x => x.employeeId == this.ppeInspectionModel.name);
    if (personelIndex >= 0) {
      let lastName = this.employeeNameObj[personelIndex].lastName;
      let firstName = this.employeeNameObj[personelIndex].firstName;
      let miName = this.employeeNameObj[personelIndex].miName;
      let mname = (miName) ? miName : "";
      return lastName + " " + mname + " " + firstName;

    }
    return "";
  }

}
