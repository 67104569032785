import { Component, ViewChild, OnInit, Inject, ElementRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { HttpClientModule, HttpClient, HttpEventType } from '@angular/common/http';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { DocumentUpload } from '../models/documentupload.model';
import { DocumentUploadService } from '../services/documentupload.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, of } from 'rxjs';
import { startWith, map, switchMap, catchError } from 'rxjs/operators';
import { saveFile, saveAs } from 'file-saver';
import { debug } from 'util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GetAllDocumentUpload } from '../models/get-all-documentupload';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DeleteUpdationComponent } from "./deleteupdation.component";

@Component({
  selector: 'documentlibrary',
  templateUrl: './documentlibrary.component.html'
})
export class DocumentLibraryComponent {
  @ViewChild('documentForm', { static: false }) documentForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  fileTypes: SystemCode[] = [];
  fileModel: DocumentUpload;
  fileList: DocumentUpload[] = [];
  dataSource : MatTableDataSource<DocumentUpload>;
  paginatorid: MatPaginator;
  sortid: MatSort;
  resultsLength: number = 0;
  @BlockUI() blockUI: NgBlockUI;
  displayedColumns: any[] = ['fileTitle', 'uploadFileName', 'download'];
  rolePermissionCheckModel: RolePermissionCheck;
  isShowAddButton: boolean = false;
  constructor(
    private systemCodeStateService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private documentUploadservice: DocumentUploadService, private http: HttpClient
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      this.initializeActionsForDisplayedColumns();
      this.loadDropDowns();
      this.fileModel = this.initializeModel();
      this.dataSource = new MatTableDataSource(this.fileList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  initializeModel(): DocumentUpload {
    return {
      fileId: 0,
      fileTitle: null,
      filetype: null,
      uploadFileName: null,
      uploadFileDelete: false
    } as DocumentUpload;
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isUpdate) {
      this.displayedColumns.push('edit');
    }
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
  }

  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && this.rolePermissionCheckModel.isRead) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllDocumentNew(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            //this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.fileList = data || [];
          this.dataSource = new MatTableDataSource(this.fileList);
          this.dataSource.sort = this.sort;
          //this.blockUI.stop();
        });
    }
  }

  getAllDocumentNew(pageIndex: number, pageSize: number): Observable<GetAllDocumentUpload> {
    debugger;
    return this.documentUploadservice.getAllUploadsNew(
      'GetAllDocumentsNew',
      (this.fileModel.fileTitle == null) ? "" : this.fileModel.fileTitle,
      (this.fileModel.filetype == null) ? 0 : this.fileModel.filetype,
      pageIndex,
      pageSize
    );
  }

  getAllDocumentsForDataSource(): void {
    this.getAllDocumentNew(0, 15)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.fileList = data || [];
        this.dataSource = new MatTableDataSource(this.fileList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.blockUI.stop();
      });
  }

  /*********************************** */
  loadGrid() {
    debugger;
    this.documentUploadservice.getAllUploads('GetAllFileUploads').subscribe((res: Array<DocumentUpload>) => {
      debugger;
      this.fileList = res || [];
      this.dataSource = new MatTableDataSource<DocumentUpload>(this.fileList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => { }
    );

    this.dataSource = new MatTableDataSource<DocumentUpload>(this.fileList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openOFile(fileId: string, fileName: string) {
    this.documentUploadservice.getuploadFile('GetUploadFile', fileId, "Upload").subscribe(res => {
      //var blob = new Blob([data], { type: type });
      //var url = window.URL.createObjectURL(blob);
      saveAs(res, fileName);
      //window.location.href = "/";
      //let url = "https://localhost:44391/api/Occupancy/GetOccupancyFile?occupancyId=4&fileType=Occupancy";
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      //saveAs(res, "sdsd.txt");
      // var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }

  deleteOFile(fileId: string) {
    this.fileModel.uploadFileDelete = true;
    this.fileModel.uploadFileName = "";
    return false;
  }

  public loadDropDowns() {
    this.systemCodeStateService.getAllfileTypes().subscribe(
      (res: Array<SystemCode>) => {
        this.fileTypes = res || [];
      }, (error: any) => { }
    );
  }

  public downloadFile(fileId: string, fileName: string) {
    debugger;
    this.documentUploadservice.getuploadFile('GetUploadFile', fileId, "Upload").subscribe(res => {
      debugger;
      if (res.type == "application/octet-stream") {
        saveAs(res, fileName);
      } else {
        this.canceldialog();
      }
      //var blob = new Blob([data], { type: type });
      //var url = window.URL.createObjectURL(blob);
      
      //window.location.href = "/";
      //let url = "https://localhost:44391/api/Occupancy/GetOccupancyFile?occupancyId=4&fileType=Occupancy";
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      //saveAs(res, "sdsd.txt");
      // var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }
  canceldialog(): void {
    //if (this.assignmentForm.dirty) {
    debugger;
    const dialogRef = this.dialog.open(DeleteUpdationComponent, {
      width: '400px',
    });
    //} else {
    //this.dialog.closeAll();
    //}  
  }

  resetTheForm() {
    this.blockUI.start();
    this.fileModel = this.initializeModel();
    this.documentForm.resetForm();
    // this.loadGrid();
    this.getAllDocumentsForDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 15;
    this.isShowAddButton = false;
    //this.searchMsg = "";
    // this.blockUI.stop();
    return false;
  }

  //deleteDocument(id:number) {
  //  const dialogRef = this.dialog.open(DeleteDialogComponent, {
  //    data: {
  //      id: 1
  //    }
  //  });
  //  dialogRef.afterClosed().subscribe(res => {
  //    if (res == 1) {
  //      // this.employeeObj.employeeId = parseInt( eId);

  //      //this.documentUploadservice.delWaterUsage('DeleteDocument', parseInt(wId)).subscribe(res => {
  //      //  //  this.loadGrid();
  //      //  this.get();

  //      //});
  //    }

  //  });
  //  return false;
  //}

  deleteDocument(dId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        // this.employeeObj.employeeId = parseInt( eId);

        this.documentUploadservice.delDocumentUpload('delDocumentUpload', parseInt(dId)).subscribe(res => {
          this.getAllDocumentsForDataSource();
          //this.loadGrid();

        });
      }
    });
    return false;
  }

  public searchDocumentReport() {
    //  this.blockUI.start();
    this.isShowAddButton = true;
    this.getAllDocumentsForDataSource();
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
