import { Component, OnInit } from '@angular/core';
import { HeaderService } from './services/header.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    this.headerService.showHeader = of(true);
  }
}
