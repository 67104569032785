import { AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors, AbstractControl } from "@angular/forms";
import { Directive, Input } from "@angular/core";
import { Observable, of, timer } from "rxjs";
import { User } from "../../models/user.model";
import { UserService } from "../../services/user.service";
import { switchMap, map } from "rxjs/operators";

@Directive({
  selector: '[userNameExists]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ExistingUserNameValidatorDirective, multi: true }]
})
export class ExistingUserNameValidatorDirective implements AsyncValidator {
  userModel: User;
  @Input() userNameForUpdate: boolean;

  constructor(private userService: UserService) { }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (this.userNameForUpdate !== control.value) {
      return timer(1500).pipe(
        switchMap(() => {
          if (!control.value) {
            return of(null);
          }
          return this.userService.userNameExists(control.value).pipe(map(user => {
            return user ? { "userNameExists": true } : null;
          }));
        })
      );
    } else {
      return of(null);
    }
  }
}
