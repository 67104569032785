import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'model-dialog-content',
  templateUrl: 'model-dialog-content.html',
})
export class ModelDialogContent {
  nameList: string[];
  heading: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.nameList = data.names;
    this.heading = data.headingName;

  }
}
