import { Component, ViewChild } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatFormFieldModule, MatInputModule } from '@angular/material';

import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { EventDpt } from '../models/eventdpt.model';
import { EventDptService } from '../services/eventdpt.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { EventCalendarExclude } from '../models/eventcalendarexclude.model';
import { EventCalendarInclude } from '../models/eventcalendarinclude.model';
import { EventInclude } from '../models/eventinclude.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { forkJoin } from 'rxjs';
import { SystemCodeKeyValue } from '../models/systemcodekeyvalue.model';
import { EventCalendar } from '../models/eventCalendar.model';
import { EventService } from '../services/event.service';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { GlobalConstants } from '../common/global-constants';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';


@Component({
  selector: 'eventscreen',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class EventComponent {
  @ViewChild('eventDptForm', { static: false }) eventDptForm: NgForm;

  eventCalendarModel: EventCalendar;
  eventTypesCode: SystemCode[] = [];
  periodicalsCode: SystemCode[] = [];
  periodicalsKey: SystemCodeKeyValue[] = [];
  yearlymonthlyCode: SystemCode[] = [];
  durationCode: SystemCode[] = [];
  monthCode: SystemCode[] = [];
  yearlymonthlydaysCode: SystemCode[] = [];
  monthlydaysCode: SystemCode[] = [];
  eventDateExcludes: EventCalendarExclude[] = [];
  eventDateIncludes: EventCalendarInclude[] = [];
  //eventTime: EventTime[] = [];
  fromDate: Date;
  toDate: Date;
  todayDate: Date = new Date();
  byDayMData: Array<number>;
  byDayM1Data: Array<number>;
  byDayYData: Array<number>;
  byDayY1Data: Array<number>;

  byDayMData1: number[] = [];
  byDayM1Data1: number[] = [];
  byDayYData1: number[] = [];
  byDayY1Data1: number[] = [];

  dailyoption1: number = 1;
  dailyoption2: number = 2;
  dailyoption3: number = 3;
  dailyoption4: number = 4;
  timeerror: string;
  dateerror: string;
  date1error: string;
  errMsg: string;
  datehint: string;
  hint1: string;
  recurrenceHint1: string = "";
  dropDownHint: string = "";
  hint2: string = ""
  totalActiveItems: number = 0;
  eventCalendarId: number;
  savedialog: boolean;
  errordialog: boolean;
  showRepeat: boolean = false;
  showdiv: boolean = false;
  showMonthlyDiv: boolean = false;
  showYearlyDiv: boolean = false;
  showWeeklyDiv: boolean = false;
  showDailyDiv: boolean = false;
  showInclude: boolean = false;
  showExclude: boolean = false;
  EndDateDisable: boolean = false;
  ByDateDisable: boolean = false;
  ByDayDisable: boolean = false;
  rangeRadioCheck1: boolean = false;
  rangeOccurreneceDisable: boolean = false;
  rangeRadioCheck2: boolean = false;
  recurrenceToDateDisable: boolean = false;
  ByDateCheckDisable: boolean = false;
  ByDayCheckDisable: boolean = false;
  DayOnEventVar: number;
  DateOnEventVar: number;
  dailyradiocheck1: boolean = false;
  dailyradiocheck2: boolean = false;
  dailyradiocheck3: boolean = false;
  dailyradiocheck4: boolean = false;
  monthlyradiocheck1: boolean = false;
  monthlyradiocheck2: boolean = false;

  monthlydd1Disable: boolean = true;
  monthlydd2Disable: boolean = true;
  monthlydd3Disable: boolean = true;
  monthlydd4Disable: boolean = true;
  monthlydd5Disable: boolean = true;

  yearlyradiocheck1: boolean = false;
  yearlyradiocheck2: boolean = false;

  yearlydd1Disable: boolean = true;
  yearlydd2Disable: boolean = true;
  yearlydd3Disable: boolean = true;
  yearlydd4Disable: boolean = true;
  yearlydd5Disable: boolean = true;

  dailyCountDisable: boolean = false;
  OccurenceDisable: boolean = false;
  AfterDateDisable: boolean = false;

  dailyoption1Rdaio: boolean = false;
  dailyoption2Rdaio: boolean = false;
  totalhrscheck: boolean = true;
  recurrenceCheck: boolean = false;
  resetDisable: boolean = false;
  //dcheck: boolean;
  dcheck: boolean = true;
  Repeat: boolean = true;
  Disable: boolean = true;
  periodicalcheck: string = "Repeats";
  floatLabel: string = 'never';
  monthdays: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  byMonthDay: number;
  january1: boolean = false;
  february1: boolean = false;
  march1: boolean = false;
  april1: boolean = false;
  may1: boolean = false;
  june1: boolean = false;
  july1: boolean = false;
  august1: boolean = false;
  september1: boolean = false;
  october1: boolean = false;
  november1: boolean = false;
  december1: boolean = false;

  radiocheck2: boolean;
  radiocheck1: boolean;
  radiocheck3: boolean;
  radiocheck4: boolean;
  thFromTime: number;
  fromTime: number = 12;
  toTime: number = 11;
  PrefromTime: number;
  PretoTime: number;
  rolePermissionCheckModel: RolePermissionCheck;
  zone: string = 'AM';
  zone1: string = 'PM';
  thToTime: number
  totalhours: number;
  totalhoursstr: string;
  startDate: string;
  endDate: string;

  fromTimeStr: string = "12:00 AM";
  toTimeStr: string = "11:30 PM";  
  //duration = Duration;
  //months = Months;
  //occurances = Occurances;
  //weekDays = WeekDays;
  occurancesNum: number;
  @BlockUI() blockUI: NgBlockUI;
  disableSave: boolean = false;
  constructor(
    private systemCodeService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private eventDptService: EventService, public datepipe: DatePipe,
    private dateAdapter: DateAdapter<Date>) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];

    //route.params.subscribe(val => {

    //  if (val.id == undefined) {
    //    this.eventCalendarId = 0;
    //    this.resetTheForm();
    //  }
    //  else {
    //    this.eventCalendarId = parseInt(val.id);
    //  }
    //});

  }


  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      //this.eventCalendarModel.fromDate = null;      
      //this.occurancesNum = parseInt( this.occurances);
      //this.disableSave = false;
      this.eventCalendarModel = this.initializeeventCalendarModell();
      var date1 = this.initializedate1();
      var date2 = this.initializedate2();
      //var time1 = this.initializetime1()
      this.datehint = "";
      this.recurrenceHint1 = '';
      this.eventDateExcludes.push(date1);
      this.eventDateIncludes.push(date2);
      // this.eventTime.push(time1);
      this.defaultfields1();
      this.defaultfields2();
      this.getTotalhour();
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.eventCalendarId = parseInt(Id);
          this.eventCalendarModel.eventCalendarId = this.eventCalendarId;
        } else if (Id == null || Id == undefined) {
          let dt = params['dt'];
          if (dt != null && dt != undefined) {
            let newdt = dt.replaceAll("-", "/");
            this.eventCalendarModel.fromDate = new Date(newdt);
            this.eventCalendarModel.recurrenceFromDate = new Date(newdt);
          }
          this.eventCalendarId = 0;
          this.resetDisable = false;
          if (dt == null || dt == undefined) {
            this.resetTheForm();          
          }
          this.eventCalendarModel.eventCalendarId = 0;
        }
        else {
          let dt = params['dt'];
          if (dt != null && dt != undefined) {
            let newdt = dt.replaceAll("-","/");
            this.eventCalendarModel.fromDate = new Date(newdt);
            this.eventCalendarModel.recurrenceFromDate = new Date(newdt);

          }
        }
      });

      if (this.eventCalendarId > 0) {
        this.eventDptService.getEvent('getEvent', this.eventCalendarId).subscribe(res => {          
          this.resetDisable = true;
          this.recurrenceHint1 = "";
          this.dropDownHint = "";
          this.datehint = "";
          this.hint2 = "";
          this.eventCalendarModel = res || [];
          //9-26-2022, today cannot be from date. otherwise, it will not allow user to change the start date before the event if they want to
          //this.todayDate = this.eventCalendarModel.fromDate;
          this.eventCalendarModel.recurrenceToDate = res.recurrenceToDate;
          this.eventDateExcludes = res.eventDate || [];
          this.eventDateIncludes = res.eventInclude || [];

          this.byDayMData1 = res.byDayMData;
          this.byDayM1Data1 = res.byDayM1Data;
          this.byDayYData1 = res.byDayYData;
          this.byDayY1Data1 = res.byDayY1Data;

          //this.byDayMData1 = res.byDayMData.map(String);
          //this.byDayM1Data1 = res.byDayM1Data.map(String);
          //this.byDayYData1 = res.byDayYData.map(String);
          //this.byDayY1Data1 = res.byDayY1Data.map(String);


          //var byDayOnM1 = this.eventCalendarModel.byDayOnM1.toString();
          //int something = (int) this.eventCalendarModel.byDayOnM1;
          //byDayOnM1.replace("""," ")
          //var hoursMinutes = event.split(/[.: ]/);
          //this.eventCalendarModel.byDayOnM1 = this.eventCalendarModel.byDayOnM1.toString();

          this.EndDateDisable = true;

          //check excluded checkbox by default
          if (this.eventDateExcludes.length > 0) {
            let excludeCount = 0;
            for (let z = 0; z < this.eventDateExcludes.length; z++) {
              if (this.eventDateExcludes[z].isActive == true) {
                excludeCount = excludeCount + 1;
              }
            }
            if (excludeCount > 0) {
              this.eventCalendarModel.excludeCheck = true;
            }
          }   

          if (this.eventCalendarModel.eventDate.length == 0) { //Exclude date list
            this.eventCalendarModel.eventDate.push(date1);
          }
            if (this.eventCalendarModel.excludeCheck == true) {
              this.showExclude = true;
            } else { this.showExclude = false; }
          

          //check included checkbox by default
          if (this.eventDateIncludes.length > 0) {
            let includeCount = 0;
            for (let z = 0; z < this.eventDateIncludes.length; z++) {
              if (this.eventDateIncludes[z].isActive == true) {
                includeCount = includeCount + 1;
              }
            }
            if (includeCount > 0) {
              this.eventCalendarModel.includeCheck = true;
            }
          }
          if (this.eventCalendarModel.eventInclude.length == 0) {
            this.eventCalendarModel.eventInclude.push(date2);
          }

          if (this.eventCalendarModel.includeCheck == true) {
            this.showInclude = true;
          } else { this.showInclude = false; }

          //if (this.eventCalendarModel.dailyOption == this.dailyoption1) {
          //  this.dailyoption1Rdaio = true;
          //}else if (this.eventCalendarModel.dailyOption == 2) {
          //  this.dailyoption2Rdaio = true;
          //}
          this.show1();            

         
          if (this.eventCalendarModel.endDateCheck == true) {
            this.showdiv = true;
          } else { this.showdiv = false; }
          if (this.eventCalendarModel.repeatCheck == true) {
            this.showRepeat = true;
          } else { this.showRepeat = false; }
          if (this.eventCalendarModel.endDateCheck == true) {
            this.EndDateDisable = true;
          }
          if (this.eventCalendarModel.rangeOccurrenceCount == 0 || this.eventCalendarModel.rangeOccurrenceCount == null || this.eventCalendarModel.recurrenceToDate != null) {
            this.rangeRadioCheck1 = true;
            this.rangeRadioCheck2 = false;
            this.recurrenceToDateDisable = false;
            this.rangeOccurreneceDisable = true;
          } else if (this.eventCalendarModel.rangeOccurrenceCount > 0 || this.eventCalendarModel.recurrenceToDate == null || this.eventCalendarModel.rangeOccurrenceCount != 0 || this.eventCalendarModel.rangeOccurrenceCount != null) {
            this.rangeRadioCheck2 = true;
            this.rangeRadioCheck1 = false;
            this.rangeOccurreneceDisable = false;
            this.recurrenceToDateDisable = true;
          }

          if (this.eventCalendarModel.periodical == this.periodicalsKey[0].key) {
            this.showDailyDiv = true;
            this.showWeeklyDiv = false;
            this.showMonthlyDiv = false;
            this.showYearlyDiv = false;
          } else if (this.eventCalendarModel.periodical == this.periodicalsKey[2].key) {
            this.showMonthlyDiv = true;
            this.showDailyDiv = false;
            this.showWeeklyDiv = false;
            this.showYearlyDiv = false;
            if (this.eventCalendarModel.byMonthDayM == null || this.eventCalendarModel.byDayMData == [] || this.eventCalendarModel.byDayMData == null) {
              this.monthlyradiocheck2 = true;
              this.monthlyradiocheck1 = false;
              this.monthlydd1Disable = true;
              this.monthlydd2Disable = true;
              this.monthlydd3Disable = false;
              this.monthlydd4Disable = false;
              this.monthlydd5Disable = false;
            } else if (this.eventCalendarModel.byDayOnM1 == null || this.eventCalendarModel.yearlyMonthM1 == null || this.eventCalendarModel.byDayM1Data == null
              || this.eventCalendarModel.byDayM1Data == []) {
              this.monthlyradiocheck1 = true;
              this.monthlyradiocheck2 = false;
              this.monthlydd1Disable = false;
              this.monthlydd2Disable = false;
              this.monthlydd3Disable = true;
              this.monthlydd4Disable = true;
              this.monthlydd5Disable = true;
            }
          } else if (this.eventCalendarModel.periodical == this.periodicalsKey[1].key) {
            this.showWeeklyDiv = true;
            this.showDailyDiv = false;
            this.showMonthlyDiv = false;
            this.showYearlyDiv = false;
          } else if (this.eventCalendarModel.periodical == this.periodicalsKey[3].key) {
            this.showYearlyDiv = true;
            this.showDailyDiv = false;
            this.showWeeklyDiv = false;
            this.showMonthlyDiv = false;
            if (this.eventCalendarModel.byMonthDayY != null || this.eventCalendarModel.byMonthDayY > 0 || this.eventCalendarModel.byDayYData.length > 0) {
              this.yearlyradiocheck1 = true;
              this.yearlyradiocheck2 = false;
              this.yearlydd1Disable = false;
              this.yearlydd2Disable = false;
              this.yearlydd3Disable = true;
              this.yearlydd4Disable = true;
              this.yearlydd5Disable = true;
            } else if (this.eventCalendarModel.byDayOnY1 != null || this.eventCalendarModel.byDayOnY1 > 0 || this.eventCalendarModel.yearlyMonthY1 != null
              || this.eventCalendarModel.byDayY1Data.length > 0) {
              this.yearlyradiocheck2 = true;
              this.yearlyradiocheck1 = false;
              this.yearlydd1Disable = true;
              this.yearlydd2Disable = true;
              this.yearlydd3Disable = false;
              this.yearlydd4Disable = false;
              this.yearlydd5Disable = false;
            }
          }
          this.recurrenceHint1 = ""; 
        });
      }
      else {
        //this.clearModelMonth();
        //this.clearMonth();
       // this.resetTheForm();
      }
    }
  }

  //keys(): Array<string> {
  //  var keys = Object.keys(this.months);
  //  return keys.slice(keys.length / 2);
  //}
  //keys1(): Array<string> {
  //  var keys = Object.keys(this.months);
  //  return keys.slice(0, keys.length / 2);
  //}
  //keyOccurr(): Array<string> {
  //  var keys = Object.keys(this.occurances);
  //  return keys.slice(keys.length / 2);
  //}

  defaultfields2() {
    //this.eventCalendarModel.duration = null;
    this.recurrenceHint1 = "";
    this.dropDownHint = "";
    this.eventCalendarModel.dailyOption = this.dailyoption1;
    this.eventCalendarModel.dailyOccurenceCount = 1;
    this.dailyCountDisable = false;
    this.rangeRadioCheck1 = true;
    this.rangeRadioCheck2 = false;
    this.rangeOccurreneceDisable = true;
    this.showDailyDiv = true;
    this.showMonthlyDiv = false;
    this.showWeeklyDiv = false;
    this.showYearlyDiv = false;
    this.monthlyradiocheck1 = true;
    // this.eventCalendarModel.dailyOccurenceCount = 1;
  }
  defaultfields1() {
    //this.eventCalendarModel.duration = null;
    // this.recurrenceHint1 = "";
    // this.dropDownHint = "";
    // this.eventCalendarModel.dailyOption = this.dailyoption1;
    // this.eventCalendarModel.dailyOccurenceCount = 1;
    this.eventCalendarModel.eventTimeRange = "12:00 AM";
    this.eventCalendarModel.eventEndTimeRange = "11:30 PM";
    this.toTime = 10;
    this.toTime = 11;
    this.zone = "AM";
    this.zone1 = "PM";
    this.totalhours = 23;
    //this.timevent(this.eventCalendarModel.eventTimeRange);
    //this.timevent1(this.eventCalendarModel.eventEndTimeRange);
    // this.dailyCountDisable = false;
    //this.rangeRadioCheck1 = true;
    //this.rangeRadioCheck2 = false;
    // this.rangeOccurreneceDisable = true;
    this.fromDate = null;
    // this.showDailyDiv = true;
    // this.showMonthlyDiv = false;
    // this.showWeeklyDiv = false;
    //this.showYearlyDiv = false;
    // this.monthlyradiocheck1 = true;
    // this.eventCalendarModel.dailyOccurenceCount = 1;
  }

  clearYear() {
    this.eventCalendarModel.byMonthDayY = null;
    this.byDayYData1 = [];
    this.eventCalendarModel.byDayOnY1 = null;
    this.byDayY1Data1 = [];
    this.eventCalendarModel.yearlyMonthY1 = null;
  }

  clearMonth() {
    this.byDayMData1 = [];
    this.eventCalendarModel.byMonthDayM = null;
    this.byDayM1Data1 = [];
    this.eventCalendarModel.byDayOnM1 = null;
    this.eventCalendarModel.yearlyMonthM1 = null;
    //this.monthlyradiocheck1 = false;
    this.monthlyradiocheck2 = false;
  }
  clearWeek() {
    this.eventCalendarModel.weeklyOccurrenceCount = null;
    this.eventCalendarModel.sunday = false;
    this.eventCalendarModel.monday = false;
    this.eventCalendarModel.tuesday = false;
    this.eventCalendarModel.wednesday = false;
    this.eventCalendarModel.thursday = false;
    this.eventCalendarModel.friday = false;
    this.eventCalendarModel.saturday = false;
  }
  clearDaily() {
    this.eventCalendarModel.dailyOccurenceCount = null;
  }


  initializeeventCalendarModell(): EventCalendar {
    return {
      eventCalendarId: 0, eventTitle: null, eventType: null, fromDate: null, toDate: null, description: null,
      eventTimeRange: null, eventEndTimeRange: null, duration: 0, periodical: 0, dailyOption: 0,
      dailyOccurenceCount: 0, weeklyOccurrenceCount: 0,
      sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false,
      byMonthDayM: 0, byDayMData: null, byDayOnM1: 0, byDayM1Data: null, yearlyMonthM1: 0, byMonthDayY: 0, byDayYData: null, byDayOnY1: 0,
      byDayY1Data: null, yearlyMonthY1: 0, recurrenceFromDate: null, recurrenceToDate: null, rangeOccurrenceCount: 0,
      eventDate: null, eventInclude: null, excludeCheck: false, includeCheck: false, endDateCheck: false, repeatCheck: false
    } as EventCalendar;
  }
  initializedate1(): EventCalendarExclude {
    return {
      eventDateId: 0, excludeDate: null, isActive: true, eventCalendarId: 0
    } as EventCalendarExclude;
  }
  initializedate2(): EventCalendarInclude {
    return {
      eventIncludeId: 0, includeDate: null, isActive: true, eventCalendarId: 0
    } as EventCalendarInclude;
  }

  resetDate() {
    this.eventCalendarModel.toDate = null;
  }

  resetDateEnd() {
    this.eventCalendarModel.recurrenceToDate = null;
  }

  public addAddition() {
    if (this.eventDateIncludes.length > 0) {
      let theEventDateIncludes: EventCalendarInclude[] = [];
      for (var i = 0; i < this.eventDateIncludes.length; i++) {
        if (this.eventDateIncludes[i].isActive == true) {
          theEventDateIncludes.push(this.eventDateIncludes[i]);
        }
      }
      if (theEventDateIncludes[theEventDateIncludes.length - 1].includeDate != null) {
        var activities = this.initializedate2();
        this.eventDateIncludes.push(activities);
      }
      else {
        this.date1error = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.date1error = "";
        //}, 1500);
      }
    }
    else {
      var activities = this.initializedate2();
      this.eventDateIncludes.push(activities);
    }
    return false;
    //var date2 = this.initializedate2();
    //this.eventDateIncludes.push(date2);
    //return false;
  }

  public addException() {
    if (this.eventDateExcludes.length > 0) {
      let theEventDate: EventCalendarExclude[] = [];
      for (var i = 0; i < this.eventDateExcludes.length; i++) {
        if (this.eventDateExcludes[i].isActive == true) {
          theEventDate.push(this.eventDateExcludes[i]);
        }
      }
      if (theEventDate[theEventDate.length - 1].excludeDate != null) {
        var activities = this.initializedate1();
        this.eventDateExcludes.push(activities);
      }
      else {
        this.dateerror = "Please Fill the above field to add another item !";
        //setTimeout(() => {
        //  this.dateerror = "";
        //}, 1500);
      }
    }
    else {
      var activities = this.initializedate1();
      this.eventDateExcludes.push(activities);
    }
    return false;
    //var date1 = this.initializedate1();
    //this.eventDateExcludes.push(date1);
    //return false;
  }


  public removeAddition(Id: number) {
    const index = Id;
    this.totalActiveItems = 0;
    for (let i = 0; i < this.eventDateIncludes.length; i++) {
      if (this.eventDateIncludes[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    // const index = this.eventDateIncludes.findIndex(x => x.eventIncludeId == Id);
    if (this.totalActiveItems == 1) {
      this.eventDateIncludes[index].includeDate = null;

    }
    else {
      this.eventDateIncludes[index].isActive = false;
    }
    this.date1error = "";
    return false;
  }

  public removeException(Id: number) {
    const index = Id;
    //const index = this.eventDateExcludes.findIndex(x => x.eventDateId == Id);
    this.totalActiveItems = 0;
    for (let i = 0; i < this.eventDateExcludes.length; i++) {
      if (this.eventDateExcludes[i].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
    if (this.totalActiveItems == 1) {
      this.eventDateExcludes[index].excludeDate = null;
    }
    else {
      this.eventDateExcludes[index].isActive = false;
    }
    this.dateerror = "";
    return false;
  }

  getTotalhour() {
    if (this.eventCalendarModel.fromDate == null || this.eventCalendarModel.fromDate == undefined) {
      let now = new Date();
      this.startDate = this.datepipe.transform(now, 'yyyy-MM-dd')
      this.endDate = this.datepipe.transform(now, 'yyyy-MM-dd')
    } else {
      this.startDate = this.datepipe.transform(this.eventCalendarModel.fromDate, 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(this.eventCalendarModel.fromDate, 'yyyy-MM-dd');
    }
    let startDate = this.startDate;
    let endDate = this.endDate;

    //if (this.eventCalendarModel.toDate != null || this.eventCalendarModel.toDate != undefined) {
    //  this.eventCalendarModel.toDate = this.eventCalendarModel.toDate;
    //} else {
    //  this.eventCalendarModel.toDate = this.eventCalendarModel.fromDate;
    //}
    //let endDate = this.datepipe.transform(this.eventCalendarModel.toDate, 'yyyy-MM-dd');


    var fromTime = this.eventCalendarModel.eventTimeRange;
    var toTime = this.eventCalendarModel.eventEndTimeRange;

    var stDate: any = new Date(startDate);
    var edDate: any = new Date(endDate);

    var hoursMinutes = this.timeConvertor(fromTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

     
    stDate.setHours(hours, minutes);
    if (hoursMinutes.includes("PM") && hours !== 12) {
      hours += 12;
      stDate.setTime(1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes));
    }

    this.fromTime = hours + minutes / 60;
    this.thFromTime = this.fromTime;

    var hoursMinutes = this.timeConvertor(toTime).split(/[.: ]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    edDate.setHours(hours, minutes);

    let diffMs = (edDate - stDate);
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    var startTimeinNumber = (new Date(stDate).getTime() / 1000);
    var endTimeinNumber = (new Date(edDate).getTime() / 1000);
    var hourDiff = (endTimeinNumber - startTimeinNumber) / 3600;
    var minDiff = (endTimeinNumber - startTimeinNumber) / 60;
    this.totalhoursstr = this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);
    //return this.appendZero(Math.floor(hourDiff)) + ":" + this.appendZero(diffMins);
  }


  appendZero(mins: number) {
    if (mins < 10) {
      return '0' + mins;
    }
    else
      return mins;

  }
  timeConvertor(time: any) {
    //var time = '02:05 PM';

    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1].toLowerCase();

    if (AMPM == "pm" && hours < 12) hours = hours + 12;
    if (AMPM == "am" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    return sHours + ':' + sMinutes;
  }


  //addAnotherItem() {
  //  if (this.eventTime.length > 0) {
  //    let theEventTime: EventTime[] = [];
  //    for (var i = 0; i < this.eventTime.length; i++) {
  //      if (this.eventTime[i].isActive == true) {
  //        theEventTime.push(this.eventTime[i]);
  //      }
  //    }
  //    if (theEventTime[theEventTime.length - 1].eventEndTimeRange != null && theEventTime[theEventTime.length - 1].eventTimeRange != null && this.hint2 == '') {
  //      var time1 = this.initializetime1();
  //      this.eventTime.push(time1);
  //      this.timeerror = "";
  //    } else if (this.hint2 != '') {
  //      this.timeerror = "Please Correct the End Time to add another item ! !";
  //    }
  //    else {
  //      this.timeerror = "Please Fill the above field to add another item !";
  //    }
  //  }
  //  else {
  //    var time1 = this.initializetime1();
  //    this.eventTime.push(time1);
  //  }
  //  return false;
  //  //var time1 = this.initializetime1();
  //  //this.eventTime.push(time1);
  //  //return false;
  //}


  //deleteTime(Id: number) {
  //  const index = Id;
  //  //  const index = this.eventTime.findIndex(x => x.eventTimeId == Id);
  //  this.totalActiveItems = 0;
  //  for (let i = 0; i < this.eventTime.length; i++) {
  //    if (this.eventTime[i].isActive == true) {
  //      this.totalActiveItems = this.totalActiveItems + 1;
  //    }
  //  }
  //  if (index > 0) {
  //    this.eventTime[index].isActive = false;
  //  }
  //  else {
  //    this.eventTime[index].eventTimeRange = null;
  //    this.eventTime[index].eventEndTimeRange = null;
  //  }
  //  this.timeerror = "";
  //  return false;
  //}



  timevent(event: string) {
    this.getTotalhour(); 
    if (this.totalhoursstr.length > 5) {
      this.hint2 = "End Time Should be greater than Start Time"
      this.totalhrscheck = false;
    } else {
      this.hint2 = "";
      this.totalhrscheck = true;
    }

    //this.hint2 = "";
    //this.getTotalhour();
    //this.fromTimeStr = event;
    ////if (this.eventCalendarModel.toDate != null || this.eventCalendarModel.toDate != undefined) {
    ////  this.eventCalendarModel.toDate = this.eventCalendarModel.toDate;
    ////} else {
    ////  this.eventCalendarModel.toDate = this.eventCalendarModel.fromDate;
    ////}

    ////var d = new Date(this.eventCalendarModel.fromDate),
    //var d = new Date(),
    //  s = this.fromTimeStr,
    //  parts = s.match(/(\d+)\:(\d+) (\w+)/),
    //  hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
    //  minutes = parseInt(parts[2], 10);

    //d.setHours(hours);
    //d.setMinutes(minutes);
    //var d2 = new Date(),
    ////var d2 = new Date(this.eventCalendarModel.toDate),
    //  s1 = this.toTimeStr,
    //  parts = s1.match(/(\d+)\:(\d+) (\w+)/),
    //  hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
    //  minutes = parseInt(parts[2], 10);

    //d2.setHours(hours);
    //d2.setMinutes(minutes);
    //if (d <= d2) {
    //  this.hint2 = "";
    //  this.getTotalhour();
    //  //alert('true');
    //}
    //else {
    //  this.hint2 = "End Time should be greater than Start Time";
    //  this.totalhoursstr = "N/A";
    //}
    ////alert('false');

  /************************************/
    //this.timeerror = "";
    //var hoursMinutes = event.split(/[.: ]/);
    //var hours = parseInt(hoursMinutes[0], 10);
    //var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //this.zone = hoursMinutes[2].toString();
    //this.fromTime = hours + minutes / 60;
    
    //this.toTime = Math.round(this.toTime);
    //var fromRoundTime = this.fromTime.toFixed(2);
    //this.fromTime = parseInt(fromRoundTime);

    //if (this.zone1 == "AM" && this.toTime == 12) {
    //  if (this.zone == this.zone1 && this.fromTime < this.toTime) {
    //    this.hint2 = "End Time Should be greater than Start Time";
    //  }
    //} else {
    //  if (this.zone == this.zone1 && this.fromTime > this.toTime) {
    //    if (this.zone == "PM" && this.zone1 == "PM" && this.fromTime == 12) {
    //      this.hint2 = "";
    //    } else if (this.zone == "AM" && this.zone1 == "AM" && this.fromTime == 12 && this.toTime < 12) {
    //      this.hint2 = "";
    //    }
    //    else {
    //      this.hint2 = "End Time Should be greater than Start Time";
    //    }
    //  } else if (this.zone != this.zone1 && this.fromTime > this.toTime) {
    //    this.hint2 = "";
    //  } else if (this.zone == this.zone1 && this.fromTime < this.toTime) {
    //    this.hint2 = "";
    //  } else if (this.zone != this.zone1 && this.fromTime > this.toTime) {
    //    this.hint2 = "";
    //  } else if (this.zone == "AM" && this.zone1 == "PM" && this.toTime == 12 && this.fromTime < this.toTime) {
    //    this.hint2 = "";
    //  }
    //}

    //if (this.zone == 'AM' && this.zone1 == 'AM') {
    //  this.thToTime = this.toTime;
    //} else if (this.zone == 'AM' && this.zone1 == 'PM') {
    //  this.thToTime = this.toTime + 12;
    //}
    //if (this.zone1 == "PM" && this.toTime == 12) {
    //  if (this.zone == "AM" && this.zone1 == "PM" && this.toTime == 12 && this.fromTime < this.toTime) {
    //    this.totalhours = this.toTime - this.fromTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  } else if (this.zone == "AM" && this.zone1 == "PM" && this.toTime == 12 && this.fromTime == 12) {
    //    this.totalhours = 12;
    //    this.totalhours = Math.round(this.totalhours);
    //  } else {
    //    this.totalhours = this.fromTime - this.toTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  }
    //} else if (this.zone == "AM" && this.fromTime == 12) {
    //  if (this.zone1 == 'AM') {
    //    this.totalhours = this.toTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  } else if (this.zone1 == 'PM') {
    //    this.totalhours = this.thToTime - (this.fromTime + 12);
    //    this.totalhours = Math.round(this.totalhours);
    //  } else {
    //    this.totalhours = this.thToTime - this.fromTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  }
    //} else if (this.zone == "PM" && this.fromTime == 12) {
    //  this.totalhours = this.toTime;
    //  this.totalhours = Math.round(this.totalhours);
    //} else {
    //  this.totalhours = this.thToTime - this.fromTime;
    //  this.totalhours = Math.round(this.totalhours);
    //}
    

  }


  timevent1(event1: string) {
    this.getTotalhour();
    if (this.totalhoursstr.length > 5) {
      this.hint2 = "End Time Should be greater than Start Time"
      this.totalhrscheck = false;     
    } else {
      this.hint2 = "";
      this.totalhrscheck = true;
    }
    //this.hint2 = "";
    //this.getTotalhour();
    ////if (this.eventCalendarModel.toDate != null || this.eventCalendarModel.toDate != undefined) {
    ////  this.eventCalendarModel.toDate = this.eventCalendarModel.toDate;
    ////} else {
    ////  this.eventCalendarModel.toDate = this.eventCalendarModel.fromDate;
    ////}
    //this.toTimeStr = event1;
    //var d = new Date(),
    ////var d = new Date(this.eventCalendarModel.fromDate),
    //  s = this.fromTimeStr,
    //  parts = s.match(/(\d+)\:(\d+) (\w+)/),
    //  hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
    //  minutes = parseInt(parts[2], 10);
    //d.setHours(hours);
    //d.setMinutes(minutes);


    //var d2 = new Date(),
    ////var d2 = new Date(this.eventCalendarModel.toDate),
    //  s1 = this.toTimeStr,
    //  parts = s1.match(/(\d+)\:(\d+) (\w+)/),
    //  hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
    //  minutes = parseInt(parts[2], 10);
    //d2.setHours(hours);
    //d2.setMinutes(minutes);

    //if (d <= d2) {
    //  this.hint2 = "";
    //  this.getTotalhour();
    //  //alert('true');
    //}
    //else {
    //  this.hint2 = "End Time should be greater than Start Time";
    //  this.totalhoursstr = "N/A";
    //}
    
    ////alert('false');


  /************************************/

    //this.timeerror = "";
    //var hoursMinutes = event1.split(/[.: ]/);
    //var hours = parseInt(hoursMinutes[0], 10);
    //var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //this.zone1 = hoursMinutes[2].toString();
    //this.toTime = hours + minutes / 60;
    ////  this.toTime - this.toTime.toPrecision(3);
    //// this.toTime = Math.round(this.toTime);
    ////if (12.1 <= this.toTime && this.toTime >=12.9) {
    ////  this.toTime = 1;
    ////}    
    //// var fromRoundTime = this.fromTime.toFixed(2);
    //this.fromTime = Math.round(this.fromTime);
    ////this.fromTime = parseInt(fromRoundTime);
    //var toRoundTime = this.toTime.toFixed(2);
    //this.toTime = parseInt(toRoundTime);

    //if (this.zone1 == "AM" && this.toTime == 12) {
    //  if (this.zone == this.zone1 && this.fromTime < this.toTime) {
    //    this.hint2 = "End Time Should be greater than Start Time";
    //  }
    //} else {
    //  if (this.zone == this.zone1 && this.fromTime > this.toTime) {
    //    if (this.zone == "PM" && this.zone1 == "PM" && this.fromTime == 12) {
    //      this.hint2 = "";
    //    } else if (this.zone == "AM" && this.zone1 == "AM" && this.fromTime == 12 && this.toTime < 12) {
    //      this.hint2 = "";
    //    }
    //    else {
    //      this.hint2 = "End Time Should be greater than Start Time";
    //    }
    //  } else if (this.zone != this.zone1 && this.fromTime > this.toTime) {
    //    this.hint2 = "";
    //  } else if (this.zone == this.zone1 && this.fromTime < this.toTime) {
    //    this.hint2 = "";
    //  } else if (this.zone != this.zone1 && this.fromTime > this.toTime) {
    //    this.hint2 = "";
    //  } else if (this.zone == "AM" && this.zone1 == "PM" && this.toTime == 12 && this.fromTime < this.toTime) {
    //    this.hint2 = "";
    //  }
    //}

    //if (this.zone == 'AM' && this.zone1 == 'AM') {
    //  this.thToTime = this.toTime;
    //} else if (this.zone == 'AM' && this.zone1 == 'PM') {
    //  this.thToTime = this.toTime + 12;
    //}
    //if (this.zone1 == "PM" && this.toTime == 12) {
    //  if (this.zone == "AM" && this.zone1 == "PM" && this.toTime == 12 && this.fromTime < this.toTime) {
    //    this.totalhours = this.toTime - this.fromTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  } else if (this.zone == "AM" && this.zone1 == "PM" && this.toTime == 12 && this.fromTime == 12) {
    //    this.totalhours = 12;
    //    this.totalhours = Math.round(this.totalhours);
    //  } else {
    //    this.totalhours = this.fromTime - this.toTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  }
    //} else if (this.zone == "AM" && this.fromTime == 12) {
    //  if (this.zone1 == 'AM') {
    //    this.totalhours = this.toTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  }
    //  else if (this.zone1 == 'PM') {
    //    this.totalhours = this.thToTime - (this.fromTime + 12);
    //    this.totalhours = Math.round(this.totalhours);
    //  } else {
    //    this.totalhours = this.thToTime - this.fromTime;
    //    this.totalhours = Math.round(this.totalhours);
    //  }
    //} else if (this.zone == "PM" && this.fromTime == 12) {
    //  this.totalhours = this.toTime;
    //  this.totalhours = Math.round(this.totalhours);
    //} else {
    //  this.totalhours = this.thToTime - this.fromTime;
    //  this.totalhours = Math.round(this.totalhours);
    //}
    
  }


  Excludedateevent(excludeDate: Date) {
    this.dateerror = "";
  }

  Includedateevent(includeDate: Date) {
    this.date1error = "";
  }


  dateevent1(fromDate: Date) {
    //this.dropDownHint = "";
    //this.recurrenceHint1 = "";
    //this.datehint = "";
    //this.hint2 = "";
    this.eventCalendarModel.recurrenceFromDate = fromDate;
    this.fromDate = fromDate;
    if (this.eventCalendarId > 0) {
      this.toDate = this.eventCalendarModel.toDate;
    }
    this.EndDateDisable = true;
    this.datehint = "";
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    if (this.eventCalendarModel.endDateCheck==false) {
      this.datehint = "";
    }
  }

  dateevent2(toDateev: Date) {
    this.toDate = toDateev;
    if (this.eventCalendarId > 0) {
      this.fromDate = this.eventCalendarModel.fromDate;
    }
    if ((new Date(this.toDate).getTime()) < (new Date(this.fromDate).getTime())) {
      this.datehint = "End Date Should Greater than or equal to Start Date";
    } else if ((new Date(this.toDate).getTime()) > (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
    else if ((new Date(this.toDate).getTime()) == (new Date(this.fromDate).getTime())) {
      this.datehint = "";
    }
  }
  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null;
    }
  }

  public updateEventDpt() {
    this.blockUI.start();
    this.disableSave = true;
    this.eventCalendarModel.eventDate = this.eventDateExcludes;
    this.eventCalendarModel.eventInclude = this.eventDateIncludes;
    this.eventCalendarModel.eventCalendarId = this.eventCalendarId;

    this.eventCalendarModel.byDayMData = this.byDayMData1;
    this.eventCalendarModel.byDayM1Data = this.byDayM1Data1;
    this.eventCalendarModel.byDayYData = this.byDayYData1;
    this.eventCalendarModel.byDayY1Data = this.byDayY1Data1;


    for (let i = 0; i < this.eventDateExcludes.length; i++) {
      if (this.eventDateExcludes[i].excludeDate) {
        this.eventDateExcludes[i].excludeDatestr = this.getDateAsString(new Date(this.eventDateExcludes[i].excludeDate));
      }
      else {
        this.eventDateExcludes[i].excludeDatestr = "01/01/1900";
      }
    }
    for (let i = 0; i < this.eventDateIncludes.length; i++) {
      if (this.eventDateIncludes[i].includeDate) {
        this.eventDateIncludes[i].includeDatestr = this.getDateAsString(new Date(this.eventDateIncludes[i].includeDate));
      }
      else {
        this.eventDateIncludes[i].includeDatestr = "01/01/1900";
      }
    }

    this.eventCalendarModel.fromDatestr = (this.eventCalendarModel.fromDate) ? this.getDateAsString(new Date(this.eventCalendarModel.fromDate)) : "01/01/1900";
    this.eventCalendarModel.toDatestr = (this.eventCalendarModel.toDate) ? this.getDateAsString(new Date(this.eventCalendarModel.toDate)) : "01/01/1900";
    this.eventCalendarModel.recurrenceFromDatestr = (this.eventCalendarModel.recurrenceFromDate) ? this.getDateAsString(new Date(this.eventCalendarModel.recurrenceFromDate)) : "01/01/1900";
    this.eventCalendarModel.recurrenceToDatestr = (this.eventCalendarModel.recurrenceToDate) ? this.getDateAsString(new Date(this.eventCalendarModel.recurrenceToDate)) : "01/01/1900";


    if (this.eventCalendarModel.periodical != 1) {
      this.eventCalendarModel.dailyOption = 0;
    }

    if (this.eventCalendarModel.eventDate.length == 1 && this.eventCalendarModel.eventDate[0].excludeDate == null) {
      this.eventCalendarModel.eventDate = [];
    }
    if (this.eventCalendarModel.eventInclude.length == 1 && this.eventCalendarModel.eventInclude[0].includeDate == null) {
      this.eventCalendarModel.eventInclude = [];
    }

    if (this.eventCalendarModel.byDayMData == null) {
      this.eventCalendarModel.byDayMData = [];
    }
    if (this.eventCalendarModel.byDayM1Data == null) {
      this.eventCalendarModel.byDayM1Data = [];
    }
    if (this.eventCalendarModel.byDayYData == null) {
      this.eventCalendarModel.byDayYData = [];
    }
    if (this.eventCalendarModel.byDayY1Data == null) {
      this.eventCalendarModel.byDayY1Data = [];
    }
    this.eventCalendarModel.eventTitle = this.eventCalendarModel.eventTitle.charAt(0).toUpperCase() + this.eventCalendarModel.eventTitle.slice(1);

    if (this.eventCalendarModel.description == null) {
      this.eventCalendarModel.description = '';
    }
    if (this.rangeRadioCheck1) {
        this.eventCalendarModel.rangeOccurrenceCount = null;
    }
    else {
      this.eventCalendarModel.recurrenceToDatestr = "01/01/1900";
      this.eventCalendarModel.recurrenceToDate = null;
    }

    if (this.eventCalendarId > 0) {
      this.eventDptService.updateEventDpt(this.eventCalendarId, this.eventCalendarModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            this.eventCalendarId = 0;
            this.GoBack();            
            //this.routerService.navigate(['/eventscreen']);
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          //this.disableSave = false;
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
            
          });
        }
      });
    }
    else {
      this.eventDptService.saveEventDpt('saveEventDpt', this.eventCalendarModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });

          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
            this.resetTheForm();
          });
        }
        else if (this.errordialog == true) {
          this.blockUI.stop();
         // this.disableSave = false;
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
            
          });
        }
      });
    }
    this.blockUI.stop();
  }



  resetTheForm() {
    this.eventDptForm.resetForm();
    this.eventCalendarModel = this.initializeeventCalendarModell();
    var date1 = this.initializedate1();
    var date2 = this.initializedate2();
    // var time1 = this.initializetime1();
    this.defaultfields1();
    this.defaultfields2();
    this.eventDateExcludes = [];
    this.eventDateIncludes = [];
    //  this.eventTime = [];

    this.eventDateExcludes.push(date1);
    this.eventDateIncludes.push(date2);
    // this.eventTime.push(time1);
    this.eventCalendarModel.periodical = this.periodicalsKey[0].key;

    this.showRepeat = false;
    this.showExclude = false;
    this.showInclude = false;
    this.showdiv = false;
    this.disableSave = false;
  }

  resetEvent() {
    this.resetTheForm();
  }

  clearcheckAndRadio() {
    this.ByDayDisable = false;
    this.ByDayCheckDisable = false;
    this.ByDateDisable = false;
    this.ByDateCheckDisable = false;
    // this.eventCalendarModel.byDayOn = null;
    // this.eventCalendarModel.byDay = null;
    // this.eventCalendarModel.byMonthDay = null;
    this.radiocheck2 = false;
    this.radiocheck1 = false;
  }

  onDDMChange(event: number) {
    this.eventCalendarModel.byMonthDayM = event;
    if (event > 0) {
      if (this.byDayMData1.length > 0) {
        this.dropDownHint = "";
      }
      this.monthlydd2Disable = false;
    } else if (event == undefined) {
      this.dropDownHint = "Fill the entire month pattern";
    } else {
      this.monthlydd2Disable = true;
    }
  }

  onDDM1Change(event1: any) {
    this.byDayMData1 = event1;
    if (this.byDayMData1.length > 0) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire month pattern";
    }
  }
  onDDM2Change(event2: any) {
    this.eventCalendarModel.byDayOnM1 = event2;
    if (event2 > 0) {
      if (this.eventCalendarModel.yearlyMonthM1 > 0 && this.byDayM1Data1.length > 0) {
        this.dropDownHint = "";
      }
      this.monthlydd4Disable = false;
    } else if (event2 == undefined) {
      this.dropDownHint = "Fill the entire month pattern";
    } else {
      this.monthlydd4Disable = true;
    }
  }
  onDDM3Change(event3: any) {
    this.eventCalendarModel.yearlyMonthM1 = event3;
    if (event3 > 0) {
      if (this.eventCalendarModel.byDayOnM1 > 0 && this.byDayM1Data1.length > 0) {
        this.dropDownHint = "";
      }
      this.monthlydd5Disable = false;
    } else if (event3 == undefined) {
      this.dropDownHint = "Fill the entire month pattern";
    } else {
      this.monthlydd5Disable = true;
    }
  }
  onDDM4Change(event4: any) {
    this.byDayM1Data1 = event4;
    if (this.byDayM1Data1.length > 0) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire month pattern";
    }
  }

  onDDYChange(event5: any) {
    this.eventCalendarModel.byMonthDayY = event5;
    if (event5 > 0) {
      if (this.byDayYData1.length > 0) {
        this.dropDownHint = "";
      }
      this.yearlydd2Disable = false;
    } else if (event5 == undefined) {
      this.dropDownHint = "Fill the entire year pattern";
    } else {
      this.yearlydd2Disable = true;
    }
  }

  onDDY1Change(event6: any) {
    this.byDayYData1 = event6;
    if (this.byDayYData1.length > 0) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire year pattern";
    }
  }

  onDDY2Change(DayOnEvent: any) {
    if (this.eventCalendarId > 0) {
      //this.DayOnEventVar = this.eventCalendarModel.byDayOn;
      //this.DateOnEventVar = this.eventCalendarModel.byDay;
    }
    this.DayOnEventVar = DayOnEvent;
    if (this.DayOnEventVar == undefined || this.DateOnEventVar == undefined) {
      this.ByDateCheckDisable = false;
      this.clearMonth();
    } else {
      this.ByDateCheckDisable = true;
    }
    //----------------
    this.eventCalendarModel.byDayOnY1 = DayOnEvent;
    if (DayOnEvent > 0) {
      if (this.eventCalendarModel.yearlyMonthY1 > 0 && this.byDayY1Data1.length > 0) {
        this.dropDownHint = "";
      }
      this.yearlydd4Disable = false;
    } else if (DayOnEvent == undefined) {
      this.dropDownHint = "Fill the entire year pattern";
    } else {
      this.yearlydd4Disable = true;
    }
  }

  onDDY3Change(DayEvent: number) {
    if (this.eventCalendarId > 0) {
      //this.DayOnEventVar = this.eventCalendarModel.byDayOn;
      //this.DateOnEventVar = this.eventCalendarModel.byDay;
    }
    this.DateOnEventVar = DayEvent;
    if (this.DateOnEventVar != undefined && this.DayOnEventVar != undefined) {
      this.ByDateCheckDisable = true;
    } else {
      this.ByDateCheckDisable = false;
      this.clearMonth();
    }
    //----------------
    this.eventCalendarModel.yearlyMonthY1 = DayEvent;
    if (DayEvent > 0) {
      if (this.eventCalendarModel.byDayOnY1 > 0 && this.byDayY1Data1.length > 0) {
        this.dropDownHint = "";
      }
      this.yearlydd5Disable = false;
    } else if (DayEvent == undefined) {
      this.dropDownHint = "Fill the entire year pattern";
    } else {
      this.yearlydd5Disable = true;
    }
  }
  onDDY4Change(event7: any) {
    this.byDayY1Data1 = event7;
    if (this.byDayY1Data1.length > 0) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire year pattern";
    }
  }

  ondailyoptionChange(event: any) {
    this.eventCalendarModel.dailyOption = event;
    if (event == 1 || event == undefined) {
      this.dailyCountDisable = false;
      this.dcheck = true;     
      this.dropDownHint = "";
      this.eventCalendarModel.dailyOption = 1;
      if (this.eventCalendarModel.dailyOccurenceCount == null) {
        this.eventCalendarModel.dailyOccurenceCount = 1;
      }
    } else {
      this.dailyCountDisable = true;
      this.eventCalendarModel.dailyOccurenceCount = null;
    }    
  }

  onPeriodicalChange(event: number) {
    if (event == this.periodicalsKey[1].key) {
      this.periodicalcheck = "Weeks";
      this.Repeat = true;
      this.showWeeklyDiv = true;
      this.showMonthlyDiv = false;
      this.showYearlyDiv = false;
      this.showDailyDiv = false;
      this.clearDaily();
      this.clearMonth();
      this.clearYear();
      this.clearDailyRadio();
      this.clearcheckAndRadio();
      this.eventCalendarModel.weeklyOccurrenceCount = 1;
      this.dropDownHint = "Fill the entire Week pattern";
    }
    else if (event == this.periodicalsKey[2].key) {
      this.periodicalcheck = "Months";
      this.Repeat = true;
      this.showMonthlyDiv = true;
      this.showYearlyDiv = false;
      this.showWeeklyDiv = false;
      this.showDailyDiv = false;
      this.clearDaily();
      this.clearWeek();
      this.clearYear();
      this.clearMonth();
      this.clearDailyRadio();
      this.clearcheckAndRadio();
      this.dropDownHint = "Fill the entire month pattern";
      this.monthlydd2Disable = true;
      this.monthlydd1Disable = false;
      // this.monthlydd2Disable = false;
      this.monthlydd3Disable = true;
      this.monthlydd4Disable = true;
      this.monthlydd5Disable = true;
      this.monthlyradiocheck1 = true;
    }
    else if (event == this.periodicalsKey[3].key) {
      this.periodicalcheck = "Years";
      this.Repeat = true;
      this.showYearlyDiv = true;
      this.showMonthlyDiv = false;
      this.showWeeklyDiv = false;
      this.showDailyDiv = false;
      this.clearDaily();
      this.clearWeek();
      this.clearMonth();
      this.clearDailyRadio();
      this.clearcheckAndRadio();
      this.dropDownHint = "Fill the entire Year pattern";
      this.yearlyradiocheck1 = true;
      this.eventCalendarModel.byDayOnY1 = null;
      this.eventCalendarModel.yearlyMonthY1 = null;
      this.yearlydd1Disable = false;
      //this.yearlydd2Disable = false;
      this.yearlydd3Disable = true;
      this.yearlydd4Disable = true;
      this.yearlydd5Disable = true;
    }
    else if (event == this.periodicalsKey[0].key) {
      this.showDailyDiv = true;
      this.clearDailyRadio();
      this.dropDownHint = "";      
      this.periodicalcheck = "Single Day";
      this.Repeat = false;
      this.showWeeklyDiv = false;
      this.showMonthlyDiv = false;
      this.showYearlyDiv = false;
      this.clearMonth();
      this.clearWeek();
      this.clearYear();
      this.clearcheckAndRadio();

      if (this.eventCalendarModel.dailyOption == 0) { //if nothing is selected then set first option
        this.eventCalendarModel.dailyOption = 1;
        this.eventCalendarModel.dailyOccurenceCount = 1;
      }
      if (this.eventCalendarModel.dailyOption == 1) {
        if (this.eventCalendarModel.dailyOccurenceCount == null) {
          this.eventCalendarModel.dailyOccurenceCount = 1;
        }
      }
    }
  }
  //Dailyoccurrenceclick() {
  //  this.eventCalendarModel.dailyOccurenceCount = 1;
  //}
  //onMonthDayChange(MonthDayevent: number) {
  //  if (MonthDayevent != undefined) {
  //    this.ByDayCheckDisable = true;
  //  } else {
  //    this.ByDayCheckDisable = false;
  //    // this.clearModelMonth();
  //  }
  //}



  RadioByDate() {
    this.ByDateDisable = true;
    this.ByDayDisable = false;
    this.ByDayCheckDisable = false;
    //this.clearModelMonth();
    //this.eventCalendarModel.byMonthDay = null;
  }

  RadioByDay() {
    this.ByDayDisable = true;
    this.ByDateDisable = false;
    this.ByDateCheckDisable = false;
    this.clearMonth();
    // this.eventCalendarModel.byDayOn = null;
    // this.eventCalendarModel.byDay = null;
  }

  RadioOccurences() {
    //this.eventCalendarModel.repeatDate = null;
    this.AfterDateDisable = false;
    this.OccurenceDisable = true;
  }

  RadioDate() {
    // this.eventCalendarModel.occurences = null;
    this.AfterDateDisable = true;
    this.OccurenceDisable = false;
  }

  RangeRadio1() {
   // this.recurrenceHint1 = "Event End Range is Required";
    this.rangeRadioCheck1 = true;
    this.rangeRadioCheck2 = false;
    this.rangeOccurreneceDisable = true;
    this.recurrenceToDateDisable = false;
    //this.eventCalendarModel.rangeOccurrenceCount = null;
  }

  RangeRadio2() {
   // this.recurrenceHint1 = "";
    //this.eventCalendarModel.rangeOccurrenceCount = 1;
    //this.eventCalendarModel.recurrenceToDate = null;
    this.rangeRadioCheck2 = true;
    this.rangeRadioCheck1 = false;
    this.recurrenceToDateDisable = true;
    this.rangeOccurreneceDisable = false;
  }


  DailyRadio1() {
    this.dailyCountDisable = false;
    //this.eventCalendarModel.dailyradio1 = true;
    //this.eventCalendarModel.dailyradio2 = false;
    //this.eventCalendarModel.dailyradio3 = false;
    //this.eventCalendarModel.dailyradio4 = false;
    //this.dailyradiocheck1 = true;
    this.dailyradiocheck2 = false;
    this.dailyradiocheck3 = false;
    this.dailyradiocheck4 = false;
    this.dailyCountDisable = false;
  }
  DailyRadio2() {
    //this.eventCalendarModel.dailyradio1 = false;
    //this.eventCalendarModel.dailyradio2 = true;
    //this.eventCalendarModel.dailyradio3 = false;
    //this.eventCalendarModel.dailyradio4 = false;
    //this.dailyradiocheck2 = true;
    this.dailyradiocheck1 = false;
    this.dailyradiocheck3 = false;
    this.dailyradiocheck4 = false;
    this.dailyCountDisable = true;
    this.eventCalendarModel.dailyOccurenceCount = null;
  }
  DailyRadio3() {
    //this.eventCalendarModel.dailyradio1 = false;
    //this.eventCalendarModel.dailyradio2 = false;
    //this.eventCalendarModel.dailyradio3 = true;
    //this.eventCalendarModel.dailyradio4 = false;
    //this.dailyradiocheck3 = true;
    this.dailyradiocheck2 = false;
    this.dailyradiocheck1 = false;
    this.dailyradiocheck4 = false;
    this.dailyCountDisable = true;
    this.eventCalendarModel.dailyOccurenceCount = null;
  }
  DailyRadio4() {
    //this.eventCalendarModel.dailyradio1 = false;
    //this.eventCalendarModel.dailyradio2 = false;
    //this.eventCalendarModel.dailyradio3 = false;
    //this.eventCalendarModel.dailyradio4 = true;
    //this.dailyradiocheck4 = true;
    this.dailyradiocheck2 = false;
    this.dailyradiocheck3 = false;
    this.dailyradiocheck1 = false;
    this.dailyCountDisable = true;
    this.eventCalendarModel.dailyOccurenceCount = null;
  } k
  MonthlyRadio1() {
    this.monthlyradiocheck1 = true;
    this.monthlyradiocheck2 = false;
    this.eventCalendarModel.byDayOnM1 = null;
    this.byDayM1Data1 = [];
    this.eventCalendarModel.yearlyMonthM1 = null;
    this.monthlydd1Disable = false;
    //this.monthlydd2Disable = false;
    this.monthlydd3Disable = true;
    this.monthlydd4Disable = true;
    this.monthlydd5Disable = true;
    this.dropDownHint = "Fill the entire month pattern";
  }

  MonthlyRadio2() {
    this.monthlyradiocheck2 = true;
    this.monthlyradiocheck1 = false;
    this.eventCalendarModel.byMonthDayM = null;
    this.byDayMData1 = [];
    this.monthlydd1Disable = true;
    this.monthlydd2Disable = true;
    this.monthlydd3Disable = false;
    this.dropDownHint = "Fill the entire month pattern";
    // this.monthlydd4Disable = false;
    // this.monthlydd5Disable = false;
  }

  YearlyRadio1() {
    this.eventCalendarModel.byDayOnY1 = null;
    this.byDayY1Data1 = [];
    this.eventCalendarModel.yearlyMonthY1 = null;
    this.yearlyradiocheck2 = false;
    this.yearlydd1Disable = false;
    //this.yearlydd2Disable = false;
    this.yearlydd3Disable = true;
    this.yearlydd4Disable = true;
    this.yearlydd5Disable = true;
    this.dropDownHint = "Fill the entire year pattern";
  }

  YearlyRadio2() {
    this.byDayYData1 = [];
    this.eventCalendarModel.byMonthDayY = null;
    this.yearlyradiocheck1 = false;
    this.yearlydd1Disable = true;
    this.yearlydd2Disable = true;
    this.yearlydd3Disable = false;
    //this.yearlydd4Disable = false;
    //this.yearlydd5Disable = false;
    this.dropDownHint = "Fill the entire year pattern";
  }

  clearDailyRadio() {
    this.dailyradiocheck1 = true;
    this.dailyradiocheck2 = true;
    this.dailyradiocheck3 = true;
    this.dailyradiocheck4 = true;
  }

  GoBack() {
    this.dailyCountDisable = true;
    this.routerService.navigate(['/eventreport']);
    //this.routerService.navigate(['/home']);
  }

  enddateevent(fromDate: Date) {
    this.recurrenceHint1 = "";
    this.eventCalendarModel.rangeOccurrenceCount = null;
  }

  weekCheck1(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.monday == true || this.eventCalendarModel.tuesday == true || this.eventCalendarModel.wednesday == true || this.eventCalendarModel.thursday == true
      || this.eventCalendarModel.friday == true || this.eventCalendarModel.saturday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }
  weekCheck2(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.sunday == true || this.eventCalendarModel.tuesday == true || this.eventCalendarModel.wednesday == true || this.eventCalendarModel.thursday == true
      || this.eventCalendarModel.friday == true || this.eventCalendarModel.saturday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }
  weekCheck3(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.monday == true || this.eventCalendarModel.sunday == true || this.eventCalendarModel.wednesday == true || this.eventCalendarModel.thursday == true
      || this.eventCalendarModel.friday == true || this.eventCalendarModel.saturday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }
  weekCheck4(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.monday == true || this.eventCalendarModel.tuesday == true || this.eventCalendarModel.sunday == true || this.eventCalendarModel.thursday == true
      || this.eventCalendarModel.friday == true || this.eventCalendarModel.saturday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }
  weekCheck5(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.monday == true || this.eventCalendarModel.tuesday == true || this.eventCalendarModel.wednesday == true || this.eventCalendarModel.sunday == true
      || this.eventCalendarModel.friday == true || this.eventCalendarModel.saturday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }
  weekCheck6(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.monday == true || this.eventCalendarModel.tuesday == true || this.eventCalendarModel.wednesday == true || this.eventCalendarModel.thursday == true
      || this.eventCalendarModel.sunday == true || this.eventCalendarModel.saturday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }
  weekCheck7(e: any) {
    if (e.target.checked == true || this.eventCalendarModel.monday == true || this.eventCalendarModel.tuesday == true || this.eventCalendarModel.wednesday == true || this.eventCalendarModel.thursday == true
      || this.eventCalendarModel.friday == true || this.eventCalendarModel.sunday == true) {
      this.dropDownHint = "";
    } else {
      this.dropDownHint = "Fill the entire Week pattern";
    }
  }

  recurrencecountchange(event: number) {
    //if (event > 0) {
    //  this.dropDownHint = "";
    //} else {
    //  this.dropDownHint = "Fill the week ocurrence ";
    //}
  }

  recurrencecountchange1(event1: number) {
    this.eventCalendarModel.dailyOccurenceCount = event1;
    this.dcheck = true;
    this.eventCalendarModel.dailyOption = 1;
    // this.dcheck = true;
    //alert("input");
    // this.eventCalendarModel.dailyOption = this.dailyoption1;
    //if (event1 > 0) {
    //  this.dropDownHint = "";
    //} else {
    //  this.dropDownHint = "Fill the week ocurrence ";
    //}
  }
  recurrencecountchange2(event2: number) {
    //alert("keypress");
    if (event2 > 0) {
      this.dropDownHint = "";
    }
    else {      
      this.dropDownHint = "Minimum 1 Occurrence required";
    }
  }
  weeklyrecurrencecountchange2(eventcount: number) {
    //alert("keypress");
    if (eventcount > 0) {
      this.dropDownHint = "";
      //this.eventCalendarModel.weeklyOccurrenceCount = eventcount;      
    }
    else {
      this.dropDownHint = "Minimum 1 Occurrence required";
     // this.eventCalendarModel.weeklyOccurrenceCount = 1;
    }
  }
  recurrencecountchange3(event3: number) {
    //alert("keydown"+event);
    //if (event3 > 0) {
    //  this.dropDownHint = "";
    //} else {
    //  this.dropDownHint = "Fill the week ocurrence ";
    //}
  }
  recurrencecountchange4(event4: number) {
    //// alert("change" + event);
    //if (event4 > 0) {
    //  this.dropDownHint = "";
    //} else {
    //  this.dropDownHint = "Fill the week ocurrence ";
    //}
  }

  recurrenceevent(event: number) {
    if (event > 0) {
      this.recurrenceHint1 = "";
    } else {
      this.recurrenceHint1 = "Event End Range is Required";
    }
  }

  recurrenceevent1(event: number) {
    if (event > 0) {
      this.recurrenceHint1 = "";
    } else {
      this.recurrenceHint1 = "Event End Range is Required";
    }
  }
  recurrenceevent2(event: number) {
    //alert("keypress");
    if (event > 0) {
      this.recurrenceHint1 = "";
     // this.eventCalendarModel.rangeOccurrenceCount = event;      
    }
    else {
      this.recurrenceHint1 = "Minimum 1 Occurrence required";
      //this.eventCalendarModel.rangeOccurrenceCount = 1;
    }
    //if (event > 0) {
    //  this.recurrenceHint1 = "";
    //} else {
    //  this.recurrenceHint1 = "Event End Range is Required";
    //}
  }
  recurrenceevent3(event: number) {
    // alert("keydown"+event);
    if (event > 0) {
      this.recurrenceHint1 = "";
    } else {
      this.recurrenceHint1 = "Event End Range is Required";
    }
  }
  recurrenceevent4(event: number) {
    // alert("change" + event);
    if (event > 0) {
      this.recurrenceHint1 = "";
    } else {
      this.recurrenceHint1 = "Event End Range is Required";
    }
  }

  show1() {
    //this.recurrenceCheck = true;
    if (this.showRepeat == true) {
      this.showRepeat = false;
    }
    else {
      this.showRepeat = true;
    }
    if (this.eventCalendarId > 0 && (this.eventCalendarModel.rangeOccurrenceCount > 0 || this.eventCalendarModel.recurrenceToDate != null)) {
      //this.recurrenceHint1 = "";
      this.recurrenceHint1 = "";
    } else {
      if (this.eventCalendarModel.repeatCheck == false) {
        this.recurrenceHint1 = "Event End Range is Required";
      } else {
        this.recurrenceHint1 = "";
        this.eventCalendarModel.dailyOption = null;
        this.eventCalendarModel.weeklyOccurrenceCount = null;
        this.eventCalendarModel.sunday = false;
        this.eventCalendarModel.monday = false;
        this.eventCalendarModel.tuesday = false;
        this.eventCalendarModel.wednesday = false;
        this.eventCalendarModel.thursday = false;
        this.eventCalendarModel.friday = false;
        this.eventCalendarModel.saturday = false;
        this.eventCalendarModel.byMonthDayM = null;
        this.byDayMData1 = [];
        this.eventCalendarModel.byDayOnM1 = null;
        this.eventCalendarModel.yearlyMonthM1 = null;
        this.byDayM1Data1 = [];
        this.eventCalendarModel.byMonthDayY = null;
        this.byDayYData1 = []
        this.eventCalendarModel.byDayOnY1 = null;
        this.eventCalendarModel.yearlyMonthY1 = null;
        this.byDayY1Data1 = [];
        this.eventCalendarModel.excludeCheck = true;
        this.eventDateExcludes = [];
        this.eventCalendarModel.includeCheck = true;
        this.eventDateIncludes = [];
        var date1 = this.initializedate1();
        var date2 = this.initializedate2();
        this.eventDateExcludes.push(date1);
        this.eventDateIncludes.push(date2);
        this.eventDateExcludes.pop();
        this.eventDateIncludes.pop();
        //this.eventCalendarModel.recurrenceFromDate = null;
        this.eventCalendarModel.recurrenceToDate = null;
        this.eventCalendarModel.rangeOccurrenceCount = null;
        this.defaultfields2();
      }
    }

  }
  show3() {
    this.dateerror = "";
    if (this.showExclude == true) {
      this.showExclude = false;
    }
    else {
      this.showExclude = true;
    }
  }
  show4() {
    this.date1error = "";
    if (this.showInclude == true) {
      this.showInclude = false;
    }
    else {
      this.showInclude = true;
    }
  }

  show() {
    this.datehint = "";
    if (this.showdiv == true) {
      this.showdiv = false;
      this.eventCalendarModel.toDate = null;
    }
    else {
      this.showdiv = true;
    }
  }

  //passmodel(model:number) {
  //}

  resetDate1() {
    // this.eventCalendarModel.repeatDate = null;
  }
  resetDate2(index: number) {
    this.eventDateExcludes[index].excludeDate = null;
  }
  resetDate3(index1: number) {
    this.eventDateIncludes[index1].includeDate = null
  }

  public loadDropDowns() {
    this.blockUI.start();
    forkJoin(
      this.systemCodeService.getAllEventType(),
      this.systemCodeService.getAllRepeatsPeriod(),
      this.systemCodeService.getSystemCodesForYearlyMonthly(),
      this.systemCodeService.getSystemCodesForIYearlyMonthlyDays(),
      this.systemCodeService.getSystemCodesForEventDuration(),
      this.systemCodeService.getSystemCodesForEventMonths(),

    ).subscribe(([res1, res2, res3, res4, res5, res6]) => {
      this.eventTypesCode = res1 || [];
      this.periodicalsCode = res2 || [];
      this.periodicalsKey = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.yearlymonthlyCode = res3 || [];
      this.yearlymonthlydaysCode = res4 || [];
      this.durationCode = res5 || [];
      this.monthCode = res6 || [];

      this.eventCalendarModel.periodical = this.periodicalsCode[0].systemCodeId;
      this.showDailyDiv = true;
      this.blockUI.stop();
    });
    this.blockUI.stop();
  }


}

