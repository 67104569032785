import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  showMenu: boolean = false;
  formPermissions: Array<{ permissionFormId: number, formAction: string, formName: string }> = [];
  username: string;

  constructor(
    private headerService: HeaderService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.formPermissions = this.authService.getFormPermissions();
    this.username = this.authService.getUserName();
  }

  checkPermission(formAction: string, formName: string): boolean {
    return this.formPermissions.filter(x => x.formAction == formAction && x.formName == formName).length > 0;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  hideMenu() {
    this.showMenu = false;
  }

  showMenus() {
    this.showMenu = true;
  }

  logout(): void {
    this.authService.signOut();
  }
}
