import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { Role } from "../../models/role.model";
import { forkJoin } from "rxjs";
import { RolePermissionsService } from "../../services/rolepermissions.service";
import { RolePermission } from "../../models/role-permission.model";
import { MatDialog } from "@angular/material";
import { SaveDialogComponent } from "../../common/saved-dialog/save-dialog.component";
import { ErrorDialogComponent } from "../../common/error-dialog/error-dialog.component";
import { RolePermissionCheck } from "../../models/role-permission-check.model";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'ffd-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  errMsg: string = '';
  savedialog: boolean;
  errordialog: boolean;
  noRoles: boolean = false;
  currentSelectedRole: number = 0;
  roles: Array<Role> = [];
  rolePermissions: Array<RolePermission> = [];
  displayedColumns: string[] = ['formName', 'create', 'read', 'update', 'delete', 'print'];
  rolePermissionCheckModel: RolePermissionCheck;

  constructor(
    private userService: UserService,
    private routerService: Router,
    private rolePermissionsService: RolePermissionsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
    }
  }

  loadDropDowns(): void {
    const roles = this.userService.getRoles();
    forkJoin([roles]).subscribe((res) => {
      this.roles = res[0].map(x => { return { roleId: x.roleId, roleName: x.roleName }; }) || [];
    }, (error: any) => {
      this.roles = [];
    });
  }

  isRoleSelected(): boolean {
    return this.currentSelectedRole > 0;
  }

  setIsSelected(isChecked: boolean, rolePermissionId: number, actionName: string): void {
    let rolePermissionModel = this.rolePermissions.find(x => x.rolePermissionId == rolePermissionId);
    if (rolePermissionModel) {
      switch (actionName.toLowerCase()) {
        case 'create':
          rolePermissionModel.isCreate = isChecked;
          break;
        case 'read':
          rolePermissionModel.isRead = isChecked;
          break;
        case 'update':
          rolePermissionModel.isUpdate = isChecked;
          break;
        case 'delete':
          rolePermissionModel.isDelete = isChecked;
          break;
        case 'print':
          rolePermissionModel.isPrint = isChecked;
          break;
        default:
          break;
      }
    }
  }

  getRolePermissions(): void {
    this.resetRolePermissionsTable();
    this.rolePermissionsService.getRolePermissions(this.currentSelectedRole).subscribe((res) => {
      if (res && res.length > 0) {
        for (var i = 0; i < res.length; i++) {
          this.rolePermissions.push({
            rolePermissionId: res[i].rolePermissionId,
            isCreate: res[i].isCreate,
            isUpdate: res[i].isUpdate,
            isRead: res[i].isRead,
            isDelete: res[i].isDelete,
            isPrint: res[i].isPrint,
            formDisplayName: res[i].formDisplayName,
            permissionFormId: res[i].permissionFormId,
            roleId: res[i].roleId
          });
        }
      } else {
        this.noRoles = true;
      }
    }, (error: any) => {
        this.rolePermissions = [];
    });
  }

  resetRolePermissionsTable(): void {
    this.noRoles = false;
    this.rolePermissions = [];
    this.rolePermissions = [...this.rolePermissions];
  }

  resetForm(): void {
    this.currentSelectedRole = 0;
    this.errMsg = '';
    this.resetRolePermissionsTable();
  }

  saveRolePermissions(): void {
    this.rolePermissionsService.updateRolePermissions(this.currentSelectedRole, this.rolePermissions).subscribe((res) => {
      this.errMsg = res.msg;
      if (this.errMsg == "Updated Successfully.") {
        this.savedialog = true;
      } else {
        this.errordialog = true;
      }
      if (this.savedialog == true) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Updated the Role Permissions successfully!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getRolePermissions();
        });
      } else if (this.errordialog == true) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error occurred while updating Role Permissions. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getRolePermissions();
        });
      }
    }, (error: any) => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error occurred while updating Role Permissions. Please try again!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.resetForm();
        });
    });
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
