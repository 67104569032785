import { Component, ViewChild, OnInit } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SystemCodeService } from '../services/system-code.service';
import { AuthService } from '../services/auth.service';
import { SystemCode } from '../models/system-codes.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GearRequest } from '../models/gearrequest.model';
import { GearRequestService } from '../services/gearrequest.service';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';

@Component({
  selector: 'gearrequestform',
  templateUrl: './gearrequestform.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class GearRequestComponent implements OnInit {
  @ViewChild('gearrequestForm', { static: false }) gearrequestForm: NgForm;
  gearRequestModel: GearRequest;
  gearRequestId: number;
  crews: SystemCode[] = [];
  replaceorrepairs: SystemCode[] = [];
  geartypes: SystemCode[] = [];
  control = new FormControl();
  Employee: string[] = [];
  filteredEmployees: Observable<string[]>;
  lName: string;
  Email: string[] = [];
  employeeResutObj: Employee[] = [];
  //public filterednames: Employee[] = [];
  filterednames: Observable<Employee[]>;

  minDate = new Date();
  //maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 3, this.minDate.getDate() - 1);
  maxDate = new Date(2025, 11, 31);
  //minDate = new Date(2019, 0, 1);
  floatLabel: string = 'never';
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  disableSave: boolean = false;

  //datePattern = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
  //datePattern = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;

  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  whitespacepattern = /^[^\s]+([\s\S]+)*$/;
  rolePermissionCheckModel: RolePermissionCheck;

  // (".*\\S.*[a-zA-z0-9 ]");  --
  // /^\w+( +\w+)*$/; --
  // /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/;--

  //emailvalidate = new FormControl('', [Validators.required, Validators.email]);

  //getErrorMessage() {
  //  return this.emailvalidate.hasError('required') ? 'Email is required' :
  //    this.emailvalidate.hasError('email') ? 'Email Address is in invalid format. Ex: (example@example.com)' :
  //      '';
  //}

  constructor(
    private dialog: MatDialog,
    private routerService: Router,
    private route: ActivatedRoute,
    private gearrequestService: GearRequestService,
    private systemCodeStateService: SystemCodeService,
    private employeeService: EmployeeService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      this.gearRequestModel = this.initializeGearRequestModel();
      this.filterednames = this.control.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.lastName),
        map(lastName => lastName ? this._filter(lastName) : this.employeeResutObj.slice())
      );
    }
  }

  private _filter(lastName: string): Employee[] {
    const filterValue = lastName.toLowerCase();
    return this.employeeResutObj.filter(option => option.lastName.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(user: Employee): string {
    return user && user.lastName ? user.lastName : '';
  }


  initializeGearRequestModel(): GearRequest {
    return {
      gearRequestId: 0, requestDate: null, crew: null, lastName: "",
      replaceOrRepair: null, reason: null, gearType: null, helmetPartsCheck: false, othersCheck: false,
      othersDesc: null, specificNeed: null, email: null, comments: null, gearStatus: null
    } as GearRequest;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForCrews(),
      this.systemCodeStateService.getAllReplaceOrRepairs(),
      this.systemCodeStateService.getAllGearTypes(),
      //this.employeeService.loadEmployeeNames('getEmployeeNames'),
      this.employeeService.getAllEmployees('GetEmployees', 0)
    ).subscribe(([res1, res2, res3, res5]) => {
      this.crews = res1 || [];
      this.replaceorrepairs = res2 || [];
      this.geartypes = res3 || [];
      //this.Employee = res4;
      //this.employeeResutObj = res5;
      this.employeeResutObj = res5.filter(s => s.isActive==true);//.map(x => { return { employeeId: x.employeeId, lastName: x.lastName, email: x.email }; });
      //this.Employee = res5.map(x => { return { employeeId: x.employeeId, name: x.lastName + ' ' + x.firstName }; });
      //this.filterednames = res5.map(x => { return { employeeId: x.employeeId, name: x.lastName + ' ' + x.firstName }; });
      //this.filterednames = this.employeeResutObj.slice();
    });
  }

  onSelectionChanged(event$) {
    //this.gearRequestModel.lastName = event$.option.value.lastName;
    //this.lName = event$.option.value.lastName;
    this.gearRequestModel.email = event$.option.value.email;
  }

  onFocusOutEvent(event: any) {
    this.lName = event.target.value;
  }

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }

  public updateGearRequest() {
    this.disableSave = true;
    this.gearRequestModel.lastName = this.lName;
    this.gearRequestModel.requestDateStr = this.getDateAsString(new Date(this.gearRequestModel.requestDate));
    this.gearrequestService.saveGearRequest('SaveGearRequest', this.gearRequestModel).subscribe(res => {
      this.errMsg = res.msg;
      if (this.errMsg == "Saved Successfully.") {
        this.savedialog = true;
      }
      else {
        this.errordialog = true;
      }

      if (this.savedialog == true) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Saved Successfully!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.resetTheForm();
          //this.routerService.navigate(['/gearrequestForm']);
        });
      } else if (this.errordialog == true) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Error", description: "Error Occured. Please try again!" }
        });
        //dialogRef.afterClosed().subscribe(result => {
        //  //this.navigateToSearchUsers();
        //});
      }
    });
  }

  public resetTheForm() {
    this.gearrequestForm.resetForm();
    this.control.markAsUntouched();
    //this.control.clearValidators();
    //this.control.updateValueAndValidity();
    this.gearRequestModel = this.initializeGearRequestModel();
    this.disableSave = false;
    return false;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
