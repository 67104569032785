import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { MatDialog } from '@angular/material';
import { SystemCodeService } from '../services/system-code.service';
import { SystemCode } from '../models/system-codes.model';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { EmployeeAward } from '../models/employeeaward.model';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { Certification } from '../models/certification.model';
import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { LocationStrategy } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from "@angular/core";
import { DeleteCertificationComponent } from "./deletecertification.component";

@Component({
  selector: 'certificationpopup',
  templateUrl: './certificationPopUp.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
  host: { '(window:popstate)': 'handleBackEvents($event)' }
  //,styleUrls: ['./createemployee.component.css']
})

export class CertificationPopUpComponent implements OnInit {
  @ViewChild('certificationForm', { static: false }) certificationForm: NgForm;
  myControl = new FormControl();

  filteredOptions: Observable<Certification[]>;
  certificationModel: Certification;
  certificationEditModel: Certification;
  employeeModel: Employee;
  startdate: FormControl;
  dateOfbirth: FormControl;
  states: SystemCode[] = [];
  ranks: SystemCode[] = [];
  shifts: SystemCode[] = [];
  paygrades: SystemCode[] = [];
  awards: SystemCode[] = [];
  certifictionTypes: SystemCode[] = [];
  employeeAwards: EmployeeAward[] = [];
  employeeCertifications: Certification[] = [];
  ff: string = "";
  instructor: string = "";
  fema: string = "";
  todayDate: Date = new Date();
  certifications: string[] = [];
  certificationsIdsandName: Certification[] = [];
  control = new FormControl();
  certificationNames: Observable<string[]>;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  employeeId: number = 0;
  totalActiveItems: number = 0;
  disableExpiryDateColumn: boolean = false;
  phonePattern = /^\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$/;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  zipCodePattern = /^\d{5}(?:[-]\d{4})?$/;
  @BlockUI() blockUI: NgBlockUI;
  selectAwardMsg: string = "";
  rolePermissionCheckModel: RolePermissionCheck;
  isClicked: boolean = false;
  options: Certification[] = [];
  certificationName: string = null;
  certificationNumber: number = 0;
  certificationdataId: number;
  requestid: string;
  certifiveName: string = "";
  employeeName: string = "";
  date: Date;
  certificationNameId: number = 0;
  getcertificationName: Certification;
  blur: boolean = false;
  isFocus: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA)
    
    public data: any,
    private systemCodeStateService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private employeeService: EmployeeService,
    private activatedRoute: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>, private locationStrategy: LocationStrategy
  ) {
    //debugger;
    var dats = data;
    this.certificationdataId = data.id;
    this.preventBackButtonEvent();
    //this.dateAdapter.setLocale('en-US'); //dd/MM/yyyy
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
    this.certificationModel = this.initializeCertificationModel();
      //this.loadDropDowns();
      
      this.loadCertificatesIds();
    this.route.params.subscribe(params => {
      let Id = params['id']
      if (Id != null && Id != undefined) {
        this.certificationdataId = parseInt(Id);
      }
      let reqid = params['reqid'];
      this.requestid = reqid;
    });
    
    this.loadCertificates();
    this.loadCertificationData();
    this.loadCertificatesIds();
 
    
  }
  loadCertificationData() {

    //debugger;
    if (this.certificationdataId > 0) {
      //debugger;
      this.employeeService.getCertificationData('GetCertificationData', this.certificationdataId).subscribe(res => {
        // this.maintenenceRequestModel = res;
        //debugger;
        /*this.certifications.findIndex(x=>x.)*/
        this.certificationModel = res || [];
        if (this.certificationModel.dateOfExpiry != null) {

          var dateExpiry = new Date(this.certificationModel.dateOfExpiry);
          if (dateExpiry.getDate() == 1 && dateExpiry.getMonth() == 0 && dateExpiry.getFullYear() == 1900) {
            this.certificationModel.dateOfExpiry = null;
            this.disableExpiryDateColumn = true;
          }
        }
       // this.loadCertificates();
        this.loadCertificatesName();
       // this.certifiveName = this.certifications[this.certificationModel.certificationName];
        
        console.log(this.certifiveName);
        this.certificationDisplayName();
        this.loadEmployee1();
        
        //debugger;
        
        //certificationModel.certificationName 
      });
    }


  }
  ngOnInit() {

    this.loadCertificatesIds();
    //debugger;
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.certificationName),
        map(name => name ? this._filter(name) : this.certificationsIdsandName.slice())

      );

    //debugger;
    if (this.certificationdataId < 0) {

      this.employeeService.getCertificationData('getCertificatesIdandName', this.certificationdataId).subscribe(res => {
        // this.maintenenceRequestModel = res;
        debugger;
        this.certificationModel = res || [];
       
        

      });
      
    }



    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate) || true) {
      this.blockUI.start();
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.employeeId = parseInt(Id);
          this.loadEmployee();
        }
        else {
          this.blockUI.stop();
        }
      }, err => {
        this.blockUI.stop();
      });

      this.employeeModel = this.initializeEmployeeModel();
      
      var awardModel = this.initializeEmployeeAwardModel();
      this.employeeAwards.push(awardModel);

      //this.certificationNames = this.control.valueChanges.pipe(
      //  startWith(''),
      //  map(value => this._filter(value))
      //);
      //debugger;
      this.loadCertificatesIds();
      this.certificationDisplayName();
      //debugger;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.certificationName),
          map(name => name ? this._filter(name) : this.certificationsIdsandName.slice())
         
      );
     // this.isFocus == true;
      this.blockUI.stop();
      
      //this.blockUI.stop();
    }
  }

  certificationDisplayName() {
    let certifyObjIndex = this.certificationsIdsandName.findIndex(x => x.certificationId.toString() == this.certificationModel.certificationName);
    //debugger;
    console.log(this.certificationsIdsandName);
    if (certifyObjIndex >= 0) {
      this.myControl.setValue(this.certificationsIdsandName[certifyObjIndex]);//.certificationName;

    }
  }

  

  initializeEmployeeModel(): Employee {
    return {
      employeeId: 0,
      lastName: null,
      miName: null,
      firstName: null,
      rank: null,
      certification: null,
      ffnNumber: null,
      kemsisNumber: null,
      dateOfBirth: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      email: null,
      phone: null,
      drivingLicense: null,
      startDate: null,
      shift: null,
      payGrade: null,
      emergencyContact: null,
      emergencyContactNumber: null,
      emergencySecContact: null,
      emergencySecContactNumber: null,
      contactNotes: null,
      employeeAward: null,
      certifications: null,
      rankName: null,
      isActive: true
    } as Employee;
  }

  initializeCertificationModel(): Certification {
    return {
      employeeCertificationId: 0,
      employeeId: 0,
      certificationName: null,
      certificationNumber: null,
      dateOfExpiry: null,
      id: null,
      notes: null,
      isActive: true,
      noExpiry: false,
      employeeName: null,
      tempId: 0,
      ffNumber: null,
      instructorNumber: null,
      ssid: null,
      certificationId: null
    } as Certification;
  }
  initializeEmployeeAwardModel(): EmployeeAward {
    return {
      employeeAwardId: this.employeeAwards.length,
      employeeId: 0,
      awardName: 0,
      awardDescription: null,
      isActive: true,
      isNew: true
    } as EmployeeAward;

  }
  //private _filter(value: string): string[] {
  //  const filterValue = this._normalizeValue(value);
  //  return this.certifications.filter(s => this._normalizeValue(s).includes(filterValue));
  //}
  //private _normalizeValue(value: string): string {
  //  if (value == null) {
  //    return "";
  //  }
  //  return value.toLowerCase().replace(/\s/g, '');
  //}


  displayFn(certification: Certification): string {
    //debugger;
    if (certification) {

      return certification && certification.certificationName ? certification.certificationName : '';
    }
    return '';
  }
  getId(event$) {
    //debugger;
    this.certificationModel.certificationId = event$.option.value.certificationId;
    this.certificationModel.certificationName = event$.option.value.certificationId;
    
  }

  private _filter(name: string): Certification[] {
    //debugger;
    if (name.length <= 1) {
      this.isFocus = true;
    } else {
      this.isFocus = false;
    }
    const filterValue = name.toLowerCase();
    let returnValue = this.certificationsIdsandName.filter(certificationsIdsandName => certificationsIdsandName.certificationName.toLowerCase().indexOf(filterValue) === 0)
    
    return returnValue;
  }
 


  loadEmployee() {
    if (this.employeeId > 0) {

      this.employeeService.getEmployee('getEmployee', this.employeeId).subscribe(res => {
        this.employeeModel = res;
        this.employeeAwards = res.employeeAward;
        this.employeeCertifications = res.certifications;
        this.blockUI.stop();
        if (this.employeeAwards.length == 0) {
          var awardModel = this.initializeEmployeeAwardModel();

          this.employeeAwards.push(awardModel);
        }
        this.calculateTotalActiveItems();
      },
        err => {
          this.blockUI.stop();
        });
    }
    // this.blockUI.stop();
  }
  loadCertificates() {
    //debugger;
    this.employeeService.loadCertificates('getCertificates').subscribe(res => {
      //debugger;
      this.certifications = res;
      
      

    }, err => {
    });
    return false;
  }
  loadEmployee1() {
    //debugger;
    this.employeeService.getEmployee('getEmployee', this.certificationModel.employeeId).subscribe(res => {
      //debugger;
      this.employeeModel = res;
      this.employeeName = this.employeeModel.firstName + " " + this.employeeModel.miName + " " +this.employeeModel.lastName;



    }, err => {
    });
    return false;
  }
  loadCertificatesIds() {
    //debugger;
    this.employeeService.loadCertificatesIds('getCertificatesIdandName').subscribe(res => {
      //debugger;
      this.certificationsIdsandName = res;
      this.certificationDisplayName();

    }, err => {
    });
    return false;
  }
  loadCertificatesName() {
    //debugger;
    this.certificationNameId = parseInt(this.certificationModel.certificationName);
    this.employeeService.getCertificationName('getCertificationName', this.certificationNameId).subscribe(res => {
      //debugger;
      this.getcertificationName = res;
      this.certifiveName = res.certificationName;


    }, err => {
    });
    return false;
  }

  //public loadDropDowns() {
  //  forkJoin(
  //    this.systemCodeStateService.getAllStates(),
  //    this.systemCodeStateService.getSystemCodesForRanks(),
  //    this.systemCodeStateService.getSystemCodesForShifts(),

  //    this.systemCodeStateService.getSystemCodesForPayGrades(),
  //    this.systemCodeStateService.getSystemCodesForAward(),
  //    this.systemCodeStateService.getSystemCodesForCertificationTypes()
  //  ).subscribe(([res1, res2, res3, res4, res5, res6]) => {
  //    this.states = res1 || [];
  //    this.ranks = res2 || [];
  //    this.shifts = res3 || [];
  //    this.paygrades = res4 || [];
  //    this.awards = res5 || [];
  //    this.certifictionTypes = res6 || [];
  //  });

  //}

  getDateAsString(dt: Date) {
    let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
    let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
    return MM + "/" + dd + "/" + dt.getFullYear();
  }
  public updateEmployee() {
    this.isClicked = true;

    this.employeeModel.employeeAward = this.employeeAwards;
    // this.employeeModel.certifications = this.employeeCertifications;// = new Date("01/01/1900");
    this.employeeModel.certifications = [];
    for (let x = 0; x < this.employeeCertifications.length; x++) {
      let item: Certification = this.employeeCertifications[x];
      //if (item.noExpiry == true) {
      //  item.dateOfExpiry = new Date("01/01/1900");
      //}
      this.employeeModel.certifications.push(item);
    }

    for (let i = 0; i < this.employeeCertifications.length; i++) {
      this.employeeCertifications[i].dateOfExpiryStr = this.getDateAsString(new Date(this.employeeCertifications[i].dateOfExpiry));
    }
    this.employeeModel.dateOfBirthStr = this.getDateAsString(new Date(this.employeeModel.dateOfBirth));
    this.employeeModel.startDateStr = this.getDateAsString(new Date(this.employeeModel.startDate));



    if (this.employeeId > 0) {
      this.employeeService.updateEmployee(this.employeeId, this.employeeModel).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.resetTheForm();
            //this.isClicked = false;
            this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.isClicked = false;
            this.GoBack();
          });
        }
      }, (error: HttpErrorResponse) => { this.isClicked = false; })
    }
    else {

      this.employeeService.saveEmployee('saveEmployee', this.employeeModel).subscribe(res => {
        this.errMsg = res.msg;

        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.resetTheForm();
            //this.isClicked = false;
            this.GoBack();
          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.isClicked = false;
            this.GoBack();
          });
        }
      }, (error: HttpErrorResponse) => { this.isClicked = false; });
    }
  }
  resetTheForm() {
    this.employeeModel = this.initializeEmployeeModel();
    this.employeeCertifications = [];
    this.certificationModel = this.initializeCertificationModel();
    //  this.employeeCertifications.push(this.certificationModel);
    this.employeeAwards = [];
    var awardModel = this.initializeEmployeeAwardModel();
    this.employeeAwards.push(awardModel);
    this.certificationForm.resetForm();
    this.isClicked = false;

  }

  //addAnotherItem() {
  //  if (this.employeeAwards.length > 0) {
  //    if (this.employeeAwards[this.employeeAwards.length - 1].awardName == 0 || this.employeeAwards[this.employeeAwards.length - 1].isActive == false) {
  //      this.selectAwardMsg = "Please select Award!";
  //    }
  //    else {
  //      this.selectAwardMsg = "";
  //    }
  //  }
  //  if (this.employeeAwards.length > 0) {
  //    //&& this.employeeAwards[this.employeeAwards.length - 1].isActive!=true
  //    if (this.employeeAwards[this.employeeAwards.length - 1].awardName > 0 || this.employeeAwards[this.employeeAwards.length - 1].isActive == false) {
  //      var awardModel = this.initializeEmployeeAwardModel();
  //      this.employeeAwards.push(awardModel);
  //    }
  //  }
  //  else {
  //    var awardModel = this.initializeEmployeeAwardModel();
  //    this.employeeAwards.push(awardModel);
  //  }
  //  this.calculateTotalActiveItems();

  //}
  //onAwardChange() {
  //  if (this.employeeAwards.length > 0) {
  //    if (this.employeeAwards[this.employeeAwards.length - 1].awardName == 0 && this.employeeAwards[this.employeeAwards.length - 1].isActive != true) {
  //      this.selectAwardMsg = "Please select Award!";
  //    }
  //    else {
  //      this.selectAwardMsg = "";
  //    }
  //  }
  //}
  calculateTotalActiveItems() {
    this.totalActiveItems = 0;
    for (let x = 0; x < this.employeeAwards.length; x++) {
      if (this.employeeAwards[x].isActive == true) {
        this.totalActiveItems = this.totalActiveItems + 1;
      }
    }
  }


  //deleteItem(Id: number) {
  //  this.onAwardChange();
  //  this.totalActiveItems = 0;
  //  // this.employeeAwards.findIndex(x => x == index)
  //  const index = this.employeeAwards.findIndex(x => x.employeeAwardId == Id);

  //  for (let x = 0; x < this.employeeAwards.length; x++) {
  //    if (this.employeeAwards[x].isActive == true) {
  //      this.totalActiveItems = this.totalActiveItems + 1;
  //    }
  //  }

  //  if (this.totalActiveItems == 1) {
  //    this.employeeAwards[index].awardDescription = null;
  //    this.employeeAwards[index].awardName = 0;      //this.employeeAwards[index].isActive=
  //  }
  //  else {
  //    this.employeeAwards[index].isActive = false;
  //  }

  //  return false;
  //}

  //deleteEmployee() {
  //  const dialogRef = this.dialog.open(DeleteDialogComponent, {
  //    data: {
  //      id: 1
  //    }
  //  });
  //  dialogRef.afterClosed().subscribe(res => {
  //    if (res == 1) {
  //      if (this.employeeModel.employeeId > 0) {
  //        this.blockUI.start();
  //        this.employeeService.deleteEmployee('deleteEmployee', this.employeeModel).subscribe(res => {
  //          this.blockUI.stop();
  //          this.routerService.navigate(['/employeeview', { back: 'y' }]);
  //        }, (err) => {
  //          this.blockUI.stop();
  //        });
  //      }
  //    }
  //  });
  //  return false;
  //}
  resetEmployee() {
    this.resetTheForm();
  }
  GoBack() {
    this.routerService.navigate(['/employeeview', { back: 'y' }]);
  }
  //addCertification() {
  //  //const index = this.employeeCertifications.findIndex(x => x.employeeCertificationId == this.certificationModel.employeeCertificationId);
  //  //this.certificationModel.certificationName = this.certificationName;
  //  //

  //  //let certifyObjIndex = this.certificationsIdsandName.findIndex(x => x.certificationId == this.certificationModel.certificationId);
  //  //if (certifyObjIndex >= 0) {


  //  if (this.certificationModel.employeeCertificationId == 0 && this.certificationModel.tempId == 0) {
  //    this.certificationModel.certificationName = this.certificationModel.certificationId.toString();
  //    this.certificationModel.tempId = this.employeeCertifications.length + 1;
  //    if (this.certificationModel.employeeCertificationId == 0) {
  //      if (this.certificationModel.dateOfExpiry == null) {
  //        this.certificationModel.dateOfExpiry = new Date("01/01/1900");
  //      }
  //      this.employeeCertifications.push(this.certificationModel);
  //    }
  //    this.certificationModel = this.initializeCertificationModel();
  //    this.ff = null;
  //    this.instructor = null;
  //    this.fema = null;
  //    this.disableExpiryDateColumn = false;
  //  }
  //  else if (this.certificationModel.employeeCertificationId > 0 || this.certificationModel.tempId > 0) {
  //    let index = this.employeeCertifications.findIndex(x => x.employeeCertificationId == this.certificationModel.employeeCertificationId);

  //    if (this.certificationModel.employeeCertificationId == 0) {
  //      index = this.employeeCertifications.findIndex(x => x.tempId == this.certificationModel.tempId);
  //    }
  //    const editcertification = this.employeeCertifications[index];
  //    if (editcertification != null) {

  //      if (this.certificationModel.certificationId)
  //        editcertification.certificationName = this.certificationModel.certificationId.toString();
  //      else
  //        editcertification.certificationName = this.certificationModel.certificationName;


  //      editcertification.certificationNumber = this.certificationModel.certificationNumber;
  //      editcertification.dateOfExpiry = this.certificationModel.dateOfExpiry;
  //      editcertification.employeeCertificationId = this.certificationModel.employeeCertificationId;
  //      editcertification.employeeId = this.certificationModel.employeeId;
  //      editcertification.employeeName = this.certificationModel.employeeName;
  //      editcertification.id = this.certificationModel.id;
  //      editcertification.isActive = this.certificationModel.isActive;
  //      editcertification.isChecked = this.certificationModel.isChecked;
  //      editcertification.newDateOfExpiry = this.certificationModel.newDateOfExpiry;
  //      editcertification.noExpiry = this.certificationModel.noExpiry;
  //      editcertification.notes = this.certificationModel.notes;
  //      editcertification.tempId = this.certificationModel.tempId;
  //      editcertification.ffNumber = this.certificationModel.ffNumber;
  //      editcertification.instructorNumber = this.certificationModel.instructorNumber;
  //      editcertification.ssid = this.certificationModel.ssid;

  //    }
  //    this.clearCertification();
  //    this.certificationModel = this.initializeCertificationModel();
  //  }
  //  else {
  //    this.clearCertification();
  //    this.certificationModel = this.initializeCertificationModel();
  //  }
  //  // this.certificationModel.certificationName = null;
  //  this.myControl.reset();
  //  this.filteredOptions = this.myControl.valueChanges
  //    .pipe(
  //      startWith(''),
  //      map(value => typeof value === 'string' ? value : value.certificationName),
  //      map(name => name ? this._filter(name) : this.certificationsIdsandName.slice())
  //    );
  //  // this.loadCertificatesIds();

  //}
  //editCertify(certifyId: number, tempId: number) {
  //  // this.employeeAwards.findIndex(x => x == index)
  //  if (certifyId > 0) {
  //    const index = this.employeeCertifications.findIndex(x => x.employeeCertificationId == certifyId);
  //    if (index >= 0) {
  //      //this.employeeCertifications[index].isActive = false;
  //      this.certificationEditModel = this.employeeCertifications[index];
  //      this.certificationModel.certificationName = this.certificationEditModel.certificationName;
  //      this.certificationModel.certificationNumber = this.certificationEditModel.certificationNumber;
  //      this.certificationModel.dateOfExpiry = this.certificationEditModel.dateOfExpiry;
  //      this.certificationModel.employeeCertificationId = this.certificationEditModel.employeeCertificationId;
  //      this.certificationModel.employeeId = this.certificationEditModel.employeeId;
  //      this.certificationModel.employeeName = this.certificationEditModel.employeeName;
  //      this.certificationModel.id = this.certificationEditModel.id;
  //      this.certificationModel.isActive = this.certificationEditModel.isActive;
  //      this.certificationModel.isChecked = this.certificationEditModel.isChecked;
  //      this.certificationModel.newDateOfExpiry = this.certificationEditModel.newDateOfExpiry;
  //      this.certificationModel.noExpiry = this.certificationEditModel.noExpiry;
  //      this.certificationModel.notes = this.certificationEditModel.notes;
  //      this.certificationModel.tempId = this.certificationEditModel.tempId;

  //      this.certificationModel.ffNumber = this.certificationEditModel.ffNumber;
  //      this.certificationModel.instructorNumber = this.certificationEditModel.instructorNumber;
  //      this.certificationModel.ssid = this.certificationEditModel.ssid;
  //      //this.certificationName = "EMT";

  //      //this.myControl.setValue({ certificationId: parseInt(this.certificationEditModel.certificationName) });

  //      let certifyObjIndex = this.certificationsIdsandName.findIndex(x => x.certificationId.toString() == this.certificationEditModel.certificationName);
  //      if (certifyObjIndex >= 0) {
  //        this.myControl.setValue(this.certificationsIdsandName[certifyObjIndex]);//.certificationName;

  //      }
  //      //if (this.certificationModel.dateOfExpiry) {

  //      //}
  //    }
  //  }
  //  else {

  //    const index = this.employeeCertifications.findIndex(x => x.tempId == tempId);
  //    if (index >= 0) {
  //      //this.employeeCertifications[index].isActive = false;
  //      this.certificationEditModel = this.employeeCertifications[index];
  //      this.certificationEditModel = this.employeeCertifications[index];
  //      this.certificationModel.certificationName = this.certificationEditModel.certificationName;
  //      this.certificationModel.certificationNumber = this.certificationEditModel.certificationNumber;
  //      this.certificationModel.dateOfExpiry = this.certificationEditModel.dateOfExpiry;
  //      this.certificationModel.employeeCertificationId = this.certificationEditModel.employeeCertificationId;
  //      this.certificationModel.employeeId = this.certificationEditModel.employeeId;
  //      this.certificationModel.employeeName = this.certificationEditModel.employeeName;
  //      this.certificationModel.id = this.certificationEditModel.id;
  //      this.certificationModel.isActive = this.certificationEditModel.isActive;
  //      this.certificationModel.isChecked = this.certificationEditModel.isChecked;
  //      this.certificationModel.newDateOfExpiry = this.certificationEditModel.newDateOfExpiry;
  //      this.certificationModel.noExpiry = this.certificationEditModel.noExpiry;
  //      this.certificationModel.notes = this.certificationEditModel.notes;
  //      this.certificationModel.tempId = this.certificationEditModel.tempId;
  //      this.certificationModel.ffNumber = this.certificationEditModel.ffNumber;
  //      this.certificationModel.instructorNumber = this.certificationEditModel.instructorNumber;
  //      this.certificationModel.ssid = this.certificationEditModel.ssid;

  //      //this.myControl.setValue({ certificationId: parseInt( this.certificationEditModel.certificationName) });

  //      //let certifyObjIndex = this.certificationsIdsandName.findIndex(x => x.certificationId.toString() == this.certificationEditModel.certificationName);
  //      //if (certifyObjIndex >= 0) {
  //      //  this.certificationName = this.certificationsIdsandName[certifyObjIndex].certificationName;
  //      //}
  //      let certifyObjIndex = this.certificationsIdsandName.findIndex(x => x.certificationId.toString() == this.certificationEditModel.certificationName);
  //      if (certifyObjIndex >= 0) {
  //        this.myControl.setValue(this.certificationsIdsandName[certifyObjIndex]);//.certificationName;

  //      }

  //    }
  //  }

  //  if (this.certificationModel.dateOfExpiry != null) {

  //    var dateExpiry = new Date(this.certificationModel.dateOfExpiry);
  //    if (dateExpiry.getDate() == 1 && dateExpiry.getMonth() == 0 && dateExpiry.getFullYear() == 1900) {
  //      this.certificationModel.dateOfExpiry = null;
  //    }
  //  }
  //  if (this.certificationModel.noExpiry == true) {
  //    this.certificationModel.dateOfExpiry = null;
  //    this.disableExpiryDateColumn = true;
  //  }
  //  else {
  //    this.disableExpiryDateColumn = false;
  //  }
  //  //else {
  //  //  this.employeeCertifications[index].certificationName = "";
  //  //  this.employeeCertifications[index].certificationNumber = "";
  //  //  this.employeeCertifications[index].dateOfExpiry = null;
  //  //  this.employeeCertifications[index].id = "";
  //  //  this.employeeCertifications[index].notes = "";
  //  //}
  //  return false;
  //}
  //deleteCertify(id: number, tempId: number) {
  //  let index = 0;
  //  // this.employeeAwards.findIndex(x => x == index)
  //  if (id > 0) {
  //    index = this.employeeCertifications.findIndex(x => x.employeeCertificationId == id);
  //  }
  //  else {
  //    index = this.employeeCertifications.findIndex(x => x.tempId == tempId);
  //  }

  //  if (index >= 0) {
  //    this.employeeCertifications[index].isActive = false;
  //  }
  //  else {
  //    this.employeeCertifications[index].certificationName = null;
  //    this.employeeCertifications[index].certificationNumber = null;
  //    this.employeeCertifications[index].dateOfExpiry = null;
  //    this.employeeCertifications[index].id = null;
  //    this.employeeCertifications[index].notes = null;
  //    this.employeeCertifications[index].ffNumber = null;
  //    this.employeeCertifications[index].instructorNumber = null;
  //    this.employeeCertifications[index].ssid = null;
  //  }
  //  return false;
  //}

  clearCertification() {
    this.certificationModel = this.initializeCertificationModel();
    this.ff = null;
    this.instructor = null;
    this.fema = null;
    this.disableExpiryDateColumn = false;
    //this.myControl.reset();
    //this.certificationName = null;
    this.myControl.reset();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.certificationName),
        map(name => name ? this._filter(name) : this.certificationsIdsandName.slice())
      );
  }
  NoExpiry(showExpiryDateColumn: boolean) {
    if (showExpiryDateColumn == true) {
      this.disableExpiryDateColumn = false;
    }
    else {
      this.disableExpiryDateColumn = true;
      this.certificationModel.dateOfExpiry = null;
    }
  }
  //getDisplayName(certifyName: any) {
  //  let certifyObjIndex = this.certificationsIdsandName.findIndex(x => x.certificationId == certifyName);
  //  if (certifyObjIndex >= 0) {
  //    return this.certificationsIdsandName[certifyObjIndex].certificationName;//.certificationName;
  //  }
  //}
  //getDisplayDate(dt: any) {
  //  if (dt == null) {
  //    return "";
  //  }
  //  let dt1: Date = new Date(dt);
  //  if (dt1.getDate().toString() == "1" && dt1.getMonth().toString() == "0" && (dt1.getFullYear().toString() == "1" || dt1.getFullYear().toString() == "0" || dt1.getFullYear().toString() == "1899" || dt1.getFullYear().toString() == "1900")) {
  //    return "";
  //  }
  //  else {
  //    return dt1;
  //  }

  //}

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
  disableAwards() {
    for (var x = 0; x < this.employeeAwards.length; x++) {
      if (this.employeeAwards[x].isActive == true) {
        if ((this.employeeAwards[x].awardName == null || this.employeeAwards[x].awardName == 0) || (this.employeeAwards[x].awardDescription == null || this.employeeAwards[x].awardDescription == '')) {
          return true;
        }
      }
    }
  }
  handleBackEvents(event: Event) {
    //debugger;
    console.log('back button pressed');
    // event.preventDefault();
    //this.preventBackButton();
    // this.routerService.navigate(['/employeeview', { back: 'y' }], { replaceUrl: true });
  }
  preventBackButton() {

    console.log("back button pressed12");
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      console.log("back button pressed inside");
      history.pushState(null, null, window.location.href);
    })
  }
  preventBackButtonEvent() {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
  public updateCertification() {
    debugger;
    this.blockUI.start();
    
    //this.disableSave = true;
    if (this.certificationdataId > 0) {
      //this.maintenenceRequestModel.updateComments = this.maintenenceRequestModel.updateComments + this.commentsFromServer ;
      //this.employeeService.updateCertificationData(this.certificationdataId, this.certificationModel).subscribe(res => {

      //this.certificationModel.certificationName = this.certificationModel.certificationId;
      if (this.certificationModel.dateOfExpiry == null) {
        this.certificationModel.dateOfExpiry = new Date("01/01/1900");
      }
      this.certificationModel.dateOfExpiryStr = this.getDateAsString(new Date(this.certificationModel.dateOfExpiry));
      
      this.employeeService.updateCertificationData('updateCertificationData', this.certificationModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          //this.resetTheForm();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
            
            this.canceldialogAfterUpdate();
            this.routerService.navigate(['/certificationupdate']);
            //this.routerService.navigate(['/maintenancerequestform']);
            //this.GoBack();
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.routerService.navigate(['/certificationupdate']);
            //this.GoBack();
          });
        }
      });
    } 
    this.blockUI.stop();
  }
  GetCertification(): void {
    //debugger;
    this.routerService.navigate(['/certificationupdate']);
  }
  canceldialog(): void {
    //if (this.assignmentForm.dirty) {
   // debugger;
    const dialogRef = this.dialog.open(DeleteCertificationComponent, {
      width: '400px',
    });
    //} else {
    //this.dialog.closeAll();
    //}  
  }
  canceldialogAfterUpdate(): void {
    //if (this.assignmentForm.dirty) {
    //debugger;
    this.dialog.closeAll();
    //} else {
    //this.dialog.closeAll();
    //}  
  }
}
