import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cbg-save-dialog',
  templateUrl: './save-dialog.component.html'
})
export class SaveDialogComponent {

  public confirmMessage: string;
  constructor(public dialogRef: MatDialogRef<SaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  noSelected(): any {
    
    this.dialogRef.close();
  }
}
export interface DialogData {
  id: number;
  name: string;
}

