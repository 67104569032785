import { Component, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { SystemCodeService } from '../services/system-code.service';
import { SystemCode } from '../models/system-codes.model';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { forkJoin, of, Observable } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalConstants } from '../common/global-constants';
import { saveFile, saveAs } from 'file-saver';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
//import { DailyRoaster } from '../models/dailyroaster.model';
import { DailyRoasterService } from '../services/dailyroaster.service';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { GetAllDailyRoaster } from '../models/GetAllDailyRoaster';
import { Employee } from '../models/employee.model';
import { EmployeeService } from '../services/employee.service';
import { debug } from 'util';
import { RosterAssignmentDialogContent } from './dailyroasterassignment.component';
import { StRoster } from '../models/stroster.model';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { UserService } from '../services/user.service';
import { DailyRoasterAssignment } from '../models/dailyroasterassignment.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { AngularCsv } from 'angular7-csv';

@Component({
  selector: 'dailyroaster',
  templateUrl: './dailyroaster.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]

})
export class DailyRoasterComponent {
  @ViewChild('dailyroasterForm', { static: false }) dailyRoasterForm: NgForm;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  // dailyRoaster: DailyRoaster;
  dailyRoaster: StRoster;
  crewCode: SystemCode[] = [];
  stationCode: SystemCode[] = [];
  //dailyRoasterList: DailyRoaster[] = [];
  dailyRoasterList: StRoster[] = [];
  dataSource: MatTableDataSource<StRoster>;
  // assignmentObjDB = new MatTableDataSource<DailyRoaster>([]);
  paginatorid: MatPaginator;
  searchMsg: string = "";
  resultsLength: number = 0;
  employee: number[] = [];
  employeeResutObj: Employee[] = [];
  allEmployeeObj: Employee[] = [];
  displayedColumns: any[] = ['crewName', 'employeeName', 'view'];
  @BlockUI() blockUI: NgBlockUI;
  crews: Array<{ key: number, value: string }> = [];
  stations: Array<{ key: number, value: string }> = [];
  employees: Array<{ key: number, value: string }> = [];
  today: Date = null;
  crewnam: string = "";
  rolePermissionCheckModel: RolePermissionCheck;
  dailyRoasterObj: DailyRoasterAssignment[] = [];
  items: number[] = [];

  constructor(
    private dialog: MatDialog,
    private systemCodeService: SystemCodeService, private dailyRoasterService: DailyRoasterService,
    private authService: AuthService, private employeeService: EmployeeService, private activatedRoute: ActivatedRoute, private routerService: Router, private userService: UserService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.rolePermissionCheckModel = this.activatedRoute.snapshot.data['rolePermissions'];
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.initializeActionsForDisplayedColumns();
      this.blockUI.start();
      this.today = new Date(new Date().toDateString());
      this.loadDropDowns();
      //  this.dailyRoaster = this.initializeModel();
      this.dailyRoaster = this.initializeStRosterModel();
      this.dailyRoaster.shiftDate = this.today;
      this.loadGrid();
      this.authService.getUserId();
      this.getCrewId();
      this.dataSource = new MatTableDataSource(this.dailyRoasterList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  initializeActionsForDisplayedColumns(): void {
    if (this.rolePermissionCheckModel.isDelete) {
      this.displayedColumns.push('delete');
    }
    //if (this.rolePermissionCheckModel.isRead) {
    //  this.displayedColumns.push('view');
    //}
  }

  public loadDropDowns() {

    forkJoin(
      this.systemCodeService.getSystemCodesForCrews(),
      this.systemCodeService.getAllStations()
     
    ).subscribe(([res1, res2]) => {
      
      this.crewCode = res1 || [];
      this.stationCode = res2 || [];
      this.crews = res1.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
      this.stations = res2.map(x => { return { key: x.systemCodeId, value: x.type }; }) || [];
    });

  }
  initializeStRosterModel(): StRoster {
    return {
      stRosterId: 0, shiftDate: null, personnel: null, crew: null,  rosterAssignments: null
    } as StRoster;
  }


  /*********************************** */
  ngAfterViewInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      //pagination subscription
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            //this.blockUI.start();
            return this.getAllDailyRoasterUsage(this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            //this.blockUI.stop();
            this.resultsLength = data.totalCount;
            return data.items;
          }),
          catchError(() => {
            //this.blockUI.stop();
            return of([]);
          })
        ).subscribe(data => {
          this.dailyRoasterList = data || [];
          this.dataSource = new MatTableDataSource(this.dailyRoasterList);
          this.dataSource.sort = this.sort;
          //this.blockUI.stop();
        });
    }
  }

  getAllDailyRoasterUsage(pageIndex: number, pageSize: number): Observable<GetAllDailyRoaster> {
    return this.dailyRoasterService.getDailyRoasters(
      'getDailyRoasters',
      (this.dailyRoaster.shiftDate) ? this.dailyRoaster.shiftDate : null,
      (this.dailyRoaster.crew != null) ? 0 : 0,
      0,0,
      pageIndex,
      pageSize,
      this.getDateAsString(this.dailyRoaster.shiftDate)
    );
  }
  
  getAllWaterUsageForDataSource(): void {
    this.getAllDailyRoasterUsage(0, 50)
      .pipe(
        map(data => {
          //this.blockUI.start();
          this.resultsLength = data.totalCount;
          return data.items;
        }),
        catchError(() => {
          //   this.blockUI.stop();
          return of([]);
        })
      ).subscribe(data => {
        this.dailyRoasterList = data || [];
        //this.dataSource.data = data || [];
        this.dataSource = new MatTableDataSource(this.dailyRoasterList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        // this.blockUI.stop();
      });
  }

  /*********************************** */
  search() {
    this.getAllWaterUsageForDataSource();
    return false;

  }
  saveDailyRoaster() {
    for (let i = 0; i < this.dailyRoasterList.length; i++) {
      this.dailyRoasterList[i].shiftDateStr = this.getDateAsString(new Date( this.dailyRoaster.shiftDate));
    }

    this.dailyRoasterService.saveDailyRoaster('saveDailyRoasters', this.dailyRoasterList).subscribe(res => {
     // this.search();
     // this.clearForm();
      this.reloadEmployees();
     
      const dialogRef = this.dialog.open(SaveDialogComponent, {
        width: '500px',
        height: '200px',
        data: { name: "Employees Added Successfully!" }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getAllWaterUsageForDataSource();        
      });
    },
      err => {

      });

  }
  addToGrid() {
    let crewIndex = this.crewCode.findIndex(x => x.systemCodeId == this.dailyRoaster.crew);
    let crewName: string = this.crewCode[crewIndex].type;

    //let stationIndex = this.stationCode.findIndex(x => x.systemCodeId == this.dailyRoaster.station);
    //let stationName: string = this.stationCode[stationIndex].type;

    for (let y = 0; y < this.employee.length; y++) {
      let employeeIndex = this.employeeResutObj.findIndex(x => x.employeeId == this.employee[y]);
      let firstName: string = this.employeeResutObj[employeeIndex].firstName;
      let miName: string = this.employeeResutObj[employeeIndex].miName;
      let lastName: string = this.employeeResutObj[employeeIndex].lastName;

      firstName = (firstName == null) ? "" : firstName;
      miName = (miName == null) ? "" : miName;
      lastName = (lastName == null) ? "" : lastName;
      var newDailyRoaster = {
        stRosterId: 0,
        shiftDate: this.dailyRoaster.shiftDate,
        crew: this.dailyRoaster.crew,
       // station: this.dailyRoaster.station,
        personnel: this.employee[y],
        isActive: true,
        //  crewName: crewName,
        //  stationName: stationName,
        rosterAssignments: null,
        startDate: null,
        endDate: null,
        shiftDateStr: null,
        crewName: null,
        employeeName: null
        // employeeName: (firstName + ' ' + miName + ' ' + lastName)         
      };

      this.dailyRoasterList.push(newDailyRoaster);
    }
    this.saveDailyRoaster();

    //this.emstrainingReportList = res || [];

    //this.assignmentObjDB.data = this.dailyRoasterList;

    //this.dataSource = new MatTableDataSource<DailyRoaster>(this.dailyRoasterList);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;

  }
  shiftDateChange(dt: any) {
    //this.startMaxDate = dt;
    // this.endMinDate = new Date(dt);
  }
  loadGrid() {
    this.employeeService.getAllEmployees('GetEmployees', 0).subscribe(res => {
    
      this.employeeResutObj = res.filter(x => x.isActive == true);
      
      this.allEmployeeObj = res;
      this.blockUI.stop();
    }, err => {
      this.blockUI.stop();
    });
  }
  deleteAssignment(assignmentId: number) {

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        // this.employeeObj.employeeId = parseInt( eId);

        this.dailyRoasterService.deleteRoaster('delRoaster', assignmentId).subscribe(res => {
          //  this.loadGrid();
          this.employee = [];
          this.getAllWaterUsageForDataSource();
          this.reloadEmployees();

        });
      }

    });
    return false;



  }
  openAssignment(id: number) {
    const dialogRef = this.dialog.open(RosterAssignmentDialogContent, {
      width: '1300px',
      data: { id: id, crewName: this.crewnam },      
      disableClose: true,
      //hasBackdrop: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.user = user;
    });
    return false;
  }
  getEmployeeName(empId: number) {
    let employeeIndex = this.allEmployeeObj.findIndex(x => x.employeeId == empId);
    if (employeeIndex > -1) {
      let firstName: string = this.allEmployeeObj[employeeIndex].firstName;
      let miName: string = this.allEmployeeObj[employeeIndex].miName;
      let lastName: string = this.allEmployeeObj[employeeIndex].lastName;

      firstName = (firstName == null) ? "" : firstName;
      miName = (miName == null) ? "" : miName;
      lastName = (lastName == null) ? "" : lastName;

      return (lastName + ' ' + miName + ' ' + firstName );
    }
    else {
      return "";
    }

  }
  assignmentDtCheck(dt1: string, dt2: Date): boolean {
    var shiftdt = new Date(new Date(dt1).toDateString());
    return (shiftdt >= dt2) ? true : false;


  }
  returnFalse() {
    return false;

  }
  goHome(): void {
    this.routerService.navigate(['/home']);
  }   
  getCrewId()
  {
    let userId = this.authService.getUserId();
    this.userService.getCrew("GetUserCrew", userId).subscribe(res => {
      this.dailyRoaster.crew = res.crewId;
      this.crewnam= this.crews.find(x => x.key == this.dailyRoaster.crew).value;
      this.loadCrewEmployees(this.dailyRoaster.crew);
    });
  }
  loadCrewEmployees(crewId: number) {
    this.employeeService.getAllEmployeesForCrew('GetEmployeesForCrew', crewId).subscribe(res => {
      this.employeeResutObj = res;
      this.blockUI.stop();
    }, err => {
      this.blockUI.stop();
    });
  }
  onCrewChange(crewId: any,event:any) {

    this.crewnam = event.source.selected.viewValue;

    if (crewId == null || crewId == '') {
      this.loadGrid();
    }
    else {
      this.loadCrewEmployees(crewId);
    }
  }
  printReport() {
    let dt = this.getDateAsString(this.dailyRoaster.shiftDate);// (this.dailyRoaster.shiftDate.getMonth() + 1) + "/" + this.dailyRoaster.shiftDate.getDate() + "/" + this.dailyRoaster.shiftDate.getFullYear();
    // this.routerService.navigate(['/dailyroasterview', { shiftdt: dt, cid: (this.dailyRoaster.crew == null) ? 0 : this.dailyRoaster.crew, cname: this.crewnam,report:"" }]);
    this.items = [];
    this.dailyRoasterService.getDailyRoasterView("getDailyRoasterView", 0, dt).subscribe(res => {
      this.dailyRoasterObj = res;
      let lastObj = this.dailyRoasterObj[this.dailyRoasterObj.length-1];
      this.dailyRoasterObj.pop();
      for (let x = 0; x < this.dailyRoasterList.length; x++) {
        //for (let y = 0; y < this.dailyRoasterObj.length; y++)  {
        //  if (this.dailyRoasterList[x].stRosterId.toString() != this.dailyRoasterObj[y].roasterId) {
        //    this.items.push(this.dailyRoasterList[x].stRosterId);
        //  }
        //}
        const index = this.dailyRoasterObj.findIndex(x1 => x1.roasterId == this.dailyRoasterList[x].stRosterId.toString());
        if (index == -1) {
          this.items.push(this.dailyRoasterList[x].stRosterId);
        }
      }
      for (let z = 0; z < this.items.length; z++) {

        const index = this.dailyRoasterList.findIndex(x1 => x1.stRosterId == this.items[z]);
        if (index >=0) {
          //const dindex = this.dailyRoasterList.findIndex(i => i.stRosterId == this.items[z]);
          //if (dindex >= 0) {
          let dailyObj = this.dailyRoasterList[index];

          let dailyRoster = {
            roasterId:'',
            personal: this.getEmployeeName(dailyObj.personnel),
            station: '',
            position: '',
            unit: '',
            activity: '',
            startDate: '',
            endDate: '',
            hours: '0.00',
            type: 'empty',
            crew:''
          };


          this.dailyRoasterObj.push(dailyRoster);

          //}
        }
      }
      this.dailyRoasterObj.push(lastObj);
      this.displayReport();
    });
  }

  isEmployeeSelected() {

    return this.employee.length > 0 ? true : false;
  }
  getCrewName(crewId: number) {
    const index = this.crews.findIndex(x => x.key == crewId); //.value;
    if (index >= 0) {
      return this.crews[index].value;
    }
    else {
      return "";
    }

  }
  clearForm() {
    this.dailyRoaster.shiftDate = new Date(new Date().toDateString());
    this.employee = [];
    this.reloadEmployees();
    this.dailyRoasterList = [];
  }
  reloadEmployees() {
    this.employee = [];
    this.authService.getUserId();
    this.getCrewId();
   
   // this.employee = [];
  }
  isAlreadyExists(employeeId: number) {
    const index = this.dailyRoasterList.findIndex(x => x.personnel == employeeId);
    if (index >= 0) {

      return true;
    }
    else {
      return false;
    }
  }
  getDateAsString(dt: Date) {
    if (dt != null) {
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let MM = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      return MM + "/" + dd + "/" + dt.getFullYear();
    } else {
      return null;
    }
  }
  displayReport() {
    let title = "Daily Roster Report";
    let count: number = 0;
    let xAxis: number = 15;
    let yAxis: number = 32;
    let alignment: number = 0;
    let startYVariable = 40;
    var doc = new jsPDF('l', 'mm', 'letter');
    doc.setProperties({
      title: 'DailyRoster_Report'
    });
    var totalPagesExp = '{total_pages_count_string}';
    doc.setFont('Calibri');
    doc.setTextColor(40)
    doc.setFontSize(16)
    var textWidth = doc.getStringUnitWidth(title) * doc.getFontSize() / doc.internal.scaleFactor;
    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 20)
    doc.setFontSize(10)

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    if (this.dailyRoaster.shiftDate != null) {
    //if (count == 1) {
    //  yAxis = yAxis + 10;
    //  count = 0;
    //}
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Shift Date: ' + this.getDateAsString(this.dailyRoaster.shiftDate), pageWidth - 35)
    //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
    doc.text(text, xAxis + 90, yAxis)
    count++;
    }

   // if (this.dailyRoaster.personnel != null) {
      //if (count == 1) {
        //xAxis = xAxis + 160;
       // count = 0;
     // }
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var text = doc.splitTextToSize('Personnel: -' , pageWidth - 35)
      //doc.text(text, xAxis, yAxis, { align: ((alignment === 0) ? 'left' : ((alignment === 1) ? 'center' : 'right')) })
      doc.text(text, xAxis + 130, yAxis)
      count++;
   // }   


    if (!this.checkPrintReportFilters()) {
      startYVariable = 40;
    } else {
      startYVariable = yAxis + 10;
    }
    var currentDate = new Date();
    var currentDateString = this.getDateAsString(currentDate);

    autoTable(doc, {
      rowPageBreak: 'avoid',
      columnStyles: {
        crew: {
          cellWidth: 15
        }
      },
      headStyles: { minCellHeight: 8, fillColor: '#ff4242', font: 'Calibri', fontStyle: 'bold', fontSize:10 },
      bodyStyles: { minCellHeight: 8, fillColor: null, font: 'Calibri', fontSize: 10 },
      head: this.getHeadRows(),
      body: this.getBody(),
      //headStyles: { minCellHeight: 8, fillColor: '#ff4242' },
      //bodyStyles: { minCellHeight: 8, fillColor: null },
      //head: this.getHeadRows(),
      //body: this.getBody(),
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(9)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, 230, pageHeight - 10);

        doc.text(currentDateString, 35, pageHeight - 10);
      },
      startY: startYVariable
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    //doc.save('Company_Training_Report.pdf');
    //doc.output('dataurlnewwindow');
    window.open(URL.createObjectURL(doc.output("blob")));
  }
  getHeadRows() {
    return [
      {
        personnel: 'Personnel', station: 'Station', position: 'Position', unit: 'Unit',  activityCode: 'Activity Code',
        startdate: 'Start Date/Time',enddate:'End Date/Time', hours: 'Hours'
      }
    ]
  }

  getBody() {
    let rowCount = this.dailyRoasterObj.length;
    var body = []
    for (var j = 0; j < rowCount; j++) {

      if (this.dailyRoasterObj[j].type == 'row') {
        body.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: (this.dailyRoasterObj[j].station) ? this.dailyRoasterObj[j].station : '',
          position: this.dailyRoasterObj[j].position,
          unit: (this.dailyRoasterObj[j].unit) ? this.dailyRoasterObj[j].unit : '',
          activityCode: (this.dailyRoasterObj[j].activity) ? this.dailyRoasterObj[j].activity : '',
          startdate: this.dailyRoasterObj[j].startDate,
          enddate: this.dailyRoasterObj[j].endDate,
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else if(this.dailyRoasterObj[j].type == 'empty') {
        body.push( {
          personnel: this.dailyRoasterObj[j].personal,
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else {
        body.push({
          personnel: '',
          station:  '',
          position: '',
          unit:'',
          activityCode: '',
          startdate: '',
          enddate: '               Total Hours -',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
    }
    return body;
  }
  checkPrintReportFilters() {
    if (this.dailyRoaster.shiftDate == null) {
      return true;
    }
    return false;
  }
  convertMinsToHrsMins(minutes: number) {
    var h: number = Math.floor(minutes / 60);
    var m = minutes % 60;
    let h1 = h < 10 ? '0' + h : h;
    let m1 = m < 10 ? '0' + m : m;
    return h1 + ':' + m1;
  }

  //exportcsv

  exportDailyRosterReportasCSV() {
    let dt = this.getDateAsString(this.dailyRoaster.shiftDate);
    this.items = [];
    this.dailyRoasterService.getDailyRoasterView("getDailyRoasterView", 0, dt).subscribe(res => {
      this.dailyRoasterObj = res;
      let lastObj = this.dailyRoasterObj[this.dailyRoasterObj.length - 1];
      this.dailyRoasterObj.pop();
      for (let x = 0; x < this.dailyRoasterList.length; x++) {
        
        const index = this.dailyRoasterObj.findIndex(x1 => x1.roasterId == this.dailyRoasterList[x].stRosterId.toString());
        if (index == -1) {
          this.items.push(this.dailyRoasterList[x].stRosterId);
        }
      }
      for (let z = 0; z < this.items.length; z++) {

        const index = this.dailyRoasterList.findIndex(x1 => x1.stRosterId == this.items[z]);
        if (index >= 0) {
          let dailyObj = this.dailyRoasterList[index];

          let dailyRoster = {
            roasterId: '',
            personal: this.getEmployeeName(dailyObj.personnel),
            station: '',
            position: '',
            unit: '',
            activity: '',
            startDate: '',
            endDate: '',
            hours: '0.00',
            type: 'empty',
            crew: ''
          };
          this.dailyRoasterObj.push(dailyRoster);
        }
      }
      this.dailyRoasterObj.push(lastObj);
      this.exportToCSV();
    });
  }

  exportToCSV() {
    let rowCount = this.dailyRoasterObj.length;
    var data = []

    data.push({
      personnel: 'Personnel', station: 'Station', position: 'Position', unit: 'Unit', activityCode: 'Activity Code',
      startdate: 'Start Date/Time', enddate: 'End Date/Time', hours: 'Hours'
    });

    for (var j = 0; j < rowCount; j++) {

      if (this.dailyRoasterObj[j].type == 'row') {
        data.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: (this.dailyRoasterObj[j].station) ? this.dailyRoasterObj[j].station : '',
          position: this.dailyRoasterObj[j].position,
          unit: (this.dailyRoasterObj[j].unit) ? this.dailyRoasterObj[j].unit : '',
          activityCode: (this.dailyRoasterObj[j].activity) ? this.dailyRoasterObj[j].activity : '',
          startdate: this.dailyRoasterObj[j].startDate,
          enddate: this.dailyRoasterObj[j].endDate,
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else if (this.dailyRoasterObj[j].type == 'empty') {
        data.push({
          personnel: this.dailyRoasterObj[j].personal,
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
      else {
        data.push({
          personnel: '',
          station: '',
          position: '',
          unit: '',
          activityCode: '',
          startdate: '',
          enddate: '               Total Hours -',
          hours: (this.dailyRoasterObj[j].hours) ? this.convertMinsToHrsMins(parseInt(this.dailyRoasterObj[j].hours)) + " " : ''
        });
      }
    }
    new AngularCsv(data, 'Daily Roster Report');
    //return body;
  }


}
