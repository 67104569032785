import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { AuthService } from './auth.service';


@Injectable()
export abstract class BaseService {
  protected baseUrl: string;

  constructor(
    baseUrl: string,
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    this.baseUrl = baseUrl;
  }

  protected postRequest(action: string, jsonData: string): Observable<any> {
    return this.customPostRequest(action, jsonData);
  }

  protected postChildRequest(id: number, childEntity: string, jsonData: string): Observable<any> {
    return this.postRequest(
      this.addDataToUrl(id) + this.addDataToUrl(childEntity, false),
      jsonData
    );
  }

  protected customPostRequest(action: string, jsonData: string): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();
    return this.httpClient.post(this.baseUrl + this.addDataToUrl(action), jsonData, { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected customPostRequestID(action: string, id: number, jsonData: string): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();

    return this.httpClient.post(this.baseUrl + this.addDataToUrl(action), this.addDataToUrl(id) + jsonData)
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected customGetRequest<T>(action: string, jsonData: string): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();
    return this.httpClient.get<T>(this.baseUrl + this.addDataToUrl(action) + (jsonData === null ? '' : jsonData), { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected getRequest(id: number | string): Observable<any> {
    return this.getChildRequest(id, null);
  }

  protected getChildRequest(id: number | string, childEntity: string, childId: number | string = null): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();

    return this.httpClient
      .get(this.baseUrl + this.addDataToUrl(id) + this.addDataToUrl(childEntity) + this.addDataToUrl(childId), { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected deleteRequest(id: number): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();
    return this.httpClient
      .delete(this.baseUrl + this.addDataToUrl(id), { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }
  protected deleteRequestParams(params: string): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();
    return this.httpClient
      .delete(this.baseUrl +  this.addDataToUrl(params), { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected customdeleteRequest(action: string, jsonData: string): Observable<any> {
    return this.customPostRequest(action, jsonData);
    //var options = this.buildBaseRequestOptionsArgs();
    //return this.httpClient
    //  .delete<T>(this.baseUrl + this.addDataToUrl(action) + jsonData, options)
    //  .pipe(
    //    catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
    //  );
  }

  protected putRequest(id: number | string, jsonData: string): Observable<any> {
    return this.putChildRequest(id, null, jsonData)
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected putChildRequest(id: number | string, childEntity: string, jsonData: string, childId: number = null): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();
    return this.httpClient
      .put(this.baseUrl + this.addDataToUrl(id) + this.addDataToUrl(childEntity) + this.addDataToUrl(childId), jsonData, { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  private buildBaseRequestOptionsArgs(): HttpHeaders {
    //const headers = new HttpHeaders().set('Content-Type', 'application/json')
    //  .set('Cache-Control', 'no-cache')
    //  .set('Pragma', 'no-cache')
    //  .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    const headers = this.authService.generateHeaders();
    return headers;
  }
  private buildBaseRequestOptionsArgs_V2(): HttpHeaders {
    //const headers = new HttpHeaders().set('Content-Type', 'application/json')
    //  .set('Cache-Control', 'no-cache')
    //  .set('Pragma', 'no-cache')
    //  .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    const headers = this.authService.generateHeaders_v2();
    return headers;
  }
  private buildBaseRequestOptionsArgsStream(): HttpHeaders {
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return headers;
  }

  private extractData(res: HttpResponse<any>): any {
    if (res.body())
      return res.body();
    return {};
  }

  private addDataToUrl(data: any, addEndingSlash: boolean = true): string {
    return data == null ? '' : (data + (addEndingSlash ? '/' : ''));
  }

  private handleError(error: HttpResponse<any>, suppressModal: boolean = true): Observable<any> {
    debugger;
    var errMsg = '';
    errMsg = error.body;
    this.errorService.handleRequestError(error);
    return throwError(errMsg);
  }

  protected customGetRequestWithQueryString<T>(action: string, httpParams: HttpParams): Observable<any> {
    var headerOptions = this.buildBaseRequestOptionsArgs();

    return this.httpClient.get<T>(this.baseUrl + (action === null ? '' : this.addDataToUrl(action)), { headers: headerOptions, params: httpParams }).pipe(
      catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
    );
  }

  protected customPostRequestWithParams(action: string, jsonData: string, httpParams: HttpParams): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs();

    return this.httpClient.post(this.baseUrl + this.addDataToUrl(action), jsonData, { headers: options, params: httpParams })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected customPostWithFile(action: string, occupancy: any) {
    //const HttpUploadOptions = {
    //  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
    //}
    var options = this.buildBaseRequestOptionsArgs_V2();
    //return this.httpClient.post(this.baseUrl + this.addDataToUrl(action), jsonData, { headers: options })
    //  .pipe(
    //    catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
    //  );

    return this.httpClient.post(this.baseUrl + this.addDataToUrl(action), occupancy, { headers: options }).pipe(
      catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
    );
  }

  protected customPutRequest(id: number | string, fileInputModel: any): Observable<any> {
    var options = this.buildBaseRequestOptionsArgs_V2();
    return this.httpClient
      .put(this.baseUrl + this.addDataToUrl(id), fileInputModel, { headers: options })
      .pipe(
        catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
      );
  }

  protected customGetRequestWithQuery(action: string, httpParams: HttpParams): Observable<any> {
    var headerOptions = this.buildBaseRequestOptionsArgs();
    // let url ="https://localhost:44391/"+this.baseUrl  + action + "?occupancyId=4&fileType=Occupancy";   
    return this.httpClient.get(this.baseUrl + (action === null ? '' : this.addDataToUrl(action)), { headers: headerOptions, params: httpParams, responseType: 'blob' as 'json' }).pipe(
      catchError((res: HttpResponse<any>) => { return this.handleError(res, false) })
    );
  }
  protected uploadFile(action: string, formData: FormData): Observable<any> {// data: any, files: Array<File>
    return Observable.create(new Promise((resolve, reject) => {
      //	let formData: any = new FormData();
      let xhr = new XMLHttpRequest();

      ////formData.append("data", data);
      //for (var prop in data) {
      //	formData.append(prop, data[prop]);
      //}
      //for (let file of files) {
      //	formData.append("files", file, file.name)
      //}
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else if (xhr.status == 204) {
            resolve();
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.open("POST", this.baseUrl + action, true);
      xhr.setRequestHeader('Authorization', this.authService.getAuthorizationHeaderValue());
      xhr.setRequestHeader('UserId', this.authService.getAuthorizationHeaderUserId());
      xhr.setRequestHeader('PermissionFormId', this.authService.getAuthorizationHeaderFormPermissionId())
      xhr.setRequestHeader('TimezoneOffset', this.authService.getAuthorizationHeaderOffset());
      xhr.send(formData);
    }));
  }
}
