import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { DocumentUpload } from '../models/documentupload.model';
import { SystemCode } from '../models/system-codes.model';
import { AuthService } from '../services/auth.service';
import { DocumentUploadService } from '../services/documentupload.service';
import { SystemCodeService } from '../services/system-code.service';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { Observable, forkJoin } from 'rxjs';
@Component({
  selector: 'uploaddocument',
  templateUrl: './uploaddocument.component.html'

})
export class UploadDocumentComponent implements OnInit {  
  @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;
  @ViewChild('documentForm', { static: false }) documentForm: NgForm;
  //startdate: FormControl;
  //startDate: Date;
  title: string = "";
  fileTypes: SystemCode[] = [];
  fileModel: DocumentUpload;
  fileObj: DocumentUpload[];
  uploadDocumentId: number =0;
  //control = new FormControl();
  //control1 = new FormControl();
  //filteredStreets: Observable<string[]>;
  //filteredStreets1: Observable<string[]>;
  //occupancyFile: File;
  uploadFile: File;
  uploadFileExists: boolean = false;
  isFileSelected: boolean = false;
  //files: Array<File>;
  //fileData: File = null;
  //previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = "";
  baseUrl: string = "";
  validationMsg: string=null;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  obj: any;
  rolePermissionCheckModel: RolePermissionCheck;
  isNew: string;
  isClicked: boolean = false;
  getFileSize: SystemCode;
  getFileSizeText: SystemCode;
  getFileSizeValueText: SystemCode;
  typeStr: string = "";
  typeInt: number = 0;
  typeValueStr: string = "";
  constructor(
    private systemCodeStateService: SystemCodeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService, private documentUploadservice: DocumentUploadService, private http: HttpClient
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      //this.baseUrl = baseUri;, @Inject('BASE_URL') baseUri: string
      this.route.params.subscribe(params => {
        //let Id = params['id']
        //if (Id != null && Id != undefined) {
        //  this.uploadDocumentId = parseInt(Id);
        //  this.loadDocument();
        //}
        if (params.id == undefined) {
          this.uploadDocumentId = 0;
          this.resetUploadDocument();
        }
        else {
          this.uploadDocumentId = parseInt(params.id);
        }


        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
      });
    }
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.loadDropDowns();
      this.fileModel = this.initializeModel();
      this.obj = this;
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.uploadDocumentId = parseInt(Id);
          this.loadDocument();
        }
       
        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
      });

    }
    this.loadFileSize();
    this.loadFileSizeText();
    this.loadFileSizeValueText();
  }

  initializeModel(): DocumentUpload {
    return {
      fileId: 0,
      fileTitle: null,
      filetype: null,
      uploadFileName: null,
      uploadFileDelete: false
    } as DocumentUpload
  }

  loadDocument() {
    if (this.uploadDocumentId > 0) {
      debugger;
      this.documentUploadservice.getUploadedDocument('getUploadedDocument', this.uploadDocumentId).subscribe(res => {
        debugger;
        this.fileModel = res;
        //this.uploadFile = res.uploadFile;
        this.uploadFileExists = false;
        this.isFileSelected = true;
        if (this.fileModel.uploadFileName != null && this.fileModel.uploadFileName.length > 0) {
          this.uploadFileExists = true;
        }
      }, err => {
      });
    }
  }
  openOFile(fileId: string, fileName: string) {
    this.documentUploadservice.getuploadFile('GetUploadFile', fileId, "Upload").subscribe(res => {
      //var blob = new Blob([data], { type: type });
      //var url = window.URL.createObjectURL(blob);
      saveAs(res, fileName);
      //window.location.href = "/";
      //let url = "https://localhost:44391/api/Occupancy/GetOccupancyFile?occupancyId=4&fileType=Occupancy";
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      //saveAs(res, "sdsd.txt");
      // var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }

  deleteOFile(fileId: string) {
    this.fileModel.uploadFileDelete = true;
    this.fileModel.uploadFileName = "";
    this.uploadFileExists = false;
    this.isFileSelected = false;
    return false;
  }

  updateDocument() {
    this.isClicked = true;
    let formData: FormData = new FormData();
    formData.append("fileId", this.fileModel.fileId.toString());
    formData.append("fileTitle", this.fileModel.fileTitle);
    formData.append("filetype", this.fileModel.filetype.toString());
    if (this.uploadFile != null && this.uploadFile != undefined) {
      formData.append("uploadFile", this.uploadFile, this.uploadFile.name);
    }
    else {
      formData.append("uploadFile", null);
    }
    if (this.uploadFileExists == false) {
      formData.append("UploadFileDelete", "true");
    }

    if (this.uploadDocumentId > 0) {

      //if (this.uploadFileExists == true) {
      //  formData.append("uploadFileDelete", "true");
      //}

      this.documentUploadservice.updateUploadFile(this.uploadDocumentId, formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully!") {
          this.savedialog = true;
          this.resetUploadDocument();
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.resetTheForm();
            this.GoBack();
           // this.routerService.navigate(['/documentlibrary']);

          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
        }
      });
    } else {
    this.documentUploadservice.saveUploadFile("saveUploadFile", formData).subscribe(res => {
      this.errMsg = res.msg;
      if (this.errMsg == "Saved Successfully!") {
        this.savedialog = true;
        // this.loadGrid();
        this.resetUploadDocument();
      }
      else {
        this.errordialog = true;
      }

      if (this.savedialog == true) {
        const dialogRef = this.dialog.open(SaveDialogComponent, {
          width: '500px',
          height: '200px',
          data: { name: "Saved Successfully!" }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.resetUploadDocument();
         // this.routerService.navigate(['/documentlibrary']);

        });
      } else if (this.errordialog == true) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          width: '500px',
          height: '200px'
        });        
      }
    });
    }
  }

  GoBack() {
    this.routerService.navigate(['/documentlibrary']);
  }

  uploaddocFile(fileInput: any) {
   // this.loadFileSize();
    //this.fileData = <File>fileInput.target.files[0];
    this.uploadFile = fileInput[0];
    debugger;
    if (this.uploadFile != undefined && this.uploadFile != null) {
      // this.uploadFileExists = true;
      this.isFileSelected = true;

      var fileName = this.uploadFile.name;

      const lastDot = fileName.lastIndexOf('.');

      const fName = fileName.substring(0, lastDot);
      const ext = fileName.substring(lastDot + 1).toLowerCase();
      this.validationMsg = "";
     
      debugger;
      if (this.uploadFile.size > this.typeInt) {

        this.validationMsg = this.typeStr;
        this.isFileSelected = false;
      }

      if (!(ext == "txt" || ext == "pdf" || ext == "docx" || ext == "xls" || ext == "csv" || ext == "mov" || ext == "ppt" || ext == "pptx" || ext == "jpg" || ext == "jpeg" || ext == "wmv" || ext == "vob")) {
        this.validationMsg = "File extension is Invalid!";
        this.isFileSelected = false;
      }
    }
    else {
      this.isFileSelected = false;
      this.validationMsg = "";
    }
    // this.preview();

    //if (this.isFileSelected == false) {
    //  setTimeout(this.hideFunction, 4000);//wait 2 seconds
    //}
  }

  closeValidation() {
    this.validationMsg = null;
    this.fileUploader.nativeElement.value = null;
    this.uploadFile = null;
  }

  resetUploadDocument() {
    this.fileModel = this.initializeModel();
    this.fileUploader.nativeElement.value = null;
    this.uploadFile = null;
    this.validationMsg = null;
    this.uploadFileExists = false;
    this.isFileSelected = false;
    this.documentForm.resetForm();
    this.isClicked = false;
    this.uploadDocumentId = 0;
    return false;
  }


  //public updateDocumentLibrary() {
  //  this.documentUploadservice.saveDocumentUpload('saveDocumentUpload', this.fileobj).subscribe(res => {     
  //    this.errMsg = res.msg;
  //    if (this.errMsg == "Saved Successfully.") {
  //      this.savedialog = true;
  //    }
  //    else {
  //      this.errordialog = true;
  //    }
  //    if (this.savedialog == true) {
  //      const dialogRef = this.dialog.open(SaveDialogComponent, {
  //        width: '500px',
  //        height: '200px',
  //        data: { name: "Saved Successfully!" }
  //      });
  //      dialogRef.afterClosed().subscribe(result => {
  //        this.resetTheForm();
  //      });
  //    } else if (this.errordialog == true) {
  //      const dialogRef = this.dialog.open(ErrorDialogComponent, {
  //        width: '500px',
  //        height: '200px'
  //      });      
  //    }
  //  });
  //}

  //resetTheForm() {
  //  this.fileobj = {
  //    fileId: 0, fileTitle: "", file: 0, filetype: 0
  //  };
  //}

  public loadDropDowns() {
    this.systemCodeStateService.getAllfileTypes().subscribe(
      (res: Array<SystemCode>) => {
        this.fileTypes = res || [];
      }, (error: any) => { }
    );


  }

  deletePFile(prePlanId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '200px',
      data: { id: 1, name: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {

        this.fileModel.uploadFileDelete = true;
        this.fileModel.uploadFileName = null;
        this.uploadFileExists = false;
      }
    });
    return false;
  }
  //loadFileSize() {
  //  forkJoin(

  //    this.systemCodeStateService.getFileSize(),
     
  //    //this.systemCodeStateService.getSystemCodesForSubTypes()
  //  ).subscribe(([res1]) => {
  //    debugger;
  //    this.getFileSize = res1.type || [];
  //    //this.crews = res2 || [];
     
  //  });
  //}
  loadFileSize()
  {
    debugger;
    this.systemCodeStateService.getFileSize('getFileSizeData').subscribe(res => {
      debugger;
      this.getFileSize = res;
      this.typeInt = parseInt(res[0].type);
         
     // console.log("value = ", this.typeInt);
      //var dt = res.0.type;

  }, err => {
  });
  return false;
  }
  loadFileSizeText() {
    debugger;
    this.systemCodeStateService.getFileSizeText('getFileSizeTextData').subscribe(res => {
      debugger;
      this.getFileSizeText = res;
      this.typeStr = res[0].type;

     // console.log("value = ", this.typeInt);
      //var dt = res.0.type;

    }, err => {
    });
    return false;
  }
  loadFileSizeValueText() {
    debugger;
    this.systemCodeStateService.getFileSizeValueText('getFileSizeValueTextData').subscribe(res => {
      debugger;
      this.getFileSizeValueText = res;
      this.typeValueStr = res[0].type;
     // console.log("value = ", this.typeInt);
      //var dt = res.0.type;

    }, err => {
    });
    return false;
  }
}
