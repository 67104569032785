import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { RolePermissionsService } from "./rolepermissions.service";
import { switchMap } from 'rxjs/operators'; 

@Injectable()
export class UserRoleAuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private route: Router,
    private rolePermissionService: RolePermissionsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.route.navigate([`./login`]);
      return false;
    }
  }
}
