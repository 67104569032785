import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { MatDialog } from '@angular/material';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Occupancy } from '../models/occupancy.model';
import { OccupancyService } from '../services/occupancy.service';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { SystemCodeService } from '../services/system-code.service';
import { SystemCode } from '../models/system-codes.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ContactPhone } from '../models/contactphone.model';
import { saveFile, saveAs } from 'file-saver';
import { debug } from 'util';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { OccupancyFile } from '../models/occupancyfile.model';
interface BuisnessFilter {
  key: number;
  value: string;
}
interface StreetNameFilter {
  key: number;
  value: string;
}
@Component({
  selector: 'createoccupancy',
  templateUrl: './createeoccupancy.component.html'
})
export class CreateOccupancyComponent {

  occupancyResultObj: Occupancy[] = [];
  occupancyModel: Occupancy;
  occupancyFilesModel: OccupancyFile[] = [];
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  occupancyFile: File;
  occupancyMFile: Array<File>=[];
  preplanFile: File;
  control = new FormControl();
  buisnessList: number = 10382;
  districts: SystemCode[] = [];
  knoxboxs: SystemCode[] = [];
  contactTypes: SystemCode[] = [];
  //buisnessNames: SystemCode[] = [];
  errorMsg: string;
  contactPhones: ContactPhone[] = [];
  occcupancyfilesDelete: OccupancyFile[] = [];
  filterednames: Observable<SystemCode[]>;
  //businessNames: SystemCode[] = [];
  //streetNames: SystemCode[] = [];


  occupancyFileExists: boolean = false;
  preplanFileExists: boolean = false;
  phonePattern = /^\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$/;
  whitespacepattern = /^[^\s]+([\s\S]+)*$/;
  phone1Exists: boolean = false;
  fileExists: boolean = true;
  deletedFileIds: number[] = [];
  buisnessNames: BuisnessFilter;
  streetNames: StreetNameFilter;
  isNew: string;
  isClicked: boolean = false;
 // buisnessId: number = null;

  buisnessResultObj: Array<{ key: number, value: string }> =[];
  public filteredBuisnessList: Array<{ key: number, value: string }> = [];

  streetResultObj: Array<{ key: number, value: string }> = [];
  public filteredStreetList: Array<{ key: number, value: string }> = [];
  //control = new FormControl();
  //control1 = new FormControl();
  rolePermissionCheckModel: RolePermissionCheck;
 

  @ViewChild('OccupancyfileUploader', { static: false }) occupancyFileUploader: ElementRef;
  @ViewChild('PrefileUploader', { static: false }) preFileUploader: ElementRef;
  @ViewChild('occupancyForm', { static: false }) formValues;
  constructor(
    private systemCodeStateService: SystemCodeService,
    private occupancyService: OccupancyService,
    private routerService: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.route.params.subscribe(params => {
        let Id = params['id']
        if (Id != null && Id != undefined) {
          this.editOccupancy(Id);
          // this.employeeId = parseInt(Id);
        }
        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
      });
    }    
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.initializeFormValues();
      this.loadDropDowns();
      //this.loadBuisness();
      //this.loadStreetNames();
      // this.loadGrid();
      this.addAnotherItemDefault();
    }
  }

  initializeOccupancyModel(): Occupancy {
    return {
      occupancyId: 0,
      employeeId: 0,
      business: null,
      phone: null,
      streetNumber: null,
      streetName: null,
      aptsuite: null,
      district: null,
      knoxbox: null,
      contactFirstName: null,
      contactLastName: null,
      contactType: null,
      contactPhone: null,
      districtName: null,
      knoxBoxName: null,
      contactTypeName: null,
      occupancyFileName: null,
      preplanFileName: null,
      occupancyFileDelete: false,
      preplanFileDelete: false,
      contactPhonePrimary: null,
      contactPhoneSecondary: null,
      streetNameText: null,
      occupancyFilesdata: null
    } as Occupancy;
  }

  initializeOccupancyFilesModel(): OccupancyFile {
    return {

      occupancyFileId: 0,
      occupancyFileName: null,
      occupancyFileType: null,
      occupancyId: 0,
      fileDeleted: false,
      isActive: true,
      occupancyFile: null
    } as OccupancyFile;
  }
  
  updateOccupancy() {
    this.isClicked = true;
    //let businessId = null;
    //if (this.buisnessNames != undefined && this.buisnessNames != null) {
    //  businessId = this.buisnessNames.key;
    //}

    //let streetNameId = null;
    //if (this.streetNames != undefined && this.streetNames != null) {
    //  streetNameId = this.streetNames.systemCodeId;
    //}   

    let formData: FormData = new FormData();     

    formData.append("occupancyId", this.occupancyModel.occupancyId.toString());
    formData.append("employeeId", this.occupancyModel.employeeId.toString());
    if (this.occupancyModel.business != undefined && this.occupancyModel.business != null) {
      formData.append("business", this.occupancyModel.business.toString());
    }
    else {
      formData.append("business", "");
    }

    if (this.occupancyModel.phone != undefined && this.occupancyModel.phone != null) {
      formData.append("phone", this.occupancyModel.phone);
    }
    else {
      formData.append("phone", "");
    }
    

    if (this.occupancyModel.streetNumber != undefined && this.occupancyModel.streetNumber != null) {
      formData.append("streetNumber", this.occupancyModel.streetNumber);
    }
    else {
      formData.append("streetNumber", "");
    }
    
    //formData.append("streetName", this.occupancyModel.streetName);
    if (this.occupancyModel.streetName != undefined && this.occupancyModel.streetName != null) {
      formData.append("streetName", this.occupancyModel.streetName.toString());
    }
    else {
      formData.append("streetName", "");
    }

    if (this.occupancyModel.aptsuite != undefined && this.occupancyModel.aptsuite != null) {
      //formData.append("streetName", this.occupancyModel.streetName.toString());
      formData.append("aptsuite", this.occupancyModel.aptsuite.toString());
    }
    else {
      formData.append("aptsuite", "");
    }
   
    if (this.occupancyModel.district == undefined || this.occupancyModel.district == null) {
      formData.append("district", "");
    }
    else {
      formData.append("district", this.occupancyModel.district.toString());
    }
    if (this.occupancyModel.knoxbox == undefined || this.occupancyModel.knoxbox == null) {
      formData.append("knoxbox", "");
    }
    else {
      formData.append("knoxbox", this.occupancyModel.knoxbox);
    }
    if (this.occupancyModel.contactType == undefined || this.occupancyModel.contactType == null) {
      formData.append("contactType", "");
    }
    else {
      formData.append("contactType", this.occupancyModel.contactType);
    }

    if (this.occupancyModel.contactFirstName != null) {
      formData.append("contactFirstName", this.occupancyModel.contactFirstName);
    }
    else {
      formData.append("contactFirstName", "");
    }
    if (this.occupancyModel.contactLastName != null) {
      formData.append("contactLastName", this.occupancyModel.contactLastName);
    }
    else {
      formData.append("contactLastName", "");
    }
    //formData.append("contactType", this.occupancyModel.contactType);

    let contactList: string = "";
    for (var x = 0; x < this.contactPhones.length; x++) {
      contactList = contactList + this.contactPhones[x].contactPhone + ", ";
    }
    formData.append("contactPhone", contactList.slice(0, -2));

    if (this.occupancyFile != null && this.occupancyFile != undefined) {
      formData.append("occupancyFile", this.occupancyFile, this.occupancyFile.name);
    }
    else {
      formData.append("occupancyFile",null);
    }

    if (this.preplanFile != null && this.preplanFile != undefined) {
      formData.append("preplanFile", this.preplanFile, this.preplanFile.name);
    }
    else {
      formData.append("preplanFile", null);
    }

    if (this.occupancyFileExists == false) {
      formData.append("occupancyFileDelete", "true");
    }   

    if (this.preplanFileExists == false) {
      formData.append("preplanFileDelete", "true");
    }
    if (this.occupancyModel.contactPhonePrimary != null) {
      formData.append("contactPhonePrimary", this.occupancyModel.contactPhonePrimary);
    }
    else {
      formData.append("contactPhonePrimary", "");
    }
    if (this.occupancyModel.contactPhoneSecondary != null) {
      formData.append("contactPhoneSecondary", this.occupancyModel.contactPhoneSecondary);
    }
    else {
      formData.append("contactPhoneSecondary", "");
    }

    formData.append("businessText", "");
    formData.append("streetNameText", "");

    for (let file of this.occupancyFilesModel) {
      if (file.isActive == true && file.occupancyFile != null) {
        formData.append("files", file.occupancyFile, file.occupancyFile.name)
      }
    }
    formData.append("deletedFileIds", JSON.stringify(this.deletedFileIds))
      
    if (this.occupancyModel.occupancyId == 0) {
      this.occupancyService.saveOccupancywithFile("saveOccupancyFile", formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
          
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.gotoOccupancyReport();

          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
        }
      });
    }
    else {
      this.occupancyService.updateOccupancywithFile(this.occupancyModel.occupancyId.toString(), formData).subscribe(res => {
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
          // this.loadGrid();
         
        }
        else {
          this.errordialog = true;
        }

        if (this.savedialog == true) {
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully." }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.gotoOccupancyReport();

          });
        } else if (this.errordialog == true) {
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px'
          });
        }
      });

    }  
  }
  resetTheForm() {
    this.contactPhones = [];
    this.initializeFormValues();
    this.fileExists = false;
    this.occupancyFileUploader.nativeElement.value = null;
    this.preFileUploader.nativeElement.value = null;
    this.formValues.resetForm();
    // 
    this.occupancyFile = null;
    this.preplanFile = null;
    this.errorMsg = "";
    this.isClicked = false;
  }

  gotoOccupancyReport() {
    this.routerService.navigate(['/occupancyreport']);
     
  }
  OccupancyFile(fileInput: any) {
    //this.fileData = <File>fileInput.target.files[0];
    this.occupancyFile = fileInput[0];
  
    // this.preview();
  }
  //OccupancyMFile(fileInput: any) {
  //  this.occupancyMFile.push(fileInput[0]);
   
  //}
  OccupancyMFile(fileInput: any, occupancyFileId: number) {
    // this.occupancyMFile.push(fileInput[0]);
    const index = this.occupancyFilesModel.findIndex(x => x.occupancyFileId == occupancyFileId);
    if (index >= 0) {
      this.occupancyFilesModel[index].occupancyFile = fileInput[0];
      this.errorMsg = "";

      //this.occupancyFilesModel[index].isActive = false;
      // this.contactPhones.splice(index, 1);
    }

  }
  PrePlanPdf(fileInput: any) {
    //this.fileData = <File>fileInput.target.files[0];
    this.preplanFile = fileInput[0];
    
    // this.preview();
  }
  initializeFormValues() {
    this.occupancyModel = this.initializeOccupancyModel();
    var contactphone = { contactPhone: "" };
    this.contactPhones.push(contactphone);
   // this.ContactPhones.push("");
  }
  addAnotherItemDefault() {
    var occupancyFile = this.initializeOccupancyFilesModel();
    occupancyFile.occupancyFileId = this.occupancyFilesModel.length;
      this.occupancyFilesModel.push(occupancyFile);
  }
  addAnotherItem() {
    var occupancyFile = this.initializeOccupancyFilesModel(); 
    occupancyFile.occupancyFileId = this.occupancyFilesModel.length;
    if (this.occupancyFilesModel[this.occupancyFilesModel.length-1].occupancyFile != null) {
      this.occupancyFilesModel.push(occupancyFile);
      this.errorMsg = " ";
    }
    else {
      this.errorMsg = "Upload Occupancy Files is required";
    }
      // if (this.contactPhones[this.contactPhones.length - 1].contactPhone!="") {   
      
    //}
  }
  //loadGrid() {
  //  this.occupancyService.getAllOccupancies('GetOccupancies', 1).subscribe(res => {
  //    this.occupancyResultObj = res;
  //  });
  //}
  public loadDropDowns() {

    this.systemCodeStateService.getSystemCodesForDistricts().subscribe(
      (res: Array<SystemCode>) => {
        this.districts = res || [];

      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForKonxBoxs().subscribe(
      (res: Array<SystemCode>) => {
        this.knoxboxs = res || [];

      }, (error: any) => { }
    );

    this.systemCodeStateService.getSystemCodesForContactTypes().subscribe(
      (res: Array<SystemCode>) => {
        this.contactTypes = res || [];

      }, (error: any) => { }
    );

    this.occupancyService.getBuisness("getSystemCodesForBuisness").subscribe(
      res => {
        // this.businessNames = res || [];        
        this.buisnessResultObj = res.map(x => { return { key: x.systemCodeId, value: x.type }; });
        this.filteredBuisnessList = this.buisnessResultObj.slice();
      }, (error: any) => { }
    );

    this.occupancyService.getStreets("getSystemCodesForStreetNames").subscribe(
      res => {
        // this.businessNames = res || [];
        this.streetResultObj = res.map(x => { return { key: x.systemCodeId, value: x.type }; });
        this.filteredStreetList = this.streetResultObj.slice();
      }, (error: any) => { }
    );
    //this.systemCodeStateService.getSystemCodesForStreetNames().subscribe(
    //  (res: Array<SystemCode>) => {
    //    this.streetNames = res || [];

    //  }, (error: any) => { }
    //);
  }


  public editOccupancy(occupancyId: string) {
    this.fileExists = true;
    this.occupancyFile = null;
    this.preplanFile = null;
    this.occupancyService.getOccupancy('GetOccupancy', occupancyId).subscribe(res => {
      this.occupancyModel = res;
      //this.buisnessList= 10382;
     //this.buisnessId = 10382;
      if (this.occupancyModel != null && this.occupancyModel != undefined) {
        this.occupancyFileExists = false;
       // this.buisness = this.occupancyModel.buisness;
        if (this.occupancyModel.occupancyFileName!=null && this.occupancyModel.occupancyFileName.length > 0) {
          this.occupancyFileExists = true;
        }
        this.preplanFileExists = false;
        if (this.occupancyModel.preplanFileName!=null && this.occupancyModel.preplanFileName.length > 0) {
          this.preplanFileExists = true;
        }

        if (this.occupancyModel.contactPhone != null && this.occupancyModel.contactPhone.indexOf(",") != -1) {
          this.contactPhones = [];
          var arrPhoneNos: string[] = this.occupancyModel.contactPhone.split(", ");
          for (let arr in arrPhoneNos) {
            var contactphone = { contactPhone: arrPhoneNos[arr] };
            this.contactPhones.push(contactphone);
            //this.contactPhones =
          }
        }
        else {
          var contactphone = { contactPhone: "" };
          this.contactPhones = [];
          this.contactPhones.push(contactphone);
        }
      }
    });   
    return false;
  }
  deleteItem(occupancyFileId: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {

      if (res == 1) {
        // this.employeeAwards.findIndex(x => x == index)
        const index = this.occupancyFilesModel.findIndex(x => x.occupancyFileId == occupancyFileId);
        if (index >= 0) {
          this.occupancyFilesModel[index].isActive = false;
         // this.contactPhones.splice(index, 1);
        }
      }
    });    
    return false;

  }
  deleteOccupancyItem(occupancyFileId: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        id: 1
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 1) {
        
        // this.employeeAwards.findIndex(x => x == index)
        const index = this.occupancyModel.occupancyFilesdata.findIndex(x => x.occupancyFileId == occupancyFileId);
        if (index > -1) {
          // this.ContactPhones[index].isActive = false;
          this.occupancyModel.occupancyFilesdata[index].fileDeleted = true;
          this.deletedFileIds.push(this.occupancyModel.occupancyFilesdata[index].occupancyFileId);
          
        } 
      }
    });
    return false;

  }
  openOFile(occupancyId: string,fileName:string) {
    this.occupancyService.getOccupancyFile('GetOccupancyFile', occupancyId, "Occupancy").subscribe(res => {

      //var blob = new Blob([data], { type: type });
      //var url = window.URL.createObjectURL(blob);

      saveAs(res, fileName);
      //window.location.href = "/";
      //let url = "https://localhost:44391/api/Occupancy/GetOccupancyFile?occupancyId=4&fileType=Occupancy";
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      //saveAs(res, "sdsd.txt");
      // var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }
  openPFile(occupancyId: string, fileName: string) {
    this.occupancyService.getOccupancyFile('GetOccupancyFile', occupancyId,"Preplan").subscribe(res => {
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(res, fileName);
      //var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }
  
  openOccupancyFile(occupancyFileId: string, fileName: string) {
    this.occupancyService.getOccupancyFilesList('GetOccupancyFileList', occupancyFileId, "OccupancyFiles").subscribe(res => {
      //saveAs(blob, filename);
      //const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(res, fileName);
      //var fileURL = URL.createObjectURL(res);
      //window.open(fileURL); // if you want to open it in new tab
    });
    return false;
  }
  
  deleteOFile(occupancyId: string) {

    //if (this.savedialog == true) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '500px',
        height: '200px',
        data: {id:1, name: "" }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.occupancyModel.occupancyFileDelete = true;
        this.occupancyModel.occupancyFileName = "";
        this.occupancyFileExists = false;
      }
      });
    
   
    return false;
  }
  deletePFile(occupancyId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      height: '200px',
      data: { id: 1, name: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {

        this.occupancyModel.preplanFileDelete = true;
        this.occupancyModel.preplanFileName = null;
        this.preplanFileExists = false;
      }
    });
    return false;
  }
  phone1(valid: boolean) {
   
    this.phone1Exists = valid;
  }

  goback() {

    this.routerService.navigate(['/occupancyreport']);
  }
  loadBuisness() {

    this.occupancyService.getBuisness("getSystemCodesForBuisness").subscribe(
      res => {
        // this.businessNames = res || [];  
        this.buisnessResultObj = res.map(x => { return { key: x.systemCodeId,  value: x.type }; });
      //  this.filteredList5 = this.buisnessResultObj.slice();
      }, (error: any) => { }
    );
  }

  loadStreetNames() {
    this.occupancyService.getStreets("getSystemCodesForStreetNames").subscribe(
      res => {
        // this.businessNames = res || [];   
        this.streetResultObj = res.map(x => { return { key: x.systemCodeId, value: x.type }; });
        this.filteredStreetList = this.streetResultObj.slice();
      }, (error: any) => { }
    );
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }
}
