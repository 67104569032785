import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MaintenenceRequestComment } from '../models/maintenencerequestcomment.model';
import { SystemCodeService } from '../services/system-code.service';
import { MaintanenceRequestService } from '../services/maintanancerequest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveDialogComponent } from '../common/saved-dialog/save-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { MaintenenceRequest } from '../models/maintenencerequest.model';
import { MatDialog } from '@angular/material';
import { SystemCode } from '../models/system-codes.model';
import { Observable, forkJoin } from 'rxjs';
import { RolePermissionCheck } from '../models/role-permission-check.model';
import { AuthService } from 'src/app/services/auth.service';
import { ViewChildren, QueryList, ElementRef } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'maintenancerequestform',
  templateUrl: './maintenancerequestform.component.html'
})
export class Maintenancerequestform {
  @ViewChild('maintenanceForm', { static: false }) maintenanceForm: NgForm;
  @ViewChild('textareaelement', { static: false }) textareaelement: ElementRef;
  @ViewChildren("txtArea") textAreas: QueryList<ElementRef>;

  maintenenceRequestModel: MaintenenceRequest;
  maintenenceRequestComments: MaintenenceRequestComment[] = [];
  stationCodes: SystemCode[] = [];
  crews: SystemCode[] = [];
  managementTypes: SystemCode[] = [];
  actionReuired: SystemCode[] = [];
  pendingTypes: SystemCode[] = [];
  subTypes: SystemCode[] = [];
  routeLinkModel: boolean = false;
  subTypeDisplay: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  clickcount: number = 0;
  count: number = 0;
  click: boolean = false;
  addanotherfielderror: boolean = false;
  errorhint: string = "";
  checkNull: boolean = false;
  seriesWorkOrderNumber: string;
  workOrderNumberDisable: boolean = false;
  errMsg: string;
  savedialog: boolean;
  errordialog: boolean;
  commentfieldempty: boolean = true;
  maintenenceRequestId: number = 0;
  requestErrorMsg: string;
  totalActiveItems: number = 0;
  errorhint1: string = "";
  isNew: string;
  hint: string;
  rolePermissionCheckModel: RolePermissionCheck;
  disableSave: boolean = false;
  //requestComment: string;
  //deleteButton: null;
  commentsFromServer: string;
  displayCommentsTextarea: boolean = false;

  constructor(
    private systemCodeStateService: SystemCodeService,
    private maintanenceRequestService: MaintanenceRequestService,
    private dialog: MatDialog,
    private authservice: AuthService,
    private route: ActivatedRoute,
    private routerService: Router,
    private cd: ChangeDetectorRef
  ) {
    this.rolePermissionCheckModel = this.route.snapshot.data['rolePermissions'];
    route.params.subscribe(val => {
      if (val.id == undefined) {
        this.maintenenceRequestId = 0;
        this.resetTheForm();
      } else {
        this.maintenenceRequestId = parseInt(val.id);
      }
    });
  }

  ngOnInit() {
    if (this.rolePermissionCheckModel && (this.rolePermissionCheckModel.isCreate || this.rolePermissionCheckModel.isUpdate)) {
      this.blockUI.start();
      this.route.params.subscribe(params => {
        let Id = params['id'];
        if (Id != null && Id != undefined) {
          this.maintenenceRequestId = parseInt(Id);
        } else {
          this.loadMaintanenceRequestForDate();
        }

        let isnew = params['isnew']
        if (isnew != null && isnew != undefined) {
          this.isNew = isnew;
        }
        this.blockUI.stop();
      });

      this.updateComments();
      this.loadDropDowns();
      this.maintenenceRequestModel = this.initializeMaintanenceRequestModel();
      if (this.maintenenceRequestId > 0) {
        this.maintanenceRequestService.getMaintanence('GetMaintanenceRequest', this.maintenenceRequestId).subscribe(res => {
          this.maintenenceRequestModel = res;
          this.commentsFromServer = this.maintenenceRequestModel.updateComments;
          //this.maintenenceRequestModel.updateComments = this.updateComments();
          this.onTypeChange(this.maintenenceRequestModel.typeId, true);
          if (this.maintenenceRequestModel.updateComments && this.maintenenceRequestModel.updateComments.length > 0) {
            this.displayCommentsTextarea = true;
          } else {
            this.displayCommentsTextarea = false;
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  initializeMaintanenceRequestModel(): MaintenenceRequest {
    return {
      maintenenceRequestId: 0,
      stationId: null,
      crewId: null,
      typeId: null,
      actionRequiredId: null,
      description: null,
      pendingOrCompleteId: null,
      workOrderNumber: null,
      isActive: true,
      pendingCompleteName: null,
      actionRequiredName: null,
      crewName: null,
      stationName: null,
      typeName: null,
      createdOn: null,
      requestComment: null,
      subTypeId: null,
      updateComments: "",
      subTypeName: null
    } as MaintenenceRequest;
  }

  initializeMaintanenceRequestCommentModel(): MaintenenceRequestComment {
    return {
      maintanenceRequestCommentId: 0,
      maintenenceRequestId: 0,
      requestComment: "",
      isActive: true,
      createdOn: new Date(),
      createdBy: "TEST",
      modifiedOn: new Date(),
      modifiedBy: "",
      orderId: this.maintenenceRequestComments.length + 1
    } as MaintenenceRequestComment;
  }

  commentInput(event: string) {
    if (event != '') {
      this.requestErrorMsg = "";
      this.commentfieldempty = true;
    } else {
      this.requestErrorMsg = "Update/Comments field is required";
      this.commentfieldempty = false;
    }
  }

  resetTheForm() {
    if (this.maintenanceForm) {
      this.maintenanceForm.resetForm();
    }
    
    this.maintenenceRequestModel = this.initializeMaintanenceRequestModel();
    this.requestErrorMsg = "";
    this.displayCommentsTextarea = false;
    //this.maintenenceRequestModel.updateComments = this.updateComments();
    this.subTypeDisplay = false;
    this.loadMaintanenceRequestForDate();
    this.disableSave = false;
    return false;
  }

  public loadDropDowns() {
    forkJoin(
      this.systemCodeStateService.getSystemCodesForStation(),
      this.systemCodeStateService.getSystemCodesForCrews(),
      this.systemCodeStateService.getSystemCodesForMaintanenceTypes(),
      this.systemCodeStateService.getSystemCodesForActionRequired(),
      this.systemCodeStateService.getSystemCodesForPendingType()
      //this.systemCodeStateService.getSystemCodesForSubTypes()
    ).subscribe(([res1, res2, res3, res4, res5]) => {
      this.stationCodes = res1 || [];
      this.crews = res2 || [];
      this.managementTypes = res3 || [];
      this.actionReuired = res4 || [];
      this.pendingTypes = res5 || [];
    });
  }

  //checkTextboxisnull() {
  //  //if (this.maintenenceRequestModel. == null) {
  //  //  this.checkNull = false;
  //  //}
  //  //else
  //  //{
  //  //  this.checkNull = true;
  //  //}
  //}

  public updateMaintanenceRequest() {
    this.blockUI.start();
    this.disableSave = true;
    if (this.maintenenceRequestId > 0) {
      //this.maintenenceRequestModel.updateComments = this.maintenenceRequestModel.updateComments + this.commentsFromServer ;
      this.maintanenceRequestService.updateMaintanence(this.maintenenceRequestId, this.maintenenceRequestModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Updated Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Updated Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            this.maintenenceRequestId = 0;
            //this.routerService.navigate(['/maintenancerequestform']);
            this.GoBack();
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            //this.GoBack();
          });
        }
      });
    } else {
      this.maintanenceRequestService.saveMaintanence('saveMaintanenceRequest', this.maintenenceRequestModel).subscribe(res => {
        this.blockUI.stop();
        this.errMsg = res.msg;
        if (this.errMsg == "Saved Successfully.") {
          this.savedialog = true;
        } else {
          this.errordialog = true;
        }
        this.blockUI.stop();
        if (this.savedialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(SaveDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Saved Successfully!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.resetTheForm();
            // this.routerService.navigate(['/maintenancerequestform']);
            //  this.GoBack();
          });
        } else if (this.errordialog == true) {
          this.blockUI.stop();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            height: '200px',
            data: { name: "Error", description: "Error Occured. Please try again!" }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.GoBack();
          });
        }
      });
    }
    this.blockUI.stop();
  }

  onTypeChange(value: any, isOnInit: boolean) {
    var type = this.managementTypes.find(x => x.systemCodeId === value).type;

    //remove hardcoded calls for type string 1/22/2024 by Josiah Luikham
    if (type != null && type.length > 0) {
      this.systemCodeStateService.getSystemCodesByCode(type).subscribe(
        (res: Array<SystemCode>) => {
          this.subTypes = res || [];
        }, (error: any) => { }
      );
    }
    
    //if (type == "Station Items") {//Station Items
    //  this.systemCodeStateService.getSystemCodesForStationItems().subscribe(
    //    (res: Array<SystemCode>) => {
    //      this.subTypes = res || [];
    //    }, (error: any) => { }
    //  );
    //}
    //else if (type == "Equipment") {//Equipment
    //  this.systemCodeStateService.getSystemCodesForEquipment().subscribe(
    //    (res: Array<SystemCode>) => {
    //      this.subTypes = res || [];
    //    }, (error: any) => { }
    //  );
    //} else {//Apparatus/Vehicle/Boats  
    //  this.systemCodeStateService.getSystemCodesForApparatus().subscribe(
    //    (res: Array<SystemCode>) => {
    //      this.subTypes = res || [];
    //    }, (error: any) => { }
    //  );
    //}

    if (!isOnInit && this.maintenenceRequestModel.maintenenceRequestId > 0) {
      this.maintenenceRequestModel.subTypeId = null;
      this.maintenenceRequestModel.typeName = null;
      this.maintenenceRequestModel.subTypeName = null;
    }

    this.subTypeDisplay = true;
  }

  loadMaintanenceRequestForDate() {
    this.maintanenceRequestService.getMaintanenceRequestForDate('getMaintanenceRequestForDate').subscribe(res => {
      this.seriesWorkOrderNumber = res;
      this.maintenenceRequestModel.workOrderNumber = this.seriesWorkOrderNumber.toString();
    }, err => { });
    return false;
  }

  updateComments() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var currentDate = mm + '/' + dd + '/' + yyyy;
    var username = this.authservice.getUserName();
    let date: Date = new Date();
    var curHours = date.getHours();
    var curMin = date.getMinutes();
    var curSec = date.getSeconds();
    var currentTime = curHours + ":" + curMin + ":" + curSec;
    var userdata = currentDate + "  " + currentTime + "  " + "by" + " " + username + ": ";
    return userdata;
  }

  goHome(): void {
    this.routerService.navigate(['/home']);
  }

  addComment() {
    if (this.maintenenceRequestModel.updateComments == "") {
      this.maintenenceRequestModel.updateComments = this.updateComments();
      this.displayCommentsTextarea = true;
    } else {
      var tempExistingText = this.maintenenceRequestModel.updateComments;
      var appendData = this.updateComments() + '\n' + tempExistingText;
      this.maintenenceRequestModel.updateComments = appendData;
      this.displayCommentsTextarea = true;
    }

    if (this.displayCommentsTextarea) {
      this.cd.detectChanges();
      this.textareaelement.nativeElement.focus();
      this.textareaelement.nativeElement.selectionEnd = this.maintenenceRequestModel.updateComments.indexOf(':');
    }
  }

  GoBack() {
    this.routerService.navigate(['/maintenanceReport']);
  }
}
